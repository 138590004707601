{
  "billingsActivityAnalytics": {
    "title": "Analisi"
  },
  "billingsActivityAnalyticsAverageChart": {
    "title": "Durata media delle richieste",
    "length": "Durata",
    "no_requests_yet": "Non ci sono ancora richieste"
  },
  "billingsActivityAnalyticsFilters": {
    "today": "oggi",
    "7_days": "7 giorni",
    "month": "mese"
  },
  "billingsActivityAnalyticsRequestsChart": {
    "title": "Richieste",
    "unique_visitors": "Visitatori unici",
    "per_day": "al giorno",
    "per_session": "per sessione"
  },
  "billingsActivityAnalyticsVisitors": {
    "title": "Visitatori unici",
    "all_visitors": "Tutti i visitatori",
    "no_visitors_yet": "Ancora nessun visitatore"
  },
  "billingsActivityAnalyticsSuccessRate": {
    "title": "Tasso di successo",
    "subtitle": "Quanto spesso OmniMind può rispondere alle domande"
  },
  "billingsActivityAnalyticsCosts": {
    "title": "Costi"
  }
}
