{
  "tenantPortalPortalSection": {
    "completed": "Concluído",
    "non_completed": "Não concluído"
  },
  "tenantPortalTabs": {
    "settings": "Configurações",
    "projects": "Projetos",
    "packages": "Pacotes",
    "users": "Usuários",
    "transactions": "Transações"
  }
}