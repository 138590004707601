{
  "addFileDrop": {
    "drag_drop_or": "Ziehen und Ablegen oder",
    "select_files": "Dateien auswählen",
    "to_upload": "zum Hochladen",
    "error_uploading_file": "Fehler beim Hochladen der Datei",
    "error_uploading_files": "Fehler beim Hochladen der Dateien"
  },
  "resourcesProject": {
    "show_uploaded_resources": "Hochgeladene Ressourcen anzeigen",
    "knowledge": "Wissen"
  },
  "resourcesProjectListItem": {
    "click_here_to_show_hide_explore_the_pages": "Hier klicken, um die Seiten {value}!",
    "delete": "Löschen",
    "chunks": "Chunks",
    "cancel": "Abbrechen",
    "resource": "Ressource",
    "size": "Größe",
    "updated": "Aktualisiert",
    "page_content": "Seiteninhalt"
  },
  "resourcesProjectModal": {
    "knowledge": "Wissen",
    "save": "Speichern",
    "search_for_uploaded_knowledge": "Nach hochgeladenem Wissen suchen...",
    "add_more": "Weitere hinzufügen"
  }
}