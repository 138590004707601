{
  "vimeoInformerInformers": {
    "you_can_provide_your_own_vimeo_token": "You can, provide your own Vimeo Token in your services credentials settings.",
    "click_here": "Click here!",
    "please_provide_a_valid_vimeo_video_url": "Please, provide a valid Vimeo video url",
    "this_video_has_no_text_tracks": "This video has no text tracks!",
    "something_went_awry": "Something went awry!"
  },
  "vimeoInformerFormInformers": {
    "insert_vimeo_video_url": "Insert Vimeo Video URL",
    "fetch_subtitles": "Fetch subtitles"
  },
  "vimeoInformerListInformers": {
    "available_tracks": "Available tracks"
  }
}