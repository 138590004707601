{
  "voiceWidget": {
    "ooops_we_have_t_found": "404 Ooops, não encontramos o projeto",
    "ooops_we_have_t_found_many": "404 Ooops, não encontramos os projetos",
    "ooops_we_have_t_found_one": "404 Ooops, não encontramos o projeto",
    "ooops_we_have_t_found_other": "404 Ooops, não encontramos os projetos",
    "oops_it_seems_youve_reached": "Oops! Parece que você atingiu o limite de mensagens. Para continuar, ajuste seu limite de mensagens nas configurações.",
    "oops_it_seems_youve_reached_many": "Oops! Parece que você atingiu o limite de mensagens. Para continuar, ajuste seu limite de mensagens nas configurações.",
    "oops_it_seems_youve_reached_one": "Oops! Parece que você atingiu o limite de mensagens. Para continuar, ajuste seu limite de mensagens nas configurações.",
    "oops_it_seems_youve_reached_other": "Oops! Parece que você atingiu o limite de mensagens. Para continuar, ajuste seu limite de mensagens nas configurações.",
    "please_ask_the_owner_of": "401 Por favor, peça ao proprietário do projeto para torná-lo público",
    "please_ask_the_owner_of_many": "401 Por favor, peça aos proprietários dos projetos para torná-los públicos",
    "please_ask_the_owner_of_one": "401 Por favor, peça ao proprietário do projeto para torná-lo público",
    "please_ask_the_owner_of_other": "401 Por favor, peça aos proprietários dos projetos para torná-los públicos"
  }
}