{
  "changePassword": {
    "you_will_be_redirected": "Serás redirigido a la página de inicio en {value} segundos",
    "to_go_to_the_home_page": "Para ir a la página de inicio",
    "click_here": "haz clic aquí",
    "enter_your_new_password": "Ingresa tu nueva contraseña",
    "password_successfully_changed": "Contraseña cambiada exitosamente"
  },
  "signIn": {
    "sign_in": "Iniciar sesión",
    "enter_your_email_and_password": "Ingresa tu correo electrónico y contraseña para iniciar sesión",
    "email": "Correo electrónico",
    "enter_email": "Ingresa el correo electrónico",
    "password": "Contraseña",
    "enter_password": "Ingresa la contraseña",
    "or": "O",
    "dont_have_an_account": "¿No tienes una cuenta?",
    "sign_up_with_email": "Regístrate con correo electrónico",
    "dont_remember_the_password": "¿No recuerdas la contraseña?",
    "reset_password": "Restablecer contraseña",
    "link_expired": "Este enlace no es válido o ha expirado.",
    "please_request_a_new_link": "Por favor solicita un nuevo enlace.",
    "something_went_awry": "¡Algo salió mal!",
    "your_email_is_not_yet_verified": "Tu correo electrónico aún no ha sido verificado. Por favor revisa tu bandeja de entrada y sigue el enlace de verificación para completar el registro."
  },
  "signUp": {
    "sign_up": "Registrarse"
  },
  "signUpForm": {
    "name": "Nombre",
    "enter_first_name": "Ingresa el nombre",
    "last_name": "Apellido",
    "enter_last_name": "Ingresa el apellido",
    "email": "Correo electrónico",
    "enter_email": "Ingresa el correo electrónico",
    "password": "Contraseña",
    "enter_password": "Ingresa la contraseña",
    "password_confirmation": "Confirmación de contraseña",
    "i_agree": "Acepto los",
    "terms_and_conditions": "Términos y Condiciones",
    "and": "y",
    "privacy_policy": "Política de Privacidad",
    "by_checking_this": "Al marcar esto, reconoces y aceptas la transferencia de los datos que cargues en este proyecto a servicios de terceros, como OpenAI. Esta transferencia de datos es necesaria para el correcto funcionamiento del proyecto y para aprovechar servicios externos que mejoran sus características.",
    "third_party_services": "Consentimiento de transferencia de datos a servicios de terceros",
    "sign_up": "REGISTRARSE",
    "or_sign_up_with": "O registrarse con",
    "already_have_an_account": "¿Ya tienes una cuenta?",
    "sign_in": "Iniciar sesión",
    "please_check_your_mailbox_and": "Por favor revisa tu bandeja de entrada y \n sigue el enlace de verificación para completar el registro. \n",
    "if_you_cant_find_it_in_your": "Si no lo encuentras en tu bandeja de entrada, por favor revisa tu",
    "spam_folder": "carpeta de spam",
    "as_well": "también",
    "this_field_contains_special": "Este campo contiene caracteres especiales",
    "please_ensure_youve_read": "Por favor asegúrate de haber leído y aceptado nuestros Términos y Condiciones",
    "and_have_consented": "y haber dado tu consentimiento para la transferencia de datos a servicios de terceros marcando las casillas respectivas",
    "before_signing_in": "antes de iniciar sesión",
    "by_signing_up_you_agree": "Al registrarte, aceptas nuestros Términos y Condiciones y reconoces que has dado tu consentimiento para la transferencia de tus datos a servicios de terceros.",
    "read_our": "Lee nuestra"
  }
}