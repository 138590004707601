{
  "bulkCsvInformerInformers": {
    "select_csv_files_to_upload": "Selecione arquivos CSV para fazer upload",
    "bulk_upload_via_csv": "A funcionalidade de Upload em Massa via CSV permite que você faça upload de links listados em um arquivo CSV.",
    "drag_drop_or": "Arraste e solte ou",
    "select_a_csv_file": "Selecione um arquivo CSV",
    "to_upload": "para fazer upload",
    "download_example_csv": "Baixar Exemplo de CSV",
    "in_column": "Na coluna",
    "we_found": "encontramos",
    "valid_links": "links válidos",
    "there_are_no_valid_links_in_this_column": "Não há links válidos nesta coluna.",
    "error_uploading_file": "Erro ao fazer upload do arquivo:"
  },
  "bulkCsvInformerMapperInformers": {
    "map_file_columns": "Mapear colunas do arquivo",
    "specify_the_corresponding_column": "Especifique a coluna correspondente no arquivo CSV que contém os URLs. Esta coluna deve ser selecionada para processamento. Se os URLs na coluna não incluírem \"https://\", o sistema adicionará automaticamente.",
    "link": "Link"
  },
  "csvInformerInformers": {
    "select_csv_file_to_upload": "Selecione um arquivo CSV para fazer upload",
    "drag_drop_or": "Arraste e solte ou",
    "select_a_csv_file": "Selecione um arquivo CSV",
    "to_upload": "para fazer upload",
    "download_example_csv": "Baixar Exemplo de CSV",
    "invalid_file_type": "Tipo de arquivo inválido. Por favor, faça upload de um arquivo CSV.",
    "invalid_mime_type": "Tipo MIME inválido. O arquivo não parece ser um CSV.",
    "error_parsing_csv": "Erro ao analisar o CSV:",
    "error_uploading_file": "Erro ao fazer upload do arquivo:",
    "something_went_awry": "Algo deu errado!"
  },
  "csvInformerAdvancedSettingsInformers": {
    "advanced_settings": "Configurações Avançadas",
    "hide_settings": "Ocultar Configurações",
    "show_settings": "Mostrar Configurações",
    "select_title_columns": "Selecionar Colunas de Título",
    "using_a_direct_custom_prompt": "Usar um prompt personalizado direto é necessário apenas em casos especiais e desabilitará o formulário principal.",
    "select_metadata_columns": "Selecionar Colunas de Metadados"
  },
  "csvInformerMapperInformers": {
    "map_file_columns": "Mapear colunas do arquivo",
    "content": "Conteúdo",
    "meta": "Meta"
  },
  "customiseColumnsListInformers": {
    "name": "Nome",
    "label": "Rótulo",
    "select_all": "Selecionar Todos"
  },
  "previewCSVInformers": {
    "preview_csv_data": "Visualizar Dados do CSV",
    "more_data": "Mais dados..."
  }
}