{
  "exportQuestionsCodeModal": {
    "how_to_import_into_quizlet": "Como Importar para o Quizlet",
    "start_by_heading_to": "Comece indo para o Quizlet e clique no botão + para criar um novo conjunto de estudos. Em seguida, encontre e clique no botão \"Importar\". Agora, simplesmente copie o conteúdo fornecido acima e cole no Quizlet. Lembre-se: use uma",
    "tab": "Tab",
    "between_each_term_and_its_definition_and_a": "entre cada termo e sua definição, e uma",
    "new_line": "Nova Linha",
    "to_separate_different_cards": "para separar diferentes cartões. Boa sorte nos estudos!",
    "copy_content": "Copiar conteúdo",
    "copied_content": "Conteúdo copiado",
    "content_was_copied": "Conteúdo foi copiado!"
  },
  "filtersModal": {
    "filters": "Filtros",
    "search_for_resources": "Buscar recursos...",
    "search_by_selected_resources": "Buscar por recursos selecionados",
    "reset_all": "Resetar Todos",
    "check_all": "Marcar Todos",
    "sorry_no_resources_found_for_your": "Desculpe, nenhum recurso encontrado para a sua busca",
    "please_try_modifying_your": "Por favor, tente modificar os termos de busca ou os filtros para expandir a sua busca.",
    "cancel": "Cancelar",
    "apply": "Aplicar"
  },
  "liveConnect": {
    "error_executing_user_code": "Erro ao executar o código do usuário",
    "something_went_wrong": "Algo deu errado! Por favor, verifique se você instalou o Intercom corretamente",
    "something_went_wrong_please_make_sure": "Algo deu errado! Por favor, verifique se você instalou o widget do Hubspot corretamente",
    "something_went_wrong_please_make_sure_dialogFlow": "Algo deu errado! Por favor, verifique se você instalou o DialogFlow corretamente"
  },
  "offlineMessage": {
    "name": "Nome",
    "email": "Email",
    "subject": "Assunto",
    "message": "Mensagem",
    "submit": "Enviar",
    "error_executing_user_code": "Erro ao executar o código do usuário",
    "thank_you_for_your_response": "Obrigado pela sua resposta!",
    "something_went_awry": "Algo deu errado!"
  },
  "poweredByMessage": {
    "powered_by": "Desenvolvido por"
  },
  "questionnaires": {
    "examples_of_questions_that_you_can_ask": "Exemplos de perguntas que você pode fazer ao bot"
  },
  "widgetsFooter": {
    "back": "Voltar",
    "help": "Ajuda"
  },
  "widgetsModalInfo": {
    "it_staff_augmentation_in_portugal": "Aumento de Equipe de TI em Portugal",
    "upload_time": "Tempo de Upload",
    "result_relevance": "Relevância dos Resultados"
  },
  "widget_external_user_form": {
    "please_provide_information_to_chat_with_me": "Por favor, forneça informações para conversar comigo",
    "your_email": "Seu email",
    "email": "Email",
    "your_name": "Seu nome",
    "name": "Nome",
    "continue": "Continuar"
  }
}