{
  "popupChatWidget": {
    "clear_chat": "Очистити чат",
    "close": "Закрити",
    "processing_your_request": "Обробка вашого запиту...",
    "please_ask_the_owner_of_the_project_to_make_it_public": "Будь ласка, попросіть власника проекту зробити його публічним",
    "the_project_owner_needs_to_upgrade": "Власник проекту повинен оновити підписку, оскільки поточна закінчилася.",
    "oops_we_have_t_found": "404 Ой, ми не знайшли проект",
    "oops_it_seems_youve_reached": "Ой! Здається, ви досягли обмеження на кількість повідомлень. Щоб продовжити, будь ласка, змініть обмеження в налаштуваннях."
  }
}