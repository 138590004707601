{
  "knowledgeQa": {
    "no_data_so_far": "Даних поки немає.",
    "question": "Питання",
    "answer": "Відповідь",
    "last_modified": "Остання зміна",
    "actions": "Дії",
    "delete_these_items": "Видалити ці елементи?",
    "are_you_sure_you_want_to_delete": "Ви впевнені, що хочете видалити вибрані елементи?",
    "this_action_cannot_be_undone": "Цю дію неможливо скасувати.",
    "the_resources_were_removed": "Ресурси були видалені.",
    "an_error_occurred": "Під час видалення ресурсів сталася помилка",
    "question_and_answer_were_saved": "Питання та відповідь успішно збережені!",
    "an_error_occurred_while_saving": "Під час збереження питання та відповіді сталася помилка!"
  },
  "knowledgeQaActions": {
    "edit": "Редагувати",
    "delete": "Видалити"
  },
  "knowledgeQaEditModal": {
    "question": "Питання",
    "cancel": "Скасувати",
    "filter": "Фільтр",
    "apply": "Застосувати"
  },
  "knowledgeQaFilterModal": {
    "cancel": "Скасувати",
    "filter": "Фільтр",
    "apply": "Застосувати",
    "by_content": "За вмістом",
    "search_for_content": "Пошук за вмістом..."
  },
  "knowledgeQaFilters": {
    "by_content": "За вмістом"
  },
  "knowledgeQaToolbar": {
    "qa_selected": "Вибрано QA:"
  }
}