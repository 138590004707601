{
  "portalPreview": {
    "example": "Exemplo",
    "suggestedQuestions": "Perguntas sugeridas",
    "who_s_on_duty_today": "Quem está de plantão hoje?",
    "send": "Enviar"
  },
  "tenantPortalPortalUi": {
    "portal_styles": "Estilos do portal",
    "background_color": "Cor de fundo",
    "primary_color": "Cor primária",
    "secondary_color": "Cor secundária/contorno",
    "accent_color": "Cor de destaque",
    "text_color": "Cor do texto",
    "portal_preview": "Visualização do portal",
    "apply": "Aplicar",
    "field_is_not_a_valid_hex_color": "Campo não é uma cor hexadecimal válida",
    "failed_to_upload_branding_logo": "Falha ao enviar o logotipo da marca",
    "portal_ui_successfully_saved": "Interface do portal salva com sucesso"
  }
}