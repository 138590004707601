{
  "knowledge": {
    "sources": "Джерела",
    "questions_and_answers": "Питання та відповіді"
  },
  "knowledgeHeader": {
    "knowledge": "Знання",
    "search_for_uploaded_knowledge": "Пошук завантажених знань..."
  },
  "knowledgeTable": {
    "name": "Назва",
    "title": "Заголовок",
    "size": "Розмір",
    "date": "Дата",
    "actions": "Дії"
  }
}