{
  "popupChatWidget": {
    "clear_chat": "Cancella chat",
    "close": "Chiudi",
    "processing_your_request": "Elaborazione della tua richiesta...",
    "please_ask_the_owner_of_the_project_to_make_it_public": "Chiedi al proprietario del progetto di renderlo pubblico",
    "the_project_owner_needs_to_upgrade": "Il proprietario del progetto deve aggiornare l'abbonamento in quanto quello attuale è scaduto.",
    "oops_we_have_t_found": "404 Oops, non abbiamo trovato il progetto",
    "oops_it_seems_youve_reached": "Oops! Sembra che tu abbia raggiunto il limite di messaggi consentiti. Per continuare, regola gentilmente il tuo limite di velocità nelle impostazioni."
  }
}