{
  "package": {
    "popular": "beliebt",
    "month": "Monat",
    "chosen": "Ausgewählt",
    "contact_us": "Kontaktiere uns",
    "package": "Paket",
    "change": "Ändern",
    "subscribe": "Abonnieren",
    "projects": "Projekte",
    "characters": "Zeichen",
    "add_units": "Einheiten hinzufügen",
    "units": "Einheiten",
    "informers": "Informanten",
    "widgets": "Widgets",
    "file_extensions": "Dateierweiterungen",
    "alternative_ai": "Alternative KI",
    "updates": "Updates",
    "extended_customization": "Erweiterte Anpassung"
  },
  "packageEnterpriseModal": {
    "contact_us": "Kontaktiere uns",
    "submit": "Absenden",
    "please_tell_us_more_about_your_use_case": "Bitte erzählen Sie uns mehr über Ihren Anwendungsfall",
    "share_your_expectations_from_the_plan": "Teilen Sie Ihre Erwartungen an den Plan mit, wie z.B. erforderliche Funktionen, Anzahl der Benutzer, Zeichenbegrenzungen, KI-Modelle usw."
  },
  "packageFree": {
    "beginner_plan": "Anfängerplan",
    "for_beginners_and_micro_projects": "Für Anfänger und Mikroprojekte",
    "free": "Kostenlos",
    "project": "Projekt",
    "characters": "Zeichen",
    "overquote": "Überquote",
    "calls": "Anrufe",
    "balance": "Guthaben",
    "try_now": "Jetzt ausprobieren!"
  },
  "packages": {
    "beginner_plan": "Anfängerplan",
    "enterprise": "Unternehmen",
    "custom_software_development": "Individuelle Softwareentwicklung mit dediziertem OmniMind-Plattform-Hosting",
    "integration_to_your_business": "Integration in Ihr Unternehmen",
    "custom_dataflow": "Individueller Datenfluss",
    "dedicated_platform": "Dedizierte Plattform",
    "100_data_privacy": "100% Datenschutz",
    "invalid_number": "Ungültige Nummer",
    "the_package_is_successfully_installed": "Das Paket wurde erfolgreich installiert!",
    "the_package_is_either_not_available": "Das Paket ist entweder nicht verfügbar oder bereits installiert."
  },
  "ResearcherDescription": "Für Einsteigerstudien und akademische Erkundungen",
  "ProDescription": "Erweiterte Tools für Fachleute und erfahrene Forscher",
  "EinsteinDescription": "Umfassende Unterstützung für führende Wissenschaftler und Innovatoren",
  "EnterpriseDescription": "Individuelle Softwareentwicklung mit dediziertem OmniMind-Plattform-Hosting"
}