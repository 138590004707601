{
  "tableDialogSQL": {
    "the_sql_query_for_your_prompt": "A consulta SQL para o seu prompt"
  },
  "tableWidget": {
    "ask_your_questions_to_get_a_data": "Faça suas perguntas para obter dados.",
    "show_sql": "Mostrar SQL",
    "export_to_csv": "Exportar para CSV",
    "failed_to_load_settings": "Falha ao carregar as configurações",
    "something_went_wrong": "Algo deu errado"
  },
  "tableWidgetTable": {
    "no_data": "Sem dados"
  }
}