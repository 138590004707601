{
  "widgetTypeIndex": {
    "let_s_upload_the_content": "Laden wir den Inhalt hoch",
    "with": "Mit",
    "informers": "Informanten",
    "you_can_upload_information": "Sie können Informationen aus verschiedenen Quellen hochladen, wie z.B. Websites, PDF-Dokumente, sogar Google-Ordner und mehr. Denken Sie daran, dass Sie jederzeit zu diesem Bildschirm zurückkehren können, um zusätzlichen Inhalt in Ihr Projekt zu laden.",
    "upload_information": "Laden Sie Informationen aus verschiedenen Quellen hoch, wie z.B. Websites, PDF-Dokumente, sogar Google-Ordner und mehr. Denken Sie daran, dass Sie jederzeit zu diesem Bildschirm zurückkehren können, um zusätzlichen Inhalt in Ihr Projekt zu laden, wann immer Sie möchten.",
    "whenever_you_need": "wann immer Sie möchten",
    "enter_the_name_of_the_project": "Geben Sie den Namen des Projekts ein",
    "enter_project_name": "Geben Sie den Projektnamen ein",
    "this_informer_is_not_available_yet": "Dieser Informant ist noch nicht verfügbar.",
    "back": "Zurück"
  }
}