import gql from 'graphql-tag';

export const UPDATE_USER_TO_PROJECT_SCOPES = gql`
  mutation updateUserToProjectScopeMutation($id: uuid!, $scopes: scope_types_enum_enum) {
    update_user_to_projects_by_pk(pk_columns: { id: $id }, _set: { scopes: $scopes }) {
      id
    }
  }
`;

export const UPDATE_USER_TO_PROJECT_INVITED = gql`
  mutation updateUserToProjectMutation($user_id: uuid!, $project_id: uuid!, $is_invited: Boolean) {
    update_user_to_projects(
      where: { user_id: { _eq: $user_id }, project_id: { _eq: $project_id } }
      _set: { is_invited: $is_invited }
    ) {
      id
    }
  }
`;

export const UPDATE_USER_TO_PROJECT_FAVORITES = gql`
  mutation updateUserToProjectFavoritesMutation(
    $id: uuid!
    $favorite: Boolean
    $favorite_time: timestamptz
  ) {
    update_user_to_projects_by_pk(
      pk_columns: { id: $id }
      _set: { favorite: $favorite, favorite_time: $favorite_time }
    ) {
      id
    }
  }
`;
