{
  "marketplaceOption": {
    "purchase": "Придбати",
    "characters": "символи",
    "informers": "інформери",
    "projects": "проекти",
    "EssentialBoost": "Основне підвищення",
    "PremiumExpansion": "Преміум розширення",
    "UltimateAdvantage": "Найвища перевага",
    "Projects10": "10 проектів",
    "EssentialBoostDescription": "Підвищте свій обліковий запис на $50, розблоковуючи нові можливості та покращуючи свій досвід.",
    "PremiumExpansionDescription": "Додаткові 1000 запитів на питання-відповідь",
    "UltimateAdvantageDescription": "Трансформуйте свій обліковий запис на $500, надаючи неперевершений доступ та найвищі функціональні можливості.",
    "Projects10Description": "Додаткові проекти"
  }
}
