{
  "pagesProjectsEmpty": {
    "empty_project": "Progetto vuoto",
    "is_not_complete_yet": "non è ancora completo perché non ha conoscenza. Puoi caricare la conoscenza o semplicemente eliminare il progetto.",
    "add_knowledge": "Aggiungi conoscenza",
    "this_project": "Questo progetto"
  },
  "pagesProjectsIndex": {
    "redirecting": "Reindirizzamento..."
  }
}