{
  "knowledgeFilterSourcesModal": {
    "filter": "Filter",
    "apply": "Apply",
    "cancel": "Clear",
    "by_title": "By title",
    "by_period": "By period",
    "search_for_resource_title": "Search for resource title..."
  },
  "knowledgeScheduleUpdateModal": {
    "schedule": "Schedule",
    "apply": "Apply",
    "cancel": "Clear"
  },
  "knowledgeUpdateModal": {
    "schedule": "Update knowledge",
    "description": "After the update, the resource will be refreshed. This action cannot be undone.\n\n\n\n\n\n\n",
    "apply": "Apply",
    "cancel": "Clear"
  },
  "knowledgeSources": {
    "no_data_so_far": "No data so far.",
    "type": "Type",
    "title": "Title",
    "date": "Date",
    "actions": "Actions",
    "delete_these_items": "Delete these items?",
    "are_you_sure_you_want_to_delete_selected_items": "Are you sure you want to delete selected items?",
    "reupload_these_items": "Reupload these items?",
    "this_action_cannot_be_undone": "This action cannot be undone.",
    "are_you_sure_you_want_to_delete_and_reupload": "Are you sure you want to delete and reupload selected items?",
    "reupload": "Reupload",
    "the_resources_were_removed": "The resources were removed.",
    "an_error_occurred_while_deleting_resources": "An error occurred while deleting resources",
    "sorry_we_re_unable_to_open_this_resource": "Sorry, we're unable to open this resource directly as it's not in a text knowledge resource.",
    "show_progress": "Click here to see the progress"
  },
  "knowledgeSourcesActions": {
    "view": "View",
    "refresh_from_source_coming_soon": "Refresh from source (Coming soon!)",
    "refresh_from_source": "Refresh from source",
    "refresh_schedule": "Schedule refresh from source",
    "delete": "Delete"
  },
  "knowledgeSourcesToolbar": {
    "resources_selected": "Resources selected:"
  },
  "knowledgeToolbarSourcesFilters": {
    "by_date": "By date"
  },
  "knowledgeToolbarSourcesUpdater": {
    "refresh_from_source_available_soon": "Refresh from source (Available Soon!)"
  }
}
