{
  "cancelChain": {
    "cancel": "Cancelar",
    "yes": "Sí",
    "no": "No",
    "stop_processing": "Detener procesamiento",
    "do_you_want_to_stop": "¿Desea detener todas las cadenas de procesamiento?"
  },
  "progressIndex": {
    "no_resources_so_far_redirecting_to_informers": "Hasta ahora no hay recursos. Redireccionando a los informadores...",
    "an_error_occured_while_deleting": "Ocurrió un error al eliminar. Por favor, inténtelo de nuevo"
  }
}