{
  "bulkCsvInformerInformers": {
    "select_csv_files_to_upload": "Seleccionar archivos CSV para cargar",
    "bulk_upload_via_csv": "La función de Carga masiva a través de CSV le permite cargar enlaces listados en un archivo CSV.",
    "drag_drop_or": "Arrastrar y soltar o",
    "select_a_csv_file": "Seleccionar un archivo CSV",
    "to_upload": "para cargar",
    "download_example_csv": "Descargar ejemplo de CSV",
    "in_column": "En la columna",
    "we_found": "encontramos",
    "valid_links": "enlaces válidos",
    "there_are_no_valid_links_in_this_column": "No hay enlaces válidos en esta columna.",
    "error_uploading_file": "Error al cargar el archivo:"
  },
  "bulkCsvInformerMapperInformers": {
    "map_file_columns": "Mapear columnas del archivo",
    "specify_the_corresponding_column": "Especifique la columna correspondiente en el archivo CSV que contiene las URL. Esta columna debe seleccionarse para su procesamiento. Si las URL en la columna no incluyen \"https://\", el sistema lo agregará automáticamente.",
    "link": "Enlace"
  },
  "csvInformerInformers": {
    "select_csv_file_to_upload": "Seleccionar archivo CSV para cargar",
    "drag_drop_or": "Arrastrar y soltar o",
    "select_a_csv_file": "Seleccionar un archivo CSV",
    "to_upload": "para cargar",
    "download_example_csv": "Descargar ejemplo de CSV",
    "invalid_file_type": "Tipo de archivo no válido. Por favor, cargue un archivo CSV.",
    "invalid_mime_type": "Tipo MIME no válido. El archivo no parece ser un CSV.",
    "error_parsing_csv": "Error al analizar el CSV:",
    "error_uploading_file": "Error al cargar el archivo:",
    "something_went_awry": "¡Algo salió mal!"
  },
  "csvInformerAdvancedSettingsInformers": {
    "advanced_settings": "Configuración avanzada",
    "hide_settings": "Ocultar configuración",
    "show_settings": "Mostrar configuración",
    "select_title_columns": "Seleccionar columnas de título",
    "using_a_direct_custom_prompt": "El uso de una indicación personalizada directa solo es necesario en casos especiales y deshabilitará el formulario principal.",
    "select_metadata_columns": "Seleccionar columnas de metadatos"
  },
  "csvInformerMapperInformers": {
    "map_file_columns": "Mapear columnas del archivo",
    "content": "Contenido",
    "meta": "Meta"
  },
  "customiseColumnsListInformers": {
    "name": "Nombre",
    "label": "Etiqueta",
    "select_all": "Seleccionar todo"
  },
  "previewCSVInformers": {
    "preview_csv_data": "Vista previa de datos CSV",
    "more_data": "Más datos..."
  }
}