{
  "serviceCredentials": {
    "you_can_define_your_credentials": "Puoi definire le tue credenziali e utilizzare i tuoi servizi per archiviare e elaborare le informazioni.",
    "save_credentials": "Salva credenziali",
    "keys": "Chiavi",
    "key": "Chiave",
    "service_credentials_were_updated": "Le credenziali del servizio sono state aggiornate!",
    "credentials_are_valid": "Le credenziali sono valide!",
    "credentials_are_invalid": "Le credenziali non sono valide!",
    "something_went_wrong": "Qualcosa è andato storto!",
    "cancel": "Annulla"
  }
}