{
  "settingsAppearance": {
    "appearance": "Erscheinungsbild",
    "bot_name": "Bot-Name",
    "brand_name": "Markenname",
    "enter_custom_bot_name": "Geben Sie einen benutzerdefinierten Bot-Namen ein",
    "enter_custom_brand_name": "Geben Sie einen benutzerdefinierten Markennamen ein",
    "change_the_widget_color_style": "Ändern Sie den Farbstil des Widgets",
    "change_the_header_font_size_px": "Ändern Sie die Schriftgröße des Headers (px)",
    "change_the_body_font_size_px": "Schriftgröße",
    "hide_powered_by_label": "Verbergen Sie das Label 'Powered by'",
    "show_answer_sources_option": "Option zur Anzeige der Antwortquellen anzeigen",
    "hide_the_full_site_overlay": "Die Vollbild-Overlay-Funktion ermöglicht es Ihnen, einen Fokuspunkt auf Ihrer Website zu erstellen, indem der Hintergrund abgedunkelt und das Widget angezeigt wird.",
    "hide_overlay": "Overlay ausblenden",
    "change_placeholder_text_of_the_form_s_input": "Platzhaltertext des Formularfelds ändern",
    "enter_custom_placeholder_text": "Geben Sie einen benutzerdefinierten Platzhaltertext ein...",
    "submit_button_s_text": "Text der Schaltfläche 'Senden'",
    "enter_text_for_submit_button": "Geben Sie den Text für die Schaltfläche 'Senden' ein...",
    "failed_to_upload_branding_logo": "Fehler beim Hochladen des Branding-Logos",
    "change_font_family": "Schriftart ändern",
    "show_on_mobile": "Auf Mobilgeräten anzeigen",
    "show_on_tablet": "Auf Tablets anzeigen"
  }
}