{
  "addNewPortalModal": {
    "add_new_portal": "Neues Portal hinzufügen?",
    "add_portal": "Portal hinzufügen",
    "the_portal_was_successfully_created": "Das Portal wurde erfolgreich erstellt.",
    "oops_something_went_wrong": "Hoppla! Etwas ist schiefgelaufen."
  },
  "deletePortalModal": {
    "delete_portal": "Portal löschen",
    "confirm": "Bestätigen",
    "you_are_about_to_delete_the_portal": "Sie sind dabei, das Portal zu löschen. Dies kann nicht rückgängig gemacht werden. Sind Sie sicher, dass Sie fortfahren möchten?"
  },
  "portalSettingsDomain": {
    "domain": "Domain",
    "your_domain_copied_to_clipboard": "Ihre Domain wurde in die Zwischenablage kopiert",
    "example": "Beispiel",
    "subdomain": "Subdomain",
    "custom_domain": "Benutzerdefinierte Domain",
    "this_domain_is_already_taken": "Diese Domain ist bereits vergeben"
  },
  "portalSettingsGeneral": {
    "general": "Allgemein"
  },
  "portalSettingsGeneralForm": {
    "name": "Name",
    "example_name": "Beispielname",
    "brief_info": "Kurzinfo",
    "example_brief_info": "Beispiel Kurzinfo"
  },
  "portalSettingsPayments": {
    "payments_processing": "Zahlungsabwicklung",
    "we_use_stripe_payment_platform": "Wir verwenden die Stripe-Zahlungsplattform zur Abwicklung von Zahlungen zwischen Ihnen und Ihren Kunden. Sie müssen Ihr Profil erstellen und eine Zahlungsmethode verbinden",
    "connect": "Verbinden",
    "configure": "Konfigurieren",
    "account": "Konto"
  },
  "portalSettings": {
    "manage": "Verwalten",
    "suspend_portal": "Portal aussetzen",
    "reactivate_portal": "Portal reaktivieren",
    "delete_portal": "Portal löschen",
    "apply": "Anwenden",
    "portal_settings_successfully_updated": "Portal-Einstellungen erfolgreich aktualisiert.",
    "oops_this_domain_is_already_taken": "Hoppla! Diese Domain ist bereits vergeben.",
    "please_fill_in_all_required_fields": "Bitte füllen Sie alle erforderlichen Felder aus."
  },
  "suspendPortalModal": {
    "suspend_portal": "Portal aussetzen",
    "confirm": "Bestätigen",
    "you_are_about_to_suspend_the_portal": "Sie sind dabei, das Portal auszusetzen. Das Portal wird für Ihre Benutzer nicht verfügbar sein, bis Sie es aktivieren. Sind Sie sicher, dass Sie fortfahren möchten?"
  }
}