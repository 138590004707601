{
  "userInformation": {
    "general_information": "Editar Informações",
    "full_name": "Nome Completo",
    "enter_full_name": "Digite o nome completo",
    "email": "Email",
    "enter_email": "Digite o email",
    "company": "Empresa",
    "enter_company": "Digite a empresa",
    "phone": "Telefone",
    "enter_phone_number": "Digite o número de telefone",
    "interface_language": "Idioma da interface",
    "select_interface_language": "Selecione o idioma da interface",
    "timezone": "Fuso horário",
    "select_your_timezone": "Selecione o seu fuso horário",
    "cancel": "Cancelar",
    "avatar": "Avatar",
    "upload_photo": "Enviar Foto",
    "save": "Salvar",
    "this_field_contains_special_characters": "Este campo contém caracteres especiais",
    "your_personal_data_was_updated": "Seus dados pessoais foram atualizados!",
    "entered_phone_number_is_already_in_use": "O número de telefone informado já está em uso",
    "invalid_form": "Alguns campos estão faltando ou incorretos. Por favor, revise os campos destacados e tente novamente.",
    "confirmation_email_sent": "Uma carta de confirmação para a alteração do seu email foi enviada para o seu novo endereço de email"
  }
}