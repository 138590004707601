<template>
  <div class="mt-6 flex items-center gap-2">
    <span class="flex-grow">
      <BaseSelect
        v-model="model"
        track-by="id"
        label="name"
        :options="expandedOptions"
        @select="onSelect"
      />
    </span>

    <button
      type="button"
      class="rounded-full border border-primary p-2 hover:bg-white-light/90 dark:hover:bg-dark/60"
      @click="$emit('update:inputValue', expandedOptions.at(-1)!)"
    >
      <BaseSvgIcon name="plus" class="!h-3 !w-3 text-primary" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useBaseComponents } from '@/hooks/asyncComponents';
import { useVModel } from '@vueuse/core';
import { useI18n } from 'vue-i18n';

//Types
import type { Project } from '@/stores';
import { useUserData } from '@nhost/vue';
import { TeamScopesEnum } from '@components/project/settings/team/TeamScopesEnum';

const { t } = useI18n();

const props = defineProps<{
  projects: Project[];
  inputValue: Project;
}>();

const emits = defineEmits<{
  (e: 'update:inputValue', value: Project): void;
}>();

const user = useUserData();
const expandedOptions = computed(() => [
  ...props.projects.map((p) => ({
    ...p,
    icon: p.user_to_projects.some(
      (up) => up.user_id === user.value?.id && up.scopes !== TeamScopesEnum.OWNER,
    )
      ? 'community'
      : null,
  })),
  { id: '1', name: t('sidebarHeader.new_project') } as Project,
]);

const model = useVModel(props, 'inputValue', emits);

const { BaseSelect, BaseSvgIcon } = useBaseComponents();

const onSelect = (value: Project) => {
  if (value.id === props.inputValue.id) {
    model.value = { id: '', name: '' } as Project;
    model.value = value;
  }
};
</script>
<style scoped>
::v-deep(.multiselect__single) {
  @apply max-w-[135px] truncate;
}
</style>
