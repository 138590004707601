{
  "youtubeInformer": {
    "please_provide_a_valid_vimeo_video_url": "Por favor, proporciona una URL válida de video de Vimeo",
    "tracks_are_unavailable_due_to_restrictions": "Las pistas de este video no están disponibles debido a restricciones",
    "something_went_awry": "Algo salió mal",
    "no_text_tracks": "¡Este video no tiene pistas de texto!",
    "start_time_cant_be_greater": "El tiempo de inicio no puede ser mayor o igual al tiempo de finalización"
  },
  "youtubeInformerForm": {
    "insert_youtube_video_url": "Insertar URL del video de Youtube",
    "fetch_subtitles": "Obtener subtítulos"
  },
  "youtubeInformerList": {
    "available_tracks": "Pistas disponibles"
  },
  "youtubeInformerPreview": {
    "please_adjust_the_sliders_below": "Por favor, ajusta los deslizadores de abajo para seleccionar un rango para cortar los subtítulos de tu video."
  }
}