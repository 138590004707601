{
  "portalPackageItem": {
    "name_is_not_provided": "Name is not provided",
    "click_to_edit_the_package": "Click to edit the package",
    "click_to_delete_the_package": "Click to delete the package",
    "package_name": "Package name",
    "your_package_name": "Your package name",
    "description": "Description",
    "your_package_description": "Your package description",
    "model": "Model",
    "package_price": "Package price",
    "subscription_cost_for_the_package": "Subscription cost for the package",
    "cost_per_symbols": "Cost per {symbols_quantity} symbols",
    "cost_for_characters": "Cost for {characters_quantity} characters when querying OpenAI (designed to help avoid excess user expenses). On average, one request is {average} characters",
    "approx_number_of_requests": "approx. number of requests",
    "trial_days": "Trial days",
    "links": "Links",
    "create_portal_link": "Create portal link",
    "delete_this_project": "Delete this project?",
    "delete_project": "Delete project",
    "package_will_be_deleted": "Package will be deleted. This cannot be undone",
    "the_item_has_been_successfully_deleted": "The item has been successfully deleted."
  },
  "portalPackages": {
    "no_packages_so_far": "No packages so far",
    "apply": "Apply",
    "stripe_account_is_not_configured": "Stripe account is not configured",
    "packages_successfully_saved": "Packages successfully saved"
  }
}