{
  "marketplaceOption": {
    "purchase": "Kaufen",
    "characters": "Charaktere",
    "informers": "Informanten",
    "projects": "Projekte",
    "EssentialBoost": "Essential Boost",
    "PremiumExpansion": "Premium Expansion",
    "UltimateAdvantage": "Ultimate Advantage",
    "Projects10": "Projekte 10",
    "EssentialBoostDescription": "Verbessern Sie Ihr Konto um zusätzliche 50 $, um neue Möglichkeiten freizuschalten und Ihr Erlebnis zu verbessern.",
    "PremiumExpansionDescription": "Zusätzliche 1000 Frage-Antwort-Anfragen",
    "UltimateAdvantageDescription": "Verwandeln Sie Ihr Konto mit zusätzlichen 500 $ und erhalten Sie beispiellosen Zugang und erstklassige Funktionen.",
    "Projects10Description": "Zusätzliche Projekte"
  }
}