import { qAWidgetSettings } from '@/components/project/settings/defaults/qAWidgetSettings';
import { SettingsPropertiesEnum } from '@/types/SettingsPropertiesEnum';
/* eslint-disable @typescript-eslint/no-unused-vars */
const {
  [SettingsPropertiesEnum.SUGGESTED_QUESTIONS]: _,
  [SettingsPropertiesEnum.SHOW_SUGGESTED_QUESTIONS]: _unused,
  [SettingsPropertiesEnum.FORMATTING]: _unused1,
  [SettingsPropertiesEnum.MESSAGE]: _unused2,
  [SettingsPropertiesEnum.USE_FOLLOWUPS]: _unused3,
  ...restQASettings
} = qAWidgetSettings;
/* eslint-enable @typescript-eslint/no-unused-vars */

export const voiceWidgetSettings = {
  ...restQASettings,
  [SettingsPropertiesEnum.LOGO]: {
    id: '',
    src: '',
  },
  [SettingsPropertiesEnum.IS_PRECONTEXT_CHECK]: true,
  [SettingsPropertiesEnum.FORM_LANGUAGE]: 'English',
  [SettingsPropertiesEnum.PRECONTEXT]: 7,
  [SettingsPropertiesEnum.MOST_LIKELY_ENABLED]: true,
};
