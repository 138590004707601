import { gql } from '@apollo/client/core';

export const GET_PROFILE_ID = gql`
  query ($id: uuid!) {
    profiles(where: { id: { _eq: $id } }) {
      id
    }
  }
`;

export const GET_USER_CREDENTIALS = gql`
  query ($id: uuid!) {
    profiles(where: { id: { _eq: $id } }) {
      id
      credentials
    }
  }
`;

export const GET_DOES_USER_HAVE_PASSWORD = gql`
  query ($email: citext = "") {
    users(where: { passwordHash: { _is_null: false }, email: { _eq: $email } }) {
      id
    }
  }
`;

export const GET_USER_IS_PAID_STATUS = gql`
  query ($id: uuid!) {
    profiles_by_pk(id: $id) {
      billing (where: { status: { _eq: "active" } }, order_by: { updated_at: desc }, limit: 1){
          id
          portal_id,
          units_amount
          units_amount_extra
          units_spent
      }
    }
  }
`;

export const GET_USER_ACCOUNT_STATE = gql`
  query ($id: uuid!) {
    profiles_by_pk(id: $id) {
      removed_at
      cancelled_at
    }
  }
`;

export const GET_USER_BILLING_PACKAGES = gql`
  query ($id: uuid!) {
    billing_user_packages(
        where: { user_id: { _eq: $id }, status: { _eq: "active" } },
        order_by: { created_at: desc },
        limit: 1
    ) {
      created_at
      id
      next_at
      package_features
      package_name
      package_price
      units_amount
      units_amount_extra
      units_spent
      status
      cost
      started_at
      updated_at
      trial_ended_at
      status
    }
  }
`;

export const GET_USER_BILLING_DAYS = gql`
  query ($id: uuid!) {
    billing_chains(where: { user_id: { _eq: $id } }) {
      created_at
      cost
      chain_id
      cost_rules
      user_id
      project_id
      portal_id
    }
  }
`;

export const GET_USER_BILLING_DAYS_FOR_PROJECT = gql`
  query GetUserBillingDaysForProject(
    $id: uuid!
    $portal_id: uuid = "8854cc58-e585-436f-990f-87466e52794c"
    $from: timestamptz
    $to: timestamptz
  ) {
    billing_chains(
      where: {
        project_id: { _eq: $id }
        portal_id: { _eq: $portal_id }
        created_at: { _gte: $from, _lte: $to }
      }
    ) {
      updated_at
      created_at
      cost
      chain_type
      chain_id
      cost_rules
      project_id
      portal_id
    }
  }
`;

export const GET_USER_METADATA = gql`
  query ($id: uuid!) {
    user(id: $id) {
      metadata
      id
    }
  }
`;

export const GET_API_KEY = gql`
  query GetApiKey($id: uuid = "") {
    user_api_keys(where: { id: { _eq: $id } }) {
      token
      id
    }
  }
`;

export const GET_APPLICATION = gql`
  query {
    application {
      billing
    }
  }
`;
