{
  "pagesProjectsEmpty": {
    "empty_project": "Projeto vazio",
    "is_not_complete_yet": "ainda não está completo porque não possui conhecimento. Você pode fazer o upload de conhecimento ou simplesmente excluir o projeto.",
    "add_knowledge": "Adicionar conhecimento",
    "this_project": "Este projeto"
  },
  "pagesProjectsIndex": {
    "redirecting": "Redirecionando..."
  }
}