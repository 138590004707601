{
  "SearchWidget_name": "Buscar",
  "SearchWidget_description": "Comunicación como en la barra de búsqueda del navegador. Haz preguntas y consulta el historial de respuestas.",
  "ExplainWidget_name": "Explicar",
  "ExplainWidget_description": "Comunicación para respuestas más detalladas. Pregunta - Respuesta.",
  "ChatWidget_name": "Chat",
  "ChatWidget_description": "Comunicación en forma habitual como en un mensajero. Corto y claro.",
  "BaseInformer_xml-sitemap_name": "Mapa del sitio XML",
  "BaseInformer_xml-sitemap_description": "Puedes agregar un mapa del sitio XML para analizar un sitio web",
  "PopupChatWidget_name": "Chat emergente",
  "PopupChatWidget_description": "Widget interactivo para respuestas instantáneas. Haz preguntas, obtén respuestas.",
  "CSVInformer_name": "Texto CSV",
  "CSVInformer_description": "Puedes cargar tu archivo .csv con mapeo de columnas personalizado",
  "WebInformer_name": "Sitios web múltiples",
  "WebInformer_description": "Puedes definir un lote de URLs de sitios web para procesar",
  "FileInformer_name": "Carga de archivo",
  "FileInformer_description": "Puedes seleccionar tu archivo local para procesar",
  "BaseInformer_url_name": "Archivo de URL",
  "BaseInformer_url_description": "Puedes definir la ruta de URL para cargar el archivo desde internet",
  "YoutubeInformer_name": "Youtube",
  "YoutubeInformer_description": "Puedes agregar subtítulos de videos de Youtube",
  "BaseInformer_text_name": "Texto",
  "BaseInformer_text_description": "Simplemente agrega texto",
  "BulkCSVInformer_name": "Enlaces CSV",
  "BulkCSVInformer_description": "Carga masiva a través de CSV con URLs",
  "BaseInformer_dropbox-file_name": "Archivo de Dropbox",
  "BaseInformer_dropbox-file_description": "Agrega el enlace al archivo de Dropbox",
  "BaseInformer_dropbox-folder_name": "Carpeta de Dropbox",
  "BaseInformer_dropbox-folder_description": "Agrega el enlace a la carpeta de Dropbox",
  "BaseInformer_gdrive-file_name": "Archivo de GDrive",
  "BaseInformer_gdrive-file_description": "Agrega el enlace al archivo de GDrive",
  "BaseInformer_gdrive-folder_name": "Carpeta de GDrive",
  "BaseInformer_gdrive-folder_description": "Agrega el enlace a la carpeta de GDrive",
  "DocSearchWidget_name": "Búsqueda de documentos",
  "DocSearchWidget_description": "Permite instalar un widget emergente para buscar en la documentación",
  "RetellingWidget_name": "Recontar",
  "RetellingWidget_description": "Herramienta interactiva de recontar para obtener información de tu base de conocimientos.",
  "VimeoInformer_name": "Vimeo",
  "VimeoInformer_description": "Puedes agregar subtítulos de videos de Vimeo",
  "ConfluenceInformer_name": "Confluence",
  "ConfluenceInformer_description": "Puedes agregar tu base de conocimientos de Confluence",
  "BaseInformer_zendesk_name": "Zendesk",
  "BaseInformer_zendesk_description": "Puedes definir la URL del centro de ayuda de Zendesk para procesar.",
  "BaseInformer_youtube-channel_name": "Canal de Youtube",
  "BaseInformer_youtube-channel_description": "Puedes definir la URL del canal de Youtube para procesar.",
  "BaseInformer_youtube-playlist_name": "Lista de reproducción de Youtube",
  "BaseInformer_youtube-playlist_description": "Puedes definir la URL de la lista de reproducción de Youtube para procesar.",
  "NotionInformer_name": "Notion",
  "NotionInformer_description": "Puedes agregar páginas o bases de datos de Notion",
  "InteractiveMindmapWidget_name": "Mapa mental interactivo",
  "InteractiveMindmapWidget_description": "Crea y personaliza mapas mentales jerárquicos.",
  "MermaidMindmapWidget_name": "Mapa mental Mermaid",
  "MermaidMindmapWidget_description": "Crea y personaliza mapas mentales jerárquicos.",
  "WordpressInformer_name": "Wordpress",
  "WordpressInformer_description": "Puedes agregar un sitio de Wordpress",
  "GoogleSheetsAddOn_name": "Complemento de Google Sheets",
  "GoogleSheetsAddOn_description": "Agrega el complemento de Omnimind AI a tus hojas de Google",
  "ZapierWidget_name": "Integración de Zapier",
  "ZapierWidget_description": "Agrega Omnimind AI a tu aplicación de Zapier",
  "ChromeExtensionInformer_name": "Extensión de Chrome",
  "ChromeExtensionInformer_description": "Carga la información de tus recursos usando nuestra extensión de Chrome.",
  "VoiceWidget_name": "Voz",
  "VoiceWidget_description": "Habla con tu bot para obtener respuestas como si hablaras con un humano",
  "SingleWebInformer_name": "Sitio web",
  "SingleWebInformer_description": "Puedes definir una única URL de sitio web para procesar",
  "DataExtractorWidget_name": "Extractor de datos",
  "DataExtractorWidget_description": "Extrae información de sitios web",
  "SlackIntegration_name": "Integración de Slack",
  "SlackIntegration_description": "Agrega Omnimind AI a tu espacio de trabajo de Slack",
  "WhatsAppIntegration_name": "Integración de WhatsApp",
  "WhatsAppIntegration_description": "Comunicación habitual en WhatsApp.",
  "BaseInformer_onedrive-file_name": "Archivo de OneDrive",
  "BaseInformer_onedrive-file_description": "Agrega el enlace al archivo de OneDrive",
  "BaseInformer_onedrive-folder_name": "Carpeta de OneDrive",
  "BaseInformer_onedrive-folder_description": "Agrega el enlace a la carpeta de OneDrive",
  "SummaryWidget_name": "Resumen",
  "SummaryWidget_description": "Transforma tus datos en resúmenes claros y concisos.",
  "QuizWidget_name": "Cuestionario",
  "QuizWidget_description": "Creación y exportación de cuestionarios completos",
  "TableWidget_name": "Tabla",
  "TableWidget_description": "Widget común para mostrar datos en una tabla. Este widget está oculto en la lista de widgets.",
  "AudioInformer_name": "Archivo de audio",
  "AudioInformer_description": "Puedes seleccionar tu archivo de audio local para procesar",
  "AirtableInformer_name": "Airtable",
  "AirtableInformer_description": "Puedes agregar tu base de conocimientos de Airtable",
  "FlashcardsWidget_name": "Tarjetas de memoria",
  "FlashcardsWidget_description": "Crea y utiliza tarjetas de memoria para un aprendizaje efectivo y pruebas de conocimiento",
  "ExcelInformer_name": "Excel",
  "ExcelInformer_description": "Puedes cargar tu archivo .xls o .xlsx con mapeo de columnas personalizado",
  "GoogleSheetsInformer_name": "Google Sheets",
  "GoogleSheetsInformer_description": "Puedes cargar tus hojas de Google con mapeo de columnas personalizado",
  "DatabaseInformer_name": "Información de base de datos",
  "DatabaseInformer_description": "Puedes hacer consultas a tus propias bases de datos en lenguaje legible por humanos.",
  "PdfOcrInformer_name": "PDF",
  "PdfOcrInformer_description": "Extrae todos los datos de los PDF y los agrega a tus recursos.",
  "BubbleIntegration_name": "Complemento de Bubble.io",
  "BubbleIntegration_description": "Integra OmniMind en tus soluciones de Bubble.",
  "NpmIntegration_name": "Paquete NPM",
  "NpmIntegration_description": "Agrega el paquete Npm de Omnimind AI a tus proyectos",
  "MicrosoftTeamsIntegration_name": "Integración de Microsoft Teams",
  "MicrosoftTeamsIntegration_description": "Integra tu base de conocimientos en tu comunicación corporativa con Microsoft Teams y uno de nuestros chatbots y acelera tus procesos operativos."
}