{
  "pagesProjectsEmpty": {
    "empty_project": "Proyecto vacío",
    "is_not_complete_yet": "no está completo todavía porque no tiene conocimiento. Puedes subir conocimiento o simplemente eliminar el proyecto.",
    "add_knowledge": "Agregar conocimiento",
    "this_project": "Este proyecto"
  },
  "pagesProjectsIndex": {
    "redirecting": "Redireccionando..."
  }
}