{
  "billingOverview": {
    "billing_overview": "Resumen de facturación",
    "expenses": "Gastos"
  },
  "billingOverviewItem": {
    "used": "Utilizado",
    "remaining": "restante",
    "used_out_of": "Utilizado {formattedCurrent_value} de {formattedTotal_value}.",
    "money": "Dinero"
  },
  "cardList": {
    "card_details": "Detalles de la tarjeta",
    "expires_on": "Vence el",
    "primary": "Principal"
  },
  "paymentHistory": {
    "payment_history": "Historial de pagos",
    "view_invoice": "Ver factura",
    "download_invoice": "Descargar factura",
    "march": "Marzo",
    "february": "Febrero",
    "january": "Enero",
    "pro_membership": "Membresía Pro"
  },
  "projectList": {
    "projects": "Proyectos"
  },
  "proPlanInfo": {
    "pro_plan": "Plan Pro",
    "renew_now": "Renovar ahora",
    "monthly_visitors": "{quantity} Visitantes mensuales",
    "unlimited_reports": "Informes ilimitados",
    "years_data_storage": "{quantity} Años de almacenamiento de datos",
    "days_left": "Días restantes",
    "month": "mes"
  },
  "userInfo": {
    "profile": "Perfil",
    "no_company_name": "sin nombre de empresa",
    "no_phone_number": "sin número de teléfono",
    "no_timezone": "sin zona horaria",
    "remove_your_account": "Eliminar tu cuenta"
  }
}