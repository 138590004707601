{
  "pagesProfileId": {
    "profile": "Profile",
    "profile_settings": "Profile Settings",
    "settings": "Settings",
    "home": "Home",
    "change_password": "Change Password"
  },
  "pagesProfileIndex": {
    "my_profile": "My Profile",
    "notifications": "Notifications",
    "profile_summary": "Profile summary",
    "services_credentials": "Services credentials",
    "api_keys": "API keys",
    "billings": "Billings",
    "marketplace": "Marketplace",
    "packages": "Packages",
    "integrations": "Integrations",
    "payment_details": "Payment details"
  }
}