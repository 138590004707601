{
  "airtableInformer": {
    "airtable_informer": "Airtable",
    "learn": "Scopri"
  },
  "audioInformer": {
    "audio_informer": "Audio",
    "learn": "Scopri"
  },
  "baseInformer": {
    "learn": "Scopri"
  },
  "bulkcsvInformer": {
    "csv_links_informer": "Link CSV",
    "learn": "Scopri"
  },
  "chromeextensionInformer": {
    "chrome_extension_informer": "Estensione Chrome",
    "select_project": "Seleziona progetto",
    "how_to_add_extension": "Come aggiungere l'estensione Chrome e iniziare a usarla.",
    "visit_the_chrome_web_store": "Visita il Chrome Web Store e cerca",
    "extension": "Estensione",
    "click": "Clicca",
    "add_to_chrome": "Aggiungi a Chrome",
    "authorize_yourself_in_the_extension": "Autorizzati nell'estensione, premi Accedi",
    "if_you_dont_have_any_existing_projects": "Se non hai progetti esistenti, puoi crearne uno. Clicca sul link fornito a OmniMind e crea il tuo progetto.",
    "to_add_the_entire_web_page": "Per aggiungere l'intera pagina web, seleziona il progetto desiderato e clicca sul pulsante \"Processa\" nell'estensione",
    "to_add_selected_text": "Per aggiungere il testo selezionato, evidenzia il testo sulla pagina web, fai clic destro e scegli \"Aggiungi testo selezionato al progetto OmniMind\" dal menu contestuale.",
    "the_content_you_added_will_be_stored": "Il contenuto che hai aggiunto verrà memorizzato nel progetto scelto. Ora puoi utilizzare queste informazioni durante l'interazione con il modello di intelligenza artificiale."
  },
  "confluenceInformer": {
    "confluence_informer": "Confluence",
    "learn": "Scopri"
  },
  "csvInformer": {
    "csv_informer": "CSV",
    "learn": "Scopri"
  },
  "excelInformer": {
    "excel_informer": "Excel",
    "learn": "Scopri"
  },
  "fileInformer": {
    "file_informer": "File",
    "learn": "Scopri"
  },
  "googleSheetsInformer": {
    "google_sheets_informer": "Google Sheets",
    "learn": "Scopri"
  },
  "notionInformer": {
    "notion_informer": "Notion",
    "learn": "Scopri"
  },
  "pdfOcrInformer": {
    "pdf_ocr_informer": "PDF",
    "learn": "Scopri"
  },
  "singleWebInformer": {
    "single_web_informer": "Single Web",
    "learn": "Scopri"
  },
  "slackIntegrationIndex": {
    "name": "nome",
    "select_project": "Seleziona progetto",
    "how_to_add_omnimind_app": "Come aggiungere l'app Omnimind a Slack Workspace",
    "start_by_copying_your_omnimind_api_key": "Inizia copiando la tua chiave API Omnimind. Ne avrai bisogno per collegare Omnimind a Slack.",
    "make_sure_your_question_is": "Assicurati che la tua domanda sia chiara e concisa per ottenere risposte più accurate. Puoi aggiornare o cambiare il progetto nelle dirette messaggi del bot se desideri utilizzare dati diversi per l'analisi.",
    "copy_your_project_id": "Copia il tuo ID progetto",
    "project_id_was_copied": "L'ID del progetto è stato copiato!",
    "add_omnimind_ai_to_your_slack_workspace": "Aggiungi l'AI Omnimind al tuo Slack Workspace",
    "configure_your_omnimind_app": "Configura l'app Omnimind impostando le credenziali",
    "click_connect_to_omnimind_button": "Clicca sul pulsante \"Connetti a Omnimind\"",
    "paste_your_api_key_and_project_id": "Incolla la tua chiave API e l'ID del progetto",
    "start_asking_your_question": "Inizia a fare la tua domanda!"
  },
  "vimeoInformer": {
    "vimeo_informer": "Vimeo",
    "learn": "Scopri"
  },
  "webInformer": {
    "multiple_web_informer": "Multiple Web",
    "learn": "Scopri"
  },
  "whatsappIntegration": {
    "name": "nome",
    "select_project": "Seleziona progetto"
  },
  "wordpressInformer": {
    "wordpress_informer": "Wordpress",
    "learn": "Scopri"
  },
  "youtubeInformer": {
    "youtube_informer": "Youtube",
    "learn": "Scopri"
  },
  "zapierWidget": {
    "name": "nome",
    "select_project": "Seleziona progetto",
    "how_to_add_omnimind_app": "Come aggiungere l'app Omnimind a Zapier",
    "start_by_copying_your_omnimind_api_key": "Inizia copiando la tua chiave API Omnimind. Ne avrai bisogno per collegare Omnimind a Zapier.",
    "make_sure_your_question_is_clear_and_concise": "Assicurati che la tua domanda sia chiara e concisa per ottenere risposte più accurate. Puoi aggiornare o cambiare il progetto nelle impostazioni dell'azione se desideri utilizzare dati diversi per l'analisi.",
    "go_to_the_zapier_application_where_you_d_like_to": "Vai all'applicazione Zapier in cui desideri utilizzare Omnimind.",
    "create_a_new_action_step": "Crea un nuovo passaggio di azione e cerca l'integrazione Omnimind.",
    "in_the_omnimind_app": "Nell'app Omnimind, seleziona l'azione chiamata \"Omni Ask\".",
    "log_into_your_omnimind_account": "Accedi al tuo account Omnimind utilizzando la chiave API che hai copiato in precedenza.",
    "in_the_action_tab": "Nella scheda Azione, fornisci le informazioni necessarie.",
    "from_the_project_dropdown": "Dal menu a discesa Progetto, scegli il progetto a cui desideri connetterti.",
    "enter_the_question_you_d_like_to": "Inserisci la domanda che desideri inviare a Omnimind.",
    "before_finalizing": "Prima di finalizzare, testa la tua azione per assicurarti che tutto funzioni correttamente.",
    "in_zapier": "In Zapier, seleziona il campo \"Risposta\" dall'app Omnimind. Puoi utilizzare questa risposta nei passaggi successivi."
  }
}