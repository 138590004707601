{
  "pricingItem": {
    "month": "mes",
    "up_to_todo_calculate_pricing_info": "Hasta 'TODO: Calcular información de precios'",
    "trial_days": "días de prueba",
    "this_includes:": "Esto incluye",
    "subscribe:": "Suscribirse"
  },
  "pricingTable": {
    "subscriptions": "Suscripciones",
    "to_access_the_widget_you_need": "Para acceder al widget, debes elegir uno de los planes de suscripción ofrecidos",
    "oops": "¡Ups!",
    "there_no_available_packages": "No hay paquetes disponibles para este enlace"
  }
}