import { gql } from '@apollo/client/core';

export const USER_TO_PROJECTS_SUBSCRIPTION = gql`
  subscription userToProjectSubscription($project_id: uuid!) {
    user_to_projects(where: { project_id: { _eq: $project_id } }) {
      id
      user {
        id
        email
        emailVerified
      }
      scopes
      is_invited
    }
  }
`;
