{
  "likeDislikeAnswer": {
    "please_provide_additional_feedback": "Por favor, forneça um feedback adicional",
    "enter_your_username": "Digite seu nome",
    "enter_your_email": "Digite seu e-mail",
    "what_was_the_issue_with_the_response": "Qual foi o problema com a resposta?",
    "email_and_username_are_required": "E-mail e nome são obrigatórios para enviar feedback",
    "thank_you_sincerely_for_your_feedback": "Agradecemos sinceramente pelo seu feedback. É muito valorizado.",
    "sorry_but_we_encountered": "Desculpe, mas encontramos um problema ao tentar enviar seu feedback. Por favor, tente novamente.",
    "grateful_for_the_thumbs_up": "Grato pelo polegar para cima!"
  },
  "likeDislikeAnswerModal": {
    "submit": "Enviar"
  },
  "likeDislikeAnswerModalHeader": {
    "didnt_like_the_response": "Não gostou da resposta"
  }
}