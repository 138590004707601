<template>
  <!--  BEGIN MAIN CONTAINER  -->
  <div class="relative">
    <!-- sidebar menu overlay -->
    <div
      class="fixed inset-0 z-50 bg-[black]/60 lg:hidden"
      :class="{ hidden: !store.sidebar }"
      @click="store.toggleSidebar"
    ></div>

    <!-- screen loader -->
    <div
      v-show="!user"
      class="screen_loader animate__animated fixed inset-0 z-[60] grid place-content-center bg-white-bgColor dark:bg-dark-bgColor"
    >
      <svg
        width="64"
        height="64"
        viewBox="0 0 135 135"
        xmlns="http://www.w3.org/2000/svg"
        fill="#4361ee"
      >
        <path
          d="M67.447 58c5.523 0 10-4.477 10-10s-4.477-10-10-10-10 4.477-10 10 4.477 10 10 10zm9.448 9.447c0 5.523 4.477 10 10 10 5.522 0 10-4.477 10-10s-4.478-10-10-10c-5.523 0-10 4.477-10 10zm-9.448 9.448c-5.523 0-10 4.477-10 10 0 5.522 4.477 10 10 10s10-4.478 10-10c0-5.523-4.477-10-10-10zM58 67.447c0-5.523-4.477-10-10-10s-10 4.477-10 10 4.477 10 10 10 10-4.477 10-10z"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 67 67"
            to="-360 67 67"
            dur="2.5s"
            repeatCount="indefinite"
          />
        </path>
        <path
          d="M28.19 40.31c6.627 0 12-5.374 12-12 0-6.628-5.373-12-12-12-6.628 0-12 5.372-12 12 0 6.626 5.372 12 12 12zm30.72-19.825c4.686 4.687 12.284 4.687 16.97 0 4.686-4.686 4.686-12.284 0-16.97-4.686-4.687-12.284-4.687-16.97 0-4.687 4.686-4.687 12.284 0 16.97zm35.74 7.705c0 6.627 5.37 12 12 12 6.626 0 12-5.373 12-12 0-6.628-5.374-12-12-12-6.63 0-12 5.372-12 12zm19.822 30.72c-4.686 4.686-4.686 12.284 0 16.97 4.687 4.686 12.285 4.686 16.97 0 4.687-4.686 4.687-12.284 0-16.97-4.685-4.687-12.283-4.687-16.97 0zm-7.704 35.74c-6.627 0-12 5.37-12 12 0 6.626 5.373 12 12 12s12-5.374 12-12c0-6.63-5.373-12-12-12zm-30.72 19.822c-4.686-4.686-12.284-4.686-16.97 0-4.686 4.687-4.686 12.285 0 16.97 4.686 4.687 12.284 4.687 16.97 0 4.687-4.685 4.687-12.283 0-16.97zm-35.74-7.704c0-6.627-5.372-12-12-12-6.626 0-12 5.373-12 12s5.374 12 12 12c6.628 0 12-5.373 12-12zm-19.823-30.72c4.687-4.686 4.687-12.284 0-16.97-4.686-4.686-12.284-4.686-16.97 0-4.687 4.686-4.687 12.284 0 16.97 4.686 4.687 12.284 4.687 16.97 0z"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 67 67"
            to="360 67 67"
            dur="8s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
    </div>

    <div
      class="main-container text-black dark:text-white-dark"
      :class="[store.navbar]"
      :style="{ minHeight: `calc(${vh} * 100)` }"
    >
      <!--  BEGIN SIDEBAR  -->
      <Sidebar v-if="user && !isPortalRoute" :user="user" />
      <PortalSidebar v-else-if="user" :user="user" />
      <!--  END SIDEBAR  -->

      <!--  BEGIN CONTENT AREA  -->
      <div
        class="main-content grid grid-rows-[min-content_1fr]"
        :class="{
          '!block': isWidgetSettingsLayout,
        }"
        :style="{ minHeight: `calc(${vh} * 100)` }"
      >
        <!--  BEGIN TOP NAVBAR  -->
        <Header v-if="!isPortalRoute" />
        <PortalHeader v-else />
        <!--  END TOP NAVBAR  -->
        <div
          class="animation bg-[var(--omni-color-background)]"
          :class="[
            isBasicProjectRoute || isAdvise || isPortalRoute ? 'p-6 pb-0' : 'p-6',
            isVisibleScroll || isWidgetSettingsLayout ? 'overflow-x-visible' : 'overflow-x-hidden',
          ]"
        >
          <template v-if="isPortalRoute">
            <div v-if="portalStore?.portalLoading" class="mx-auto mt-16 h-8 w-8">
              <Loader class="h-10 w-10" />
            </div>
            <router-view v-else></router-view>
          </template>

          <router-view v-else></router-view>
        </div>
      </div>
      <!--  END CONTENT AREA  -->
    </div>
  </div>
</template>
<script setup lang="ts">
import type { ComputedRef } from 'vue';
import { onMounted, watch, computed, inject } from 'vue';
import { useUserData } from '@nhost/vue';
import appSetting from '@/app-setting';
import { useAppStore } from '@/stores';
import { useRouter } from 'vue-router';
import { useTimeZone } from '@/hooks/useTimeZone';
import { useViewportDimensions } from '@/composables/useViewportDimensions';
import Sidebar from '@components/layout/sidebar/Sidebar.vue';
import Header from '@components/layout/Header.vue';
import PortalSidebar from '@components/layout/sidebar/portal/PortalSidebar.vue';
import PortalHeader from '@components/layout/PortalHeader.vue';
import { usePortalsStore } from '@/stores/portalsStore';
import Loader from '@components/Loader.vue';

const store = useAppStore();
const portalStore = usePortalsStore();
const user = useUserData();
const router = useRouter();
const { setTimeZone } = useTimeZone();
const { vh } = useViewportDimensions();

const isBasicProjectRoute = computed(() => {
  const { path } = router.currentRoute.value;

  return path.includes('info') || path.includes('resources') || path.includes('projects') || false;
});

const isPortalRoute = inject<ComputedRef<boolean>>('is-portal-route');

const isAdvise = computed(() => {
  const { path } = router.currentRoute.value;

  return path.includes('advise');
});

const isWidgetSettingsLayout = computed(() => {
  const { fullPath } = router.currentRoute.value;

  return (
    fullPath.includes('settings?layout=behaviour') ||
    fullPath.includes('settings?layout=appearance') ||
    false
  );
});

const isVisibleScroll = computed(() => {
  const { fullPath } = router.currentRoute.value;

  return (
    fullPath.includes('widgetType') ||
    fullPath.includes('create') ||
    fullPath.includes('resources') ||
    false
  );
});

watch(user, (newVal) => {
  if (newVal) {
    store.setUser(newVal);
    setTimeZone(newVal.id);
  }
});

watch(
  () => store.sidebar,
  (value) => {
    if (value) {
      document.body.classList.add('overflow-hidden', 'sm:overflow-visible');

      return;
    }

    document.body.classList.remove('overflow-hidden', 'sm:overflow-visible');
  },
);

onMounted(() => {
  const eleanimation: any = document.querySelector('.animation');
  eleanimation.addEventListener('animationend', function () {
    appSetting.changeAnimation('remove');
  });
  store.toggleMainLoader();
});
</script>
