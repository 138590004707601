{
  "tableDialogSQL": {
    "the_sql_query_for_your_prompt": "SQL-запит для вашого запиту"
  },
  "tableWidget": {
    "ask_your_questions_to_get_a_data": "Задавайте свої запитання, щоб отримати дані.",
    "show_sql": "Показати SQL",
    "export_to_csv": "Експорт в CSV",
    "failed_to_load_settings": "Не вдалося завантажити налаштування",
    "something_went_wrong": "Щось пішло не так"
  },
  "tableWidgetTable": {
    "no_data": "Немає даних"
  }
}