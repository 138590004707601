{
  "settingsAppearance": {
    "appearance": "Appearance",
    "bot_name": "Bot name",
    "brand_name": "Brand name",
    "enter_custom_bot_name": "Enter custom bot name",
    "enter_custom_brand_name": "Enter custom bot name",
    "change_the_widget_color_style": "Change the widget color style",
    "change_the_header_font_size_px": "Change the header font size (px)",
    "change_the_body_font_size_px": "Font size",
    "hide_powered_by_label": "Hide 'Powered by' label",
    "show_answer_sources_option": "Show answer sources option",
    "hide_the_full_site_overlay": "The full site overlay is a feature that allows you to create a focus point on your website by dimming the background and displaying the widget.",
    "hide_overlay": "Hide overlay",
    "change_placeholder_text_of_the_form_s_input": "Change placeholder text of the form's input",
    "enter_custom_placeholder_text": "Enter custom placeholder text...",
    "submit_button_s_text": "Submit button's text",
    "enter_text_for_submit_button": "Enter text for submit button...",
    "failed_to_upload_branding_logo": "Failed to upload branding logo",
    "change_font_family": "Change font family",
    "show_on_mobile": "Show on mobile",
    "show_on_tablet": "Show on tablet"
  }
}
