{
  "dataExtractorWidget": {
    "data_extractor_widget": "Data Extractor",
    "language": "Language",
    "add_prompt": "Add prompt",
    "reset_table": "Reset table",
    "active": "Active",
    "resource": "Resource",
    "resource_already_exists": "Resource already exists",
    "something_went_awry": "Something went awry",
    "loading": "Loading",
    "error": "Error",
    "error_occured_while_creating_pdf": "Error occured while creating PDF",
    "the_prompt_has_been_successfully_saved": "The prompt has been successfully saved to the Catalog.",
    "contact_owner_to_grant_permission": "Please contact the project owner to grant you permission to add resources."
  },
  "dataExtractorWidgetActions": {
    "scan": "Scan",
    "export_pdf": "Export pdf",
    "export_csv": "Export csv"
  },
  "dataExtractorWidgetAddPromptModal": {
    "add_edit_prompt": "Add/Edit Prompt",
    "add_save": "Add/Save",
    "add_prompt_to_catalog": "Add prompt to catalog",
    "column": "Column",
    "locations": "Locations",
    "open_prompt_catalog": "Open prompt catalog",
    "prompt": "Prompt",
    "common_locations_of_clients": "Common locations of clients they do business with"
  },
  "dataExtractorWidgetAddPromptSupplement": {
    "these_prompts_will_be_applied": "These prompts will be applied to each resource",
    "by_bullet_points": "by bullet points",
    "as_a_quote": "as a quote",
    "in_a_few_words": "in a few words"
  },
  "dataExtractorWidgetAddResourceModal": {
    "add_edit_resource": "Add/Edit Resource",
    "submit": "Submit",
    "enter_your_resource": "Enter your resource",
    "this_resource_is_already_exist": "This resource is already exist!"
  },
  "dataExtractorWidgetDeleteResourceModal": {
    "delete_this_resource": "Delete this resource?",
    "delete_resource": "Delete resource",
    "resource_will_be_deleted": "Resource will be deleted from the table.",
    "delete_resource_from_project": "Delete resource from project"
  },
  "dataExtractorWidgetPdfModal": {
    "create_pdf": "Create PDF",
    "ok": "Ok",
    "wait_for_a_few_seconds_please": "Wait for a few seconds please..."
  },
  "dataExtractorWidgetPromptCatalog": {
    "prompt_catalog": "Prompt Catalog",
    "delete_column": "Delete column from catalog",
    "delete_prompt": "Delete prompt from catalog"
  },
  "dataExtractorWidgetTable": {
    "no_resources_so_far": "No resources so far"
  },
  "dataExtractorWidgetTableHead": {
    "resource": "Resource",
    "refresh_values": "Refresh values",
    "edit_prompt": "Edit prompt",
    "delete_prompt": "Delete prompt from table"
  }
}
