{
  "knowledgeChunksModal": {
    "chunks": "Частинки",
    "save": "Зберегти",
    "chunks_selected": "вибрані частинки",
    "no_data_so_far": "Поки немає даних.",
    "title": "Заголовок",
    "page": "Сторінка",
    "size": "Розмір",
    "last_modified": "Остання зміна",
    "text": "Текст",
    "actions": "Дії",
    "no_chunks_so_far": "Поки немає частинок",
    "all_done_your_items_were_removed_successfully": "Все готово! Ваші елементи успішно видалено."
  },
  "knowledgeChunksModalPagination": {
    "prev": "Попередня",
    "next": "Наступна"
  },
  "knowledgeChunksModalSearch": {
    "quick_search": "Швидкий пошук",
    "search": "Пошук"
  },
  "knowledgeFilterModal": {
    "filter": "Фільтр",
    "apply": "Застосувати",
    "reset": "Скинути",
    "by_name": "За назвою",
    "by_title": "За заголовком",
    "by_period": "За періодом",
    "search_for_resource_name": "Пошук за назвою ресурсу...",
    "search_for_resource_title": "Пошук за заголовком ресурсу...",
    "last_year": "Минулий рік",
    "quarter": "Квартал",
    "month": "Місяць",
    "week": "Тиждень",
    "day": "День"
  }
}