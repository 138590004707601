{
  "billingOverview": {
    "billing_overview": "Billing overview",
    "expenses": "Expenses"
  },
  "billingOverviewItem": {
    "used": "Used",
    "remaining": "remaining",
    "used_out_of": "Used {formattedCurrent_value} out of {formattedTotal_value}.",
    "money": "Money"
  },
  "cardList": {
    "card_details": "Card Details",
    "expires_on": "Expires on",
    "primary": "Primary"
  },
  "paymentHistory": {
    "payment_history": "Payment History",
    "view_invoice": "View Invoice",
    "download_invoice": "Download Invoice",
    "march": "March",
    "february": "February",
    "january": "January",
    "pro_membership": "Pro Membership"
  },
  "projectList": {
    "projects": "Projects"
  },
  "proPlanInfo": {
    "pro_plan": "Pro Plan",
    "renew_now": "Renew Now",
    "monthly_visitors": "{quantity} Monthly Visitors",
    "unlimited_reports": "Unlimited Reports",
    "years_data_storage": "{quantity} Years Data Storage",
    "days_left": "Days Left",
    "month": "month"
  },
  "userInfo": {
    "profile": "Profile",
    "no_company_name": "no company name",
    "no_phone_number": "no phone number",
    "no_timezone": "no timezone",
    "remove_your_account": "Remove your account"
  }
}