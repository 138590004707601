{
  "auth": {
    "changePassword": {
      "change_password": "Змінити пароль",
      "enter_password": "Введіть пароль",
      "new_password": "Новий пароль",
      "password": "Пароль",
      "old_password": "Старий пароль",
      "cancel": "Скасувати",
      "password_confirmation": "Підтвердження нового пароля",
      "success": "Пароль успішно змінено"
    },
    "changePasswordForm": {
      "new_password": "Новий пароль",
      "enter_password": "Введіть пароль",
      "password_confirmation": "Підтвердження пароля",
      "change_password": "Змінити пароль"
    },
    "portalProvider": {
      "portal": "Портал",
      "portal_not_found": "Портал не знайдено",
      "is_not_currently_active": "неактивний наразі",
      "you_are_currently_blocked": "Ви наразі заблоковані"
    },
    "promocode": {
      "something_went_wrong": "Щось пішло не так",
      "enjoy_benefits": "Отримайте переваги за допомогою коду викупу!",
      "redemption_code": "Код викупу",
      "enter_redemption_code": "Промокод",
      "verifying": "Перевірка...",
      "verify": "Перевірити",
      "create_account_in_omnimind": "Створіть обліковий запис в Omnimind AI, щоб отримати придбані кредити",
      "this_promo_code_does_not_seem_to_exist": "Здається, цей промокод не існує. Будь ласка, перевірте наявність помилок і спробуйте ще раз.",
      "this_promo_code_has_expired": "Цей промокод закінчився. Спробуйте інший код."
    },
    "resetPasswordForm": {
      "reset_password": "Скинути пароль",
      "enter_your_email_for_resetting_password": "Введіть свою електронну пошту для скидання пароля",
      "enter_email": "Введіть електронну пошту",
      "please_check_your_mailbox": "Будь ласка, перевірте свою поштову скриньку \n та перейдіть за посиланням інструкції, щоб завершити зміну пароля.",
      "if_you_can_not_find_it": "Якщо ви не можете знайти його у своїй скринці, перевірте свою",
      "spam_folder": "папку зі спамом",
      "as_well": "також."
    },
    "signOut": {
      "sign_out": "Вийти"
    }
  }
}