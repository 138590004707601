{
  "cardEditorFooter": {
    "cancel": "Cancel",
    "save": "Save"
  },
  "cardFillBlank": {
    "correct_answer": "Correct answer",
    "explanation": "Explanation"
  },
  "cardFillBlankEditor": {
    "question": "Question",
    "enter_question": "Enter question",
    "correct_answer": "Correct answer",
    "enter_correct_answer": "Enter correct answer",
    "explanation_optional": "Explanation (optional)",
    "enter_explanation_for_the_correct_answer": "Enter explanation for the correct answer",
    "instructions_optional": "Instructions (optional)",
    "enter_instructions_for_answering_the_question": "Enter instructions for answering the question"
  },
  "cardMatching": {
    "correct_answer": "Correct answer",
    "explanation": "Explanation"
  },
  "cardMatchingEditor": {
    "question": "Question",
    "enter_question": "Enter question",
    "answers": "Answers",
    "list_the_items_in_the_correct_sequence": "List the items in the correct sequence. They will be randomized for the activity.",
    "enter_answer_option": "Enter answer option",
    "add_more": "Add More",
    "explanation_optional": "Explanation (optional)",
    "enter_explanation_for_the_correct_answer": "Enter explanation for the correct answer",
    "instructions_optional": "Instructions (optional)",
    "enter_instructions_for_answering_the_question": "Enter instructions for answering the question"
  },
  "cardMultipleAnswers": {
    "correct_answers": "Correct answers",
    "explanation": "Explanation"
  },
  "cardMultipleAnswersEditor": {
    "question": "Question",
    "enter_question": "Enter question",
    "answer": "Answer",
    "enter_answer_option": "Enter answer option",
    "add_more": "Add More",
    "correct_answers": "Correct answers",
    "explanation_optional": "Explanation (optional)",
    "enter_explanation_for_the_correct_answer": "Enter explanation for the correct answer",
    "instructions_optional": "Instructions (optional)",
    "enter_instructions_for_answering_the_question": "Enter instructions for answering the question",
    "only_unique_answer_values_allowed": "Only unique answer values allowed!"
  },
  "cardMultipleChoice": {
    "correct_answer": "Correct answer",
    "explanation": "Explanation"
  },
  "cardMultipleChoiceEditor": {
    "question": "Question",
    "enter_question": "Enter question",
    "answer": "Answer",
    "enter_answer_option": "Enter answer option",
    "add_more": "Add More",
    "correct_answer": "Correct answer",
    "enter_correct_answer": "Enter correct answer",
    "explanation_optional": "Explanation (optional)",
    "enter_explanation_for_the_correct_answer": "Enter explanation for the correct answer",
    "instructions_optional": "Instructions (optional)",
    "enter_instructions_for_answering_the_question": "Enter instructions for answering the question",
    "only_unique_answer_values_allowed": "Only unique answer values allowed!"
  },
  "cardSequence": {
    "correct_sequence": "Correct sequence",
    "explanation": "Explanation"
  },
  "cardSequenceEditor": {
    "question": "Question",
    "enter_question": "Enter question",
    "correct_answers": "Correct answers",
    "list_the_items_in_the_correct_sequence": "List the items in the correct sequence. They will be randomized for the activity.",
    "enter_answer_option": "Enter answer option",
    "add_more": "Add More",
    "explanation_optional": "Explanation (optional)",
    "enter_explanation_for_the_correct_answer": "Enter explanation for the correct answer",
    "instructions_optional": "Instructions (optional)",
    "enter_instructions_for_answering_the_question": "Enter instructions for answering the question",
    "empty_values_are_not_allowed": "Empty values are not allowed!"
  },
  "cardShortAnswer": {
    "correct_answer": "Correct answer",
    "explanation": "Explanation"
  },
  "cardShortAnswerEditor": {
    "question": "Question",
    "enter_question": "Enter question",
    "correct_answer": "Correct answer",
    "enter_correct_answer": "Enter correct answer",
    "explanation_optional": "Explanation (optional)",
    "enter_explanation_for_the_correct_answer": "Enter explanation for the correct answer",
    "instructions_optional": "Instructions (optional)",
    "enter_instructions_for_answering_the_question": "Enter instructions for answering the question"
  },
  "cardTrueFalse": {
    "true": "True",
    "false": "False",
    "correct_answer": "Correct answer",
    "explanation": "Explanation"
  },
  "cardTrueFalseEditor": {
    "question": "Question",
    "enter_question": "Enter question",
    "correct_answer": "Correct answer",
    "explanation_optional": "Explanation (optional)",
    "enter_explanation_for_the_correct_answer": "Enter explanation for the correct answer",
    "instructions_optional": "Instructions (optional)",
    "enter_instructions_for_answering_the_question": "Enter instructions for answering the question",
    "true": "True",
    "false": "False"
  }
}