{
  "tenantPortalPortalSection": {
    "completed": "Abgeschlossen",
    "non_completed": "Nicht abgeschlossen"
  },
  "tenantPortalTabs": {
    "settings": "Einstellungen",
    "projects": "Projekte",
    "packages": "Pakete",
    "users": "Benutzer",
    "transactions": "Transaktionen"
  }
}