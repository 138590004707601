{
  "portalPackageItem": {
    "name_is_not_provided": "No se proporciona el nombre",
    "click_to_edit_the_package": "Haz clic para editar el paquete",
    "click_to_delete_the_package": "Haz clic para eliminar el paquete",
    "package_name": "Nombre del paquete",
    "your_package_name": "Tu nombre de paquete",
    "description": "Descripción",
    "your_package_description": "Tu descripción de paquete",
    "model": "Modelo",
    "package_price": "Precio del paquete",
    "subscription_cost_for_the_package": "Costo de suscripción para el paquete",
    "cost_per_symbols": "Costo por {symbols_quantity} símbolos",
    "cost_for_characters": "Costo por {characters_quantity} caracteres al consultar OpenAI (diseñado para ayudar a evitar gastos excesivos para el usuario). En promedio, una solicitud tiene {average} caracteres",
    "approx_number_of_requests": "número aproximado de solicitudes",
    "trial_days": "Días de prueba",
    "links": "Enlaces",
    "create_portal_link": "Crear enlace del portal",
    "delete_this_project": "¿Eliminar este proyecto?",
    "delete_project": "Eliminar proyecto",
    "package_will_be_deleted": "El paquete será eliminado. Esto no se puede deshacer",
    "the_item_has_been_successfully_deleted": "El elemento se ha eliminado correctamente."
  },
  "portalPackages": {
    "no_packages_so_far": "Hasta ahora no hay paquetes",
    "apply": "Aplicar",
    "stripe_account_is_not_configured": "La cuenta de Stripe no está configurada",
    "packages_successfully_saved": "Paquetes guardados correctamente"
  }
}