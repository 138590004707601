{
  "pagesProjectsEmpty": {
    "empty_project": "Leeres Projekt",
    "is_not_complete_yet": "ist noch nicht vollständig, da es kein Wissen enthält. Sie können entweder Wissen hochladen oder das Projekt einfach löschen.",
    "add_knowledge": "Wissen hinzufügen",
    "this_project": "Dieses Projekt"
  },
  "pagesProjectsIndex": {
    "redirecting": "Weiterleitung..."
  }
}