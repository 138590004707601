{
  "flashcardsWidget": {
    "export_to_quizlet": "Exportieren nach Quizlet",
    "export_to_cram": "Exportieren nach Cram",
    "how_to_import_into_cram": "Wie man in Cram importiert",
    "begin_by_visiting_the_cram": "Beginnen Sie, indem Sie die Cram-Website besuchen und die Option \"Flashcards erstellen\" auswählen. Wählen Sie dann \"Ein Karteikartenset importieren\". Kopieren Sie Ihre Daten und stellen Sie sicher, dass jedes Fachwort und seine Definition durch einen",
    "and_different_cards_are": "und verschiedene Karten durch ein",
    "new_line": "Neue Zeile",
    "paste_this_into_the": "Fügen Sie dies in das Eingabefeld mit der Bezeichnung \"DATEN KOPIEREN UND EINFÜGEN:\" ein und klicken Sie auf \"Daten importieren\". Viel Spaß bei Ihrer Lernsession!",
    "oops_it_seems_youve_reached_your": "Hoppla! Es scheint, dass Sie Ihr Nachrichtenlimit erreicht haben. Um fortzufahren, passen Sie bitte Ihr Limit in den Einstellungen an.",
    "question": "Frage",
    "tab": "Tab",
    "my_answer": "Meine Antwort",
    "correct_answer": "Richtige Antwort",
    "evaluate_my_answer": "Bewerten Sie meine Antwort auf einer Skala von 1 bis 5 basierend auf ihrer Genauigkeit und Vollständigkeit im Vergleich zur richtigen Antwort unter Berücksichtigung des Kontexts { context }. Verwenden Sie die folgenden Richtlinien zur Bewertung: Wenn die Antwort völlig falsch oder irrelevant für die Frage ist und ein Mangel an Verständnis des Themas oder der Frage zeigt, bewerten Sie mit \"1\". Wenn die Antwort die Frage anspricht, aber wesentliche Ungenauigkeiten oder Auslassungen aufweist und ein gewisses Verständnis, aber auch erhebliche Missverständnisse oder Wissenslücken zeigt, bewerten Sie mit \"2\". Wenn die Antwort weitgehend korrekt ist, aber unvollständig oder geringfügig ungenau und die Antwort ein faires Verständnis des Themas zeigt, aber Detailgenauigkeit oder Präzision fehlt, bewerten Sie mit \"3\". Wenn die Antwort weitgehend korrekt ist und die meisten Aspekte der Frage abdeckt, aber kleine Details oder Nuancen fehlen können, aber das Gesamtverständnis gut ist, bewerten Sie mit \"4\". Wenn die Antwort vollständig korrekt ist und eine umfassende Antwort auf die Frage liefert, bewerten Sie mit \"5\". Verwenden Sie das Format: \"Zahl;Erklärung\" für Ihre Antwort.",
    "something_went_awry": "Etwas ist schiefgelaufen!",
    "your_rate_limit_exceeded": "Generieren Sie in {formLanguage} Sprache {formAmount} Fragen aus { context } in einem GÜLTIGEN JSON-FORMAT. Verwenden Sie das Muster",
    "answers_should_only_contain_the_essential": "Antworten sollten nur die wesentlichen Schlüsselbegriffe oder Phrasen enthalten, die direkt relevant für die Frage sind, ohne auszuschweifen.",
    "we_ve_found_parsed_length_questions": "Wir haben bisher {parsedLength} Fragen gefunden. Durch Hinzufügen weiteren Inhalts können wir möglicherweise noch mehr finden!"
  },
  "flashcardsWidgetActions": {
    "view_all_questions": "Alle Fragen anzeigen",
    "delete_card": "Karte löschen",
    "edit_card": "Karte bearbeiten",
    "export_to_quizlet": "Exportieren nach Quizlet",
    "export_to_anki": "Exportieren nach Anki",
    "export_to_brainscape": "Exportieren nach Brainscape",
    "export_to_cram": "Exportieren nach Cram",
    "regenerate_cards": "Karten neu generieren",
    "clear_all_cards": "Alle Karten löschen"
  },
  "flashcardsWidgetFeedback": {
    "feedback": "Feedback"
  },
  "flashcardsWidgetForm": {
    "select_the_number_and_the_language": "Wählen Sie die Anzahl und die Sprache der Karteikarten aus",
    "cards_number": "Anzahl der Karten",
    "enter_quantity": "Menge eingeben",
    "language": "Sprache",
    "generate": "Generieren",
    "original": "Original"
  },
  "flashcardsWidgetNavigation": {
    "back": "Zurück",
    "step_and_length_of_questions": "{currentStep} von {questionsLength} Fragen",
    "next": "Weiter",
    "skip": "Überspringen"
  },
  "flashcardsWidgetQuestion": {
    "question": "Frage",
    "enter_your_answer_here": "Geben Sie hier Ihre Antwort ein...",
    "answer": "Antwort",
    "your_answer": "Ihre Antwort",
    "correct_answer": "Richtige Antwort"
  },
  "flashcardsWidgetQuestionEditor": {
    "edit_card": "Karte bearbeiten",
    "save": "Speichern",
    "question": "Frage",
    "answer": "Antwort"
  },
  "flashcardsWidgetQuestionFooter": {
    "check_my_answer": "Antwort überprüfen",
    "hide_answer": "Antwort ausblenden",
    "i_want_to_answer_it_by_myself": "Ich möchte selbst antworten",
    "show_answer": "Antwort anzeigen"
  },
  "flashcardsWidgetQuestionsList": {
    "looks_like_it_s_pretty_empty_here": "Es sieht ziemlich leer aus hier!",
    "start_adding_some_cards_to_see_them_appear": "Fangen Sie an, einige Karten hinzuzufügen, um sie erscheinen zu sehen."
  }
}