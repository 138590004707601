{
  "settingsCustomize": {
    "bot_name": "Nome do Bot",
    "enter_custom_bot_name": "Digite o nome personalizado do bot",
    "welcome_messages": "Mensagens de boas-vindas",
    "enter_messages_separated_by_double_enter": "Digite as mensagens, separadas por duas quebras de linha.",
    "type_messages_double_enter_to_separate": "Digite as mensagens, dê duas quebras de linha para separar.",
    "add_a_custom_welcome_message_interval_seconds": "Adicione um intervalo personalizado para mensagens de boas-vindas (em segundos)",
    "enter_welcome_message_interval": "Digite o intervalo para mensagens de boas-vindas...",
    "show_followups_questions": "Mostrar perguntas de acompanhamento",
    "add_a_custom_message_ending_to_every_omni_mind_response": "Adicione uma mensagem personalizada ao final de cada resposta do OmniMind",
    "enter_custom_message": "Digite a mensagem personalizada...",
    "label_for_examples_of_questions": "Rótulo para exemplos de perguntas que você pode fazer ao bot",
    "before_you_start_to_work_with_widget": "Antes de começar a trabalhar com o widget, veja exemplos de perguntas que você pode fazer ao bot",
    "enter_label_for_examples": "Digite o rótulo para exemplos de perguntas que você pode fazer ao bot...",
    "change_placeholder_text_of_the_form_s_input": "Altere o texto do espaço reservado para a entrada do formulário",
    "enter_custom_placeholder_text": "Digite o texto personalizado do espaço reservado...",
    "submit_button_s_text": "Texto do botão de envio",
    "enter_text_for_submit_button": "Digite o texto para o botão de envio...",
    "opening_message": "Mensagem de abertura",
    "enter_custom_opening_message": "Digite a mensagem de abertura personalizada",
    "change_the_widget_color_style": "Altere o estilo de cor do widget",
    "show_examples_of_questions_that_you_can_ask_the_bot_about": "Mostrar exemplos de perguntas que você pode fazer ao bot",
    "default_language": "Idioma padrão",
    "browser_language_auto_detect": "Detecção automática do idioma do navegador",
    "custom_prompt": "Prompt personalizado",
    "using_a_direct_custom_prompt_is_only_necessary": "Usar um prompt personalizado direto é necessário apenas em casos especiais e desabilitará o formulário principal.",
    "change_the_header_font_size": "Altere o tamanho da fonte do cabeçalho",
    "change_the_body_font_size": "Altere o tamanho da fonte do corpo",
    "hide_powered_by_label": "Ocultar rótulo 'Powered by'",
    "show_answer_sources_option": "Mostrar opção de fontes de resposta",
    "failed_to_upload_branding_logo": "Falha ao fazer upload do logotipo de marca"
  },
  "customizeColorInput": {
    "primary": "Primário",
    "background": "Fundo",
    "secondary": "Secundário",
    "stroke": "Traço",
    "accent": "Acento",
    "text": "Texto",
    "body": "Corpo",
    "default_color_scheme": "Esquema de cores padrão"
  },
  "customizeColorModal": {
    "create_the_custom_widget_color_style": "Crie o estilo de cor personalizado do widget",
    "save": "Salvar",
    "the_custom_widget_color_style": "{value} o estilo de cor personalizado do widget",
    "edit": "Editar",
    "create": "Criar",
    "primary": "Primário",
    "background_color": "Cor de fundo",
    "secondary": "Secundário",
    "stroke_color": "Cor do traço",
    "text_color": "Cor do texto",
    "accent_color": "Cor do acento",
    "body_background": "Fundo do corpo",
    "leave_empty_for_transparent_body": "Deixe vazio para fundo transparente",
    "current_color_style_examples_click_to_clone": "Exemplos de estilo de cor atual, clique para clonar",
    "field_is_not_a_valid_hex_color": "O campo não é uma cor hexadecimal válida"
  },
  "customizeColorSchemes": {
    "changes_to_the_color_theme_of_your_widget": "As alterações no tema de cores do seu widget serão refletidas nos modos \"Compartilhar Widget\" e \"Código de Incorporação\".",
    "you_have_reached_the_maximum_allowed_limit": "Você atingiu o limite máximo permitido. Por favor, remova alguns esquemas antes de criar novos.",
    "click_here_to_create_a_new_scheme": "Clique aqui para criar um novo esquema."
  },
  "customizeFileUploader": {
    "upload_custom_avatar_or_logo": "Fazer upload do logotipo de marca",
    "drag_drop_or": "Arraste e solte ou",
    "select_files": "Selecionar arquivos",
    "to_upload": "para fazer upload",
    "error_uploading_file": "Erro ao fazer upload do arquivo"
  },
  "customizeFileUploaderImage": {
    "no_logo": "Sem logotipo"
  },
  "customizeQuestionnaires": {
    "add_custom_questions": "Adicionar perguntas personalizadas",
    "enter_custom_message": "Digite a mensagem personalizada...",
    "regenerate": "Atualizar perguntas"
  }
}