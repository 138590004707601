{
  "tenantPortalDeleteUserModal": {
    "delete_this_user": "¿Eliminar a este usuario?",
    "delete_user": "Eliminar usuario",
    "user_will_be_deleted": "El usuario será eliminado de la lista."
  },
  "tenantPortalInviteModal": {
    "invite_user": "Invitar usuario",
    "apply": "Aplicar",
    "email": "Correo electrónico",
    "promocode": "Código promocional",
    "enter_your_promo_code": "Ingrese su código promocional",
    "message": "Mensaje",
    "enter_message_text": "Ingrese el texto del mensaje...",
    "the_invite_has_been_successfully_sent": "¡La invitación se ha enviado correctamente!",
    "the_error_occurred_while_sending_an_email": "Se produjo un error al enviar un correo electrónico"
  },
  "tenantPortalPortalUsers": {
    "no_users_so_far": "Aún no hay usuarios",
    "invite": "Invitar",
    "email": "Correo electrónico",
    "packages": "Paquetes",
    "status": "Estado",
    "actions": "Acciones"
  },
  "tenantPortalPortalUsersItem": {
    "user_was_deleted": "El usuario fue eliminado"
  },
  "tenantPortalPortalUserModal": {
    "edit_user": "Editar usuario",
    "apply": "Aplicar",
    "email": "Correo electrónico",
    "package": "Paquete",
    "status": "Estado"
  }
}