{
  "agent_page": {
    "agent_settings": "Налаштування агента",
    "select_model": "Виберіть модель",
    "prompt_editor": "Редактор Prompt",
    "prompt_library": "Бібліотека Prompt"
  },
  "select_model": {
    "select_provider": "Виберіть постачальника",
    "select_model": "Виберіть модель"
  },
  "prompt_editor": {
    "write_your_instruction": "Напишіть вашу інструкцію",
    "variables": "Змінні",
    "save_prompt": "Зберегти Prompt",
    "prompt_has_been_updated": "Prompt було оновлено!",
    "model_has_been_updated": "Модель було оновлено!",
    "prompt_view": "Перегляд prompt",
    "edit_prompt": "Редагувати prompt",
    "cancel": "Скасувати"
  },
  "prompt_library": {
    "no_save_prompts": "Немає збережених Prompt",
    "select_a_department_to_see_tasks": "Виберіть відділ, щоб побачити завдання"
  }
}