{
  "youtubeInformer": {
    "please_provide_a_valid_vimeo_video_url": "Per favore, fornisci un URL video Vimeo valido",
    "tracks_are_unavailable_due_to_restrictions": "Le tracce di questo video non sono disponibili a causa di restrizioni",
    "something_went_awry": "Qualcosa è andato storto",
    "no_text_tracks": "Questo video non ha tracce di testo!",
    "start_time_cant_be_greater": "L'ora di inizio non può essere maggiore o uguale all'ora di fine"
  },
  "youtubeInformerForm": {
    "insert_youtube_video_url": "Inserisci l'URL del video di Youtube",
    "fetch_subtitles": "Recupera sottotitoli"
  },
  "youtubeInformerList": {
    "available_tracks": "Tracce disponibili"
  },
  "youtubeInformerPreview": {
    "please_adjust_the_sliders_below": "Per favore, regola gli slider qui sotto per selezionare un intervallo per tagliare i sottotitoli dal tuo video."
  }
}