{
  "marketplaceOption": {
    "purchase": "Comprar",
    "characters": "personagens",
    "informers": "informantes",
    "projects": "projetos",
    "EssentialBoost": "Impulso Essencial",
    "PremiumExpansion": "Expansão Premium",
    "UltimateAdvantage": "Vantagem Suprema",
    "Projects10": "Projetos 10",
    "EssentialBoostDescription": "Eleve sua conta com mais $50, desbloqueando novas possibilidades e aprimorando sua experiência.",
    "PremiumExpansionDescription": "Mais 1000 solicitações de perguntas e respostas",
    "UltimateAdvantageDescription": "Transforme sua conta com mais $500, oferecendo acesso incomparável e funcionalidades supremas.",
    "Projects10Description": "Projetos adicionais"
  }
}