{
  "projectSettingsSecurity": {
    "add": "Hinzufügen",
    "add_new_domain": "Neue Domain hinzufügen",
    "allowed_domains": "Erlaubte Domains",
    "the_value_is_not_a_valid_domain": "Der Wert ist keine gültige Domain",
    "password_is_required": "Passwort ist erforderlich",
    "password_must_be_at_least": "Das Passwort muss mindestens 8 Zeichen lang sein",
    "password_should_be_alphanumeric": "Das Passwort sollte alphanumerisch sein",
    "this_domain_was_added_previously": "Diese Domain wurde zuvor hinzugefügt"
  },
  "projectSettingsSecurityPassword": {
    "password": "Passwort",
    "delete_project_password": "Projektpasswort löschen",
    "delete_the_project_password": "Das Projektpasswort löschen",
    "set_up_password_for_the_project": "Passwort für das Projekt einrichten",
    "anyone_will_be_able_to_use": "Jeder kann das Projekt ohne Passwort verwenden",
    "anyone_who_has_access_to_this_project": "Jeder, der Zugriff auf dieses Projekt hat, muss ein Passwort eingeben"
  }
}