{
  "widgetTypeIndex": {
    "let_s_upload_the_content": "Carguemos el contenido",
    "with": "Con",
    "informers": "Informantes",
    "you_can_upload_information": "puedes cargar información de una variedad de fuentes, como sitios web, documentos PDF, incluso carpetas de Google, y más. Recuerda que siempre puedes volver a esta pantalla para cargar contenido adicional en tu proyecto",
    "upload_information": "Carga información de una variedad de fuentes, como sitios web, documentos PDF, incluso carpetas de Google, y más. Recuerda que siempre puedes volver a esta pantalla para cargar contenido adicional en tu proyecto cuando lo necesites",
    "whenever_you_need": "cuando lo necesites",
    "enter_the_name_of_the_project": "Ingresa el nombre del proyecto",
    "enter_project_name": "Ingresa el nombre del proyecto",
    "this_informer_is_not_available_yet": "Este informante aún no está disponible.",
    "back": "Atrás"
  }
}