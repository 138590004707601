{
  "voiceWidget": {
    "ooops_we_have_t_found": "404 ¡Ups! No hemos encontrado el proyecto",
    "ooops_we_have_t_found_many": "404 ¡Ups! No hemos encontrado los proyectos",
    "ooops_we_have_t_found_one": "404 ¡Ups! No hemos encontrado el proyecto",
    "ooops_we_have_t_found_other": "404 ¡Ups! No hemos encontrado los proyectos",
    "oops_it_seems_youve_reached": "¡Ups! Parece que has alcanzado el límite de mensajes. Para continuar, ajusta tu límite de mensajes en la configuración.",
    "oops_it_seems_youve_reached_many": "¡Ups! Parece que has alcanzado el límite de mensajes. Para continuar, ajusta tu límite de mensajes en la configuración.",
    "oops_it_seems_youve_reached_one": "¡Ups! Parece que has alcanzado el límite de mensajes. Para continuar, ajusta tu límite de mensajes en la configuración.",
    "oops_it_seems_youve_reached_other": "¡Ups! Parece que has alcanzado el límite de mensajes. Para continuar, ajusta tu límite de mensajes en la configuración.",
    "please_ask_the_owner_of": "401 Por favor, solicita al propietario del proyecto que lo haga público",
    "please_ask_the_owner_of_many": "401 Por favor, solicita a los propietarios de los proyectos que los hagan públicos",
    "please_ask_the_owner_of_one": "401 Por favor, solicita al propietario del proyecto que lo haga público",
    "please_ask_the_owner_of_other": "401 Por favor, solicita a los propietarios de los proyectos que los hagan públicos"
  }
}