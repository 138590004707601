{
  "voiceWidget": {
    "ooops_we_have_t_found": "404 Ой, ми не знайшли проект",
    "ooops_we_have_t_found_few": "404 Ой, ми не знайшли проекти",
    "ooops_we_have_t_found_many": "404 Ой, ми не знайшли проектів",
    "ooops_we_have_t_found_one": "404 Ой, ми не знайшли проект",
    "ooops_we_have_t_found_other": "404 Ой, ми не знайшли проектів",
    "oops_it_seems_youve_reached": "Ой! Здається, ви досягли обмеження на кількість повідомлень. Щоб продовжити, будь ласка, змініть обмеження в налаштуваннях.",
    "oops_it_seems_youve_reached_few": "Ой! Здається, ви досягли обмеження на кількість повідомлень",
    "oops_it_seems_youve_reached_many": "Ой! Здається, ви досягли обмеження на кількість повідомлень",
    "oops_it_seems_youve_reached_one": "Ой! Здається, ви досягли обмеження на кількість повідомлень",
    "oops_it_seems_youve_reached_other": "Ой! Здається, ви досягли обмеження на кількість повідомлень",
    "please_ask_the_owner_of": "401 Будь ласка, зверніться до власника проекту, щоб зробити його публічним",
    "please_ask_the_owner_of_few": "401 Будь ласка, зверніться до власників проектів, щоб зробити їх публічними",
    "please_ask_the_owner_of_many": "401 Будь ласка, зверніться до власників проектів, щоб зробити їх публічними",
    "please_ask_the_owner_of_one": "401 Будь ласка, зверніться до власника проекту, щоб зробити його публічним",
    "please_ask_the_owner_of_other": "401 Будь ласка, зверніться до власників проектів, щоб зробити їх публічними"
  }
}