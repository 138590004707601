{
  "baseDropboxFile": {
    "schema": {
      "folder_name": "Nome da pasta do Dropbox",
      "folder_description": "Nome da pasta atual",
      "url_title": "URL do arquivo do Dropbox",
      "url_description": "A URL para o arquivo do Dropbox"
    },
    "schemaUi": {
      "label": "Arquivo do Dropbox",
      "description": "Aqui você pode colar a URL do seu arquivo no Dropbox. Por favor, certifique-se de que o arquivo esteja disponível publicamente, caso contrário você receberá um erro na etapa de processamento",
      "placeholder": "Digite a URL"
    }
  },
  "baseDropboxFolder": {
    "schema": {
      "url_title": "URL da pasta do Dropbox",
      "url_description": "A URL para a pasta do Dropbox"
    },
    "schemaUi": {
      "label": "Pasta do Dropbox",
      "description": "Aqui você pode colar o link para a sua pasta do Dropbox. Por favor, certifique-se de que a pasta, assim como todos os arquivos dentro dela, estejam disponíveis publicamente, caso contrário você receberá um erro na etapa de processamento",
      "placeholder": "Digite a URL"
    }
  },
  "baseGoogleDriveFile": {
    "schema": {
      "folder_name": "Nome da pasta do Gdrive",
      "folder_description": "O nome para a pasta do Gdrive",
      "url_title": "URL do arquivo do Gdrive",
      "url_description": "A URL para o arquivo do Gdrive"
    },
    "schemaUi": {
      "label": "Arquivo do Gdrive",
      "description": "Aqui você pode colar o link para o seu arquivo do Google Drive. Por favor, certifique-se de que o arquivo esteja disponível publicamente, caso contrário você receberá um erro na etapa de processamento",
      "placeholder": "Digite a URL"
    }
  },
  "baseGoogleDriveFolder": {
    "schema": {
      "url_title": "URL da pasta do Gdrive",
      "url_description": "A URL para a pasta do Gdrive"
    },
    "schemaUi": {
      "label": "Pasta do Gdrive",
      "description": "Aqui você pode adicionar o link para a sua pasta do Google Drive. Por favor, certifique-se de que a pasta, assim como todos os arquivos dentro dela, estejam disponíveis publicamente, caso contrário você receberá um erro na etapa de processamento",
      "placeholder": "Digite a URL"
    }
  },
  "baseOneDriveFile": {
    "schema": {
      "parent_title": "Nome da pasta do Gdrive",
      "url_title": "URL do arquivo do OneDrive",
      "url_description": "A URL para o arquivo do OneDrive"
    },
    "schemaUi": {
      "label": "Arquivo do OneDrive",
      "description": "Aqui você pode colar o link para o seu arquivo do OneDrive. Por favor, certifique-se de que o arquivo esteja disponível publicamente, caso contrário você receberá um erro na etapa de processamento",
      "placeholder": "Digite a URL"
    }
  },
  "baseOneDriveFolder": {
    "schema": {
      "url_title": "URL da pasta do OneDrive",
      "url_description": "A URL para a pasta do OneDrive"
    },
    "schemaUi": {
      "label": "Pasta do OneDrive",
      "description": "Aqui você pode adicionar o link para a sua pasta do OneDrive. Por favor, certifique-se de que a pasta, assim como todos os arquivos dentro dela, estejam disponíveis publicamente, caso contrário você receberá um erro na etapa de processamento",
      "placeholder": "Digite a URL"
    }
  },
  "baseXmlSitemap": {
    "schema": {
      "url_title": "URL do XML",
      "url_description": "Digite a URL do seu sitemap"
    },
    "schemaUi": {
      "label": "Sitemap XML",
      "description": "Se você tiver um arquivo de sitemap com todos os links que deseja indexar, você pode definir a URL do arquivo de sitemap",
      "placeholder": "https://exemplo.com/sitemap.xml"
    }
  },
  "baseYoutubeChannel": {
    "schema": {
      "url_title": "URL do canal",
      "url_description": "Por favor, clique em compartilhar no canal do YouTube e copie o link para a caixa de entrada acima",
      "language_title": "O idioma preferido"
    },
    "schemaUi": {
      "label": "Canal do YouTube",
      "description": "Por favor, cole o link para o canal do YouTube que possui as legendas no idioma especificado abaixo. Observação: o informante processa apenas vídeos com legendas, caso contrário você receberá um erro.",
      "placeholder": "Digite a URL",
      "placeholder_2": "pt"
    }
  },
  "baseYoutubePlaylist": {
    "schema": {
      "url_title": "URL da playlist",
      "url_description": "Por favor, clique em compartilhar na playlist do YouTube e copie o link para a caixa de entrada acima",
      "language_title": "O idioma preferido"
    },
    "schemaUi": {
      "label": "Playlist do YouTube",
      "description": "Por favor, cole o link para a playlist do YouTube que possui as legendas no idioma especificado abaixo. Observação: o informante processa apenas vídeos com legendas, caso contrário você receberá um erro.",
      "placeholder": "Digite a URL",
      "placeholder_2": "pt"
    }
  },
  "baseZendesk": {
    "schema": {
      "url_title": "URL do Zendesk",
      "url_description": "Por favor, insira o link do centro de ajuda do Zendesk"
    },
    "schemaUi": {
      "label": "Zendesk",
      "description": "Por favor, cole o link para o centro de ajuda do Zendesk.",
      "placeholder": "Digite a URL"
    }
  },
  "baseText": {
    "schema": {
      "content_title": "Texto",
      "content_description": "Por favor, adicione o texto que você deseja adicionar ao seu projeto",
      "metadata_title": "Metadados adicionais"
    },
    "schemaUi": {
      "label": "Texto",
      "description": "Adicione o texto ao seu projeto",
      "placeholder": "Digite o texto"
    }
  },
  "baseUrl": {
    "schema": {
      "url_title": "URL do arquivo",
      "url_description": "A URL do arquivo que será adicionado ao projeto"
    },
    "schemaUi": {
      "label": "Arquivo URL",
      "description": "Por favor, certifique-se de que o arquivo esteja público na Internet, caso contrário você receberá um erro de autorização",
      "placeholder": "exemplo.com/arquivo.pdf"
    }
  }
}