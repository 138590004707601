{
  "audioInformerInformers": {
    "language": "Idioma",
    "select_files_to_upload": "Selecionar arquivos para enviar",
    "for_advanced_users_only": "Apenas para usuários avançados!",
    "advanced_settings": "Configurações avançadas",
    "your_uploaded_files": "Seus arquivos enviados",
    "file": "Arquivo",
    "was_added_previously": "foi adicionado anteriormente",
    "an_error_occurred_while": "Ocorreu um erro ao enviar um arquivo"
  },
  "baseInformerInformers": {
    "project_does_not_exist_in_data_base_with": "O projeto não existe no banco de dados com"
  },
  "fileInformerInformers": {
    "select_files_to_upload": "Selecionar arquivos para enviar",
    "for_advanced_users_only": "Apenas para usuários avançados!",
    "advanced_settings": "Configurações avançadas",
    "your_uploaded_files": "Seus arquivos enviados",
    "looks_like_your_file": "Parece que seu arquivo",
    "does_not_have_any_text": "não possui nenhum texto que possamos usar diretamente. Não se preocupe! Você pode facilmente",
    "convert_it_into_a_text_filled_pdf": "convertê-lo em um PDF preenchido com texto e enviar com o Informer OCR de PDF",
    "upgrade_your_plan_and": "atualize seu plano e converta-o em um PDF preenchido com texto e envie com o Informer OCR de PDF",
    "file": "Arquivo",
    "was_added_previously": "foi adicionado anteriormente",
    "your": "Seu",
    "has_been_successfully_uploaded": "foi enviado com sucesso",
    "an_error_occurred_while": "Ocorreu um erro ao enviar o arquivo"
  },
  "notionInformerInformers": {
    "your": "Seu",
    "a_secure_integration_secret": "* Um segredo de integração seguro gerado a partir da sua conta do Notion, garantindo uma integração perfeita e segura.",
    "integration_token": "Token de integração",
    "page_url": "URL da página",
    "page_should_be_shared": "* Sua página do Notion deve ser compartilhada com sua integração.",
    "your_uploaded_links": "Seus links enviados:",
    "not_valid_url": "O valor não é um endereço URL válido",
    "add": "Adicionar"
  },
  "pdfOcrFileInformerInformers": {
    "select_files_to_upload": "Selecionar arquivos para enviar",
    "for_advanced_users_only": "Apenas para usuários avançados!",
    "advanced_settings": "Configurações avançadas",
    "your_uploaded_files": "Seus arquivos enviados",
    "great_news_your_file": "Ótimas notícias! Seu arquivo",
    "is_a_straightforward_pdf": "é um PDF simples e não requer processamento OCR especial. Por favor, prossiga e",
    "upload_it_using_our": "envie-o usando nosso Informer de Arquivo",
    "for_a_smoother_experience": "para uma experiência mais tranquila!",
    "file": "Arquivo",
    "was_added_previously": "foi adicionado anteriormente",
    "your": "Seu",
    "has_been_successfully_uploaded": "foi enviado com sucesso",
    "an_error_occurred_while": "Ocorreu um erro ao enviar o arquivo"
  },
  "singleWebInformerInformers": {
    "type_in_your_url_here": "Digite sua URL aqui",
    "preview": "Visualizar",
    "page_content": "Conteúdo da página",
    "preview_content": "Visualizar conteúdo",
    "project_does_not_exist_in_data_base_with": "O projeto não existe no banco de dados com"
  },
  "uploadAdvancedSettingsInformers": {
    "embeddings_provider": "Provedor de embeddings",
    "you_can_specify_the": "Você pode especificar o provedor de embeddings que processará suas informações."
  },
  "webInformerInformers": {
    "paste_your_batch_of_links_here": "Cole seu lote de links aqui",
    "add_urls": "Adicionar URLs",
    "your_uploaded_links": "Seus links enviados:",
    "page_content": "Conteúdo da página",
    "preview_content": "Visualizar conteúdo",
    "must_be_filled_with_a_value": "Deve ser preenchido com um valor.",
    "invalid_urls": "URLs inválidos",
    "project_does_not_exist_in_data_base_with": "O projeto não existe no banco de dados com"
  },
  "wordpressInformerInformers": {
    "import_from_url": "Importar de URL",
    "add": "Adicionar",
    "remove": "Remover",
    "couldn_t_get_retrieve_the_wordpress": "Não foi possível obter o site do WordPress. Você pode tentar usar o Informer de Site Único ou especificar outra URL.",
    "go_to_website_informer": "Ir para o informer de site",
    "select_the_types_of_content": "Selecione os tipos de conteúdo que você está interessado em receber do site."
  }
}