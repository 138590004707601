{
  "mermaidMindmapWidgetActions": {
    "export": "Exportar",
    "click_here": "Clique aqui para abrir os editores de mapa mental",
    "theme": "Tema",
    "select_timezone": "Selecione seu fuso horário",
    "language": "Idioma",
    "create_map": "Criar mapa",
    "regenerate_tooltip": "Clique aqui para regenerar todo o mapa mental. Por favor, note que isso substituirá completamente o mapa atual. Use esta opção apenas se estiver insatisfeito com o mapa existente. Se você deseja fazer ajustes menores, basta clicar no botão 'Editar' em vez disso.",
    "export_svg": "Exportar SVG",
    "export_png": "Exportar PNG"
  },
  "mermaidMindmapWidgetMap": {
    "mermaid_mind_map": "Mapa Mental Mermaid",
    "what_is_mind_map": "O que é um Mapa Mental",
    "a_mermaid_mind_map_is_a_visualization_tool": "Um mapa mental Mermaid é uma ferramenta de visualização criada com a biblioteca Mermaid.js, que permite a organização e representação de informações em um formato hierárquico. Usando uma sintaxe simples semelhante ao markdown, você pode criar mapas mentais que oferecem uma estrutura clara e visual para ideias ou projetos complexos. Este método auxilia significativamente nas fases de brainstorming e planejamento, ajudando a delinear e esclarecer dados ou conceitos complexos de forma eficiente. Além disso, a entrada de texto simples é facilmente editável, facilitando atualizações e adaptações rápidas. Ele pode ser integrado facilmente em várias plataformas, tornando o compartilhamento e a incorporação desses mapas mentais um processo sem complicações.",
    "steps_to_improve_your_mind_map": "Passos para Melhorar seu Mapa Mental",
    "clarify_your_intent": "Esclareça sua Intenção",
    "use_the_custom_prompt_to": "Use o prompt personalizado para especificar seus requisitos ao criar o mapa mental.",
    "declutter_knowledge": "Organize o Conhecimento",
    "navigate_to_the_knowledge_section": "Navegue até a seção \"Conhecimento\" (ao lado das configurações). Exclua partes redundantes e informações desnecessárias para manter o foco e a clareza.",
    "adjust_with_editors": "Ajuste com os Editores",
    "employ_the_map_editor_to_correct": "Utilize o 'Editor de Mapa' para corrigir e ajustar a sintaxe Mermaid. Use o 'Editor de Nós' para adicionar, excluir ou substituir nós no mapa mental para um melhor alinhamento.",
    "by_following_these_steps": "Seguindo esses passos, seu mapa mental será mais organizado, focado e adaptado às suas necessidades.",
    "map_should_highlight_the_key_aspects_and": "O mapa deve destacar os aspectos-chave e as relações hierárquicas do {contexto}. Comece o mapa com um único nó raiz que represente sucintamente o conceito ou tema principal. Desenvolva ramos para explorar subtemas ou conceitos relacionados, fornecendo rótulos claros para cada nó. Mantenha um fluxo claro e lógico entre nós e subnós conectados, usando recuo adequado para indicar hierarquia. Mantenha os nós breves e diretos, garantindo que eles se relacionem diretamente com os nós adjacentes. Na resposta, siga o padrão",
    "with_adequate_spacing_to_demonstrate": "com espaçamento adequado para demonstrar as relações hierárquicas. Sua saída deve ser textual, formatada para ser renderizada corretamente com o Mermaid e sem palavras em maiúsculas ou símbolos especiais, exceto aqueles necessários para a sintaxe Mermaid.",
    "we_are_unable_to_create_a_mindmap": "Não é possível criar um mapa mental com base no conteúdo fornecido. Você pode tentar abrir as configurações do widget e alterá-las para obter melhores resultados!",
    "something_went_awry": "Algo deu errado! Mas tentaremos gerar seu mapa novamente!",
    "please_generate_a_mermaid_mind": "Por favor, gere um mapa mental Mermaid com base no {contexto} em",
    "language": "idioma"
  },
  "mermaidMindmapWidgetMapEditor": {
    "map_editor": "Editor de Mapa",
    "manipulate_the_structure_and": "Manipule a estrutura e o fluxo do seu mapa facilmente ajustando recuos e adicionando novas linhas para criar nós, facilitando uma visualização personalizada e esclarecedora de suas ideias. Prepare-se para refinar e redefinir sua experiência de mapeamento mental, onde cada mudança o aproxima da perfeição."
  },
  "mermaidMindmapWidgetNodesEditor": {
    "nodes_editor": "Editor de Nós",
    "dont_settle_for_nodes_that_dont_resonate": "Não se contente com nós que não ressoam; com o Editor de Nós, você pode regenerar qualquer elemento facilmente para se adequar melhor à sua visão ou adicionar novos nós para expandir suas ideias.",
    "click_here_to_re_generate_this_node": "Clique aqui para regenerar este nó. Certifique-se de fornecer instruções claras.",
    "click_here_to_add_a_brand_new_node": "Clique aqui para adicionar um novo nó. Todos os outros nós permanecerão inalterados. Certifique-se de fornecer instruções claras.",
    "add_node": "Adicionar Nó",
    "add_instructions": "Adicionar instruções",
    "enter_your_hint_here": "Digite sua dica aqui",
    "this_node": "Este nó",
    "and_all_it_s_children_will": "e todos os seus filhos serão renderizados novamente.",
    "you_have_the_option_to": "Você tem a opção de aprimorar seu mapa mental adicionando um novo nó.",
    "feel_free_to_include_any_specific": "Sinta-se à vontade para incluir quaisquer detalhes ou requisitos específicos como uma dica, se necessário."
  }
}