{
  "settingsAppearance": {
    "appearance": "Aspetto",
    "bot_name": "Nome del bot",
    "brand_name": "Nome del marchio",
    "enter_custom_bot_name": "Inserisci il nome personalizzato del bot",
    "enter_custom_brand_name": "Inserisci il nome personalizzato del marchio",
    "change_the_widget_color_style": "Cambia lo stile del colore del widget",
    "change_the_header_font_size_px": "Cambia la dimensione del carattere dell'intestazione (px)",
    "change_the_body_font_size_px": "Dimensione del carattere",
    "hide_powered_by_label": "Nascondi l'etichetta 'Powered by'",
    "show_answer_sources_option": "Mostra l'opzione delle fonti di risposta",
    "hide_the_full_site_overlay": "L'overlay del sito completo è una funzionalità che consente di creare un punto di focalizzazione sul tuo sito web oscurando lo sfondo e visualizzando il widget.",
    "hide_overlay": "Nascondi overlay",
    "change_placeholder_text_of_the_form_s_input": "Cambia il testo segnaposto dell'input del modulo",
    "enter_custom_placeholder_text": "Inserisci il testo segnaposto personalizzato...",
    "submit_button_s_text": "Testo del pulsante di invio",
    "enter_text_for_submit_button": "Inserisci il testo per il pulsante di invio...",
    "failed_to_upload_branding_logo": "Impossibile caricare il logo del marchio",
    "change_font_family": "Cambia il tipo di carattere",
    "show_on_mobile": "Mostra su mobile",
    "show_on_tablet": "Mostra su tablet"
  }
}