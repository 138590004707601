import { TeamScopesEnum } from '@/components/project/settings/team/TeamScopesEnum';
import { useAppStore } from '@/stores';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export const useCurrentUserProjectScopes = (projectId: string = '') => {
  const store = useAppStore();
  const route = useRoute();
  const projectIdRoute = computed(() => route?.params?.id);

  const project = computed(() =>
    projectId
      ? store?.getProjectById(projectId)
      : store?.getProjectById(String(projectIdRoute.value)),
  );
  const userId = computed(() => store?.user?.id);

  const currentUserProjectRelation = computed(() =>
    project?.value?.user_to_projects?.find((pr) => pr.user_id === userId.value),
  );
  const canViewOnly = computed(
    () => currentUserProjectRelation.value?.scopes === TeamScopesEnum.READ,
  );
  const isOwner = computed(() => currentUserProjectRelation.value?.scopes === TeamScopesEnum.OWNER);
  return {
    project,
    userId,
    currentUserProjectRelation,
    canViewOnly,
    isOwner,
  };
};
