{
  "chatWidget": {
    "we_are_creating_suggestion": "Ми створюємо запитання-пропозиції для вас. Але ви можете використовувати чат зараз",
    "type_your_question": "Вибачте, ми ще не створили жодного {title}.",
    "processing_your_request": "Обробка вашого запиту...",
    "ooops_we_have_t_found": "404 Ой, ми не знайшли проект",
    "please_ask_the_owner_of": "Будь ласка, зверніться до власника проекту, щоб зробити його публічним",
    "the_project_owner_needs_to": "Власник проекту повинен оновити підписку, оскільки поточна закінчилася.",
    "oops_it_seems_youve_reached": "Ой! Здається, ви досягли обмеження на кількість повідомлень. Щоб продовжити, будь ласка, налаштуйте обмеження на кількість повідомлень у налаштуваннях."
  }
}