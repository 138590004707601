{
  "apiKeys": {
    "api_keys": "Chiavi API",
    "please_generate_your_api_key": "Per favore, genera la tua chiave API se vuoi utilizzare la nostra API di Backend",
    "api_keys_not_found": "Chiavi API non trovate.",
    "let_s_generate_it": "Generiamola!",
    "generate_api_key": "Genera chiave API",
    "your_secret_api_keys_are_listed_below": "Le tue chiavi API segrete sono elencate di seguito. Non condividere la tua chiave API con altri, né esporla nel browser o in altro codice lato client.",
    "name": "Nome",
    "key": "Chiave",
    "created": "Creata",
    "expires": "Scade",
    "last_used": "Ultimo utilizzo",
    "actions": "Azioni",
    "name_is_not_provided": "Nome non fornito",
    "api_key_was_update": "Chiave API aggiornata!",
    "you_must_generate_a_new_api_key": "Devi generare una nuova chiave API per revocare l'unica chiave rimanente.",
    "api_key_was_revoked": "Chiave API revocata!"
  },
  "createApiKeyModal": {
    "create_new_secret_key": "Crea nuova chiave segreta",
    "name_optional": "Nome (opzionale)",
    "my_test_key": "La mia chiave di test",
    "create_secret_key": "Crea chiave segreta"
  },
  "createdNewKeyModal": {
    "created_new_secret_key": "Creata nuova chiave segreta",
    "please_save_this_secret_key": "Per favore, salva questa chiave segreta in un luogo sicuro e accessibile. Per motivi di sicurezza, non potrai visualizzarla nuovamente tramite il tuo account. Se perdi questa chiave segreta, dovrai generarne una nuova.",
    "api_key_copied": "Chiave API copiata!"
  },
  "presentApiKeyModal": {
    "your_secret_key": "La tua chiave segreta",
    "secret_key": "Chiave segreta",
    "api_key_copied": "Chiave API copiata!"
  },
  "renameApiKeyModal": {
    "edit_secret_key": "Modifica chiave segreta",
    "save": "Salva",
    "edit_name": "Modifica nome",
    "my_test_key": "La mia chiave di test"
  },
  "revokeKeyModal": {
    "revoke_secret_key": "Revoca chiave segreta",
    "this_api_key_will_immediately_be_disabled": "Questa chiave API verrà immediatamente disabilitata. Le richieste API effettuate utilizzando questa chiave verranno rifiutate, il che potrebbe causare il malfunzionamento dei sistemi che dipendono ancora da essa. Una volta revocata, non potrai più visualizzare o modificare questa chiave API.",
    "revoke_key": "Revoca chiave"
  }
}