<template>
  <h4 class="billing-overview-heading mb-2 flex items-end justify-between">
    {{ name }}
    <span
      class="ml-1 text-xs font-semibold text-[#64748B] dark:text-white"
      :class="{ '!text-[var(--omni-color-text)]': isPortalRoute }"
    >
      {{ formattedCurrent }}/{{ formattedTotal }}
    </span>
  </h4>

  <div
    v-tippy="{
      content: tooltip,
      placement: 'top',
      touch: false,
    }"
    class="relative mb-4 h-[6px] rounded-full bg-[#ebedf2] dark:bg-dark/40"
  >
    <div
      class="h-full rounded-full text-center text-xs leading-none text-white"
      :style="{
        width: `${progress}%`,
      }"
      :class="`bg-${baseClass}`"
    ></div>
    <span
      class="absolute bottom-0 left-0 right-0 top-0 text-center text-xs leading-none dark:text-white"
    >
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, type ComputedRef, inject } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const TO_FIXED_DIGITS = 0;

const props = defineProps<{
  name: string;
  total: number;
  current: number;
  // loading: boolean;
}>();

const isPortalRoute = inject<ComputedRef<boolean>>('is-portal-route');

const progress = computed(() => {
  if (props.total === 0) {
    return 0;
  }
  return (props.current / props.total) * 100 < 100 ? (props.current / props.total) * 100 : 100;
});

const formattedTotal = computed(() => props.total.toFixed(TO_FIXED_DIGITS));
const formattedCurrent = computed(() => props.current.toFixed(TO_FIXED_DIGITS));

const baseClass = computed(() => {
  if (progress.value > 80) {
    return 'danger';
  } else if (progress.value > 50) {
    return 'warning';
  } else {
    return 'success';
  }
});

const tooltip = computed(() => {
  if (props.name === t('billingOverviewItem.money')) {
    return `${t('billingOverviewItem.used')}: ${formattedCurrent.value}; ${t(
      'billingOverviewItem.remaining',
    )}: ${formattedTotal.value}`;
  }
  return t('billingOverviewItem.used_out_of', {
    formattedCurrent_value: formattedCurrent.value,
    formattedTotal_value: formattedTotal.value,
  });
});
</script>
