import gql from 'graphql-tag';

export const INSERT_USER_TO_PROJECT = gql`
  mutation InsertUserToProject(
    $project_id: uuid!
    $email: citext!
    $locale: String!
    $scopes: scope_types_enum_enum!
  ) {
    insert_user_to_projects_one(
      object: {
        scopes: $scopes
        project_id: $project_id
        is_invited: true
        user: {
          data: { email: $email, locale: $locale }
          on_conflict: { constraint: users_email_key, update_columns: [email] }
        }
      }
      on_conflict: { constraint: user_to_projects_user_id_project_id_key, update_columns: [] }
    ) {
      user {
        id
      }
    }
  }
`;
