{
  "SearchWidget_name": "Пошук",
  "SearchWidget_description": "Комунікація, як у рядку пошуку браузера. Задавайте питання та переглядайте історію відповідей.",
  "ExplainWidget_name": "Пояснити",
  "ExplainWidget_description": "Комунікація для отримання більш детальних відповідей. Питання - Відповідь.",
  "ChatWidget_name": "Чат",
  "ChatWidget_description": "Звичайна форма комунікації, як у месенджері. Коротко і зрозуміло.",
  "BaseInformer_xml-sitemap_name": "Xml-карта сайту",
  "BaseInformer_xml-sitemap_description": "Ви можете додати Xml-карту сайту для аналізу веб-сайту",
  "PopupChatWidget_name": "Виринаючий чат",
  "PopupChatWidget_description": "Інтерактивний віджет для миттєвих відповідей. Задавайте питання, отримуйте відповіді.",
  "CSVInformer_name": "CSV-текст",
  "CSVInformer_description": "Ви можете завантажити свій файл .csv з власним відображенням стовпців",
  "WebInformer_name": "Декілька веб-сайтів",
  "WebInformer_description": "Ви можете визначити пакет URL-адрес веб-сайтів для обробки",
  "FileInformer_name": "Завантаження файлу",
  "FileInformer_description": "Ви можете вибрати свій локальний файл для обробки",
  "BaseInformer_url_name": "URL-файл",
  "BaseInformer_url_description": "Ви можете визначити шлях URL для завантаження файлу з Інтернету",
  "YoutubeInformer_name": "Youtube",
  "YoutubeInformer_description": "Ви можете додати субтитри до відео на Youtube",
  "BaseInformer_text_name": "Текст",
  "BaseInformer_text_description": "Просто додайте текст",
  "BulkCSVInformer_name": "CSV-посилання",
  "BulkCSVInformer_description": "Масове завантаження через CSV з URL-адресами",
  "BaseInformer_dropbox-file_name": "Файл Dropbox",
  "BaseInformer_dropbox-file_description": "Додайте посилання на файл Dropbox",
  "BaseInformer_dropbox-folder_name": "Папка Dropbox",
  "BaseInformer_dropbox-folder_description": "Додайте посилання на папку Dropbox",
  "BaseInformer_gdrive-file_name": "Файл GDrive",
  "BaseInformer_gdrive-file_description": "Додайте посилання на файл GDrive",
  "BaseInformer_gdrive-folder_name": "Папка GDrive",
  "BaseInformer_gdrive-folder_description": "Додайте посилання на папку GDrive",
  "DocSearchWidget_name": "Пошук документів",
  "DocSearchWidget_description": "Дозволяє встановити спливаючий віджет для пошуку в документації",
  "RetellingWidget_name": "Переказ",
  "RetellingWidget_description": "Інтерактивний інструмент переказування для ваших інсайтів бази знань.",
  "VimeoInformer_name": "Vimeo",
  "VimeoInformer_description": "Ви можете додати субтитри до відео на Vimeo",
  "ConfluenceInformer_name": "Confluence",
  "ConfluenceInformer_description": "Ви можете додати свою базу знань Confluence",
  "BaseInformer_zendesk_name": "Zendesk",
  "BaseInformer_zendesk_description": "Ви можете визначити URL-адресу центру довідки Zendesk для обробки.",
  "BaseInformer_youtube-channel_name": "Канал Youtube",
  "BaseInformer_youtube-channel_description": "Ви можете визначити URL-адресу каналу Youtube для обробки.",
  "BaseInformer_youtube-playlist_name": "Плейлист Youtube",
  "BaseInformer_youtube-playlist_description": "Ви можете визначити URL-адресу плейлиста Youtube для обробки.",
  "NotionInformer_name": "Notion",
  "NotionInformer_description": "Ви можете додати сторінки або бази даних Notion",
  "InteractiveMindmapWidget_name": "Інтерактивна ментальна карта",
  "InteractiveMindmapWidget_description": "Створюйте та налаштовуйте ієрархічні ментальні карти.",
  "MermaidMindmapWidget_name": "Mermaid Mindmap",
  "MermaidMindmapWidget_description": "Створюйте та налаштовуйте ієрархічні ментальні карти.",
  "WordpressInformer_name": "Wordpress",
  "WordpressInformer_description": "Ви можете додати свій сайт Wordpress",
  "GoogleSheetsAddOn_name": "Додаток Google Sheets",
  "GoogleSheetsAddOn_description": "Додавання додатка Omnimind AI до Google Sheets",
  "ZapierWidget_name": "Інтеграція з Zapier",
  "ZapierWidget_description": "Додавання Omnimind AI до вашої програми Zapier",
  "ChromeExtensionInformer_name": "Розширення Chrome",
  "ChromeExtensionInformer_description": "Завантажте інформацію зі своїх ресурсів за допомогою нашого розширення Chrome.",
  "VoiceWidget_name": "Голосовий розмова",
  "VoiceWidget_description": "Розмовляйте зі своїм ботом, щоб отримати відповіді, як ви розмовляєте з людиною",
  "SingleWebInformer_name": "Веб-сайт",
  "SingleWebInformer_description": "Ви можете визначити URL-адресу одного веб-сайту для обробки",
  "DataExtractorWidget_name": "Видобувач даних",
  "DataExtractorWidget_description": "Видобування інформації з веб-сайтів для збору даних",
  "SlackIntegration_name": "Інтеграція з Slack",
  "SlackIntegration_description": "Додавання Omnimind AI до вашого робочого простору Slack",
  "WhatsAppIntegration_name": "Інтеграція з WhatsApp",
  "WhatsAppIntegration_description": "Звичайна комунікація в WhatsApp.",
  "BaseInformer_onedrive-file_name": "Файл OneDrive",
  "BaseInformer_onedrive-file_description": "Додайте посилання на файл OneDrive",
  "BaseInformer_onedrive-folder_name": "Папка OneDrive",
  "BaseInformer_onedrive-folder_description": "Додайте посилання на папку OneDrive",
  "SummaryWidget_name": "Резюме",
  "SummaryWidget_description": "Перетворіть свої дані на чіткі та лаконічні резюме.",
  "QuizWidget_name": "Тест",
  "QuizWidget_description": "Створення та експорт комплексних тестів",
  "TableWidget_name": "Таблиця",
  "TableWidget_description": "Загальний віджет для відображення даних у таблиці. Цей віджет прихований у списку віджетів.",
  "AudioInformer_name": "Аудіофайл",
  "AudioInformer_description": "Ви можете вибрати свій локальний аудіофайл для обробки",
  "AirtableInformer_name": "Airtable",
  "AirtableInformer_description": "Ви можете додати свою базу знань Airtable",
  "FlashcardsWidget_name": "Флеш-картки",
  "FlashcardsWidget_description": "Створення та використання флеш-карток для ефективного навчання та перевірки знань",
  "ExcelInformer_name": "Excel",
  "ExcelInformer_description": "Ви можете завантажити свій файл .xls або .xlsx з власним відображенням стовпців",
  "GoogleSheetsInformer_name": "Google Sheets",
  "GoogleSheetsInformer_description": "Ви можете завантажити свої Google Sheets з власним відображенням стовпців",
  "DatabaseInformer_name": "Висновки з бази даних",
  "DatabaseInformer_description": "Ви можете робити запити до власних баз даних за допомогою зрозумілого для людини мови.",
  "PdfOcrInformer_name": "PDF",
  "PdfOcrInformer_description": "Витягує всі дані з PDF-файлів та додає їх до ваших ресурсів.",
  "BubbleIntegration_name": "Плагін Bubble.io",
  "BubbleIntegration_description": "Інтегруйте OmniMind у свої рішення Bubble.",
  "NpmIntegration_name": "NPM-пакет",
  "NpmIntegration_description": "Додавання пакету Omnimind AI Npm до ваших проектів",
  "MicrosoftTeamsIntegration_name": "Інтеграція з Microsoft Teams",
  "MicrosoftTeamsIntegration_description": "Інтегруйте свою базу знань у корпоративну комунікацію з Microsoft Teams та одним з наших чат-ботів та прискорте свої операційні процеси."
}