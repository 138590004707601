{
  "settingsCustomize": {
    "bot_name": "Nome del bot",
    "enter_custom_bot_name": "Inserisci il nome personalizzato del bot",
    "welcome_messages": "Messaggi di benvenuto",
    "enter_messages_separated_by_double_enter": "Inserisci i messaggi, separati da doppio Invio.",
    "type_messages_double_enter_to_separate": "Digita i messaggi, doppio Invio per separarli.",
    "add_a_custom_welcome_message_interval_seconds": "Aggiungi un intervallo di tempo personalizzato per i messaggi di benvenuto (secondi)",
    "enter_welcome_message_interval": "Inserisci l'intervallo di tempo per i messaggi di benvenuto...",
    "show_followups_questions": "Mostra domande di approfondimento",
    "add_a_custom_message_ending_to_every_omni_mind_response": "Aggiungi un messaggio finale personalizzato a ogni risposta di OmniMind",
    "enter_custom_message": "Inserisci un messaggio personalizzato...",
    "label_for_examples_of_questions": "Etichetta per Esempi di domande che puoi fare al bot",
    "before_you_start_to_work_with_widget": "Prima di iniziare a lavorare con il widget, vedi gli esempi di domande che puoi fare al bot",
    "enter_label_for_examples": "Inserisci l'etichetta per Esempi di domande che puoi fare al bot...",
    "change_placeholder_text_of_the_form_s_input": "Cambia il testo segnaposto dell'input del modulo",
    "enter_custom_placeholder_text": "Inserisci un testo segnaposto personalizzato...",
    "submit_button_s_text": "Testo del pulsante di invio",
    "enter_text_for_submit_button": "Inserisci il testo per il pulsante di invio...",
    "opening_message": "Messaggio di apertura",
    "enter_custom_opening_message": "Inserisci un messaggio di apertura personalizzato",
    "change_the_widget_color_style": "Cambia lo stile del colore del widget",
    "show_examples_of_questions_that_you_can_ask_the_bot_about": "Mostra esempi di domande che puoi fare al bot",
    "default_language": "Lingua predefinita",
    "browser_language_auto_detect": "Rilevamento automatico della lingua del browser",
    "custom_prompt": "Prompt personalizzato",
    "using_a_direct_custom_prompt_is_only_necessary": "L'utilizzo di un prompt personalizzato diretto è necessario solo in casi speciali e disabiliterà il modulo principale.",
    "change_the_header_font_size": "Cambia la dimensione del carattere dell'intestazione",
    "change_the_body_font_size": "Cambia la dimensione del carattere del corpo",
    "hide_powered_by_label": "Nascondi l'etichetta 'Powered by'",
    "show_answer_sources_option": "Mostra l'opzione delle fonti di risposta",
    "failed_to_upload_branding_logo": "Caricamento del logo del marchio non riuscito"
  },
  "customizeColorInput": {
    "primary": "Primario",
    "background": "Sfondo",
    "secondary": "Secondario",
    "stroke": "Contorno",
    "accent": "Accento",
    "text": "Testo",
    "body": "Corpo",
    "default_color_scheme": "Schema di colore predefinito"
  },
  "customizeColorModal": {
    "create_the_custom_widget_color_style": "Crea lo stile di colore personalizzato del widget",
    "save": "Salva",
    "the_custom_widget_color_style": "{value} lo stile di colore personalizzato del widget",
    "edit": "Modifica",
    "create": "Crea",
    "primary": "Primario",
    "background_color": "Colore di sfondo",
    "secondary": "Secondario",
    "stroke_color": "Colore del contorno",
    "text_color": "Colore del testo",
    "accent_color": "Colore dell'accento",
    "body_background": "Sfondo del corpo",
    "leave_empty_for_transparent_body": "Lascia vuoto per un corpo trasparente",
    "current_color_style_examples_click_to_clone": "Esempi di stile di colore corrente, clicca per clonare",
    "field_is_not_a_valid_hex_color": "Il campo non è un colore esadecimale valido"
  },
  "customizeColorSchemes": {
    "changes_to_the_color_theme_of_your_widget": "Le modifiche al tema di colore del tuo widget verranno riflesse nelle modalità \"Condividi widget\" e \"Codice di incorporamento\".",
    "you_have_reached_the_maximum_allowed_limit": "Hai raggiunto il limite massimo consentito. Rimuovi alcuni schemi prima di crearne di nuovi.",
    "click_here_to_create_a_new_scheme": "Clicca qui per creare un nuovo schema."
  },
  "customizeFileUploader": {
    "upload_custom_avatar_or_logo": "Carica il logo del marchio",
    "drag_drop_or": "Trascina e rilascia o",
    "select_files": "Seleziona file",
    "to_upload": "per caricare",
    "error_uploading_file": "Errore durante il caricamento del file"
  },
  "customizeFileUploaderImage": {
    "no_logo": "Nessun logo"
  },
  "customizeQuestionnaires": {
    "add_custom_questions": "Aggiungi domande personalizzate",
    "enter_custom_message": "Inserisci un messaggio personalizzato...",
    "regenerate": "Rigenera domande"
  }
}