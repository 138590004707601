{
  "basicModeSelectWidget": {
    "need_another": "Benötigen Sie ein weiteres",
    "data_source": "Datenquelle",
    "view": "Ansicht",
    "have_a_specific": "Haben Sie eine spezifische {feature} im Sinn, die Ihr Erlebnis verbessern würde? Fordern Sie es als Funktion an und wir werden daran arbeiten, es zu integrieren.",
    "sorry_we_could_not": "Entschuldigung, wir konnten keine übereinstimmenden Ergebnisse finden. Wenn Sie eine spezifische {feature} im Sinn haben, können Sie sie als Funktion anfordern und wir werden daran arbeiten, sie für Sie zu integrieren.",
    "request_a_new": "Fordern Sie eine neue {feature} an"
  },
  "basicModeWidgetCard": {
    "coming_soon": "BALD VERFÜGBAR",
    "beta": "BETA",
    "popular": "BELIEBT",
    "we_understand_you": "Wir verstehen, dass Sie an dieser Funktion interessiert sind. Sie ist in unseren höheren Tarifplänen verfügbar. Erwägen Sie ein Upgrade, um darauf zugreifen zu können!",
    "add_another_widget": "Ein weiteres Widget hinzufügen",
    "expand_your_dashboard_by_adding_a_new_widget": "Erweitern Sie Ihr Dashboard, indem Sie ein neues Widget hinzufügen."
  },
  "basicModeWidgetFilters": {
    "informers": "Informanten",
    "all": "Alle",
    "widgets": "Widgets",
    "search": "Suchen...",
    "all_informers": "Alle Informanten",
    "popular": "Beliebt",
    "beta": "Beta",
    "embed": "Einbetten",
    "integrations": "Integrationen"
  }
}