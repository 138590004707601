import gql from 'graphql-tag';

export const UPDATE_PROJECT_PUBLIC_STATE = gql`
  mutation UpdateProjectPublicState($id: uuid, $public_state: Boolean = false) {
    update_projects(where: { id: { _eq: $id } }, _set: { is_public: $public_state }) {
      returning {
        id
      }
    }
  }
`;
