{
  "copyProjectModal": {
    "clone_current_project": "Клонувати поточний проект",
    "clone_project": "Клонувати проект",
    "copy_this_project": "Скопіювати цей проект?",
    "copy_project": "Скопіювати проект",
    "all_your_settings_and_resources_on_this": "Всі ваші налаштування та ресурси на цьому проекті будуть скопійовані.",
    "it_looks_like_you_ve_reached_your_maximum_number": "Схоже, ви досягли максимальної кількості проектів. Щоб створити новий проект, розгляньте можливість архівування або видалення існуючих проектів, які вам більше не потрібні, або ознайомтесь з нашими підписними планами для отримання більш високих обмежень."
  },
  "deleteProjectModal": {
    "delete_current_project": "Видалити поточний проект",
    "delete_project": "Видалити проект",
    "delete": "Видалити",
    "all_your_data_on": "Всі ваші дані на",
    "will_be_deleted_immediately": "будуть видалені негайно.",
    "the_project": "проект"
  },
  "deleteShopifyContent": {
    "seems_like_you_ve_created_a_project_using_our_shopify": "Схоже, ви створили проект за допомогою нашого додатку Shopify і розглядаєте можливість видалення його через основний додаток. Ми рекомендуємо видаляти його через додаток Shopify: Видалення проекту через наш додаток Shopify є рекомендованим і найбезпечнішим способом, щоб забезпечити гладкий і безпомилковий процес.",
    "yes_i_understand_it_but_want_to_delete_it_anyway": "Так, я розумію це, але все одно хочу видалити його."
  },
  "projectUploadingMessage": {
    "you_have_updated_knowledge_please_wait": "Ви оновили знання. Зачекайте, поки ми завантажуємо ваші знання.",
    "knowledge_uploaded": "Знання завантажено..."
  },
  "publishProject": {
    "unpublish": "Скасувати публікацію",
    "publish": "Опублікувати",
    "unpublish_project": "Скасувати публікацію проекту",
    "all_users_who_had_access_to_the_widgets": "Всі користувачі, які мали доступ до віджетів за посиланням, втратять можливість використовувати їх.",
    "share_project": "Поділитися проектом",
    "please_note_that_any_activity_of_the_users": "Зверніть увагу, що будь-яка дія користувачів, з якими ви поділитеся цим посиланням, буде зарахована на ваш рахунок.",
    "publish_widget": "Опублікувати віджет"
  },
  "renameProject": {
    "enter_the_short_name_of_your_project": "Введіть коротку назву вашого проекту",
    "my_project": "Мій проект",
    "description": "Опис"
  },
  "shareProject": {
    "widget_link_copied_to_clipboard": "Посилання на віджет скопійовано в буфер обміну",
    "share_project": "Поділитися проектом",
    "please_note_that_any_activity_of_the_users": "Зверніть увагу, що будь-яка дія користувачів, з якими ви поділитеся цим посиланням, буде зарахована на ваш рахунок.",
    "share": "Поділитися",
    "publish_widget": "Опублікувати віджет"
  },
  "shareProjectModal": {
    "share_widget": "Поділитися віджетом",
    "please_note_that_any_activity_of_the_users": "Зверніть увагу, що будь-яка дія користувачів, з якими ви поділитеся цим посиланням, буде зарахована на ваш рахунок.",
    "copy_link": "Скопіювати посилання",
    "share_on": "Поділитися на"
  },
  "unpublishProject": {
    "unpublish_project": "Скасувати публікацію проекту",
    "all_users_who_had_access_to_the_widgets": "Всі користувачі, які мали доступ до віджетів за посиланням, втратять можливість використовувати їх."
  }
}