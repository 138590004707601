{
  "knowledgeChunksModal": {
    "chunks": "Chunks",
    "save": "Salvar",
    "chunks_selected": "chunks selecionados",
    "no_data_so_far": "Nenhum dado até agora.",
    "title": "Título",
    "page": "Página",
    "size": "Tamanho",
    "last_modified": "Última modificação",
    "text": "Texto",
    "actions": "Ações",
    "no_chunks_so_far": "Nenhum chunk até agora",
    "all_done_your_items_were_removed_successfully": "Tudo pronto! Seus itens foram removidos com sucesso."
  },
  "knowledgeChunksModalPagination": {
    "prev": "Anterior",
    "next": "Próximo"
  },
  "knowledgeChunksModalSearch": {
    "quick_search": "Busca rápida",
    "search": "Buscar"
  },
  "knowledgeFilterModal": {
    "filter": "Filtrar",
    "apply": "Aplicar",
    "reset": "Redefinir",
    "by_name": "Por nome",
    "by_title": "Por título",
    "by_period": "Por período",
    "search_for_resource_name": "Buscar por nome do recurso...",
    "search_for_resource_title": "Buscar por título do recurso...",
    "last_year": "Último ano",
    "quarter": "Trimestre",
    "month": "Mês",
    "week": "Semana",
    "day": "Dia"
  }
}