{
  "baseDropboxFile": {
    "schema": {
      "folder_name": "Dropbox-Ordnername",
      "folder_description": "Aktueller Ordnername",
      "url_title": "Dropbox-Datei-URL",
      "url_description": "Die URL zur Dropbox-Datei"
    },
    "schemaUi": {
      "label": "Dropbox-Datei",
      "description": "Hier können Sie die URL zu Ihrer Datei auf Dropbox einfügen. Stellen Sie bitte sicher, dass die Datei öffentlich verfügbar ist, sonst erhalten Sie einen Fehler während der Verarbeitung",
      "placeholder": "URL eingeben"
    }
  },
  "baseDropboxFolder": {
    "schema": {
      "url_title": "Dropbox-Ordner-URL",
      "url_description": "Die URL zum Dropbox-Ordner"
    },
    "schemaUi": {
      "label": "Dropbox-Ordner",
      "description": "Hier können Sie den Link zu Ihrem Dropbox-Ordner einfügen. Stellen Sie bitte sicher, dass der Ordner sowie alle Dateien darin öffentlich verfügbar sind, sonst erhalten Sie einen Fehler während der Verarbeitung",
      "placeholder": "URL eingeben"
    }
  },
  "baseGoogleDriveFile": {
    "schema": {
      "folder_name": "Gdrive-Ordnername",
      "folder_description": "Der Name für den Gdrive-Ordner",
      "url_title": "Gdrive-Datei-URL",
      "url_description": "Die URL zur Gdrive-Datei"
    },
    "schemaUi": {
      "label": "Gdrive-Datei",
      "description": "Hier können Sie den Link zu Ihrer Google Drive-Datei einfügen. Stellen Sie bitte sicher, dass die Datei öffentlich verfügbar ist, sonst erhalten Sie einen Fehler während der Verarbeitung",
      "placeholder": "URL eingeben"
    }
  },
  "baseGoogleDriveFolder": {
    "schema": {
      "url_title": "Gdrive-Ordner-URL",
      "url_description": "Die URL zum Gdrive-Ordner"
    },
    "schemaUi": {
      "label": "Gdrive-Ordner",
      "description": "Hier können Sie den Link zu Ihrem Google Drive-Ordner hinzufügen. Stellen Sie bitte sicher, dass der Ordner sowie alle Dateien darin öffentlich verfügbar sind, sonst erhalten Sie einen Fehler während der Verarbeitung",
      "placeholder": "URL eingeben"
    }
  },
  "baseOneDriveFile": {
    "schema": {
      "parent_title": "Gdrive-Ordnername",
      "url_title": "OneDrive-Datei-URL",
      "url_description": "Die URL zur OneDrive-Datei"
    },
    "schemaUi": {
      "label": "OneDrive-Datei",
      "description": "Hier können Sie den Link zu Ihrer OneDrive-Datei einfügen. Stellen Sie bitte sicher, dass die Datei öffentlich verfügbar ist, sonst erhalten Sie einen Fehler während der Verarbeitung",
      "placeholder": "URL eingeben"
    }
  },
  "baseOneDriveFolder": {
    "schema": {
      "url_title": "OneDrive-Ordner-URL",
      "url_description": "Die URL zum OneDrive-Ordner"
    },
    "schemaUi": {
      "label": "OneDrive-Ordner",
      "description": "Hier können Sie den Link zu Ihrem OneDrive-Ordner hinzufügen. Stellen Sie bitte sicher, dass der Ordner sowie alle Dateien darin öffentlich verfügbar sind, sonst erhalten Sie einen Fehler während der Verarbeitung",
      "placeholder": "URL eingeben"
    }
  },
  "baseXmlSitemap": {
    "schema": {
      "url_title": "XML-URL",
      "url_description": "Geben Sie die URL zu Ihrer Sitemap ein"
    },
    "schemaUi": {
      "label": "XML-Sitemap",
      "description": "Wenn Sie Ihre Sitemap-Datei mit allen Links, die Sie indexieren möchten, haben, können Sie die URL zur Sitemap-Datei definieren",
      "placeholder": "https://example.com/sitemap.xml"
    }
  },
  "baseYoutubeChannel": {
    "schema": {
      "url_title": "Kanal-URL",
      "url_description": "Bitte klicken Sie auf 'Teilen' auf dem YouTube-Kanal und kopieren Sie den Link in das obige Eingabefeld",
      "language_title": "Die bevorzugte Sprache"
    },
    "schemaUi": {
      "label": "YouTube-Kanal",
      "description": "Bitte fügen Sie den Link zum YouTube-Kanal ein, der die Untertitel in der angegebenen Sprache enthält. Hinweis: Der Informer verarbeitet nur Videos mit Untertiteln, sonst erhalten Sie einen Fehler.",
      "placeholder": "URL eingeben",
      "placeholder_2": "de"
    }
  },
  "baseYoutubePlaylist": {
    "schema": {
      "url_title": "Playlist-URL",
      "url_description": "Bitte klicken Sie auf 'Teilen' auf der YouTube-Playlist und kopieren Sie den Link in das obige Eingabefeld",
      "language_title": "Die bevorzugte Sprache"
    },
    "schemaUi": {
      "label": "YouTube-Playlist",
      "description": "Bitte fügen Sie den Link zur YouTube-Playlist ein, die die Untertitel in der angegebenen Sprache enthält. Hinweis: Der Informer verarbeitet nur Videos mit Untertiteln, sonst erhalten Sie einen Fehler.",
      "placeholder": "URL eingeben",
      "placeholder_2": "de"
    }
  },
  "baseZendesk": {
    "schema": {
      "url_title": "Zendesk-URL",
      "url_description": "Bitte geben Sie den Link zum Zendesk-Hilfecenter ein"
    },
    "schemaUi": {
      "label": "Zendesk",
      "description": "Bitte fügen Sie den Link zum Zendesk-Hilfecenter ein.",
      "placeholder": "URL eingeben"
    }
  },
  "baseText": {
    "schema": {
      "content_title": "Text",
      "content_description": "Bitte fügen Sie den Text hinzu, den Sie Ihrem Projekt hinzufügen möchten",
      "metadata_title": "Zusätzliche Metadaten"
    },
    "schemaUi": {
      "label": "Text",
      "description": "Fügen Sie den Text zu Ihrem Projekt hinzu",
      "placeholder": "Text eingeben"
    }
  },
  "baseUrl": {
    "schema": {
      "url_title": "Datei-URL",
      "url_description": "Die Datei-URL, die dem Projekt hinzugefügt wird"
    },
    "schemaUi": {
      "label": "URL-Datei",
      "description": "Bitte stellen Sie sicher, dass die Datei im Internet öffentlich verfügbar ist, sonst erhalten Sie einen Autorisierungsfehler",
      "placeholder": "example.com/datei.pdf"
    }
  }
}