{
  "sidebar": {
    "it_looks_like_you_are": "Sembra che tu abbia raggiunto il numero massimo di progetti. Per avviare un nuovo progetto, considera di archiviare o eliminare quelli esistenti che non ti servono più, oppure esplora i nostri piani di abbonamento per limiti più elevati.",
    "new_project": "Nuovo progetto",
    "select_widget": "Seleziona widget"
  },
  "sidebarHeader": {
    "new_project": "Nuovo progetto"
  },
  "sidebarLink": {
    "project_settings": "Impostazioni del progetto",
    "project_has_been_successfully": "Il progetto è stato {isFav} ai preferiti con successo!",
    "we_encountered_an_issue": "Abbiamo riscontrato un problema durante il tentativo di aggiornare i preferiti. Riprova."
  },
  "sidebarNoProjects": {
    "there_are_no_projects": "Non ci sono ancora progetti qui"
  },
  "sidebarProjectsListFooter": {
    "create_project": "Crea progetto",
    "create_a_project_using": "crea un progetto utilizzando",
    "bot_advice": "consigli del bot",
    "enter_redemption_code": "Riscatto",
    "applying": "Applicazione in corso...",
    "apply": "Applica",
    "oops_you_ve_reached_your_project_limit": "Ops! Hai raggiunto il limite di progetti per la creazione di nuovi progetti. Per crearne altri, considera l'aggiornamento del tuo pacchetto!"
  },
  "sidebarProjectTree": {
    "knowledge": "Conoscenza",
    "add_a_new_view": "Aggiungi una nuova vista",
    "dialogs": "Dialoghi",
    "conversations": "Conversazioni",
    "team": "Team",
    "project": "Progetto"
  },
  "sidebarPromocode": {
    "enter_redemption_code": "Riscatto",
    "exists_promo": "Questo codice promozionale esiste già",
    "apply": "Applica"
  },
  "sidebarProjectTreeSettingsItem": {
    "settings": "Impostazioni",
    "ai_model": "Modello AI",
    "behavior": "Comportamento",
    "appearance": "Aspetto",
    "integrations": "Integrazioni",
    "database": "Database"
  },
  "sidebarSharedProjectsListItem": {
    "something_went_awry": "Qualcosa è andato storto!"
  },
  "sidebarEmailConfirmWarning": {
    "resend": "Invia di nuovo",
    "sending": "Invio in corso",
    "please_check_your_inbox": "Controlla la tua casella di posta",
    "to_verify_your_email": "per verificare la tua email.",
    "verification_email_is_sent": "È stata inviata un'email di verifica.",
    "something_went_wrong": "Qualcosa è andato storto"
  }
}