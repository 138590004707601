{
  "widgetsComponentDeleteWidgetModal": {
    "delete": "Excluir",
    "widget": "widget",
    "do_you_really_want_to_remove": "Você realmente deseja remover",
    "widget_from": "widget de",
    "you_can_always_add_it_any_time_later": "Você sempre pode adicioná-lo mais tarde."
  },
  "widgetsComponentPasswordProjectModal": {
    "password_is_required": "Senha é obrigatória",
    "enter_the_project_password": "Digite a senha do projeto",
    "submit": "Enviar"
  }
}