import { gql } from '@apollo/client/core';

export const GET_USER_PACKAGES = gql`
  query GetBillingUserPackages($id: uuid!) {
    billing_user_packages(
        where: { user_id: { _eq: $id } },  
        order_by: { updated_at: desc }, 
        limit: 1
    ) {
      package_features
    }
  }
`;
