{
  "mermaidMindmapWidgetActions": {
    "export": "Export",
    "click_here": "Click here to open mind map editors",
    "theme": "Theme",
    "select_timezone": "Select your timezone",
    "language": "Language",
    "create_map": "Create map",
    "regenerate_tooltip": "Click here to regenerate the entire mind map. Please note that this will replace the current map entirely. Use this option only if you're dissatisfied with the existing map. If you'd like to make minor adjustments, simply click the 'Edit' button instead.",
    "export_svg": "Export SVG",
    "export_png": "Export PNG"
  },
  "mermaidMindmapWidgetMap": {
    "mermaid_mind_map": "Mermaid Mind Map",
    "what_is_mind_map": "What is Mind Map",
    "a_mermaid_mind_map_is_a_visualization_tool": "A Mermaid mind map is a visualization tool created with the Mermaid.js library, which allows for the organization and representation of information in a hierarchical format. Using a simple markdown-like syntax, you can craft mind maps that offer a clear, visual structure to complex ideas or projects. This method aids significantly in brainstorming and planning phases, helping to delineate and clarify complex data or concepts efficiently. Additionally, the plain text input is easily editable, facilitating swift updates and adaptations. It can be smoothly integrated into various platforms, making the sharing and embedding of these mind maps a hassle-free process.",
    "steps_to_improve_your_mind_map": "Steps to Improve Your Mind Map",
    "clarify_your_intent": "Clarify Your Intent",
    "use_the_custom_prompt_to": "Use the custom prompt to specify your requirements when creating the mind map.",
    "declutter_knowledge": "Declutter Knowledge",
    "navigate_to_the_knowledge_section": "Navigate to the \"Knowledge\" section (next to settings). Delete redundant chunks and unnecessary information to maintain focus and clarity.",
    "adjust_with_editors": "Adjust with Editors",
    "employ_the_map_editor_to_correct": "Employ the 'Map Editor' to correct and adjust the Mermaid syntax. Use the 'Nodes Editor' to add, delete, or replace nodes in the mind map for a better alignment.",
    "by_following_these_steps": "By following these steps, your mind map will be more streamlined, focused, and tailored to your needs.",
    "map_should_highlight_the_key_aspects_and": "Map should highlight the key aspects and hierarchical relationships of the { context }. Begin the map with a single root node that succinctly represents the main concept or theme. Develop branches to explore sub-topics or related concepts, providing clear labels for each node. Maintain a clear and logical flow between connected nodes and sub-nodes, using proper indentation to indicate hierarchy. Keep nodes brief and to the point, ensuring they directly relate to adjacent nodes. In the response, stick to the pattern",
    "with_adequate_spacing_to_demonstrate": "with adequate spacing to demonstrate hierarchical ties. Your output should be textual, formatted to render accurately with Mermaid, and devoid of uppercase words or special symbols apart from those necessary for the Mermaid syntax.",
    "we_are_unable_to_create_a_mindmap": "We are unable to create a mindmap based on the provided content. You can try to open widget settings and change them to get better results!",
    "something_went_awry": "Something went awry! But we try to generate your map again!",
    "please_generate_a_mermaid_mind": "Please generate a Mermaid mind map based on the { context } in",
    "language": "language"
  },
  "mermaidMindmapWidgetMapEditor": {
    "map_editor": "Map editor",
    "manipulate_the_structure_and": "Manipulate the structure and flow of your map effortlessly by adjusting indentations and adding new lines to craft nodes, facilitating a tailored and insightful visualization of your ideas. Get ready to refine and redefine your mindmapping experience, where every change brings you closer to perfection."
  },
  "mermaidMindmapWidgetNodesEditor": {
    "nodes_editor": "Nodes editor",
    "dont_settle_for_nodes_that_dont_resonate": "Don't settle for nodes that don't resonate; with the Nodes Editor, you can effortlessly regenerate any element to better suit your vision, or add brand new nodes to expand upon your ideas.",
    "click_here_to_re_generate_this_node": "Click here to re-generate this node. Make sure to provide clear instructions.",
    "click_here_to_add_a_brand_new_node": "Click here to add a brand new node. All other nodes will be untouched. Make sure to provide clear instructions.",
    "add_node": "Add Node",
    "add_instructions": "Add instructions",
    "enter_your_hint_here": "Enter your hint here",
    "this_node": "This node",
    "and_all_it_s_children_will": "and all it's children will be rerendered.",
    "you_have_the_option_to": "You have the option to enhance your mind map by adding a new node.",
    "feel_free_to_include_any_specific": "Feel free to include any specific details or requirements as a hint if necessary."
  }
}
