{
  "popupChatWidget": {
    "clear_chat": "Chat leeren",
    "close": "Schließen",
    "processing_your_request": "Ihre Anfrage wird bearbeitet...",
    "please_ask_the_owner_of_the_project_to_make_it_public": "Bitte fragen Sie den Eigentümer des Projekts, es öffentlich zu machen",
    "the_project_owner_needs_to_upgrade": "Der Projektinhaber muss das Abonnement aktualisieren, da das aktuelle abgelaufen ist.",
    "oops_we_have_t_found": "404 Ooops, wir haben das Projekt nicht gefunden",
    "oops_it_seems_youve_reached": "Hoppla! Es scheint, dass Sie Ihr Nachrichtenlimit erreicht haben. Um fortzufahren, passen Sie bitte Ihr Limit in den Einstellungen an."
  }
}