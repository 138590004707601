{
  "copyProjectModal": {
    "clone_current_project": "Clona progetto corrente",
    "clone_project": "Clona progetto",
    "copy_this_project": "Copiare questo progetto?",
    "copy_project": "Copia progetto",
    "all_your_settings_and_resources_on_this": "Tutte le impostazioni e le risorse di questo progetto verranno copiate.",
    "it_looks_like_you_ve_reached_your_maximum_number": "Sembra che tu abbia raggiunto il numero massimo di progetti. Per avviare un nuovo progetto, considera l'archiviazione o l'eliminazione di quelli esistenti che non ti servono più, o esplora i nostri piani di abbonamento per limiti più elevati."
  },
  "deleteProjectModal": {
    "delete_current_project": "Elimina progetto corrente",
    "delete_project": "Elimina progetto",
    "delete": "Elimina",
    "all_your_data_on": "Tutti i tuoi dati su",
    "will_be_deleted_immediately": "verranno eliminati immediatamente.",
    "the_project": "il progetto"
  },
  "deleteShopifyContent": {
    "seems_like_you_ve_created_a_project_using_our_shopify": "Sembra che tu abbia creato un progetto utilizzando la nostra app Shopify e stai considerando di eliminarlo tramite l'app principale. Ti consigliamo di eliminarlo tramite l'app Shopify: eliminare un progetto tramite la nostra app Shopify è il modo consigliato e più sicuro per garantire un processo senza problemi e senza errori.",
    "yes_i_understand_it_but_want_to_delete_it_anyway": "Sì, lo capisco, ma voglio eliminarlo comunque."
  },
  "projectUploadingMessage": {
    "you_have_updated_knowledge_please_wait": "Hai aggiornato la conoscenza. Attendi mentre carichiamo la tua conoscenza.",
    "knowledge_uploaded": "Conoscenza caricata..."
  },
  "publishProject": {
    "unpublish": "Annulla pubblicazione",
    "publish": "Pubblica",
    "unpublish_project": "Annulla pubblicazione progetto",
    "all_users_who_had_access_to_the_widgets": "Tutti gli utenti che avevano accesso ai widget tramite il link perderanno la possibilità di utilizzarli.",
    "share_project": "Condividi progetto",
    "please_note_that_any_activity_of_the_users": "Si prega di notare che qualsiasi attività degli utenti con cui condividi questo link è legata al saldo del tuo account.",
    "publish_widget": "Pubblica widget"
  },
  "renameProject": {
    "enter_the_short_name_of_your_project": "Inserisci il nome breve del tuo progetto",
    "my_project": "Mio Progetto",
    "description": "Descrizione"
  },
  "shareProject": {
    "widget_link_copied_to_clipboard": "Link del widget copiato negli appunti",
    "share_project": "Condividi progetto",
    "please_note_that_any_activity_of_the_users": "Si prega di notare che qualsiasi attività degli utenti con cui condividi questo link è legata al saldo del tuo account.",
    "share": "Condividi",
    "publish_widget": "Pubblica widget"
  },
  "shareProjectModal": {
    "share_widget": "Condividi widget",
    "please_note_that_any_activity_of_the_users": "Si prega di notare che qualsiasi attività degli utenti con cui condividi questo link è legata al saldo del tuo account.",
    "copy_link": "Copia link",
    "share_on": "Condividi su"
  },
  "unpublishProject": {
    "unpublish_project": "Annulla pubblicazione progetto",
    "all_users_who_had_access_to_the_widgets": "Tutti gli utenti che avevano accesso ai widget tramite il link perderanno la possibilità di utilizzarli."
  }
}