{
  "airtableInformerInformers": {
    "you_can_provide_your_own_airtable": "Sie können Ihre eigene Airtable bereitstellen",
    "personal_access_token": "Persönlicher Zugriffstoken",
    "in_your_services_credentials_settings": "in Ihren Dienst-Anmeldeinformationen-Einstellungen.",
    "click_here": "Klicken Sie hier!",
    "if_you_have_not_generated_yet_a_token": "Wenn Sie noch keinen Token generiert haben, gehen Sie bitte zu Ihrer",
    "airtable_platform": "Airtable-Plattform",
    "and_generate_one": "und generieren Sie einen",
    "please_make_sure_to_select": "Bitte stellen Sie sicher, dass Sie",
    "and": "und",
    "scopes_and_related_bases": "Berechtigungen und zugehörige Basen",
    "your_stored_integration_token": "Ihr gespeicherter Integrations-Token",
    "is_the_token_incorrect": "Ist der Token falsch? Sie können ihn in den Dienst-Anmeldeinformationen-Einstellungen ändern.",
    "services_credentials_settings": "Dienst-Anmeldeinformationen-Einstellungen",
    "fetch_airtable_bases": "Airtable-Basen abrufen",
    "select_base": "Basis auswählen",
    "select_table": "Tabelle auswählen"
  }
}