{
  "explainWidget": {
    "we_are_creating_suggestion_questions": "Estamos criando perguntas de sugestão para você. Mas você pode usar o chat agora",
    "sorry_we_could_not_create_any_examples": "Desculpe, não conseguimos criar exemplos de perguntas que você pode fazer ao bot ainda.",
    "loading": "Carregando...",
    "explanation": "Explicação",
    "please_ask_the_owner_of_the": "Por favor, pergunte ao proprietário do projeto para torná-lo público",
    "the_project_owner_needs_to": "O proprietário do projeto precisa atualizar a assinatura, pois a atual expirou.",
    "404_oops_we_havet_found": "404 Ooops, não encontramos o projeto",
    "oops_it_seems_youve_reached": "Ops! Parece que você atingiu o limite de mensagens. Para continuar, ajuste seu limite de taxa nas configurações."
  }
}