{
  "vimeoInformerInformers": {
    "you_can_provide_your_own_vimeo_token": "Sie können Ihren eigenen Vimeo-Token in den Einstellungen Ihrer Dienstleistung bereitstellen.",
    "click_here": "Hier klicken!",
    "please_provide_a_valid_vimeo_video_url": "Bitte geben Sie eine gültige Vimeo-Video-URL an.",
    "this_video_has_no_text_tracks": "Dieses Video hat keine Untertitel!",
    "something_went_awry": "Etwas ist schiefgelaufen!"
  },
  "vimeoInformerFormInformers": {
    "insert_vimeo_video_url": "Vimeo-Video-URL einfügen",
    "fetch_subtitles": "Untertitel abrufen"
  },
  "vimeoInformerListInformers": {
    "available_tracks": "Verfügbare Tracks"
  }
}