{
  "pricingItem": {
    "month": "month",
    "up_to_todo_calculate_pricing_info": "Up to 'TODO: Calculate Pricing Info'",
    "trial_days": "trial days",
    "this_includes:": "This includes",
    "subscribe:": "Subscribe"
  },
  "pricingTable": {
    "subscriptions": "Subscriptions",
    "to_access_the_widget_you_need": "To access the widget you need to choose one of the offered subscription plans",
    "oops": "Oops!",
    "there_no_available_packages": "There no available packages for this link"
  }
}