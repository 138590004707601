{
  "widgetsComponentDeleteWidgetModal": {
    "delete": "Elimina",
    "widget": "widget",
    "do_you_really_want_to_remove": "Vuoi davvero rimuovere",
    "widget_from": "widget da",
    "you_can_always_add_it_any_time_later": "Puoi sempre aggiungerlo in qualsiasi momento in seguito.",
    "widget_many": "widget",
    "do_you_really_want_to_remove_many": "Vuoi davvero rimuovere i",
    "widgets_from": "widget da",
    "you_can_always_add_them_any_time_later": "Puoi sempre aggiungerli in qualsiasi momento in seguito."
  },
  "widgetsComponentPasswordProjectModal": {
    "password_is_required": "La password è obbligatoria",
    "enter_the_project_password": "Inserisci la password del progetto",
    "submit": "Invia"
  }
}