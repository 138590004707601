{
  "flashcardsWidget": {
    "export_to_quizlet": "Exportar para o Quizlet",
    "export_to_cram": "Exportar para o Cram",
    "how_to_import_into_cram": "Como Importar para o Cram",
    "begin_by_visiting_the_cram": "Comece visitando o site do Cram e selecionando a opção \"Criar Flashcards\". Em seguida, escolha \"Importar um conjunto de flashcards\". Copie seus dados, garantindo que cada termo e sua definição estejam separados por uma",
    "and_different_cards_are": "e diferentes cartões sejam distinguidos por uma",
    "new_line": "Nova Linha",
    "paste_this_into_the": "Cole isso no campo de entrada rotulado como \"COPIAR E COLAR SEUS DADOS:\", e clique em \"Importar Dados\". Aproveite sua sessão de estudo!",
    "oops_it_seems_youve_reached_your": "Ops! Parece que você atingiu o limite de taxa de mensagens. Para continuar, ajuste gentilmente seu limite de taxa nas configurações.",
    "question": "Pergunta",
    "tab": "Aba",
    "my_answer": "Minha Resposta",
    "correct_answer": "Resposta Correta",
    "evaluate_my_answer": "Avalie minha resposta em uma escala de 1 a 5 com base em sua precisão e completude em comparação com a resposta correta, considerando o contexto { context }. Use as seguintes diretrizes para classificação: Se a resposta estiver completamente incorreta ou irrelevante para a pergunta e demonstrar falta de compreensão do tópico ou pergunta, classifique como \"1\". Se a resposta abordar a pergunta, mas tiver grandes imprecisões ou omissões, mas mostrar algum entendimento, mas também significativas incompreensões ou lacunas de conhecimento, classifique como \"2\". Se a resposta estiver parcialmente correta, mas for incompleta ou tiver pequenas imprecisões e mostrar um entendimento razoável do tópico, mas falta detalhes ou precisão, classifique como \"3\". Se a resposta estiver em grande parte correta e cobrir a maioria dos aspectos da pergunta, mas detalhes ou nuances menores podem estar faltando, mas o entendimento geral é bom, classifique como \"4\". Se a resposta estiver completamente correta e fornecer uma resposta abrangente à pergunta, classifique como \"5\". Use o formato: \"número;explicação\" para sua resposta.",
    "something_went_awry": "Algo deu errado!",
    "your_rate_limit_exceeded": "Gerar em {formLanguage} idioma {formAmount} perguntas de { context } em um FORMATO JSON VÁLIDO. Use o padrão",
    "answers_should_only_contain_the_essential": "As respostas devem conter apenas os termos-chave ou frases essenciais diretamente relevantes para a pergunta, sem elaboração.",
    "we_ve_found_parsed_length_questions": "Encontramos {parsedLength} perguntas por enquanto. Adicionar mais conteúdo pode nos ajudar a encontrar ainda mais!"
  },
  "flashcardsWidgetActions": {
    "view_all_questions": "Ver todas as perguntas",
    "delete_card": "Excluir cartão",
    "edit_card": "Editar cartão",
    "export_to_quizlet": "Exportar para o Quizlet",
    "export_to_anki": "Exportar para o Anki",
    "export_to_brainscape": "Exportar para o Brainscape",
    "export_to_cram": "Exportar para o Cram",
    "regenerate_cards": "Regenerar cartões",
    "clear_all_cards": "Limpar todos os cartões"
  },
  "flashcardsWidgetFeedback": {
    "feedback": "Feedback"
  },
  "flashcardsWidgetForm": {
    "select_the_number_and_the_language": "Selecione o número e o idioma dos flashcards",
    "cards_number": "Número de Cartões",
    "enter_quantity": "Digite a quantidade",
    "language": "Idioma",
    "generate": "Gerar",
    "original": "Original"
  },
  "flashcardsWidgetNavigation": {
    "back": "Voltar",
    "step_and_length_of_questions": "{currentStep} de {questionsLength} perguntas",
    "next": "Próximo",
    "skip": "Pular"
  },
  "flashcardsWidgetQuestion": {
    "question": "Pergunta",
    "enter_your_answer_here": "Digite sua resposta aqui...",
    "answer": "Resposta",
    "your_answer": "Sua Resposta",
    "correct_answer": "Resposta Correta"
  },
  "flashcardsWidgetQuestionEditor": {
    "edit_card": "Editar Cartão",
    "save": "Salvar",
    "question": "Pergunta",
    "answer": "Resposta"
  },
  "flashcardsWidgetQuestionFooter": {
    "check_my_answer": "Verificar minha resposta",
    "hide_answer": "Esconder resposta",
    "i_want_to_answer_it_by_myself": "Quero responder por mim mesmo",
    "show_answer": "Mostrar resposta"
  },
  "flashcardsWidgetQuestionsList": {
    "looks_like_it_s_pretty_empty_here": "Parece que está bem vazio aqui!",
    "start_adding_some_cards_to_see_them_appear": "Comece adicionando alguns cartões para vê-los aparecer."
  }
}