{
  "settingsIntegrations": {
    "integrations": "Integraciones",
    "please_note_that_any_activity_of_the_users": "Ten en cuenta que cualquier actividad de los usuarios con quienes compartas este enlace está vinculada a tu saldo de cuenta.",
    "embed": "Insertar",
    "messengers": "Mensajeros",
    "integration_confirmation": "Confirmación de integración",
    "please_note_that_any_activity": "Ten en cuenta que cualquier actividad de los usuarios con quienes compartas este enlace está vinculada a tu saldo de cuenta.",
    "copy_link": "Copiar enlace",
    "share_on": "Compartir en",
    "widget_link_copied_to_clipboard": "Enlace del widget copiado al portapapeles",
    "personal_shared": "personal / compartido",
    "share": "Compartir",
    "on": "Activar",
    "off": "Desactivar"
  }
}