{
  "userInformation": {
    "general_information": "Редагувати інформацію",
    "full_name": "Повне ім'я",
    "enter_full_name": "Введіть повне ім'я",
    "email": "Електронна пошта",
    "enter_email": "Введіть електронну пошту",
    "company": "Компанія",
    "enter_company": "Введіть назву компанії",
    "phone": "Телефон",
    "enter_phone_number": "Введіть номер телефону",
    "interface_language": "Мова інтерфейсу",
    "select_interface_language": "Виберіть мову інтерфейсу",
    "timezone": "Часовий пояс",
    "select_your_timezone": "Виберіть свій часовий пояс",
    "cancel": "Скасувати",
    "avatar": "Аватар",
    "upload_photo": "Завантажити фото",
    "save": "Зберегти",
    "this_field_contains_special_characters": "Це поле містить спеціальні символи",
    "your_personal_data_was_updated": "Ваші персональні дані були оновлені!",
    "entered_phone_number_is_already_in_use": "Введений номер телефону вже використовується",
    "invalid_form": "Деякі поля відсутні або некоректні. Будь ласка, перегляньте підсвічені поля та спробуйте знову.",
    "confirmation_email_sent": "Лист з підтвердженням для зміни вашої електронної пошти був відправлений на вашу нову адресу електронної пошти"
  }
}