{
  "pricingItem": {
    "month": "Monat",
    "up_to_todo_calculate_pricing_info": "Bis zu 'TODO: Preisinformationen berechnen'",
    "trial_days": "Testtage",
    "this_includes:": "Dies beinhaltet",
    "subscribe:": "Abonnieren"
  },
  "pricingTable": {
    "subscriptions": "Abonnements",
    "to_access_the_widget_you_need": "Um auf das Widget zuzugreifen, müssen Sie eines der angebotenen Abonnementpläne auswählen",
    "oops": "Hoppla!",
    "there_no_available_packages": "Es gibt keine verfügbaren Pakete für diesen Link"
  }
}