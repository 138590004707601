{
  "settingsBehaviour": {
    "behavior": "Поведінка",
    "choose_option": "Виберіть опцію, яку потрібно редагувати",
    "label_for_examples_of_questions": "Мітка для прикладів питань, на які ви можете запитати бота",
    "before_you_start_to_work_with_widget": "Перед тим, як почати працювати з віджетом, ви побачите приклади питань, на які ви можете запитати бота",
    "enter_label_for_examples_of_questions": "Введіть мітку для прикладів питань, на які ви можете запитати бота...",
    "split_your_questions_with_a_new_line": "Розділіть ваші питання новим рядком.",
    "welcome_messages": "Повідомлення привітання",
    "enter_messages": "Введіть повідомлення, розділені подвійним Enter.",
    "start_each_message": "Почніть кожне повідомлення з нового рядка. Натисніть клавішу Enter двічі, щоб розділити повідомлення.",
    "type_messages": "Введіть повідомлення, подвійний Enter для розділення.",
    "add_a_custom_welcome_message_interval": "Додайте власний інтервал привітання (у секундах)",
    "enter_welcome_message_interval": "Введіть інтервал привітання...",
    "add_a_custom_welcome_message": "Змінити фразу \"Я не знаю відповіді\"",
    "when_your_bot_isn_t_sure_about": "Якщо ваш бот не впевнений у відповіді, він починає речення з фрази \"Найімовірніше.\" Ви можете змінити цю фразу, і вона з'явиться замість неї.",
    "most_likely": "Найімовірніше...",
    "add_a_custom_message": "Додайте власне повідомлення в кінці кожної відповіді OmniMind",
    "enter_custom_message": "Введіть власне повідомлення...",
    "default_language": "Мова за замовчуванням",
    "custom_language": "Власна мова",
    "show_followups_questions": "Показувати питання про продовження",
    "live_connect": "Підключення в реальному часі",
    "none": "Немає",
    "button_name_for_leaving_a_message": "Назва кнопки для залишення повідомлення",
    "leave_a_message": "Залишити повідомлення",
    "offline_message": "Повідомлення в автономному режимі",
    "button_name_for_offline_message": "Назва кнопки для повідомлення в автономному режимі",
    "dialogs": "Розмови",
    "anonymize_sensitive_data": "Анонімізувати конфіденційні дані",
    "language_of_the_data": "Мова даних",
    "language": "Мова",
    "replace_value": "Замінити значення",
    "replace": "Замінити"
  },
  "behaviourDialogsSetting": {
    "history_mode": "Режим історії",
    "collect_user_mode": "Режим збору даних користувача",
    "track_all_messages": "Відстежувати всі повідомлення",
    "track_only_messages_with": "Відстежувати лише негативний відгук та запити на підтримку",
    "dont_track": "Не відстежувати",
    "collect_username_and_email_with_form": "Збирати ім'я користувача та електронну пошту за допомогою форми",
    "provide_username_and_email_by_yourself": "Надати ім'я користувача та електронну пошту самостійно",
    "do_not_collect_username_and_email": "Не збирати ім'я користувача та електронну пошту"
  },
  "behaviourLiveConnectAction": {
    "selected_option_will_be_applied": "Вибрана опція буде застосована як дія для кнопки",
    "live_connect_options": "Опції підключення в реальному часі",
    "your_custom_js_code": "Ваш власний JS-код",
    "ensure": "Переконайтеся, що",
    "is_correctly_set_up": "правильно налаштовано в вашому проекті. Потрібна допомога?",
    "check_here": "Перевірте тут."
  },
  "behaviourOfflineMessageAction": {
    "selected_option_will_be_applied_as_action_for_button": "Вибрана опція буде застосована як дія для кнопки",
    "offline_message_options": "Опції повідомлення в автономному режимі",
    "your_custom_js_code": "Ваш власний JS-код",
    "your_email": "Ваша електронна пошта"
  },
  "behaviourRateLimit": {
    "enable_rate_limit": "Увімкнути обмеження швидкості",
    "rate_limit": "Обмеження швидкості",
    "enable_rate_limit_message": "Обмежити кількість повідомлень, відправлених з цього віджета за годину"
  }
}