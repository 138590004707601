{
  "auth": {
    "changePassword": {
      "change_password": "Change password",
      "enter_password": "Enter password",
      "new_password": "New Password",
      "password": "Password",
      "old_password": "Old Password",
      "cancel": "Cancel",
      "password_confirmation": "Confirm New Password",
      "success": "Password changed successfully"
    },
    "changePasswordForm": {
      "new_password": "New password",
      "enter_password": "Enter password",
      "password_confirmation": "Password confirmation",
      "change_password": "Change password"
    },
    "portalProvider": {
      "portal": "Portal",
      "portal_not_found": "Portal not found",
      "is_not_currently_active": "is not currently active",
      "you_are_currently_blocked": "You are currently blocked"
    },
    "promocode": {
      "something_went_wrong": "Something went wrong",
      "enjoy_benefits": "Enjoy benefits by redemption code!",
      "redemption_code": "Redemption code",
      "enter_redemption_code": "Enter redemption code",
      "verifying": "Verifying...",
      "verify": "Verify",
      "create_account_in_omnimind": "Create account in Omnimind AI to get purchased credits",
      "this_promo_code_does_not_seem_to_exist": "This promo code doesn't seem to exist. Please check for typos and try again.",
      "this_promo_code_has_expired": "This promo code has expired. Try another code."
    },
    "resetPasswordForm": {
      "reset_password": "Reset password",
      "enter_your_email_for_resetting_password": "Enter your email for resetting password",
      "enter_email": "Enter email",
      "please_check_your_mailbox": "Please check your mailbox and \n follow the instruction link to finish change password.",
      "if_you_can_not_find_it": "If you can't find it in your inbox, please check your",
      "spam_folder": "spam folder",
      "as_well": "as well."
    },
    "signOut": {
      "sign_out": "Sign Out"
    }
  }
}
