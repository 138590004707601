{
  "interactiveMindmapWidgetActions": {
    "click_here_to_download": "Hier klicken zum Herunterladen",
    "language": "Sprache",
    "create_map": "Karte erstellen"
  },
  "interactiveMindmapWidgetMap": {
    "interactive_mind_map": "Interaktive Mind Map",
    "an_interactive_mind_map_is_a_digital": "Eine interaktive Mind Map ist ein digitales Werkzeug, das entwickelt wurde, um die Organisation und Visualisierung von Informationen in einer hierarchischen Struktur zu erleichtern. Diese Mind Maps werden mit dynamischen Webtechnologien erstellt und bieten interaktive Funktionen wie Ziehen, Zoomen und Knotenbearbeitung. Sie sind besonders nützlich für Brainstorming-Sitzungen, Projektplanung und Visualisierung komplexer Ideen oder Systeme. Mit einer interaktiven Mind Map können Sie verschiedene Informationen leicht kategorisieren und verknüpfen, was das Verständnis, die Analyse und die Zusammenarbeit erleichtert.",
    "generate_a_mind_map_in": "Erzeugen Sie eine Mind Map in",
    "something_went_awry": "Etwas ist schiefgelaufen!",
    "we_are_unable_to_create_a_mindmap": "Wir können keine Mindmap auf Basis des bereitgestellten Inhalts erstellen. Sie können versuchen, die Widget-Einstellungen zu öffnen und sie zu ändern, um bessere Ergebnisse zu erzielen!",
    "translate_into": "Übersetzen in",
    "language_every_name_property_s_value_of_in": "Sprache für den Wert der \"name\"-Eigenschaft in diesem JSON-String-Objekt",
    "don_t_change_other_values": "Ändern Sie keine anderen Werte. Stellen Sie sicher, dass Sie nur doppelte Anführungszeichen verwenden und die Antwort mit",
    "give_me_a_valid_js_array_of_5_topics": "Geben Sie mir ein gültiges JS-Array mit 5 Themen (1-3 Wörter pro Thema), um eine Mind Map basierend auf {context} im Format \"[Thema 1, Thema 2, Thema 3, Thema 4, Thema 5]\" in {languageValue} Sprache zu erstellen",
    "please_generate_a_mind_map": "Bitte generieren Sie eine Mind Map",
    "about": "über",
    "with": "mit",
    "as_a_core_node": "als Kernknoten"
  }
}