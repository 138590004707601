import { computed, ref } from 'vue';
import { showAlert } from '@/composables/mixin-alert';
import { useAppStore } from '@/stores';
import { useRouter } from 'vue-router';
import { UserPermissionEnum } from '@/interfaces/UserPermissionEnum';
import { useAuthorization } from '@/plugins/authorization';
import { useUserCan } from '@/composables/useUserCan';
import { NPM_PACKAGE_LINK } from '@/constants/links';

export type IntegrationType = 'messenger' | 'package' | 'extension' | 'integration';
export const useIntegrations = (
  currentProjectId: string | null = null,
  filterByTypes: IntegrationType[] = [],
) => {
  const store = useAppStore();
  const projectsData = computed(() => {
    return store.projects;
  });

  const router = useRouter();
  const isBeta = false;
  const isPopular = false;

  const widgetData = ref([
    {
      id: '1',
      icon: 'npm',
      title: 'NPM Package',
      description: 'You can natively integrate our library and use Omnimind inside your web app.',
      is_active: true,
      isBeta,
      isPopular,
      link: NPM_PACKAGE_LINK,
      type: 'package',
      key: 'NpmIntegration',
    },
    {
      id: '2',
      icon: 'chrome',
      title: 'Chrome Extension',
      description: 'Feed information from anywhere on the Internet into your project.',
      is_active: true,
      isBeta,
      isPopular,
      type: 'extension',
      key: 'ChromeExtensionInformer',
    },
    {
      id: '3',
      icon: 'zapier',
      title: 'Zapier integration',
      description: 'You can use the power of OmniMind AI in your Zapier automation processes.',
      is_active: true,
      isBeta,
      isPopular,
      type: 'integration',
      key: 'ZapierWidget',
    },
    {
      id: '4',
      icon: 'bubble',
      title: 'Bubble.io plugin',
      type: 'integration',
      isBeta,
      isPopular,
      description: 'Integrate OmniMind into your Bubble solutions.',
      is_active: false,
      key: 'BubbleIntegration',
    },
    {
      id: '5',
      icon: 'slack',
      title: 'Slack integration',
      description:
        'Integrate your knowledge base into your corporate communication means as Slack messenger and make your processes snappier.',
      is_active: true,
      isBeta,
      isPopular,
      type: 'messenger',
      key: 'SlackIntegration',
    },
    {
      id: '6',
      icon: 'whatsapp',
      title: 'Whatsapp integration',
      description:
        'Make our AI bot support your business 24\\7 while you spend your time growing it.',
      is_active: true,
      isBeta,
      isPopular,
      type: 'messenger',
      key: 'WhatsAppIntegration',
    },
    {
      id: '7',
      icon: 'team',
      title: 'Microsoft Teams integration',
      description:
        'Integrate your knowledge base into your corporate communication with Microsoft Teams and one of our chatbots and speed up your operational processes.',
      type: 'integration',
      is_active: false,
      isBeta,
      isPopular,
      key: 'MicrosoftTeamsIntegration',
    },
  ]);

  const filteredIntegrations = computed(() =>
    widgetData.value
      .filter((widget) =>
        filterByTypes.length ? filterByTypes.includes(widget.type as IntegrationType) : true,
      )
      .map((widget) => {
        return {
          ...widget,
          disabled:
            availableIntegrations.value && !availableIntegrations.value.includes(widget.key),
        };
      }),
  );

  const handleWidgetCardClicked = async (widget: any) => {
    if (!widget.is_active) {
      await showAlert('error', 'We are working on it.');
      return;
    }
    if (widget.link) {
      window.open(widget.link, '_blank');
    } else {
      if (projectsData.value.length > 0) {
        await router.push(
          `/projects/${
            currentProjectId
              ? currentProjectId
              : projectsData.value[projectsData.value.length - 1].id
          }/widget/view/resources/${widget.key}`,
        );
      } else {
        await showAlert('error', 'No projects available.');
      }
    }
  };

  const { package_features } = useAuthorization();
  const availableIntegrations = computed(() => {
    const userCanUseInformers =
      (useUserCan({
        permission: UserPermissionEnum.CanUseInformers,
        features: package_features.value,
      }) as string[]) || [];
    const userCanUseWidgets =
      (useUserCan({
        permission: UserPermissionEnum.CanUseWidgets,
        features: package_features.value,
      }) as string[]) || [];
    return [...userCanUseInformers, ...userCanUseWidgets];
  });

  return {
    filteredIntegrations,
    availableIntegrations,
    widgetData,
    handleWidgetCardClicked,
  };
};
