export enum ItemTypeKeysEnum {
  Knowledge = 'knowledge',
  Dialogs = 'dialogs',
  AiModel = 'ai-model',
  Behaviour = 'behaviour',
  Appearance = 'appearance',
  Integrations = 'integrations',
  Team = 'team',
  Project = 'project',
  Widget = 'widget',
  Database = 'database',
  Tools = 'tools',
  Agent = 'agent'
}
