import { gql } from '@apollo/client/core';

export const CHAINS_SUBSCRIPTION = gql`
  subscription chainsSubscription($project_id: uuid!) {
    chains(where: { project: { id: { _eq: $project_id } }, canceled_at: { _is_null: true } }) {
      id
      body
      createdAt
      updatedAt
      project_id
    }
  }
`;
