{
  "CustomPromptOptionsEnumTranslations": {
    "adviser": "KI-Berater",
    "sales_assistant": "Verkaufsassistent",
    "shakespeare": "William Shakespeare",
    "professor": "Professor",
    "formal": "formell",
    "informal": "informell",
    "persuasive": "überzeugend",
    "collaborative": "kollaborativ",
    "creative": "kreativ",
    "emojis": "Emojis",
    "mark_keywords": "fettgedruckte Hauptkeywords",
    "subheaders": "Unterüberschriften",
    "billet_points_format": "Aufzählungspunkte-Format"
  }
}