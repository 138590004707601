{
  "billingsActivityAnalytics": {
    "title": "Analytik"
  },
  "billingsActivityAnalyticsAverageChart": {
    "title": "Durchschnittliche Dauer von Anfragen",
    "length": "Dauer",
    "no_requests_yet": "Es liegen noch keine Anfragen vor"
  },
  "billingsActivityAnalyticsFilters": {
    "today": "heute",
    "7_days": "7 Tage",
    "month": "Monat"
  },
  "billingsActivityAnalyticsRequestsChart": {
    "title": "Anfragen",
    "unique_visitors": "Einzigartige Besucher",
    "per_day": "pro Tag",
    "per_session": "pro Sitzung"
  },
  "billingsActivityAnalyticsVisitors": {
    "title": "Einzigartige Besucher",
    "all_visitors": "Alle Besucher",
    "no_visitors_yet": "Es liegen noch keine Besucher vor"
  },
  "billingsActivityAnalyticsSuccessRate": {
    "title": "Erfolgsrate",
    "subtitle": "Wie oft OmniMind Fragen beantworten kann"
  },
  "billingsActivityAnalyticsCosts": {
    "title": "Kosten"
  }
}
