{
  "pagesProjectsIdWidgetViewContent": {
    "let_s_upload_the_content": "Laden wir den Inhalt hoch",
    "with": "Mit",
    "informers": "Informanten",
    "you_can_upload_information": "Sie können Informationen aus verschiedenen Quellen hochladen, wie z.B. Websites, PDF-Dokumente, sogar Google-Ordner und mehr. Denken Sie daran, dass Sie jederzeit zu diesem Bildschirm zurückkehren können, um zusätzlichen Inhalt in Ihr Projekt zu laden.",
    "upload_information": "Laden Sie Informationen aus verschiedenen Quellen hoch, wie z.B. Websites, PDF-Dokumente, sogar Google-Ordner und mehr. Denken Sie daran, dass Sie jederzeit zu diesem Bildschirm zurückkehren können, um zusätzlichen Inhalt in Ihr Projekt zu laden, wann immer Sie möchten.",
    "whenever_you_need": "wann immer Sie möchten",
    "select_widget": "Widget auswählen",
    "back": "Zurück",
    "we_are_working_on_it": "Wir arbeiten daran.",
    "is_not_supported_for_project_with": "wird für ein Projekt mit hochgeladenen Daten nicht unterstützt. Bitte erstellen Sie ein neues Projekt dafür"
  },
  "pagesProjectsIdWidgetMyWidgets": {
    "delete_widget": "Widget löschen",
    "confirm_widget_deletion": "Widget-Löschung bestätigen",
    "are_you_sure_you_want_to_delete": "Sind Sie sicher, dass Sie das {widgetName}-Widget löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden."
  }
}