{
  "settingsIntegrations": {
    "integrations": "Integrazioni",
    "please_note_that_any_activity_of_the_users": "Si prega di notare che qualsiasi attività degli utenti con cui condividi questo link è legata al saldo del tuo account.",
    "embed": "Incorpora",
    "messengers": "Messaggi",
    "integration_confirmation": "Conferma integrazione",
    "please_note_that_any_activity": "Si prega di notare che qualsiasi attività degli utenti con cui condividi questo link è legata al saldo del tuo account.",
    "copy_link": "Copia link",
    "share_on": "Condividi su",
    "widget_link_copied_to_clipboard": "Link del widget copiato negli appunti",
    "personal_shared": "personale / condiviso",
    "share": "Condividi",
    "on": "Attiva",
    "off": "Disattiva"
  }
}