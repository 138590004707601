{
  "billingInfo": {
    "select_project": "Seleccionar proyecto",
    "required": "Requerido",
    "select_period": "Seleccionar período",
    "your_current_package": "Tu paquete actual",
    "cancelled_at": "cancelado el",
    "next_billing_date": "próxima fecha de facturación",
    "to_cancel_or_update_your_payment_plan": "Para cancelar o actualizar tu plan de pago, por favor ve al Portal de Pago al Cliente.",
    "customer_payment_portal": "Portal de pago al cliente",
    "cancel_subscription": "Cancelar suscripción",
    "remove_your_account": "Eliminar tu cuenta",
    "remove_account": "Eliminar cuenta",
    "reactivate_account": "Reactivar cuenta",
    "no_package_found": "No se encontró ningún paquete.",
    "let_s_select_a_suitable_package": "Seleccionemos un paquete adecuado.",
    "select_your_package": "Selecciona tu paquete",
    "the_account_will_deleted_at": "La cuenta se eliminará el",
    "cancel_subscription_and_remove_account": "Cancelar suscripción y eliminar cuenta",
    "the_subscription_will_cancelled_at": "La suscripción se cancelará el",
    "cancel_current_subscription": "Cancelar suscripción actual",
    "something_went_awry": "Algo salió mal"
  },
  "billings": {
    "billing_details": "Detalles de facturación",
    "activity_summary": "Resumen de actividad"
  },
  "billingsActivity": {
    "purchase_history": "Historial de compras",
    "request_history": "Historial de solicitudes",
    "request_analytics": "Analítica"
  },
  "billingsActivityPurchase": {
    "no_data_so_far": "No hay datos hasta ahora.",
    "download_invoice": "Descargar factura",
    "date": "Fecha",
    "amount": "Monto",
    "package": "Paquete",
    "status": "Estado",
    "invoice": "Factura"
  },
  "billingsActivityRequest": {
    "select_project": "Seleccionar proyecto",
    "required": "Requerido",
    "select_period": "Seleccionar período",
    "apply": "Aplicar",
    "no_data_so_far": "No hay datos hasta ahora.",
    "created": "Creado",
    "entity": "Entidad",
    "cost": "Costo"
  },
  "billingsCurrentPackage": {
    "current_package": "Paquete actual",
    "click_here_to_see_package_info": "Haz clic aquí para ver la información del paquete",
    "cancelled_at": "cancelado el",
    "deleted_at": "eliminado el",
    "trial_ends_at": "prueba expira el",
    "next_billing_date": "próxima fecha de facturación",
    "change_package": "Cambiar paquete",
    "add_package": "Agregar paquete",
    "cancel_subscription": "Cancelar suscripción",
    "the_subscription_will_cancelled_at": "La suscripción se cancelará el",
    "cancel_current_subscription": "Cancelar suscripción actual",
    "the_subscription_will_delete_at": "La suscripción se eliminará el",
    "something_went_wrong": "¡Algo salió mal!"
  },
  "billingsPackageInfo": {
    "package_information": "Información del paquete",
    "marketplace": "Mercado",
    "learning_data": "Datos de aprendizaje",
    "requests": "Solicitudes",
    "on_average_one_request_contains": "En promedio, una solicitud contiene",
    "characters": "caracteres",
    "no_data_so_far": "No hay datos hasta ahora"
  },
  "billingsPackageInfoItem": {
    "upgrade_to_add_more": "Actualiza para agregar más"
  },
  "billingsUpgradePackageModal": {
    "expand_package": "Expandir paquete"
  },
  "chart": {
    "consumption_graph": "Gráfico de consumo"
  },
  "claimOffer": {
    "fetching_a_discount_data": "Obteniendo datos de descuento...",
    "claiming_the_offer": "Reclamando la oferta...",
    "are_you_considering_our": "¿Estás considerando nuestra",
    "discount_the": "descuento? El",
    "month_plan_is_now_just": "plan de {month} ahora solo cuesta",
    "month": "mes",
    "think_it_over": "¡Piénsalo bien!"
  },
  "finalStep": {
    "your_subscription_has_been_successfully": "Tu suscripción ha sido cancelada exitosamente. Esperamos verte pronto. Aún puedes usar nuestro servicio de forma gratuita.",
    "the_account_will_be_active": "La cuenta estará activa hasta el final del período de facturación.",
    "you_have_got_the_discount_now": "¡Ahora tienes el descuento! ¡Nos alegra que te hayas quedado con nosotros!"
  },
  "billingHelpers": {
    "request": "Solicitud",
    "requests": "Solicitudes",
    "learning_data_characters": "Datos de aprendizaje, caracteres",
    "package_name": "Nombre del paquete",
    "projects": "Proyectos",
    "content_uploads": "Cargas de contenido",
    "widgets": "Widgets",
    "scheduled_content_updates": "Actualizaciones de contenido programadas",
    "alternative_ai_models": "Modelos de IA alternativos",
    "api_access": "Acceso a la API",
    "chrome_extension": "Extensión de Chrome",
    "extended_customizations": "Personalizaciones extendidas"
  },
  "keepAi": {
    "hold_on": "¡Espera!",
    "we_just_wanted_to_let_you_know": "Solo queríamos hacerte saber que OmniMind.AI te ha ahorrado",
    "hour_of_work_so_far": "hora de trabajo hasta ahora.",
    "our_support_team_can_help_you": "Nuestro equipo de soporte puede ayudarte con cualquier problema que puedas estar enfrentando.",
    "chat_with_live_support": "Chatear con soporte en vivo"
  },
  "keepPlan": {
    "keep_plan_we_are_sorry_to_see_you_go": "¡Mantén el plan, lamentamos verte irte!",
    "cancel_your_subscription": "¿Estás seguro de que quieres cancelar tu suscripción?",
    "cancel_your_current_subscription": "¿Estás seguro de que quieres cancelar tu suscripción actual y eliminar tu cuenta?",
    "please_let_us_know_cancel_your_subscription": "Por favor, avísanos la razón por la que quieres cancelar tu suscripción.",
    "reason_you_want_to_remove_the_account": "Por favor, avísanos la razón por la que quieres eliminar la cuenta. Después de confirmar la acción, la suscripción se cancelará y la cuenta estará activa hasta el final del período de facturación. Cuando termine el período de facturación, eliminaremos permanentemente todos los datos de tus proyectos y tu cuenta.",
    "select_a_reason": "Selecciona una razón",
    "other_reason": "Otra razón"
  },
  "keepPlanModal": {
    "MissingFeatures": "Funciones faltantes",
    "TechnicalIssues": "Problemas técnicos",
    "Havenovalue": "No tiene valor",
    "Tooexpensive": "Demasiado caro",
    "Otherreason": "Otra razón"
  },
  "packageInfoModal": {
    "package_information": "Información del paquete"
  },
  "purchasedList": {
    "no_available_data_try_select_date": "No hay datos disponibles, intenta seleccionar una fecha...",
    "no_data_for_the_selected_period": "No hay datos para el período seleccionado",
    "created": "Creado",
    "entity": "Entidad",
    "quantity": "Cantidad"
  },
  "subscriptionModal": {
    "cancel": "Cancelar",
    "remove": "Eliminar",
    "removing": "Eliminando",
    "plan": "Plan",
    "account": "Cuenta",
    "work_time_optimization": "Optimización del tiempo de trabajo",
    "keep": "Mantener",
    "discount_offer": "Oferta de descuento",
    "claim_offer": "Reclamar oferta",
    "cancellation": "Cancelación",
    "confirmation": "Confirmación",
    "keep_value": "Mantener {value}",
    "final_step": "Último paso",
    "confirm": "Confirmar",
    "continue_to": "Continuar a",
    "something_went_awry": "¡Algo salió mal!"
  }
}