{
  "settingsCustomize": {
    "bot_name": "Nombre del bot",
    "enter_custom_bot_name": "Ingrese el nombre personalizado del bot",
    "welcome_messages": "Mensajes de bienvenida",
    "enter_messages_separated_by_double_enter": "Ingrese mensajes, separados por doble Enter.",
    "type_messages_double_enter_to_separate": "Escriba mensajes, doble Enter para separar.",
    "add_a_custom_welcome_message_interval_seconds": "Agregar un intervalo de mensaje de bienvenida personalizado (segundos)",
    "enter_welcome_message_interval": "Ingrese el intervalo de mensaje de bienvenida...",
    "show_followups_questions": "Mostrar preguntas de seguimiento",
    "add_a_custom_message_ending_to_every_omni_mind_response": "Agregar un mensaje de finalización personalizado a cada respuesta de OmniMind",
    "enter_custom_message": "Ingrese mensaje personalizado...",
    "label_for_examples_of_questions": "Etiqueta para Ejemplos de preguntas que puedes hacer al bot",
    "before_you_start_to_work_with_widget": "Antes de comenzar a trabajar con el widget, verás ejemplos de preguntas que puedes hacer al bot",
    "enter_label_for_examples": "Ingrese la etiqueta para Ejemplos de preguntas que puedes hacer al bot...",
    "change_placeholder_text_of_the_form_s_input": "Cambiar el texto de marcador de posición de la entrada del formulario",
    "enter_custom_placeholder_text": "Ingrese texto de marcador de posición personalizado...",
    "submit_button_s_text": "Texto del botón de envío",
    "enter_text_for_submit_button": "Ingrese el texto para el botón de envío...",
    "opening_message": "Mensaje de apertura",
    "enter_custom_opening_message": "Ingrese el mensaje de apertura personalizado",
    "change_the_widget_color_style": "Cambiar el estilo de color del widget",
    "show_examples_of_questions_that_you_can_ask_the_bot_about": "Mostrar ejemplos de preguntas que puedes hacer al bot",
    "default_language": "Idioma predeterminado",
    "browser_language_auto_detect": "Detección automática del idioma del navegador",
    "custom_prompt": "Indicación personalizada",
    "using_a_direct_custom_prompt_is_only_necessary": "El uso de una indicación personalizada directa solo es necesario en casos especiales y deshabilitará el formulario principal.",
    "change_the_header_font_size": "Cambiar el tamaño de fuente del encabezado",
    "change_the_body_font_size": "Cambiar el tamaño de fuente del cuerpo",
    "hide_powered_by_label": "Ocultar etiqueta 'Desarrollado por'",
    "show_answer_sources_option": "Mostrar opción de fuentes de respuesta",
    "failed_to_upload_branding_logo": "Error al cargar el logotipo de marca"
  },
  "customizeColorInput": {
    "primary": "Primario",
    "background": "Fondo",
    "secondary": "Secundario",
    "stroke": "Contorno",
    "accent": "Acento",
    "text": "Texto",
    "body": "Cuerpo",
    "default_color_scheme": "Esquema de color predeterminado"
  },
  "customizeColorModal": {
    "create_the_custom_widget_color_style": "Crear el estilo de color personalizado del widget",
    "save": "Guardar",
    "the_custom_widget_color_style": "{value} el estilo de color personalizado del widget",
    "edit": "Editar",
    "create": "Crear",
    "primary": "Primario",
    "background_color": "Color de fondo",
    "secondary": "Secundario",
    "stroke_color": "Color de contorno",
    "text_color": "Color de texto",
    "accent_color": "Color de acento",
    "body_background": "Fondo del cuerpo",
    "leave_empty_for_transparent_body": "Dejar vacío para un cuerpo transparente",
    "current_color_style_examples_click_to_clone": "Ejemplos de estilo de color actual, hacer clic para clonar",
    "field_is_not_a_valid_hex_color": "El campo no es un color hexadecimal válido"
  },
  "customizeColorSchemes": {
    "changes_to_the_color_theme_of_your_widget": "Los cambios en el tema de color de su widget se reflejarán en los modos \"Compartir widget\" y \"Código de inserción\".",
    "you_have_reached_the_maximum_allowed_limit": "Ha alcanzado el límite máximo permitido. Elimine algunos esquemas antes de crear nuevos.",
    "click_here_to_create_a_new_scheme": "Haga clic aquí para crear un nuevo esquema."
  },
  "customizeFileUploader": {
    "upload_custom_avatar_or_logo": "Cargar logotipo de marca",
    "drag_drop_or": "Arrastrar y soltar o",
    "select_files": "Seleccionar archivos",
    "to_upload": "para cargar",
    "error_uploading_file": "Error al cargar el archivo"
  },
  "customizeFileUploaderImage": {
    "no_logo": "Sin logotipo"
  },
  "customizeQuestionnaires": {
    "add_custom_questions": "Agregar preguntas personalizadas",
    "enter_custom_message": "Ingrese mensaje personalizado...",
    "regenerate": "Actualizar preguntas"
  }
}