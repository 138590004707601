{
  "confluenceInformerInformers": {
    "stay_organized_and_enhance_your_ai": "Stay organized and enhance your AI project by effortlessly bringing in your Confluence content.",
    "something_went_awry_check_provided_data": "Something went awry! Check provided data and try again, please!",
    "something_went_awry": "Something went awry!"
  },
  "confluenceInformerFormInformers": {
    "email": "Email",
    "your_confluence_email": "Your Confluence email address used for login.",
    "api_token": "API Token",
    "a_secure_api_token": "A secure API token generated from your Atlassian account, ensuring a seamless and safe integration.",
    "your_specific_confluence_domain": "Your specific Confluence domain prefix",
    "e_g": "e.g.",
    "if_your_confluence_url_is": "if your Confluence URL is",
    "fetch_resources": "Fetch resources",
    "your": "Your",
    "subdomain": "Subdomain"
  },
  "confluenceInformerListPagesInformers": {
    "pages": "Pages"
  },
  "confluenceInformerListPostsInformers": {
    "posts": "Posts"
  }
}