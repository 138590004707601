{
  "audioInformerInformers": {
    "language": "Мова",
    "select_files_to_upload": "Виберіть файли для завантаження",
    "for_advanced_users_only": "Тільки для досвідчених користувачів!",
    "advanced_settings": "Розширені налаштування",
    "your_uploaded_files": "Ваші завантажені файли",
    "file": "Файл",
    "was_added_previously": "був доданий раніше",
    "an_error_occurred_while": "Під час завантаження файлу сталася помилка"
  },
  "baseInformerInformers": {
    "project_does_not_exist_in_data_base_with": "Проект не існує в базі даних з"
  },
  "fileInformerInformers": {
    "select_files_to_upload": "Виберіть файли для завантаження",
    "for_advanced_users_only": "Тільки для досвідчених користувачів!",
    "advanced_settings": "Розширені налаштування",
    "your_uploaded_files": "Ваші завантажені файли",
    "looks_like_your_file": "Схоже, ваш файл",
    "does_not_have_any_text": "не містить жодного тексту, який ми можемо використовувати безпосередньо. Не хвилюйтесь! Ви можете легко",
    "convert_it_into_a_text_filled_pdf": "конвертувати його в PDF з заповненим текстом та завантажити за допомогою PDF OCR Informer",
    "upgrade_your_plan_and": "покращити свій план та конвертувати його в PDF з заповненим текстом та завантажити за допомогою PDF OCR Informer",
    "file": "Файл",
    "was_added_previously": "був доданий раніше",
    "your": "Ваш",
    "has_been_successfully_uploaded": "був успішно завантажений",
    "an_error_occurred_while": "Під час завантаження файлу сталася помилка"
  },
  "notionInformerInformers": {
    "your": "Ваш",
    "a_secure_integration_secret": "* Безпечний секрет інтеграції, згенерований з вашого облікового запису Notion, що забезпечує безпроблемну та безпечну інтеграцію.",
    "integration_token": "Токен інтеграції",
    "page_url": "URL сторінки",
    "page_should_be_shared": "* Ваша сторінка Notion повинна бути спільною з вашою інтеграцією.",
    "your_uploaded_links": "Ваші завантажені посилання:",
    "not_valid_url": "Значення не є дійсною URL-адресою",
    "add": "Додати"
  },
  "pdfOcrFileInformerInformers": {
    "select_files_to_upload": "Виберіть файли для завантаження",
    "for_advanced_users_only": "Тільки для досвідчених користувачів!",
    "advanced_settings": "Розширені налаштування",
    "your_uploaded_files": "Ваші завантажені файли",
    "great_news_your_file": "Чудові новини! Ваш файл",
    "is_a_straightforward_pdf": "є простим PDF і не потребує спеціальної обробки OCR. Будь ласка, продовжуйте та",
    "upload_it_using_our": "завантажте його за допомогою нашого File Informer",
    "for_a_smoother_experience": "для більш плавного досвіду!",
    "file": "Файл",
    "was_added_previously": "був доданий раніше",
    "your": "Ваш",
    "has_been_successfully_uploaded": "був успішно завантажений",
    "an_error_occurred_while": "Під час завантаження файлу сталася помилка"
  },
  "singleWebInformerInformers": {
    "type_in_your_url_here": "Введіть свій URL тут",
    "preview": "Попередній перегляд",
    "page_content": "Вміст сторінки",
    "preview_content": "Попередній перегляд вмісту",
    "project_does_not_exist_in_data_base_with": "Проект не існує в базі даних з"
  },
  "uploadAdvancedSettingsInformers": {
    "embeddings_provider": "Постачальник вбудовувань",
    "you_can_specify_the": "Ви можете вказати постачальника вбудовувань, який оброблятиме вашу інформацію."
  },
  "webInformerInformers": {
    "paste_your_batch_of_links_here": "Вставте вашу партію посилань тут",
    "add_urls": "Додати URL-адреси",
    "your_uploaded_links": "Ваші завантажені посилання:",
    "page_content": "Вміст сторінки",
    "preview_content": "Попередній перегляд вмісту",
    "must_be_filled_with_a_value": "Повинно бути заповнено значенням.",
    "invalid_urls": "Недійсні URL-адреси",
    "project_does_not_exist_in_data_base_with": "Проект не існує в базі даних з"
  },
  "wordpressInformerInformers": {
    "import_from_url": "Імпортувати з URL",
    "add": "Додати",
    "remove": "Видалити",
    "couldn_t_get_retrieve_the_wordpress": "Не вдалося отримати сайт WordPress. Ви можете спробувати використовувати інформер для одного сайту або вказати інший URL.",
    "go_to_website_informer": "Перейти до інформера веб-сайту",
    "select_the_types_of_content": "Виберіть типи вмісту, який вас цікавить отримати з веб-сайту."
  }
}