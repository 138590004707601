import type { SweetAlertIcon } from 'sweetalert2';
import Swal from 'sweetalert2';

export async function showAlert(
  icon: SweetAlertIcon,
  title: string,
  width = '50%',
  timer: number = 4000,
  showConfirmButton: boolean = false,
  confirmButtonText: string = 'OK',
) {
  const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer,
    padding: '10px 15px',
    confirmButtonText: 'OK',
    customClass: {
      confirmButton: 'btn-primary text-xs',
      cancelButton: 'btn btn-danger',
    },
  });
  return await toast.fire({
    icon,
    title,
    position: 'bottom',
    showClass: {
      popup: 'animate__animated animate__fadeInDown',
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp',
    },
    width,
    showConfirmButton,
    confirmButtonText,
  });
}
