{
  "pagesOmniPortalId": {
    "name": "nome",
    "add_new": "Aggiungi nuovo",
    "name_not_provided": "Nome non fornito"
  },
  "pagesOmniPortalIndex": {
    "portal": "Portale",
    "welcome_to_our_turnkey_portal": "Benvenuto nella nostra funzionalità di portale chiavi in mano, dove hai il potere di personalizzare, ribrandizzare e fornire soluzioni AI all'avanguardia senza la necessità di sviluppare da zero. La nostra piattaforma offre un framework completo che ti consente di adattare piani di abbonamento e livelli in modo preciso alle tue esigenze e a quelle dei tuoi utenti. Con pochi clic, puoi selezionare e assegnare vari widget e informatori, garantendo a ciascuno dei tuoi utenti un'esperienza personalizzata sotto il tuo marchio.",
    "the_seamless_integration": "Questa integrazione senza soluzione di continuità significa che puoi offrire servizi sofisticati mantenendo il completo controllo sull'interfaccia utente e sulla funzionalità. Si tratta di migliorare la tua offerta con il minimo sforzo e la massima efficienza. Tu fornisci la visione; la nostra piattaforma fornisce gli strumenti per realizzarla. Inizia a ridefinire oggi stesso il tuo servizio digitale e guarda la crescita della tua attività sulla base delle nostre soluzioni AI.",
    "create_my_first_portal": "Crea il mio primo portale"
  },
  "pagesOmniPortalChangePassword": {
    "you_will_be_redirected_to_the_home": "Verrai reindirizzato alla pagina principale in {value} secondi",
    "to_go_to_the_home_page": "Per andare alla pagina principale",
    "click_here": "clicca qui",
    "enter_your_new_password": "Inserisci la tua nuova password",
    "password_successfully_changed": "Password cambiata con successo",
    "invalid_token": "Token non valido"
  },
  "pagesOmniPortalLink": {
    "looks_like_you_ve_got_no_access": "Sembra che tu non abbia accesso a questo widget"
  },
  "pagesOmniPortalResetPassword": {
    "user_with_such_email_not_found": "Utente con tale email non trovato"
  },
  "pagesOmniPortal": {
    "this_portal_does_not_contain_any_projects": "Questo portale non contiene progetti"
  }
}