{
  "cancelChain": {
    "cancel": "Cancel",
    "yes": "Yes",
    "no": "No",
    "stop_processing": "Stop processing",
    "do_you_want_to_stop": "Do you want to stop all the processing chains?"
  },
  "progressIndex": {
    "no_resources_so_far_redirecting_to_informers": "No resources so far. Redirecting to informers...",
    "an_error_occured_while_deleting": "An error occured while deleting. Please try again"
  }
}
