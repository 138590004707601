{
  "popupChatWidget": {
    "clear_chat": "Limpiar chat",
    "close": "Cerrar",
    "processing_your_request": "Procesando tu solicitud...",
    "please_ask_the_owner_of_the_project_to_make_it_public": "Por favor, pide al propietario del proyecto que lo haga público",
    "the_project_owner_needs_to_upgrade": "El propietario del proyecto necesita actualizar la suscripción ya que la actual ha expirado.",
    "oops_we_have_t_found": "404 ¡Oops! No hemos encontrado el proyecto",
    "oops_it_seems_youve_reached": "¡Oops! Parece que has alcanzado el límite de mensajes. Para continuar, ajusta tu límite de mensajes en la configuración."
  }
}