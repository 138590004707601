{
  "casesDatabase": {
    "popular": "Beliebt",
    "HR": "HR",
    "support": "Support",
    "eCommerce": "E-Commerce",
    "business": "Geschäft",
    "community": "Gemeinschaft",
    "developers": "Entwickler",
    "professionals": "Profis",
    "teachers": "Lehrer",
    "sales_and_marketing": "Vertrieb & Marketing",
    "website_widget_title": "Website & Chatbot",
    "website_widget_description": "Chat-Tool für Website-Daten.",
    "knowledge_bot_title": "Wissensbot",
    "knowledge_bot_description": "Schnelle Suche nach Informationen in hochgeladenen Dateien.",
    "slack_HR_support_bot_title": "Slack HR Support Bot",
    "slack_HR_support_bot_description": "HR-Bot im Slack-Team",
    "GDrive_file_HR_chatbot_for_Slack_title": "GDrive-Datei HR-Chatbot für Slack",
    "GDrive_file_HR_chatbot_for_Slack_description": "Google Drive-Dateien in Slack hochladen.",
    "GDrive_folder_HR_chatbot_for_slack_title": "GDrive-Ordner HR-Chatbot für Slack",
    "GDrive_folder_HR_chatbot_for_slack_description": "Google Drive-Ordner in Slack hochladen.",
    "OneDrive_file_HR_chatbot_for_Slack_title": "OneDrive-Datei HR-Chatbot für Slack",
    "OneDrive_file_HR_chatbot_for_Slack_description": "OneDrive-Dateien in Slack hochladen.",
    "OneDrive_folder_HR_chatbot_for_slack_title": "OneDrive-Ordner HR-Chatbot für Slack",
    "OneDrive_folder_HR_chatbot_for_slack_description": "OneDrive-Ordner in Slack hochladen.",
    "knowledge_base_chat_popup_for_websites_title": "Wissensdatenbank-Chat-Popup für Websites",
    "knowledge_base_chat_popup_for_websites_description": "Website-Daten als Hilfschat.",
    "interactive_website_chat_for_customer_support_title": "Interaktiver Website-Chat für Kundensupport",
    "interactive_website_chat_for_customer_support_description": "Hilfschat basierend auf Website-Daten.",
    "slack_integration_for_internal_knowledge_management_title": "Slack-Integration für internes Wissensmanagement",
    "slack_integration_for_internal_knowledge_management_description": "Slack-Bot für Dateiwissen.",
    "AI_product_search_advisor_with_CSV_sync_title": "KI-Produktsuchberater mit CSV-Synchronisierung",
    "AI_product_search_advisor_with_CSV_sync_description": "Produktlisten mit CSV synchronisieren.",
    "whatsApp_AI_travel_advisor_for_villas_title": "WhatsApp-KI-Reiseberater für Villen",
    "whatsApp_AI_travel_advisor_for_villas_description": "Villen über WhatsApp finden und buchen.",
    "zendesk_inquiry_slackbot_title": "Zendesk-Anfragen-Slackbot",
    "zendesk_inquiry_slackbot_description": "Zendesk-Tickets im Slack anzeigen.",
    "google_sheets_query_slackbot_title": "Google Sheets-Abfrage-Slackbot",
    "google_sheets_query_slackbot_description": "Fragen Sie nach Google Sheets-Daten im Slack.",
    "community_content_explorer_widget_title": "Community-Inhalts-Explorer",
    "community_content_explorer_widget_description": "Suche nach Community-Inhalten.",
    "vimeo_content_explorer_for_communities_title": "Vimeo-Inhalts-Explorer für Communities",
    "vimeo_content_explorer_for_communities_description": "Vimeo-Videos einfach erkunden.",
    "web_content_explorer_widget_title": "Web-Inhalts-Explorer",
    "web_content_explorer_widget_description": "Website-Inhalte einfach finden.",
    "PDF_content_discovery_widget_title": "PDF-Inhaltsentdeckung",
    "PDF_content_discovery_widget_description": "PDF-Dokumente einfach finden.",
    "celebrity_chef_AI_chatbot_title": "Promi-Koch-KI-Chatbot",
    "celebrity_chef_AI_chatbot_description": "Rezepte von einem Koch-Bot erhalten.",
    "project_artifact_summarizer_title": "Projektartefakt-Zusammenfassung",
    "project_artifact_summarizer_description": "Projektdateien schnell zusammenfassen.",
    "AI_powered_data_extractor_for_bubble_apps_title": "KI-gesteuerter Datenextraktor für Bubble-Apps",
    "AI_powered_data_extractor_for_bubble_apps_description": "Verwenden Sie KI, um Daten zu sammeln.",
    "complex_document_simplifier_title": "Komplexer Dokumentenvereinfacher",
    "complex_document_simplifier_description": "Dokumente leicht lesbar machen.",
    "professional_summary_creator_title": "Professioneller Zusammenfassungsersteller",
    "professional_summary_creator_description": "Erhalten Sie einfache Zusammenfassungen aus Dateien.",
    "lesson_plan_quiz_generator_title": "Lektionsplan-Quizgenerator",
    "lesson_plan_quiz_generator_description": "Erstellen Sie Quizze aus Lektionsplänen.",
    "notion_flashcard_creator_title": "Notion-Flashcard-Ersteller",
    "notion_flashcard_creator_description": "Ändern Sie Notion-Seiten in Flashcards.",
    "client_engagement_chat_assistant_title": "Chat-Assistent für Kundenbindung",
    "client_engagement_chat_assistant_description": "Erstellen Sie Follow-up-Pläne für Kunden.",
    "lead_data_extraction_tool_title": "Lead-Datenextraktionstool",
    "lead_data_extraction_tool_description": "Daten von Lead-Websites sammeln.",
    "personalized_follow_up_generator_title": "Personalisierter Follow-up-Generator",
    "personalized_follow_up_generator_description": "Erstellen Sie Follow-up-Pläne aus Daten.",
    "competitive_knowledge_mindmap_tool_title": "Wettbewerbswissen-Mindmap-Tool",
    "competitive_knowledge_mindmap_tool_description": "Konkurrenzseiten in eine Mindmap umwandeln."
  },
  "informerHelpContent": {
    "PdfOcrInformer": "Laden Sie eine PDF Datei von Ihrem Computer hoch.",
    "FileInformer": "Laden Sie eine Datei von Ihrem Computer hoch.",
    "WebInformer": "Liste von Website-Links, einer pro Zeile.",
    "SingleWebInformer": "Geben Sie einen Website-Link zum Scrapen an.",
    "AirtableInformer": "Wählen Sie Tabellen aus, die in Airtable enthalten sein sollen.",
    "AudioInformer": "Laden Sie eine Audiodatei hoch und wählen Sie die Sprache aus.",
    "ChromeExtensionInformer": "Verbinden Sie sich mit unserer Chrome-Erweiterung.",
    "ConfluenceInformer": "Zugriff auf Confluence-Artikel mit Anmeldeinformationen.",
    "DatabaseInformer": "Direkt mit der Datenbank kommunizieren.",
    "SlackIntegration": "Omnimind in den Slack-Kanal integrieren.",
    "CSVInformer": "Laden Sie eine CSV-Datei mit Daten hoch.",
    "dropbox-file": "Fügen Sie den Link zur Dropbox-Datei ein.",
    "dropbox-folder": "Fügen Sie den Link zum Dropbox-Ordner ein.",
    "ExcelInformer": "Laden Sie Ihre Excel-Datei mit Daten hoch.",
    "gdrive-file": "Fügen Sie den Link zur Google Drive-Datei ein.",
    "gdrive-folder": "Fügen Sie den Link zum Google Drive-Ordner ein.",
    "GoogleSheetsInformer": "Geben Sie den Link zur Google Sheets-Datei ein.",
    "onedrive-file": "Fügen Sie den Link zur OneDrive-Datei ein.",
    "onedrive-folder": "Fügen Sie den Link zum OneDrive-Ordner ein.",
    "text": "Geben Sie den Text ein oder fügen Sie ihn ein.",
    "url": "Fügen Sie den Link zu einer Internetdatei ein.",
    "VimeoInformer": "Fügen Sie den Vimeo-Video-Link ein und speichern Sie das Token.",
    "xml-sitemap": "Fügen Sie den Link zur XML-Sitemap-Datei ein.",
    "youtube-channel": "Fügen Sie den YouTube-Kanal-Link ein und wählen Sie die Sprache aus.",
    "YoutubeInformer": "Fügen Sie den YouTube-Video-Link ein und wählen Sie die Sprache aus.",
    "youtube-playlist": "Fügen Sie den YouTube-Playlist-Link ein und wählen Sie die Sprache aus.",
    "BulkCSVInformer": "Laden Sie eine CSV-Datei mit Links hoch.",
    "NotionInformer": "Geben Sie das Notion-Token und den Seitenlink an.",
    "WordpressInformer": "Fügen Sie den Wordpress-Link ein und wählen Sie Kategorien aus.",
    "zendesk": "Fügen Sie den Link zum Zendesk-Hilfezentrum ein."
  }
}