{
  "validations": {
    "required": "Este campo es obligatorio",
    "isOldPasswordCorrect": "Por favor ingrese una contraseña antigua válida",
    "email": "Por favor ingrese una dirección de correo electrónico válida",
    "url": "Por favor ingrese una URL válida",
    "URL": "Por favor ingrese una URL válida",
    "min": "Por favor ingrese al menos {min} caracteres",
    "integer": "Por favor ingrese un valor entero",
    "minValue": "El valor debe ser al menos {min}",
    "maxValue": "El valor debe ser como máximo {max}",
    "max": "Por favor ingrese no más de {max} caracteres",
    "minLength": "Por favor ingrese al menos {min} caracteres",
    "maxLength": "Por favor ingrese no más de {max} caracteres",
    "requiredIf": "Este campo es obligatorio",
    "sameAs": "El valor debe ser igual al otro valor"
  },
  "promocode": {
    "the_promocode_was_successfully_redeemed": "El código promocional se ha canjeado correctamente. Para más detalles, por favor revise sus estadísticas de facturación",
    "unable_to_redeem_the_promo_code": "No se pudo canjear el código promocional. Por favor verifique el código en busca de errores tipográficos y vuelva a intentarlo, o contacte al soporte para obtener ayuda."
  }
}