{
  "mermaidMindmapWidgetActions": {
    "export": "Esporta",
    "click_here": "Clicca qui per aprire gli editor di mappe mentali",
    "theme": "Tema",
    "select_timezone": "Seleziona il tuo fuso orario",
    "language": "Lingua",
    "create_map": "Crea mappa",
    "regenerate_tooltip": "Clicca qui per rigenerare l'intera mappa mentale. Nota che ciò sostituirà completamente la mappa attuale. Usa questa opzione solo se non sei soddisfatto della mappa esistente. Se desideri apportare piccoli aggiustamenti, clicca semplicemente il pulsante 'Modifica' al posto di questo.",
    "export_svg": "Esporta SVG",
    "export_png": "Esporta PNG"
  },
  "mermaidMindmapWidgetMap": {
    "mermaid_mind_map": "Mappa mentale Mermaid",
    "what_is_mind_map": "Cos'è una mappa mentale",
    "a_mermaid_mind_map_is_a_visualization_tool": "Una mappa mentale Mermaid è uno strumento di visualizzazione creato con la libreria Mermaid.js, che consente l'organizzazione e la rappresentazione delle informazioni in un formato gerarchico. Utilizzando una sintassi semplice simile al markdown, puoi creare mappe mentali che offrono una struttura chiara e visiva per idee o progetti complessi. Questo metodo aiuta significativamente nelle fasi di brainstorming e pianificazione, aiutando a delineare e chiarire dati o concetti complessi in modo efficiente. Inoltre, l'input di testo semplice è facilmente modificabile, facilitando aggiornamenti e adattamenti rapidi. Può essere integrato senza problemi in varie piattaforme, semplificando la condivisione e l'inserimento di queste mappe mentali.",
    "steps_to_improve_your_mind_map": "Passaggi per migliorare la tua mappa mentale",
    "clarify_your_intent": "Chiarisci la tua intenzione",
    "use_the_custom_prompt_to": "Utilizza il prompt personalizzato per specificare le tue esigenze durante la creazione della mappa mentale.",
    "declutter_knowledge": "Svuota la conoscenza",
    "navigate_to_the_knowledge_section": "Vai alla sezione \"Conoscenza\" (accanto alle impostazioni). Elimina parti ridondanti e informazioni superflue per mantenere concentrazione e chiarezza.",
    "adjust_with_editors": "Regola con gli editor",
    "employ_the_map_editor_to_correct": "Utilizza l'editor di mappe per correggere e regolare la sintassi Mermaid. Utilizza l'editor dei nodi per aggiungere, eliminare o sostituire nodi nella mappa mentale per un migliore allineamento.",
    "by_following_these_steps": "Seguendo questi passaggi, la tua mappa mentale sarà più snella, focalizzata e adatta alle tue esigenze.",
    "map_should_highlight_the_key_aspects_and": "La mappa dovrebbe evidenziare gli aspetti chiave e le relazioni gerarchiche del {context}. Inizia la mappa con un singolo nodo radice che rappresenti in modo sintetico il concetto o il tema principale. Sviluppa rami per esplorare sottotemi o concetti correlati, fornendo etichette chiare per ogni nodo. Mantieni un flusso chiaro e logico tra nodi e sottonodi collegati, utilizzando l'indentazione corretta per indicare la gerarchia. Mantieni i nodi brevi e concisi, assicurandoti che siano direttamente correlati ai nodi adiacenti. Nella risposta, attieniti al modello",
    "with_adequate_spacing_to_demonstrate": "con uno spaziamento adeguato per mostrare le relazioni gerarchiche. Il tuo output dovrebbe essere testuale, formattato in modo da essere visualizzato correttamente con Mermaid e privo di parole in maiuscolo o simboli speciali diversi da quelli necessari per la sintassi Mermaid.",
    "we_are_unable_to_create_a_mindmap": "Non siamo in grado di creare una mappa mentale basata sul contenuto fornito. Puoi provare ad aprire le impostazioni del widget e modificarle per ottenere risultati migliori!",
    "something_went_awry": "Qualcosa è andato storto! Ma stiamo cercando di generare nuovamente la tua mappa!",
    "please_generate_a_mermaid_mind": "Genera una mappa mentale Mermaid basata sul {context} in",
    "language": "lingua"
  },
  "mermaidMindmapWidgetMapEditor": {
    "map_editor": "Editor di mappe",
    "manipulate_the_structure_and": "Manipola la struttura e il flusso della tua mappa in modo semplice regolando le indentazioni e aggiungendo nuove righe per creare nodi, facilitando una visualizzazione personalizzata e approfondita delle tue idee. Preparati a perfezionare e ridefinire la tua esperienza di mappatura mentale, dove ogni modifica ti avvicina alla perfezione."
  },
  "mermaidMindmapWidgetNodesEditor": {
    "nodes_editor": "Editor dei nodi",
    "dont_settle_for_nodes_that_dont_resonate": "Non accontentarti di nodi che non risuonano; con l'editor dei nodi, puoi rigenerare facilmente qualsiasi elemento per adattarlo meglio alla tua visione o aggiungere nuovi nodi per ampliare le tue idee.",
    "click_here_to_re_generate_this_node": "Clicca qui per rigenerare questo nodo. Assicurati di fornire istruzioni chiare.",
    "click_here_to_add_a_brand_new_node": "Clicca qui per aggiungere un nuovo nodo. Tutti gli altri nodi rimarranno intatti. Assicurati di fornire istruzioni chiare.",
    "add_node": "Aggiungi nodo",
    "add_instructions": "Aggiungi istruzioni",
    "enter_your_hint_here": "Inserisci qui il tuo suggerimento",
    "this_node": "Questo nodo",
    "and_all_it_s_children_will": "e tutti i suoi figli saranno rigenerati.",
    "you_have_the_option_to": "Hai la possibilità di migliorare la tua mappa mentale aggiungendo un nuovo nodo.",
    "feel_free_to_include_any_specific": "Sentiti libero di includere eventuali dettagli o requisiti specifici come suggerimento, se necessario."
  }
}