import { gql } from '@apollo/client/core';
import { ProjectFields } from '../fragments';

export const GET_PROJECTS_LIST = gql`
  ${ProjectFields}
  subscription GetProjects($id: uuid!) {
    projects(
      where: { user_to_projects: { user_id: { _eq: $id } } }
      order_by: { created_at: asc }
    ) {
      ...ProjectFields
    }
  }
`;

export const GET_WIDGET = gql`
  subscription GetWidget($id: uuid!) {
    widgets(where: { id: { _eq: $id } }) {
      id
      name
      type_id
      is_default
      settings
      project_id
      project {
        name
        projects_agents {
          id
        }
      }
    }
  }
`;

export const GET_BILLING_USER_PACKAGES = gql`
  subscription GetBillingUserPackages($id: uuid!) {
    billing_user_packages(
      where: { user_id: { _eq: $id }, portal_id: { _eq: "8854cc58-e585-436f-990f-87466e52794c" } }
    ) {
      id
      package_features
      cost
    }
  }
`;

export const GET_IS_PAID_USER = gql`
  subscription GetIsPaidUser($id: uuid!) {
    profiles(where: { id: { _eq: $id } }) {
      billing(where: { status: { _eq: "active" } }, order_by: { updated_at: desc }, limit: 1) {
        id
        portal_id
        units_amount
        units_spent
      }
    }
  }
`;
