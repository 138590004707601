{
  "settingsBehaviour": {
    "behavior": "Comportamento",
    "choose_option": "Escolha a opção que deseja editar",
    "label_for_examples_of_questions": "Rótulo para Exemplos de perguntas que você pode fazer ao bot",
    "before_you_start_to_work_with_widget": "Antes de começar a trabalhar com o widget, veja exemplos de perguntas que você pode fazer ao bot",
    "enter_label_for_examples_of_questions": "Digite o rótulo para Exemplos de perguntas que você pode fazer ao bot...",
    "split_your_questions_with_a_new_line": "Separe suas perguntas com uma nova linha.",
    "welcome_messages": "Mensagens de boas-vindas",
    "enter_messages": "Digite as mensagens, separadas por duas quebras de linha.",
    "start_each_message": "Inicie cada mensagem em uma nova linha. Pressione a tecla Enter duas vezes para separar as mensagens.",
    "type_messages": "Digite as mensagens, pressione Enter duas vezes para separar.",
    "add_a_custom_welcome_message_interval": "Adicione um intervalo de mensagem de boas-vindas personalizado (segundos)",
    "enter_welcome_message_interval": "Digite o intervalo de mensagem de boas-vindas...",
    "add_a_custom_welcome_message": "Altere a frase \"Não sei a resposta\"",
    "when_your_bot_isn_t_sure_about": "Quando o seu bot não tem certeza sobre a resposta, ele começa a frase com \"Provavelmente.\" Você pode alterar essa frase e ela aparecerá no lugar.",
    "most_likely": "Provavelmente...",
    "add_a_custom_message": "Adicione uma mensagem personalizada ao final de cada resposta do OmniMind",
    "enter_custom_message": "Digite a mensagem personalizada...",
    "default_language": "Idioma padrão",
    "custom_language": "Idioma personalizado",
    "show_followups_questions": "Mostrar perguntas de acompanhamento",
    "live_connect": "Conexão ao vivo",
    "none": "Nenhum",
    "button_name_for_leaving_a_message": "Nome do botão para deixar uma mensagem",
    "leave_a_message": "Deixar uma mensagem",
    "offline_message": "Mensagem offline",
    "button_name_for_offline_message": "Nome do botão para mensagem offline",
    "dialogs": "Conversas",
    "anonymize_sensitive_data": "Anonimizar dados sensíveis",
    "language_of_the_data": "Idioma dos dados",
    "language": "Idioma",
    "replace_value": "Substituir valor",
    "replace": "Substituir"
  },
  "behaviourDialogsSetting": {
    "history_mode": "Modo de histórico",
    "collect_user_mode": "Modo de coleta de usuário",
    "track_all_messages": "Rastrear todas as mensagens",
    "track_only_messages_with": "Rastrear apenas feedbacks negativos e solicitações de suporte",
    "dont_track": "Não rastrear",
    "collect_username_and_email_with_form": "Coletar nome de usuário e e-mail com formulário",
    "provide_username_and_email_by_yourself": "Fornecer nome de usuário e e-mail por conta própria",
    "do_not_collect_username_and_email": "Não coletar nome de usuário e e-mail"
  },
  "behaviourLiveConnectAction": {
    "selected_option_will_be_applied": "A opção selecionada será aplicada como ação para o botão",
    "live_connect_options": "Opções de conexão ao vivo",
    "your_custom_js_code": "Seu código JS personalizado",
    "ensure": "Verifique se",
    "is_correctly_set_up": "está configurado corretamente em seu projeto. Precisa de ajuda?",
    "check_here": "Verifique aqui."
  },
  "behaviourOfflineMessageAction": {
    "selected_option_will_be_applied_as_action_for_button": "A opção selecionada será aplicada como ação para o botão",
    "offline_message_options": "Opções de mensagem offline",
    "your_custom_js_code": "Seu código JS personalizado",
    "your_email": "Seu e-mail"
  },
  "behaviourRateLimit": {
    "enable_rate_limit": "Ativar limite de taxa",
    "rate_limit": "Limite de taxa",
    "enable_rate_limit_message": "Limite o número de mensagens enviadas deste widget por hora"
  }
}