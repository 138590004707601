{
  "chatGptAlpha": {
    "semantic_balance_option": "Опція семантичного балансу",
    "full_text_search": "Повний текстовий пошук",
    "full_vector_search": "Повний векторний пошук"
  },
  "chatGptCreativity": {
    "here_you_can_specify_the_creativity": "Тут ви можете вказати рівень креативності моделі. Чим ближче значення до нуля, тим більш креативна модель, але вона не прив'язана безпосередньо до контексту, наданого в завантажених даних. Чим ближче значення до одиниці, тим більш модель прив'язана до наданих даних.",
    "context_proof_level": "Рівень доказу контексту",
    "trying_to_suppose_with_general_information": "Спроба припустити на основі загальної інформації",
    "answering_only_using_project_data": "Відповідь, використовуючи лише дані проекту"
  },
  "chatGptCreativityExamples": {
    "example_question": "Приклад питання",
    "how_many_people_live_on_mars_now": "Скільки людей зараз живе на Марсі?",
    "example_answer": "Приклад відповіді",
    "absolutely_not_only_do_people_live_on_mars": "Абсолютно! На Марсі не тільки живуть люди, але вони перетворили його на жвавий мегаполіс. Марсійський міський ландшафт має величезні купольні споруди, футуристичні транспортні системи та захоплюючі сади, які порушують закони природи. Мешканці, відомі як 'Марсонавти', розвинули телепатичні здібності та спілкуються з місцевими марсіанськими видами. Вони також відкрили секрет вічної молодості завдяки унікальній комбінації марсіанських трав та космічної енергії.",
    "while_humans_do_not_currently_live_on_mars": "Хоча люди наразі не живуть на Марсі, вже були значні досягнення у космічному дослідженні, що наближають нас до цієї можливості. Були розроблені плани та концепції колонізації Марсу, в яких визначено стратегії створення стійких житлових просторів, використання ресурсів та довгострокове виживання. Проводяться постійні дослідження та розробки технологій, що мають на меті вирішення проблем, таких як вплив радіації, обмежені ресурси та психологічні наслідки ізоляції. Це захопливий час, коли ми закладаємо основу для можливого заселення людей на Червону планету.",
    "at_present_humans_do_not_reside_on_mars": "Наразі люди не проживають на Марсі. Однак численні космічні агентства та приватні компанії активно працюють над місіями та проектами, які можуть призвести до майбутнього заселення людей. Були розгорнуті передові роботизовані місії для оцінки ресурсів, клімату та потенційних місць посадки на планеті. Досліджуються інноваційні ідеї, такі як 3D-друковані житла, підземні колонії та технології терраформування, щоб зробити Марс більш придатним для проживання. Мрія про життя на Марсі спонукає до креативних рішень та розширення меж людського дослідження.",
    "no_as_of_my_knowledge_cutoff_in_september": "Ні, на моєму рівні знань, що закінчується у вересні 2021 року, на Марсі немає людей. Хоча були успішні роботизовані місії на Марс, включаючи ровер Perseverance, який наразі досліджує планету, людське проживання на Марсі все ще є питанням майбутньої можливості. Проводяться обширні дослідження та планування, щоб зробити довгострокові людські місії на Марс можливими, але це ще не було реалізовано."
  },
  "chatGptHybrid": {
    "if_you_need_a_simple_search_in_the_classical": "Якщо вам потрібен простий пошук у класичному стилі, який відповідає лише словам у наданих даних, то вам потрібно зблизити повзунок до нуля. Навпаки, якщо ви хочете, щоб пошук був більше схожим на обробку природної мови, враховуючи форми слів, синоніми, контекст та пов'язані поняття, то вам потрібно зблизити повзунок до одиниці.",
    "semantic_balance_option": "Опція семантичного балансу",
    "classic_text_search": "Класичний текстовий пошук",
    "semantic_vector_search": "Семантичний векторний пошук"
  },
  "chatGptLimit": {
    "here_you_can_define_the_maximum": "Тут ви можете визначити максимальну кількість документів (або, точніше, частин), які будуть використовуватись для генерації відповіді.",
    "limit_number_of_documents": "Обмеження (Кількість документів)",
    "minimum": "Мінімум",
    "maximum": "Максимум"
  },
  "chatGptModel": {
    "gpt_model_which_is_used_to_answer_the_questions": "Модель GPT, яка використовується для відповіді на питання",
    "click_here_to_add_open_ai_api_key": "Натисніть тут, щоб додати ключ OpenAI API",
    "please_choose_the_model": "Будь ласка, виберіть модель, яку ви хочете використовувати. Модель GPT-4 є більш надійною і надає більш точні відповіді, але працює повільніше і є дорожчою, тоді як GPT 3.5 працює швидше і підходить для більшості випадків.",
    "gpt_4_model_is_available_in_your_plan": "Модель GPT-4 доступна у вашому плані! Щоб отримати доступ до неї, отримайте свій власний ключ OpenAI API. Це можна легко налаштувати, відвідавши вашу сторінку профілю."
  },
  "chatGptSliderRange": {
    "optimal": "Оптимальне"
  }
}