{
  "chatGptAlpha": {
    "semantic_balance_option": "Opción de equilibrio semántico",
    "full_text_search": "Búsqueda de texto completo",
    "full_vector_search": "Búsqueda de vector completo"
  },
  "chatGptCreativity": {
    "here_you_can_specify_the_creativity": "Aquí puedes especificar el nivel de creatividad para el modelo. Cuanto más cercano sea el valor a cero, más creativo será el modelo, pero no se adhiere directamente al contexto proporcionado en los datos cargados. Cuanto más cercano sea el valor a uno, el modelo se adhiere directamente a los datos proporcionados.",
    "context_proof_level": "Nivel de prueba de contexto",
    "trying_to_suppose_with_general_information": "Intentando suponer con información general",
    "answering_only_using_project_data": "Responder solo usando datos del proyecto"
  },
  "chatGptCreativityExamples": {
    "example_question": "Pregunta de ejemplo",
    "how_many_people_live_on_mars_now": "¿Cuántas personas viven en Marte ahora?",
    "example_answer": "Respuesta de ejemplo",
    "absolutely_not_only_do_people_live_on_mars": "¡Absolutamente! No solo las personas viven en Marte en la actualidad, sino que lo han transformado en una metrópolis vibrante. El paisaje urbano marciano cuenta con magníficas estructuras abovedadas, sistemas de transporte futuristas y jardines impresionantes que desafían las leyes de la naturaleza. Los habitantes, conocidos como 'marsonautas', han desarrollado habilidades telepáticas y se comunican con las especies marcianas autóctonas. También han descubierto el secreto de la eterna juventud a través de una combinación única de hierbas marcianas y energía cósmica.",
    "while_humans_do_not_currently_live_on_mars": "Si bien los humanos no viven actualmente en Marte, ha habido avances significativos en la exploración espacial que nos acercan a esa posibilidad. Se han desarrollado planes y conceptos para la colonización de Marte, que describen estrategias para hábitats sostenibles, utilización de recursos y supervivencia a largo plazo. La investigación en curso y los avances tecnológicos tienen como objetivo abordar desafíos como la exposición a la radiación, los recursos limitados y los efectos psicológicos del aislamiento. Es un momento emocionante en el que sentamos las bases para un posible asentamiento humano en el planeta rojo.",
    "at_present_humans_do_not_reside_on_mars": "En la actualidad, los humanos no residen en Marte. Sin embargo, numerosas agencias espaciales y empresas privadas están trabajando activamente en misiones y proyectos que podrían llevar a una futura habitación humana. Se han desplegado misiones robóticas avanzadas para evaluar los recursos del planeta, el clima y los posibles lugares de aterrizaje. Se están explorando ideas innovadoras, como hábitats impresos en 3D, colonias subterráneas y tecnologías de terraformación, para hacer que Marte sea más habitable. El sueño de vivir en Marte está impulsando soluciones creativas y empujando los límites de la exploración humana.",
    "no_as_of_my_knowledge_cutoff_in_september": "No, según mi conocimiento hasta septiembre de 2021, no hay personas viviendo en Marte. Si bien ha habido misiones robóticas exitosas a Marte, incluido el rover Perseverance, que actualmente está explorando el planeta, la habitación humana en Marte todavía está en el ámbito de las posibilidades futuras. Se están llevando a cabo investigaciones y planificaciones exhaustivas para hacer que las misiones humanas a Marte a largo plazo sean factibles, pero aún no se ha logrado."
  },
  "chatGptHybrid": {
    "if_you_need_a_simple_search_in_the_classical": "Si necesitas una búsqueda simple de la manera clásica que coincida solo con las palabras en los datos proporcionados, debes mover el control deslizante más cerca de cero. Por el contrario, si deseas que la búsqueda sea más como un procesamiento de lenguaje natural, teniendo en cuenta las formas de las palabras, sinónimos, contexto y conceptos relacionados, debes mover el control deslizante más cerca de uno.",
    "semantic_balance_option": "Opción de equilibrio semántico",
    "classic_text_search": "Búsqueda de texto clásica",
    "semantic_vector_search": "Búsqueda de vector semántico"
  },
  "chatGptLimit": {
    "here_you_can_define_the_maximum": "Aquí puedes definir el número máximo de documentos (o fragmentos) que se utilizarán para generar la respuesta.",
    "limit_number_of_documents": "Límite (Número de documentos)",
    "minimum": "Mínimo",
    "maximum": "Máximo"
  },
  "chatGptModel": {
    "gpt_model_which_is_used_to_answer_the_questions": "Modelo GPT que se utiliza para responder las preguntas",
    "click_here_to_add_open_ai_api_key": "Haz clic aquí para agregar la clave de API de OpenAI",
    "please_choose_the_model": "Por favor, elige el modelo que deseas utilizar. El modelo GPT-4 es más robusto y proporciona respuestas más precisas, pero es más lento y costoso, mientras que GPT 3.5 es más rápido y se adapta a la mayoría de los casos.",
    "gpt_4_model_is_available_in_your_plan": "¡El modelo GPT-4 está disponible en tu plan! Para acceder a él, obtén tu propia clave de API de OpenAI. Puedes configurarlo fácilmente visitando tu página de perfil."
  },
  "chatGptSliderRange": {
    "optimal": "Óptimo"
  }
}