{
  "validations": {
    "required": "Dieses Feld ist erforderlich",
    "isOldPasswordCorrect": "Bitte geben Sie ein gültiges altes Passwort ein",
    "email": "Bitte geben Sie eine gültige E-Mail-Adresse ein",
    "url": "Bitte geben Sie eine gültige URL ein",
    "URL": "Bitte geben Sie eine gültige URL ein",
    "min": "Bitte geben Sie mindestens {min} Zeichen ein",
    "integer": "Bitte geben Sie einen ganzzahligen Wert ein",
    "minValue": "Der Wert muss mindestens {min} sein",
    "maxValue": "Der Wert darf höchstens {max} sein",
    "max": "Bitte geben Sie maximal {max} Zeichen ein",
    "minLength": "Bitte geben Sie mindestens {min} Zeichen ein",
    "maxLength": "Bitte geben Sie maximal {max} Zeichen ein",
    "requiredIf": "Dieses Feld ist erforderlich",
    "sameAs": "Der Wert muss dem anderen Wert entsprechen"
  },
  "promocode": {
    "the_promocode_was_successfully_redeemed": "Der Promocode wurde erfolgreich eingelöst. Weitere Details finden Sie in Ihren Abrechnungsstatistiken",
    "unable_to_redeem_the_promo_code": "Der Promo-Code konnte nicht eingelöst werden. Bitte überprüfen Sie den Code auf Tippfehler und versuchen Sie es erneut oder wenden Sie sich an den Support."
  }
}