{
  "databaseSettings": {
    "databaseSettings": "Configuración de la base de datos",
    "configure_the_connection_to_your_database": "Configura la conexión a tu base de datos y selecciona las tablas que deseas utilizar en tus consultas.",
    "cancel": "Cancelar",
    "back": "Atrás",
    "save": "Guardar",
    "fetch_resources": "Obtener recursos",
    "resources_fetched_successfully": "Recursos obtenidos exitosamente",
    "something_went_wrong": "Algo salió mal",
    "failed_to_load_settings": "Error al cargar la configuración"
  },
  "databaseSettingsConnectInformation": {
    "connect_information": "Información de conexión",
    "type_of_database": "Tipo de base de datos",
    "host": "Host",
    "port": "Puerto",
    "database": "Base de datos",
    "username": "Nombre de usuario",
    "password": "Contraseña"
  },
  "databaseSettingsForm": {
    "host": "Host"
  },
  "databaseSettingsHost": {
    "type_of_database": "Tipo de base de datos",
    "select_type_of_connection": "Selecciona el tipo de conexión",
    "host": "Host",
    "address_to_your_database": "dirección de tu base de datos",
    "address_of_your_db": "Dirección de tu base de datos",
    "port_to_your_database": "puerto de tu base de datos",
    "port_of_your_db": "Puerto de tu base de datos",
    "port": "Puerto",
    "username": "Nombre de usuario",
    "username_to_your_database": "nombre de usuario de tu base de datos",
    "username_of_your_db": "Nombre de usuario de tu base de datos",
    "password": "Contraseña",
    "password_to_your_database": "contraseña de tu base de datos",
    "password_of_your_db": "Contraseña de tu base de datos",
    "database": "Base de datos",
    "your_database_name": "nombre de tu base de datos",
    "database_name": "Nombre de tu base de datos en la base de datos"
  },
  "databaseSettingsTables": {
    "general_instructions": "Instrucciones generales",
    "there_goes_general_description_about": "Aquí va una descripción general sobre la base de datos, su lógica o algunos detalles específicos del proyecto...",
    "select_all": "Seleccionar todo",
    "clean_all_selected": "Limpiar todo lo seleccionado",
    "search": "Buscar...",
    "active": "Activo",
    "table": "Tabla",
    "comment": "Comentario",
    "description": "Descripción"
  },
  "databaseSettingsURL": {
    "host": "Host",
    "address_to_your_database": "dirección de tu base de datos",
    "address_of_your_db": "Dirección de tu base de datos"
  }
}