{
  "knowledgeFilterSourcesModal": {
    "filter": "Filtrar",
    "apply": "Aplicar",
    "cancel": "Cancelar",
    "by_title": "Por título",
    "by_period": "Por período",
    "search_for_resource_title": "Buscar por título de recurso..."
  },
  "knowledgeSources": {
    "no_data_so_far": "Sin datos hasta ahora.",
    "type": "Tipo",
    "title": "Título",
    "date": "Fecha",
    "actions": "Acciones",
    "delete_these_items": "¿Eliminar estos elementos?",
    "are_you_sure_you_want_to_delete_selected_items": "¿Estás seguro de que quieres eliminar los elementos seleccionados?",
    "reupload_these_items": "¿Volver a subir estos elementos?",
    "this_action_cannot_be_undone": "Esta acción no se puede deshacer.",
    "are_you_sure_you_want_to_delete_and_reupload": "¿Estás seguro de que quieres eliminar y volver a subir los elementos seleccionados?",
    "reupload": "Volver a subir",
    "the_resources_were_removed": "Los recursos fueron eliminados.",
    "an_error_occurred_while_deleting_resources": "Ocurrió un error al eliminar los recursos",
    "sorry_we_re_unable_to_open_this_resource": "Lo sentimos, no podemos abrir este recurso directamente ya que no es un recurso de conocimiento de texto.",
    "show_progress": "Haz clic aquí para ver el progreso"
  },
  "knowledgeSourcesActions": {
    "view": "Ver",
    "refresh_from_source_coming_soon": "Actualizar desde la fuente (¡Próximamente!)",
    "delete": "Eliminar"
  },
  "knowledgeSourcesToolbar": {
    "resources_selected": "Recursos seleccionados:"
  },
  "knowledgeToolbarSourcesFilters": {
    "by_date": "Por fecha"
  },
  "knowledgeToolbarSourcesUpdater": {
    "refresh_from_source_available_soon": "Actualizar desde la fuente (¡Próximamente!)"
  }
}