{
  "knowledgeFilterSourcesModal": {
    "filter": "Фільтр",
    "apply": "Застосувати",
    "cancel": "Скасувати",
    "by_title": "За назвою",
    "by_period": "За періодом",
    "search_for_resource_title": "Пошук за назвою ресурсу..."
  },
  "knowledgeSources": {
    "no_data_so_far": "Даних поки немає.",
    "type": "Тип",
    "title": "Назва",
    "date": "Дата",
    "actions": "Дії",
    "delete_these_items": "Видалити ці елементи?",
    "are_you_sure_you_want_to_delete_selected_items": "Ви впевнені, що хочете видалити вибрані елементи?",
    "reupload_these_items": "Перезавантажити ці елементи?",
    "this_action_cannot_be_undone": "Цю дію неможливо скасувати.",
    "are_you_sure_you_want_to_delete_and_reupload": "Ви впевнені, що хочете видалити та перезавантажити вибрані елементи?",
    "reupload": "Перезавантажити",
    "the_resources_were_removed": "Ресурси були видалені.",
    "an_error_occurred_while_deleting_resources": "Під час видалення ресурсів сталася помилка",
    "sorry_we_re_unable_to_open_this_resource": "На жаль, ми не можемо відкрити цей ресурс безпосередньо, оскільки він не є текстовим ресурсом.",
    "show_progress": "Натисніть тут, щоб побачити прогрес"
  },
  "knowledgeSourcesActions": {
    "view": "Переглянути",
    "refresh_from_source_coming_soon": "Оновити з джерела (Незабаром!)",
    "delete": "Видалити"
  },
  "knowledgeSourcesToolbar": {
    "resources_selected": "Вибрано ресурсів:"
  },
  "knowledgeToolbarSourcesFilters": {
    "by_date": "За датою"
  },
  "knowledgeToolbarSourcesUpdater": {
    "refresh_from_source_available_soon": "Оновити з джерела (Незабаром!)"
  }
}