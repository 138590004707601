{
  "portalPackageItem": {
    "name_is_not_provided": "Nome non fornito",
    "click_to_edit_the_package": "Clicca per modificare il pacchetto",
    "click_to_delete_the_package": "Clicca per eliminare il pacchetto",
    "package_name": "Nome del pacchetto",
    "your_package_name": "Il nome del tuo pacchetto",
    "description": "Descrizione",
    "your_package_description": "La descrizione del tuo pacchetto",
    "model": "Modello",
    "package_price": "Prezzo del pacchetto",
    "subscription_cost_for_the_package": "Costo di abbonamento per il pacchetto",
    "cost_per_symbols": "Costo per {symbols_quantity} simboli",
    "cost_for_characters": "Costo per {characters_quantity} caratteri quando si effettua una query a OpenAI (progettato per evitare spese eccessive per l'utente). In media, una richiesta è di {average} caratteri",
    "approx_number_of_requests": "numero approssimativo di richieste",
    "trial_days": "Giorni di prova",
    "links": "Link",
    "create_portal_link": "Crea link del portale",
    "delete_this_project": "Eliminare questo progetto?",
    "delete_project": "Elimina progetto",
    "package_will_be_deleted": "Il pacchetto verrà eliminato. Questa azione non può essere annullata",
    "the_item_has_been_successfully_deleted": "L'elemento è stato eliminato con successo."
  },
  "portalPackages": {
    "no_packages_so_far": "Nessun pacchetto finora",
    "apply": "Applica",
    "stripe_account_is_not_configured": "L'account Stripe non è configurato",
    "packages_successfully_saved": "Pacchetti salvati con successo"
  }
}