<template>
  <div class="flex" :class="containerClass">
    <div
      v-if="$slots.icon"
      class="flex items-center justify-center rounded-l-md border border-r-0 border-white-white100 px-3 dark:border-dark-blue100 dark:text-white"
    >
      <slot name="icon" />
    </div>
    <input
      v-if="rows === 1"
      :id="field.id || randomId"
      :value="modelValue"
      :required="field.required"
      :type="type"
      :readonly="readonly"
      :disabled="disabled"
      :placeholder="placeholder"
      :max="max"
      :min="min"
      :class="inputClass + ($slots.icon ? ' rounded-l-none' : '')"
      @input="onInput"
      @focus="emit('focus')"
      @blur="emit('blur')"
      @keydown="preventSpaceButton"
      @keypress="preventSpaceButton"
    />
    <textarea
      v-else-if="isTextAutoGrow"
      ref="textareaRef"
      :rows="rows"
      :readonly="readonly"
      :value="modelValue"
      :required="field.required"
      :disabled="disabled"
      :placeholder="placeholder"
      :class="inputClass + ($slots.icon ? ' rounded-l-none' : '')"
      tabindex="1"
      @input="handleInput"
      @focus="emit('focus')"
      @blur="emit('blur')"
      @keydown="preventSpaceButton"
      @keypress="preventSpaceButton"
    />
    <textarea
      v-else
      :id="field.id"
      :rows="rows"
      :readonly="readonly"
      :value="modelValue"
      :required="field.required"
      :disabled="disabled"
      :placeholder="placeholder"
      :class="inputClass + ($slots.icon ? ' rounded-l-none' : '')"
      tabindex="1"
      @input="emit('update:modelValue', ($event.target as HTMLInputElement).value)"
      @focus="emit('focus')"
      @blur="emit('blur')"
      @keydown="preventSpaceButton"
      @keypress="preventSpaceButton"
    />
  </div>
</template>

<script setup lang="ts">
import { correctDecimalValue } from '@/helpers/correctDecimalValue';
import { computed, inject, ref, onMounted } from 'vue';
import { generateUuid } from '@/helpers/generateUuid';
import { cva } from 'class-variance-authority';
import { useVModel } from '@vueuse/core';

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'text',
  },
  rows: {
    type: Number,
    default: 1,
  },
  placeholder: {
    type: String,
    default: 'text',
  },
  class: {
    type: [String],
    default: '',
  },
  modelValue: {
    type: [String, Number],
    default: '',
  },
  containerClass: {
    type: String,
    default: '',
  },
  required: Boolean,
  invalid: Boolean,
  disabled: Boolean,
  ariaDescribedBy: {
    type: String,
    default: '',
  },
  resize: {
    type: Boolean,
    default: true,
  },
  isTextAutoGrow: {
    type: Boolean,
    default: false,
  },
  correctDecimal: {
    type: Boolean,
    default: false,
  },
  readonly: Boolean,
  min: { type: Number, default: undefined },
  max: { type: Number, default: undefined },
});

const textareaRef = ref<HTMLTextAreaElement | null>(null);

const randomId = generateUuid();

const emit = defineEmits(['update:modelValue', 'focus', 'blur']);

const resize = () => {
  if (!props.isTextAutoGrow) return;

  const element = textareaRef.value;
  if (element) {
    element.style.height = 'auto';
    element.style.height = `${element.scrollHeight + 2}px`;
  }
};

const handleInput = (e: Event) => {
  const target = e.target as HTMLTextAreaElement;

  resize();

  emit('update:modelValue', target.value.trim());
};

const field = inject('field', props);

const inputClass = computed(() => {
  return cva(`form-input ${props.class}`, {
    variants: {
      invalid: {
        true: `!border-red-500`,
      },
      resize: {
        false: `resize-none`,
      },
    },
  })({
    invalid: props.invalid,
    resize: props.resize,
  });
});

const preventSpaceButton = (e: KeyboardEvent) => {
  if (e.key === ' ') {
    e.stopPropagation();
  }
};

onMounted(() => {
  resize();
});

const model = useVModel(props, 'modelValue', emit);

const onInput = (event: Event) => {
  emit('update:modelValue', (event.target as HTMLInputElement).value.trim());
  props.correctDecimal && correctDecimalValue(event as InputEvent, props.type, model);
};
</script>

<style scoped></style>
