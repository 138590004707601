{
  "billingInfo": {
    "select_project": "Projekt auswählen",
    "required": "Erforderlich",
    "select_period": "Zeitraum auswählen",
    "your_current_package": "Ihr aktuelles Paket",
    "cancelled_at": "gekündigt am",
    "next_billing_date": "nächster Abrechnungstermin",
    "to_cancel_or_update_your_payment_plan": "Um Ihr Zahlungsplan zu kündigen oder zu aktualisieren, gehen Sie bitte zum Kundenzahlungsportal.",
    "customer_payment_portal": "Kundenzahlungsportal",
    "cancel_subscription": "Abonnement kündigen",
    "remove_your_account": "Ihr Konto entfernen",
    "remove_account": "Konto entfernen",
    "reactivate_account": "Konto reaktivieren",
    "no_package_found": "Kein Paket gefunden.",
    "let_s_select_a_suitable_package": "Lassen Sie uns ein passendes Paket auswählen.",
    "select_your_package": "Wählen Sie Ihr Paket aus",
    "the_account_will_deleted_at": "Das Konto wird am gelöscht",
    "cancel_subscription_and_remove_account": "Abonnement kündigen und Konto entfernen",
    "the_subscription_will_cancelled_at": "Das Abonnement wird am gekündigt",
    "cancel_current_subscription": "Aktuelles Abonnement kündigen",
    "something_went_awry": "Etwas ist schief gelaufen"
  },
  "billings": {
    "billing_details": "Abrechnungsdetails",
    "activity_summary": "Aktivitätsübersicht"
  },
  "billingsActivity": {
    "purchase_history": "Kaufverlauf",
    "request_history": "Anforderungsverlauf",
    "request_analytics": "Analytik"
  },
  "billingsActivityPurchase": {
    "no_data_so_far": "Bisher keine Daten.",
    "download_invoice": "Rechnung herunterladen",
    "date": "Datum",
    "amount": "Betrag",
    "package": "Paket",
    "status": "Status",
    "invoice": "Rechnung"
  },
  "billingsActivityRequest": {
    "select_project": "Projekt auswählen",
    "required": "Erforderlich",
    "select_period": "Zeitraum auswählen",
    "apply": "Anwenden",
    "no_data_so_far": "Bisher keine Daten.",
    "created": "Erstellt",
    "entity": "Entität",
    "cost": "Kosten"
  },
  "billingsCurrentPackage": {
    "current_package": "Aktuelles Paket",
    "click_here_to_see_package_info": "Klicken Sie hier, um Paketinformationen anzuzeigen",
    "cancelled_at": "gekündigt am",
    "deleted_at": "gelöscht am",
    "trial_ends_at": "Probezeitraum endet am",
    "next_billing_date": "nächster Abrechnungstermin",
    "change_package": "Paket ändern",
    "add_package": "Paket hinzufügen",
    "cancel_subscription": "Abonnement kündigen",
    "the_subscription_will_cancelled_at": "Das Abonnement wird am gekündigt",
    "cancel_current_subscription": "Aktuelles Abonnement kündigen",
    "the_subscription_will_delete_at": "Das Abonnement wird am gelöscht",
    "something_went_wrong": "Etwas ist schief gelaufen!"
  },
  "billingsPackageInfo": {
    "package_information": "Paketinformationen",
    "marketplace": "Marktplatz",
    "learning_data": "Lernmaterial",
    "requests": "Anfragen",
    "on_average_one_request_contains": "Im Durchschnitt enthält eine Anfrage",
    "characters": "Zeichen",
    "no_data_so_far": "Bisher keine Daten"
  },
  "billingsPackageInfoItem": {
    "upgrade_to_add_more": "Upgrade, um mehr hinzuzufügen"
  },
  "billingsUpgradePackageModal": {
    "expand_package": "Paket erweitern"
  },
  "chart": {
    "consumption_graph": "Verbrauchsdiagramm"
  },
  "claimOffer": {
    "fetching_a_discount_data": "Rabattdaten werden abgerufen...",
    "claiming_the_offer": "Angebot wird beansprucht...",
    "are_you_considering_our": "Überlegen Sie sich unser",
    "discount_the": "Rabatt? Das",
    "month_plan_is_now_just": "Monatspaket kostet jetzt nur noch",
    "month": "Monat",
    "think_it_over": "Denken Sie darüber nach!"
  },
  "finalStep": {
    "your_subscription_has_been_successfully": "Ihr Abonnement wurde erfolgreich gekündigt. Wir hoffen, Sie bald wiederzusehen. Sie können unseren Service weiterhin kostenlos nutzen.",
    "the_account_will_be_active": "Das Konto bleibt bis zum Ende des Abrechnungszeitraums aktiv.",
    "you_have_got_the_discount_now": "Sie haben jetzt den Rabatt erhalten! Wir freuen uns, dass Sie bei uns geblieben sind!"
  },
  "billingHelpers": {
    "request": "Anfrage",
    "requests": "Anfragen",
    "learning_data_characters": "Lernmaterial, Zeichen",
    "package_name": "Paketname",
    "projects": "Projekte",
    "content_uploads": "Inhaltsuploads",
    "widgets": "Widgets",
    "scheduled_content_updates": "Geplante Inhaltsaktualisierungen",
    "alternative_ai_models": "Alternative KI-Modelle",
    "api_access": "API-Zugriff",
    "chrome_extension": "Chrome-Erweiterung",
    "extended_customizations": "Erweiterte Anpassungen"
  },
  "keepAi": {
    "hold_on": "Warten Sie mal!",
    "we_just_wanted_to_let_you_know": "Wir wollten Ihnen nur mitteilen, dass OmniMind.AI Ihnen",
    "hour_of_work_so_far": "Arbeitsstunden bisher gespart hat.",
    "our_support_team_can_help_you": "Unser Support-Team kann Ihnen bei allen Problemen helfen, mit denen Sie konfrontiert sind.",
    "chat_with_live_support": "Mit Live-Support chatten"
  },
  "keepPlan": {
    "keep_plan_we_are_sorry_to_see_you_go": "Behalten Sie den Plan, wir bedauern, dass Sie gehen!",
    "cancel_your_subscription": "Sind Sie sicher, dass Sie Ihr Abonnement kündigen möchten?",
    "cancel_your_current_subscription": "Sind Sie sicher, dass Sie Ihr aktuelles Abonnement kündigen und Ihr Konto entfernen möchten?",
    "please_let_us_know_cancel_your_subscription": "Bitte teilen Sie uns den Grund mit, warum Sie Ihr Abonnement kündigen möchten.",
    "reason_you_want_to_remove_the_account": "Bitte teilen Sie uns den Grund mit, warum Sie das Konto entfernen möchten. Nach Bestätigung der Aktion wird das Abonnement gekündigt und das Konto bleibt bis zum Ende des Abrechnungszeitraums aktiv. Wenn der Abrechnungszeitraum abgelaufen ist, werden alle Projektdaten und Ihr Konto dauerhaft gelöscht.",
    "select_a_reason": "Wählen Sie einen Grund aus",
    "other_reason": "Anderer Grund"
  },
  "keepPlanModal": {
    "MissingFeatures": "Fehlende Funktionen",
    "TechnicalIssues": "Technische Probleme",
    "Havenovalue": "Kein Mehrwert",
    "Tooexpensive": "Zu teuer",
    "Otherreason": "Anderer Grund"
  },
  "packageInfoModal": {
    "package_information": "Paketinformationen"
  },
  "purchasedList": {
    "no_available_data_try_select_date": "Keine verfügbaren Daten, versuchen Sie ein Datum auszuwählen...",
    "no_data_for_the_selected_period": "Keine Daten für den ausgewählten Zeitraum",
    "created": "Erstellt",
    "entity": "Entität",
    "quantity": "Menge"
  },
  "subscriptionModal": {
    "cancel": "Abbrechen",
    "remove": "Entfernen",
    "removing": "Entfernen",
    "plan": "Plan",
    "account": "Konto",
    "work_time_optimization": "Arbeitszeitoptimierung",
    "keep": "Behalten",
    "discount_offer": "Rabattangebot",
    "claim_offer": "Angebot beanspruchen",
    "cancellation": "Kündigung",
    "confirmation": "Bestätigung",
    "keep_value": "{value} behalten",
    "final_step": "Letzter Schritt",
    "confirm": "Bestätigen",
    "continue_to": "Weiter zu",
    "something_went_awry": "Etwas ist schief gelaufen!"
  }
}