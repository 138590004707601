{
  "flashcardsWidget": {
    "export_to_quizlet": "Esporta su Quizlet",
    "export_to_cram": "Esporta su Cram",
    "how_to_import_into_cram": "Come importare su Cram",
    "begin_by_visiting_the_cram": "Inizia visitando il sito di Cram e selezionando l'opzione \"Crea schede flash\". Quindi, scegli \"Importa un set di schede flash\". Copia i tuoi dati, assicurandoti che ogni termine e la sua definizione siano separati da una",
    "and_different_cards_are": "e le diverse schede siano distinte da una",
    "new_line": "Nuova riga",
    "paste_this_into_the": "Incolla questo nel campo di input denominato \"COPIA E INCOLLA I TUOI DATI:\", e clicca su \"Importa dati\". Goditi la tua sessione di studio!",
    "oops_it_seems_youve_reached_your": "Oops! Sembra che tu abbia raggiunto il limite di messaggi consentiti. Per continuare, ti preghiamo di regolare il limite di messaggi nelle impostazioni.",
    "question": "Domanda",
    "tab": "Scheda",
    "my_answer": "La mia risposta",
    "correct_answer": "Risposta corretta",
    "evaluate_my_answer": "Valuta la mia risposta su una scala da 1 a 5 in base alla sua precisione e completezza rispetto alla risposta corretta, considerando il contesto { context }. Utilizza le seguenti linee guida per la valutazione: Se la risposta è completamente errata o non pertinente alla domanda e dimostra una mancanza di comprensione dell'argomento o della domanda, valutala con \"1\". Se la risposta affronta la domanda ma presenta inesattezze o omissioni significative ma mostra una certa comprensione ma anche fraintendimenti significativi o lacune di conoscenza, valutala con \"2\". Se la risposta è in parte corretta ma è incompleta o presenta inesattezze minori e mostra una comprensione sufficiente dell'argomento ma manca di dettagli o precisione, valutala con \"3\". Se la risposta è in gran parte corretta e copre la maggior parte degli aspetti della domanda ma possono mancare dettagli o sfumature minori, ma la comprensione generale è buona, valutala con \"4\". Se la risposta è completamente corretta e fornisce una risposta esaustiva alla domanda, valutala con \"5\". Utilizza il formato: \"numero;spiegazione\" per la tua risposta.",
    "something_went_awry": "Qualcosa è andato storto!",
    "your_rate_limit_exceeded": "Genera in lingua {formLanguage} {formAmount} domande da { context } in un FORMATO JSON VALIDO. Utilizza il modello",
    "answers_should_only_contain_the_essential": "Le risposte dovrebbero contenere solo i termini chiave o le frasi essenziali direttamente pertinenti alla domanda, senza dilungarsi.",
    "we_ve_found_parsed_length_questions": "Abbiamo trovato {parsedLength} domande finora. Aggiungere più contenuti potrebbe aiutarci a trovarne ancora di più!"
  },
  "flashcardsWidgetActions": {
    "view_all_questions": "Visualizza tutte le domande",
    "delete_card": "Elimina scheda",
    "edit_card": "Modifica scheda",
    "export_to_quizlet": "Esporta su Quizlet",
    "export_to_anki": "Esporta su Anki",
    "export_to_brainscape": "Esporta su Brainscape",
    "export_to_cram": "Esporta su Cram",
    "regenerate_cards": "Rigenera schede",
    "clear_all_cards": "Cancella tutte le schede"
  },
  "flashcardsWidgetFeedback": {
    "feedback": "Feedback"
  },
  "flashcardsWidgetForm": {
    "select_the_number_and_the_language": "Seleziona il numero e la lingua delle schede flash",
    "cards_number": "Numero di schede",
    "enter_quantity": "Inserisci la quantità",
    "language": "Lingua",
    "generate": "Genera",
    "original": "Originale"
  },
  "flashcardsWidgetNavigation": {
    "back": "Indietro",
    "step_and_length_of_questions": "{currentStep} di {questionsLength} domande",
    "next": "Avanti",
    "skip": "Salta"
  },
  "flashcardsWidgetQuestion": {
    "question": "Domanda",
    "enter_your_answer_here": "Inserisci la tua risposta qui...",
    "answer": "Risposta",
    "your_answer": "La tua risposta",
    "correct_answer": "Risposta corretta"
  },
  "flashcardsWidgetQuestionEditor": {
    "edit_card": "Modifica scheda",
    "save": "Salva",
    "question": "Domanda",
    "answer": "Risposta"
  },
  "flashcardsWidgetQuestionFooter": {
    "check_my_answer": "Controlla la mia risposta",
    "hide_answer": "Nascondi risposta",
    "i_want_to_answer_it_by_myself": "Voglio rispondere da solo",
    "show_answer": "Mostra risposta"
  },
  "flashcardsWidgetQuestionsList": {
    "looks_like_it_s_pretty_empty_here": "Sembra che sia abbastanza vuoto qui!",
    "start_adding_some_cards_to_see_them_appear": "Inizia ad aggiungere alcune schede per vederle apparire."
  }
}