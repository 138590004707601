{
  "mermaidMindmapWidgetActions": {
    "export": "Exportieren",
    "click_here": "Hier klicken, um Mind-Map-Editoren zu öffnen",
    "theme": "Thema",
    "select_timezone": "Wählen Sie Ihre Zeitzone aus",
    "language": "Sprache",
    "create_map": "Karte erstellen",
    "regenerate_tooltip": "Klicken Sie hier, um die gesamte Mind-Map neu zu generieren. Beachten Sie bitte, dass dadurch die aktuelle Karte vollständig ersetzt wird. Verwenden Sie diese Option nur, wenn Sie mit der vorhandenen Karte unzufrieden sind. Wenn Sie geringfügige Anpassungen vornehmen möchten, klicken Sie einfach auf die Schaltfläche 'Bearbeiten'.",
    "export_svg": "SVG exportieren",
    "export_png": "PNG exportieren"
  },
  "mermaidMindmapWidgetMap": {
    "mermaid_mind_map": "Mermaid Mind Map",
    "what_is_mind_map": "Was ist eine Mind Map",
    "a_mermaid_mind_map_is_a_visualization_tool": "Eine Mermaid-Mind-Map ist ein Visualisierungstool, das mit der Mermaid.js-Bibliothek erstellt wurde und die Organisation und Darstellung von Informationen in hierarchischer Form ermöglicht. Mit einer einfachen Markdown-ähnlichen Syntax können Sie Mind-Maps erstellen, die komplexe Ideen oder Projekte klar und visuell strukturiert darstellen. Diese Methode unterstützt erheblich bei Brainstorming- und Planungsphasen und hilft dabei, komplexe Daten oder Konzepte effizient abzugrenzen und zu klären. Darüber hinaus ist die Eingabe als einfacher Text leicht bearbeitbar, was schnelle Updates und Anpassungen ermöglicht. Sie kann nahtlos in verschiedene Plattformen integriert werden, sodass das Teilen und Einbetten dieser Mind-Maps problemlos möglich ist.",
    "steps_to_improve_your_mind_map": "Schritte zur Verbesserung Ihrer Mind Map",
    "clarify_your_intent": "Klären Sie Ihre Absicht",
    "use_the_custom_prompt_to": "Verwenden Sie die benutzerdefinierte Aufforderung, um Ihre Anforderungen bei der Erstellung der Mind Map anzugeben.",
    "declutter_knowledge": "Wissen entrümpeln",
    "navigate_to_the_knowledge_section": "Navigieren Sie zum Abschnitt \"Wissen\" (neben den Einstellungen). Löschen Sie überflüssige Abschnitte und unnötige Informationen, um den Fokus und die Klarheit beizubehalten.",
    "adjust_with_editors": "Mit Editoren anpassen",
    "employ_the_map_editor_to_correct": "Verwenden Sie den Karten-Editor, um die Mermaid-Syntax zu korrigieren und anzupassen. Verwenden Sie den Knoten-Editor, um Knoten in der Mind Map hinzuzufügen, zu löschen oder zu ersetzen, um eine bessere Ausrichtung zu erreichen.",
    "by_following_these_steps": "Durch das Befolgen dieser Schritte wird Ihre Mind Map schlanker, fokussierter und auf Ihre Bedürfnisse zugeschnitten sein.",
    "map_should_highlight_the_key_aspects_and": "Die Karte sollte die wichtigen Aspekte und hierarchischen Beziehungen des { context } hervorheben. Beginnen Sie die Karte mit einem einzigen Wurzelknoten, der das Hauptkonzept oder Thema prägnant darstellt. Entwickeln Sie Zweige, um Untertopics oder verwandte Konzepte zu erkunden, und geben Sie jedem Knoten klare Bezeichnungen. Halten Sie einen klaren und logischen Fluss zwischen verbundenen Knoten und Unterknoten aufrecht und verwenden Sie eine ordnungsgemäße Einrückung, um die Hierarchie anzuzeigen. Halten Sie die Knoten kurz und prägnant und stellen Sie sicher, dass sie sich direkt auf benachbarte Knoten beziehen. In der Antwort halten Sie sich an das Muster",
    "with_adequate_spacing_to_demonstrate": "mit ausreichendem Abstand, um hierarchische Verbindungen darzustellen. Ihre Ausgabe sollte textuell sein, so formatiert, dass sie mit Mermaid korrekt dargestellt wird, und frei von Großbuchstaben oder Sonderzeichen, außer denen, die für die Mermaid-Syntax erforderlich sind.",
    "we_are_unable_to_create_a_mindmap": "Wir können keine Mind Map basierend auf dem bereitgestellten Inhalt erstellen. Sie können versuchen, die Widget-Einstellungen zu öffnen und sie zu ändern, um bessere Ergebnisse zu erzielen!",
    "something_went_awry": "Etwas ist schiefgelaufen! Aber wir versuchen, Ihre Karte erneut zu generieren!",
    "please_generate_a_mermaid_mind": "Bitte generieren Sie eine Mermaid-Mind-Map basierend auf dem { context } in",
    "language": "Sprache"
  },
  "mermaidMindmapWidgetMapEditor": {
    "map_editor": "Karten-Editor",
    "manipulate_the_structure_and": "Manipulieren Sie die Struktur und den Fluss Ihrer Karte mühelos, indem Sie Einrückungen anpassen und neue Zeilen hinzufügen, um Knoten zu erstellen. Dadurch wird eine maßgeschneiderte und aufschlussreiche Visualisierung Ihrer Ideen ermöglicht. Machen Sie sich bereit, Ihre Mind-Mapping-Erfahrung zu verfeinern und neu zu definieren, bei der jede Änderung Sie näher zur Perfektion bringt."
  },
  "mermaidMindmapWidgetNodesEditor": {
    "nodes_editor": "Knoten-Editor",
    "dont_settle_for_nodes_that_dont_resonate": "Setzen Sie sich nicht mit Knoten zufrieden, die nicht resonieren. Mit dem Knoten-Editor können Sie jedes Element mühelos neu generieren, um Ihrer Vision besser gerecht zu werden, oder brandneue Knoten hinzufügen, um Ihre Ideen zu erweitern.",
    "click_here_to_re_generate_this_node": "Klicken Sie hier, um diesen Knoten neu zu generieren. Geben Sie klare Anweisungen an.",
    "click_here_to_add_a_brand_new_node": "Klicken Sie hier, um einen brandneuen Knoten hinzuzufügen. Alle anderen Knoten bleiben unberührt. Geben Sie klare Anweisungen an.",
    "add_node": "Knoten hinzufügen",
    "add_instructions": "Anweisungen hinzufügen",
    "enter_your_hint_here": "Geben Sie hier Ihren Hinweis ein",
    "this_node": "Dieser Knoten",
    "and_all_it_s_children_will": "und alle seine Kinder werden neu gerendert.",
    "you_have_the_option_to": "Sie haben die Möglichkeit, Ihre Mind Map durch Hinzufügen eines neuen Knotens zu verbessern.",
    "feel_free_to_include_any_specific": "Fühlen Sie sich frei, spezifische Details oder Anforderungen als Hinweis einzuschließen, falls erforderlich."
  }
}