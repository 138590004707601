{
  "quizWidget": {
    "delete_this_question": "Elimina questa domanda?",
    "are_you_sure_you_want_to_delete": "Sei sicuro di voler eliminare questa domanda dal quiz? Questa azione non può essere annullata.",
    "delete_this_quiz": "Elimina questo quiz?",
    "are_you_sure_you_want_to_delete_the_quiz": "Sei sicuro di voler eliminare l'intero quiz? Questa azione non può essere annullata.",
    "export_to_quizlet": "Esporta su Quizlet",
    "use_pattern": "Usa il modello",
    "the_quiz_should_test_general_knowledge": "Il quiz dovrebbe testare la conoscenza generale, il pensiero critico e la comprensione. La domanda deve avere uno spazio da riempire.",
    "can_be_more_than_one_correct": "Possono esserci più opzioni corrette",
    "each_question_should_focus": "4) Ogni domanda dovrebbe concentrarsi su un tema o argomento specifico 5) Ogni domanda dovrebbe avere UN MINIMO DI 4 COPPIE DI ELEMENTI da abbinare 6) La struttura del JSON dovrebbe essere un array di oggetti, dove ogni oggetto rappresenta una domanda con un campo 'question' e un campo 'correct'. Il campo 'correct' dovrebbe essere a sua volta un oggetto, contenente coppie chiave-valore da abbinare. 7) Il contenuto dovrebbe essere educativo e basato su informazioni fattuali o concetti ben stabiliti. 8) Usa il seguente modello JSON per ogni domanda:",
    "the_quiz_should_cover": "Il quiz dovrebbe coprire argomenti diversi o diversi aspetti dello stesso tema.",
    "question_task_arrange_items": "Compito della domanda: ordinare elementi o eventi nell'ordine corretto. Minimo 4 opzioni posizionate nell'array 'correct' in sequenza corretta",
    "given_the_context": "Dato il {context}, genera un quiz IN",
    "language_in_a_valid_json_format": "lingua in un formato JSON VALIDO. Il quiz dovrebbe avere le seguenti caratteristiche: 1) Tipo di domanda:",
    "number_of_questions": "Numero di domande",
    "difficulty_level": "Livello di difficoltà",
    "this_time_only": "Solo questa volta {newQuestionsLength} domande. Forse non abbastanza contenuto.",
    "something_didn_t_go_as_planned": "Qualcosa non è andato come previsto.",
    "failed_to_get_the_pdf_file": "Impossibile ottenere il file PDF."
  },
  "quizWidgetAddCustomQuestionModal": {
    "create_custom_question": "Crea domanda personalizzata",
    "question_type": "Tipo di domanda",
    "difficulty": "Difficoltà"
  },
  "quizWidgetDeleteModal": {
    "delete_this_question": "Elimina questa domanda?",
    "are_you_sure_you_want_to_delete": "Sei sicuro di voler eliminare questa domanda dal quiz? Questa azione non può essere annullata."
  },
  "quizWidgetExportModal": {
    "export_configuration": "Configurazione esportazione",
    "decide_what_should_be_included": "Decidi cosa dovrebbe essere incluso nell'esportazione del quiz",
    "show_numeration": "Mostra numerazione",
    "show_answers": "Mostra risposte",
    "show_explanation": "Mostra spiegazione",
    "show_instructions": "Mostra istruzioni",
    "show_difficulty": "Mostra difficoltà",
    "show_images": "Mostra immagini"
  },
  "quizWidgetForm": {
    "question_type": "Tipo di domanda",
    "language": "Lingua",
    "difficulty": "Difficoltà",
    "max_questions": "Numero massimo di domande",
    "add_more": "Aggiungi altro",
    "generate": "Genera",
    "original": "Originale"
  },
  "quizWidgetListFooter": {
    "hide": "Nascondi",
    "show": "Mostra",
    "answers": "risposte",
    "create_custom_question": "Crea domanda personalizzata",
    "clear_quiz": "Cancella quiz",
    "export": "Esporta"
  },
  "quizWidgetListHeader": {
    "quiz": "Quiz",
    "questions": "domande"
  },
  "quizWidgetQuestionCardFooter": {
    "change_difficulty": "Clicca qui per cambiare la difficoltà"
  }
}