{
  "searchWidget": {
    "search": "cerca",
    "we_are_creating_examples_of_questions": "Stiamo creando esempi di domande che puoi fare al bot per te, ma puoi usare la chat ora.",
    "sorry_we_could_not_create_any_examples": "Spiacenti, non siamo riusciti a creare esempi di domande che puoi fare al bot ancora.",
    "please_ask_the_owner_of": "Si prega di chiedere al proprietario del progetto di renderlo pubblico",
    "the_project_owner_needs_to": "Il proprietario del progetto deve aggiornare l'abbonamento in quanto quello attuale è scaduto.",
    "404_oops_we_havet_found": "404 Oops, non abbiamo trovato il progetto",
    "loading": "Caricamento...",
    "oops_it_seems_youve_reached": "Ops! Sembra che tu abbia raggiunto il limite di messaggi consentiti. Per continuare, regola gentilmente il tuo limite di velocità nelle impostazioni."
  }
}