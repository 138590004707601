{
  "formClearHistoryItem": {
    "clear_chat_history": "Borrar historial de chat",
    "do_you_want_to_clear": "¿Deseas borrar tu historial de chat?",
    "clear_history": "Borrar historial",
    "all_your_history_will_be_deleted": "Todo tu historial de chat será eliminado."
  },
  "formOptions": {
    "more_options": "Más opciones"
  },
  "formPdfGeneratingItem": {
    "export_chat_as_pdf": "Exportar chat como PDF",
    "create_chat_to_pdf": "Crear chat a PDF",
    "wait_for_a_few_seconds_please": "Espera unos segundos por favor...",
    "something_happened": "Algo ocurrió al crear el PDF"
  }
}