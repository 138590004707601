export enum WidgetTypeKeysEnum {
  SearchWidget = 'SearchWidget',
  ExplainWidget = 'ExplainWidget',
  ChatWidget = 'ChatWidget',
  VoiceWidget = 'VoiceWidget',
  SwaggerWidget = 'SwaggerWidget',
  DocSearchWidget = 'DocSearchWidget',
  PopupChatWidget = 'PopupChatWidget',
  RetellingWidget = 'RetellingWidget',
  SummaryWidget = 'SummaryWidget',
  InteractiveMindmapWidget = 'InteractiveMindmapWidget',
  MermaidMindmapWidget = 'MermaidMindmapWidget',
  DataExtractorWidget = 'DataExtractorWidget',
  QuizWidget = 'QuizWidget',
  FlashcardsWidget = 'FlashcardsWidget',
  TableWidget = 'TableWidget',
  FileInformer = 'FileInformer',
  WebInformer = 'WebInformer',
  SingleWebInformer = 'SingleWebInformer',
  AirtableInformer = 'AirtableInformer',
  AudioInformer = 'AudioInformer',
  ChromeExtensionInformer = 'ChromeExtensionInformer',
  ConfluenceInformer = 'ConfluenceInformer',
  CSVInformer = 'CSVInformer',
  DropboxFile = 'dropbox-file',
  DropboxFolder = 'dropbox-folder',
  ExcelInformer = 'ExcelInformer',
  GdriveFile = 'gdrive-file',
  GdriveFolder = 'gdrive-folder',
  GoogleSheetsInformer = 'GoogleSheetsInformer',
  OnedriveFile = 'onedrive-file',
  OnedriveFolder = 'onedrive-folder',
  PlainText = 'text',
  Pdf = 'PdfOcrInformer',
  UrlFile = 'url',
  VimeoInformer = 'VimeoInformer',
  XmlSitemap = 'xml-sitemap',
  YoutubeChannel = 'youtube-channel',
  YoutubeInformer = 'YoutubeInformer',
  YoutubePlaylist = 'youtube-playlist',
  BulkCSVInformer = 'BulkCSVInformer',
  NotionInformer = 'NotionInformer',
  WordpressInformer = 'WordpressInformer',
  Zendesk = 'zendesk',
  DatabaseInsights = 'DatabaseInformer',
  SlackIntegration = 'SlackIntegration'
}

export enum WidgetNamesEnum {
  EMBED = 'Embed code',
  CUSTOMIZE = 'Customize',
  CHATGPT = 'ChatGPT',
  PROMPT = 'Prompt',
  ANONYMIZER = 'Anonymizer',
  BEHAVIOUR = 'Behaviour',
}
