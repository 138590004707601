<template>
  <div class="relative flex w-full items-center">
    <router-link class="w-full" :to="linkRoute" :class="{ 'active-link': isMeActive }">
      <button
        type="button"
        class="router-link"
        :class="isMeActive && 'router-link--active'"
        @click="store.sidebar && store.toggleSidebar()"
      >
        <div class="mr-3 h-5 w-5">
          <BaseSvgIcon
            :name="!portalStore.isLockedLink(link.id) ? 'icon-box' : 'lock'"
            :class="`flex-shrink-0`"
          />
        </div>
        <span class="overflow-hidden overflow-ellipsis whitespace-nowrap">{{
          link.link_name
        }}</span>
      </button>
    </router-link>
  </div>
</template>

<script lang="ts" setup>
// Modules
import { computed } from 'vue';
import { useRoute } from 'vue-router';

// Store
import { useAppStore } from '@/stores';
import BaseSvgIcon from '@components/base/BaseSvgIcon.vue';
import { usePortalsStore } from '@/stores/portalsStore';

const store = useAppStore();

const portalStore = usePortalsStore();

const props = defineProps<{
  link: any;
}>();

const linkRoute = computed(() => {
  return `/omni-portal/portal/link/${props.link.id}`;
});
const route = useRoute();
const isMeActive = computed(() => route.path.includes(props.link.id));
</script>
<style scoped>
.router-link {
  @apply relative
  mt-[-1px]
  grid
  w-full
  grid-cols-[min-content_1fr_35px]
  items-center
  border-y
  border-transparent
  p-4 text-left
  text-[var(--omni-color-text)]
  transition
  duration-200
  before:absolute
  before:bottom-[0]
  before:left-[0]
  before:top-[0]
  before:hidden
  before:h-[100%]
  before:w-[2px]
  before:bg-[var(--omni-color-accent)]
  hover:border-[var(--omni-color-secondary)]
  hover:bg-[var(--omni-color-primary)]
  hover:!text-[var(--omni-color-accent)]
  hover:before:!block;
}

.router-link--active {
  @apply border-[var(--omni-color-secondary)]
  bg-[var(--omni-color-primary)]
  !text-[var(--omni-color-accent)]
  before:!block
  before:bg-[var(--omni-color-accent)];
}
</style>
