{
  "billingInfo": {
    "select_project": "Selecionar projeto",
    "required": "Obrigatório",
    "select_period": "Selecionar período",
    "your_current_package": "Seu pacote atual",
    "cancelled_at": "cancelado em",
    "next_billing_date": "próxima data de cobrança",
    "to_cancel_or_update_your_payment_plan": "Para cancelar ou atualizar seu plano de pagamento, acesse o Portal de Pagamento do Cliente.",
    "customer_payment_portal": "Portal de pagamento do cliente",
    "cancel_subscription": "Cancelar assinatura",
    "remove_your_account": "Remover sua conta",
    "remove_account": "Remover conta",
    "reactivate_account": "Reativar conta",
    "no_package_found": "Nenhum pacote encontrado.",
    "let_s_select_a_suitable_package": "Vamos selecionar um pacote adequado.",
    "select_your_package": "Selecione seu pacote",
    "the_account_will_deleted_at": "A conta será excluída em",
    "cancel_subscription_and_remove_account": "Cancelar assinatura e remover conta",
    "the_subscription_will_cancelled_at": "A assinatura será cancelada em",
    "cancel_current_subscription": "Cancelar assinatura atual",
    "something_went_awry": "Algo deu errado"
  },
  "billings": {
    "billing_details": "Detalhes de cobrança",
    "activity_summary": "Resumo da atividade"
  },
  "billingsActivity": {
    "purchase_history": "Histórico de compras",
    "request_history": "Histórico de solicitações",
    "request_analytics": "Análise"
  },
  "billingsActivityPurchase": {
    "no_data_so_far": "Nenhum dado até o momento.",
    "download_invoice": "Baixar fatura",
    "date": "Data",
    "amount": "Valor",
    "package": "Pacote",
    "status": "Status",
    "invoice": "Fatura"
  },
  "billingsActivityRequest": {
    "select_project": "Selecionar projeto",
    "required": "Obrigatório",
    "select_period": "Selecionar período",
    "apply": "Aplicar",
    "no_data_so_far": "Nenhum dado até o momento.",
    "created": "Criado",
    "entity": "Entidade",
    "cost": "Custo"
  },
  "billingsCurrentPackage": {
    "current_package": "Pacote atual",
    "click_here_to_see_package_info": "Clique aqui para ver as informações do pacote",
    "cancelled_at": "cancelado em",
    "deleted_at": "excluído em",
    "trial_ends_at": "período de teste expira em",
    "next_billing_date": "próxima data de cobrança",
    "change_package": "Alterar pacote",
    "add_package": "Adicionar pacote",
    "cancel_subscription": "Cancelar assinatura",
    "the_subscription_will_cancelled_at": "A assinatura será cancelada em",
    "cancel_current_subscription": "Cancelar assinatura atual",
    "the_subscription_will_delete_at": "A assinatura será excluída em",
    "something_went_wrong": "Algo deu errado!"
  },
  "billingsPackageInfo": {
    "package_information": "Informações do pacote",
    "marketplace": "Loja",
    "learning_data": "Dados de aprendizado",
    "requests": "Solicitações",
    "on_average_one_request_contains": "Em média, uma solicitação contém",
    "characters": "caracteres",
    "no_data_so_far": "Nenhum dado até o momento"
  },
  "billingsPackageInfoItem": {
    "upgrade_to_add_more": "Atualize para adicionar mais"
  },
  "billingsUpgradePackageModal": {
    "expand_package": "Expandir pacote"
  },
  "chart": {
    "consumption_graph": "Gráfico de consumo"
  },
  "claimOffer": {
    "fetching_a_discount_data": "Obtendo dados de desconto...",
    "claiming_the_offer": "Reivindicando a oferta...",
    "are_you_considering_our": "Você está considerando nossa",
    "discount_the": "desconto? O",
    "month_plan_is_now_just": "plano mensal agora custa apenas",
    "month": "mês",
    "think_it_over": "Pense sobre isso!"
  },
  "finalStep": {
    "your_subscription_has_been_successfully": "Sua assinatura foi cancelada com sucesso. Esperamos vê-lo em breve. Você ainda pode usar nosso serviço gratuitamente.",
    "the_account_will_be_active": "A conta ficará ativa até o final do período de cobrança.",
    "you_have_got_the_discount_now": "Você obteve o desconto agora! Ficamos felizes que você tenha permanecido conosco!"
  },
  "billingHelpers": {
    "request": "Solicitação",
    "requests": "Solicitações",
    "learning_data_characters": "Dados de aprendizado, caracteres",
    "package_name": "Nome do pacote",
    "projects": "Projetos",
    "content_uploads": "Uploads de conteúdo",
    "widgets": "Widgets",
    "scheduled_content_updates": "Atualizações de conteúdo programadas",
    "alternative_ai_models": "Modelos de IA alternativos",
    "api_access": "Acesso à API",
    "chrome_extension": "Extensão do Chrome",
    "extended_customizations": "Personalizações estendidas"
  },
  "keepAi": {
    "hold_on": "Aguarde!",
    "we_just_wanted_to_let_you_know": "Só queríamos informar que a OmniMind.AI economizou",
    "hour_of_work_so_far": "hora de trabalho até agora.",
    "our_support_team_can_help_you": "Nossa equipe de suporte pode ajudá-lo com qualquer problema que você possa estar enfrentando.",
    "chat_with_live_support": "Converse com o Suporte ao Vivo"
  },
  "keepPlan": {
    "keep_plan_we_are_sorry_to_see_you_go": "Manter plano, lamentamos vê-lo partir!",
    "cancel_your_subscription": "Tem certeza de que deseja cancelar sua assinatura?",
    "cancel_your_current_subscription": "Tem certeza de que deseja cancelar sua assinatura atual e remover sua conta?",
    "please_let_us_know_cancel_your_subscription": "Informe-nos o motivo pelo qual você deseja cancelar sua assinatura.",
    "reason_you_want_to_remove_the_account": "Informe-nos o motivo pelo qual você deseja remover a conta. Após confirmar a ação, a assinatura será cancelada e a conta ficará ativa até o final do período de cobrança. Quando o período de cobrança terminar, removeremos permanentemente todos os dados do seu projeto e sua conta.",
    "select_a_reason": "Selecione um motivo",
    "other_reason": "Outro motivo"
  },
  "keepPlanModal": {
    "MissingFeatures": "Recursos ausentes",
    "TechnicalIssues": "Problemas técnicos",
    "Havenovalue": "Não tem valor",
    "Tooexpensive": "Muito caro",
    "Otherreason": "Outro motivo"
  },
  "packageInfoModal": {
    "package_information": "Informações do pacote"
  },
  "purchasedList": {
    "no_available_data_try_select_date": "Nenhum dado disponível, tente selecionar uma data...",
    "no_data_for_the_selected_period": "Nenhum dado para o período selecionado",
    "created": "Criado",
    "entity": "Entidade",
    "quantity": "Quantidade"
  },
  "subscriptionModal": {
    "cancel": "Cancelar",
    "remove": "Remover",
    "removing": "Removendo",
    "plan": "Plano",
    "account": "Conta",
    "work_time_optimization": "Otimização do tempo de trabalho",
    "keep": "Manter",
    "discount_offer": "Oferta de desconto",
    "claim_offer": "Reivindicar oferta",
    "cancellation": "Cancelamento",
    "confirmation": "Confirmação",
    "keep_value": "Manter {value}",
    "final_step": "Última etapa",
    "confirm": "Confirmar",
    "continue_to": "Continuar para",
    "something_went_awry": "Algo deu errado!"
  }
}