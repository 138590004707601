{
  "basicModeSelectWidget": {
    "need_another": "Need another",
    "data_source": "data source",
    "view": "view",
    "have_a_specific": "Have a specific {feature} in mind that would enhance your experience? Request it as a feature, and we'll work on integrating it.",
    "sorry_we_could_not": "Sorry, we couldn't find any matching results. If you have a specific {feature} in mind, you can request it as a feature, and we'll work on integrating it for you.",
    "request_a_new": "Request a new {feature}"
  },
  "basicModeWidgetCard": {
    "coming_soon": "COMING SOON",
    "beta": "BETA",
    "popular": "POPULAR",
    "we_understand_you": "We understand you're interested in this feature. It's available in our higher-tier plans. Consider upgrading to access it!",
    "add_another_widget": "Add another widget",
    "expand_your_dashboard_by_adding_a_new_widget": "Expand your dashboard by adding a new widget."
  },
  "basicModeWidgetFilters": {
    "informers": "Informers",
    "all": "All",
    "widgets": "Widgets",
    "search": "Search...",
    "all_informers": "All Informers",
    "popular": "Popular",
    "beta": "Beta",
    "embed": "Embed",
    "integrations": "Integrations"
  }
}
