{
  "projectSettingsSecurity": {
    "add": "Agregar",
    "add_new_domain": "Agregar nuevo dominio",
    "allowed_domains": "Dominios permitidos",
    "the_value_is_not_a_valid_domain": "El valor no es un dominio válido",
    "password_is_required": "Se requiere contraseña",
    "password_must_be_at_least": "La contraseña debe tener al menos 8 caracteres",
    "password_should_be_alphanumeric": "La contraseña debe ser alfanumérica",
    "this_domain_was_added_previously": "Este dominio fue agregado previamente"
  },
  "projectSettingsSecurityPassword": {
    "password": "Contraseña",
    "delete_project_password": "Eliminar contraseña del proyecto",
    "delete_the_project_password": "Eliminar la contraseña del proyecto",
    "set_up_password_for_the_project": "Configurar contraseña para el proyecto",
    "anyone_will_be_able_to_use": "Cualquiera podrá usar el proyecto sin contraseña",
    "anyone_who_has_access_to_this_project": "Cualquiera que tenga acceso a este proyecto deberá ingresar una contraseña"
  }
}