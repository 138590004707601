{
  "cardEditorFooter": {
    "cancel": "Cancelar",
    "save": "Salvar"
  },
  "cardFillBlank": {
    "correct_answer": "Resposta correta",
    "explanation": "Explicação"
  },
  "cardFillBlankEditor": {
    "question": "Pergunta",
    "enter_question": "Digite a pergunta",
    "correct_answer": "Resposta correta",
    "enter_correct_answer": "Digite a resposta correta",
    "explanation_optional": "Explicação (opcional)",
    "enter_explanation_for_the_correct_answer": "Digite a explicação para a resposta correta",
    "instructions_optional": "Instruções (opcional)",
    "enter_instructions_for_answering_the_question": "Digite as instruções para responder a pergunta"
  },
  "cardMatching": {
    "correct_answer": "Resposta correta",
    "explanation": "Explicação"
  },
  "cardMatchingEditor": {
    "question": "Pergunta",
    "enter_question": "Digite a pergunta",
    "answers": "Respostas",
    "list_the_items_in_the_correct_sequence": "Liste os itens na sequência correta. Eles serão randomizados para a atividade.",
    "enter_answer_option": "Digite a opção de resposta",
    "add_more": "Adicionar Mais",
    "explanation_optional": "Explicação (opcional)",
    "enter_explanation_for_the_correct_answer": "Digite a explicação para a resposta correta",
    "instructions_optional": "Instruções (opcional)",
    "enter_instructions_for_answering_the_question": "Digite as instruções para responder a pergunta"
  },
  "cardMultipleAnswers": {
    "correct_answers": "Respostas corretas",
    "explanation": "Explicação"
  },
  "cardMultipleAnswersEditor": {
    "question": "Pergunta",
    "enter_question": "Digite a pergunta",
    "answer": "Resposta",
    "enter_answer_option": "Digite a opção de resposta",
    "add_more": "Adicionar Mais",
    "correct_answers": "Respostas corretas",
    "explanation_optional": "Explicação (opcional)",
    "enter_explanation_for_the_correct_answer": "Digite a explicação para a resposta correta",
    "instructions_optional": "Instruções (opcional)",
    "enter_instructions_for_answering_the_question": "Digite as instruções para responder a pergunta",
    "only_unique_answer_values_allowed": "Apenas valores de resposta únicos são permitidos!"
  },
  "cardMultipleChoice": {
    "correct_answer": "Resposta correta",
    "explanation": "Explicação"
  },
  "cardMultipleChoiceEditor": {
    "question": "Pergunta",
    "enter_question": "Digite a pergunta",
    "answer": "Resposta",
    "enter_answer_option": "Digite a opção de resposta",
    "add_more": "Adicionar Mais",
    "correct_answer": "Resposta correta",
    "enter_correct_answer": "Digite a resposta correta",
    "explanation_optional": "Explicação (opcional)",
    "enter_explanation_for_the_correct_answer": "Digite a explicação para a resposta correta",
    "instructions_optional": "Instruções (opcional)",
    "enter_instructions_for_answering_the_question": "Digite as instruções para responder a pergunta",
    "only_unique_answer_values_allowed": "Apenas valores de resposta únicos são permitidos!"
  },
  "cardSequence": {
    "correct_sequence": "Sequência correta",
    "explanation": "Explicação"
  },
  "cardSequenceEditor": {
    "question": "Pergunta",
    "enter_question": "Digite a pergunta",
    "correct_answers": "Respostas corretas",
    "list_the_items_in_the_correct_sequence": "Liste os itens na sequência correta. Eles serão randomizados para a atividade.",
    "enter_answer_option": "Digite a opção de resposta",
    "add_more": "Adicionar Mais",
    "explanation_optional": "Explicação (opcional)",
    "enter_explanation_for_the_correct_answer": "Digite a explicação para a resposta correta",
    "instructions_optional": "Instruções (opcional)",
    "enter_instructions_for_answering_the_question": "Digite as instruções para responder a pergunta",
    "empty_values_are_not_allowed": "Valores vazios não são permitidos!"
  },
  "cardShortAnswer": {
    "correct_answer": "Resposta correta",
    "explanation": "Explicação"
  },
  "cardShortAnswerEditor": {
    "question": "Pergunta",
    "enter_question": "Digite a pergunta",
    "correct_answer": "Resposta correta",
    "enter_correct_answer": "Digite a resposta correta",
    "explanation_optional": "Explicação (opcional)",
    "enter_explanation_for_the_correct_answer": "Digite a explicação para a resposta correta",
    "instructions_optional": "Instruções (opcional)",
    "enter_instructions_for_answering_the_question": "Digite as instruções para responder a pergunta"
  },
  "cardTrueFalse": {
    "true": "Verdadeiro",
    "false": "Falso",
    "correct_answer": "Resposta correta",
    "explanation": "Explicação"
  },
  "cardTrueFalseEditor": {
    "question": "Pergunta",
    "enter_question": "Digite a pergunta",
    "correct_answer": "Resposta correta",
    "explanation_optional": "Explicação (opcional)",
    "enter_explanation_for_the_correct_answer": "Digite a explicação para a resposta correta",
    "instructions_optional": "Instruções (opcional)",
    "enter_instructions_for_answering_the_question": "Digite as instruções para responder a pergunta",
    "true": "Verdadeiro",
    "false": "Falso"
  }
}