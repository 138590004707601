import { gql } from '@apollo/client/core';

export const GET_PROJECT_STATUS = gql`
  query GetProjectStatus($project_id: uuid = "") {
    projects(where: {id: {_eq: $project_id}}) {
      id
      name
      is_public
      user_to_projects(where: {scopes: {_eq: OWNER}}) {
        billing (where: { status: { _eq: "active" } }, order_by: { updated_at: desc }, limit: 1){
            id
            portal_id,
            units_amount
            units_spent
        }
      }
    }
  }
`;