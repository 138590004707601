{
  "confluenceInformerInformers": {
    "stay_organized_and_enhance_your_ai": "Bleiben Sie organisiert und verbessern Sie Ihr KI-Projekt, indem Sie Ihre Confluence-Inhalte mühelos einbinden.",
    "something_went_awry_check_provided_data": "Etwas ist schiefgelaufen! Überprüfen Sie die bereitgestellten Daten und versuchen Sie es erneut, bitte!",
    "something_went_awry": "Etwas ist schiefgelaufen!"
  },
  "confluenceInformerFormInformers": {
    "email": "E-Mail",
    "your_confluence_email": "Ihre Confluence-E-Mail-Adresse, die für die Anmeldung verwendet wird.",
    "api_token": "API-Token",
    "a_secure_api_token": "Ein sicheres API-Token, das von Ihrem Atlassian-Konto generiert wurde und eine nahtlose und sichere Integration gewährleistet.",
    "your_specific_confluence_domain": "Ihr spezifischer Confluence-Domänenpräfix",
    "e_g": "z.B.",
    "if_your_confluence_url_is": "wenn Ihre Confluence-URL lautet",
    "fetch_resources": "Ressourcen abrufen",
    "your": "Ihre",
    "subdomain": "Subdomain"
  },
  "confluenceInformerListPagesInformers": {
    "pages": "Seiten"
  },
  "confluenceInformerListPostsInformers": {
    "posts": "Beiträge"
  }
}