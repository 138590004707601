{
  "knowledge": {
    "sources": "Fontes",
    "questions_and_answers": "Perguntas e respostas"
  },
  "knowledgeHeader": {
    "knowledge": "Conhecimento",
    "search_for_uploaded_knowledge": "Pesquisar conhecimento enviado..."
  },
  "knowledgeTable": {
    "name": "Nome",
    "title": "Título",
    "size": "Tamanho",
    "date": "Data",
    "actions": "Ações"
  }
}