{
  "vimeoInformerInformers": {
    "you_can_provide_your_own_vimeo_token": "Puedes proporcionar tu propio Token de Vimeo en la configuración de tus credenciales de servicios.",
    "click_here": "¡Haz clic aquí!",
    "please_provide_a_valid_vimeo_video_url": "Por favor, proporciona una URL de video de Vimeo válida",
    "this_video_has_no_text_tracks": "¡Este video no tiene pistas de texto!",
    "something_went_awry": "¡Algo salió mal!"
  },
  "vimeoInformerFormInformers": {
    "insert_vimeo_video_url": "Insertar URL de video de Vimeo",
    "fetch_subtitles": "Obtener subtítulos"
  },
  "vimeoInformerListInformers": {
    "available_tracks": "Pistas disponibles"
  }
}