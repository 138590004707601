{
  "casesDatabase": {
    "popular": "Popular",
    "HR": "RRHH",
    "support": "Soporte",
    "eCommerce": "Comercio electrónico",
    "business": "Negocios",
    "community": "Comunidad",
    "developers": "Desarrolladores",
    "professionals": "Profesionales",
    "teachers": "Profesores",
    "sales_and_marketing": "Ventas y Marketing",
    "website_widget_title": "Sitio web y Chatbot",
    "website_widget_description": "Herramienta de chat para datos del sitio web.",
    "knowledge_bot_title": "Bot de conocimiento",
    "knowledge_bot_description": "Encuentra información en archivos cargados rápidamente.",
    "slack_HR_support_bot_title": "Bot de soporte de RRHH en Slack",
    "slack_HR_support_bot_description": "Bot de RRHH en Slack del equipo",
    "GDrive_file_HR_chatbot_for_Slack_title": "Chatbot de RRHH de archivo de Google Drive para Slack",
    "GDrive_file_HR_chatbot_for_Slack_description": "Carga archivos de Google Drive a Slack.",
    "GDrive_folder_HR_chatbot_for_slack_title": "Chatbot de RRHH de carpeta de Google Drive para Slack",
    "GDrive_folder_HR_chatbot_for_slack_description": "Carga carpetas de Google Drive a Slack.",
    "OneDrive_file_HR_chatbot_for_Slack_title": "Chatbot de RRHH de archivo de OneDrive para Slack",
    "OneDrive_file_HR_chatbot_for_Slack_description": "Carga archivos de OneDrive a Slack.",
    "OneDrive_folder_HR_chatbot_for_slack_title": "Chatbot de RRHH de carpeta de OneDrive para Slack",
    "OneDrive_folder_HR_chatbot_for_slack_description": "Carga carpetas de OneDrive a Slack.",
    "knowledge_base_chat_popup_for_websites_title": "Ventana emergente de chat de base de conocimiento para sitios web",
    "knowledge_base_chat_popup_for_websites_description": "Datos del sitio web como chat de ayuda.",
    "interactive_website_chat_for_customer_support_title": "Chat interactivo de sitio web para soporte al cliente",
    "interactive_website_chat_for_customer_support_description": "Chat de ayuda a partir de datos del sitio web.",
    "slack_integration_for_internal_knowledge_management_title": "Integración de Slack para la gestión interna del conocimiento",
    "slack_integration_for_internal_knowledge_management_description": "Bot de Slack para el conocimiento de archivos.",
    "AI_product_search_advisor_with_CSV_sync_title": "Asesor de búsqueda de productos de IA con sincronización de CSV",
    "AI_product_search_advisor_with_CSV_sync_description": "Sincroniza listas de productos con CSV.",
    "whatsApp_AI_travel_advisor_for_villas_title": "Asesor de viajes de IA de WhatsApp para villas",
    "whatsApp_AI_travel_advisor_for_villas_description": "Encuentra y reserva villas a través de WhatsApp.",
    "zendesk_inquiry_slackbot_title": "Slackbot de consulta de Zendesk",
    "zendesk_inquiry_slackbot_description": "Ver tickets de Zendesk en Slack.",
    "google_sheets_query_slackbot_title": "Slackbot de consulta de hojas de cálculo de Google",
    "google_sheets_query_slackbot_description": "Pregunta sobre datos de hojas de cálculo de Google en Slack.",
    "community_content_explorer_widget_title": "Explorador de contenido de la comunidad",
    "community_content_explorer_widget_description": "Busca contenido de la comunidad.",
    "vimeo_content_explorer_for_communities_title": "Explorador de contenido de Vimeo para comunidades",
    "vimeo_content_explorer_for_communities_description": "Explora videos de Vimeo fácilmente.",
    "web_content_explorer_widget_title": "Explorador de contenido web",
    "web_content_explorer_widget_description": "Encuentra contenido de sitios web fácilmente.",
    "PDF_content_discovery_widget_title": "Descubrimiento de contenido de PDF",
    "PDF_content_discovery_widget_description": "Encuentra documentos PDF fácilmente.",
    "celebrity_chef_AI_chatbot_title": "Chatbot de IA de chef famoso",
    "celebrity_chef_AI_chatbot_description": "Obtén recetas de un bot de chef.",
    "project_artifact_summarizer_title": "Resumidor de artefactos de proyecto",
    "project_artifact_summarizer_description": "Resume archivos de proyecto rápidamente.",
    "AI_powered_data_extractor_for_bubble_apps_title": "Extractor de datos con IA para aplicaciones Bubble",
    "AI_powered_data_extractor_for_bubble_apps_description": "Usa IA para recopilar datos.",
    "complex_document_simplifier_title": "Simplificador de documentos complejos",
    "complex_document_simplifier_description": "Facilita la lectura de documentos.",
    "professional_summary_creator_title": "Creador de resúmenes profesionales",
    "professional_summary_creator_description": "Obtén resúmenes simples de archivos.",
    "lesson_plan_quiz_generator_title": "Generador de cuestionarios de plan de lecciones",
    "lesson_plan_quiz_generator_description": "Crea cuestionarios a partir de planes de lecciones.",
    "notion_flashcard_creator_title": "Creador de tarjetas de memoria de Notion",
    "notion_flashcard_creator_description": "Convierte páginas de Notion en tarjetas de memoria.",
    "client_engagement_chat_assistant_title": "Asistente de chat de participación del cliente",
    "client_engagement_chat_assistant_description": "Crea planes de seguimiento para clientes.",
    "lead_data_extraction_tool_title": "Herramienta de extracción de datos de clientes potenciales",
    "lead_data_extraction_tool_description": "Recopila datos de sitios web de clientes potenciales.",
    "personalized_follow_up_generator_title": "Generador de seguimiento personalizado",
    "personalized_follow_up_generator_description": "Crea planes de seguimiento a partir de datos.",
    "competitive_knowledge_mindmap_tool_title": "Herramienta de mapa mental de conocimiento competitivo",
    "competitive_knowledge_mindmap_tool_description": "Convierte sitios de competidores en un mapa mental."
  },
  "informerHelpContent": {
    "PdfOcrInformer": "Carga un archivo Pdf desde tu computadora.",
    "FileInformer": "Carga un archivo desde tu computadora.",
    "WebInformer": "Enumera enlaces de sitios web, uno por línea.",
    "SingleWebInformer": "Proporciona un enlace de sitio web para extraer información.",
    "AirtableInformer": "Elige las tablas a incluir de Airtable.",
    "AudioInformer": "Carga un archivo de audio y elige el idioma.",
    "ChromeExtensionInformer": "Conéctate a nuestra extensión de Chrome.",
    "ConfluenceInformer": "Accede a los artículos de Confluence con credenciales.",
    "DatabaseInformer": "Comunícate directamente con la base de datos.",
    "SlackIntegration": "Integrar Omnimind en el canal de Slack.",
    "CSVInformer": "Carga un archivo CSV con datos.",
    "dropbox-file": "Pega el enlace al archivo de Dropbox.",
    "dropbox-folder": "Pega el enlace a la carpeta de Dropbox.",
    "ExcelInformer": "Carga tu archivo de Excel con datos.",
    "gdrive-file": "Pega el enlace al archivo de Google Drive.",
    "gdrive-folder": "Pega el enlace a la carpeta de Google Drive.",
    "GoogleSheetsInformer": "Ingresa el enlace al archivo de Google Sheets.",
    "onedrive-file": "Pega el enlace al archivo de OneDrive.",
    "onedrive-folder": "Pega el enlace a la carpeta de OneDrive.",
    "text": "Escribe o pega el texto para agregar.",
    "url": "Pega el enlace a un archivo de internet.",
    "VimeoInformer": "Pega el enlace de video de Vimeo y guarda el token.",
    "xml-sitemap": "Pega el enlace al archivo de mapa del sitio XML.",
    "youtube-channel": "Pega el enlace del canal de YouTube y elige el idioma.",
    "YoutubeInformer": "Pega el enlace de video de YouTube y elige el idioma.",
    "youtube-playlist": "Pega el enlace de la lista de reproducción de YouTube y elige el idioma.",
    "BulkCSVInformer": "Carga un archivo CSV con enlaces.",
    "NotionInformer": "Proporciona el token de Notion y el enlace de la página.",
    "WordpressInformer": "Pega el enlace de Wordpress y elige las categorías.",
    "zendesk": "Pega el enlace del centro de ayuda de Zendesk."
  }
}