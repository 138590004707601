{
  "addNewPortalModal": {
    "add_new_portal": "Aggiungi nuovo portale?",
    "add_portal": "Aggiungi Portale",
    "the_portal_was_successfully_created": "Il portale è stato creato con successo.",
    "oops_something_went_wrong": "Ops! Qualcosa è andato storto."
  },
  "deletePortalModal": {
    "delete_portal": "Elimina portale",
    "confirm": "Conferma",
    "you_are_about_to_delete_the_portal": "Stai per eliminare il portale. Questa azione non può essere annullata. Sei sicuro di voler continuare?"
  },
  "portalSettingsDomain": {
    "domain": "Dominio",
    "your_domain_copied_to_clipboard": "Il tuo dominio è stato copiato negli appunti",
    "example": "esempio",
    "subdomain": "Sottodominio",
    "custom_domain": "Dominio personalizzato",
    "this_domain_is_already_taken": "Questo dominio è già stato preso"
  },
  "portalSettingsGeneral": {
    "general": "Generale"
  },
  "portalSettingsGeneralForm": {
    "name": "Nome",
    "example_name": "Nome esempio",
    "brief_info": "Informazioni brevi",
    "example_brief_info": "Informazioni brevi esempio"
  },
  "portalSettingsPayments": {
    "payments_processing": "Elaborazione pagamenti",
    "we_use_stripe_payment_platform": "Utilizziamo la piattaforma di pagamento Stripe per elaborare i pagamenti tra te e i tuoi clienti. Devi creare il tuo profilo e collegare il metodo di pagamento",
    "connect": "Collega",
    "configure": "Configura",
    "account": "account"
  },
  "portalSettings": {
    "manage": "Gestisci",
    "suspend_portal": "Sospendi portale",
    "reactivate_portal": "Riattiva portale",
    "delete_portal": "Elimina portale",
    "apply": "Applica",
    "portal_settings_successfully_updated": "Impostazioni del portale aggiornate con successo.",
    "oops_this_domain_is_already_taken": "Ops! Questo dominio è già stato preso.",
    "please_fill_in_all_required_fields": "Si prega di compilare tutti i campi obbligatori."
  },
  "suspendPortalModal": {
    "suspend_portal": "Sospendi portale",
    "confirm": "Conferma",
    "you_are_about_to_suspend_the_portal": "Stai per sospendere il portale. Il portale non sarà disponibile per i tuoi utenti fino a quando non lo riattivi. Sei sicuro di voler continuare?"
  }
}