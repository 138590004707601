{
  "dialogs": {
    "user_info": "User info",
    "select_all_dialogs": "Select all dialogs",
    "search": "Search...",
    "deletion_of_the_selected_dialogs": "Deletion of the selected dialogs",
    "there_are_no_dialogs_yet": "There are no dialogs yet",
    "knowledge": "Knowledge",
    "delete": "Delete",
    "something_went_wrong": "Something went wrong during deletion",
    "question_and_answer_were_saved_successfully": "Question and answer were saved successfully!",
    "an_error_occurred_while_saving": "An error occurred while saving question and answer!",
    "upgrade_plan": "To use this functionality, please, upgrade your plan",
    "anonym": "Anonym",
    "no_tracking": "Message tracking is currently disabled. Please enable it in the settings to proceed.",
    "not_defined": "Not defined",
    "browser": "Browser",
    "os": "OS",
    "more": "more",
    "less": "less"
  },
  "dialogsFilters": {
    "filters": "Filters",
    "by_period": "By period",
    "by_negative": "Negative feedbacks",
    "by_calls": "Operator calls",
    "by_offline": "Offline messages",
    "apply": "Apply",
    "cancel": "Cancel"
  }
}
