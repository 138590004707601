{
  "likeDislikeAnswer": {
    "please_provide_additional_feedback": "Bitte geben Sie zusätzliches Feedback",
    "enter_your_username": "Geben Sie Ihren Namen ein",
    "enter_your_email": "Geben Sie Ihre E-Mail-Adresse ein",
    "what_was_the_issue_with_the_response": "Was war das Problem mit der Antwort?",
    "email_and_username_are_required": "E-Mail und Name sind erforderlich, um Feedback zu senden",
    "thank_you_sincerely_for_your_feedback": "Vielen Dank für Ihr Feedback. Es wird sehr geschätzt.",
    "sorry_but_we_encountered": "Entschuldigung, aber wir sind auf ein Problem gestoßen, als wir versuchten, Ihr Feedback zu senden. Bitte versuchen Sie es erneut.",
    "grateful_for_the_thumbs_up": "Dankbar für den Daumen hoch!"
  },
  "likeDislikeAnswerModal": {
    "submit": "Absenden"
  },
  "likeDislikeAnswerModalHeader": {
    "didnt_like_the_response": "Die Antwort hat nicht gefallen"
  }
}