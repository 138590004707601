{
  "confluenceInformerInformers": {
    "stay_organized_and_enhance_your_ai": "Mantenha-se organizado e aprimore seu projeto de IA trazendo facilmente seu conteúdo do Confluence.",
    "something_went_awry_check_provided_data": "Algo deu errado! Verifique os dados fornecidos e tente novamente, por favor!",
    "something_went_awry": "Algo deu errado!"
  },
  "confluenceInformerFormInformers": {
    "email": "Email",
    "your_confluence_email": "Seu endereço de email do Confluence usado para fazer login.",
    "api_token": "Token da API",
    "a_secure_api_token": "Um token de API seguro gerado a partir da sua conta Atlassian, garantindo uma integração perfeita e segura.",
    "your_specific_confluence_domain": "O prefixo do seu domínio específico do Confluence",
    "e_g": "por exemplo",
    "if_your_confluence_url_is": "se a URL do seu Confluence for",
    "fetch_resources": "Buscar recursos",
    "your": "Seu",
    "subdomain": "Subdomínio"
  },
  "confluenceInformerListPagesInformers": {
    "pages": "Páginas"
  },
  "confluenceInformerListPostsInformers": {
    "posts": "Postagens"
  }
}