{
  "widgetTypeIndex": {
    "let_s_upload_the_content": "Vamos fazer o upload do conteúdo",
    "with": "Com",
    "informers": "Informantes",
    "you_can_upload_information": "você pode fazer o upload de informações de várias fontes, como sites, documentos em PDF, até mesmo pastas do Google e muito mais. Lembre-se de que você sempre pode voltar a esta tela para carregar conteúdo adicional em seu projeto",
    "upload_information": "Faça o upload de informações de várias fontes, como sites, documentos em PDF, até mesmo pastas do Google e muito mais. Lembre-se de que você sempre pode voltar a esta tela para carregar conteúdo adicional em seu projeto sempre que precisar",
    "whenever_you_need": "sempre que precisar",
    "enter_the_name_of_the_project": "Digite o nome do projeto",
    "enter_project_name": "Digite o nome do projeto",
    "this_informer_is_not_available_yet": "Este informante ainda não está disponível.",
    "back": "Voltar"
  }
}