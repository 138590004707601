import { callAgent } from '@/api/agents';
import { getAllToolsList, getToolData, getToolMetaData } from '@/api/tools';
import { StoreId } from '@/stores/StoreId';
import type { ToolMetadata, ToolsTypeEntityInterface } from '@/types/ToolsTypeEntityInterface';
import { defineStore } from 'pinia';

export const useAgentToolsStore = defineStore(StoreId.Tools, {
  state: (): {
    toolTypesCollection: ToolsTypeEntityInterface[];
    toolMetadata: any;
  } => ({
    toolTypesCollection: [],
    toolMetadata: [],
  }),

  getters: {
    toolTypes: (state) => () => state.toolTypesCollection,
    getToolMetaData: (state) => () => state.toolMetadata,
  },

  actions: {
    async fetchAllToolTypes(force = false) {
      if (this.toolTypesCollection.length === 0 || force) {
        const data = await getAllToolsList();
        if (data?.agent_tools_types) {
          this.toolTypesCollection = data.agent_tools_types;
        }
      }
    },
    async fetchToolMetadata(toolKey: string, force = false) {
      if (this.toolMetadata.length === 0 || force) {
        const data = await getToolMetaData(toolKey);
        console.log('fetchToolMetadata', data?.meta_data);
        if (data?.meta_data) {
          this.toolMetadata = data.meta_data;
        }
      }
    },
    async fetchToolData(payload: any) {
      return await getToolData(payload);
    },
    async runAgent(payload: any) {
      try {
        return await callAgent(payload);
      } catch (error) {
        console.error('Error creating GoogleCredentials Calendar event:', error);
        throw error;
      }
    },
  },
});
