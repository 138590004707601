{
  "package": {
    "popular": "popular",
    "month": "mês",
    "chosen": "Escolhido",
    "contact_us": "Entre em contato",
    "package": "Pacote",
    "change": "Alterar",
    "subscribe": "Inscrever-se",
    "projects": "Projetos",
    "characters": "Personagens",
    "add_units": "Adicionar unidades",
    "units": "unidades",
    "informers": "Informantes",
    "widgets": "Widgets",
    "file_extensions": "Extensões de arquivo",
    "alternative_ai": "IA Alternativa",
    "updates": "Atualizações",
    "extended_customization": "Customização estendida"
  },
  "packageEnterpriseModal": {
    "contact_us": "Entre em contato",
    "submit": "Enviar",
    "please_tell_us_more_about_your_use_case": "Por favor, nos conte mais sobre o seu caso de uso",
    "share_your_expectations_from_the_plan": "Compartilhe suas expectativas em relação ao plano, como: recursos necessários, número de usuários, limites de personagens, modelos de IA, etc."
  },
  "packageFree": {
    "beginner_plan": "Plano Iniciante",
    "for_beginners_and_micro_projects": "Para iniciantes e micro projetos",
    "free": "Grátis",
    "project": "Projeto",
    "characters": "Personagens",
    "overquote": "cota excedida",
    "calls": "Chamadas",
    "balance": "Saldo",
    "try_now": "Experimente agora!"
  },
  "packages": {
    "beginner_plan": "Plano Iniciante",
    "enterprise": "Empresarial",
    "custom_software_development": "Desenvolvimento de software personalizado com hospedagem dedicada na plataforma OmniMind",
    "integration_to_your_business": "Integração ao seu negócio",
    "custom_dataflow": "Fluxo de dados personalizado",
    "dedicated_platform": "Plataforma dedicada",
    "100_data_privacy": "Privacidade de dados 100%",
    "invalid_number": "Número inválido",
    "the_package_is_successfully_installed": "O pacote foi instalado com sucesso!",
    "the_package_is_either_not_available": "O pacote não está disponível ou já foi instalado."
  },
  "ResearcherDescription": "Para estudos de nível básico e exploração acadêmica",
  "ProDescription": "Ferramentas avançadas para profissionais e pesquisadores experientes",
  "EinsteinDescription": "Suporte abrangente para cientistas e inovadores líderes",
  "EnterpriseDescription": "Desenvolvimento de software personalizado com hospedagem dedicada na plataforma OmniMind"
}