{
  "dialogs": {
    "user_info": "Informazioni utente",
    "select_all_dialogs": "Seleziona tutte le conversazioni",
    "search": "Cerca...",
    "deletion_of_the_selected_dialogs": "Eliminazione delle conversazioni selezionate",
    "there_are_no_dialogs_yet": "Non ci sono ancora conversazioni",
    "knowledge": "Conoscenza",
    "delete": "Elimina",
    "something_went_wrong": "Si è verificato un errore durante l'eliminazione",
    "question_and_answer_were_saved_successfully": "Domanda e risposta sono state salvate con successo!",
    "an_error_occurred_while_saving": "Si è verificato un errore durante il salvataggio della domanda e risposta!",
    "upgrade_plan": "Per utilizzare questa funzionalità, aggiorna il tuo piano",
    "anonym": "Anonimo",
    "no_tracking": "Il tracciamento dei messaggi è attualmente disabilitato. Abilitalo nelle impostazioni per procedere.",
    "not_defined": "Non definito",
    "browser": "Browser",
    "os": "Sistema operativo",
    "more": "più",
    "less": "meno"
  },
  "dialogsFilters": {
    "filters": "Filtri",
    "by_period": "Per periodo",
    "by_negative": "Feedback negativi",
    "by_calls": "Chiamate operatori",
    "by_offline": "Messaggi offline",
    "apply": "Applica",
    "cancel": "Annulla"
  }
}