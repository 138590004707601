{
  "dataExtractorWidget": {
    "data_extractor_widget": "Видобувач даних",
    "language": "Мова",
    "add_prompt": "Додати підказку",
    "reset_table": "Скинути таблицю",
    "active": "Активний",
    "resource": "Ресурс",
    "resource_already_exists": "Ресурс вже існує",
    "something_went_awry": "Щось пішло не так",
    "loading": "Завантаження",
    "error": "Помилка",
    "error_occured_while_creating_pdf": "Під час створення PDF сталася помилка",
    "the_prompt_has_been_successfully_saved": "Підказку успішно збережено в каталозі.",
    "contact_owner_to_grant_permission": "Будь ласка, зверніться до власника проекту, щоб отримати дозвіл на додавання ресурсів."
  },
  "dataExtractorWidgetActions": {
    "scan": "Сканувати",
    "export_pdf": "Експортувати PDF",
    "export_csv": "Експортувати CSV"
  },
  "dataExtractorWidgetAddPromptModal": {
    "add_edit_prompt": "Додати/Редагувати підказку",
    "add_save": "Додати/Зберегти",
    "add_prompt_to_catalog": "Додати підказку до каталогу",
    "column": "Стовпець",
    "locations": "Місця",
    "open_prompt_catalog": "Відкрити каталог підказок",
    "prompt": "Підказка",
    "common_locations_of_clients": "Загальні місця розташування клієнтів, з якими вони співпрацюють"
  },
  "dataExtractorWidgetAddPromptSupplement": {
    "these_prompts_will_be_applied": "Ці підказки будуть застосовані до кожного ресурсу",
    "by_bullet_points": "за допомогою маркерів",
    "as_a_quote": "як цитата",
    "in_a_few_words": "кількома словами"
  },
  "dataExtractorWidgetAddResourceModal": {
    "add_edit_resource": "Додати/Редагувати ресурс",
    "submit": "Надіслати",
    "enter_your_resource": "Введіть свій ресурс",
    "this_resource_is_already_exist": "Цей ресурс вже існує!"
  },
  "dataExtractorWidgetDeleteResourceModal": {
    "delete_this_resource": "Видалити цей ресурс?",
    "delete_resource": "Видалити ресурс",
    "resource_will_be_deleted": "Ресурс буде видалений з таблиці.",
    "delete_resource_from_project": "Видалити ресурс з проекту"
  },
  "dataExtractorWidgetPdfModal": {
    "create_pdf": "Створити PDF",
    "ok": "ОК",
    "wait_for_a_few_seconds_please": "Зачекайте кілька секунд, будь ласка..."
  },
  "dataExtractorWidgetPromptCatalog": {
    "prompt_catalog": "Каталог підказок",
    "delete_column": "Видалити стовпець з каталогу",
    "delete_prompt": "Видалити підказку з каталогу"
  },
  "dataExtractorWidgetTable": {
    "no_resources_so_far": "Поки що немає ресурсів"
  },
  "dataExtractorWidgetTableHead": {
    "resource": "Ресурс",
    "refresh_values": "Оновити значення",
    "edit_prompt": "Редагувати підказку",
    "delete_prompt": "Видалити підказку з таблиці"
  }
}