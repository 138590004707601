{
  "infoMessageOne": {
    "here_you_can_specify_the_depth_of_the_web_site_scraping": "Qui puoi specificare la profondità dello scraping del sito web",
    "not_deeper_than_the_specified_url": "non più profondo dell'URL specificato",
    "the_scraper_goes_one_level_deeper": "lo scraper va un livello più in profondità ma non segue i link al secondo livello",
    "the_scraper_goes_two_levels_deeper": "lo scraper va due livelli più in profondità ma non segue i link al terzo livello",
    "the_scraper_goes_three_levels_deeper": "lo scraper va tre livelli più in profondità ma non segue i link al quarto livello"
  },
  "webInformerAdvancedSettingItems": {
    "depth": "Profondità:",
    "page_limit": "Limite di pagine:",
    "enter_page_limit": "Inserisci il limite di pagine",
    "page_filter": "Filtro di pagine:",
    "glob_mask": "maschera glob",
    "define_the_update_period": "Definisci il periodo di aggiornamento",
    "if_you_are_not_sure_how_many_links": "Se non sei sicuro di quanti link ci sono sul sito, puoi definire il limite di pagine estratte.",
    "here_you_can_specify_the_regularity": "Qui puoi specificare la regolarità con cui verrà estratto nuovamente il link specificato per aggiornare le informazioni.",
    "you_can_lock_scraper_to_parse": "Puoi bloccare lo scraper per analizzare pagine con un determinato pattern di URL secondo",
    "hourly": "ogni ora",
    "daily": "quotidianamente",
    "weekly": "settimanalmente",
    "monthly": "mensilmente"
  },
  "webInformerBtnAdvancedSetting": {
    "advanced_link_setting": "Impostazione avanzata dei link"
  },
  "webList": {
    "preview": "Anteprima"
  }
}