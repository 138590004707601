{
  "anonymizerSettings": {
    "anonymize_sensitive_data": "Anonimizar datos sensibles",
    "language": "Idioma",
    "language_of_the_data": "Idioma de los datos",
    "replace": "Reemplazar",
    "replace_value": "Valor de reemplazo",
    "define_what_entities_must_be_anonymized": "Definir qué entidades deben ser anonimizadas",
    "anonymize_entities": "Anonimizar entidades"
  }
}