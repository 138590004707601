{
  "CustomPromptOptionsEnumTranslations": {
    "adviser": "AI adviser",
    "sales_assistant": "Sales assistant",
    "shakespeare": "William Shakespeare",
    "professor": "Professor",
    "formal": "formal",
    "informal": "informal",
    "persuasive": "persuasive",
    "collaborative": "collaborative",
    "creative": "creative",
    "emojis": "emojis",
    "mark_keywords": "bold main keywords",
    "subheaders": "subheaders",
    "billet_points_format": "bullet points format"
  }
}