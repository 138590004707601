{
  "dataExtractorWidget": {
    "data_extractor_widget": "Datenextraktor",
    "language": "Sprache",
    "add_prompt": "Prompt hinzufügen",
    "reset_table": "Tabelle zurücksetzen",
    "active": "Aktiv",
    "resource": "Ressource",
    "resource_already_exists": "Ressource existiert bereits",
    "something_went_awry": "Etwas ist schiefgelaufen",
    "loading": "Wird geladen",
    "error": "Fehler",
    "error_occured_while_creating_pdf": "Fehler beim Erstellen des PDFs",
    "the_prompt_has_been_successfully_saved": "Der Prompt wurde erfolgreich im Katalog gespeichert.",
    "contact_owner_to_grant_permission": "Bitte kontaktieren Sie den Projekteigentümer, um Ihnen die Berechtigung zum Hinzufügen von Ressourcen zu erteilen."
  },
  "dataExtractorWidgetActions": {
    "scan": "Scannen",
    "export_pdf": "PDF exportieren",
    "export_csv": "CSV exportieren"
  },
  "dataExtractorWidgetAddPromptModal": {
    "add_edit_prompt": "Prompt hinzufügen/bearbeiten",
    "add_save": "Hinzufügen/speichern",
    "add_prompt_to_catalog": "Prompt zum Katalog hinzufügen",
    "column": "Spalte",
    "locations": "Standorte",
    "open_prompt_catalog": "Prompt-Katalog öffnen",
    "prompt": "Prompt",
    "common_locations_of_clients": "Gemeinsame Standorte von Kunden, mit denen sie Geschäfte machen"
  },
  "dataExtractorWidgetAddPromptSupplement": {
    "these_prompts_will_be_applied": "Diese Prompts werden auf jede Ressource angewendet",
    "by_bullet_points": "als Aufzählungspunkte",
    "as_a_quote": "als Zitat",
    "in_a_few_words": "in wenigen Worten"
  },
  "dataExtractorWidgetAddResourceModal": {
    "add_edit_resource": "Ressource hinzufügen/bearbeiten",
    "submit": "Senden",
    "enter_your_resource": "Geben Sie Ihre Ressource ein",
    "this_resource_is_already_exist": "Diese Ressource existiert bereits!"
  },
  "dataExtractorWidgetDeleteResourceModal": {
    "delete_this_resource": "Diese Ressource löschen?",
    "delete_resource": "Ressource löschen",
    "resource_will_be_deleted": "Die Ressource wird aus der Tabelle gelöscht.",
    "delete_resource_from_project": "Ressource aus dem Projekt löschen"
  },
  "dataExtractorWidgetPdfModal": {
    "create_pdf": "PDF erstellen",
    "ok": "OK",
    "wait_for_a_few_seconds_please": "Bitte warten Sie einige Sekunden..."
  },
  "dataExtractorWidgetPromptCatalog": {
    "prompt_catalog": "Prompt-Katalog",
    "delete_column": "Spalte aus dem Katalog löschen",
    "delete_prompt": "Prompt aus dem Katalog löschen"
  },
  "dataExtractorWidgetTable": {
    "no_resources_so_far": "Bisher keine Ressourcen"
  },
  "dataExtractorWidgetTableHead": {
    "resource": "Ressource",
    "refresh_values": "Werte aktualisieren",
    "edit_prompt": "Prompt bearbeiten",
    "delete_prompt": "Prompt aus der Tabelle löschen"
  }
}