{
  "pagesProjectsWidgetIdSettings": {
    "back": "Zurück",
    "your_settings_successfully_saved": "Ihre Einstellungen wurden erfolgreich gespeichert",
    "failed_to_save_settings": "Fehler beim Speichern der Einstellungen",
    "failed_to_load_settings": "Fehler beim Laden der Einstellungen"
  },
  "pagesProjectsWidgetIdTeam": {
    "team": "Team",
    "back": "Zurück"
  },
  "pagesProjectsWidgetIdBasic": {
    "this_widget_view_is_not_ready_yet": "Diese Widget-Ansicht ist noch nicht bereit.",
    "add_a_new_view": "Neue Ansicht hinzufügen",
    "you_dont_have_any_resources": "Sie haben keine Ressourcen. Sie sollten einen neuen Informanten hinzufügen.",
    "change_widget_view": "Widget-Ansicht ändern"
  }
}