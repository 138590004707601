{
  "appTranslations": {
    "subscription_limit_reached": "Subscription Limit Reached",
    "upgrade_subscription": "Upgrade Subscription",
    "it_looks_like_you_ve_reached": "It looks like you've reached the limit of your current subscription. To continue enjoying our services, please click the button below to upgrade your subscription.",
    "we_ve_successfully_renewed_your": "We've successfully renewed your subscription.",
    "we_encountered_an_error_while": "We encountered an error while trying to renew your subscription.",
    "run_out_of_units": "You have run out of available units. Please replenish your units or upgrade the billing plan",
    "ok": "OK"
  }
}
