{
  "whatsAppWidget": {
    "access_token": "Token de acesso",
    "phone_id": "ID do telefone",
    "save": "Salvar",
    "how_to_connect": "Como conectar o chatbot do WhatsApp Omnimind AI",
    "make_sure_your_question": "Certifique-se de que sua pergunta seja clara e concisa para obter as respostas mais precisas. Você pode atualizar ou alterar o projeto nas configurações de ação se desejar usar dados diferentes para análise.",
    "create_meta_business_account": "Criar uma conta de negócios Meta (Facebook)",
    "create_new_meta_app": "Criar novo aplicativo Meta (Facebook)",
    "login_to_your_account": "Faça login na sua conta",
    "select_my_apps": "Selecione \"Meus aplicativos\"",
    "select_create_app": "Selecione \"Criar aplicativo\"",
    "select_options": "Selecione as opções",
    "use_case_other": "Caso de uso: Outro",
    "app_type_business": "Tipo de aplicativo: Negócios",
    "enter_app_details": "Digite os detalhes do aplicativo",
    "app_name": "Nome do aplicativo",
    "app_contact_email": "E-mail de contato do aplicativo",
    "business_account": "Conta de negócios (opcional)",
    "add_whatsapp_to_your_meta_app": "Adicione o WhatsApp ao seu aplicativo Meta",
    "find_add_products_to_your_app": "Encontre \"Adicionar produtos ao seu aplicativo\"",
    "select_whatsapp_set_up": "Selecione 'Configurar WhatsApp'",
    "quick_start": "Início rápido",
    "select_business_account": "Selecione a conta de negócios",
    "purchase_new_business_number": "Compre um novo número de negócios para o WhatsApp",
    "select_api_setup": "Selecione 'Configuração da API' e encontre 'Passo 5'",
    "click_on_the_button": "Clique no botão \"Adicionar número de telefone\"",
    "fill_in_all_fields": "Preencha todos os campos e confirme seu número de telefone",
    "skip_if_already_have": "Pule se você já tiver um número de negócios que possa usar para o seu bot do WhatsApp",
    "configure_whatsapp_settings": "Configure as configurações do WhatsApp",
    "select_configure_webhooks": "Selecione 'Configurar webhooks' na página 'Configuração da API'",
    "edit_webhook": "Edite o webhook",
    "add_a_callback_url_and_verify_token": "Adicione uma URL de retorno de chamada e um token de verificação.",
    "callback_url": "URL de retorno de chamada",
    "verify_token": "Token de verificação",
    "manage_webhook_fields": "Gerencie os campos do webhook",
    "add_and_subscribe_only_to": "Adicione e inscreva-se apenas em",
    "add_a_payment_method_to_your_whatsapp": "Adicione um método de pagamento ao seu aplicativo do WhatsApp",
    "payment_settings": "Configurações de pagamento",
    "add_payment_method": "Adicionar método de pagamento",
    "add_phone_number_and_business_details": "Adicionar número de telefone (e detalhes do negócio)",
    "provide_necessary_info": "Forneça as informações necessárias",
    "verify_number": "Verificar número",
    "send_a_test_message": "Enviar uma mensagem de teste",
    "select_api_setup_and_find_step_1": "Selecione 'Configuração da API' e encontre 'Passo 1':",
    "select_the_from_number": "Selecione o número 'De'",
    "choose_the_option_to_add_and_verify_a_new_number": "Escolha a opção para adicionar e verificar um novo número",
    "select_to_number": "Selecione o número 'Para' (adicione um número pessoal para teste)",
    "select_send_message": "Selecione 'Enviar mensagem'",
    "update_business_details_for_your_meta": "Atualize os detalhes do negócio para o seu aplicativo Meta",
    "select_settings_basic": "Dentro do seu aplicativo Meta, selecione 'Configurações > Básico'",
    "fill_in_all_the_necessary_information": "Preencha todas as informações necessárias",
    "verify_business_account": "Verificar conta de negócios",
    "select_settings": "Selecione 'Configurações'",
    "select_business_account_info": "Selecione 'Informações da conta de negócios'",
    "provide_all_the_necessary_information": "Forneça todas as informações necessárias",
    "select_security_centre": "Selecione 'Centro de segurança'",
    "select_start_verification": "Selecione 'Iniciar verificação'",
    "create_a_system_user": "Crie um usuário do sistema em sua conta de negócios",
    "settings_users_system_users": "Configurações > Usuários > Usuários do sistema",
    "create_new_system_user": "Criar novo usuário do sistema",
    "name_anything": "Nome (qualquer coisa)",
    "role_admin": "Função: Administrador",
    "select_add_assets": "Selecione 'Adicionar ativos'",
    "select_apps": "Selecione 'Aplicativos'",
    "select_the_new_app_for_whatsapp": "Selecione o novo aplicativo (para o WhatsApp)",
    "toggle_manage_app": "Ative 'Gerenciar aplicativo'",
    "generate_new_token_for_system_user": "Gerar novo token para usuário do sistema",
    "select_app_and_generate_new_token": "Selecione o aplicativo e 'Gerar novo token'",
    "select_never_expire": "Selecione 'Nunca' expirar",
    "select_only_permissions_whatsapp_business_messaging": "Selecione apenas as permissões: whatsapp_business_messaging",
    "select_generate_token": "Selecione 'Gerar token'",
    "important_save_this_token": "Importante: Salve este token em algum lugar, você precisará dele",
    "add_token_and_phone_id": "Adicione o Token e o ID do telefone no início desta página",
    "phone_id_this_is_from": "'ID do telefone' - isso é do seu aplicativo Meta (configuração da API, passo 5), certifique-se de selecionar o número 'De' correto e copie o ID do telefone daqui",
    "token_this_is_from_step_8": "'Token' - isso é do Passo 8, do usuário do sistema em sua conta de negócios",
    "launch_your_whatsApp_integration": "Inicie sua integração do WhatsApp",
    "set_your_meta_app_from_development": "Defina seu aplicativo Meta de desenvolvimento para produção (Abra seu aplicativo Meta de"
  }
}