import { getLocaleForUser } from './userPreferences';
import { createI18n } from 'vue-i18n';
import en from './locales/en/helpers-translations.json';
import es from './locales/es/helpers-translations.json';
import pt from './locales/pt/helpers-translations.json';
import de from './locales/de/helpers-translations.json';
import uk from './locales/uk/helpers-translations.json';
import it from './locales/it/helpers-translations.json';

export default createI18n({
  locale: getLocaleForUser(),
  messages: { en, es, pt, de, uk, it },
});
