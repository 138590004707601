{
  "pagesInviteIndex": {
    "invite_sign_up": "Iscriviti all'invito",
    "invite_sign_up_many": "Iscriviti all'invito",
    "invite_sign_up_one": "Iscriviti all'invito",
    "invite_sign_up_other": "Iscriviti all'invito",
    "something_went_awry": "Qualcosa è andato storto!",
    "something_went_awry_many": "Qualcosa è andato storto!",
    "something_went_awry_one": "Qualcosa è andato storto!",
    "something_went_awry_other": "Qualcosa è andato storto!"
  },
  "pagesInviteToken": {
    "something_went_awry": "Qualcosa è andato storto!",
    "something_went_awry_many": "Qualcosa è andato storto!",
    "something_went_awry_one": "Qualcosa è andato storto!",
    "something_went_awry_other": "Qualcosa è andato storto!",
    "token_verification_in_progress": "Verifica del token in corso...",
    "token_verification_in_progress_many": "Verifica del token in corso...",
    "token_verification_in_progress_one": "Verifica del token in corso...",
    "token_verification_in_progress_other": "Verifica del token in corso..."
  }
}