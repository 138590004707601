{
  "aiModel": {
    "please_choose_the_model_that_you_want_to_use": "Будь ласка, оберіть модель, яку ви хочете використовувати. Модель GPT-4 є більш надійною і надає більш точні відповіді, але вона повільніша і дорожча, тоді як GPT 3.5 працює швидше і підходить для більшості випадків.",
    "gpt_model_which_is_used_to_answer_the_questions": "Модель GPT, яка використовується для відповідей на питання",
    "personality": "Особистість",
    "role": "Роль",
    "act_as": "Діяти як...",
    "tone": "Тон",
    "custom_prompt_is_deactivated": "Вимкнено настроювання власного запиту",
    "personality_settings_are_deactivated": "Вимкнено налаштування особистості",
    "speak_tone": "Говорити... тоном",
    "formatting": "Форматування",
    "use": "Використовувати...",
    "formatting_works_better_with_the_chat_gpt_4_model": "Форматування працює краще з чат-моделлю GPT 4.",
    "the_answer_length_field_should_not_be_less_than": "Поле довжини відповіді не повинно бути менше 50 і не більше 2500",
    "using_variables_question_and_context_are_mandatory": "Використання змінних {question} та {context} є обов'язковим!"
  },
  "aiModelAdvanceSettings": {
    "advance_settings": "Розширені налаштування",
    "enter_a_number_between": "Введіть число від {value_1} до {value_2}",
    "custom_prompt": "Власний запит",
    "custom_prompt_template": "Шаблон власного запиту",
    "amount_of_the_last_messages_to_be_saved_in_context": "Кількість останніх повідомлень, які будуть збережені в контексті",
    "precontext": "Попередній контекст",
    "more_about_custom_prompt": "Докладніше про власний запит",
    "save_last_messages_to_context": "Зберегти останні повідомлення в контексті: [введіть число]",
    "precontext_property_controls": "Властивість попереднього контексту контролює кількість відповідей та питань у вашій історії розмови з штучним інтелектом, які будуть використовуватися в нових відповідях штучного інтелекту"
  },
  "aiModelHeader": {
    "ai_model": "Модель ШІ",
    "advance_settings": "Розширені налаштування"
  },
  "aiModelHelpData": {
    "struggling_with_tuning_ai_model": "Важко налаштувати модель ШІ?",
    "don_t_hesitate_to_book_a_15_minutes_free_workshop": "Не соромтеся забронювати 15-хвилинну безкоштовну майстерню з нашим інженером ШІ. Він допоможе налаштувати вашого реального бота ШІ.",
    "book_a_call": "Забронювати дзвінок",
    "struggling_with_custom_settings": "Важко налаштувати власні налаштування?",
    "don_t_hesitate_to_chat_with_our_ai_engineer": "Не соромтеся спілкуватися з нашим інженером ШІ. Він допоможе налаштувати вашого реального бота ШІ.",
    "open_chat": "Відкрити чат"
  },
  "aiModelSettingsData": {
    "degree_of_originality": "Ступінь оригінальності",
    "here_you_can_specify_the_creativity_level": "Тут ви можете вказати рівень креативності моделі. Чим ближче значення до нуля, тим більш креативна модель, але вона може не точно відповідати наданому контексту. Чим ближче значення до одиниці, тим більше модель точно відповідає наданим даним.",
    "topic": "Тема",
    "creative": "Креативна",
    "diversity_range": "Діапазон різноманітності",
    "adjust_this_setting_to_control": "Налаштуйте це налаштування, щоб контролювати різноманітність тексту моделі. Нижчі значення надають більш передбачувані відповіді, тоді як вищі значення пропонують більше різноманіття, але можуть бути менш послідовними.",
    "minimum_textual_diversity": "Мінімальна текстова різноманітність",
    "maximum_textual_diversity": "Максимальна текстова різноманітність",
    "balance": "Баланс",
    "semantic_balance_option": "Варіант семантичного балансу",
    "if_you_need_a_simple_search_in": "Якщо вам потрібен простий пошук у класичному стилі, який відповідає лише словам у наданих даних, то вам потрібно перемістити повзунок ближче до нуля. Навпаки, якщо ви хочете, щоб пошук був більш схожим на обробку природної мови, враховуючи синоніми, контекст і пов'язані поняття, то вам потрібно перемістити повзунок ближче до одиниці.",
    "if_you_want_a_simple_text_search": "Якщо ви хочете простий текстовий пошук, перемістіть повзунок на \"Класичний текстовий пошук\". Для більш складного пошуку з мовними значеннями та формами слів виберіть \"Пошук за контекстом\". Якщо ви не впевнені, встановіть повзунок на \"Оптимальний\".",
    "classic_text_search": "Класичний текстовий пошук",
    "text_search": "Текстовий пошук",
    "semantic_vector_search": "Семантичний векторний пошук",
    "search_by_context": "Пошук за контекстом",
    "knowledge_sources": "Джерела знань",
    "here_you_can_define_the_maximum": "Тут ви можете визначити максимальну кількість документів (або частин), які будуть використовуватися для генерації відповіді.",
    "knowledge_diversity": "Різноманітність знань",
    "knowledge_diversity_settings_helps": "Налаштування різноманітності знань допомагає контролювати різноманітність інформації у ваших результатів пошуку. <br/><br/><strong>Ось як це працює:</strong><br/><br/><strong>Рекомендоване значення: 3:</strong><br/> <i>Мета:</i> Забезпечує хороше поєднання різних інформацій, уникнення повторюваного контенту.<br/> <i>Використання:</i> Чудово підходить для отримання різних точок зору та широкого спектру знань з певної теми.<br/><br/><strong>Значення: 0:</strong><br/> <i>Мета:</i> Включає всю схожу інформацію, навіть якщо вона повторюється.<br/><i>Використання:</i> Корисно, коли ви хочете зібрати всі можливі дані, наприклад, для детального дослідження або комплексного дослідження.<br/><br/>Встановлюючи рівень різноманітності знань, ви можете переконатися, що результати вашого пошуку відповідають вашим потребам, чи то різноманітна інформація, чи всі можливі деталі на певну тему."
  }
}