{
  "apiKeys": {
    "api_keys": "Ключі API",
    "please_generate_your_api_key": "Будь ласка, згенеруйте свій ключ API, якщо ви хочете використовувати наш API Backend",
    "api_keys_not_found": "Ключі API не знайдені.",
    "let_s_generate_it": "Згенеруймо його!",
    "generate_api_key": "Згенерувати ключ API",
    "your_secret_api_keys_are_listed_below": "Ваші секретні ключі API перераховані нижче. Не діліться своїм ключем API з іншими або не розкривайте його в браузері або іншому клієнтському коді.",
    "name": "Назва",
    "key": "Ключ",
    "created": "Створено",
    "expires": "Закінчується",
    "last_used": "Останнє використання",
    "actions": "Дії",
    "name_is_not_provided": "Назва не вказана",
    "api_key_was_update": "Ключ API був оновлений!",
    "you_must_generate_a_new_api_key": "Ви повинні згенерувати новий ключ API, щоб скасувати єдиний залишений ключ.",
    "api_key_was_revoked": "Ключ API був скасований!"
  },
  "createApiKeyModal": {
    "create_new_secret_key": "Створити новий секретний ключ",
    "name_optional": "Назва (необов'язково)",
    "my_test_key": "Мій тестовий ключ",
    "create_secret_key": "Створити секретний ключ"
  },
  "createdNewKeyModal": {
    "created_new_secret_key": "Створено новий секретний ключ",
    "please_save_this_secret_key": "Будь ласка, збережіть цей секретний ключ в безпечному і доступному місці. З міркувань безпеки ви не зможете його переглянути знову через свій обліковий запис. Якщо ви втратите цей секретний ключ, вам доведеться згенерувати новий.",
    "api_key_copied": "Ключ API скопійовано!"
  },
  "presentApiKeyModal": {
    "your_secret_key": "Ваш секретний ключ",
    "secret_key": "Секретний ключ",
    "api_key_copied": "Ключ API скопійовано!"
  },
  "renameApiKeyModal": {
    "edit_secret_key": "Редагувати секретний ключ",
    "save": "Зберегти",
    "edit_name": "Редагувати назву",
    "my_test_key": "Мій тестовий ключ"
  },
  "revokeKeyModal": {
    "revoke_secret_key": "Скасувати секретний ключ",
    "this_api_key_will_immediately_be_disabled": "Цей ключ API буде негайно вимкнено. Запити API, зроблені за допомогою цього ключа, будуть відхилені, що може призвести до поломки будь-яких систем, які все ще залежать від нього. Після скасування ви більше не зможете переглядати або змінювати цей ключ API.",
    "revoke_key": "Скасувати ключ"
  }
}