{
  "aiModel": {
    "please_choose_the_model_that_you_want_to_use": "Please, choose the model that you want to use. GPT-4 model is more robust and provides more precise answers but slower and more expensive whereas GPT 3.5 is faster and suits most cases.",
    "gpt_model_which_is_used_to_answer_the_questions": "GPT model which is used to answer the questions",
    "personality": "Personality",
    "role": "Role",
    "act_as": "Act as...",
    "tone": "Tone",
    "custom_prompt_is_deactivated": "Custom prompt is deactivated",
    "personality_settings_are_deactivated": "Personality settings are deactivated",
    "speak_tone": "Speak... tone",
    "formatting": "Formatting",
    "use": "Use...",
    "formatting_works_better_with_the_chat_gpt_4_model": "Formatting works better with the chat GPT 4 model.",
    "the_answer_length_field_should_not_be_less_than": "The Answer length field should not be less than 50 and not greater than 2500",
    "using_variables_question_and_context_are_mandatory": "Using variables {question} and {context} are mandatory!"
  },
  "aiModelAdvanceSettings": {
    "advance_settings": "Advance Settings",
    "enter_a_number_between": "Enter a number between {value_1} and {value_2}",
    "custom_prompt": "Custom Prompt",
    "custom_prompt_template": "Custom prompt template",
    "amount_of_the_last_messages_to_be_saved_in_context": "Amount of the last messages to be saved in context",
    "precontext": "Precontext",
    "more_about_custom_prompt": "More about custom prompt",
    "save_last_messages_to_context": "Save last messages to context: [enter a number]",
    "precontext_property_controls": "Pre-context property controls the number of answers and questions in your conversation history with AI which are going to be used in the new answers of AI"
  },
  "aiModelHeader": {
    "ai_model": "AI Model",
    "advance_settings": "Advance Settings"
  },
  "aiModelHelpData": {
    "struggling_with_tuning_ai_model": "Struggling with tuning AI model?",
    "don_t_hesitate_to_book_a_15_minutes_free_workshop": "Don't hesitate to book a 15-minutes free workshop with our AI engineer. He helps with tuning your real AI bot.",
    "book_a_call": "Book a call",
    "struggling_with_custom_settings": "Struggling with custom settings?",
    "don_t_hesitate_to_chat_with_our_ai_engineer": "Don't hesitate to chat with our AI engineer. He helps with tuning your real AI bot.",
    "open_chat": "Open chat"
  },
  "aiModelSettingsData": {
    "degree_of_originality": "Degree of Originality",
    "here_you_can_specify_the_creativity_level": "Here you can specify the creativity level for the model. The closer the value to zero, the more creative the model, but it may not stick directly to the context provided in the uploaded data. The closer the value to one, the more the model sticks directly to the provided data.",
    "topic": "Topic",
    "creative": "Creative",
    "diversity_range": "Diversity Range",
    "adjust_this_setting_to_control": "Adjust this setting to control how diverse the model's text will be. Lower values provide more predictable responses, while higher values offer more variety but may be less consistent.",
    "minimum_textual_diversity": "Minimum textual diversity",
    "maximum_textual_diversity": "Maximum textual diversity",
    "balance": "Balance",
    "semantic_balance_option": "Semantic balance option",
    "if_you_need_a_simple_search_in": "If you need a simple search in the classical way matching just the words in the provided data, then you have to move the slider closer to zero. On the contrary, if you want the search to be more like natural language processing, looking up word forms taking into consideration synonyms, context, and related concepts, then you have to move the slider closer to one.",
    "if_you_want_a_simple_text_search": "If you want a simple text search, move the slider to \"Classical text search.\" For a more complex search with language meanings and word forms, choose \"Search by context.\" If you are unsure, set the slider to \"Optimal.\"",
    "classic_text_search": "Classic text search",
    "text_search": "Text search",
    "semantic_vector_search": "Semantic vector search",
    "search_by_context": "Search by context",
    "knowledge_sources": "Knowledge Sources",
    "here_you_can_define_the_maximum": "Here you can define the maximum number of documents (or rather chunks) that will be used to generate the answer.",
    "knowledge_diversity": "Knowledge Diversity",
    "knowledge_diversity_settings_helps": "The Knowledge Diversity setting helps you control the variety of information you get in your search results. <br/><br/><strong>Here’s how it works:</strong><br/><br/><strong>Recommended Value: 3:</strong><br/> <i>Purpose:</i> Provides a good mix of different information while avoiding repetitive content.<br/> <i>Use Case:</i> Great for getting diverse viewpoints and a wide range of knowledge on a topic.<br/><br/><strong>Value: 0:</strong><br/> <i>Purpose:</i> Includes all similar content, even if it repeats information.<br/><i>Use Case:</i> Useful when you want to collect all possible data, like for a detailed research project or comprehensive study.<br/><br/>By setting the Knowledge Diversity level, you can make sure your search results are tailored to what you need, whether it’s varied information or every possible detail on a topic."
  }
}
