{
  "appTranslations": {
    "subscription_limit_reached": "Abonnementlimit erreicht",
    "upgrade_subscription": "Abonnement aktualisieren",
    "it_looks_like_you_ve_reached": "Es sieht so aus, als hätten Sie das Limit Ihres aktuellen Abonnements erreicht. Um unseren Service weiterhin nutzen zu können, klicken Sie bitte auf die Schaltfläche unten, um Ihr Abonnement zu aktualisieren.",
    "we_ve_successfully_renewed_your": "Wir haben Ihr Abonnement erfolgreich verlängert.",
    "we_encountered_an_error_while": "Beim Versuch, Ihr Abonnement zu verlängern, ist ein Fehler aufgetreten.",
    "run_out_of_units": "Ihnen sind die verfügbaren Einheiten ausgegangen. Bitte füllen Sie Ihre Einheiten auf oder aktualisieren Sie den Abrechnungsplan.",
    "ok": "OK"
  }
}