import { GET_IS_PAID_USER } from '@/api';
import { useAppStore } from '@/stores';
import { useSubscription } from '@vue/apollo-composable';
import { ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { redirectToPackagesSelectionPage } from '@/router/utils/redirectToPackagesSelection';

export const subscribeToIsPaidUser = (id?: string) => {
  const userId = ref(id);
  const isPaidUser = ref<boolean | null>(null);

  const store = useAppStore();
  const router = useRouter();
  const route = useRoute();

  const { onResult, stop, start } = useSubscription(GET_IS_PAID_USER, { id: userId });

  onResult((result) => {
    if (result.data) {
      const res = result.data?.profiles[0].billing[0];
      isPaidUser.value = +res?.units_amount - +res?.units_spent >= 0;
      store.setIsPaid(isPaidUser.value);
      if (!isPaidUser.value && route.path.indexOf('/create') > -1) {
        redirectToPackagesSelectionPage(router);
      }
    }
  });

  const setNewUserId = (id?: string) => {
    userId.value = id;
    start();
  };

  const stopSubscription = () => {
    isPaidUser.value = null;
    stop();
  };

  watch(
    () => isPaidUser.value,
    () => {
      store.setIsPaid(isPaidUser.value);
    },
  );

  return { isPaidUser, setNewUserId, stopSubscription };
};
