{
  "docSearchWidget": {
    "scanning_through_the_documentation": "Analisando a documentação, por favor aguarde um momento!",
    "generate_three_suggested_questions": "Gerar três perguntas sugeridas com base nos recursos fornecidos. Cada pergunta deve ser separada por uma quebra de linha e não deve ter mais de cinco palavras. Por favor, evite numerar as perguntas.",
    "oops_it_seems_youve_reached_your": "Ops! Parece que você atingiu o limite de mensagens. Para continuar, ajuste seu limite de mensagens nas configurações.",
    "we_encountered_an_issue_while": "Encontramos um problema ao tentar gerar a resposta! Tente novamente mais tarde!",
    "search": "Pesquisar"
  },
  "docSearchWidgetHeader": {
    "hi_how_can_i_help_you": "Oi! Como posso ajudar você?"
  },
  "docSearchWidgetInput": {
    "type_a_question_here": "Digite uma pergunta aqui...",
    "ask": "Perguntar"
  },
  "docSearchWidgetReset": {
    "reset": "Redefinir"
  },
  "docSearchWidgetWrapper": {
    "click_here_to_see_embed_code": "Clique aqui para ver o código de incorporação"
  }
}