<template>
  <vue-collapsible
    class="text-white-steel-blue-gray"
    :is-open="true"
    :class="{ 'disabled-items': isDisabled }"
  >
    <SidebarProjectTreeItem
      v-for="item in collapsibleList"
      :key="item.query"
      :title="item.title"
      :route="basicItemRoute + `/settings?layout=${item.query}`"
      :is-selected="itemName === item.query && isSettingsRoute"
      :is-setting-item="true"
      @click="$emit('selectedSettingItem', item.query)"
    />
  </vue-collapsible>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import VueCollapsible from 'vue-height-collapsible/vue3';
import SidebarProjectTreeItem from '@components/layout/sidebar/SidebarProjectTreeItem.vue';
import { useI18n } from 'vue-i18n';

//Types
import { ItemTypeKeysEnum } from '@/types/ItemTypeKeysEnum';
import type { WidgetTypeKeysEnum } from '@/types/WidgetTypeKeysEnum';
import { getWidgetSettingsEnabled } from '@components/project/settings/helpers';
import { useRouter } from 'vue-router';
import { useAppStore } from '@/stores';

interface WidgetSettingsItem {
  title: string;
  query: ItemTypeKeysEnum;
}

const { t } = useI18n();

const props = defineProps<{
  basicItemRoute: string;
  itemName: ItemTypeKeysEnum;
  wKey: WidgetTypeKeysEnum;
}>();

defineEmits<{
  (e: 'selectedSettingItem', value: ItemTypeKeysEnum): void;
}>();

const accordionIsOpen = ref(false);
const router = useRouter();
const store = useAppStore();

const isSettingsRoute = computed(
  () => router.currentRoute.value.name === 'projects-id-widget-widgetId-settings',
);

watch(
  accordionIsOpen,
  (newValue) => {
    if (newValue) {
      router.push(`${props.basicItemRoute}/settings?layout=${collapsibleList.value[0].query}`);
    }
  },
  { immediate: true },
);

const isDisabled = computed(() => store.isLoadingQuestions);

const widgetSettingsItems = computed<WidgetSettingsItem[]>(() => {
  const enabledSettings = computed(() => getWidgetSettingsEnabled(props.wKey));

  const items: WidgetSettingsItem[] = [];

  if (enabledSettings.value.hasGpt || enabledSettings.value.hasPrompt) {
    items.push({
      title: t('sidebarProjectTreeSettingsItem.ai_model'),
      query: ItemTypeKeysEnum.AiModel,
    });
  }

  if (enabledSettings.value.hasBehaviour) {
    items.push({
      title: t('sidebarProjectTreeSettingsItem.behavior'),
      query: ItemTypeKeysEnum.Behaviour,
    });
  }

  if (enabledSettings.value.hasCustomize) {
    items.push({
      title: t('sidebarProjectTreeSettingsItem.appearance'),
      query: ItemTypeKeysEnum.Appearance,
    });
  }

  if (enabledSettings.value.hasIntegrations) {
    items.push({
      title: t('sidebarProjectTreeSettingsItem.integrations'),
      query: ItemTypeKeysEnum.Integrations,
    });
  }

  return items;
});

const collapsibleList = computed<WidgetSettingsItem[]>(() =>
  props.wKey === 'TableWidget'
    ? [{ title: t('sidebarProjectTreeSettingsItem.database'), query: ItemTypeKeysEnum.Database }]
    : widgetSettingsItems.value,
);
</script>

<style scoped>
.settingsItem:hover {
  background-color: rgba(159, 161, 160, 0.058);
}
.disabled-items {
  pointer-events: none;
  opacity: 0.5;
}
</style>
