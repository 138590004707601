{
  "quizWidget": {
    "delete_this_question": "¿Eliminar esta pregunta?",
    "are_you_sure_you_want_to_delete": "¿Estás seguro de que quieres eliminar esta pregunta del cuestionario? Esta acción no se puede deshacer.",
    "delete_this_quiz": "¿Eliminar este cuestionario?",
    "are_you_sure_you_want_to_delete_the_quiz": "¿Estás seguro de que quieres eliminar todo el cuestionario? Esta acción no se puede deshacer.",
    "export_to_quizlet": "Exportar a Quizlet",
    "use_pattern": "Usar patrón",
    "the_quiz_should_test_general_knowledge": "El cuestionario debe evaluar conocimientos generales, pensamiento crítico y comprensión. La pregunta debe tener un espacio para llenar.",
    "can_be_more_than_one_correct": "Puede haber más de una opción correcta",
    "each_question_should_focus": "4) Cada pregunta debe centrarse en un tema o tema específico. 5) Cada pregunta debe tener UN MÍNIMO DE 4 PARES DE ELEMENTOS para emparejar. 6) La estructura del JSON debe ser una matriz de objetos, donde cada objeto representa una pregunta con un campo 'question' y un campo 'correct'. El campo 'correct' debe ser un objeto en sí mismo, que contenga pares clave-valor que deben coincidir. 7) El contenido debe ser educativo y basado en información factual o conceptos bien establecidos. 8) Utilice el siguiente patrón JSON para cada pregunta:",
    "the_quiz_should_cover": "El cuestionario debe cubrir diversos temas o diferentes aspectos del mismo tema.",
    "question_task_arrange_items": "Tarea de la pregunta: ordenar elementos o eventos en el orden correcto. Mínimo 4 opciones colocadas en la matriz 'correct' en la secuencia correcta",
    "given_the_context": "Dado el {contexto}, por favor genera un cuestionario EN",
    "language_in_a_valid_json_format": "idioma en un FORMATO JSON VÁLIDO. El cuestionario debe tener las siguientes características: 1) Tipo de pregunta:",
    "number_of_questions": "Número de preguntas",
    "difficulty_level": "Nivel de dificultad",
    "this_time_only": "Esta vez solo {newQuestionsLength} preguntas. Tal vez no haya suficiente contenido.",
    "something_didn_t_go_as_planned": "Algo no salió como se planeó.",
    "failed_to_get_the_pdf_file": "No se pudo obtener el archivo PDF."
  },
  "quizWidgetAddCustomQuestionModal": {
    "create_custom_question": "Crear pregunta personalizada",
    "question_type": "Tipo de pregunta",
    "difficulty": "Dificultad"
  },
  "quizWidgetDeleteModal": {
    "delete_this_question": "¿Eliminar esta pregunta?",
    "are_you_sure_you_want_to_delete": "¿Estás seguro de que quieres eliminar esta pregunta del cuestionario? Esta acción no se puede deshacer."
  },
  "quizWidgetExportModal": {
    "export_configuration": "Configuración de exportación",
    "decide_what_should_be_included": "Decide qué debe incluirse en la exportación de tu cuestionario",
    "show_numeration": "Mostrar numeración",
    "show_answers": "Mostrar respuestas",
    "show_explanation": "Mostrar explicación",
    "show_instructions": "Mostrar instrucciones",
    "show_difficulty": "Mostrar dificultad",
    "show_images": "Mostrar imágenes"
  },
  "quizWidgetForm": {
    "question_type": "Tipo de pregunta",
    "language": "Idioma",
    "difficulty": "Dificultad",
    "max_questions": "Máximo de preguntas",
    "add_more": "Agregar más",
    "generate": "Generar",
    "original": "Original"
  },
  "quizWidgetListFooter": {
    "hide": "Ocultar",
    "show": "Mostrar",
    "answers": "respuestas",
    "create_custom_question": "Crear pregunta personalizada",
    "clear_quiz": "Limpiar cuestionario",
    "export": "Exportar"
  },
  "quizWidgetListHeader": {
    "quiz": "Cuestionario",
    "questions": "preguntas"
  },
  "quizWidgetQuestionCardFooter": {
    "change_difficulty": "Haz clic aquí para cambiar la dificultad"
  }
}