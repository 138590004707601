{
  "cardEditorFooter": {
    "cancel": "Abbrechen",
    "save": "Speichern"
  },
  "cardFillBlank": {
    "correct_answer": "Richtige Antwort",
    "explanation": "Erklärung"
  },
  "cardFillBlankEditor": {
    "question": "Frage",
    "enter_question": "Frage eingeben",
    "correct_answer": "Richtige Antwort",
    "enter_correct_answer": "Richtige Antwort eingeben",
    "explanation_optional": "Erklärung (optional)",
    "enter_explanation_for_the_correct_answer": "Erklärung für die richtige Antwort eingeben",
    "instructions_optional": "Anweisungen (optional)",
    "enter_instructions_for_answering_the_question": "Anweisungen zum Beantworten der Frage eingeben"
  },
  "cardMatching": {
    "correct_answer": "Richtige Antwort",
    "explanation": "Erklärung"
  },
  "cardMatchingEditor": {
    "question": "Frage",
    "enter_question": "Frage eingeben",
    "answers": "Antworten",
    "list_the_items_in_the_correct_sequence": "Liste die Elemente in der richtigen Reihenfolge auf. Sie werden für die Aktivität zufällig angeordnet.",
    "enter_answer_option": "Antwortoption eingeben",
    "add_more": "Weitere hinzufügen",
    "explanation_optional": "Erklärung (optional)",
    "enter_explanation_for_the_correct_answer": "Erklärung für die richtige Antwort eingeben",
    "instructions_optional": "Anweisungen (optional)",
    "enter_instructions_for_answering_the_question": "Anweisungen zum Beantworten der Frage eingeben"
  },
  "cardMultipleAnswers": {
    "correct_answers": "Richtige Antworten",
    "explanation": "Erklärung"
  },
  "cardMultipleAnswersEditor": {
    "question": "Frage",
    "enter_question": "Frage eingeben",
    "answer": "Antwort",
    "enter_answer_option": "Antwortoption eingeben",
    "add_more": "Weitere hinzufügen",
    "correct_answers": "Richtige Antworten",
    "explanation_optional": "Erklärung (optional)",
    "enter_explanation_for_the_correct_answer": "Erklärung für die richtige Antwort eingeben",
    "instructions_optional": "Anweisungen (optional)",
    "enter_instructions_for_answering_the_question": "Anweisungen zum Beantworten der Frage eingeben",
    "only_unique_answer_values_allowed": "Nur eindeutige Antwortwerte erlaubt!"
  },
  "cardMultipleChoice": {
    "correct_answer": "Richtige Antwort",
    "explanation": "Erklärung"
  },
  "cardMultipleChoiceEditor": {
    "question": "Frage",
    "enter_question": "Frage eingeben",
    "answer": "Antwort",
    "enter_answer_option": "Antwortoption eingeben",
    "add_more": "Weitere hinzufügen",
    "correct_answer": "Richtige Antwort",
    "enter_correct_answer": "Richtige Antwort eingeben",
    "explanation_optional": "Erklärung (optional)",
    "enter_explanation_for_the_correct_answer": "Erklärung für die richtige Antwort eingeben",
    "instructions_optional": "Anweisungen (optional)",
    "enter_instructions_for_answering_the_question": "Anweisungen zum Beantworten der Frage eingeben",
    "only_unique_answer_values_allowed": "Nur eindeutige Antwortwerte erlaubt!"
  },
  "cardSequence": {
    "correct_sequence": "Richtige Reihenfolge",
    "explanation": "Erklärung"
  },
  "cardSequenceEditor": {
    "question": "Frage",
    "enter_question": "Frage eingeben",
    "correct_answers": "Richtige Antworten",
    "list_the_items_in_the_correct_sequence": "Liste die Elemente in der richtigen Reihenfolge auf. Sie werden für die Aktivität zufällig angeordnet.",
    "enter_answer_option": "Antwortoption eingeben",
    "add_more": "Weitere hinzufügen",
    "explanation_optional": "Erklärung (optional)",
    "enter_explanation_for_the_correct_answer": "Erklärung für die richtige Antwort eingeben",
    "instructions_optional": "Anweisungen (optional)",
    "enter_instructions_for_answering_the_question": "Anweisungen zum Beantworten der Frage eingeben",
    "empty_values_are_not_allowed": "Leere Werte sind nicht erlaubt!"
  },
  "cardShortAnswer": {
    "correct_answer": "Richtige Antwort",
    "explanation": "Erklärung"
  },
  "cardShortAnswerEditor": {
    "question": "Frage",
    "enter_question": "Frage eingeben",
    "correct_answer": "Richtige Antwort",
    "enter_correct_answer": "Richtige Antwort eingeben",
    "explanation_optional": "Erklärung (optional)",
    "enter_explanation_for_the_correct_answer": "Erklärung für die richtige Antwort eingeben",
    "instructions_optional": "Anweisungen (optional)",
    "enter_instructions_for_answering_the_question": "Anweisungen zum Beantworten der Frage eingeben"
  },
  "cardTrueFalse": {
    "true": "Wahr",
    "false": "Falsch",
    "correct_answer": "Richtige Antwort",
    "explanation": "Erklärung"
  },
  "cardTrueFalseEditor": {
    "question": "Frage",
    "enter_question": "Frage eingeben",
    "correct_answer": "Richtige Antwort",
    "explanation_optional": "Erklärung (optional)",
    "enter_explanation_for_the_correct_answer": "Erklärung für die richtige Antwort eingeben",
    "instructions_optional": "Anweisungen (optional)",
    "enter_instructions_for_answering_the_question": "Anweisungen zum Beantworten der Frage eingeben",
    "true": "Wahr",
    "false": "Falsch"
  }
}