{
  "popupChatWidget": {
    "clear_chat": "Limpar chat",
    "close": "Fechar",
    "processing_your_request": "Processando sua solicitação...",
    "please_ask_the_owner_of_the_project_to_make_it_public": "Por favor, peça ao proprietário do projeto para torná-lo público",
    "the_project_owner_needs_to_upgrade": "O proprietário do projeto precisa fazer upgrade da assinatura, pois a atual expirou.",
    "oops_we_have_t_found": "404 Ooops, não encontramos o projeto",
    "oops_it_seems_youve_reached": "Oops! Parece que você atingiu o limite de mensagens. Para continuar, ajuste seu limite de taxa nas configurações."
  }
}