{
  "pagesProfileId": {
    "profile": "Profil",
    "profile_settings": "Profil-Einstellungen",
    "settings": "Einstellungen",
    "home": "Startseite",
    "change_password": "Passwort ändern"
  },
  "pagesProfileIndex": {
    "my_profile": "Mein Profil",
    "notifications": "Benachrichtigungen",
    "profile_summary": "Profilzusammenfassung",
    "services_credentials": "Dienstzugangsdaten",
    "api_keys": "API-Schlüssel",
    "billings": "Abrechnungen",
    "marketplace": "Marktplatz",
    "packages": "Pakete",
    "integrations": "Integrationen",
    "payment_details": "Zahlungsdetails"
  }
}