{
  "billingsActivityAnalytics": {
    "title": "Analíticas"
  },
  "billingsActivityAnalyticsAverageChart": {
    "title": "Duración promedio de las solicitudes",
    "length": "Duración",
    "no_requests_yet": "Aún no hay solicitudes"
  },
  "billingsActivityAnalyticsFilters": {
    "today": "hoy",
    "7_days": "7 días",
    "month": "mes"
  },
  "billingsActivityAnalyticsRequestsChart": {
    "title": "Solicitudes",
    "unique_visitors": "Visitantes únicos",
    "per_day": "por día",
    "per_session": "por sesión"
  },
  "billingsActivityAnalyticsVisitors": {
    "title": "Visitantes únicos",
    "all_visitors": "Todos los visitantes",
    "no_visitors_yet": "Aún no hay visitantes"
  },
  "billingsActivityAnalyticsSuccessRate": {
    "title": "Tasa de éxito",
    "subtitle": "Con qué frecuencia OmniMind puede responder preguntas"
  },
  "billingsActivityAnalyticsCosts": {
    "title": "Costos"
  }
}
