{
  "ninjaIndicator": {
    "please_wait_while": "Aguarde enquanto processamos seus dados. Isso pode levar cerca de um minuto.",
    "redirecting_to_your_project": "Redirecionando para o seu projeto...",
    "we_are_processing_your_information": "Estamos processando suas informações..."
  },
  "progressCancelChain": {
    "cancel_all": "Cancelar tudo",
    "do_you_want_to_stop": "Deseja parar todas as cadeias de processamento?",
    "stop_processing": "Parar o processamento?",
    "yes": "Sim",
    "no": "Não"
  },
  "progressNinjaLoader": {
    "please_wait_while_we_process": "Aguarde enquanto processamos seus dados. Isso pode levar cerca de um minuto.",
    "redirecting_to_your_project": "Redirecionando para o seu projeto...",
    "we_are_processing_your_information": "Estamos processando seus dados agora, o que pode levar algum tempo. Você pode começar a usar o sistema imediatamente e o processo continuará em segundo plano.",
    "process_in_background": "Processar em segundo plano"
  },
  "progressResourceItem": {
    "there_were_some_errors_during_processing": "Ocorreram alguns erros durante o processamento."
  },
  "progressResourceList": {
    "jobs_in_progress": "Trabalhos em andamento"
  }
}