{
  "baseDropboxFile": {
    "schema": {
      "folder_name": "Nombre de la carpeta de Dropbox",
      "folder_description": "Nombre de la carpeta actual",
      "url_title": "URL del archivo de Dropbox",
      "url_description": "La URL al archivo de Dropbox"
    },
    "schemaUi": {
      "label": "Archivo de Dropbox",
      "description": "Aquí puedes pegar la URL de tu archivo en Dropbox. Por favor, asegúrate de que el archivo esté disponible públicamente, de lo contrario obtendrás un error en la etapa de procesamiento",
      "placeholder": "Ingrese la URL"
    }
  },
  "baseDropboxFolder": {
    "schema": {
      "url_title": "URL de la carpeta de Dropbox",
      "url_description": "La URL a la carpeta de Dropbox"
    },
    "schemaUi": {
      "label": "Carpeta de Dropbox",
      "description": "Aquí puedes pegar el enlace a tu carpeta de Dropbox. Por favor, asegúrate de que tanto la carpeta como todos los archivos dentro estén disponibles públicamente, de lo contrario obtendrás un error en la etapa de procesamiento",
      "placeholder": "Ingrese la URL"
    }
  },
  "baseGoogleDriveFile": {
    "schema": {
      "folder_name": "Nombre de la carpeta de Gdrive",
      "folder_description": "El nombre para la carpeta de Gdrive",
      "url_title": "URL del archivo de Gdrive",
      "url_description": "La URL al archivo de Gdrive"
    },
    "schemaUi": {
      "label": "Archivo de Gdrive",
      "description": "Aquí puedes pegar el enlace a tu archivo de Google Drive. Por favor, asegúrate de que el archivo esté disponible públicamente, de lo contrario obtendrás un error en la etapa de procesamiento",
      "placeholder": "Ingrese la URL"
    }
  },
  "baseGoogleDriveFolder": {
    "schema": {
      "url_title": "URL de la carpeta de Gdrive",
      "url_description": "La URL a la carpeta de Gdrive"
    },
    "schemaUi": {
      "label": "Carpeta de Gdrive",
      "description": "Aquí puedes agregar el enlace a tu carpeta de Google Drive. Por favor, asegúrate de que tanto la carpeta como todos los archivos dentro de la carpeta estén disponibles públicamente, de lo contrario obtendrás un error en la etapa de procesamiento",
      "placeholder": "Ingrese la URL"
    }
  },
  "baseOneDriveFile": {
    "schema": {
      "parent_title": "Nombre de la carpeta de Gdrive",
      "url_title": "URL del archivo de OneDrive",
      "url_description": "La URL al archivo de OneDrive"
    },
    "schemaUi": {
      "label": "Archivo de OneDrive",
      "description": "Aquí puedes pegar el enlace a tu archivo de OneDrive. Por favor, asegúrate de que el archivo esté disponible públicamente, de lo contrario obtendrás un error en la etapa de procesamiento",
      "placeholder": "Ingrese la URL"
    }
  },
  "baseOneDriveFolder": {
    "schema": {
      "url_title": "URL de la carpeta de OneDrive",
      "url_description": "La URL a la carpeta de OneDrive"
    },
    "schemaUi": {
      "label": "Carpeta de OneDrive",
      "description": "Aquí puedes agregar el enlace a tu carpeta de OneDrive. Por favor, asegúrate de que tanto la carpeta como todos los archivos dentro de la carpeta estén disponibles públicamente, de lo contrario obtendrás un error en la etapa de procesamiento",
      "placeholder": "Ingrese la URL"
    }
  },
  "baseXmlSitemap": {
    "schema": {
      "url_title": "URL de XML",
      "url_description": "Escribe la URL de tu mapa del sitio"
    },
    "schemaUi": {
      "label": "Mapa del sitio XML",
      "description": "Si tienes un archivo de mapa del sitio con todos los enlaces que deseas indexar, puedes definir la URL del archivo de mapa del sitio",
      "placeholder": "https://ejemplo.com/sitemap.xml"
    }
  },
  "baseYoutubeChannel": {
    "schema": {
      "url_title": "URL del canal",
      "url_description": "Por favor, presiona compartir en el canal de YouTube y copia el enlace en el cuadro de entrada de arriba",
      "language_title": "El idioma preferido"
    },
    "schemaUi": {
      "label": "Canal de YouTube",
      "description": "Por favor, pega el enlace al canal de YouTube que tiene los subtítulos en el idioma especificado a continuación. Nota: el informador solo procesa videos con subtítulos, de lo contrario obtendrás un error.",
      "placeholder": "Ingrese la URL",
      "placeholder_2": "en"
    }
  },
  "baseYoutubePlaylist": {
    "schema": {
      "url_title": "URL de la lista de reproducción",
      "url_description": "Por favor, presiona compartir en la lista de reproducción de YouTube y copia el enlace en el cuadro de entrada de arriba",
      "language_title": "El idioma preferido"
    },
    "schemaUi": {
      "label": "Lista de reproducción de YouTube",
      "description": "Por favor, pega el enlace a la lista de reproducción de YouTube que tiene los subtítulos en el idioma especificado a continuación. Nota: el informador solo procesa videos con subtítulos, de lo contrario obtendrás un error.",
      "placeholder": "Ingrese la URL",
      "placeholder_2": "en"
    }
  },
  "baseZendesk": {
    "schema": {
      "url_title": "URL de Zendesk",
      "url_description": "Por favor, ingresa el enlace del centro de ayuda de Zendesk"
    },
    "schemaUi": {
      "label": "Zendesk",
      "description": "Por favor, pega el enlace al centro de ayuda de Zendesk.",
      "placeholder": "Ingrese la URL"
    }
  },
  "baseText": {
    "schema": {
      "content_title": "Texto",
      "content_description": "Por favor, agrega el texto que deseas agregar a tu proyecto",
      "metadata_title": "Metadatos adicionales"
    },
    "schemaUi": {
      "label": "Texto",
      "description": "Agrega el texto a tu proyecto",
      "placeholder": "Ingrese el texto"
    }
  },
  "baseUrl": {
    "schema": {
      "url_title": "URL del archivo",
      "url_description": "La URL del archivo que se agregará al proyecto"
    },
    "schemaUi": {
      "label": "Archivo URL",
      "description": "Por favor, asegúrate de que el archivo esté público en Internet, de lo contrario obtendrás un error de autorización",
      "placeholder": "ejemplo.com/archivo.pdf"
    }
  }
}