{
  "audioInformerInformers": {
    "language": "Sprache",
    "select_files_to_upload": "Dateien zum Hochladen auswählen",
    "for_advanced_users_only": "Nur für fortgeschrittene Benutzer!",
    "advanced_settings": "Erweiterte Einstellungen",
    "your_uploaded_files": "Ihre hochgeladenen Dateien",
    "file": "Datei",
    "was_added_previously": "wurde zuvor hinzugefügt",
    "an_error_occurred_while": "Beim Hochladen einer Datei ist ein Fehler aufgetreten"
  },
  "baseInformerInformers": {
    "project_does_not_exist_in_data_base_with": "Projekt existiert nicht in der Datenbank mit"
  },
  "fileInformerInformers": {
    "select_files_to_upload": "Dateien zum Hochladen auswählen",
    "for_advanced_users_only": "Nur für fortgeschrittene Benutzer!",
    "advanced_settings": "Erweiterte Einstellungen",
    "your_uploaded_files": "Ihre hochgeladenen Dateien",
    "looks_like_your_file": "Sieht so aus, als ob Ihre Datei",
    "does_not_have_any_text": "keinen Text enthält, den wir direkt verwenden können. Keine Sorge! Sie können sie ganz einfach",
    "convert_it_into_a_text_filled_pdf": "in eine mit Text gefüllte PDF konvertieren und mit PDF OCR Informer hochladen",
    "upgrade_your_plan_and": "upgraden Sie Ihren Plan und konvertieren Sie ihn in eine mit Text gefüllte PDF und laden Sie sie mit PDF OCR Informer hoch",
    "file": "Datei",
    "was_added_previously": "wurde zuvor hinzugefügt",
    "your": "Ihre",
    "has_been_successfully_uploaded": "wurde erfolgreich hochgeladen",
    "an_error_occurred_while": "Beim Hochladen der Datei ist ein Fehler aufgetreten"
  },
  "notionInformerInformers": {
    "your": "Ihre",
    "a_secure_integration_secret": "* Ein sicheres Integrationsschlüssel, der von Ihrem Notion-Konto generiert wurde und eine nahtlose und sichere Integration gewährleistet.",
    "integration_token": "Integrations-Token",
    "page_url": "Seiten-URL",
    "page_should_be_shared": "* Ihre Notion-Seite sollte mit Ihrer Integration geteilt werden.",
    "your_uploaded_links": "Ihre hochgeladenen Links:",
    "not_valid_url": "Der Wert ist keine gültige URL-Adresse",
    "add": "Hinzufügen"
  },
  "pdfOcrFileInformerInformers": {
    "select_files_to_upload": "Dateien zum Hochladen auswählen",
    "for_advanced_users_only": "Nur für fortgeschrittene Benutzer!",
    "advanced_settings": "Erweiterte Einstellungen",
    "your_uploaded_files": "Ihre hochgeladenen Dateien",
    "great_news_your_file": "Großartige Neuigkeiten! Ihre Datei",
    "is_a_straightforward_pdf": "ist eine einfache PDF und erfordert keine spezielle OCR-Verarbeitung. Bitte gehen Sie weiter und",
    "upload_it_using_our": "laden Sie es mit unserem File Informer hoch",
    "for_a_smoother_experience": "für ein reibungsloseres Erlebnis!",
    "file": "Datei",
    "was_added_previously": "wurde zuvor hinzugefügt",
    "your": "Ihre",
    "has_been_successfully_uploaded": "wurde erfolgreich hochgeladen",
    "an_error_occurred_while": "Beim Hochladen der Datei ist ein Fehler aufgetreten"
  },
  "singleWebInformerInformers": {
    "type_in_your_url_here": "Geben Sie hier Ihre URL ein",
    "preview": "Vorschau",
    "page_content": "Seiteninhalt",
    "preview_content": "Vorschauinhalt",
    "project_does_not_exist_in_data_base_with": "Projekt existiert nicht in der Datenbank mit"
  },
  "uploadAdvancedSettingsInformers": {
    "embeddings_provider": "Embeddings-Anbieter",
    "you_can_specify_the": "Sie können den Embeddings-Anbieter angeben, der Ihre Informationen verarbeitet."
  },
  "webInformerInformers": {
    "paste_your_batch_of_links_here": "Fügen Sie hier Ihre Links ein",
    "add_urls": "URLs hinzufügen",
    "your_uploaded_links": "Ihre hochgeladenen Links:",
    "page_content": "Seiteninhalt",
    "preview_content": "Vorschauinhalt",
    "must_be_filled_with_a_value": "Muss mit einem Wert gefüllt sein.",
    "invalid_urls": "Ungültige URLs",
    "project_does_not_exist_in_data_base_with": "Projekt existiert nicht in der Datenbank mit"
  },
  "wordpressInformerInformers": {
    "import_from_url": "Von URL importieren",
    "add": "Hinzufügen",
    "remove": "Entfernen",
    "couldn_t_get_retrieve_the_wordpress": "Die WordPress-Website konnte nicht abgerufen werden. Sie können es mit dem Single Site Informer versuchen oder eine andere URL angeben.",
    "go_to_website_informer": "Zum Website-Informer gehen",
    "select_the_types_of_content": "Wählen Sie die Arten von Inhalten aus, die Sie von der Website erhalten möchten."
  }
}