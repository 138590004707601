{
  "airtableInformerInformers": {
    "you_can_provide_your_own_airtable": "Ви можете надати свій власний Airtable",
    "personal_access_token": "Персональний токен доступу",
    "in_your_services_credentials_settings": "у налаштуваннях облікових даних служби.",
    "click_here": "Натисніть тут!",
    "if_you_have_not_generated_yet_a_token": "Якщо ви ще не згенерували токен, перейдіть до вашої",
    "airtable_platform": "платформи Airtable",
    "and_generate_one": "і згенеруйте його",
    "please_make_sure_to_select": "Будь ласка, переконайтеся, що ви вибрали",
    "and": "і",
    "scopes_and_related_bases": "області та пов'язані бази",
    "your_stored_integration_token": "Ваш збережений інтеграційний токен",
    "is_the_token_incorrect": "Чи неправильний токен? Ви можете змінити його в налаштуваннях облікових даних служби.",
    "services_credentials_settings": "налаштування облікових даних служби",
    "fetch_airtable_bases": "Отримати бази Airtable",
    "select_base": "Виберіть базу",
    "select_table": "Виберіть таблицю"
  }
}