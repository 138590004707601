{
  "quizWidget": {
    "delete_this_question": "Apagar esta pergunta?",
    "are_you_sure_you_want_to_delete": "Tem certeza de que deseja apagar esta pergunta do quiz? Essa ação não pode ser desfeita.",
    "delete_this_quiz": "Apagar este quiz?",
    "are_you_sure_you_want_to_delete_the_quiz": "Tem certeza de que deseja apagar todo o quiz? Essa ação não pode ser desfeita.",
    "export_to_quizlet": "Exportar para o Quizlet",
    "use_pattern": "Usar padrão",
    "the_quiz_should_test_general_knowledge": "O quiz deve testar conhecimentos gerais, pensamento crítico e compreensão. A pergunta deve ter um espaço para preencher.",
    "can_be_more_than_one_correct": "Pode haver mais de uma opção correta",
    "each_question_should_focus": "4) Cada pergunta deve focar em um tema ou tópico específico 5) Cada pergunta deve ter UM MÍNIMO DE 4 PARES DE ITENS para correspondência 6) A estrutura do JSON deve ser uma matriz de objetos, onde cada objeto representa uma pergunta com um campo 'question' e um campo 'correct'. O campo 'correct' deve ser um objeto em si, contendo pares de chave-valor que precisam ser correspondidos. 7) O conteúdo deve ser educacional e baseado em informações factuais ou conceitos bem estabelecidos. 8) Use o seguinte padrão JSON para cada pergunta:",
    "the_quiz_should_cover": "O quiz deve abranger diversos tópicos ou diferentes aspectos do mesmo tema.",
    "question_task_arrange_items": "Tarefa da pergunta: organizar itens ou eventos na ordem correta. Mínimo de 4 opções colocadas na matriz 'correct' na sequência correta",
    "given_the_context": "Dado o {contexto}, por favor, gere um quiz EM",
    "language_in_a_valid_json_format": "idioma em um formato JSON VÁLIDO. O quiz deve ter as seguintes características: 1) Tipo de Pergunta:",
    "number_of_questions": "Número de Perguntas",
    "difficulty_level": "Nível de Dificuldade",
    "this_time_only": "Desta vez apenas {newQuestionsLength} perguntas. Talvez não haja conteúdo suficiente.",
    "something_didn_t_go_as_planned": "Algo não saiu como planejado.",
    "failed_to_get_the_pdf_file": "Falha ao obter o arquivo PDF."
  },
  "quizWidgetAddCustomQuestionModal": {
    "create_custom_question": "Criar pergunta personalizada",
    "question_type": "Tipo de pergunta",
    "difficulty": "Dificuldade"
  },
  "quizWidgetDeleteModal": {
    "delete_this_question": "Apagar esta pergunta?",
    "are_you_sure_you_want_to_delete": "Tem certeza de que deseja apagar esta pergunta do quiz? Essa ação não pode ser desfeita."
  },
  "quizWidgetExportModal": {
    "export_configuration": "Configuração de exportação",
    "decide_what_should_be_included": "Decida o que deve ser incluído na exportação do seu quiz",
    "show_numeration": "Mostrar numeração",
    "show_answers": "Mostrar respostas",
    "show_explanation": "Mostrar explicação",
    "show_instructions": "Mostrar instruções",
    "show_difficulty": "Mostrar dificuldade",
    "show_images": "Mostrar imagens"
  },
  "quizWidgetForm": {
    "question_type": "Tipo de pergunta",
    "language": "Idioma",
    "difficulty": "Dificuldade",
    "max_questions": "Máximo de Perguntas",
    "add_more": "Adicionar mais",
    "generate": "Gerar",
    "original": "Original"
  },
  "quizWidgetListFooter": {
    "hide": "Esconder",
    "show": "Mostrar",
    "answers": "respostas",
    "create_custom_question": "Criar pergunta personalizada",
    "clear_quiz": "Limpar quiz",
    "export": "Exportar"
  },
  "quizWidgetListHeader": {
    "quiz": "Quiz",
    "questions": "perguntas"
  },
  "quizWidgetQuestionCardFooter": {
    "change_difficulty": "Clique aqui para alterar a dificuldade"
  }
}