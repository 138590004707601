{
  "likeDislikeAnswer": {
    "please_provide_additional_feedback": "Please provide additional feedback",
    "enter_your_username": "Enter your name",
    "enter_your_email": "Enter your email",
    "what_was_the_issue_with_the_response": "What was the issue with the response?",
    "email_and_username_are_required": "Email and name are required to send feedback",
    "thank_you_sincerely_for_your_feedback": "Thank you sincerely for your feedback. It's greatly valued.",
    "sorry_but_we_encountered": "Sorry, but we encountered an issue while attempting to send your feedback. Please try again.",
    "grateful_for_the_thumbs_up": "Grateful for the thumbs up!"
  },
  "likeDislikeAnswerModal": {
    "submit": "Submit"
  },
  "likeDislikeAnswerModalHeader": {
    "didnt_like_the_response": "Didn’t like the response"
  }
}
