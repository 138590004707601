{
  "aiModel": {
    "please_choose_the_model_that_you_want_to_use": "Per favore, scegli il modello che desideri utilizzare. Il modello GPT-4 è più robusto e fornisce risposte più precise ma è più lento e costoso, mentre GPT 3.5 è più veloce e adatto alla maggior parte dei casi.",
    "gpt_model_which_is_used_to_answer_the_questions": "Modello GPT utilizzato per rispondere alle domande",
    "personality": "Personalità",
    "role": "Ruolo",
    "act_as": "Agisci come...",
    "tone": "Tono",
    "custom_prompt_is_deactivated": "Il prompt personalizzato è disattivato",
    "personality_settings_are_deactivated": "Le impostazioni della personalità sono disattivate",
    "speak_tone": "Parla... tono",
    "formatting": "Formattazione",
    "use": "Usa...",
    "formatting_works_better_with_the_chat_gpt_4_model": "La formattazione funziona meglio con il modello di chat GPT 4.",
    "the_answer_length_field_should_not_be_less_than": "Il campo della lunghezza della risposta non deve essere inferiore a 50 e non superiore a 2500",
    "using_variables_question_and_context_are_mandatory": "L'utilizzo delle variabili {question} e {context} è obbligatorio!"
  },
  "aiModelAdvanceSettings": {
    "advance_settings": "Impostazioni avanzate",
    "enter_a_number_between": "Inserisci un numero compreso tra {value_1} e {value_2}",
    "custom_prompt": "Prompt personalizzato",
    "custom_prompt_template": "Modello di prompt personalizzato",
    "amount_of_the_last_messages_to_be_saved_in_context": "Quantità degli ultimi messaggi da salvare nel contesto",
    "precontext": "Precontesto",
    "more_about_custom_prompt": "Maggiori informazioni sul prompt personalizzato",
    "save_last_messages_to_context": "Salva gli ultimi messaggi nel contesto: [inserisci un numero]",
    "precontext_property_controls": "Il precontesto controlla il numero di risposte e domande nella tua cronologia di conversazione con l'IA che verranno utilizzate nelle nuove risposte dell'IA"
  },
  "aiModelHeader": {
    "ai_model": "Modello IA",
    "advance_settings": "Impostazioni avanzate"
  },
  "aiModelHelpData": {
    "struggling_with_tuning_ai_model": "Hai difficoltà a ottimizzare il modello di IA?",
    "don_t_hesitate_to_book_a_15_minutes_free_workshop": "Non esitare a prenotare un workshop gratuito di 15 minuti con il nostro ingegnere di IA. Ti aiuterà a ottimizzare il tuo vero bot di IA.",
    "book_a_call": "Prenota una chiamata",
    "struggling_with_custom_settings": "Hai difficoltà con le impostazioni personalizzate?",
    "don_t_hesitate_to_chat_with_our_ai_engineer": "Non esitare a chattare con il nostro ingegnere di IA. Ti aiuterà a ottimizzare il tuo vero bot di IA.",
    "open_chat": "Apri chat"
  },
  "aiModelSettingsData": {
    "degree_of_originality": "Grado di originalità",
    "here_you_can_specify_the_creativity_level": "Qui puoi specificare il livello di creatività del modello. Più il valore si avvicina a zero, più il modello è creativo, ma potrebbe non attenersi direttamente al contesto fornito nei dati caricati. Più il valore si avvicina a uno, più il modello si attiene direttamente ai dati forniti.",
    "topic": "Argomento",
    "creative": "Creativo",
    "diversity_range": "Intervallo di diversità",
    "adjust_this_setting_to_control": "Regola questa impostazione per controllare quanto diverso sarà il testo del modello. Valori più bassi forniscono risposte più prevedibili, mentre valori più alti offrono maggiore varietà ma possono essere meno coerenti.",
    "minimum_textual_diversity": "Diversità testuale minima",
    "maximum_textual_diversity": "Diversità testuale massima",
    "balance": "Equilibrio",
    "semantic_balance_option": "Opzione di equilibrio semantico",
    "if_you_need_a_simple_search_in": "Se hai bisogno di una semplice ricerca nel modo classico che corrisponde solo alle parole nei dati forniti, devi spostare il cursore più vicino a zero. Al contrario, se desideri che la ricerca sia più simile all'elaborazione del linguaggio naturale, considerando forme di parole, sinonimi, contesto e concetti correlati, devi spostare il cursore più vicino a uno.",
    "if_you_want_a_simple_text_search": "Se desideri una semplice ricerca di testo, sposta il cursore su \"Ricerca di testo classica\". Per una ricerca più complessa con significati linguistici e forme di parole, scegli \"Ricerca per contesto\". Se non sei sicuro, imposta il cursore su \"Ottimale\".",
    "classic_text_search": "Ricerca di testo classica",
    "text_search": "Ricerca di testo",
    "semantic_vector_search": "Ricerca semantica vettoriale",
    "search_by_context": "Ricerca per contesto",
    "knowledge_sources": "Fonti di conoscenza",
    "here_you_can_define_the_maximum": "Qui puoi definire il numero massimo di documenti (o frammenti) che verranno utilizzati per generare la risposta.",
    "knowledge_diversity": "Diversità della conoscenza",
    "knowledge_diversity_settings_helps": "L'impostazione della diversità della conoscenza ti aiuta a controllare la varietà di informazioni che ottieni nei risultati della ricerca. <br/><br/><strong>Ecco come funziona:</strong><br/><br/><strong>Valore consigliato: 3:</strong><br/> <i>Scopo:</i> Fornisce una buona combinazione di informazioni diverse evitando contenuti ripetitivi.<br/> <i>Utilizzo:</i> Ottimo per ottenere punti di vista diversi e una vasta gamma di conoscenze su un argomento.<br/><br/><strong>Valore: 0:</strong><br/> <i>Scopo:</i> Include tutti i contenuti simili, anche se ripetono le informazioni.<br/><i>Utilizzo:</i> Utile quando si desidera raccogliere tutti i dati possibili, ad esempio per un progetto di ricerca dettagliato o uno studio completo.<br/><br/>Impostando il livello di diversità della conoscenza, puoi assicurarti che i risultati della ricerca siano adatti alle tue esigenze, che si tratti di informazioni varie o di tutti i dettagli possibili su un argomento."
  }
}