{
  "settingsAppearance": {
    "appearance": "Apariencia",
    "bot_name": "Nombre del bot",
    "brand_name": "Nombre de la marca",
    "enter_custom_bot_name": "Ingrese un nombre personalizado para el bot",
    "enter_custom_brand_name": "Ingrese un nombre personalizado para la marca",
    "change_the_widget_color_style": "Cambiar el estilo de color del widget",
    "change_the_header_font_size_px": "Cambiar el tamaño de fuente del encabezado (px)",
    "change_the_body_font_size_px": "Tamaño de fuente",
    "hide_powered_by_label": "Ocultar etiqueta 'Desarrollado por'",
    "show_answer_sources_option": "Mostrar opción de fuentes de respuestas",
    "hide_the_full_site_overlay": "La superposición del sitio completo es una función que le permite crear un punto de enfoque en su sitio web al oscurecer el fondo y mostrar el widget.",
    "hide_overlay": "Ocultar superposición",
    "change_placeholder_text_of_the_form_s_input": "Cambiar el texto de marcador de posición de la entrada del formulario",
    "enter_custom_placeholder_text": "Ingrese un texto de marcador de posición personalizado...",
    "submit_button_s_text": "Texto del botón de envío",
    "enter_text_for_submit_button": "Ingrese el texto para el botón de envío...",
    "failed_to_upload_branding_logo": "Error al cargar el logotipo de la marca",
    "change_font_family": "Cambiar la familia de fuentes",
    "show_on_mobile": "Mostrar en dispositivos móviles",
    "show_on_tablet": "Mostrar en tabletas"
  }
}