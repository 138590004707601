{
  "youtubeInformer": {
    "please_provide_a_valid_vimeo_video_url": "Por favor, forneça um URL válido de vídeo do Vimeo",
    "tracks_are_unavailable_due_to_restrictions": "As faixas deste vídeo não estão disponíveis devido a restrições",
    "something_went_awry": "Algo deu errado",
    "no_text_tracks": "Este vídeo não possui faixas de texto!",
    "start_time_cant_be_greater": "O horário de início não pode ser maior ou igual ao horário de término"
  },
  "youtubeInformerForm": {
    "insert_youtube_video_url": "Insira o URL do vídeo do Youtube",
    "fetch_subtitles": "Buscar legendas"
  },
  "youtubeInformerList": {
    "available_tracks": "Faixas disponíveis"
  },
  "youtubeInformerPreview": {
    "please_adjust_the_sliders_below": "Por favor, ajuste os controles deslizantes abaixo para selecionar um intervalo para cortar as legendas do seu vídeo."
  }
}