{
  "sidebar": {
    "it_looks_like_you_are": "Parece que has alcanzado el número máximo de proyectos. Para comenzar un nuevo proyecto, considera archivar o eliminar los existentes que ya no necesitas, o explora nuestros planes de suscripción para obtener límites más altos.",
    "new_project": "Nuevo proyecto",
    "select_widget": "Seleccionar widget"
  },
  "sidebarHeader": {
    "new_project": "Nuevo proyecto"
  },
  "sidebarLink": {
    "project_settings": "Configuración del proyecto",
    "project_has_been_successfully": "¡El proyecto se ha {isFav} a favoritos correctamente!",
    "we_encountered_an_issue": "Encontramos un problema al intentar actualizar tus favoritos. Por favor, inténtalo de nuevo."
  },
  "sidebarNoProjects": {
    "there_are_no_projects": "No hay proyectos aquí por el momento"
  },
  "sidebarProjectsListFooter": {
    "create_project": "Crear proyecto",
    "create_a_project_using": "crear un proyecto usando",
    "bot_advice": "consejo de bot",
    "enter_redemption_code": "Canje",
    "applying": "Aplicando...",
    "apply": "Aplicar",
    "oops_you_ve_reached_your_project_limit": "¡Ups! Has alcanzado el límite de proyectos para crear nuevos proyectos. ¡Para crear más, considera actualizar tu paquete!"
  },
  "sidebarProjectTree": {
    "knowledge": "Conocimiento",
    "add_a_new_view": "Agregar una nueva vista",
    "dialogs": "Diálogos",
    "conversations": "Conversaciones",
    "team": "Equipo",
    "project": "Proyecto"
  },
  "sidebarPromocode": {
    "enter_redemption_code": "Canje",
    "exists_promo": "Este código promocional ya existe",
    "apply": "Aplicar"
  },
  "sidebarProjectTreeSettingsItem": {
    "settings": "Configuración",
    "ai_model": "Modelo de IA",
    "behavior": "Comportamiento",
    "appearance": "Apariencia",
    "integrations": "Integraciones",
    "database": "Base de datos"
  },
  "sidebarSharedProjectsListItem": {
    "something_went_awry": "¡Algo salió mal!"
  },
  "sidebarEmailConfirmWarning": {
    "resend": "Reenviar",
    "sending": "Enviando",
    "please_check_your_inbox": "Por favor, revisa tu bandeja de entrada",
    "to_verify_your_email": "para verificar tu correo electrónico.",
    "verification_email_is_sent": "Se ha enviado un correo electrónico de verificación.",
    "something_went_wrong": "Algo salió mal"
  }
}