{
  "userInformation": {
    "general_information": "Modifica informazioni",
    "full_name": "Nome completo",
    "enter_full_name": "Inserisci il nome completo",
    "email": "Email",
    "enter_email": "Inserisci l'email",
    "company": "Azienda",
    "enter_company": "Inserisci l'azienda",
    "phone": "Telefono",
    "enter_phone_number": "Inserisci il numero di telefono",
    "interface_language": "Lingua dell'interfaccia",
    "select_interface_language": "Seleziona la lingua dell'interfaccia",
    "timezone": "Fuso orario",
    "select_your_timezone": "Seleziona il tuo fuso orario",
    "cancel": "Annulla",
    "avatar": "Avatar",
    "upload_photo": "Carica foto",
    "save": "Salva",
    "this_field_contains_special_characters": "Questo campo contiene caratteri speciali",
    "your_personal_data_was_updated": "I tuoi dati personali sono stati aggiornati!",
    "entered_phone_number_is_already_in_use": "Il numero di telefono inserito è già in uso",
    "invalid_form": "Alcuni campi mancano o sono incorretti. Controlla i campi evidenziati e riprova.",
    "confirmation_email_sent": "È stata inviata una lettera di conferma per la modifica dell'email al tuo nuovo indirizzo email"
  }
}