{
  "CustomPromptOptionsEnumTranslations": {
    "adviser": "Asesor de IA",
    "sales_assistant": "Asistente de ventas",
    "shakespeare": "William Shakespeare",
    "professor": "Profesor",
    "formal": "formal",
    "informal": "informal",
    "persuasive": "persuasivo",
    "collaborative": "colaborativo",
    "creative": "creativo",
    "emojis": "emojis",
    "mark_keywords": "marcar palabras clave principales",
    "subheaders": "subencabezados",
    "billet_points_format": "formato de viñetas"
  }
}