{
  "baseDropboxFile": {
    "schema": {
      "folder_name": "Dropbox Folder name",
      "folder_description": "Current folder name",
      "url_title": "Dropbox File Url",
      "url_description": "The url to the Dropbox file"
    },
    "schemaUi": {
      "label": "Dropbox file",
      "description": "Here you can paste the URL to your file on Dropbox. Please, make sure that the file is publicly available, otherwise you will get an error on the processing stage",
      "placeholder": "Enter url"
    }
  },
  "baseDropboxFolder": {
    "schema": {
      "url_title": "Dropbox Folder Url",
      "url_description": "The url to the Dropbox folder"
    },
    "schemaUi": {
      "label": "Dropbox Folder",
      "description": "Here you can paste the link to your Dropbox folder. Please, make sure that the Folder as well as all the files inside are publicly available, otherwise you will get an error on the processing stage",
      "placeholder": "Enter url"
    }
  },
  "baseGoogleDriveFile": {
    "schema": {
      "folder_name": "Gdrive Folder Name",
      "folder_description": "The name for Gdrive folder",
      "url_title": "Gdrive File Url",
      "url_description": "The url to the Gdrive file"
    },
    "schemaUi": {
      "label": "Gdrive file",
      "description": "Here you can paste the link to your Google Drive file. Please, make sure that the file is publicly available, otherwise you will get an error on the processing stage",
      "placeholder": "Enter url"
    }
  },
  "baseGoogleDriveFolder": {
    "schema": {
      "url_title": "Gdrive Folder Url",
      "url_description": "The url to the Gdrive folder"
    },
    "schemaUi": {
      "label": "Gdrive Folder",
      "description": "Here you can add the link to your Google Drive folder. Please, make sure that the folder is publicly available as well as all the files inside the folder, otherwise you will get an error on the processing stage",
      "placeholder": "Enter url"
    }
  },
  "baseOneDriveFile": {
    "schema": {
      "parent_title": "Gdrive Folder Name",
      "url_title": "OneDrive File Url",
      "url_description": "The url to the OneDrive file"
    },
    "schemaUi": {
      "label": "OneDrive file",
      "description": "Here you can paste the link to your One Drive file. Please, make sure that the file is publicly available, otherwise you will get an error on the processing stage",
      "placeholder": "Enter url"
    }
  },
  "baseOneDriveFolder": {
    "schema": {
      "url_title": "OneDrive Folder Url",
      "url_description": "The url to the OneDrive folder"
    },
    "schemaUi": {
      "label": "OneDrive Folder",
      "description": "Here you can add the link to your OneDrive Drive folder. Please, make sure that the folder is publicly available as well as all the files inside the folder, otherwise you will get an error on the processing stage",
      "placeholder": "Enter url"
    }
  },
  "baseXmlSitemap": {
    "schema": {
      "url_title": "Xml Url",
      "url_description": "Type in the URL to your sitemap"
    },
    "schemaUi": {
      "label": "Xml sitemap",
      "description": "If you have your sitemap file with all the links that you want to index you are free to define the URL to the sitemap file",
      "placeholder": "https://example.com/sitemap.xml"
    }
  },
  "baseYoutubeChannel": {
    "schema": {
      "url_title": "Channel Url",
      "url_description": "Please press share on the YouTube channel and copy the link into the input box above",
      "language_title": "The preferable language"
    },
    "schemaUi": {
      "label": "Youtube channel",
      "description": "Please paste the link to the youtube channel which has the subtitles with the specified language below. Note: the informer processes only videos with subtitles otherwise you will get an error.",
      "placeholder": "Enter url",
      "placeholder_2": "en"
    }
  },
  "baseYoutubePlaylist": {
    "schema": {
      "url_title": "Playlist Url",
      "url_description": "Please press share on the YouTube playlist and copy the link into the input box above",
      "language_title": "The preferable language"
    },
    "schemaUi": {
      "label": "Youtube playlist",
      "description": "Please paste the link to the youtube playlist which has the subtitles with the specified language below. Note: the informer processes only videos with subtitles otherwise you will get an error.",
      "placeholder": "Enter url",
      "placeholder_2": "en"
    }
  },
  "baseZendesk": {
    "schema": {
      "url_title": "Zendesk Url",
      "url_description": "Please enter the zendesk help center link"
    },
    "schemaUi": {
      "label": "Zendesk",
      "description": "Please paste the link to the zendesk help center.",
      "placeholder": "Enter url"
    }
  },
  "baseText": {
    "schema": {
      "content_title": "Text",
      "content_description": "Please add the text that you want to add to your project",
      "metadata_title": "Additional metadata"
    },
    "schemaUi": {
      "label": "Text",
      "description": "Add the text to your project",
      "placeholder": "Enter the text"
    }
  },
  "baseUrl": {
    "schema": {
      "url_title": "File url",
      "url_description": "The file url that will add to the project"
    },
    "schemaUi": {
      "label": "Url file",
      "description": "Please, make sure that the file is public on the Internet otherwise you will get an Authorization error",
      "placeholder": "example.com/file.pdf"
    }
  }
}
