{
  "quizWidget": {
    "delete_this_question": "Видалити це питання?",
    "are_you_sure_you_want_to_delete": "Ви впевнені, що хочете видалити це питання з квізу? Цю дію неможливо скасувати.",
    "delete_this_quiz": "Видалити цей квіз?",
    "are_you_sure_you_want_to_delete_the_quiz": "Ви впевнені, що хочете видалити весь квіз? Цю дію неможливо скасувати.",
    "export_to_quizlet": "Експортувати в Quizlet",
    "use_pattern": "Використовуйте шаблон",
    "the_quiz_should_test_general_knowledge": "Квіз повинен тестувати загальні знання, критичне мислення та розуміння. Питання повинні мати пропуск для заповнення.",
    "can_be_more_than_one_correct": "Може бути більше одного правильного варіанту",
    "each_question_should_focus": "4) Кожне питання повинно фокусуватися на конкретній темі або темі 5) Кожне питання повинно мати МІНІМУМ 4 ПАРИ ЕЛЕМЕНТІВ для зіставлення 6) Структура JSON повинна бути масивом об'єктів, де кожен об'єкт представляє питання з полем 'question' та полем 'correct'. Поле 'correct' повинно бути самим об'єктом, що містить пари ключ-значення, які потрібно зіставити. 7) Вміст повинен бути освітнім і базуватися на фактичній інформації або добре встановлених концепціях. 8) Використовуйте наступний шаблон JSON для кожного питання:",
    "the_quiz_should_cover": "Квіз повинен охоплювати різноманітні теми або різні аспекти однієї теми.",
    "question_task_arrange_items": "Завдання питання: впорядкувати елементи або події в правильному порядку. Мінімум 4 варіанти, розміщені в масиві \"correct\" в правильній послідовності",
    "given_the_context": "З урахуванням { context }, будь ласка, створіть квіз IN",
    "language_in_a_valid_json_format": "мова у валідному форматі JSON. Квіз повинен мати наступні характеристики: 1) Тип питання:",
    "number_of_questions": "Кількість питань",
    "difficulty_level": "Рівень складності",
    "this_time_only": "Цього разу лише {newQuestionsLength} питань. Можливо, недостатньо контенту.",
    "something_didn_t_go_as_planned": "Щось пішло не за планом.",
    "failed_to_get_the_pdf_file": "Не вдалося отримати файл PDF."
  },
  "quizWidgetAddCustomQuestionModal": {
    "create_custom_question": "Створити власне питання",
    "question_type": "Тип питання",
    "difficulty": "Складність"
  },
  "quizWidgetDeleteModal": {
    "delete_this_question": "Видалити це питання?",
    "are_you_sure_you_want_to_delete": "Ви впевнені, що хочете видалити це питання з квізу? Цю дію неможливо скасувати."
  },
  "quizWidgetExportModal": {
    "export_configuration": "Конфігурація експорту",
    "decide_what_should_be_included": "Вирішіть, що повинно бути включено до експорту вашого квізу",
    "show_numeration": "Показати нумерацію",
    "show_answers": "Показати відповіді",
    "show_explanation": "Показати пояснення",
    "show_instructions": "Показати інструкції",
    "show_difficulty": "Показати складність",
    "show_images": "Показати зображення"
  },
  "quizWidgetForm": {
    "question_type": "Тип питання",
    "language": "Мова",
    "difficulty": "Складність",
    "max_questions": "Максимальна кількість питань",
    "add_more": "Додати ще",
    "generate": "Створити",
    "original": "Оригінал"
  },
  "quizWidgetListFooter": {
    "hide": "Приховати",
    "show": "Показати",
    "answers": "відповіді",
    "create_custom_question": "Створити власне питання",
    "clear_quiz": "Очистити квіз",
    "export": "Експорт"
  },
  "quizWidgetListHeader": {
    "quiz": "Квіз",
    "questions": "питання"
  },
  "quizWidgetQuestionCardFooter": {
    "change_difficulty": "Натисніть тут, щоб змінити складність"
  }
}