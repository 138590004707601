{
  "retellingWidget": {
    "retelling": "Retelling",
    "generate_retelling": "Generate retelling",
    "context": "CONTEXT",
    "retell_in_language": "Retell in {formLanguage} language to {formSize} {formMethod} of the original context",
    "keeping_the_tone": "keeping the {formTone} tone",
    "use_markdown_format": "Use markdown format",
    "add_the_primary_question": "Add the primary question with an answer that answers the context using italic formatting.",
    "add_main_emojis": "Add main emojis here.",
    "add_bold_main_keywords": "Add bold main keywords here.",
    "add_a_header": "Add a header as h2 markdown.",
    "add_1_sentence_quote": "Add 1 sentence quote of the most important phrase of context using blockqoute markdown.",
    "if_you_have_what_to_add": "If you have what to add as bullet points than add it in the end as markdown list.",
    "an_error_occurred_while_fetching": "An error occurred while fetching the retelling.",
    "something_didn_t_go_as_planned": "Something didn't go as planned.",
    "error_occured_while_creating_pdf": "Error occured while creating PDF"
  },
  "retellingWidgetAnswer": {
    "retold": "Retold",
    "from_original": "from original ~{pages} pages of text to",
    "download_pdf": "Download PDF",
    "clear_retelling": "Clear retelling",
    "retelling": "Retelling"
  },
  "retellingWidgetAnswerChunk": {
    "click_to_edit_this_block": "Click to edit this block",
    "click_to_adjust_this_block": "Click to adjust this block",
    "click_to_delete_this_block": "Click to delete this block",
    "something_went_awry": "Something went awry!",
    "you_ve_successfully_deleted": "You've successfully deleted part of the retelling!"
  },
  "retellingWidgetAnswerHeader": {
    "click_to_edit": "Click to edit retold header"
  },
  "retellingWidgetForm": {
    "you_ve_enabled_custom_prompt_in_settings": "You've enabled custom prompt in settings!",
    "block_size": "Block Size",
    "size_from_original": "Size from original ~{totalPages} pages of text",
    "tone": "Tone",
    "speak_tone": "Speak... tone",
    "language": "Language",
    "options": "Options",
    "add_subheaders": "add subheaders",
    "add_important_quotes": "add important quotes",
    "use_bullet_points": "use bullet points format",
    "add_main_question": "add main question",
    "add_emojis": "add emojis",
    "bold_main_keywords": "bold main keywords",
    "write_it_for_a": "Write it for a 9 y.o. kid like a journey story",
    "create_preview": "Create preview",
    "original": "original"
  },
  "retellingWidgetModal": {
    "create_retelling_pdf": "Create retelling PDF",
    "ok": "Ok",
    "wait_for_a_few_seconds_please": "Wait for a few seconds please..."
  },
  "retellingWidgetPreview": {
    "preview": "Preview",
    "retelling_a_piece_of_content": "retelling a piece of content",
    "refresh_preview": "Refresh Preview"
  }
}