{
  "casesDatabase": {
    "popular": "Популярне",
    "HR": "HR",
    "support": "Підтримка",
    "eCommerce": "Електронна комерція",
    "business": "Бізнес",
    "community": "Спільнота",
    "developers": "Розробники",
    "professionals": "Професіонали",
    "teachers": "Вчителі",
    "sales_and_marketing": "Продажі та маркетинг",
    "website_widget_title": "Веб-сайт та чатбот",
    "website_widget_description": "Чат-інструмент для даних веб-сайту.",
    "knowledge_bot_title": "Бот знань",
    "knowledge_bot_description": "Швидкий пошук інформації в завантажених файлах.",
    "slack_HR_support_bot_title": "Бот підтримки HR в Slack",
    "slack_HR_support_bot_description": "Бот HR в Slack команди",
    "GDrive_file_HR_chatbot_for_Slack_title": "GDrive File HR Chatbot для Slack",
    "GDrive_file_HR_chatbot_for_Slack_description": "Завантажте файли Google Drive в Slack.",
    "GDrive_folder_HR_chatbot_for_slack_title": "GDrive Folder HR Chatbot для Slack",
    "GDrive_folder_HR_chatbot_for_slack_description": "Завантажте папки Google Drive в Slack.",
    "OneDrive_file_HR_chatbot_for_Slack_title": "OneDrive File HR Chatbot для Slack",
    "OneDrive_file_HR_chatbot_for_Slack_description": "Завантажте файли OneDrive в Slack.",
    "OneDrive_folder_HR_chatbot_for_slack_title": "OneDrive Folder HR Chatbot для Slack",
    "OneDrive_folder_HR_chatbot_for_slack_description": "Завантажте папки OneDrive в Slack.",
    "knowledge_base_chat_popup_for_websites_title": "Вікно чату бази знань для веб-сайтів",
    "knowledge_base_chat_popup_for_websites_description": "Дані веб-сайту як допоміжний чат.",
    "interactive_website_chat_for_customer_support_title": "Інтерактивний чат веб-сайту для підтримки клієнтів",
    "interactive_website_chat_for_customer_support_description": "Допоміжний чат з даних веб-сайту.",
    "slack_integration_for_internal_knowledge_management_title": "Інтеграція Slack для внутрішнього управління знаннями",
    "slack_integration_for_internal_knowledge_management_description": "Бот Slack для знань про файли.",
    "AI_product_search_advisor_with_CSV_sync_title": "AI-пошуковий радник продукту з синхронізацією CSV",
    "AI_product_search_advisor_with_CSV_sync_description": "Синхронізуйте списки продуктів з CSV.",
    "whatsApp_AI_travel_advisor_for_villas_title": "WhatsApp AI Travel Advisor для вілл",
    "whatsApp_AI_travel_advisor_for_villas_description": "Знайдіть та забронюйте вілли через WhatsApp.",
    "zendesk_inquiry_slackbot_title": "Zendesk Inquiry Slackbot",
    "zendesk_inquiry_slackbot_description": "Перегляньте квитки Zendesk в Slack.",
    "google_sheets_query_slackbot_title": "Google Sheets Query Slackbot",
    "google_sheets_query_slackbot_description": "Запитуйте дані Google Sheets в Slack.",
    "community_content_explorer_widget_title": "Пошук вмісту спільноти",
    "community_content_explorer_widget_description": "Пошук вмісту спільноти.",
    "vimeo_content_explorer_for_communities_title": "Vimeo Content Explorer для спільнот",
    "vimeo_content_explorer_for_communities_description": "Легко досліджуйте відео Vimeo.",
    "web_content_explorer_widget_title": "Пошук веб-контенту",
    "web_content_explorer_widget_description": "Легко знаходьте веб-контент.",
    "PDF_content_discovery_widget_title": "Пошук вмісту PDF",
    "PDF_content_discovery_widget_description": "Легко знаходьте документи PDF.",
    "celebrity_chef_AI_chatbot_title": "Чат-бот Celebrity Chef AI",
    "celebrity_chef_AI_chatbot_description": "Отримуйте рецепти від шеф-бота.",
    "project_artifact_summarizer_title": "Сумаризатор артефактів проекту",
    "project_artifact_summarizer_description": "Швидко стисло описуйте проектні файли.",
    "AI_powered_data_extractor_for_bubble_apps_title": "AI-приводний екстрактор даних для додатків Bubble",
    "AI_powered_data_extractor_for_bubble_apps_description": "Використовуйте AI для збору даних.",
    "complex_document_simplifier_title": "Складний спрощувач документів",
    "complex_document_simplifier_description": "Робіть документи легкими для читання.",
    "professional_summary_creator_title": "Створювач професійного резюме",
    "professional_summary_creator_description": "Отримуйте прості резюме з файлів.",
    "lesson_plan_quiz_generator_title": "Генератор тестів для плану уроку",
    "lesson_plan_quiz_generator_description": "Створюйте тести з планів уроків.",
    "notion_flashcard_creator_title": "Створювач карток Notion",
    "notion_flashcard_creator_description": "Змінюйте сторінки Notion на картки.",
    "client_engagement_chat_assistant_title": "Асистент чату залучення клієнтів",
    "client_engagement_chat_assistant_description": "Створюйте плани взаємодії з клієнтами.",
    "lead_data_extraction_tool_title": "Інструмент для видобування даних з потенційних клієнтів",
    "lead_data_extraction_tool_description": "Збирайте дані з веб-сайтів потенційних клієнтів.",
    "personalized_follow_up_generator_title": "Генератор персоналізованого відстеження",
    "personalized_follow_up_generator_description": "Створюйте плани відстеження з даних.",
    "competitive_knowledge_mindmap_tool_title": "Інструмент ментальної картки конкурентних знань",
    "competitive_knowledge_mindmap_tool_description": "Перетворюйте сайти конкурентів на ментальну карту."
  },
  "informerHelpContent": {
    "PdfOcrInformer": "Завантажте файл Pdf з вашого комп'ютера.",
    "FileInformer": "Завантажте файл з вашого комп'ютера.",
    "WebInformer": "Перерахуйте посилання на веб-сайти, по одному на рядок.",
    "SingleWebInformer": "Надайте посилання на веб-сайт для парсингу.",
    "AirtableInformer": "Виберіть таблиці для включення з Airtable.",
    "AudioInformer": "Завантажте аудіофайл та виберіть мову.",
    "ChromeExtensionInformer": "Підключіться до нашого розширення для Chrome.",
    "ConfluenceInformer": "Отримайте доступ до статей Confluence з обліковими даними.",
    "DatabaseInformer": "Спілкуйтеся безпосередньо з базою даних.",
    "SlackIntegration": "Інтегруйте Omnimind у канал Slack.",
    "CSVInformer": "Завантажте CSV-файл з даними.",
    "dropbox-file": "Вставте посилання на файл Dropbox.",
    "dropbox-folder": "Вставте посилання на папку Dropbox.",
    "ExcelInformer": "Завантажте свій файл Excel з даними.",
    "gdrive-file": "Вставте посилання на файл Google Drive.",
    "gdrive-folder": "Вставте посилання на папку Google Drive.",
    "GoogleSheetsInformer": "Введіть посилання на файл Google Sheets.",
    "onedrive-file": "Вставте посилання на файл OneDrive.",
    "onedrive-folder": "Вставте посилання на папку OneDrive.",
    "text": "Введіть або вставте текст для додавання.",
    "url": "Вставте посилання на інтернет-файл.",
    "VimeoInformer": "Вставте посилання на відео Vimeo та збережіть токен.",
    "xml-sitemap": "Вставте посилання на файл XML-карти сайту.",
    "youtube-channel": "Вставте посилання на канал YouTube та виберіть мову.",
    "YoutubeInformer": "Вставте посилання на відео YouTube та виберіть мову.",
    "youtube-playlist": "Вставте посилання на плейлист YouTube та виберіть мову.",
    "BulkCSVInformer": "Завантажте CSV-файл з посиланнями.",
    "NotionInformer": "Надайте токен Notion та посилання на сторінку.",
    "WordpressInformer": "Вставте посилання на Wordpress та виберіть категорії.",
    "zendesk": "Вставте посилання на центр довідки Zendesk."
  }
}