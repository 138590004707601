<template>
  <div class="dark">
    <nav
      class="sidebar fixed bottom-0 top-0 z-50 h-full min-h-screen w-[260px] border-r border-[var(--omni-color-secondary)] shadow-lg shadow-[var(--omni-color-secondary)] transition-all duration-300"
    >
      <div class="flex h-full flex-col bg-[var(--omni-color-primary)]">
        <div
          class="flex w-full items-center justify-between border-b border-[var(--omni-color-secondary)] px-4 py-2.5"
        >
          <router-link
            to="/omni-portal/portal"
            class="main-logo flex grow items-center text-[var(--omni-color-text)]"
          >
            <PortalSidebarHeaderSkeleton
              v-if="portalStore.portalLoading || portalStore.billingUserPackagesLoading"
            />
            <template v-else>
              <div
                class="ml-[5px] h-[38px] w-8"
                :class="{ '!h-8': portalStore?.portal?.settings?.logo?.src }"
              >
                <BaseWidgetSvgIcon
                  v-if="!portalStore?.portal?.settings?.logo?.src"
                  name="logo-main"
                />
                <img
                  v-else
                  :src="portalStore?.portal?.settings?.logo?.src"
                  alt="portal-logo"
                  class="my-auto h-8 w-8 rounded-full object-cover"
                />
              </div>
              <span class="align-middle text-sm font-medium ltr:ml-3.5 rtl:mr-1.5 lg:inline">{{
                portalStore?.portal?.name
              }}</span>
            </template>
          </router-link>
          <a
            href="javascript:;"
            class="collapse-icon flex h-8 w-8 items-center rounded-full transition duration-200 hover:bg-[var(--omni-color-primary)] hover:text-[var(--omni-color-accent)] rtl:rotate-180"
            @click="store.toggleSidebar()"
          >
            <svg
              class="m-auto h-5 w-5"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 19L7 12L13 5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.9998 19L10.9998 12L16.9998 5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
        <PortalSidebarLinksList
          :is-links-loading="portalStore.portalLoading || portalStore.billingUserPackagesLoading"
          :links="portalStore.portalLinks"
        />
        <BillingOverview class="mt-auto" />
      </div>
    </nav>
  </div>
</template>
<script setup lang="ts">
import { usePortalsStore } from '@/stores/portalsStore';
import { useAppStore } from '@/stores';
import BaseWidgetSvgIcon from '@components/base/BaseWidgetSvgIcon.vue';
import PortalSidebarLinksList from '@components/layout/sidebar/portal/PortalSidebarLinksList.vue';
import PortalSidebarHeaderSkeleton from '@components/base/skeleton/layout/PortalSidebarHeaderSkeleton.vue';
import BillingOverview from '@components/profile/info/BillingOverview.vue';

const portalStore = usePortalsStore();
const store = useAppStore();
</script>
