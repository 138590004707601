{
  "settingsIntegrations": {
    "integrations": "Integrações",
    "please_note_that_any_activity_of_the_users": "Por favor, note que qualquer atividade dos usuários com quem você compartilha este link está vinculada ao saldo da sua conta.",
    "embed": "Incorporar",
    "messengers": "Mensageiros",
    "integration_confirmation": "Confirmação de integração",
    "please_note_that_any_activity": "Por favor, note que qualquer atividade dos usuários com quem você compartilha este link está vinculada ao saldo da sua conta.",
    "copy_link": "Copiar link",
    "share_on": "Compartilhar em",
    "widget_link_copied_to_clipboard": "Link do widget copiado para a área de transferência",
    "personal_shared": "pessoal / compartilhado",
    "share": "Compartilhar",
    "on": "Ativar",
    "off": "Desativar"
  }
}