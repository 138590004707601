{
  "googleSheetsAddOn": {
    "how_to_add_omnimind": "So fügen Sie das Omnimind AI Google Sheets Add-On hinzu",
    "please_ensure_that_the_project": "Stellen Sie sicher, dass das Projekt auf 'öffentlich' eingestellt ist. Klicken Sie dazu auf die Schaltfläche 'Veröffentlichen', um das Projekt für die Öffentlichkeit zugänglich zu machen.",
    "copy_your_api_key": "Kopieren Sie Ihren API-Schlüssel",
    "go_to_the_google_sheet_document": "Gehen Sie zum Google Sheet-Dokument, in dem Sie das Add-On verwenden möchten.",
    "press_the_extensions_menu_button": "Drücken Sie die Schaltfläche 'Erweiterungen' und wählen Sie 'Add-Ons' und 'Add-Ons abrufen'",
    "find_the_omnimind_add_on": "Suchen Sie das OmniMind-Add-On und installieren Sie es",
    "after_installing_the_add_on": "Nach der Installation des Add-Ons gehen Sie zum Menü 'Erweiterungen', suchen Sie den Menüpunkt 'Omnimind Add-On' und klicken Sie auf 'Starten'",
    "you_will_be_prompted_to": "Sie werden aufgefordert, sich bei Omnimind zu autorisieren. Bitte autorisieren Sie sich, Sie werden zurückerstellt zum Google-Dokument.",
    "launch_the_add_on_again": "Starten Sie das Add-On erneut. Es wird ein Seitenleistenfenster angezeigt, in dem Sie auf 'Projekte abrufen' klicken und angeben können, welche Projektdaten Sie in dem Dokument verwenden möchten.",
    "paste_your_api_key_in_the_provided_field": "Fügen Sie Ihren API-Schlüssel in das bereitgestellte Feld ein und klicken Sie auf 'Projekte abrufen'.",
    "select_your_project": "Wählen Sie Ihr Projekt aus",
    "a_list_of_your_projects_in_omnimind": "Eine Liste Ihrer Projekte in Omnimind wird angezeigt.",
    "select_the_project_you_would_like_to": "Wählen Sie das Projekt aus, das Sie für die Datenanalyse verwenden möchten.",
    "use_the_omni_ask_function": "Verwenden Sie die OMNI_ASK-Funktion",
    "click_on_a_cell_where_you": "Klicken Sie auf eine Zelle, in der die Antwort angezeigt werden soll.",
    "enter_the_function": "Geben Sie die Funktion ein",
    "your_question_here": "Ihre Frage hier",
    "replace": "Ersetzen",
    "with_the_actual_question_you": "durch die tatsächliche Frage, die Sie stellen möchten.",
    "press_enter": "Drücken Sie die Eingabetaste",
    "getting_answers": "Antworten erhalten",
    "after_pressing_enter": "Nachdem Sie die Eingabetaste gedrückt haben, sucht das Add-On automatisch nach der Antwort basierend auf den in Ihrem ausgewählten Projekt auf Omnimind hochgeladenen Ressourcen.",
    "the_answer_will_then_appear": "Die Antwort wird dann in der Zelle angezeigt, in der Sie die Funktion eingegeben haben.",
    "tips": "Tipps",
    "make_sure_your_question": "Stellen Sie sicher, dass Ihre Frage klar und präzise ist, um die genauesten Antworten zu erhalten. Sie können mehrere Fragen gleichzeitig stellen, indem Sie die OMNI_ASK-Funktion in verschiedenen Zellen verwenden. Sie können das Projekt in den Add-On-Einstellungen aktualisieren oder ändern, wenn Sie unterschiedliche Daten für die Analyse verwenden möchten.",
    "support": "Support",
    "if_you_encounter_any": "Wenn Sie Probleme haben oder weitere Fragen haben, besuchen Sie bitte unsere Support-Seite unter"
  }
}