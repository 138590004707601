<template>
  <div class="flex h-full items-center justify-center">
    <div class="h-[36px] w-[36px]">
      <Loader />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, watch } from 'vue';
import { useMeta } from '@/composables/use-meta';
import { useAppStore } from '@/stores';
import { useUserData } from '@nhost/vue';
import { useRouter } from 'vue-router';
// import { getInvited } from '@components/share-project/utils/getInvited';
import { useResourcesStore } from '@/stores/resourcesStore';
import Loader from '@components/Loader.vue';

// Enum
import { WidgetView } from '@components/basic-mode/interfaces/WidgetTypeInterface';
import { WidgetTypeKeysEnum } from '@/types/WidgetTypeKeysEnum';
import { redirectToPackagesSelectionPage } from '@/router/utils/redirectToPackagesSelection';

const user = useUserData();
const router = useRouter();
const store = useAppStore();
const resourcesStore = useResourcesStore();
const dividedProjects = computed(() => store.dividedProjects(user?.value?.id!));

watch(
  dividedProjects,
  ({ myProjects }) => {
    nextTick(async () => {
      if (!store.isPaid) return;
      const project = myProjects.at(-1);
      const widgetId = project?.widgets[0]?.id;

      if (project) {
        project.id && (await resourcesStore.fetchResources(project.id));

        const isResources = Boolean(resourcesStore.resourcesByProjectId(project?.id!)?.length);
        const storedWidget =
          localStorage.getItem(`project_${project.id}_${user.value?.id}`)?.split('_')[1] || null;
        let routePath;

        if (
          isResources ||
          project?.widgets[0]?.widget_type.key === WidgetTypeKeysEnum.TableWidget
        ) {
          routePath = widgetId ? widgetId : `view?widgetType=${WidgetView.VISUAL}`;
        } else if (!isResources && !widgetId) {
          await router.push(`/projects/${project.id}/empty`);
          return;
        } else if (storedWidget) {
          routePath = storedWidget;
        } else {
          routePath = `view?widgetType=${WidgetView.INFORMER}`;
        }
        await router.push(`/projects/${project.id}/widget/${routePath}`);
      }
      // The sharedProjects feature is not available at the moment.

      // else if (sharedProjects.length) {
      //   const invitedProjects = getInvited(sharedProjects, true);
      //   const project = Array.isArray(invitedProjects) ? invitedProjects.at(-1) : undefined;
      //
      //   project
      //     ? await router.push(`projects/${project.id}/widget/${project.widgets[0].id}`)
      //     : await router.push('/advise');
      // }
      else {
        await router.push('/advise');
      }
    });
  },
  { immediate: true, deep: true },
);

onMounted(() => {
  nextTick(() => {
    if (!store.isPaid) {
      redirectToPackagesSelectionPage(router);
    }
  });
});

useMeta({ title: 'Dashboard' });
</script>
