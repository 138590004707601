{
  "exportQuestionsCodeModal": {
    "how_to_import_into_quizlet": "How to Import into Quizlet",
    "start_by_heading_to": "Start by heading to Quizlet and clicking the + button to create a new study set. Next, find and click the \"Import\" button. Now, simply copy the content provided above and paste it into Quizlet. Remember: use a",
    "tab": "Tab",
    "between_each_term_and_its_definition_and_a": "between each term and its definition, and a",
    "new_line": "New Line",
    "to_separate_different_cards": "to separate different cards. Happy studying!",
    "copy_content": "Copy content",
    "copied_content": "Copied content",
    "content_was_copied": "Content was copied!"
  },
  "filtersModal": {
    "filters": "Filters",
    "search_for_resources": "Search for resources...",
    "search_by_selected_resources": "Search by selected resources",
    "reset_all": "Reset All",
    "check_all": "Check All",
    "sorry_no_resources_found_for_your": "Sorry, no resources found for your search query",
    "please_try_modifying_your": "Please try modifying your search terms or filters to expand your search.",
    "cancel": "Cancel",
    "apply": "Apply"
  },
  "liveConnect": {
    "error_executing_user_code": "Error executing user code",
    "something_went_wrong": "Something went wrong! Please, make sure you installed Intercom correctly",
    "something_went_wrong_please_make_sure": "Something went wrong! Please, make sure you installed Hubspot widget correctly",
    "something_went_wrong_please_make_sure_dialogFlow": "Something went wrong! Please, make sure you installed DialogFlow correctly"
  },
  "offlineMessage": {
    "name": "Name",
    "email": "Email",
    "subject": "Subject",
    "message": "Message",
    "submit": "Submit",
    "error_executing_user_code": "Error executing user code",
    "thank_you_for_your_response": "Thank you for your response!",
    "something_went_awry": "Something went awry!"
  },
  "poweredByMessage": {
    "powered_by": "Powered by"
  },
  "questionnaires": {
    "examples_of_questions_that_you_can_ask": "Examples of questions that you can ask the bot about"
  },
  "widgetsFooter": {
    "back": "Back",
    "help": "Help"
  },
  "widgetsModalInfo": {
    "it_staff_augmentation_in_portugal": "IT Staff Augmentation in Portugal",
    "upload_time": "Upload time",
    "result_relevance": "Result relevance"
  },
  "widget_external_user_form": {
    "please_provide_information_to_chat_with_me": "Please provide information to chat with me",
    "your_email": "Your email",
    "email": "Email",
    "your_name": "Your name",
    "name": "Name",
    "continue": "Continue"
  }
}
