{
  "pagesProjectsWidgetIdSettings": {
    "back": "Atrás",
    "your_settings_successfully_saved": "Tus ajustes se guardaron correctamente",
    "failed_to_save_settings": "Error al guardar los ajustes",
    "failed_to_load_settings": "Error al cargar los ajustes"
  },
  "pagesProjectsWidgetIdTeam": {
    "team": "Equipo",
    "back": "Atrás"
  },
  "pagesProjectsWidgetIdBasic": {
    "this_widget_view_is_not_ready_yet": "Esta vista de widget aún no está lista.",
    "add_a_new_view": "Agregar una nueva vista",
    "you_dont_have_any_resources": "No tienes ningún recurso. Debes agregar un nuevo informador.",
    "change_widget_view": "Cambiar vista de widget"
  }
}