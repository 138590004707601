{
  "settingsAppearance": {
    "appearance": "Вигляд",
    "bot_name": "Ім'я бота",
    "brand_name": "Назва бренду",
    "enter_custom_bot_name": "Введіть власне ім'я бота",
    "enter_custom_brand_name": "Введіть власну назву бренду",
    "change_the_widget_color_style": "Змінити стиль кольору віджета",
    "change_the_header_font_size_px": "Змінити розмір шрифту заголовка (px)",
    "change_the_body_font_size_px": "Розмір шрифту",
    "hide_powered_by_label": "Приховати напис 'Powered by'",
    "show_answer_sources_option": "Показати опцію джерела відповіді",
    "hide_the_full_site_overlay": "Повний оверлей сайту - це функція, яка дозволяє створити фокусний пункт на вашому веб-сайті, затемнюючи фон та відображаючи віджет.",
    "hide_overlay": "Приховати оверлей",
    "change_placeholder_text_of_the_form_s_input": "Змінити текст заповнюваної форми",
    "enter_custom_placeholder_text": "Введіть власний текст заповнюваної форми...",
    "submit_button_s_text": "Текст кнопки відправки",
    "enter_text_for_submit_button": "Введіть текст для кнопки відправки...",
    "failed_to_upload_branding_logo": "Не вдалося завантажити логотип бренду",
    "change_font_family": "Змінити шрифтову сім'ю",
    "show_on_mobile": "Показувати на мобільних пристроях",
    "show_on_tablet": "Показувати на планшетах"
  }
}