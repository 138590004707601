{
  "casesDatabase": {
    "popular": "Popular",
    "HR": "HR",
    "support": "Support",
    "eCommerce": "eCommerce",
    "business": "Business",
    "community": "Community",
    "developers": "Developers",
    "professionals": "Professionals",
    "teachers": "Teachers",
    "sales_and_marketing": "Sales & Marketing",
    "website_widget_title": "Website & Chatbot",
    "website_widget_description": "Chat tool for website data.",
    "knowledge_bot_title": "Knowledge bot",
    "knowledge_bot_description": "Find info in uploaded files quickly.",
    "slack_HR_support_bot_title": "Slack HR support bot",
    "slack_HR_support_bot_description": "HR bot in the team’s Slack",
    "GDrive_file_HR_chatbot_for_Slack_title": "GDrive File HR Chatbot for Slack",
    "GDrive_file_HR_chatbot_for_Slack_description": "Upload Google Drive files to Slack.",
    "GDrive_folder_HR_chatbot_for_slack_title": "GDrive Folder HR Chatbot for Slack",
    "GDrive_folder_HR_chatbot_for_slack_description": "Upload Google Drive folders to Slack.",
    "OneDrive_file_HR_chatbot_for_Slack_title": "OneDrive File HR Chatbot for Slack",
    "OneDrive_file_HR_chatbot_for_Slack_description": "Upload OneDrive files to Slack.",
    "OneDrive_folder_HR_chatbot_for_slack_title": "OneDrive Folder HR Chatbot for Slack",
    "OneDrive_folder_HR_chatbot_for_slack_description": "Upload OneDrive folders to Slack.",
    "knowledge_base_chat_popup_for_websites_title": "Knowledge Base Chat Popup for Websites",
    "knowledge_base_chat_popup_for_websites_description": "Website data as help chat.",
    "interactive_website_chat_for_customer_support_title": "Interactive Website Chat for Customer Support",
    "interactive_website_chat_for_customer_support_description": "Help chat from website data.",
    "slack_integration_for_internal_knowledge_management_title": "Slack Integration for Internal Knowledge Management",
    "slack_integration_for_internal_knowledge_management_description": "Slack bot for file knowledge.",
    "AI_product_search_advisor_with_CSV_sync_title": "AI Product Search Advisor with CSV Sync",
    "AI_product_search_advisor_with_CSV_sync_description": "Sync product lists with CSV.",
    "whatsApp_AI_travel_advisor_for_villas_title": "WhatsApp AI Travel Advisor for Villas",
    "whatsApp_AI_travel_advisor_for_villas_description": "Find and book villas via WhatsApp.",
    "zendesk_inquiry_slackbot_title": "Zendesk Inquiry Slackbot",
    "zendesk_inquiry_slackbot_description": "See Zendesk tickets in Slack.",
    "google_sheets_query_slackbot_title": "Google Sheets Query Slackbot",
    "google_sheets_query_slackbot_description": "Ask about Google Sheets data in Slack.",
    "community_content_explorer_widget_title": "Community Content Explorer",
    "community_content_explorer_widget_description": "Search for community content.",
    "vimeo_content_explorer_for_communities_title": "Vimeo Content Explorer for Communities",
    "vimeo_content_explorer_for_communities_description": "Explore Vimeo videos easily.",
    "web_content_explorer_widget_title": "Web Content Explorer",
    "web_content_explorer_widget_description": "Find website content easily.",
    "PDF_content_discovery_widget_title": "PDF Content Discovery",
    "PDF_content_discovery_widget_description": "Find PDF documents easily.",
    "celebrity_chef_AI_chatbot_title": "Celebrity Chef AI Chatbot",
    "celebrity_chef_AI_chatbot_description": "Get recipes from a chef bot.",
    "project_artifact_summarizer_title": "Project Artifact Summarizer",
    "project_artifact_summarizer_description": "Summarize project files quickly.",
    "AI_powered_data_extractor_for_bubble_apps_title": "AI-Powered Data Extractor for Bubble Apps",
    "AI_powered_data_extractor_for_bubble_apps_description": "Use AI to collect data.",
    "complex_document_simplifier_title": "Complex Document Simplifier",
    "complex_document_simplifier_description": "Make documents easy to read.",
    "professional_summary_creator_title": "Professional Summary Creator",
    "professional_summary_creator_description": "Get simple summaries from files.",
    "lesson_plan_quiz_generator_title": "Lesson Plan Quiz Generator",
    "lesson_plan_quiz_generator_description": "Create quizzes from lesson plans.",
    "notion_flashcard_creator_title": "Notion Flashcard Creator",
    "notion_flashcard_creator_description": "Change Notion pages to flashcards.",
    "client_engagement_chat_assistant_title": "Client Engagement Chat Assistant",
    "client_engagement_chat_assistant_description": "Create follow-up plans for clients.",
    "lead_data_extraction_tool_title": "Lead Data Extraction Tool",
    "lead_data_extraction_tool_description": "Collect data from lead websites.",
    "personalized_follow_up_generator_title": "Personalized Follow-Up Generator",
    "personalized_follow_up_generator_description": "Make follow-up plans from data.",
    "competitive_knowledge_mindmap_tool_title": "Competitive Knowledge Mindmap Tool",
    "competitive_knowledge_mindmap_tool_description": "Turn competitor sites into a mindmap."
  },
  "informerHelpContent": {
    "PdfOcrInformer": "Upload a pdf file from your computer.",
    "FileInformer": "Upload a file from your computer.",
    "WebInformer": "List website links, one per line.",
    "SingleWebInformer": "Provide a website link to scrape.",
    "AirtableInformer": "Choose tables to include from Airtable.",
    "AudioInformer": "Upload an audio file and choose language.",
    "ChromeExtensionInformer": "Connect to our Chrome extension.",
    "ConfluenceInformer": "Access Confluence articles with credentials.",
    "DatabaseInformer": "Communicate directly with the database.",
    "SlackIntegration" : "Integrate Omnimind in to the slack channel",
    "CSVInformer": "Upload a CSV file with data.",
    "dropbox-file": "Paste the link to Dropbox file.",
    "dropbox-folder": "Paste the link to Dropbox folder.",
    "ExcelInformer": "Upload your Excel file with data.",
    "gdrive-file": "Paste the link to Google Drive file.",
    "gdrive-folder": "Paste the link to Google Drive folder.",
    "GoogleSheetsInformer": "Enter the link to Google Sheets file.",
    "onedrive-file": "Paste the link to OneDrive file.",
    "onedrive-folder": "Paste the link to OneDrive folder.",
    "text": "Type or paste the text to add.",
    "url": "Paste the link to an internet file.",
    "VimeoInformer": "Paste Vimeo video link and save token.",
    "xml-sitemap": "Paste the link to XML sitemap file.",
    "youtube-channel": "Paste YouTube channel link and choose language.",
    "YoutubeInformer": "Paste YouTube video link and choose language.",
    "youtube-playlist": "Paste YouTube playlist link and choose language.",
    "BulkCSVInformer": "Upload a CSV file with links.",
    "NotionInformer": "Provide Notion token and page link.",
    "WordpressInformer": "Paste Wordpress link and choose categories.",
    "zendesk": "Paste Zendesk help center link."
  }
}
