{
  "embedCode": {
    "you_can_copy_paste_this_snippet_into_your": "Sie können diesen Codeausschnitt kopieren und in Ihren Code einfügen, um das Widget auf Ihrer Seite zu verwenden, oder Sie können unser",
    "package": "Paket",
    "and_natively_integrate_the_widget_into_your_react_app": "und das Widget nativ in Ihre React-App integrieren.",
    "incorporate_this_html_code_into_your_document": "Fügen Sie diesen HTML-Code in Ihr Dokument ein",
    "subsequently_append_the_following_java_script": "Fügen Sie anschließend den folgenden JavaScript-Code nach Ihren Skripten hinzu",
    "send_the_code_and_integration_instructions_to_the_developer": "Senden Sie den Code und die Integrationsanweisungen an den Entwickler",
    "email": "E-Mail",
    "send": "Senden",
    "copy_code": "Code kopieren",
    "code_was_copied": "Code wurde kopiert!",
    "copied_code": "Kopierter Code",
    "something_went_awry": "Etwas ist schiefgelaufen",
    "your_code_successfully_sent": "Ihr Code wurde erfolgreich gesendet"
  },
  "settingsInformerModal": {
    "informer_settings": "Informer-Einstellungen",
    "anonymizer": "Anonymisierer",
    "splitter": "Splitter",
    "advanced": "Erweitert",
    "apply": "Anwenden",
    "apply_for_all": "Für alle anwenden",
    "your_settings_were_successfully_saved": "Ihre Einstellungen wurden erfolgreich gespeichert"
  },
  "settingsProject": {
    "rename_the_project": "Projekt umbenennen",
    "security": "Sicherheit",
    "operations": "Operationen",
    "back": "Zurück",
    "failed_to_load_settings": "Fehler beim Laden der Einstellungen",
    "your_settings_successfully_saved": "Ihre Einstellungen wurden erfolgreich gespeichert",
    "failed_to_save_settings": "Fehler beim Speichern der Einstellungen",
    "failed_to_delete_password": "Fehler beim Löschen des Passworts"
  }
}