{
  "tableDialogSQL": {
    "the_sql_query_for_your_prompt": "Die SQL-Abfrage für Ihre Eingabeaufforderung"
  },
  "tableWidget": {
    "ask_your_questions_to_get_a_data": "Stellen Sie Ihre Fragen, um Daten zu erhalten.",
    "show_sql": "SQL anzeigen",
    "export_to_csv": "Als CSV exportieren",
    "failed_to_load_settings": "Fehler beim Laden der Einstellungen",
    "something_went_wrong": "Etwas ist schiefgelaufen"
  },
  "tableWidgetTable": {
    "no_data": "Keine Daten"
  }
}