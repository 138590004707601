{
  "pagesProfileId": {
    "profile": "Perfil",
    "profile_settings": "Configuración del perfil",
    "settings": "Configuración",
    "home": "Inicio",
    "change_password": "Cambiar contraseña"
  },
  "pagesProfileIndex": {
    "my_profile": "Mi perfil",
    "notifications": "Notificaciones",
    "profile_summary": "Resumen del perfil",
    "services_credentials": "Credenciales de servicios",
    "api_keys": "Claves de API",
    "billings": "Facturación",
    "marketplace": "Tienda",
    "packages": "Paquetes",
    "integrations": "Integraciones",
    "payment_details": "Detalles de pago"
  }
}