{
  "projectSettingsSecurity": {
    "add": "Aggiungi",
    "add_new_domain": "Aggiungi nuovo dominio",
    "allowed_domains": "Domini consentiti",
    "the_value_is_not_a_valid_domain": "Il valore non è un dominio valido",
    "password_is_required": "La password è obbligatoria",
    "password_must_be_at_least": "La password deve contenere almeno 8 caratteri",
    "password_should_be_alphanumeric": "La password deve essere alfanumerica",
    "this_domain_was_added_previously": "Questo dominio è stato aggiunto in precedenza"
  },
  "projectSettingsSecurityPassword": {
    "password": "Password",
    "delete_project_password": "Elimina password del progetto",
    "delete_the_project_password": "Elimina la password del progetto",
    "set_up_password_for_the_project": "Imposta una password per il progetto",
    "anyone_will_be_able_to_use": "Chiunque potrà utilizzare il progetto senza password",
    "anyone_who_has_access_to_this_project": "Chiunque abbia accesso a questo progetto dovrà inserire una password"
  }
}