import { createApp } from 'vue';
import 'virtual:svg-icons-register';
import App from './App.vue';
import mitt from 'mitt';
import { TippyPlugin } from 'tippy.vue';
import InitializationProvider from '@/components/auth/InitializationProvider.vue';
import { initializeEventProcessor } from './analytics/eventProcessor';

import './style.css';

const app = createApp(App);

import { nhost } from '@/lib/nhost';

app.use(nhost);

import PrimeVue from 'primevue/config';

app.use(PrimeVue);

import { createApolloClient } from '@nhost/apollo';
import { DefaultApolloClient } from '@vue/apollo-composable';

const apolloClient = createApolloClient({ nhost });
app.provide(DefaultApolloClient, apolloClient);

// pinia store
import { createPinia } from 'pinia';

const pinia = createPinia();

app.use(pinia);

app.component('InitializationProvider', InitializationProvider);

//vue-meta
import { createHead } from '@vueuse/head';

const head = createHead();
app.use(head);

// set default settings
import appSetting from '@/app-setting';

appSetting.init();

// popper
import Popper from 'vue3-popper';

app.component('Popper', Popper);

// perfect scrollbar
import PerfectScrollbar from 'vue3-perfect-scrollbar';

app.use(PerfectScrollbar);

import router from '@/router';

app.use(router);

initializeEventProcessor(app);

//vue-i18n
import i18n from '@/i18n';
app.use(i18n);

//multiselect
import Multiselect from '@suadelabs/vue3-multiselect';
app.component('Multiselect', Multiselect);

// fallbackImageDirective
import fallbackImage from '@/directives/fallbackImageDirective';
app.directive('fallback-image', fallbackImage);

const emitter = mitt();
app.provide('emitter', emitter);

app.use(TippyPlugin);

// add VueApexCharts
import VueApexCharts from 'vue3-apexcharts';
app.use(VueApexCharts);

import VueDOMPurifyHTML from 'vue-dompurify-html';
app.use(VueDOMPurifyHTML);

import CronLightPlugin from '@vue-js-cron/light';
app.use(CronLightPlugin);

import '@vue-js-cron/light/dist/light.css';
import { CronLight } from '@vue-js-cron/light';
app.component('CronComponent', CronLight);

app.mount('#app');
