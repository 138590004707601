{
  "useWebPreview": {
    "do_you_see_relevant_content_from_url": "Vedi contenuti rilevanti dall'URL? Se no, prova un metodo alternativo per recuperarli.",
    "could_not_get_the_preview": "Impossibile ottenere l'anteprima. Prova un altro metodo.",
    "alternative_fetch": "Recupero alternativo",
    "we_tried_to_fetch_the_content_as_it_would_be": "Abbiamo cercato di recuperare i contenuti come farebbe il browser Chrome. Se non sei ancora soddisfatto, prova a fare clic su \"Testo alternativo\".",
    "alternative_text": "Testo alternativo",
    "we_have_demonstrated_all_the_ways": "Abbiamo dimostrato tutti i modi in cui abbiamo potuto recuperare i contenuti. Vuoi tornare al metodo originale e visualizzarlo?",
    "back_to_origin": "Torna all'origine"
  },
  "useMaximumNumberOfProjectsAlert": {
    "limit_error": "Sembra che tu abbia raggiunto il numero massimo di progetti. Per avviare un nuovo progetto, considera di archiviare o eliminare quelli esistenti che non ti servono più, oppure esplora i nostri piani di abbonamento per limiti più elevati.",
    "upgrade": "Aggiorna"
  },
  "Preview": "Anteprima"
}