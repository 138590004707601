{
  "chatGptAlpha": {
    "semantic_balance_option": "Semantische Balance Option",
    "full_text_search": "Volltextsuche",
    "full_vector_search": "Vollvektorsuche"
  },
  "chatGptCreativity": {
    "here_you_can_specify_the_creativity": "Hier können Sie das Kreativitätsniveau für das Modell festlegen. Je näher der Wert bei null liegt, desto kreativer ist das Modell, aber es hält sich nicht direkt an den bereitgestellten Kontext. Je näher der Wert bei eins liegt, desto stärker orientiert sich das Modell an den bereitgestellten Daten.",
    "context_proof_level": "Kontextbeweisniveau",
    "trying_to_suppose_with_general_information": "Versuchen, mit allgemeinen Informationen zu vermuten",
    "answering_only_using_project_data": "Nur unter Verwendung von Projektdaten antworten"
  },
  "chatGptCreativityExamples": {
    "example_question": "Beispiel Frage",
    "how_many_people_live_on_mars_now": "Wie viele Menschen leben jetzt auf dem Mars?",
    "example_answer": "Beispiel Antwort",
    "absolutely_not_only_do_people_live_on_mars": "Absolut! Nicht nur leben heutzutage Menschen auf dem Mars, sondern sie haben ihn in eine lebendige Metropole verwandelt. Die Mars-Stadtlandschaft bietet prächtige Kuppelstrukturen, futuristische Transportsysteme und atemberaubende Gärten, die den Naturgesetzen trotzen. Die Bewohner, bekannt als 'Marsonauten', haben telepathische Fähigkeiten entwickelt und kommunizieren mit der einheimischen Mars-Spezies. Sie haben auch das Geheimnis der ewigen Jugend durch eine einzigartige Mischung aus Mars-Kräutern und kosmischer Energie entdeckt.",
    "while_humans_do_not_currently_live_on_mars": "Obwohl Menschen derzeit nicht auf dem Mars leben, gab es bedeutende Fortschritte in der Raumfahrt, die uns dieser Möglichkeit näher bringen. Pläne und Konzepte für die Mars-Kolonisierung wurden entwickelt, in denen Strategien für nachhaltige Lebensräume, Ressourcennutzung und langfristiges Überleben skizziert werden. Fortlaufende Forschung und technologische Entwicklungen zielen darauf ab, Herausforderungen wie Strahlenbelastung, begrenzte Ressourcen und die psychologischen Auswirkungen von Isolation anzugehen. Es ist eine aufregende Zeit, in der wir die Grundlagen für eine potenzielle menschliche Besiedlung des roten Planeten legen.",
    "at_present_humans_do_not_reside_on_mars": "Derzeit leben keine Menschen auf dem Mars. Es gibt jedoch zahlreiche Raumfahrtagenturen und private Unternehmen, die aktiv an Missionen und Projekten arbeiten, die zu zukünftigen menschlichen Siedlungen führen könnten. Fortgeschrittene Robotermissionen wurden eingesetzt, um die Ressourcen, das Klima und potenzielle Landeplätze des Planeten zu bewerten. Innovative Ideen wie 3D-gedruckte Lebensräume, unterirdische Kolonien und Terraforming-Technologien werden erforscht, um den Mars bewohnbarer zu machen. Der Traum vom Leben auf dem Mars beflügelt kreative Lösungen und treibt die Grenzen der menschlichen Erforschung voran.",
    "no_as_of_my_knowledge_cutoff_in_september": "Nein, nach meinem Kenntnisstand bis September 2021 leben keine Menschen auf dem Mars. Obwohl erfolgreiche Robotermissionen zum Mars stattgefunden haben, einschließlich des derzeitigen Erkundungsrovers Perseverance, ist die menschliche Besiedlung des Mars immer noch in der Zukunft. Es werden umfangreiche Forschung und Planung betrieben, um langfristige bemannte Missionen zum Mars realisierbar zu machen, aber bisher ist dies noch nicht geschehen."
  },
  "chatGptHybrid": {
    "if_you_need_a_simple_search_in_the_classical": "Wenn Sie eine einfache Suche auf klassische Weise benötigen, bei der nur die Wörter in den bereitgestellten Daten übereinstimmen, müssen Sie den Schieberegler näher an null bewegen. Wenn Sie hingegen möchten, dass die Suche eher wie eine natürliche Sprachverarbeitung aussieht und Wortformen unter Berücksichtigung von Synonymen, Kontext und verwandten Konzepten nachschlägt, müssen Sie den Schieberegler näher an eins bewegen.",
    "semantic_balance_option": "Semantische Balance Option",
    "classic_text_search": "Klassische Textsuche",
    "semantic_vector_search": "Semantische Vektorsuche"
  },
  "chatGptLimit": {
    "here_you_can_define_the_maximum": "Hier können Sie die maximale Anzahl von Dokumenten (bzw. Chunks) festlegen, die zur Generierung der Antwort verwendet werden.",
    "limit_number_of_documents": "Limit (Anzahl der Dokumente)",
    "minimum": "Minimum",
    "maximum": "Maximum"
  },
  "chatGptModel": {
    "gpt_model_which_is_used_to_answer_the_questions": "GPT-Modell, das zur Beantwortung der Fragen verwendet wird",
    "click_here_to_add_open_ai_api_key": "Klicken Sie hier, um den OpenAI API-Schlüssel hinzuzufügen",
    "please_choose_the_model": "Bitte wählen Sie das Modell aus, das Sie verwenden möchten. Das GPT-4-Modell ist robuster und liefert präzisere Antworten, ist jedoch langsamer und teurer, während GPT 3.5 schneller ist und den meisten Fällen entspricht.",
    "gpt_4_model_is_available_in_your_plan": "Das GPT-4-Modell ist in Ihrem Plan verfügbar! Um darauf zugreifen zu können, erhalten Sie bitte Ihren eigenen OpenAI API-Schlüssel. Sie können dies einfach einrichten, indem Sie Ihre Profilseite besuchen."
  },
  "chatGptSliderRange": {
    "optimal": "Optimal"
  }
}