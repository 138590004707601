{
  "cancelChain": {
    "cancel": "Abbrechen",
    "yes": "Ja",
    "no": "Nein",
    "stop_processing": "Verarbeitung stoppen",
    "do_you_want_to_stop": "Möchten Sie alle Verarbeitungsketten stoppen?"
  },
  "progressIndex": {
    "no_resources_so_far_redirecting_to_informers": "Bisher keine Ressourcen. Weiterleitung zu Informanten...",
    "an_error_occured_while_deleting": "Beim Löschen ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut"
  }
}