import { createRouter, createWebHistory } from 'vue-router';
import { useAppStore } from '@/stores';
import routes from '~pages';
import { nhostAuthProvider, nhost } from '@/lib/nhost';
import { computed } from 'vue';
import { notifyExtension } from '@/router/utils/notifyExtension';
import { getIsPaidUser } from '@/helpers';
import { getDomain } from '@helpers/getDomain';
import { useThrottleFn } from '@vueuse/core';
import { signupAnalyticsInform } from '@/router/utils/signupAnalyticsInform';
import { checkIfNewUser } from './utils/checkIfNewUser';

const informAnalitycs = useThrottleFn(signupAnalyticsInform, 2000);

const router = createRouter({
  history: createWebHistory(),
  routes,
});

enum AuthRoutes {
  SignIn = 'SignIn',
  SignUp = 'SignUp',
  SignUpInvite = 'invite',
  InviteAccept = 'invite-token',
  InviteReject = 'reject-token',
  ResetPassword = 'ResetPassword',
  Extension = 'Extension',
  PortalSignIn = 'omni-portal-portal-SignIn',
  PortalSignUp = 'omni-portal-portal-SignUp',
  PortalResetPassword = 'omni-portal-portal-reset-password',
  PortalChangePassword = 'omni-portal-portal-change-password-token',
  PortalSignUpInvite = 'omni-portal-portal-invite',
  PortalSignUpInviteAccept = 'omni-portal-portal-invite-token',
}

router.beforeEach(async (to, from, next) => {
  const store = useAppStore();

  const isAuthRoute = [
    AuthRoutes.SignIn,
    AuthRoutes.SignUp,
    AuthRoutes.SignUpInvite,
    AuthRoutes.InviteAccept,
    AuthRoutes.InviteReject,
    AuthRoutes.ResetPassword,
    AuthRoutes.PortalSignIn,
    AuthRoutes.PortalSignUp,
    AuthRoutes.PortalResetPassword,
    AuthRoutes.PortalChangePassword,
    AuthRoutes.PortalSignUpInvite,
    AuthRoutes.PortalSignUpInviteAccept,
  ].includes(to.name as AuthRoutes);
  const defaultDomains = ['app.omnimind.ai', 'dev.omnimind.ai', 'demo.omnimind.ai'];
  const isLocalEnv = import.meta.env.MODE === 'development';
  const domain = getDomain();

  const portalDomain = isLocalEnv ? 'test.omnimind.ai' : domain;
  const isPortalRoute =
    Boolean(import.meta.env.VITE_OMNI_PORTALS_ENABLED) &&
    (isLocalEnv ? to.path.includes('omni-portal/portal') : !defaultDomains.includes(domain));

  const isWidgetRoute = to.path.includes('/share/widget/index.htm');
  store.setMainLayout(isAuthRoute || isWidgetRoute ? 'auth' : 'app');

  const isProjectsRoute = to.path.includes('project');
  const isPortalProjectRoute = to.path.includes('link');
  const isPortalFormRoute = to.path.includes('omni-portal');
  const isAuthenticated = await nhostAuthProvider.isAuthenticatedAsync();
  const isProtected = to.matched.some((record) => record.meta.protected);

  const isExtension = computed(() => to.path.includes('/extension'));

  if (isExtension.value) {
    const externalRedirect = String(to.query.externalRedirect);
    if (isAuthenticated) {
      await notifyExtension(externalRedirect, next);
    } else {
      next({
        path: '/signin',
        query: { externalRedirect: encodeURIComponent(externalRedirect), name: to.query.name },
      });
    }
  }

  nhost.auth.onAuthStateChanged(async (state) => {
    if (state === 'SIGNED_IN') {
      if (store.user) {
        const newUser = checkIfNewUser(store.user.createdAt);
        newUser ? informAnalitycs('signup') : informAnalitycs('signin');
      }

      const path = localStorage.getItem('externalRedirect');
      if (path?.length) {
        await notifyExtension(path, next);
      }
    }
  });

  if (
    [
      AuthRoutes.SignUpInvite,
      AuthRoutes.InviteReject,
      AuthRoutes.InviteAccept,
      AuthRoutes.PortalSignUpInvite,
      AuthRoutes.PortalSignUpInviteAccept,
    ].includes(to.name as AuthRoutes)
  ) {
    next();
    return;
  }

  if (isProtected) {
    if (isWidgetRoute) {
      next('/');
      return;
    }

    if (!isAuthenticated) {
      if (isPortalRoute) {
        next({
          path: '/omni-portal/portal/signin',
          query: { redirectTo: encodeURIComponent(String(to.fullPath)) },
        });
      } else if (!isPortalRoute) {
        next({
          path: '/signin',
          query: { redirectTo: encodeURIComponent(String(to.fullPath)) },
        });
      }
      return;
    }

    const isPaidUser = await getIsPaidUser(isPortalRoute, portalDomain);

    if (!isPaidUser && (isProjectsRoute || isPortalFormRoute) && !isPortalRoute) {
      next('/profile?tab=packages');

      return;
    }

    if (!isPaidUser && isPortalProjectRoute && isPortalRoute) {
      next('/omni-portal/portal/profile?tab=packages');

      return;
    }

    next();

    return;
  } else if (isAuthenticated && isAuthRoute) {
    next(isPortalRoute ? '/omni-portal/portal' : '/');

    return;
  } else {
    next();
  }
});

export default router;
