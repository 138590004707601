{
  "SearchWidget_name": "Suche",
  "SearchWidget_description": "Kommunikation wie in der Suchleiste des Browsers. Stellen Sie Fragen und sehen Sie den Verlauf der Antworten.",
  "ExplainWidget_name": "Erklären",
  "ExplainWidget_description": "Kommunikation für ausführlichere Antworten. Frage - Antwort.",
  "ChatWidget_name": "Chat",
  "ChatWidget_description": "Kommunikation in üblicher Form wie in einem Messenger. Kurz und klar.",
  "BaseInformer_xml-sitemap_name": "XML-Sitemap",
  "BaseInformer_xml-sitemap_description": "Sie können eine XML-Sitemap zum Parsen der Website hinzufügen",
  "PopupChatWidget_name": "Popup-Chat",
  "PopupChatWidget_description": "Interaktives Widget für sofortige Antworten. Fragen stellen, Antworten erhalten.",
  "CSVInformer_name": "CSV-Text",
  "CSVInformer_description": "Sie können Ihre .csv-Datei mit benutzerdefinierter Spaltenzuordnung hochladen",
  "WebInformer_name": "Mehrere Websites",
  "WebInformer_description": "Sie können eine Gruppe von Website-URLs für die Verarbeitung definieren",
  "FileInformer_name": "Datei-Upload",
  "FileInformer_description": "Sie können Ihre lokale Datei zur Verarbeitung auswählen",
  "BaseInformer_url_name": "URL-Datei",
  "BaseInformer_url_description": "Sie können den URL-Pfad definieren, um die Datei aus dem Internet hochzuladen",
  "YoutubeInformer_name": "Youtube",
  "YoutubeInformer_description": "Sie können Untertitel für Youtube-Videos hinzufügen",
  "BaseInformer_text_name": "Text",
  "BaseInformer_text_description": "Einfach Text hinzufügen",
  "BulkCSVInformer_name": "CSV-Links",
  "BulkCSVInformer_description": "Massenupload über CSV mit URLs",
  "BaseInformer_dropbox-file_name": "Dropbox-Datei",
  "BaseInformer_dropbox-file_description": "Link zur Dropbox-Datei hinzufügen",
  "BaseInformer_dropbox-folder_name": "Dropbox-Ordner",
  "BaseInformer_dropbox-folder_description": "Link zum Dropbox-Ordner hinzufügen",
  "BaseInformer_gdrive-file_name": "GDrive-Datei",
  "BaseInformer_gdrive-file_description": "Link zur GDrive-Datei hinzufügen",
  "BaseInformer_gdrive-folder_name": "GDrive-Ordner",
  "BaseInformer_gdrive-folder_description": "Link zum GDrive-Ordner hinzufügen",
  "DocSearchWidget_name": "Dokumentensuche",
  "DocSearchWidget_description": "Ermöglicht die Installation eines Popup-Widgets zum Durchsuchen der Dokumentation",
  "RetellingWidget_name": "Nacherzählen",
  "RetellingWidget_description": "Interaktives Nacherzählungstool für Ihre Wissensbasis-Einblicke.",
  "VimeoInformer_name": "Vimeo",
  "VimeoInformer_description": "Sie können Untertitel für Vimeo-Videos hinzufügen",
  "ConfluenceInformer_name": "Confluence",
  "ConfluenceInformer_description": "Sie können Ihre Confluence-Wissensbasis hinzufügen",
  "BaseInformer_zendesk_name": "Zendesk",
  "BaseInformer_zendesk_description": "Sie können die URL des Zendesk-Hilfezentrums für die Verarbeitung definieren.",
  "BaseInformer_youtube-channel_name": "Youtube-Kanal",
  "BaseInformer_youtube-channel_description": "Sie können die URL des Youtube-Kanals für die Verarbeitung definieren.",
  "BaseInformer_youtube-playlist_name": "Youtube-Playlist",
  "BaseInformer_youtube-playlist_description": "Sie können die URL der Youtube-Playlist für die Verarbeitung definieren.",
  "NotionInformer_name": "Notion",
  "NotionInformer_description": "Sie können Notion-Seiten oder -Datenbanken hinzufügen",
  "InteractiveMindmapWidget_name": "Interaktive Mindmap",
  "InteractiveMindmapWidget_description": "Hierarchische Mindmaps erstellen und anpassen.",
  "MermaidMindmapWidget_name": "Mermaid Mindmap",
  "MermaidMindmapWidget_description": "Hierarchische Mindmaps erstellen und anpassen.",
  "WordpressInformer_name": "Wordpress",
  "WordpressInformer_description": "Sie können Ihre Wordpress-Website hinzufügen",
  "GoogleSheetsAddOn_name": "Google Sheets Add-On",
  "GoogleSheetsAddOn_description": "Fügen Sie das Omnimind AI Google Sheets Add-On zu Ihren Google Sheets hinzu",
  "ZapierWidget_name": "Zapier-Integration",
  "ZapierWidget_description": "Fügen Sie das Omnimind AI zu Ihrer Zapier-Anwendung hinzu",
  "ChromeExtensionInformer_name": "Chrome-Erweiterung",
  "ChromeExtensionInformer_description": "Laden Sie Informationen aus Ihren Ressourcen mit unserer Chrome-Erweiterung hoch.",
  "VoiceWidget_name": "Sprachdialog",
  "VoiceWidget_description": "Sprechen Sie mit Ihrem Bot, um Antworten zu erhalten, wie Sie mit einem Menschen sprechen",
  "SingleWebInformer_name": "Website",
  "SingleWebInformer_description": "Sie können eine einzelne Website-URL für die Verarbeitung definieren",
  "DataExtractorWidget_name": "Datenextraktor",
  "DataExtractorWidget_description": "Websites durchsuchen, um Informationen zu sammeln",
  "SlackIntegration_name": "Slack-Integration",
  "SlackIntegration_description": "Fügen Sie das Omnimind AI zu Ihrem Slack-Arbeitsbereich hinzu",
  "WhatsAppIntegration_name": "WhatsApp-Integration",
  "WhatsAppIntegration_description": "Kommunikation wie gewohnt in WhatsApp.",
  "BaseInformer_onedrive-file_name": "OneDrive-Datei",
  "BaseInformer_onedrive-file_description": "Link zur OneDrive-Datei hinzufügen",
  "BaseInformer_onedrive-folder_name": "OneDrive-Ordner",
  "BaseInformer_onedrive-folder_description": "Link zum OneDrive-Ordner hinzufügen",
  "SummaryWidget_name": "Zusammenfassung",
  "SummaryWidget_description": "Verwandeln Sie Ihre Daten in klare und prägnante Zusammenfassungen.",
  "QuizWidget_name": "Quiz",
  "QuizWidget_description": "Umfassende Quiz-Erstellung und Export",
  "TableWidget_name": "Tabelle",
  "TableWidget_description": "Gemeinsames Widget zur Anzeige von Daten in einer Tabelle. Dieses Widget ist in der Liste der Widgets ausgeblendet.",
  "AudioInformer_name": "Audiodatei",
  "AudioInformer_description": "Sie können Ihre lokale Audiodatei zur Verarbeitung auswählen",
  "AirtableInformer_name": "Airtable",
  "AirtableInformer_description": "Sie können Ihre Airtable-Wissensbasis hinzufügen",
  "FlashcardsWidget_name": "Flashcards",
  "FlashcardsWidget_description": "Erstellen und verwenden Sie Flashcards für effektives Lernen und Wissenstests",
  "ExcelInformer_name": "Excel",
  "ExcelInformer_description": "Sie können Ihre .xls- oder .xlsx-Datei mit benutzerdefinierter Spaltenzuordnung hochladen",
  "GoogleSheetsInformer_name": "Google Sheets",
  "GoogleSheetsInformer_description": "Sie können Ihre Google Sheets mit benutzerdefinierter Spaltenzuordnung hochladen",
  "DatabaseInformer_name": "Datenbank-Einblicke",
  "DatabaseInformer_description": "Sie können Anfragen an Ihre eigenen Datenbanken in natürlicher Sprache stellen.",
  "PdfOcrInformer_name": "PDF",
  "PdfOcrInformer_description": "Extrahiert alle Daten aus PDFs und fügt sie Ihren Ressourcen hinzu.",
  "BubbleIntegration_name": "Bubble.io-Plugin",
  "BubbleIntegration_description": "Integrieren Sie OmniMind in Ihre Bubble-Lösungen.",
  "NpmIntegration_name": "NPM-Paket",
  "NpmIntegration_description": "Fügen Sie das Omnimind AI Npm-Paket zu Ihren Projekten hinzu",
  "MicrosoftTeamsIntegration_name": "Microsoft Teams-Integration",
  "MicrosoftTeamsIntegration_description": "Integrieren Sie Ihre Wissensbasis in Ihre Unternehmenskommunikation mit Microsoft Teams und einem unserer Chatbots und beschleunigen Sie Ihre betrieblichen Prozesse."
}