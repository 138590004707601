{
  "addFileDrop": {
    "drag_drop_or": "Arraste e solte ou",
    "select_files": "Selecionar arquivos",
    "to_upload": "para enviar",
    "error_uploading_file": "Erro ao enviar arquivo",
    "error_uploading_files": "Erro ao enviar arquivos"
  },
  "resourcesProject": {
    "show_uploaded_resources": "Mostrar recursos enviados",
    "knowledge": "Conhecimento"
  },
  "resourcesProjectListItem": {
    "click_here_to_show_hide_explore_the_pages": "Clique aqui para {value} as páginas!",
    "delete": "Excluir",
    "chunks": "pedaços",
    "cancel": "Cancelar",
    "resource": "Recurso",
    "size": "Tamanho",
    "updated": "Atualizado",
    "page_content": "Conteúdo da página"
  },
  "resourcesProjectModal": {
    "knowledge": "Conhecimento",
    "save": "Salvar",
    "search_for_uploaded_knowledge": "Buscar conhecimento enviado...",
    "add_more": "Adicionar Mais"
  }
}