{
  "formClearHistoryItem": {
    "clear_chat_history": "Chat-Verlauf löschen",
    "do_you_want_to_clear": "Möchten Sie Ihren Chat-Verlauf löschen?",
    "clear_history": "Verlauf löschen",
    "all_your_history_will_be_deleted": "Ihr gesamter Chat-Verlauf wird gelöscht."
  },
  "formOptions": {
    "more_options": "Weitere Optionen"
  },
  "formPdfGeneratingItem": {
    "export_chat_as_pdf": "Chat als PDF exportieren",
    "create_chat_to_pdf": "Chat in PDF umwandeln",
    "wait_for_a_few_seconds_please": "Bitte warten Sie einige Sekunden...",
    "something_happened": "Beim Erstellen des PDFs ist ein Fehler aufgetreten"
  }
}