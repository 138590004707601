{
  "likeDislikeAnswer": {
    "please_provide_additional_feedback": "Fornisci ulteriori commenti",
    "enter_your_username": "Inserisci il tuo nome",
    "enter_your_email": "Inserisci la tua email",
    "what_was_the_issue_with_the_response": "Qual è stato il problema con la risposta?",
    "email_and_username_are_required": "Email e nome sono obbligatori per inviare un feedback",
    "thank_you_sincerely_for_your_feedback": "Grazie sinceramente per il tuo feedback. È molto apprezzato.",
    "sorry_but_we_encountered": "Spiacenti, ma abbiamo riscontrato un problema durante il tentativo di inviare il tuo feedback. Riprova.",
    "grateful_for_the_thumbs_up": "Grato per il pollice in su!"
  },
  "likeDislikeAnswerModal": {
    "submit": "Invia"
  },
  "likeDislikeAnswerModalHeader": {
    "didnt_like_the_response": "Non mi è piaciuta la risposta"
  }
}