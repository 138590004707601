{
  "settingsBehaviour": {
    "behavior": "Verhalten",
    "choose_option": "Wählen Sie die Option aus, die Sie bearbeiten möchten",
    "label_for_examples_of_questions": "Beschriftung für Beispiele von Fragen, die Sie den Bot stellen können",
    "before_you_start_to_work_with_widget": "Bevor Sie mit dem Widget arbeiten, sehen Sie Beispiele von Fragen, die Sie den Bot stellen können",
    "enter_label_for_examples_of_questions": "Geben Sie eine Beschriftung für Beispiele von Fragen ein, die Sie den Bot stellen können...",
    "split_your_questions_with_a_new_line": "Trennen Sie Ihre Fragen mit einer neuen Zeile.",
    "welcome_messages": "Willkommensnachrichten",
    "enter_messages": "Geben Sie Nachrichten ein, die durch doppeltes Drücken der Eingabetaste getrennt sind.",
    "start_each_message": "Beginnen Sie jede Nachricht in einer neuen Zeile. Drücken Sie zweimal die Eingabetaste, um die Nachrichten zu trennen.",
    "type_messages": "Geben Sie Nachrichten ein, doppelte Eingabetaste zum Trennen.",
    "add_a_custom_welcome_message_interval": "Fügen Sie ein benutzerdefiniertes Willkommensnachrichten-Intervall hinzu (Sekunden)",
    "enter_welcome_message_interval": "Geben Sie das Willkommensnachrichten-Intervall ein...",
    "add_a_custom_welcome_message": "Ändern Sie die \"Ich kenne die Antwort nicht\"",
    "when_your_bot_isn_t_sure_about": "Wenn Ihr Bot sich nicht sicher ist, antwortet er mit \"Vermutlich.\" Sie können diesen Satz ändern, und er wird anstelle davon angezeigt.",
    "most_likely": "Vermutlich...",
    "add_a_custom_message": "Fügen Sie jeder OmniMind-Antwort eine benutzerdefinierte Nachricht hinzu",
    "enter_custom_message": "Geben Sie eine benutzerdefinierte Nachricht ein...",
    "default_language": "Standardsprache",
    "custom_language": "Benutzerdefinierte Sprache",
    "show_followups_questions": "Folgefragen anzeigen",
    "live_connect": "Live Connect",
    "none": "Keine",
    "button_name_for_leaving_a_message": "Button-Name zum Hinterlassen einer Nachricht",
    "leave_a_message": "Eine Nachricht hinterlassen",
    "offline_message": "Offline-Nachricht",
    "button_name_for_offline_message": "Button-Name für Offline-Nachricht",
    "dialogs": "Unterhaltungen",
    "anonymize_sensitive_data": "Sensible Daten anonymisieren",
    "language_of_the_data": "Sprache der Daten",
    "language": "Sprache",
    "replace_value": "Wert ersetzen",
    "replace": "Ersetzen"
  },
  "behaviourDialogsSetting": {
    "history_mode": "Verlaufsmodus",
    "collect_user_mode": "Benutzermodus erfassen",
    "track_all_messages": "Alle Nachrichten verfolgen",
    "track_only_messages_with": "Nur negative Rückmeldungen und Supportanfragen verfolgen",
    "dont_track": "Nicht verfolgen",
    "collect_username_and_email_with_form": "Benutzername und E-Mail mit Formular erfassen",
    "provide_username_and_email_by_yourself": "Benutzername und E-Mail selbst angeben",
    "do_not_collect_username_and_email": "Benutzername und E-Mail nicht erfassen"
  },
  "behaviourLiveConnectAction": {
    "selected_option_will_be_applied": "Die ausgewählte Option wird als Aktion für den Button angewendet",
    "live_connect_options": "Live-Connect-Optionen",
    "your_custom_js_code": "Ihr benutzerdefinierter JS-Code",
    "ensure": "Stellen Sie sicher, dass",
    "is_correctly_set_up": "in Ihrem Projekt korrekt eingerichtet ist. Brauchen Sie Hilfe?",
    "check_here": "Hier überprüfen."
  },
  "behaviourOfflineMessageAction": {
    "selected_option_will_be_applied_as_action_for_button": "Die ausgewählte Option wird als Aktion für den Button angewendet",
    "offline_message_options": "Offline-Nachrichtenoptionen",
    "your_custom_js_code": "Ihr benutzerdefinierter JS-Code",
    "your_email": "Ihre E-Mail"
  },
  "behaviourRateLimit": {
    "enable_rate_limit": "Rate Limit aktivieren",
    "rate_limit": "Rate Limit",
    "enable_rate_limit_message": "Begrenzen Sie die Anzahl der Nachrichten, die pro Stunde von diesem Widget gesendet werden"
  }
}