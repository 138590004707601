import { nhost } from '@/lib/nhost';

type Explanation = {
  explanation: { reason?: string; description?: string };
};

export const generateApiKey = async (name?: string): Promise<any> => {
  return await nhost.functions.call('users/api-token?period=1y', name ? { name } : null);
};

export const getApiKeys = async (): Promise<any> => {
  return await nhost.functions.call('users/api-tokens', null);
};

export const generateSignToken = async (period?: string): Promise<any> => {
  return await nhost.functions.call('users/sign-token', { period });
};

export const callCancelSubscription = async (value: Explanation): Promise<any> => {
  return nhost.functions.call('billing/cancel-subscription', value);
};

export const callAccountMarkAsRemoved = async (value: Explanation): Promise<any> => {
  return nhost.functions.call('users/remove', value);
};

export const callAccountReactivate = async (): Promise<any> => {
  return nhost.functions.call('users/reactivate', {});
};

export const getDiscounts = async (): Promise<any> => {
  return nhost.functions.call('users/discounts', {});
};

export const claimOffer = async (): Promise<any> => {
  return nhost.functions.call('users/discount/create', {});
};
