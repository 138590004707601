{
  "billingOverview": {
    "billing_overview": "Огляд рахунків",
    "expenses": "Витрати"
  },
  "billingOverviewItem": {
    "used": "Використано",
    "remaining": "залишилося",
    "used_out_of": "Використано {formattedCurrent_value} з {formattedTotal_value}.",
    "money": "Гроші"
  },
  "cardList": {
    "card_details": "Деталі картки",
    "expires_on": "Термін дії до",
    "primary": "Основна"
  },
  "paymentHistory": {
    "payment_history": "Історія платежів",
    "view_invoice": "Переглянути рахунок",
    "download_invoice": "Завантажити рахунок",
    "march": "Березень",
    "february": "Лютий",
    "january": "Січень",
    "pro_membership": "Про-членство"
  },
  "projectList": {
    "projects": "Проекти"
  },
  "proPlanInfo": {
    "pro_plan": "Про-план",
    "renew_now": "Поновити зараз",
    "monthly_visitors": "{quantity} щомісячних відвідувачів",
    "unlimited_reports": "Необмежені звіти",
    "years_data_storage": "{quantity} років зберігання даних",
    "days_left": "Залишилось днів",
    "month": "місяць"
  },
  "userInfo": {
    "profile": "Профіль",
    "no_company_name": "немає назви компанії",
    "no_phone_number": "немає номера телефону",
    "no_timezone": "немає часового поясу",
    "remove_your_account": "Видалити ваш обліковий запис"
  }
}