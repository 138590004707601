{
  "sidebar": {
    "it_looks_like_you_are": "Es sieht so aus, als hättest du die maximale Anzahl an Projekten erreicht. Um ein neues Projekt zu starten, solltest du vorhandene Projekte archivieren oder löschen, die du nicht mehr benötigst, oder unsere Abonnementpläne für höhere Limits erkunden.",
    "new_project": "Neues Projekt",
    "select_widget": "Widget auswählen"
  },
  "sidebarHeader": {
    "new_project": "Neues Projekt"
  },
  "sidebarLink": {
    "project_settings": "Projekteinstellungen",
    "project_has_been_successfully": "Projekt wurde erfolgreich {isFav} favorisiert!",
    "we_encountered_an_issue": "Beim Aktualisieren deiner Favoriten ist ein Problem aufgetreten. Bitte versuche es erneut."
  },
  "sidebarNoProjects": {
    "there_are_no_projects": "Hier gibt es bisher keine Projekte"
  },
  "sidebarProjectsListFooter": {
    "create_project": "Projekt erstellen",
    "create_a_project_using": "Erstelle ein Projekt mit",
    "bot_advice": "Bot-Beratung",
    "enter_redemption_code": "Einlösecode",
    "applying": "Wird angewendet...",
    "apply": "Anwenden",
    "oops_you_ve_reached_your_project_limit": "Hoppla! Du hast dein Projektlimit erreicht und kannst keine neuen Projekte erstellen. Um mehr Projekte zu erstellen, solltest du dein Paket aktualisieren!"
  },
  "sidebarProjectTree": {
    "knowledge": "Wissen",
    "add_a_new_view": "Neue Ansicht hinzufügen",
    "dialogs": "Dialoge",
    "conversations": "Unterhaltungen",
    "team": "Team",
    "project": "Projekt"
  },
  "sidebarPromocode": {
    "enter_redemption_code": "Einlösecode",
    "exists_promo": "Dieser Promo-Code existiert bereits",
    "apply": "Anwenden"
  },
  "sidebarProjectTreeSettingsItem": {
    "settings": "Einstellungen",
    "ai_model": "KI-Modell",
    "behavior": "Verhalten",
    "appearance": "Erscheinungsbild",
    "integrations": "Integrationen",
    "database": "Datenbank"
  },
  "sidebarSharedProjectsListItem": {
    "something_went_awry": "Etwas ist schiefgelaufen!"
  },
  "sidebarEmailConfirmWarning": {
    "resend": "Erneut senden",
    "sending": "Senden",
    "please_check_your_inbox": "Bitte überprüfe deinen Posteingang",
    "to_verify_your_email": "um deine E-Mail zu bestätigen.",
    "verification_email_is_sent": "Bestätigungs-E-Mail wurde gesendet.",
    "something_went_wrong": "Etwas ist schiefgelaufen"
  }
}