import { GET_USER_BILLING_PACKAGES } from '@/api';
import type { BillingUserPackage } from '@/interfaces';
import { useAppStore } from '@/stores';
import { useUserData } from '@nhost/vue';
import { useLazyQuery } from '@vue/apollo-composable';
import { onMounted, ref } from 'vue';
import { OMNIMIND_APP_PORTAL_ID } from '@/api/constants/portals';

export const useGetBillingPackages = (portal_id: string = OMNIMIND_APP_PORTAL_ID) => {
  const store = useAppStore();
  const user = useUserData();
  const { load, onResult, refetch } = useLazyQuery(GET_USER_BILLING_PACKAGES);
  // const { load: applicationLoad, onResult: applicationResult } = useLazyQuery(GET_APPLICATION);
  const billingPackages = ref<BillingUserPackage | null>(null);

  onMounted(() => {
    refetch({ id: user.value?.id, portal_id });
  });

  onResult(async (result: any) => {
    if (!result) {
      await refetch({ id: user.value?.id });
      return;
    }
    if (!result.data?.billing_user_packages || result.data?.billing_user_packages.length === 0) {
      store.setBillingPackagesData({});
    } else {
      const packageData = result.data?.billing_user_packages[0];
      store.setBillingPackagesData(packageData);
      billingPackages.value = packageData;
    }
  });

  // applicationResult((result: any) => {
  //   store.setBillingPackagesData({ application: result?.data?.application ?? [] });
  // });

  const loadBillingPackages = () => {
    refetch({ id: user.value?.id, portal_id });
    load(undefined, { id: user.value?.id, portal_id });
    // applicationLoad();
  };

  return {
    loadBillingPackages,
    billingPackages,
  };
};
