{
  "chatGptAlpha": {
    "semantic_balance_option": "Opção de equilíbrio semântico",
    "full_text_search": "Pesquisa de texto completo",
    "full_vector_search": "Pesquisa de vetor completo"
  },
  "chatGptCreativity": {
    "here_you_can_specify_the_creativity": "Aqui você pode especificar o nível de criatividade para o modelo. Quanto mais próximo o valor de zero, mais criativo o modelo, mas ele não adere diretamente ao contexto fornecido nos dados enviados. Quanto mais próximo o valor de um, mais o modelo adere diretamente aos dados fornecidos.",
    "context_proof_level": "Nível de prova de contexto",
    "trying_to_suppose_with_general_information": "Tentando supor com informações gerais",
    "answering_only_using_project_data": "Respondendo apenas usando dados do projeto"
  },
  "chatGptCreativityExamples": {
    "example_question": "Exemplo de pergunta",
    "how_many_people_live_on_mars_now": "Quantas pessoas vivem em Marte agora?",
    "example_answer": "Exemplo de resposta",
    "absolutely_not_only_do_people_live_on_mars": "Absolutamente! Não apenas as pessoas vivem em Marte hoje em dia, mas elas o transformaram em uma metrópole vibrante. A paisagem urbana marciana possui magníficas estruturas abobadadas, sistemas de transporte futuristas e jardins deslumbrantes que desafiam as leis da natureza. Os habitantes, conhecidos como 'marsonautas', desenvolveram habilidades telepáticas e se comunicam com as espécies marcianas nativas. Eles também descobriram o segredo da juventude eterna através de uma combinação única de ervas marcianas e energia cósmica.",
    "while_humans_do_not_currently_live_on_mars": "Embora os humanos não vivam atualmente em Marte, houve avanços significativos na exploração espacial que nos aproximam dessa possibilidade. Planos e conceitos para a colonização de Marte foram desenvolvidos, delineando estratégias para habitats sustentáveis, utilização de recursos e sobrevivência a longo prazo. Pesquisas em andamento e desenvolvimentos tecnológicos visam enfrentar desafios como exposição à radiação, recursos limitados e os efeitos psicológicos do isolamento. É um momento emocionante em que lançamos as bases para um possível assentamento humano no planeta vermelho.",
    "at_present_humans_do_not_reside_on_mars": "Atualmente, os humanos não residem em Marte. No entanto, várias agências espaciais e empresas privadas estão trabalhando ativamente em missões e projetos que poderiam levar à futura habitação humana. Missões robóticas avançadas foram implantadas para avaliar os recursos do planeta, o clima e os possíveis locais de pouso. Ideias inovadoras, como habitats impressos em 3D, colônias subterrâneas e tecnologias de terraformação, estão sendo exploradas para tornar Marte mais habitável. O sonho de viver em Marte está impulsionando soluções criativas e ampliando os limites da exploração humana.",
    "no_as_of_my_knowledge_cutoff_in_september": "Não, até o meu conhecimento atualizado em setembro de 2021, não há pessoas vivendo em Marte. Embora tenham ocorrido missões robóticas bem-sucedidas em Marte, incluindo o rover Perseverance, que está explorando o planeta atualmente, a habitação humana em Marte ainda está no campo das possibilidades futuras. Pesquisas extensas e planejamento estão sendo conduzidos para tornar missões humanas de longo prazo a Marte viáveis, mas ainda não foi realizado."
  },
  "chatGptHybrid": {
    "if_you_need_a_simple_search_in_the_classical": "Se você precisa de uma pesquisa simples no estilo clássico, correspondendo apenas às palavras nos dados fornecidos, então você deve mover o controle deslizante mais próximo de zero. Por outro lado, se você deseja que a pesquisa seja mais parecida com um processamento de linguagem natural, considerando formas de palavras, sinônimos, contexto e conceitos relacionados, então você deve mover o controle deslizante mais próximo de um.",
    "semantic_balance_option": "Opção de equilíbrio semântico",
    "classic_text_search": "Pesquisa de texto clássica",
    "semantic_vector_search": "Pesquisa de vetor semântico"
  },
  "chatGptLimit": {
    "here_you_can_define_the_maximum": "Aqui você pode definir o número máximo de documentos (ou seja, partes) que serão usados para gerar a resposta.",
    "limit_number_of_documents": "Limite (Número de Documentos)",
    "minimum": "Mínimo",
    "maximum": "Máximo"
  },
  "chatGptModel": {
    "gpt_model_which_is_used_to_answer_the_questions": "Modelo GPT usado para responder às perguntas",
    "click_here_to_add_open_ai_api_key": "Clique aqui para adicionar a chave da API OpenAI",
    "please_choose_the_model": "Por favor, escolha o modelo que você deseja usar. O modelo GPT-4 é mais robusto e fornece respostas mais precisas, mas é mais lento e mais caro, enquanto o GPT 3.5 é mais rápido e adequado para a maioria dos casos.",
    "gpt_4_model_is_available_in_your_plan": "O modelo GPT-4 está disponível no seu plano! Para acessá-lo, obtenha sua própria chave da API OpenAI. Você pode configurar isso facilmente visitando sua página de perfil."
  },
  "chatGptSliderRange": {
    "optimal": "Ótimo"
  }
}