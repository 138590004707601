{
  "settingsIntegrations": {
    "integrations": "Integrations",
    "please_note_that_any_activity_of_the_users": "Please note that any activity of the users who you share this link with is bound to your account balance.",
    "embed": "Embed",
    "messengers": "Messengers",
    "integration_confirmation": "Integration confirmation",
    "please_note_that_any_activity": "Please note that any activity of the users who you share this link with is bound to your account balance.",
    "copy_link": "Copy link",
    "share_on": "Share on",
    "widget_link_copied_to_clipboard": "Widget link copied to clipboard",
    "personal_shared": "personal / shared",
    "share": "Share",
    "on": "Activate",
    "off": "Deactivate"
  }
}
