import { ApiError } from './ApiError';
import type { ApiRequest } from './ApiRequest';
import { convertResponseToApiResponse } from './convertResponseToApiResponse';
import type { ErrorResponseDto } from './ErrorResponseDto';
import { nhost } from '@/lib/nhost';

export async function callRestApi<R>({
  body,
  headers,
  method,
  params,
  path,
}: ApiRequest): Promise<R> {
  const cleanPath = `/${path.join('/')}`.replaceAll('//', '/').replace(/\/$/, '');
  const searchParams = params ? new URLSearchParams(params).toString() : '';
  const base = `${import.meta.env.VITE_NHOST_BACKEND_URL}/rest`;
  const url = base + cleanPath + (searchParams ? `?${searchParams}` : '');

  const defaultHeaders: Record<string, string> = {};

  if (!(body instanceof FormData)) {
    defaultHeaders['Accept'] = 'application/json';
    defaultHeaders['Content-Type'] = 'application/json';
  }
  const accessToken = nhost.auth.getAccessToken();

  defaultHeaders['Authorization'] = `Bearer ${accessToken}`;

  const options: RequestInit = {
    body: body ? (body instanceof FormData ? body : JSON.stringify(body)) : undefined,
    headers: JSON.parse(JSON.stringify({ ...defaultHeaders, ...headers })) as HeadersInit,
    method,
  };

  return fetch(url, options).then(async (response) => {
    if (!response.ok) {
      return handleErrorResponse(response, { body, headers, method, params, path });
    }

    return extractResponseData<R>(response);
  });
}

async function handleErrorResponse<R>(response: Response, request: ApiRequest): Promise<R> {
  const responseData = await extractResponseData<ErrorResponseDto>(response);
  const { message } = responseData;

  if (response.status === 401) {
    window.location.href = '/login';
    return new Promise<R>(() => ({}));
  } else {
    const apiResponse = convertResponseToApiResponse(response, responseData);
    throw new ApiError({ message, request, response: apiResponse });
  }
}

async function extractResponseData<D = Record<string, unknown>>(response: Response): Promise<D> {
  try {
    return (await response.json()) as D;
  } catch (error) {
    return {} as D;
  }
}
