{
  "anonymizerSettings": {
    "anonymize_sensitive_data": "Sensible Daten anonymisieren",
    "language": "Sprache",
    "language_of_the_data": "Sprache der Daten",
    "replace": "Ersetzen",
    "replace_value": "Wert ersetzen",
    "define_what_entities_must_be_anonymized": "Definieren, welche Entitäten anonymisiert werden müssen",
    "anonymize_entities": "Entitäten anonymisieren"
  }
}