import { gptWidgetSettings } from '@/components/project/settings/defaults/gptWidgetSettings';
import { SettingsPropertiesEnum } from '@/types/SettingsPropertiesEnum';
import { ChatGptModelNamesEnum } from '@/types/ChatGptModelNamesEnum';

export const mindmapWidgetSettings = {
  ...gptWidgetSettings,
  [SettingsPropertiesEnum.MODELNAME]: ChatGptModelNamesEnum.GPT_4_TURBO_128K,
  [SettingsPropertiesEnum.CUSTOMPROMPT]: '',
  [SettingsPropertiesEnum.FORM_LANGUAGE]: 'English',
  [SettingsPropertiesEnum.USECUSTOMPROMPT]: false,
};
