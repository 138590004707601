{
  "airtableInformer": {
    "airtable_informer": "Airtable",
    "learn": "Lernen"
  },
  "audioInformer": {
    "audio_informer": "Audio",
    "learn": "Lernen"
  },
  "baseInformer": {
    "learn": "Lernen"
  },
  "bulkcsvInformer": {
    "csv_links_informer": "CSV-Links",
    "learn": "Lernen"
  },
  "chromeextensionInformer": {
    "chrome_extension_informer": "Chrome-Erweiterung",
    "select_project": "Projekt auswählen",
    "how_to_add_extension": "So fügen Sie die Chrome-Erweiterung hinzu und verwenden sie.",
    "visit_the_chrome_web_store": "Besuchen Sie den Chrome Web Store und suchen Sie nach der",
    "extension": "Erweiterung",
    "click": "Klicken",
    "add_to_chrome": "Zu Chrome hinzufügen",
    "authorize_yourself_in_the_extension": "Autorisieren Sie sich in der Erweiterung, klicken Sie auf Anmelden",
    "if_you_dont_have_any_existing_projects": "Wenn Sie keine vorhandenen Projekte haben, können Sie eines erstellen. Klicken Sie auf den bereitgestellten Link zu OmniMind und erstellen Sie Ihr Projekt.",
    "to_add_the_entire_web_page": "Um die gesamte Webseite hinzuzufügen, wählen Sie Ihr gewünschtes Projekt aus und klicken Sie auf die Schaltfläche \"Verarbeiten\" in der Erweiterung",
    "to_add_selected_text": "Um ausgewählten Text hinzuzufügen, markieren Sie den Text auf der Webseite, klicken Sie mit der rechten Maustaste und wählen Sie \"Ausgewählten Text zum OmniMind-Projekt hinzufügen\" im Kontextmenü.",
    "the_content_you_added_will_be_stored": "Der von Ihnen hinzugefügte Inhalt wird in Ihrem ausgewählten Projekt gespeichert. Sie können diese Informationen jetzt verwenden, wenn Sie mit dem KI-Modell interagieren."
  },
  "confluenceInformer": {
    "confluence_informer": "Confluence",
    "learn": "Lernen"
  },
  "csvInformer": {
    "csv_informer": "CSV",
    "learn": "Lernen"
  },
  "excelInformer": {
    "excel_informer": "Excel",
    "learn": "Lernen"
  },
  "fileInformer": {
    "file_informer": "Datei",
    "learn": "Lernen"
  },
  "googleSheetsInformer": {
    "google_sheets_informer": "Google Sheets",
    "learn": "Lernen"
  },
  "notionInformer": {
    "notion_informer": "Notion",
    "learn": "Lernen"
  },
  "pdfOcrInformer": {
    "pdf_ocr_informer": "PDF",
    "learn": "Lernen"
  },
  "singleWebInformer": {
    "single_web_informer": "Einzelne Webseite",
    "learn": "Lernen"
  },
  "slackIntegrationIndex": {
    "name": "Name",
    "select_project": "Projekt auswählen",
    "how_to_add_omnimind_app": "So fügen Sie die Omnimind-App zu Slack Workspace hinzu",
    "start_by_copying_your_omnimind_api_key": "Beginnen Sie damit, Ihren Omnimind-API-Schlüssel zu kopieren. Sie benötigen diesen, um Omnimind mit Slack zu verbinden.",
    "make_sure_your_question_is": "Stellen Sie sicher, dass Ihre Frage klar und präzise ist, um die genauesten Antworten zu erhalten. Sie können das Projekt in den Direktnachrichten des Bots aktualisieren oder ändern, wenn Sie unterschiedliche Daten für die Analyse verwenden möchten.",
    "copy_your_project_id": "Kopieren Sie Ihre Projekt-ID",
    "project_id_was_copied": "Projekt-ID wurde kopiert!",
    "add_omnimind_ai_to_your_slack_workspace": "Fügen Sie die Omnimind-KI zu Ihrem Slack Workspace hinzu",
    "configure_your_omnimind_app": "Konfigurieren Sie Ihre Omnimind-App, indem Sie Anmeldeinformationen festlegen",
    "click_connect_to_omnimind_button": "Klicken Sie auf die Schaltfläche \"Mit Omnimind verbinden\"",
    "paste_your_api_key_and_project_id": "Fügen Sie Ihren API-Schlüssel und Ihre Projekt-ID ein",
    "start_asking_your_question": "Stellen Sie Ihre Frage!"
  },
  "vimeoInformer": {
    "vimeo_informer": "Vimeo",
    "learn": "Lernen"
  },
  "webInformer": {
    "multiple_web_informer": "Mehrere Webseiten",
    "learn": "Lernen"
  },
  "whatsappIntegration": {
    "name": "Name",
    "select_project": "Projekt auswählen"
  },
  "wordpressInformer": {
    "wordpress_informer": "Wordpress",
    "learn": "Lernen"
  },
  "youtubeInformer": {
    "youtube_informer": "Youtube",
    "learn": "Lernen"
  },
  "zapierWidget": {
    "name": "Name",
    "select_project": "Projekt auswählen",
    "how_to_add_omnimind_app": "So fügen Sie die Omnimind-App zu Zapier hinzu",
    "start_by_copying_your_omnimind_api_key": "Beginnen Sie damit, Ihren Omnimind-API-Schlüssel zu kopieren. Sie benötigen diesen, um Omnimind mit Zapier zu verbinden.",
    "make_sure_your_question_is_clear_and_concise": "Stellen Sie sicher, dass Ihre Frage klar und präzise ist, um die genauesten Antworten zu erhalten. Sie können das Projekt in den Aktionseinstellungen aktualisieren oder ändern, wenn Sie unterschiedliche Daten für die Analyse verwenden möchten.",
    "go_to_the_zapier_application_where_you_d_like_to": "Gehen Sie zur Zapier-Anwendung, in der Sie Omnimind verwenden möchten.",
    "create_a_new_action_step": "Erstellen Sie einen neuen Aktionsschritt und suchen Sie nach der Omnimind-Integration.",
    "in_the_omnimind_app": "In der Omnimind-App wählen Sie die Aktion mit dem Namen \"Omni Ask\" aus.",
    "log_into_your_omnimind_account": "Melden Sie sich mit dem zuvor kopierten API-Schlüssel in Ihrem Omnimind-Konto an.",
    "in_the_action_tab": "Geben Sie die erforderlichen Informationen ein.",
    "from_the_project_dropdown": "Wählen Sie aus dem Projektdropdown das Projekt aus, mit dem Sie sich verbinden möchten.",
    "enter_the_question_you_d_like_to": "Geben Sie die Frage ein, die Sie an Omnimind senden möchten.",
    "before_finalizing": "Testen Sie vor der endgültigen Bestätigung Ihre Aktion, um sicherzustellen, dass alles reibungslos funktioniert.",
    "in_zapier": "In Zapier wählen Sie das Feld \"Antwort\" aus der Omnimind-App aus. Sie können diese Antwort in Ihren nachfolgenden Schritten verwenden."
  }
}