{
  "databaseSettings": {
    "databaseSettings": "Datenbankeinstellungen",
    "configure_the_connection_to_your_database": "Konfigurieren Sie die Verbindung zu Ihrer Datenbank und wählen Sie die Tabellen aus, die Sie in Ihren Abfragen verwenden möchten.",
    "cancel": "Abbrechen",
    "back": "Zurück",
    "save": "Speichern",
    "fetch_resources": "Ressourcen abrufen",
    "resources_fetched_successfully": "Ressourcen erfolgreich abgerufen",
    "something_went_wrong": "Etwas ist schiefgelaufen",
    "failed_to_load_settings": "Fehler beim Laden der Einstellungen"
  },
  "databaseSettingsConnectInformation": {
    "connect_information": "Verbindungsinformationen",
    "type_of_database": "Datenbanktyp",
    "host": "Host",
    "port": "Port",
    "database": "Datenbank",
    "username": "Benutzername",
    "password": "Passwort"
  },
  "databaseSettingsForm": {
    "host": "Host"
  },
  "databaseSettingsHost": {
    "type_of_database": "Datenbanktyp",
    "select_type_of_connection": "Verbindungstyp auswählen",
    "host": "Host",
    "address_to_your_database": "Adresse Ihrer Datenbank",
    "address_of_your_db": "Adresse Ihrer DB",
    "port_to_your_database": "Port Ihrer Datenbank",
    "port_of_your_db": "Port Ihrer DB",
    "port": "Port",
    "username": "Benutzername",
    "username_to_your_database": "Benutzername Ihrer Datenbank",
    "username_of_your_db": "Benutzername Ihrer DB",
    "password": "Passwort",
    "password_to_your_database": "Passwort Ihrer Datenbank",
    "password_of_your_db": "Passwort Ihrer DB",
    "database": "Datenbank",
    "your_database_name": "Ihr Datenbankname",
    "database_name": "Name Ihrer Datenbank in der DB"
  },
  "databaseSettingsTables": {
    "general_instructions": "Allgemeine Anweisungen",
    "there_goes_general_description_about": "Hier steht eine allgemeine Beschreibung der Datenbank, ihrer Logik oder einiger projektspezifischer Details...",
    "select_all": "Alle auswählen",
    "clean_all_selected": "Alle ausgewählten löschen",
    "search": "Suchen...",
    "active": "Aktiv",
    "table": "Tabelle",
    "comment": "Kommentar",
    "description": "Beschreibung"
  },
  "databaseSettingsURL": {
    "host": "Host",
    "address_to_your_database": "Adresse Ihrer Datenbank",
    "address_of_your_db": "Adresse Ihrer DB"
  }
}