{
  "baseAiHelp": {
    "book_call": "Записати дзвінок",
    "hide": "Приховати"
  },
  "baseModal": {
    "ok": "Ок",
    "cancel": "Скасувати",
    "delete": "Видалити"
  },
  "baseModalFooter": {
    "cancel_text": "Скасувати",
    "submit_text": "Зберегти"
  },
  "baseDatePicker": {
    "to": "до"
  },
  "basePagination": {
    "first": "Перша",
    "prev": "Попередня",
    "next": "Наступна",
    "last": "Остання"
  },
  "baseSelect": {
    "choose": "Вибрати...",
    "select_all": "Вибрати все"
  },
  "baseSelectTable": {
    "select_all": "Вибрати все",
    "wordpress_site_types": "ТИПИ САЙТІВ WORDPRESS"
  },
  "baseSpeechToTextButton": {
    "hold_and_ask_your_question": "Утримуйте та задайте своє питання. Уточніть мову, якщо потрібно, натиснувши кнопку 'Додаткові параметри'"
  },
  "baseStepper": {
    "previous": "Попередня",
    "next": "Наступна"
  },
  "baseTextToSpeechButton": {
    "text_to_voice": "Текст в голос",
    "text_to_speech_syntesis": "Синтез мовлення не підтримує мову {languageValue}"
  },
  "baseUnsavedModal": {
    "all_your_unsaved_changes": "Всі ваші незбережені зміни будуть втрачені. Збережіть зміни перед виходом зі сторінки?",
    "save_changes": "Зберегти зміни",
    "you_have_unsaved_changes": "У вас є незбережені зміни"
  },
  "buttonInputViewCopyText": {
    "copied_to_clipboard": "Скопійовано в буфер обміну!"
  }
}