{
  "validations": {
    "required": "Este campo é obrigatório",
    "isOldPasswordCorrect": "Por favor, insira uma senha antiga válida",
    "email": "Por favor, insira um endereço de email válido",
    "url": "Por favor, insira um URL válido",
    "URL": "Por favor, insira um URL válido",
    "min": "Por favor, insira pelo menos {min} caracteres",
    "integer": "Por favor, insira um valor inteiro",
    "minValue": "O valor deve ser pelo menos {min}",
    "maxValue": "O valor deve ser no máximo {max}",
    "max": "Por favor, insira no máximo {max} caracteres",
    "minLength": "Por favor, insira pelo menos {min} caracteres",
    "maxLength": "Por favor, insira no máximo {max} caracteres",
    "requiredIf": "Este campo é obrigatório",
    "sameAs": "O valor deve ser igual ao outro valor"
  },
  "promocode": {
    "the_promocode_was_successfully_redeemed": "O código promocional foi resgatado com sucesso. Para mais detalhes, por favor, verifique suas estatísticas de faturamento",
    "unable_to_redeem_the_promo_code": "Não foi possível resgatar o código promocional. Por favor, verifique o código quanto a erros de digitação e tente novamente, ou entre em contato com o suporte para obter assistência."
  }
}
