{
  "portalProjectItem": {
    "your_link_name": "Ваше ім'я посилання",
    "delete_this_project": "Видалити цей проект?",
    "delete_project": "Видалити проект",
    "project_will_be_deleted": "Проект буде видалено. Цю дію неможливо скасувати"
  },
  "portalProjects": {
    "no_projects_so_far": "Поки що немає проектів",
    "apply": "Застосувати",
    "link_name": "Ім'я посилання",
    "project": "Проект",
    "widget": "Віджет",
    "actions": "Дії",
    "the_item_has_been_successfully_deleted": "Елемент успішно видалено.",
    "projects_successfully_updated": "Проекти успішно оновлено."
  }
}