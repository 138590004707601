{
  "databaseSettings": {
    "databaseSettings": "Configurações do Banco de Dados",
    "configure_the_connection_to_your_database": "Configure a conexão com o seu banco de dados e selecione as tabelas que deseja usar em suas consultas.",
    "cancel": "Cancelar",
    "back": "Voltar",
    "save": "Salvar",
    "fetch_resources": "Buscar recursos",
    "resources_fetched_successfully": "Recursos buscados com sucesso",
    "something_went_wrong": "Algo deu errado",
    "failed_to_load_settings": "Falha ao carregar as configurações"
  },
  "databaseSettingsConnectInformation": {
    "connect_information": "Informações de Conexão",
    "type_of_database": "Tipo de Banco de Dados",
    "host": "Host",
    "port": "Porta",
    "database": "Banco de Dados",
    "username": "Nome de Usuário",
    "password": "Senha"
  },
  "databaseSettingsForm": {
    "host": "Host"
  },
  "databaseSettingsHost": {
    "type_of_database": "Tipo de Banco de Dados",
    "select_type_of_connection": "Selecione o tipo de conexão",
    "host": "Host",
    "address_to_your_database": "endereço do seu banco de dados",
    "address_of_your_db": "Endereço do seu BD",
    "port_to_your_database": "porta do seu banco de dados",
    "port_of_your_db": "Porta do seu BD",
    "port": "Porta",
    "username": "Nome de Usuário",
    "username_to_your_database": "nome de usuário do seu banco de dados",
    "username_of_your_db": "Nome de Usuário do seu BD",
    "password": "Senha",
    "password_to_your_database": "senha do seu banco de dados",
    "password_of_your_db": "Senha do seu BD",
    "database": "Banco de Dados",
    "your_database_name": "nome do seu banco de dados",
    "database_name": "Nome do seu banco de dados no BD"
  },
  "databaseSettingsTables": {
    "general_instructions": "Instruções Gerais",
    "there_goes_general_description_about": "Aqui vai uma descrição geral sobre o banco de dados, sua lógica ou detalhes específicos do projeto...",
    "select_all": "Selecionar todos",
    "clean_all_selected": "Limpar todos selecionados",
    "search": "Buscar...",
    "active": "Ativo",
    "table": "Tabela",
    "comment": "Comentário",
    "description": "Descrição"
  },
  "databaseSettingsURL": {
    "host": "Host",
    "address_to_your_database": "endereço do seu banco de dados",
    "address_of_your_db": "Endereço do seu BD"
  }
}