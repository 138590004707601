{
  "pagesProjectsEmpty": {
    "empty_project": "Порожній проект",
    "is_not_complete_yet": "ще не завершений, оскільки він не має знань. Ви можете завантажити знання або просто видалити проект.",
    "add_knowledge": "Додати знання",
    "this_project": "Цей проект"
  },
  "pagesProjectsIndex": {
    "redirecting": "Перенаправлення..."
  }
}