import { nhost } from "@/lib/nhost";

export const getToolData = async (payload: any) => {
  const { slug, method } = payload;
  const response = await fetch(
    `${import.meta.env.VITE_NHOST_BACKEND_URL}/v1/functions/tools/slug/${slug}/properties/${method}`,
    {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${nhost.auth.getAccessToken()}`,
      },
    },
  );
  if (response?.ok) {
    return await response.json();
  }
  return [];
};
