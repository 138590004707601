export enum DialogSavingOptionsEnum {
  ALL = 'Track all messages',
  NEGATIVE = 'Track only messages with negative feedback or turning to support',
  NONE = 'Do not track',
}

export enum DialogExternalUserOptionsEnum {
  FORM = 'Collect username and email with form',
  PROVIDE = 'Provide username and email by yourself',
  NONE = 'Do not collect username and email',
}
