{
  "marketplaceOption": {
    "purchase": "Acquista",
    "characters": "personaggi",
    "informers": "informatore",
    "projects": "progetti",
    "EssentialBoost": "Essential Boost",
    "PremiumExpansion": "Premium Expansion",
    "UltimateAdvantage": "Ultimate Advantage",
    "Projects10": "Progetti 10",
    "EssentialBoostDescription": "Eleva il tuo account con ulteriori $50, sbloccando nuove possibilità e migliorando la tua esperienza.",
    "PremiumExpansionDescription": "Ulteriori richieste di domande-risposte 1000",
    "UltimateAdvantageDescription": "Trasforma il tuo account con ulteriori $500, offrendo un accesso senza precedenti e funzionalità supreme.",
    "Projects10Description": "Progetti aggiuntivi"
  }
}