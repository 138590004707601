<template>
  <div
    class="w-full border-t border-t-white-white100 dark:border-t-dark-blue100"
    :class="{ '!border-[var(--omni-color-secondary)]': isPortalRoute }"
  >
    <div class="px-4">
      <div>
        <h3
          class="overview-heading mb-2 mt-3 flex justify-center text-lg text-sm dark:text-white"
          :class="{ '!text-[var(--omni-color-text)]': isPortalRoute }"
        >
          {{ $t('billingOverview.billing_overview') }}
        </h3>
        <div>
          <!--          <BillingOverviewItem-->
          <!--            name="Requests GPT"-->
          <!--            :total="requests.total"-->
          <!--            :current="requests.current"-->
          <!--          />-->

          <!--    <h3 class="mb-2 mt-2 flex justify-center text-lg dark:text-white">Billing overview</h3>
    <div v-if="rights && Object.keys(rights)">
      <h4 class="mb-2 flex items-end justify-between">
        Requests GPT
        <span class="ml-1 text-xs dark:text-white">
        {{ formatNumber(requests.total) }}
      </span>
      </h4>
      <div
        v-tippy="{
        content: `Used ${requests.current} of ${requests.total}`,
        placement: 'top',
        touch: false,
      }"
        class="relative mb-4 h-3 rounded-full bg-[#ebedf2] dark:bg-dark/40"
      >
        <div
          class="h-full rounded-full bg-primary text-center text-xs leading-none text-white"
          :style="{
          width: `${getProgress(requests.current, requests.total)}%`,
        }"
        ></div>
        <span
          class="absolute bottom-0 left-0 right-0 top-0 text-center text-xs leading-none dark:text-white"
        >
        {{ formatNumber(requests.current) }}
      </span>
      </div>
      -->

          <BillingOverviewItem
            :name="$t('billingOverview.expenses')"
            :total="total"
            :current="current"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ComputedRef } from 'vue';
import { computed, inject, watch } from 'vue';
import { useAuthorization } from '@/plugins/authorization';
import BillingOverviewItem from '@components/profile/info/BillingOverviewItem.vue';
import { useAppStore } from '@/stores';

const { units_spent, units_amount } = useAuthorization();

const isPortalRoute = inject<ComputedRef<boolean>>('is-portal-route');

const total = computed(() => {
  return Number(units_amount.value) || 0;
});

const current = computed(() => {
  return Number(units_spent.value) || 0;
});

const store = useAppStore();

watch(
  () => [units_spent.value, units_amount.value],
  () => {
    store.setIsPaid((Number(units_amount.value) - Number(units_spent.value)) >= 0);
  }
)
</script>
