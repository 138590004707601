{
  "auth": {
    "changePassword": {
      "change_password": "Passwort ändern",
      "enter_password": "Passwort eingeben",
      "new_password": "Neues Passwort",
      "password": "Passwort",
      "old_password": "Altes Passwort",
      "cancel": "Abbrechen",
      "password_confirmation": "Neues Passwort bestätigen",
      "success": "Passwort erfolgreich geändert"
    },
    "changePasswordForm": {
      "new_password": "Neues Passwort",
      "enter_password": "Passwort eingeben",
      "password_confirmation": "Passwortbestätigung",
      "change_password": "Passwort ändern"
    },
    "portalProvider": {
      "portal": "Portal",
      "portal_not_found": "Portal nicht gefunden",
      "is_not_currently_active": "ist derzeit nicht aktiv",
      "you_are_currently_blocked": "Sie sind derzeit blockiert"
    },
    "promocode": {
      "something_went_wrong": "Etwas ist schiefgelaufen",
      "enjoy_benefits": "Profitieren Sie von einem Einlösecode!",
      "redemption_code": "Einlösecode",
      "enter_redemption_code": "Einlösecode",
      "verifying": "Überprüfen...",
      "verify": "Überprüfen",
      "create_account_in_omnimind": "Erstellen Sie ein Konto bei Omnimind AI, um gekaufte Guthaben zu erhalten",
      "this_promo_code_does_not_seem_to_exist": "Dieser Promo-Code scheint nicht zu existieren. Bitte überprüfen Sie auf Tippfehler und versuchen Sie es erneut.",
      "this_promo_code_has_expired": "Dieser Promo-Code ist abgelaufen. Versuchen Sie es mit einem anderen Code."
    },
    "resetPasswordForm": {
      "reset_password": "Passwort zurücksetzen",
      "enter_your_email_for_resetting_password": "Geben Sie Ihre E-Mail-Adresse ein, um das Passwort zurückzusetzen",
      "enter_email": "E-Mail-Adresse eingeben",
      "please_check_your_mailbox": "Bitte überprüfen Sie Ihren Posteingang und \n folgen Sie dem Anweisungslink, um die Passwortänderung abzuschließen.",
      "if_you_can_not_find_it": "Wenn Sie es nicht in Ihrem Posteingang finden können, überprüfen Sie Ihren",
      "spam_folder": "Spam-Ordner",
      "as_well": "auch."
    },
    "signOut": {
      "sign_out": "Abmelden"
    }
  }
}
