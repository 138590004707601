{
  "settingsIntegrations": {
    "integrations": "Інтеграції",
    "please_note_that_any_activity_of_the_users": "Зверніть увагу, що будь-яка активність користувачів, з якими ви поділитеся цим посиланням, пов'язана з балансом вашого облікового запису.",
    "embed": "Вбудувати",
    "messengers": "Месенджери",
    "integration_confirmation": "Підтвердження інтеграції",
    "please_note_that_any_activity": "Зверніть увагу, що будь-яка активність користувачів, з якими ви поділитеся цим посиланням, пов'язана з балансом вашого облікового запису.",
    "copy_link": "Скопіювати посилання",
    "share_on": "Поділитися на",
    "widget_link_copied_to_clipboard": "Посилання на віджет скопійовано в буфер обміну",
    "personal_shared": "особистий / спільний",
    "share": "Поділитися",
    "on": "Активувати",
    "off": "Деактивувати"
  }
}