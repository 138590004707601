{
  "popupChatWidget": {
    "clear_chat": "Clear chat",
    "close": "Close",
    "processing_your_request": "Processing your request...",
    "please_ask_the_owner_of_the_project_to_make_it_public": "Please ask the owner of the project to make it public",
    "the_project_owner_needs_to_upgrade": "The project owner needs to upgrade subscription as the current one has expired.",
    "oops_we_have_t_found": "404 Ooops, we have't found the project",
    "oops_it_seems_youve_reached": "Oops! It seems you've reached your message rate limit. To continue, kindly adjust your rate limit in the settings."
  }
}