{
  "pricingItem": {
    "month": "mese",
    "up_to_todo_calculate_pricing_info": "Fino a 'TODO: Calcola le informazioni sui prezzi'",
    "trial_days": "giorni di prova",
    "this_includes:": "Questo include",
    "subscribe:": "Iscriviti"
  },
  "pricingTable": {
    "subscriptions": "Abbonamenti",
    "to_access_the_widget_you_need": "Per accedere al widget è necessario scegliere uno dei piani di abbonamento offerti",
    "oops": "Ops!",
    "there_no_available_packages": "Non ci sono pacchetti disponibili per questo link"
  }
}