{
  "explainWidget": {
    "we_are_creating_suggestion_questions": "We are creating suggestion questions for you. But You can use the chat now",
    "sorry_we_could_not_create_any_examples": "Sorry, we could not create any examples of questions that you can ask the bot about yet.",
    "loading": "Loading...",
    "explanation": "Explanation",
    "please_ask_the_owner_of_the": "Please ask the owner of the project to make it public",
    "the_project_owner_needs_to": "The project owner needs to upgrade subscription as the current one has expired.",
    "404_oops_we_havet_found": "404 Ooops, we have't found the project",
    "oops_it_seems_youve_reached": "Oops! It seems you've reached your message rate limit. To continue, kindly adjust your rate limit in the settings."
  }
}
