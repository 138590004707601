{
  "userInformation": {
    "general_information": "Information bearbeiten",
    "full_name": "Vollständiger Name",
    "enter_full_name": "Vollständigen Namen eingeben",
    "email": "E-Mail",
    "enter_email": "E-Mail eingeben",
    "company": "Unternehmen",
    "enter_company": "Unternehmen eingeben",
    "phone": "Telefon",
    "enter_phone_number": "Telefonnummer eingeben",
    "interface_language": "Sprache der Benutzeroberfläche",
    "select_interface_language": "Sprache der Benutzeroberfläche auswählen",
    "timezone": "Zeitzone",
    "select_your_timezone": "Ihre Zeitzone auswählen",
    "cancel": "Abbrechen",
    "avatar": "Avatar",
    "upload_photo": "Foto hochladen",
    "save": "Speichern",
    "this_field_contains_special_characters": "Dieses Feld enthält Sonderzeichen",
    "your_personal_data_was_updated": "Ihre persönlichen Daten wurden aktualisiert!",
    "entered_phone_number_is_already_in_use": "Die eingegebene Telefonnummer wird bereits verwendet",
    "invalid_form": "Einige Felder fehlen oder sind falsch. Bitte überprüfen Sie die markierten Felder und versuchen Sie es erneut.",
    "confirmation_email_sent": "Eine Bestätigungs-E-Mail für Ihre E-Mail-Änderung wurde an Ihre neue E-Mail-Adresse gesendet"
  }
}