import { nhost } from '@/lib/nhost';

export const getAllToolsList = async () => {
  const response = await fetch(
    `${import.meta.env.VITE_NHOST_BACKEND_URL}/v1/functions/tools/list`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${nhost.auth.getAccessToken()}`,
      },
    },
  );
  if (response?.ok) {
    return await response.json();
  }
  return [];
};
