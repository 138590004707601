{
  "cancelChain": {
    "cancel": "Annulla",
    "yes": "Sì",
    "no": "No",
    "stop_processing": "Interrompi elaborazione",
    "do_you_want_to_stop": "Vuoi interrompere tutte le catene di elaborazione?"
  },
  "progressIndex": {
    "no_resources_so_far_redirecting_to_informers": "Nessuna risorsa finora. Reindirizzamento agli informatori...",
    "an_error_occured_while_deleting": "Si è verificato un errore durante l'eliminazione. Riprova"
  }
}