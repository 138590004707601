import gql from 'graphql-tag';

export const GET_WIDGET_SETTINGS = gql`
  query ($id: uuid!) {
    widgets_by_pk(id: $id) {
      settings
      type_id
    }
  }
`;
