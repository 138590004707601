{
  "chatGptAlpha": {
    "semantic_balance_option": "Semantic balance option",
    "full_text_search": "Full text search",
    "full_vector_search": "Full vector search"
  },
  "chatGptCreativity": {
    "here_you_can_specify_the_creativity": "Here you can specify the creativity level for the model. The closer the value to zero the more creative the model but it doesn't stick directly to the context provided in the uploaded data. The closer the value to one and the model sticks directly to the provided data.",
    "context_proof_level": "Context proof level",
    "trying_to_suppose_with_general_information": "Trying to suppose with general information",
    "answering_only_using_project_data": "Answering only using project data"
  },
  "chatGptCreativityExamples": {
    "example_question": "Example question",
    "how_many_people_live_on_mars_now": "How many people live on Mars now?",
    "example_answer": "Example answer",
    "absolutely_not_only_do_people_live_on_mars": "Absolutely! Not only do people live on Mars nowadays, but they have transformed it into a vibrant metropolis. The Martian cityscape boasts magnificent domed structures, futuristic transportation systems, and breathtaking gardens that defy the laws of nature. The inhabitants, known as 'Marsonauts,' have developed telepathic abilities and communicate with the indigenous Martian species. They have also discovered the secret to eternal youth through a unique blend of Martian herbs and cosmic energy.",
    "while_humans_do_not_currently_live_on_mars": "While humans do not currently live on Mars, there have been significant advancements in space exploration that bring us closer to that possibility. Plans and concepts for Mars colonization have been developed, outlining strategies for sustainable habitats, resource utilization, and long-term survival. Ongoing research and technological developments aim to address challenges such as radiation exposure, limited resources, and the psychological effects of isolation. It is an exciting time as we lay the groundwork for potential human settlement on the red planet.",
    "at_present_humans_do_not_reside_on_mars": "At present, humans do not reside on Mars. However, numerous space agencies and private companies are actively working on missions and projects that could lead to future human habitation. Advanced robotic missions have been deployed to assess the planet's resources, climate, and potential landing sites. Innovative ideas, such as 3D-printed habitats, underground colonies, and terraforming technologies, are being explored to make Mars more habitable. The dream of living on Mars is fueling creative solutions and pushing the boundaries of human exploration.",
    "no_as_of_my_knowledge_cutoff_in_september": "No, as of my knowledge cutoff in September 2021, there are no people living on Mars. While there have been successful robotic missions to Mars, including the Perseverance rover, which is currently exploring the planet, human habitation on Mars is still in the realm of future possibilities. Extensive research and planning are being conducted to make long-term human missions to Mars feasible, but it has not yet been realized."
  },
  "chatGptHybrid": {
    "if_you_need_a_simple_search_in_the_classical": "If you need a simple search in the classical way matching just the words in the provided data then you have to move the slider closer to zero. On the contrary if you want the search to be more like a natural language processing looking up word forms taking into consideration synonyms, context and related concepts then you have to move the slider closer to one.",
    "semantic_balance_option": "Semantic balance option",
    "classic_text_search": "Classic text search",
    "semantic_vector_search": "Semantic vector search"
  },
  "chatGptLimit": {
    "here_you_can_define_the_maximum": "Here you can define the maximum number of documents (or rather chunks) that will be used to generate the answer.",
    "limit_number_of_documents": "Limit (Number of Documents)",
    "minimum": "Minimum",
    "maximum": "Maximum"
  },
  "chatGptModel": {
    "gpt_model_which_is_used_to_answer_the_questions": "GPT model which is used to answer the questions",
    "click_here_to_add_open_ai_api_key": "Click here to add OpenAI API key",
    "please_choose_the_model": "Please, choose the model that you want to use. GPT-4 model is more robust and provides more precise answers but slower and more expensive whereas GPT 3.5 is faster and suits most cases.",
    "gpt_4_model_is_available_in_your_plan": "GPT-4 model is available in your plan! To access it, please obtain your own OpenAI API key. You can set this up easily by visiting your profile page."
  },
  "chatGptSliderRange": {
    "optimal": "Optimal"
  }
}