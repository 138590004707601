{
  "mindmapLoader": {
    "something_went_awry": "Algo deu errado!",
    "please_wait_while_we_generate_your": "Aguarde enquanto geramos o seu",
    "attempt": "Tentativa"
  },
  "topicsModal": {
    "select_mind_map_topic": "Selecione o tópico do mapa mental",
    "wait_while_we_generate_topics": "Aguarde enquanto geramos tópicos com base no contexto do seu projeto",
    "your_custom_topic": "Seu tópico personalizado"
  }
}