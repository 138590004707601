{
  "tenantPortalDeleteUserModal": {
    "delete_this_user": "Eliminare questo utente?",
    "delete_user": "Elimina utente",
    "user_will_be_deleted": "L'utente sarà eliminato dalla lista."
  },
  "tenantPortalInviteModal": {
    "invite_user": "Invita utente",
    "apply": "Applica",
    "email": "Email",
    "promocode": "Codice promozionale",
    "enter_your_promo_code": "Inserisci il tuo codice promozionale",
    "message": "Messaggio",
    "enter_message_text": "Inserisci il testo del messaggio...",
    "the_invite_has_been_successfully_sent": "L'invito è stato inviato con successo!",
    "the_error_occurred_while_sending_an_email": "Si è verificato un errore durante l'invio di una email"
  },
  "tenantPortalPortalUsers": {
    "no_users_so_far": "Nessun utente finora",
    "invite": "Invita",
    "email": "Email",
    "packages": "Pacchetti",
    "status": "Stato",
    "actions": "Azioni"
  },
  "tenantPortalPortalUsersItem": {
    "user_was_deleted": "L'utente è stato eliminato"
  },
  "tenantPortalPortalUserModal": {
    "edit_user": "Modifica utente",
    "apply": "Applica",
    "email": "Email",
    "package": "Pacchetto",
    "status": "Stato"
  }
}