{
  "youtubeInformer": {
    "please_provide_a_valid_vimeo_video_url": "Please, provide a valid Vimeo video url",
    "tracks_are_unavailable_due_to_restrictions": "This video's tracks are unavailable due to restrictions",
    "something_went_awry": "Something went awry",
    "no_text_tracks": "This video has no text tracks!",
    "start_time_cant_be_greater": "Start time can't be greater than or equal end time"
  },
  "youtubeInformerForm": {
    "insert_youtube_video_url": "Insert Youtube Video URL",
    "fetch_subtitles": "Fetch subtitles"
  },
  "youtubeInformerList": {
    "available_tracks": "Available tracks"
  },
  "youtubeInformerPreview": {
    "please_adjust_the_sliders_below": "Please adjust the sliders below to select a range for cutting subtitles from your video."
  }
}
