{
  "widgetsComponentDeleteWidgetModal": {
    "delete": "Löschen",
    "widget": "Widget",
    "do_you_really_want_to_remove": "Möchten Sie das Widget wirklich entfernen",
    "widget_from": "Widget von",
    "you_can_always_add_it_any_time_later": "Sie können es jederzeit später hinzufügen."
  },
  "widgetsComponentPasswordProjectModal": {
    "password_is_required": "Passwort ist erforderlich",
    "enter_the_project_password": "Geben Sie das Projekt-Passwort ein",
    "submit": "Senden"
  }
}