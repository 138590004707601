<template>
  <button
    type="button"
    class="treeItem my-1 flex w-full cursor-pointer items-center gap-2 py-2 pl-5 pr-3"
    :class="{ itemSelected: isSelected }"
    @click="handleClick"
  >
    <BaseSvgIcon
      :name="iconName"
      class="!h-7 !w-7"
      :class="isSelected ? 'text-primary' : 'text-white-steel-blue-gray'"
    />
    <p class="flex-grow text-left text-sm" :class="{ 'dark:text-[#fff]': !isSettingItem }">
      {{ title }}
    </p>
    <slot />
  </button>
</template>

<script setup lang="ts">
import { useAppStore } from '@/stores';
import { useRouter } from 'vue-router';
import BaseSvgIcon from '@components/base/BaseSvgIcon.vue';

const props = withDefaults(
  defineProps<{
    iconName?: string;
    title: string;
    isSelected?: boolean;
    route?: string;
    isSettingItem?: boolean;
  }>(),
  {
    isSelected: false,
    isSettingItem: false,
    iconName: '',
    route: undefined,
  },
);

const router = useRouter();
const store = useAppStore();

const handleClick = () => {
  if (props.route) {
    if (store.isSettingsChanges) {
      store.setUnsavedModalOpen(true);
      store.setUnsavedSettingsCancelRedirect(props.route);
      return;
    }

    router.push(props.route);
  }
};
</script>
<style scoped>
.itemSelected,
.treeItem:hover {
  background-color: rgba(159, 161, 160, 0.058);
}
</style>
