{
  "useWebPreview": {
    "do_you_see_relevant_content_from_url": "Sehen Sie relevante Inhalte von der URL? Wenn nicht, versuchen Sie es mit einer alternativen Methode zum Abrufen.",
    "could_not_get_the_preview": "Vorschau konnte nicht abgerufen werden. Bitte versuchen Sie es mit einer anderen Methode.",
    "alternative_fetch": "Alternative Abrufmethode",
    "we_tried_to_fetch_the_content_as_it_would_be": "Wir haben versucht, den Inhalt so abzurufen, wie er im Chrome-Browser angezeigt würde. Wenn Sie immer noch nicht zufrieden sind, klicken Sie auf \"Alternative Text\".",
    "alternative_text": "Alternativer Text",
    "we_have_demonstrated_all_the_ways": "Wir haben alle möglichen Methoden demonstriert, um den Inhalt abzurufen. Möchten Sie zur ursprünglichen Methode zurückkehren und ihn anzeigen?",
    "back_to_origin": "Zurück zur ursprünglichen Methode"
  },
  "useMaximumNumberOfProjectsAlert": {
    "limit_error": "Es scheint, als hätten Sie die maximale Anzahl an Projekten erreicht. Um ein neues Projekt zu starten, sollten Sie vorhandene Projekte archivieren oder löschen, die Sie nicht mehr benötigen, oder unsere Abonnementpläne für höhere Limits erkunden.",
    "upgrade": "Upgrade"
  },
  "Preview": "Vorschau"
}