{
  "addFileDrop": {
    "drag_drop_or": "Drag & Drop or",
    "select_files": "Select files",
    "to_upload": "to upload",
    "error_uploading_file": "Error uploading file",
    "error_uploading_files": "Error uploading files"
  },
  "resourcesProject": {
    "show_uploaded_resources": "Show uploaded resources",
    "knowledge": "Knowledge"
  },
  "resourcesProjectListItem": {
    "click_here_to_show_hide_explore_the_pages": "Click here to {value} the pages!",
    "delete": "Delete",
    "chunks": "chunks",
    "cancel": "Cancel",
    "resource": "Resource",
    "size": "Size",
    "updated": "Updated",
    "page_content": "Page Content"
  },
  "resourcesProjectModal": {
    "knowledge": "Knowledge",
    "save": "Save",
    "search_for_uploaded_knowledge": "Search for uploaded knowledge...",
    "add_more": "Add More"
  }
}