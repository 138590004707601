{
  "portalProjectItem": {
    "your_link_name": "Nome del tuo link",
    "delete_this_project": "Eliminare questo progetto?",
    "delete_project": "Elimina progetto",
    "project_will_be_deleted": "Il progetto verrà eliminato. Questa azione non può essere annullata"
  },
  "portalProjects": {
    "no_projects_so_far": "Nessun progetto finora",
    "apply": "Applica",
    "link_name": "Nome del link",
    "project": "Progetto",
    "widget": "Widget",
    "actions": "Azioni",
    "the_item_has_been_successfully_deleted": "L'elemento è stato eliminato con successo.",
    "projects_successfully_updated": "Progetti aggiornati con successo."
  }
}