{
  "mindmapLoader": {
    "something_went_awry": "¡Algo salió mal!",
    "please_wait_while_we_generate_your": "Por favor espera mientras generamos tu",
    "attempt": "Intento"
  },
  "topicsModal": {
    "select_mind_map_topic": "Selecciona el tema del mapa mental",
    "wait_while_we_generate_topics": "Espera, por favor, mientras generamos temas basados en el contexto de tu proyecto",
    "your_custom_topic": "Tu tema personalizado"
  }
}