{
  "portalPreview": {
    "example": "Example",
    "suggestedQuestions": "Suggested questions",
    "who_s_on_duty_today": "Who's on duty today?",
    "send": "Send"
  },
  "tenantPortalPortalUi": {
    "portal_styles": "Portal styles",
    "background_color": "Background color",
    "primary_color": "Primary color",
    "secondary_color": "Secondary/Stroke color",
    "accent_color": "Accent color",
    "text_color": "Text color",
    "portal_preview": "Portal preview",
    "apply": "Apply",
    "field_is_not_a_valid_hex_color": "Field is not a valid hex color",
    "failed_to_upload_branding_logo": "Failed to upload branding logo",
    "portal_ui_successfully_saved": "Portal UI successfully saved"
  }
}