{
  "ninjaIndicator": {
    "please_wait_while": "Por favor espera mientras procesamos tus datos. Puede tomar aproximadamente un minuto.",
    "redirecting_to_your_project": "Redirigiendo a tu proyecto...",
    "we_are_processing_your_information": "Estamos procesando tu información..."
  },
  "progressCancelChain": {
    "cancel_all": "Cancelar todo",
    "do_you_want_to_stop": "¿Quieres detener todas las cadenas de procesamiento?",
    "stop_processing": "¿Detener el procesamiento?",
    "yes": "Sí",
    "no": "No"
  },
  "progressNinjaLoader": {
    "please_wait_while_we_process": "Por favor espera mientras procesamos tus datos. Puede tomar aproximadamente un minuto.",
    "redirecting_to_your_project": "Redirigiendo a tu proyecto...",
    "we_are_processing_your_information": "Estamos procesando tus datos ahora, lo cual puede llevar algún tiempo. Puedes comenzar a usar el sistema de inmediato y el proceso continuará en segundo plano.",
    "process_in_background": "Procesar en segundo plano"
  },
  "progressResourceItem": {
    "there_were_some_errors_during_processing": "Hubo algunos errores durante el procesamiento."
  },
  "progressResourceList": {
    "jobs_in_progress": "Trabajos en progreso"
  }
}