{
  "docSearchWidget": {
    "scanning_through_the_documentation": "Escaneando la documentación, ¡por favor espera un momento!",
    "generate_three_suggested_questions": "Generar tres preguntas sugeridas basadas en los recursos proporcionados. Cada pregunta debe estar separada por un salto de línea y no debe tener más de cinco palabras. Por favor, evita numerar las preguntas.",
    "oops_it_seems_youve_reached_your": "¡Ups! Parece que has alcanzado el límite de mensajes. Para continuar, ajusta tu límite de mensajes en la configuración.",
    "we_encountered_an_issue_while": "¡Hemos encontrado un problema al intentar generar la respuesta! ¡Inténtalo de nuevo más tarde!",
    "search": "Buscar"
  },
  "docSearchWidgetHeader": {
    "hi_how_can_i_help_you": "¡Hola! ¿Cómo puedo ayudarte?"
  },
  "docSearchWidgetInput": {
    "type_a_question_here": "Escribe una pregunta aquí...",
    "ask": "Preguntar"
  },
  "docSearchWidgetReset": {
    "reset": "Restablecer"
  },
  "docSearchWidgetWrapper": {
    "click_here_to_see_embed_code": "Haz clic aquí para ver el código de inserción"
  }
}