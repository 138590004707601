{
  "changePassword": {
    "you_will_be_redirected": "Verrai reindirizzato alla pagina principale in {value} secondi",
    "to_go_to_the_home_page": "Per andare alla pagina principale",
    "click_here": "clicca qui",
    "enter_your_new_password": "Inserisci la tua nuova password",
    "password_successfully_changed": "Password cambiata con successo"
  },
  "signIn": {
    "sign_in": "Accedi",
    "enter_your_email_and_password": "Inserisci la tua email e password per effettuare l'accesso",
    "email": "Email",
    "enter_email": "Inserisci l'email",
    "password": "Password",
    "enter_password": "Inserisci la password",
    "or": "O",
    "dont_have_an_account": "Non hai un account?",
    "sign_up_with_email": "Registrati con l'email",
    "dont_remember_the_password": "Non ricordi la password?",
    "reset_password": "Reimposta la password",
    "link_expired": "Questo link non è valido o è scaduto.",
    "please_request_a_new_link": "Richiedi un nuovo link.",
    "something_went_awry": "Qualcosa è andato storto!",
    "your_email_is_not_yet_verified": "La tua email non è ancora verificata. Controlla la tua casella di posta e segui il link di verifica per completare la registrazione."
  },
  "signUp": {
    "sign_up": "Registrati"
  },
  "signUpForm": {
    "name": "Nome",
    "enter_first_name": "Inserisci il nome",
    "last_name": "Cognome",
    "enter_last_name": "Inserisci il cognome",
    "email": "Email",
    "enter_email": "Inserisci l'email",
    "password": "Password",
    "enter_password": "Inserisci la password",
    "password_confirmation": "Conferma password",
    "i_agree": "Accetto",
    "terms_and_conditions": "Termini e Condizioni",
    "and": "e",
    "privacy_policy": "Informativa sulla privacy",
    "by_checking_this": "Spuntando questa casella, riconosci e accetti il trasferimento dei dati che carichi in questo progetto a servizi di terze parti, come OpenAI. Questo trasferimento di dati è necessario per il corretto funzionamento del progetto e per sfruttare servizi esterni che ne migliorano le funzionalità.",
    "third_party_services": "Consenso al trasferimento dei dati a servizi di terze parti",
    "sign_up": "REGISTRATI",
    "or_sign_up_with": "Oppure registrati con",
    "already_have_an_account": "Hai già un account?",
    "sign_in": "Accedi",
    "please_check_your_mailbox_and": "Controlla la tua casella di posta e \n segui il link di verifica per completare la registrazione. \n",
    "if_you_cant_find_it_in_your": "Se non lo trovi nella tua casella di posta, controlla la tua",
    "spam_folder": "cartella dello spam",
    "as_well": "anche",
    "this_field_contains_special": "Questo campo contiene caratteri speciali",
    "please_ensure_youve_read": "Assicurati di aver letto e accettato i nostri Termini e Condizioni",
    "and_have_consented": "e di aver acconsentito al trasferimento dei dati a servizi di terze parti spuntando le caselle corrispondenti",
    "before_signing_in": "prima di accedere",
    "by_signing_up_you_agree": "Registrandoti, accetti i nostri Termini e Condizioni e riconosci di aver acconsentito al trasferimento dei tuoi dati a servizi di terze parti.",
    "read_our": "Leggi la nostra"
  }
}