{
  "exportQuestionsCodeModal": {
    "how_to_import_into_quizlet": "Cómo importar en Quizlet",
    "start_by_heading_to": "Comienza dirigiéndote a Quizlet y haciendo clic en el botón + para crear un nuevo conjunto de estudio. A continuación, encuentra y haz clic en el botón \"Importar\". Ahora, simplemente copia el contenido proporcionado arriba y pégalo en Quizlet. Recuerda: usa una",
    "tab": "Tabulación",
    "between_each_term_and_its_definition_and_a": "entre cada término y su definición, y una",
    "new_line": "Nueva Línea",
    "to_separate_different_cards": "para separar diferentes tarjetas. ¡Feliz estudio!",
    "copy_content": "Copiar contenido",
    "copied_content": "Contenido copiado",
    "content_was_copied": "¡El contenido fue copiado!"
  },
  "filtersModal": {
    "filters": "Filtros",
    "search_for_resources": "Buscar recursos...",
    "search_by_selected_resources": "Buscar por recursos seleccionados",
    "reset_all": "Restablecer todo",
    "check_all": "Marcar todo",
    "sorry_no_resources_found_for_your": "Lo sentimos, no se encontraron recursos para tu consulta de búsqueda",
    "please_try_modifying_your": "Por favor, intenta modificar tus términos de búsqueda o filtros para ampliar tu búsqueda.",
    "cancel": "Cancelar",
    "apply": "Aplicar"
  },
  "liveConnect": {
    "error_executing_user_code": "Error al ejecutar el código del usuario",
    "something_went_wrong": "¡Algo salió mal! Por favor, asegúrate de haber instalado Intercom correctamente",
    "something_went_wrong_please_make_sure": "¡Algo salió mal! Por favor, asegúrate de haber instalado el widget de Hubspot correctamente",
    "something_went_wrong_please_make_sure_dialogFlow": "¡Algo salió mal! Por favor, asegúrate de haber instalado DialogFlow correctamente"
  },
  "offlineMessage": {
    "name": "Nombre",
    "email": "Correo electrónico",
    "subject": "Asunto",
    "message": "Mensaje",
    "submit": "Enviar",
    "error_executing_user_code": "Error al ejecutar el código del usuario",
    "thank_you_for_your_response": "¡Gracias por tu respuesta!",
    "something_went_awry": "¡Algo salió mal!"
  },
  "poweredByMessage": {
    "powered_by": "Desarrollado por"
  },
  "questionnaires": {
    "examples_of_questions_that_you_can_ask": "Ejemplos de preguntas que puedes hacer al bot"
  },
  "widgetsFooter": {
    "back": "Volver",
    "help": "Ayuda"
  },
  "widgetsModalInfo": {
    "it_staff_augmentation_in_portugal": "Aumento de personal de TI en Portugal",
    "upload_time": "Tiempo de carga",
    "result_relevance": "Relevancia del resultado"
  },
  "widget_external_user_form": {
    "please_provide_information_to_chat_with_me": "Por favor, proporciona información para chatear conmigo",
    "your_email": "Tu correo electrónico",
    "email": "Correo electrónico",
    "your_name": "Tu nombre",
    "name": "Nombre",
    "continue": "Continuar"
  }
}