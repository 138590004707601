{
  "customRenderer": {
    "you_have_to_type_in_at_least": "Ви повинні ввести принаймні {symbols} символ(ів) у поле вводу вище",
    "you_have_to_type_in_at_least_few": "Ви повинні ввести принаймні {symbols} символи у поле вводу вище",
    "you_have_to_type_in_at_least_many": "Ви повинні ввести принаймні {symbols} символів у поле вводу вище",
    "you_have_to_type_in_at_least_one": "Ви повинні ввести принаймні {symbols} символ у поле вводу вище",
    "you_have_to_type_in_at_least_other": "Ви повинні ввести принаймні {symbols} символів у поле вводу вище",
    "please_enter_a_valid_url": "Будь ласка, введіть правильну URL-адресу",
    "please_enter_a_valid_url_few": "Будь ласка, введіть правильні URL-адреси",
    "please_enter_a_valid_url_many": "Будь ласка, введіть правильні URL-адреси",
    "please_enter_a_valid_url_one": "Будь ласка, введіть правильну URL-адресу",
    "please_enter_a_valid_url_other": "Будь ласка, введіть правильні URL-адреси"
  }
}