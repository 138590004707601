{
  "knowledge": {
    "sources": "Sources",
    "questions_and_answers": "Questions and answers"
  },
  "knowledgeHeader": {
    "knowledge": "Knowledge",
    "search_for_uploaded_knowledge": "Search for uploaded knowledge..."
  },
  "knowledgeTable": {
    "name": "Name",
    "title": "Title",
    "size": "Size",
    "date": "Date",
    "actions": "Actions"
  }
}