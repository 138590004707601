import { gql } from '@apollo/client/core';

export const CHECK_PROMO_CODE = gql`
  query CheckPromoCode($code: String!) {
    billing_promo_codes(where: { code: { _eq: $code } }) {
      code
      description
      name
      type
      valid_until
      apply_limit
      id
      params
    }
  }
`;
