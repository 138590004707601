{
  "sidebar": {
    "it_looks_like_you_are": "It looks like you've reached your maximum number of projects. To start a new project, consider archiving or deleting existing ones you no longer need, or explore our subscription plans for higher limits.",
    "new_project": "New project",
    "select_widget": "Select widget"
  },
  "sidebarHeader": {
    "new_project": "New project"
  },
  "sidebarLink": {
    "project_settings": "Project settings",
    "project_has_been_successfully": "Project has been successfully {isFav} favorites!",
    "we_encountered_an_issue": "We encountered an issue while attempting to update your favorites. Please try again."
  },
  "sidebarNoProjects": {
    "there_are_no_projects": "There are no projects here so far"
  },
  "sidebarProjectsListFooter": {
    "create_project": "Create Project",
    "create_a_project_using": "create a project using",
    "bot_advice": "bot advice",
    "enter_redemption_code": "Enter redemption code",
    "applying": "Applying...",
    "apply": "Apply",
    "oops_you_ve_reached_your_project_limit": "Oops! You've reached your project limit for creating new projects. To create more, please consider upgrading your package!"
  },
  "sidebarProjectTree": {
    "knowledge": "Knowledge",
    "add_a_new_view": "Add a new view",
    "dialogs": "Dialogs",
    "conversations": "Conversations",
    "team": "Team",
    "project": "Project",
    "agent": "Agent",
    "tools": "Tools",
    "add_a_new_tool": "Add a new tools"
  },
  "sidebarPromocode": {
    "enter_redemption_code": "Enter redemption code",
    "exists_promo": "This promo code already exists",
    "apply": "Apply"
  },
  "sidebarProjectTreeSettingsItem": {
    "settings": "Settings",
    "ai_model": "AI Model",
    "behavior": "Behavior",
    "appearance": "Appearance",
    "integrations": "Integrations",
    "database": "Database"
  },
  "sidebarSharedProjectsListItem": {
    "something_went_awry": "Something went awry!"
  },
  "sidebarEmailConfirmWarning": {
    "resend": "Resend",
    "sending": "Sending",
    "please_check_your_inbox": "Please check your inbox",
    "to_verify_your_email": "to verify your email.",
    "verification_email_is_sent": "Verification email has been sent.",
    "something_went_wrong": "Something went wrong"
  }
}
