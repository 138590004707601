import { ref } from 'vue';
import { nhost } from '@/lib/nhost';
import axios, { type AxiosProgressEvent } from 'axios';

type PrepareFileParams = {
  file: File;
  projectId: string;
  bucketId?: 'chains' | 'logos' | 'quiz';
};

const uploadProgress = ref(0);

const uploadFileWithProgress = async ({ file, bucketId }: { file: File; bucketId: string }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('bucketId', bucketId);

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${nhost.auth.getAccessToken()}`,
    },
    onUploadProgress: function (progressEvent: AxiosProgressEvent) {
      const uploadPercentage = parseInt(
        Math.round((progressEvent.loaded / progressEvent.total!) * 100).toString(),
      );
      uploadProgress.value = uploadPercentage;
    },
  };

  const response = await axios.post(
    `${import.meta.env.VITE_NHOST_BACKEND_URL}/v1/storage/files`,
    formData,
    config,
  );

  return response.data;
};

const prepareFile = async ({ file, projectId, bucketId = 'chains' }: PrepareFileParams) => {
  try {
    const fileMetadata = await uploadFileWithProgress({ file, bucketId });

    if (!fileMetadata?.id) {
      return;
    }

    const url = nhost.storage.getPublicUrl({
      fileId: fileMetadata.id,
    });

    const value = {
      uuid: projectId,
      url,
    };
    const meta = {
      url: file.name,
      title: file.name,
      mimeType: file.type,
    };

    return { value, meta };
  } catch (error) {
    console.error('Upload failed:', error);
    return null;
  }
};

export { prepareFile, uploadProgress };
