import gql from 'graphql-tag';

export const ADD_PROJECT_TOOL = gql`
  mutation AddProjectTool($project_id: uuid = "", $settings: json = "", $slug: String = "") {
    insert_project_tools_one(
      object: { project_id: $project_id, settings: $settings, slug: $slug }
    ) {
      id
    }
  }
`;

export const ADD_PROFILE_CREDENTIALS = gql`
  mutation addProfileCredentials($object: profiles_credentials_insert_input!) {
    insert_profiles_credentials_one(object: $object) {
      id
      name
      slug
      config
    }
  }
`;

export const ADD_PROJECT_AGENT = gql`
  mutation addProjectAgent($object: projects_agents_insert_input!) {
    insert_projects_agents_one(object: $object) {
      id
    }
  }
`;

export const CHANGE_AGENT = gql`
  mutation update_projects_agents_by_pk($id: uuid!, $object: projects_agents_set_input!) {
    update_projects_agents(where: { id: { _eq: $id } }, _set: $object) {
      description
      engine
      id
      llm
      name
      project_id
      prompt
    }
  }
`;

export const AGENT_UPDATE_PROMPT = gql`
  mutation update_prompt($id: uuid!, $prompt: String = "", $prompt_raw: jsonb = "") {
    update_projects_agents(
      where: { id: { _eq: $id } }
      _set: { prompt: $prompt, prompt_raw: $prompt_raw }
    ) {
      returning {
        id
        name
        prompt
        prompt_raw
      }
    }
  }
`;

export const AGENT_UPDATE_LLM = gql`
  mutation update_prompt($id: uuid!, $llm: String = "") {
    update_projects_agents(where: { id: { _eq: $id } }, _set: { llm: $llm }) {
      returning {
        id
        name
        llm
      }
    }
  }
`;

export const ADD_AGENT_TOOL = gql`
  mutation add_agent_tool($object: agents_tools_insert_input!) {
    insert_agents_tools_one(object: $object) {
      id
      config
    }
  }
`;

export const UPDATE_AGENT_TOOL = gql`
  mutation update_agent_tool($id: uuid!, $object: agents_tools_set_input!) {
    update_agents_tools_by_pk(_set: $object, pk_columns: { id: $id }) {
      config
      id
      description
      name
      slug
      tools_credentials {
        credential_id
        tool_id
      }
    }
  }
`;

export const ADD_TOOL_CREDENTIAL = gql`
  mutation add_tool_credential($objects: [tools_credentials_insert_input!]!) {
    insert_tools_credentials(objects: $objects) {
      affected_rows
    }
  }
`;

export const DELETE_TOOL_CREDENTIALS = gql`
  mutation delete_tool_credential($tool_id: uuid!, $credential_ids: [uuid!]!) {
    delete_tools_credentials(
      where: { tool_id: { _eq: $tool_id }, credential_id: { _in: $credential_ids } }
    ) {
      affected_rows
    }
  }
`;
