{
  "marketplaceOption": {
    "purchase": "Comprar",
    "characters": "personajes",
    "informers": "informadores",
    "projects": "proyectos",
    "EssentialBoost": "Impulso Esencial",
    "PremiumExpansion": "Expansión Premium",
    "UltimateAdvantage": "Ventaja Definitiva",
    "Projects10": "Proyectos 10",
    "EssentialBoostDescription": "Eleva tu cuenta con $50 adicionales, desbloqueando nuevas posibilidades y mejorando tu experiencia.",
    "PremiumExpansionDescription": "1000 solicitudes de preguntas y respuestas adicionales",
    "UltimateAdvantageDescription": "Transforma tu cuenta con $500 adicionales, ofreciendo un acceso sin igual y funcionalidades supremas.",
    "Projects10Description": "Proyectos adicionales"
  }
}