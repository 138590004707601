{
  "flashcardsWidget": {
    "export_to_quizlet": "Exportar a Quizlet",
    "export_to_cram": "Exportar a Cram",
    "how_to_import_into_cram": "Cómo importar en Cram",
    "begin_by_visiting_the_cram": "Comienza visitando el sitio web de Cram y seleccionando la opción \"Crear tarjetas de estudio\". Luego, elige \"Importar un conjunto de tarjetas de estudio\". Copia tus datos, asegurándote de que cada término y su definición estén separados por un",
    "and_different_cards_are": "y las diferentes tarjetas se distinguen por un",
    "new_line": "Nueva línea",
    "paste_this_into_the": "Pega esto en el campo de entrada etiquetado como \"COPIAR Y PEGAR TUS DATOS:\" y haz clic en \"Importar datos\". ¡Disfruta de tu sesión de estudio!",
    "oops_it_seems_youve_reached_your": "¡Ups! Parece que has alcanzado el límite de mensajes. Para continuar, ajusta amablemente tu límite de mensajes en la configuración.",
    "question": "Pregunta",
    "tab": "Pestaña",
    "my_answer": "Mi respuesta",
    "correct_answer": "Respuesta correcta",
    "evaluate_my_answer": "Evalúa mi respuesta en una escala del 1 al 5 en función de su precisión y completitud en comparación con la respuesta correcta, considerando el contexto { context }. Utiliza las siguientes pautas para calificar: Si la respuesta es completamente incorrecta o no tiene relevancia para la pregunta y demuestra una falta de comprensión del tema o la pregunta, califícala con \"1\". Si la respuesta aborda la pregunta pero tiene inexactitudes o omisiones importantes, pero muestra cierta comprensión pero también malentendidos significativos o lagunas en el conocimiento, califícala con \"2\". Si la respuesta es algo correcta pero está incompleta o tiene inexactitudes menores y muestra una comprensión justa del tema pero carece de detalles o precisión, califícala con \"3\". Si la respuesta es en su mayoría correcta y cubre la mayoría de los aspectos de la pregunta pero pueden faltar detalles o matices menores, pero la comprensión general es buena, califícala con \"4\". Si la respuesta es completamente correcta y proporciona una respuesta completa a la pregunta, califícala con \"5\". Utiliza el formato: \"número;explicación\" para tu respuesta.",
    "something_went_awry": "¡Algo salió mal!",
    "your_rate_limit_exceeded": "Genera en el idioma {formLanguage} {formAmount} preguntas de { context } en un FORMATO JSON VÁLIDO. Utiliza el patrón",
    "answers_should_only_contain_the_essential": "Las respuestas solo deben contener los términos clave o frases esenciales directamente relevantes para la pregunta, sin elaborar.",
    "we_ve_found_parsed_length_questions": "Hemos encontrado {parsedLength} preguntas por ahora. ¡Agregar más contenido podría ayudarnos a encontrar aún más!"
  },
  "flashcardsWidgetActions": {
    "view_all_questions": "Ver todas las preguntas",
    "delete_card": "Eliminar tarjeta",
    "edit_card": "Editar tarjeta",
    "export_to_quizlet": "Exportar a Quizlet",
    "export_to_anki": "Exportar a Anki",
    "export_to_brainscape": "Exportar a Brainscape",
    "export_to_cram": "Exportar a Cram",
    "regenerate_cards": "Regenerar tarjetas",
    "clear_all_cards": "Borrar todas las tarjetas"
  },
  "flashcardsWidgetFeedback": {
    "feedback": "Comentarios"
  },
  "flashcardsWidgetForm": {
    "select_the_number_and_the_language": "Selecciona el número y el idioma de las tarjetas de estudio",
    "cards_number": "Número de tarjetas",
    "enter_quantity": "Ingresa la cantidad",
    "language": "Idioma",
    "generate": "Generar",
    "original": "Original"
  },
  "flashcardsWidgetNavigation": {
    "back": "Atrás",
    "step_and_length_of_questions": "{currentStep} de {questionsLength} preguntas",
    "next": "Siguiente",
    "skip": "Saltar"
  },
  "flashcardsWidgetQuestion": {
    "question": "Pregunta",
    "enter_your_answer_here": "Ingresa tu respuesta aquí...",
    "answer": "Respuesta",
    "your_answer": "Tu respuesta",
    "correct_answer": "Respuesta correcta"
  },
  "flashcardsWidgetQuestionEditor": {
    "edit_card": "Editar tarjeta",
    "save": "Guardar",
    "question": "Pregunta",
    "answer": "Respuesta"
  },
  "flashcardsWidgetQuestionFooter": {
    "check_my_answer": "Verificar mi respuesta",
    "hide_answer": "Ocultar respuesta",
    "i_want_to_answer_it_by_myself": "Quiero responderlo yo mismo",
    "show_answer": "Mostrar respuesta"
  },
  "flashcardsWidgetQuestionsList": {
    "looks_like_it_s_pretty_empty_here": "¡Parece que está bastante vacío aquí!",
    "start_adding_some_cards_to_see_them_appear": "Comienza a agregar algunas tarjetas para verlas aparecer."
  }
}