{
  "youtubeInformer": {
    "please_provide_a_valid_vimeo_video_url": "Будь ласка, вкажіть дійсну URL-адресу відео Vimeo",
    "tracks_are_unavailable_due_to_restrictions": "Треки цього відео недоступні через обмеження",
    "something_went_awry": "Щось пішло не так",
    "no_text_tracks": "У цього відео немає текстових треків!",
    "start_time_cant_be_greater": "Час початку не може бути більшим або рівним часу закінчення"
  },
  "youtubeInformerForm": {
    "insert_youtube_video_url": "Вставте URL-адресу відео YouTube",
    "fetch_subtitles": "Отримати субтитри"
  },
  "youtubeInformerList": {
    "available_tracks": "Доступні треки"
  },
  "youtubeInformerPreview": {
    "please_adjust_the_sliders_below": "Будь ласка, налаштуйте повзунки нижче, щоб вибрати діапазон для вирізання субтитрів з вашого відео."
  }
}