{
  "googleSheetsAddOn": {
    "how_to_add_omnimind": "Como adicionar o complemento do Google Sheets do Omnimind AI",
    "please_ensure_that_the_project": "Certifique-se de que o projeto esteja definido como 'público'. Para fazer isso, clique no botão 'Publicar' para tornar o projeto acessível ao público.",
    "copy_your_api_key": "Copie sua chave de API",
    "go_to_the_google_sheet_document": "Vá para o documento do Google Sheets onde você deseja usar o complemento.",
    "press_the_extensions_menu_button": "Pressione o botão do menu Extensões e escolha Complementos e Obter complementos",
    "find_the_omnimind_add_on": "Encontre o complemento do OmniMind e instale-o",
    "after_installing_the_add_on": "Após instalar o complemento, vá para o menu Extensões, encontre o item de menu do complemento do Omnimind e pressione Iniciar",
    "you_will_be_prompted_to": "Você será solicitado a autorizar-se com o Omnimind. Por favor, autorize-se, você será redirecionado de volta ao documento do Google.",
    "launch_the_add_on_again": "Inicie o complemento novamente. Você terá uma janela de barra lateral onde poderá pressionar Obter Projetos e especificar quais dados do projeto você deseja usar no documento.",
    "paste_your_api_key_in_the_provided_field": "Cole sua chave de API no campo fornecido e clique em Obter Projetos.",
    "select_your_project": "Selecione seu projeto",
    "a_list_of_your_projects_in_omnimind": "Uma lista dos seus projetos no Omnimind será exibida.",
    "select_the_project_you_would_like_to": "Selecione o projeto que você gostaria de usar para análise de dados.",
    "use_the_omni_ask_function": "Use a função OMNI_ASK",
    "click_on_a_cell_where_you": "Clique em uma célula onde você deseja que a resposta apareça.",
    "enter_the_function": "Digite a função",
    "your_question_here": "Sua pergunta aqui",
    "replace": "Substitua",
    "with_the_actual_question_you": "pela pergunta real que você deseja fazer.",
    "press_enter": "Pressione Enter",
    "getting_answers": "Obtendo Respostas",
    "after_pressing_enter": "Após pressionar Enter, o complemento pesquisará automaticamente a resposta com base nos recursos enviados para o projeto selecionado no Omnimind.",
    "the_answer_will_then_appear": "A resposta aparecerá na célula onde você digitou a função.",
    "tips": "Dicas",
    "make_sure_your_question": "Certifique-se de que sua pergunta seja clara e concisa para obter as respostas mais precisas. Você pode fazer várias perguntas de uma vez usando a função OMNI_ASK em células diferentes. Você pode atualizar ou alterar o projeto nas configurações do complemento se desejar usar dados diferentes para análise.",
    "support": "Suporte",
    "if_you_encounter_any": "Se você encontrar algum problema ou tiver outras perguntas, visite nossa página de suporte em"
  }
}