{
  "billingsActivityAnalytics": {
    "title": "Аналітика"
  },
  "billingsActivityAnalyticsAverageChart": {
    "title": "Середня тривалість запитів",
    "length": "Тривалість",
    "no_requests_yet": "Поки немає запитів"
  },
  "billingsActivityAnalyticsFilters": {
    "today": "сьогодні",
    "7_days": "7 днів",
    "month": "місяць"
  },
  "billingsActivityAnalyticsRequestsChart": {
    "title": "Запити",
    "unique_visitors": "Унікальні відвідувачі",
    "per_day": "на день",
    "per_session": "на сесію"
  },
  "billingsActivityAnalyticsVisitors": {
    "title": "Унікальні відвідувачі",
    "all_visitors": "Всі відвідувачі",
    "no_visitors_yet": "Поки немає відвідувачів"
  },
  "billingsActivityAnalyticsSuccessRate": {
    "title": "Відсоток успішності",
    "subtitle": "Як часто OmniMind може відповісти на запитання"
  },
  "billingsActivityAnalyticsCosts": {
    "title": "Витрати"
  }
}
