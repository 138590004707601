{
  "SearchWidget_name": "Search",
  "SearchWidget_description": "Communication as in the search bar of the browser. Ask questions and see the history of answers.",
  "ExplainWidget_name": "Explain",
  "ExplainWidget_description": "Communication for more detailed answers. Question - Answer.",
  "ChatWidget_name": "Chat",
  "ChatWidget_description": "Communication in the usual form as in the messenger. Short and clear.",
  "BaseInformer_xml-sitemap_name": "Xml Sitemap",
  "BaseInformer_xml-sitemap_description": "You can add Xml sitemap for parsing website",
  "PopupChatWidget_name": "Popup Chat",
  "PopupChatWidget_description": "Interactive widget for instant answers. Ask, get responses.",
  "CSVInformer_name": "CSV Text",
  "CSVInformer_description": "You can upload your .csv file with custom column mapping",
  "WebInformer_name": "Multiple Websites",
  "WebInformer_description": "You can define a batch of website URLs for processing",
  "FileInformer_name": "File Upload",
  "FileInformer_description": "You can select your local file for processing",
  "BaseInformer_url_name": "URL file",
  "BaseInformer_url_description": "You can define the url path to upload the file from the internet",
  "YoutubeInformer_name": "Youtube",
  "YoutubeInformer_description": "You can add Youtube video subtitles",
  "BaseInformer_text_name": "Text",
  "BaseInformer_text_description": "Simple add text",
  "BulkCSVInformer_name": "CSV Links",
  "BulkCSVInformer_description": "Bulk upload via CSV with urls",
  "BaseInformer_dropbox-file_name": "Dropbox File",
  "BaseInformer_dropbox-file_description": "Add link to the Dropbox File",
  "BaseInformer_dropbox-folder_name": "Dropbox Folder",
  "BaseInformer_dropbox-folder_description": "Add link to the Dropbox Folder",
  "BaseInformer_gdrive-file_name": "GDrive File",
  "BaseInformer_gdrive-file_description": "Add link to the GDrive file",
  "BaseInformer_gdrive-folder_name": "GDrive Folder",
  "BaseInformer_gdrive-folder_description": "Add link to the GDrive folder",
  "DocSearchWidget_name": "Documents Search",
  "DocSearchWidget_description": "Allows to install a popup widget to search through documantation",
  "RetellingWidget_name": "Retelling",
  "RetellingWidget_description": "Interactive retelling tool for your knowledge base insights.",
  "VimeoInformer_name": "Vimeo",
  "VimeoInformer_description": "You can add Vimeo video subtitles",
  "ConfluenceInformer_name": "Confluence",
  "ConfluenceInformer_description": "You can add your Coufluence knowledge base",
  "BaseInformer_zendesk_name": "Zendesk",
  "BaseInformer_zendesk_description": "You can define the Zendesk help center url for processing. ",
  "BaseInformer_youtube-channel_name": "Youtube channel",
  "BaseInformer_youtube-channel_description": "You can define the Youtube channel url for processing.",
  "BaseInformer_youtube-playlist_name": "Youtube playlist",
  "BaseInformer_youtube-playlist_description": "You can define the Youtube playlist url for processing.",
  "NotionInformer_name": "Notion",
  "NotionInformer_description": "You can add notion pages or databases",
  "InteractiveMindmapWidget_name": "Interactive Mindmap",
  "InteractiveMindmapWidget_description": "Create and customize hierarchical mind maps.",
  "MermaidMindmapWidget_name": "Mermaid Mindmap",
  "MermaidMindmapWidget_description": "Create and customize hierarchical mind maps.",
  "WordpressInformer_name": "Wordpress",
  "WordpressInformer_description": "You can add wordpress site",
  "GoogleSheetsAddOn_name": "Google Sheets Add-On",
  "GoogleSheetsAddOn_description": "Adding the Omnimind AI Google Sheets Add-On to your google sheets",
  "ZapierWidget_name": "Zapier Integration",
  "ZapierWidget_description": "Adding the Omnimind AI to your Zapier application",
  "ChromeExtensionInformer_name": "Chrome Extension",
  "ChromeExtensionInformer_description": "Upload the information from your resources using our Chrome Extension.",
  "VoiceWidget_name": "Voice Talk",
  "VoiceWidget_description": "Talk to your bot to get answers like you talk to a human",
  "SingleWebInformer_name": "Website",
  "SingleWebInformer_description": "You can define a single website URL for processing",
  "DataExtractorWidget_name": "Data Extractor",
  "DataExtractorWidget_description": "Scrapping websites to collect information",
  "SlackIntegration_name": "Slack Integration",
  "SlackIntegration_description": "Adding the Omnimind AI to your Slack Workspace",
  "WhatsAppIntegration_name": "WhatsApp Integration",
  "WhatsAppIntegration_description": "Communication in the usual in the WhatsApp.",
  "BaseInformer_onedrive-file_name": "OneDrive File",
  "BaseInformer_onedrive-file_description": "Add link to the OneDrive File",
  "BaseInformer_onedrive-folder_name": "OneDrive Folder",
  "BaseInformer_onedrive-folder_description": "Add link to the OneDrive folder",
  "SummaryWidget_name": "Summary",
  "SummaryWidget_description": "Transform Your Data into Clear and Concise Summaries.",
  "QuizWidget_name": "Quiz",
  "QuizWidget_description": "Comprehensive Quiz Creation and Exporting",
  "TableWidget_name": "Table",
  "TableWidget_description": "Common widget for displaying data in a table. This widget is hidden from the list of widgets.",
  "AudioInformer_name": "Audio File",
  "AudioInformer_description": "You can select your local audio file for processing",
  "AirtableInformer_name": "Airtable",
  "AirtableInformer_description": "You can add your Airtable knowledgebase",
  "FlashcardsWidget_name": "Flashcards",
  "FlashcardsWidget_description": "Create and use flashcards for effective learning and knowledge testing",
  "ExcelInformer_name": "Excel",
  "ExcelInformer_description": "You can upload your .xls .or .xlsx file with custom column mapping",
  "GoogleSheetsInformer_name": "Google Sheets",
  "GoogleSheetsInformer_description": "You can upload your Google Sheets with custom column mapping",
  "DatabaseInformer_name": "Database Insights",
  "DatabaseInformer_description": "You can make requests to your own DBs by human-readable language.",
  "PdfOcrInformer_name": "PDF",
  "PdfOcrInformer_description": "Extracts all data from PDFs and adds it to your resources.",
  "BubbleIntegration_name": "Bubble.io plugin",
  "BubbleIntegration_description": "Integrate OmniMind into your Bubble solutions.",
  "NpmIntegration_name": "NPM Package",
  "NpmIntegration_description": "Adding the Omnimind AI Npm package to your projects",
  "MicrosoftTeamsIntegration_name": "Microsoft Teams integration",
  "MicrosoftTeamsIntegration_description": "Integrate your knowledge base into your corporate communication with Microsoft Teams and one of our chatbots and speed up your operational processes."
}
