import { nhost } from "@/lib/nhost";
import { showPromocodeApplyMessage } from "@/helpers/showPromocodeApplyMessage";
import { applyPromocode } from "@/api";
import { showAlert } from "@/composables/mixin-alert";
import validationI18n from "@helpers/validationI18n";

const { t } = validationI18n.global;


export const signupCheckPromocode = async () => {
  const signupPromocode = localStorage.getItem("signupPromocode");
  if (!signupPromocode) return false;
  setTimeout(async () => {
      try {
        const userDetails = nhost.auth.getUser();
        const userEmail = userDetails?.email;
        if (userEmail) {
          const { res } = await applyPromocode(userEmail, signupPromocode);
          showPromocodeApplyMessage(res);
        }
      } catch (e) {
        showAlert("error", t("auth.promocode.something_went_wrong"));
        console.error(e);
      } finally {
        localStorage.removeItem("signupPromocode");
      }
    }, 3000,
  );

  return true;
};
