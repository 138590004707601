import { gql } from '@apollo/client/core';

export const GET_USER_PACKAGES_HISTORY = gql`
  query GetBillingUserPackages($id: uuid!) {
    billing_user_packages(
        where: { user_id: { _eq: $id } },
        order_by: { created_at: desc }
    ) {
      id
      comment
      created_at
      next_at
      package_features
      package_name
      package_price
      portal_id
      product_id
      started_at
      status
      trial_ended_at
      trial_started_at
      stripe_subscription
      invoice_id
      updated_at
      units_amount
      units_spent
      user_id
    }
  }
`;
