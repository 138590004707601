{
  "embedCode": {
    "you_can_copy_paste_this_snippet_into_your": "Ви можете скопіювати цей фрагмент і вставити його у свій код та використовувати віджет на своєму сайті, або ви можете використовувати наш",
    "package": "пакет",
    "and_natively_integrate_the_widget_into_your_react_app": "та нативно інтегрувати віджет у свою React-додаток.",
    "incorporate_this_html_code_into_your_document": "Вставте цей HTML-код у свій документ",
    "subsequently_append_the_following_java_script": "Потім додайте наступний JavaScript-код після своїх скриптів",
    "send_the_code_and_integration_instructions_to_the_developer": "Надішліть код та інструкції щодо інтеграції розробнику",
    "email": "Електронна пошта",
    "send": "Надіслати",
    "copy_code": "Скопіювати код",
    "code_was_copied": "Код був скопійований!",
    "copied_code": "Скопійований код",
    "something_went_awry": "Щось пішло не так",
    "your_code_successfully_sent": "Ваш код успішно надіслано"
  },
  "settingsInformerModal": {
    "informer_settings": "Налаштування інформатора",
    "anonymizer": "Анонімізатор",
    "splitter": "Роздільник",
    "advanced": "Розширені",
    "apply": "Застосувати",
    "apply_for_all": "Застосувати для всіх",
    "your_settings_were_successfully_saved": "Ваші налаштування успішно збережено"
  },
  "settingsProject": {
    "rename_the_project": "Перейменувати проект",
    "security": "Безпека",
    "operations": "Операції",
    "back": "Назад",
    "failed_to_load_settings": "Не вдалося завантажити налаштування",
    "your_settings_successfully_saved": "Ваші налаштування успішно збережено",
    "failed_to_save_settings": "Не вдалося зберегти налаштування",
    "failed_to_delete_password": "Не вдалося видалити пароль"
  }
}