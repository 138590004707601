{
  "apiKeys": {
    "api_keys": "API Keys",
    "please_generate_your_api_key": "Please, generate your API key if you want to use our Backend API",
    "api_keys_not_found": "API keys not found.",
    "let_s_generate_it": "Let's generate it!",
    "generate_api_key": "Generate API Key",
    "your_secret_api_keys_are_listed_below": "Your secret API keys are listed below. Do not share your API key with others, or expose it in the browser or other client-side code.",
    "name": "Name",
    "key": "Key",
    "created": "Created",
    "expires": "Expires",
    "last_used": "Last Used",
    "actions": "Actions",
    "name_is_not_provided": "Name is not provided",
    "api_key_was_update": "API key was update!",
    "you_must_generate_a_new_api_key": "You must generate a new API key in order to revoke you only remaining key.",
    "api_key_was_revoked": "API key was revoked!"
  },
  "createApiKeyModal": {
    "create_new_secret_key": "Create new secret key",
    "name_optional": "Name (optional)",
    "my_test_key": "My Test Key",
    "create_secret_key": "Create secret key"
  },
  "createdNewKeyModal": {
    "created_new_secret_key": "Created new secret key",
    "please_save_this_secret_key": "Please save this secret key somewhere safe and accessible. For security reasons, you won't be able to view it again through your account. If you lose this secret key, you'll need to generate a new one.",
    "api_key_copied": "API key copied!"
  },
  "presentApiKeyModal": {
    "your_secret_key": "Your secret key",
    "secret_key": "Secret key",
    "api_key_copied": "API key copied!"
  },
  "renameApiKeyModal": {
    "edit_secret_key": "Edit secret key",
    "save": "Save",
    "edit_name": "Edit name",
    "my_test_key": "My Test Key"
  },
  "revokeKeyModal": {
    "revoke_secret_key": "Revoke secret key",
    "this_api_key_will_immediately_be_disabled": "This API key will immediately be disabled. API requests made using this key will be rejected, which could cause any systems still depending on it to break. Once revoked, you'll no longer be able to view or modify this API key.",
    "revoke_key": "Revoke key"
  }
}