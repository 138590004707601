{
  "addToFavorites": {
    "remove_from_favorites": "Aus Favoriten entfernen",
    "remove_from_favorites_one": "Aus Favoriten entfernen",
    "remove_from_favorites_other": "Aus Favoriten entfernen",
    "add_to_favorites": "Zu Favoriten hinzufügen",
    "add_to_favorites_one": "Zu Favoriten hinzufügen",
    "add_to_favorites_other": "Zu Favoriten hinzufügen"
  },
  "confirmationModal": {
    "ok": "Ok",
    "cancel": "Abbrechen"
  }
}