{
  "pagesProjectsWidgetIdSettings": {
    "back": "Назад",
    "your_settings_successfully_saved": "Ваші налаштування успішно збережені",
    "failed_to_save_settings": "Не вдалося зберегти налаштування",
    "failed_to_load_settings": "Не вдалося завантажити налаштування"
  },
  "pagesProjectsWidgetIdTeam": {
    "team": "Команда",
    "back": "Назад"
  },
  "pagesProjectsWidgetIdBasic": {
    "this_widget_view_is_not_ready_yet": "Цей виджет ще не готовий.",
    "add_a_new_view": "Додати новий вигляд",
    "you_dont_have_any_resources": "У вас немає жодних ресурсів. Ви повинні додати новий інформер.",
    "change_widget_view": "Змінити вигляд виджета"
  }
}