{
  "portalProjectItem": {
    "your_link_name": "Nombre de tu enlace",
    "delete_this_project": "¿Eliminar este proyecto?",
    "delete_project": "Eliminar proyecto",
    "project_will_be_deleted": "El proyecto será eliminado. Esto no se puede deshacer"
  },
  "portalProjects": {
    "no_projects_so_far": "No hay proyectos hasta ahora",
    "apply": "Aplicar",
    "link_name": "Nombre del enlace",
    "project": "Proyecto",
    "widget": "Widget",
    "actions": "Acciones",
    "the_item_has_been_successfully_deleted": "El elemento ha sido eliminado correctamente.",
    "projects_successfully_updated": "Proyectos actualizados correctamente."
  }
}