<template>
  <div
    class="overflow-hidden border-t border-t-white-white100 px-4 py-2 dark:border-t-dark-blue100"
  >
    <form class="relative max-h-[36px]">
      <BaseField>
        <BaseInput
          v-model="code"
          :placeholder="$t('sidebarPromocode.enter_redemption_code')"
          type="text"
          class="h-[36px] min-h-[unset] rounded-md border border-white-white100 outline-none dark:border-dark-blue100"
          @update:model-value="error = ''"
          @keypress.enter="onStartApply"
        />
        <button
          class="btn-primary absolute right-0 top-0 inline-flex h-[36px] max-w-[109.28px] items-center justify-center whitespace-nowrap rounded-r-lg px-4 text-sm text-white shadow-none transition hover:opacity-80 hover:shadow-lg"
          :class="{ 'pointer-events-none cursor-not-allowed opacity-80': !code || isLoading }"
          @click.prevent="onStartApply"
        >
          <span :class="{ 'opacity-0': isLoading }">{{ $t('sidebarPromocode.apply') }}</span>
        </button>
        <BaseErrorMessage v-if="error" class="input-errors animate__animated animate__fadeIn">
          {{ error }}
        </BaseErrorMessage>
      </BaseField>
      <div v-if="isLoading" class="absolute right-6 top-2 h-5 w-5 animate-spin opacity-100">
        <Loader />
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import Loader from '@components/Loader.vue';
import { showPromocodeApplyMessage } from '@/helpers/showPromocodeApplyMessage';
import BaseErrorMessage from '@components/base/BaseErrorMessage.vue';
import BaseInput from '@components/base/BaseInput.vue';
import BaseField from '@components/base/BaseField.vue';
import { useUserData } from '@nhost/vue';
import { applyPromocode, GET_USER_PROMOCODE_EXISTS } from '@/api';
import { ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { showAlert } from '@/composables/mixin-alert';
import { useI18n } from 'vue-i18n';
import { useDebounceFn } from '@vueuse/core';

const DEBOUNCE_TIME = 1000;

const error = ref('');
const code = ref('');
const isLoading = ref(false);

const isPromoExists = ref(false);

const user = useUserData();
const { t } = useI18n();

const { refetch } = useQuery(
  GET_USER_PROMOCODE_EXISTS,
  { id: user.value?.id, code: code.value },
  {
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
  },
);

const checkExistsPromo = async () => {
  const result = await refetch({ id: user.value?.id, code: `%${code.value}%` });
  isPromoExists.value = result?.data?.billing_user_packages?.length;
};

const onApply = async () => {
  await checkExistsPromo();
  if (isPromoExists.value) {
    showAlert('warning', t('sidebarPromocode.exists_promo'));
    isLoading.value = false;
    return;
  }
  if (!user.value?.email) return;
  const { res } = await applyPromocode(user.value.email, code.value);
  showPromocodeApplyMessage(res);
  isLoading.value = false;
};

const debouncedOnApply = useDebounceFn(onApply, DEBOUNCE_TIME);

const onStartApply = () => {
  isLoading.value = true;
  debouncedOnApply();
};
</script>
