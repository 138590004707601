{
  "embedCode": {
    "you_can_copy_paste_this_snippet_into_your": "Puedes copiar y pegar este fragmento en tu código y usar el widget en tu sitio o puedes usar nuestro",
    "package": "paquete",
    "and_natively_integrate_the_widget_into_your_react_app": "e integrar nativamente el widget en tu aplicación de React.",
    "incorporate_this_html_code_into_your_document": "Incorpora este código HTML en tu documento",
    "subsequently_append_the_following_java_script": "A continuación, agrega el siguiente código JavaScript después de tus scripts",
    "send_the_code_and_integration_instructions_to_the_developer": "Envía el código e instrucciones de integración al desarrollador",
    "email": "Correo electrónico",
    "send": "Enviar",
    "copy_code": "Copiar código",
    "code_was_copied": "¡Código copiado!",
    "copied_code": "Código copiado",
    "something_went_awry": "Algo salió mal",
    "your_code_successfully_sent": "Tu código se envió correctamente"
  },
  "settingsInformerModal": {
    "informer_settings": "Configuración del informador",
    "anonymizer": "Anonimizador",
    "splitter": "Separador",
    "advanced": "Avanzado",
    "apply": "Aplicar",
    "apply_for_all": "Aplicar para todos",
    "your_settings_were_successfully_saved": "Tus configuraciones se guardaron correctamente"
  },
  "settingsProject": {
    "rename_the_project": "Renombrar el proyecto",
    "security": "Seguridad",
    "operations": "Operaciones",
    "back": "Volver",
    "failed_to_load_settings": "Error al cargar las configuraciones",
    "your_settings_successfully_saved": "Tus configuraciones se guardaron correctamente",
    "failed_to_save_settings": "Error al guardar las configuraciones",
    "failed_to_delete_password": "Error al eliminar la contraseña"
  }
}