{
  "baseDropboxFile": {
    "schema": {
      "folder_name": "Ім'я папки Dropbox",
      "folder_description": "Поточне ім'я папки",
      "url_title": "URL файлу Dropbox",
      "url_description": "URL до файлу Dropbox"
    },
    "schemaUi": {
      "label": "Файл Dropbox",
      "description": "Тут ви можете вставити URL вашого файлу на Dropbox. Будь ласка, переконайтеся, що файл є загальнодоступним, інакше ви отримаєте помилку на етапі обробки",
      "placeholder": "Введіть URL"
    }
  },
  "baseDropboxFolder": {
    "schema": {
      "url_title": "URL папки Dropbox",
      "url_description": "URL до папки Dropbox"
    },
    "schemaUi": {
      "label": "Папка Dropbox",
      "description": "Тут ви можете вставити посилання на вашу папку Dropbox. Будь ласка, переконайтеся, що папка, а також всі файли всередині, є загальнодоступними, інакше ви отримаєте помилку на етапі обробки",
      "placeholder": "Введіть URL"
    }
  },
  "baseGoogleDriveFile": {
    "schema": {
      "folder_name": "Ім'я папки Gdrive",
      "folder_description": "Назва папки Gdrive",
      "url_title": "URL файлу Gdrive",
      "url_description": "URL до файлу Gdrive"
    },
    "schemaUi": {
      "label": "Файл Gdrive",
      "description": "Тут ви можете вставити посилання на ваш файл Google Drive. Будь ласка, переконайтеся, що файл є загальнодоступним, інакше ви отримаєте помилку на етапі обробки",
      "placeholder": "Введіть URL"
    }
  },
  "baseGoogleDriveFolder": {
    "schema": {
      "url_title": "URL папки Gdrive",
      "url_description": "URL до папки Gdrive"
    },
    "schemaUi": {
      "label": "Папка Gdrive",
      "description": "Тут ви можете додати посилання на вашу папку Google Drive. Будь ласка, переконайтеся, що папка, а також всі файли всередині папки, є загальнодоступними, інакше ви отримаєте помилку на етапі обробки",
      "placeholder": "Введіть URL"
    }
  },
  "baseOneDriveFile": {
    "schema": {
      "parent_title": "Ім'я папки Gdrive",
      "url_title": "URL файлу OneDrive",
      "url_description": "URL до файлу OneDrive"
    },
    "schemaUi": {
      "label": "Файл OneDrive",
      "description": "Тут ви можете вставити посилання на ваш файл OneDrive. Будь ласка, переконайтеся, що файл є загальнодоступним, інакше ви отримаєте помилку на етапі обробки",
      "placeholder": "Введіть URL"
    }
  },
  "baseOneDriveFolder": {
    "schema": {
      "url_title": "URL папки OneDrive",
      "url_description": "URL до папки OneDrive"
    },
    "schemaUi": {
      "label": "Папка OneDrive",
      "description": "Тут ви можете додати посилання на вашу папку OneDrive. Будь ласка, переконайтеся, що папка, а також всі файли всередині папки, є загальнодоступними, інакше ви отримаєте помилку на етапі обробки",
      "placeholder": "Введіть URL"
    }
  },
  "baseXmlSitemap": {
    "schema": {
      "url_title": "URL XML",
      "url_description": "Введіть URL вашої карти сайту"
    },
    "schemaUi": {
      "label": "XML карта сайту",
      "description": "Якщо у вас є файл карти сайту з усіма посиланнями, які ви хочете індексувати, ви можете вказати URL до файлу карти сайту",
      "placeholder": "https://example.com/sitemap.xml"
    }
  },
  "baseYoutubeChannel": {
    "schema": {
      "url_title": "URL каналу",
      "url_description": "Будь ласка, натисніть кнопку «Поділитися» на каналі YouTube та скопіюйте посилання в поле вище",
      "language_title": "Бажана мова"
    },
    "schemaUi": {
      "label": "Канал YouTube",
      "description": "Будь ласка, вставте посилання на канал YouTube, в якому є субтитри з вказаною мовою нижче. Примітка: інформер обробляє лише відео з субтитрами, інакше ви отримаєте помилку.",
      "placeholder": "Введіть URL",
      "placeholder_2": "en"
    }
  },
  "baseYoutubePlaylist": {
    "schema": {
      "url_title": "URL плейлисту",
      "url_description": "Будь ласка, натисніть кнопку «Поділитися» на плейлисті YouTube та скопіюйте посилання в поле вище",
      "language_title": "Бажана мова"
    },
    "schemaUi": {
      "label": "Плейлист YouTube",
      "description": "Будь ласка, вставте посилання на плейлист YouTube, в якому є субтитри з вказаною мовою нижче. Примітка: інформер обробляє лише відео з субтитрами, інакше ви отримаєте помилку.",
      "placeholder": "Введіть URL",
      "placeholder_2": "en"
    }
  },
  "baseZendesk": {
    "schema": {
      "url_title": "URL Zendesk",
      "url_description": "Будь ласка, введіть посилання на центр допомоги Zendesk"
    },
    "schemaUi": {
      "label": "Zendesk",
      "description": "Будь ласка, вставте посилання на центр допомоги Zendesk.",
      "placeholder": "Введіть URL"
    }
  },
  "baseText": {
    "schema": {
      "content_title": "Текст",
      "content_description": "Будь ласка, додайте текст, який ви хочете додати до вашого проекту",
      "metadata_title": "Додаткові метадані"
    },
    "schemaUi": {
      "label": "Текст",
      "description": "Додайте текст до вашого проекту",
      "placeholder": "Введіть текст"
    }
  },
  "baseUrl": {
    "schema": {
      "url_title": "URL файлу",
      "url_description": "URL файлу, який буде додано до проекту"
    },
    "schemaUi": {
      "label": "URL файлу",
      "description": "Будь ласка, переконайтеся, що файл є загальнодоступним в Інтернеті, інакше ви отримаєте помилку авторизації",
      "placeholder": "example.com/file.pdf"
    }
  }
}