{
  "mermaidMindmapWidgetActions": {
    "export": "Exportar",
    "click_here": "Haz clic aquí para abrir los editores de mapas mentales",
    "theme": "Tema",
    "select_timezone": "Selecciona tu zona horaria",
    "language": "Idioma",
    "create_map": "Crear mapa",
    "regenerate_tooltip": "Haz clic aquí para regenerar todo el mapa mental. Ten en cuenta que esto reemplazará por completo el mapa actual. Utiliza esta opción solo si no estás satisfecho con el mapa existente. Si deseas hacer ajustes menores, simplemente haz clic en el botón 'Editar' en su lugar.",
    "export_svg": "Exportar SVG",
    "export_png": "Exportar PNG"
  },
  "mermaidMindmapWidgetMap": {
    "mermaid_mind_map": "Mapa mental de Mermaid",
    "what_is_mind_map": "¿Qué es un mapa mental?",
    "a_mermaid_mind_map_is_a_visualization_tool": "Un mapa mental de Mermaid es una herramienta de visualización creada con la biblioteca Mermaid.js, que permite la organización y representación de información en un formato jerárquico. Utilizando una sintaxis simple similar a Markdown, puedes crear mapas mentales que ofrecen una estructura visual clara para ideas o proyectos complejos. Este método ayuda significativamente en las fases de lluvia de ideas y planificación, ayudando a delinear y aclarar datos o conceptos complejos de manera eficiente. Además, la entrada de texto plano es fácilmente editable, facilitando actualizaciones y adaptaciones rápidas. Se puede integrar fácilmente en varias plataformas, lo que hace que compartir e incrustar estos mapas mentales sea un proceso sin complicaciones.",
    "steps_to_improve_your_mind_map": "Pasos para mejorar tu mapa mental",
    "clarify_your_intent": "Clarifica tu intención",
    "use_the_custom_prompt_to": "Utiliza la indicación personalizada para especificar tus requisitos al crear el mapa mental.",
    "declutter_knowledge": "Despeja el conocimiento",
    "navigate_to_the_knowledge_section": "Navega a la sección \"Conocimiento\" (junto a la configuración). Elimina fragmentos redundantes e información innecesaria para mantener el enfoque y la claridad.",
    "adjust_with_editors": "Ajusta con los editores",
    "employ_the_map_editor_to_correct": "Utiliza el 'Editor de mapas' para corregir y ajustar la sintaxis de Mermaid. Utiliza el 'Editor de nodos' para agregar, eliminar o reemplazar nodos en el mapa mental para una mejor alineación.",
    "by_following_these_steps": "Siguiendo estos pasos, tu mapa mental será más eficiente, enfocado y adaptado a tus necesidades.",
    "map_should_highlight_the_key_aspects_and": "El mapa debe resaltar los aspectos clave y las relaciones jerárquicas de {context}. Comienza el mapa con un solo nodo raíz que represente de manera concisa el concepto o tema principal. Desarrolla ramas para explorar subtemas o conceptos relacionados, proporcionando etiquetas claras para cada nodo. Mantén un flujo claro y lógico entre nodos y subnodos conectados, utilizando una sangría adecuada para indicar jerarquía. Mantén los nodos breves y concisos, asegurándote de que se relacionen directamente con los nodos adyacentes. En la respuesta, sigue el patrón",
    "with_adequate_spacing_to_demonstrate": "con un espaciado adecuado para demostrar las relaciones jerárquicas. La salida debe ser textual, formateada para renderizarse correctamente con Mermaid y sin palabras en mayúsculas ni símbolos especiales aparte de los necesarios para la sintaxis de Mermaid.",
    "we_are_unable_to_create_a_mindmap": "No podemos crear un mapa mental basado en el contenido proporcionado. ¡Puedes intentar abrir la configuración del widget y cambiarla para obtener mejores resultados!",
    "something_went_awry": "¡Algo salió mal! Pero intentaremos generar tu mapa nuevamente.",
    "please_generate_a_mermaid_mind": "Por favor, genera un mapa mental de Mermaid basado en {context} en",
    "language": "idioma"
  },
  "mermaidMindmapWidgetMapEditor": {
    "map_editor": "Editor de mapas",
    "manipulate_the_structure_and": "Manipula la estructura y el flujo de tu mapa fácilmente ajustando las sangrías y agregando nuevas líneas para crear nodos, facilitando una visualización personalizada e informativa de tus ideas. Prepárate para refinar y redefinir tu experiencia de mapas mentales, donde cada cambio te acerca a la perfección."
  },
  "mermaidMindmapWidgetNodesEditor": {
    "nodes_editor": "Editor de nodos",
    "dont_settle_for_nodes_that_dont_resonate": "No te conformes con nodos que no te satisfacen; con el Editor de nodos, puedes regenerar cualquier elemento sin esfuerzo para que se ajuste mejor a tu visión, o agregar nuevos nodos para ampliar tus ideas.",
    "click_here_to_re_generate_this_node": "Haz clic aquí para regenerar este nodo. Asegúrate de proporcionar instrucciones claras.",
    "click_here_to_add_a_brand_new_node": "Haz clic aquí para agregar un nodo completamente nuevo. Todos los demás nodos permanecerán intactos. Asegúrate de proporcionar instrucciones claras.",
    "add_node": "Agregar nodo",
    "add_instructions": "Agregar instrucciones",
    "enter_your_hint_here": "Ingresa tu indicación aquí",
    "this_node": "Este nodo",
    "and_all_it_s_children_will": "y todos sus hijos se volverán a renderizar.",
    "you_have_the_option_to": "Tienes la opción de mejorar tu mapa mental agregando un nuevo nodo.",
    "feel_free_to_include_any_specific": "Si es necesario, siéntete libre de incluir detalles o requisitos específicos como indicación."
  }
}