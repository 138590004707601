import { type ApiContext, callQaApi } from '@/api';

export async function getQa(
  params: {
    limit?: number;
    offset?: number;
    query?: string;
  },
  context: ApiContext,
): Promise<any> {
  const queryParams = new URLSearchParams(params as any).toString();
  return await callQaApi(
    {
      method: 'GET',
      path: [`/?${queryParams}`],
    },
    context,
  );
}
