{
  "whatsAppWidget": {
    "access_token": "Access token",
    "phone_id": "Phone ID",
    "save": "Save",
    "how_to_connect": "How to connect Omnimind AI WhatsApp chatbot",
    "make_sure_your_question": "Make sure your question is clear and concise to get the most accurate answers. You can update or change the project in the action settings if you wish to use different data for analysis.",
    "create_meta_business_account": "Create Meta (Facebook) Business Account",
    "create_new_meta_app": "Create new Meta (Facebook) app",
    "login_to_your_account": "Login to your account",
    "select_my_apps": "Select \"My apps\"",
    "select_create_app": "Select \"Create App\"",
    "select_options": "Select options",
    "use_case_other": "Use case: Other",
    "app_type_business": "App type: Business",
    "enter_app_details": "Enter app details",
    "app_name": "App name",
    "app_contact_email": "App contact email",
    "business_account": "Business Account (optional)",
    "add_whatsapp_to_your_meta_app": "Add WhatsApp to your Meta app",
    "find_add_products_to_your_app": "Find \"Add products to your app\"",
    "select_whatsapp_set_up": "Select ‘WhatsApp’ Set up",
    "quick_start": "Quick Start",
    "select_business_account": "Select Business Account",
    "purchase_new_business_number": "Purchase a new business number for WhatsApp",
    "select_api_setup": "Select 'API Setup' and find 'Step 5'",
    "click_on_the_button": "Click on the button \"Add Phone Number\"",
    "fill_in_all_fields": "Fill in all fields and confirm your phone number",
    "skip_if_already_have": "Skip if you already have a business number you can use for your WhatsApp bot",
    "configure_whatsapp_settings": "Configure WhatsApp settings",
    "select_configure_webhooks": "Select ‘Configure webhooks’ on the 'API Setup' page",
    "edit_webhook": "‘Edit’ webhook",
    "add_a_callback_url_and_verify_token": "Add a callback URL and Verify token.",
    "callback_url": "Callback URL",
    "verify_token": "Verify token",
    "manage_webhook_fields": "‘Manage’ webhook fields",
    "add_and_subscribe_only_to": "Add and subscribe only to",
    "add_a_payment_method_to_your_whatsapp": "Add a payment method to your WhatsApp app",
    "payment_settings": "Payment settings",
    "add_payment_method": "Add payment method",
    "add_phone_number_and_business_details": "Add phone number (and business details)",
    "provide_necessary_info": "Provide necessary info",
    "verify_number": "Verify number",
    "send_a_test_message": "Send a test message",
    "select_api_setup_and_find_step_1": "Select ‘API Setup’ and find ‘Step 1’:",
    "select_the_from_number": "Select the ‘From’ number",
    "choose_the_option_to_add_and_verify_a_new_number": "Choose the option to add and verify a new number",
    "select_to_number": "Select ‘To’ number (add a personal number for testing)",
    "select_send_message": "Select ‘Send Message’",
    "update_business_details_for_your_meta": "Update business details for your Meta app",
    "select_settings_basic": "Within your Meta app, select ‘Settings > Basic’",
    "fill_in_all_the_necessary_information": "Fill in all the necessary information",
    "verify_business_account": "Verify Business Account",
    "select_settings": "Select ‘Settings’",
    "select_business_account_info": "Select ‘Business Account Info’",
    "provide_all_the_necessary_information": "Provide all the necessary information",
    "select_security_centre": "Select ‘Security Centre’",
    "select_start_verification": "Select ‘Start Verification’",
    "create_a_system_user": "Create a System User in your Business Account",
    "settings_users_system_users": "Settings > Users > System Users",
    "create_new_system_user": "Create new system user",
    "name_anything": "Name (anything)",
    "role_admin": "Role: Admin",
    "select_add_assets": "Select ‘Add Assets’",
    "select_apps": "Select ‘Apps’",
    "select_the_new_app_for_whatsapp": "Select the new app (for WhatsApp)",
    "toggle_manage_app": "Toggle ‘Manage app’",
    "generate_new_token_for_system_user": "Generate new token for system user",
    "select_app_and_generate_new_token": "Select app and ‘Generate new token’",
    "select_never_expire": "Select ‘Never’ expire",
    "select_only_permissions_whatsapp_business_messaging": "Select only permissions: whatsapp_business_messaging",
    "select_generate_token": "Select ‘Generate token’",
    "important_save_this_token": "Important: Save this token somewhere, you’ll need it",
    "add_token_and_phone_id": "Add Token and Phone ID at the beginning of this page",
    "phone_id_this_is_from": "‘Phone ID’ — this is from your Meta app (API setup, step 5), ensure you select the correct ‘From’ number and copy the phone ID from here",
    "token_this_is_from_step_8": "‘Token’ — this is from Step 8, from the system user in your Business Account",
    "launch_your_whatsApp_integration": "Launch your WhatsApp integration",
    "set_your_meta_app_from_development": "Set your Meta app from development to live (Open your Meta app from"
  }
}