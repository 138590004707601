{
  "knowledgeChunksModal": {
    "chunks": "Chunks",
    "save": "Save",
    "chunks_selected": "chunks selected",
    "no_data_so_far": "No data so far.",
    "title": "Title",
    "page": "Page",
    "size": "Size",
    "last_modified": "Last modified",
    "text": "Text",
    "actions": "Actions",
    "no_chunks_so_far": "No chunks so far",
    "all_done_your_items_were_removed_successfully": "All done! Your items were removed successfully."
  },
  "knowledgeChunksModalPagination": {
    "prev": "Prev",
    "next": "Next"
  },
  "knowledgeChunksModalSearch": {
    "quick_search": "Quick search",
    "search": "Search"
  },
  "knowledgeFilterModal": {
    "filter": "Filter",
    "apply": "Apply",
    "reset": "Reset",
    "by_name": "By name",
    "by_title": "By title",
    "by_period": "By period",
    "search_for_resource_name": "Search for resource name...",
    "search_for_resource_title": "Search for resource title...",
    "last_year": "Last year",
    "quarter": "Quarter",
    "month": "Month",
    "week": "Week",
    "day": "Day"
  }
}