{
  "searchWidget": {
    "search": "buscar",
    "we_are_creating_examples_of_questions": "Estamos creando ejemplos de preguntas que puedes hacerle al bot, pero puedes usar el chat ahora.",
    "sorry_we_could_not_create_any_examples": "Lo sentimos, no pudimos crear ejemplos de preguntas que puedas hacerle al bot todavía.",
    "please_ask_the_owner_of": "Por favor, pregúntale al propietario del proyecto que lo haga público",
    "the_project_owner_needs_to": "El propietario del proyecto necesita actualizar la suscripción ya que la actual ha expirado.",
    "404_oops_we_havet_found": "404 ¡Ups! No hemos encontrado el proyecto",
    "loading": "Cargando...",
    "oops_it_seems_youve_reached": "¡Ups! Parece que has alcanzado el límite de mensajes. Para continuar, ajusta amablemente tu límite de mensajes en la configuración."
  }
}