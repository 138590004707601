{
  "changePassword": {
    "you_will_be_redirected": "Você será redirecionado para a página inicial em {value} segundos",
    "to_go_to_the_home_page": "Para ir para a página inicial",
    "click_here": "clique aqui",
    "enter_your_new_password": "Digite sua nova senha",
    "password_successfully_changed": "Senha alterada com sucesso"
  },
  "signIn": {
    "sign_in": "Entrar",
    "enter_your_email_and_password": "Digite seu email e senha para fazer login",
    "email": "Email",
    "enter_email": "Digite o email",
    "password": "Senha",
    "enter_password": "Digite a senha",
    "or": "OU",
    "dont_have_an_account": "Não tem uma conta?",
    "sign_up_with_email": "Cadastre-se com Email",
    "dont_remember_the_password": "Não lembra da senha?",
    "reset_password": "Redefinir senha",
    "link_expired": "Este link é inválido ou expirou.",
    "please_request_a_new_link": "Por favor, solicite um novo link.",
    "something_went_awry": "Algo deu errado!",
    "your_email_is_not_yet_verified": "Seu email ainda não foi verificado. Por favor, verifique sua caixa de entrada e siga o link de verificação para concluir o registro."
  },
  "signUp": {
    "sign_up": "Cadastrar"
  },
  "signUpForm": {
    "name": "Nome",
    "enter_first_name": "Digite o primeiro nome",
    "last_name": "Sobrenome",
    "enter_last_name": "Digite o sobrenome",
    "email": "Email",
    "enter_email": "Digite o email",
    "password": "Senha",
    "enter_password": "Digite a senha",
    "password_confirmation": "Confirmação de senha",
    "i_agree": "Eu concordo com os",
    "terms_and_conditions": "Termos e Condições",
    "and": "e",
    "privacy_policy": "Política de Privacidade",
    "by_checking_this": "Ao marcar esta opção, você reconhece e concorda com a transferência dos dados que você enviar para este projeto para serviços de terceiros, como OpenAI. Essa transferência de dados é necessária para o bom funcionamento do projeto e para aproveitar serviços externos que aprimoram suas funcionalidades.",
    "third_party_services": "Consentimento de transferência de dados para serviços de terceiros",
    "sign_up": "CADASTRAR",
    "or_sign_up_with": "Ou Cadastre-se com",
    "already_have_an_account": "Já tem uma conta?",
    "sign_in": "Entrar",
    "please_check_your_mailbox_and": "Por favor, verifique sua caixa de entrada e \n siga o link de verificação para concluir o registro. \n",
    "if_you_cant_find_it_in_your": "Se você não encontrar na sua caixa de entrada, verifique sua",
    "spam_folder": "pasta de spam",
    "as_well": "também",
    "this_field_contains_special": "Este campo contém caracteres especiais",
    "please_ensure_youve_read": "Por favor, certifique-se de ter lido e concordado com nossos Termos e Condições",
    "and_have_consented": "e ter consentido a transferência de dados para serviços de terceiros marcando as respectivas caixas",
    "before_signing_in": "antes de fazer login",
    "by_signing_up_you_agree": "Ao se cadastrar, você concorda com nossos Termos e Condições e reconhece que consentiu a transferência de seus dados para serviços de terceiros.",
    "read_our": "Leia nossa"
  }
}