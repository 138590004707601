{
  "databaseSettings": {
    "databaseSettings": "Database Settings",
    "configure_the_connection_to_your_database": "Configure the connection to your database and select the tables you want to use in your queries.",
    "cancel": "Cancel",
    "back": "Back",
    "save": "Save",
    "fetch_resources": "Fetch resources",
    "resources_fetched_successfully": "Resources fetched successfully",
    "something_went_wrong": "Something went wrong",
    "failed_to_load_settings": "Failed to load settings"
  },
  "databaseSettingsConnectInformation": {
    "connect_information": "Connect Information",
    "type_of_database": "Type of Database",
    "host": "Host",
    "port": "Port",
    "database": "Database",
    "username": "Username",
    "password": "Password"
  },
  "databaseSettingsForm": {
    "host": "Host"
  },
  "databaseSettingsHost": {
    "type_of_database": "Type of Database",
    "select_type_of_connection": "Select type of connection",
    "host": "Host",
    "address_to_your_database": "address to your database",
    "address_of_your_db": "Address of your DB",
    "port_to_your_database": "port to your database",
    "port_of_your_db": "Port of your DB",
    "port": "Port",
    "username": "Username",
    "username_to_your_database": "username to your database",
    "username_of_your_db": "Username of your DB",
    "password": "Password",
    "password_to_your_database": "password to your database",
    "password_of_your_db": "Password of your DB",
    "database": "Database",
    "your_database_name": "your's database name",
    "database_name": "Name of your database in the DB"
  },
  "databaseSettingsTables": {
    "general_instructions": "General instructions",
    "there_goes_general_description_about": "There goes general description about database, it's logic or some project-specific details...",
    "select_all": "Select all",
    "clean_all_selected": "Clean all selected",
    "search": "Search...",
    "active": "Active",
    "table": "Table",
    "comment": "Comment",
    "description": "Description"
  },
  "databaseSettingsURL": {
    "host": "Host",
    "address_to_your_database": "address to your database",
    "address_of_your_db": "Address of your DB"
  }
}