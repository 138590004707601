{
  "audioInformerInformers": {
    "language": "Idioma",
    "select_files_to_upload": "Seleccionar archivos para cargar",
    "for_advanced_users_only": "¡Solo para usuarios avanzados!",
    "advanced_settings": "Configuración avanzada",
    "your_uploaded_files": "Tus archivos cargados",
    "file": "Archivo",
    "was_added_previously": "fue agregado previamente",
    "an_error_occurred_while": "Ocurrió un error al cargar un archivo"
  },
  "baseInformerInformers": {
    "project_does_not_exist_in_data_base_with": "El proyecto no existe en la base de datos con"
  },
  "fileInformerInformers": {
    "select_files_to_upload": "Seleccionar archivos para cargar",
    "for_advanced_users_only": "¡Solo para usuarios avanzados!",
    "advanced_settings": "Configuración avanzada",
    "your_uploaded_files": "Tus archivos cargados",
    "looks_like_your_file": "Parece que tu archivo",
    "does_not_have_any_text": "no tiene ningún texto que podamos usar directamente. ¡No te preocupes! Puedes convertirlo fácilmente en un PDF con texto y cargarlo con PDF OCR Informer",
    "convert_it_into_a_text_filled_pdf": "convertirlo en un PDF con texto y cargarlo con PDF OCR Informer",
    "upgrade_your_plan_and": "actualiza tu plan y conviértelo en un PDF con texto y cárgalo con PDF OCR Informer",
    "file": "Archivo",
    "was_added_previously": "fue agregado previamente",
    "your": "Tu",
    "has_been_successfully_uploaded": "se ha cargado correctamente",
    "an_error_occurred_while": "Ocurrió un error al cargar el archivo"
  },
  "notionInformerInformers": {
    "your": "Tu",
    "a_secure_integration_secret": "* Un secreto de integración seguro generado desde tu cuenta de Notion, asegurando una integración sin problemas y segura.",
    "integration_token": "Token de integración",
    "page_url": "URL de la página",
    "page_should_be_shared": "* Tu página de Notion debe compartirse con tu integración.",
    "your_uploaded_links": "Tus enlaces cargados:",
    "not_valid_url": "El valor no es una dirección URL válida",
    "add": "Agregar"
  },
  "pdfOcrFileInformerInformers": {
    "select_files_to_upload": "Seleccionar archivos para cargar",
    "for_advanced_users_only": "¡Solo para usuarios avanzados!",
    "advanced_settings": "Configuración avanzada",
    "your_uploaded_files": "Tus archivos cargados",
    "great_news_your_file": "¡Buenas noticias! Tu archivo",
    "is_a_straightforward_pdf": "es un PDF sencillo y no requiere un procesamiento OCR especial. Por favor, continúa y",
    "upload_it_using_our": "cárgalo usando nuestro File Informer",
    "for_a_smoother_experience": "para una experiencia más fluida",
    "file": "Archivo",
    "was_added_previously": "fue agregado previamente",
    "your": "Tu",
    "has_been_successfully_uploaded": "se ha cargado correctamente",
    "an_error_occurred_while": "Ocurrió un error al cargar el archivo"
  },
  "singleWebInformerInformers": {
    "type_in_your_url_here": "Escribe tu URL aquí",
    "preview": "Vista previa",
    "page_content": "Contenido de la página",
    "preview_content": "Vista previa del contenido",
    "project_does_not_exist_in_data_base_with": "El proyecto no existe en la base de datos con"
  },
  "uploadAdvancedSettingsInformers": {
    "embeddings_provider": "Proveedor de embeddings",
    "you_can_specify_the": "Puedes especificar el proveedor de embeddings que procesará tu información."
  },
  "webInformerInformers": {
    "paste_your_batch_of_links_here": "Pega tu grupo de enlaces aquí",
    "add_urls": "Agregar URLs",
    "your_uploaded_links": "Tus enlaces cargados:",
    "page_content": "Contenido de la página",
    "preview_content": "Vista previa del contenido",
    "must_be_filled_with_a_value": "Debe ser completado con un valor.",
    "invalid_urls": "URLs inválidas",
    "project_does_not_exist_in_data_base_with": "El proyecto no existe en la base de datos con"
  },
  "wordpressInformerInformers": {
    "import_from_url": "Importar desde URL",
    "add": "Agregar",
    "remove": "Eliminar",
    "couldn_t_get_retrieve_the_wordpress": "No se pudo obtener el sitio de WordPress. Puedes intentar usar el Informer de un solo sitio o especificar otra URL.",
    "go_to_website_informer": "Ir al informer del sitio web",
    "select_the_types_of_content": "Selecciona los tipos de contenido que te interesa recibir del sitio web."
  }
}