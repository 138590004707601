{
  "infoMessageOne": {
    "here_you_can_specify_the_depth_of_the_web_site_scraping": "Hier können Sie die Tiefe des Webseiten-Scrapings angeben",
    "not_deeper_than_the_specified_url": "nicht tiefer als die angegebene URL",
    "the_scraper_goes_one_level_deeper": "Der Scraper geht eine Ebene tiefer, folgt jedoch nicht den Links auf der zweiten Ebene",
    "the_scraper_goes_two_levels_deeper": "Der Scraper geht zwei Ebenen tiefer, folgt jedoch nicht den Links auf der dritten Ebene",
    "the_scraper_goes_three_levels_deeper": "Der Scraper geht drei Ebenen tiefer, folgt jedoch nicht den Links auf der vierten Ebene"
  },
  "webInformerAdvancedSettingItems": {
    "depth": "Tiefe:",
    "page_limit": "Seitenlimit:",
    "enter_page_limit": "Seitenlimit eingeben",
    "page_filter": "Seitenfilter:",
    "glob_mask": "Glob-Maske",
    "define_the_update_period": "Definieren Sie den Aktualisierungszeitraum",
    "if_you_are_not_sure_how_many_links": "Wenn Sie nicht sicher sind, wie viele Links auf der Website vorhanden sind, können Sie das Limit der gescrapten Seiten festlegen.",
    "here_you_can_specify_the_regularity": "Hier können Sie die Regelmäßigkeit angeben, wie oft der angegebene Link erneut gescraped wird, um die Informationen zu aktualisieren.",
    "you_can_lock_scraper_to_parse": "Sie können den Scraper sperren, um Seiten mit einem bestimmten URL-Muster gemäß",
    "hourly": "stündlich",
    "daily": "täglich",
    "weekly": "wöchentlich",
    "monthly": "monatlich"
  },
  "webInformerBtnAdvancedSetting": {
    "advanced_link_setting": "Erweiterte Link-Einstellung"
  },
  "webList": {
    "preview": "Vorschau"
  }
}