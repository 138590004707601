{
  "bulkCsvInformerInformers": {
    "select_csv_files_to_upload": "Seleziona i file CSV da caricare",
    "bulk_upload_via_csv": "La funzione di caricamento in blocco tramite CSV consente di caricare i collegamenti elencati in un file CSV.",
    "drag_drop_or": "Trascina e rilascia o",
    "select_a_csv_file": "Seleziona un file CSV",
    "to_upload": "per caricare",
    "download_example_csv": "Scarica l'esempio di CSV",
    "in_column": "Nella colonna",
    "we_found": "abbiamo trovato",
    "valid_links": "collegamenti validi",
    "there_are_no_valid_links_in_this_column": "Non ci sono collegamenti validi in questa colonna.",
    "error_uploading_file": "Errore durante il caricamento del file:"
  },
  "bulkCsvInformerMapperInformers": {
    "map_file_columns": "Mappa le colonne del file",
    "specify_the_corresponding_column": "Specifica la colonna corrispondente nel file CSV che contiene gli URL. Questa colonna dovrebbe essere selezionata per il processo. Se gli URL nella colonna non includono \"https://\", il sistema lo aggiungerà automaticamente.",
    "link": "Collegamento"
  },
  "csvInformerInformers": {
    "select_csv_file_to_upload": "Seleziona il file CSV da caricare",
    "drag_drop_or": "Trascina e rilascia o",
    "select_a_csv_file": "Seleziona un file CSV",
    "to_upload": "per caricare",
    "download_example_csv": "Scarica l'esempio di CSV",
    "invalid_file_type": "Tipo di file non valido. Carica un file CSV.",
    "invalid_mime_type": "Tipo MIME non valido. Il file non sembra essere un CSV.",
    "error_parsing_csv": "Errore durante l'analisi del CSV:",
    "error_uploading_file": "Errore durante il caricamento del file:",
    "something_went_awry": "Qualcosa è andato storto!"
  },
  "csvInformerAdvancedSettingsInformers": {
    "advanced_settings": "Impostazioni avanzate",
    "hide_settings": "Nascondi impostazioni",
    "show_settings": "Mostra impostazioni",
    "select_title_columns": "Seleziona le colonne del titolo",
    "using_a_direct_custom_prompt": "L'utilizzo di un prompt personalizzato diretto è necessario solo in casi speciali e disabiliterà il modulo principale.",
    "select_metadata_columns": "Seleziona le colonne dei metadati"
  },
  "csvInformerMapperInformers": {
    "map_file_columns": "Mappa le colonne del file",
    "content": "Contenuto",
    "meta": "Meta"
  },
  "customiseColumnsListInformers": {
    "name": "Nome",
    "label": "Etichetta",
    "select_all": "Seleziona tutto"
  },
  "previewCSVInformers": {
    "preview_csv_data": "Anteprima dati CSV",
    "more_data": "Altri dati..."
  }
}