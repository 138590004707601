{
  "searchWidget": {
    "search": "Suche",
    "we_are_creating_examples_of_questions": "Wir erstellen Beispiele für Fragen, die Sie dem Bot stellen können, aber Sie können den Chat jetzt nutzen.",
    "sorry_we_could_not_create_any_examples": "Entschuldigung, wir konnten noch keine Beispiele für Fragen erstellen, die Sie dem Bot stellen können.",
    "please_ask_the_owner_of": "Bitte fragen Sie den Projekteigentümer, es öffentlich zu machen",
    "the_project_owner_needs_to": "Der Projekteigentümer muss das Abonnement aktualisieren, da das aktuelle abgelaufen ist.",
    "404_oops_we_havet_found": "404 Ooops, wir haben das Projekt nicht gefunden",
    "loading": "Wird geladen...",
    "oops_it_seems_youve_reached": "Hoppla! Es scheint, dass Sie Ihr Nachrichtenlimit erreicht haben. Um fortzufahren, passen Sie bitte Ihr Limit in den Einstellungen an."
  }
}