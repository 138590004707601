{
  "useWebPreview": {
    "do_you_see_relevant_content_from_url": "¿Ves contenido relevante desde la URL? Si no es así, intenta obtenerlo de otra manera.",
    "could_not_get_the_preview": "No se pudo obtener la vista previa. Por favor, intenta otro método.",
    "alternative_fetch": "Obtención alternativa",
    "we_tried_to_fetch_the_content_as_it_would_be": "Intentamos obtener el contenido como lo haría el navegador Chrome. Si aún no estás satisfecho, intenta hacer clic en \"Texto alternativo\".",
    "alternative_text": "Texto alternativo",
    "we_have_demonstrated_all_the_ways": "Hemos demostrado todas las formas en las que pudimos obtener el contenido. ¿Quieres volver al método original y previsualizarlo?",
    "back_to_origin": "Volver al origen"
  },
  "useMaximumNumberOfProjectsAlert": {
    "limit_error": "Parece que has alcanzado el número máximo de proyectos. Para comenzar un nuevo proyecto, considera archivar o eliminar los existentes que ya no necesitas, o explora nuestros planes de suscripción para obtener límites más altos.",
    "upgrade": "Actualizar"
  },
  "Preview": "Vista previa"
}