{
  "searchWidget": {
    "search": "пошук",
    "we_are_creating_examples_of_questions": "Ми створюємо приклади питань, на які ви можете запитати бота, але ви можете використовувати чат зараз.",
    "sorry_we_could_not_create_any_examples": "Вибачте, ми не змогли створити жодного прикладу питань, на які ви можете запитати бота.",
    "please_ask_the_owner_of": "Будь ласка, зверніться до власника проекту, щоб зробити його публічним",
    "the_project_owner_needs_to": "Власник проекту повинен оновити підписку, оскільки поточна закінчилася.",
    "404_oops_we_havet_found": "404 Ой, ми не знайшли проект",
    "loading": "Завантаження...",
    "oops_it_seems_youve_reached": "Ой! Здається, ви досягли обмеження на кількість повідомлень. Щоб продовжити, будь ласка, налаштуйте обмеження на кількість повідомлень у налаштуваннях."
  }
}