{
  "portalProjectItem": {
    "your_link_name": "Nome do seu link",
    "delete_this_project": "Excluir este projeto?",
    "delete_project": "Excluir projeto",
    "project_will_be_deleted": "O projeto será excluído. Isso não pode ser desfeito"
  },
  "portalProjects": {
    "no_projects_so_far": "Nenhum projeto até agora",
    "apply": "Aplicar",
    "link_name": "Nome do link",
    "project": "Projeto",
    "widget": "Widget",
    "actions": "Ações",
    "the_item_has_been_successfully_deleted": "O item foi excluído com sucesso.",
    "projects_successfully_updated": "Projetos atualizados com sucesso."
  }
}