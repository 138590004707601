import { computed } from 'vue';
import { WidgetTypeKeysEnum } from '@/types/WidgetTypeKeysEnum';
import { useAuthorization } from '@/plugins/authorization';
import { useUserCan } from '@/composables/useUserCan';
import { UserPermissionEnum } from '@/interfaces/UserPermissionEnum';

const witoutSettings = new Set([
  WidgetTypeKeysEnum.InteractiveMindmapWidget,
  WidgetTypeKeysEnum.MermaidMindmapWidget,
  WidgetTypeKeysEnum.RetellingWidget,
  WidgetTypeKeysEnum.SummaryWidget,
]);

const embedWidgets = new Set([
  WidgetTypeKeysEnum.SearchWidget,
  WidgetTypeKeysEnum.ExplainWidget,
  WidgetTypeKeysEnum.ChatWidget,
  WidgetTypeKeysEnum.VoiceWidget,
  WidgetTypeKeysEnum.DocSearchWidget,
  WidgetTypeKeysEnum.PopupChatWidget,
]);

const dialogWidgets = new Set([WidgetTypeKeysEnum.ChatWidget, WidgetTypeKeysEnum.PopupChatWidget]);

const sharedWidgets = new Set([
  WidgetTypeKeysEnum.SearchWidget,
  WidgetTypeKeysEnum.ExplainWidget,
  WidgetTypeKeysEnum.ChatWidget,
  WidgetTypeKeysEnum.PopupChatWidget,
  WidgetTypeKeysEnum.VoiceWidget,
]);

const gptWidgets = new Set([
  WidgetTypeKeysEnum.SearchWidget,
  WidgetTypeKeysEnum.ExplainWidget,
  WidgetTypeKeysEnum.ChatWidget,
  WidgetTypeKeysEnum.VoiceWidget,
  WidgetTypeKeysEnum.PopupChatWidget,
  WidgetTypeKeysEnum.DocSearchWidget,
  WidgetTypeKeysEnum.RetellingWidget,
  WidgetTypeKeysEnum.InteractiveMindmapWidget,
  WidgetTypeKeysEnum.MermaidMindmapWidget,
  WidgetTypeKeysEnum.DataExtractorWidget,
  WidgetTypeKeysEnum.QuizWidget,
  WidgetTypeKeysEnum.FlashcardsWidget,
]);

const withoutCustomize = new Set([
  WidgetTypeKeysEnum.DataExtractorWidget,
  WidgetTypeKeysEnum.QuizWidget,
  WidgetTypeKeysEnum.FlashcardsWidget,
  WidgetTypeKeysEnum.InteractiveMindmapWidget,
  WidgetTypeKeysEnum.MermaidMindmapWidget,
  WidgetTypeKeysEnum.RetellingWidget,
  WidgetTypeKeysEnum.SummaryWidget,
]);

const withoutPrompt = new Set([
  WidgetTypeKeysEnum.RetellingWidget,
  WidgetTypeKeysEnum.InteractiveMindmapWidget,
  WidgetTypeKeysEnum.MermaidMindmapWidget,
  WidgetTypeKeysEnum.DataExtractorWidget,
  WidgetTypeKeysEnum.QuizWidget,
  WidgetTypeKeysEnum.FlashcardsWidget,
  WidgetTypeKeysEnum.SummaryWidget,
]);

const withoutAnonymizer = new Set([
  WidgetTypeKeysEnum.DataExtractorWidget,
  WidgetTypeKeysEnum.InteractiveMindmapWidget,
  WidgetTypeKeysEnum.MermaidMindmapWidget,
  WidgetTypeKeysEnum.TableWidget,
  WidgetTypeKeysEnum.FlashcardsWidget,
  WidgetTypeKeysEnum.QuizWidget,
  WidgetTypeKeysEnum.RetellingWidget,
  WidgetTypeKeysEnum.SummaryWidget,
  WidgetTypeKeysEnum.VoiceWidget,
]);

const withoutBehaviour = new Set([
  WidgetTypeKeysEnum.SummaryWidget,
  WidgetTypeKeysEnum.DataExtractorWidget,
  WidgetTypeKeysEnum.FlashcardsWidget,
  WidgetTypeKeysEnum.InteractiveMindmapWidget,
  WidgetTypeKeysEnum.MermaidMindmapWidget,
  WidgetTypeKeysEnum.QuizWidget,
  WidgetTypeKeysEnum.RetellingWidget,
]);

const hasMessengers = new Set([
  WidgetTypeKeysEnum.ChatWidget,
  WidgetTypeKeysEnum.ExplainWidget,
  WidgetTypeKeysEnum.SearchWidget,
  WidgetTypeKeysEnum.PopupChatWidget,
]);

const withoutIntegrations = new Set([
  WidgetTypeKeysEnum.DataExtractorWidget,
  WidgetTypeKeysEnum.FlashcardsWidget,
  WidgetTypeKeysEnum.QuizWidget,
]);

export type GetWidgetSettingsEnabledType = {
  hasSettings: boolean;
  hasEmbed: boolean;
  hasGpt: boolean;
  hasShared: boolean;
  hasCustomize: boolean;
  hasPrompt: boolean;
  hasAnonymizer: boolean;
  hasBehaviour: boolean;
  hasDialogs: boolean;
  hasMessengers: boolean;
  hasIntegrations: boolean;
};

export const getWidgetSettingsEnabled = (
  widgetType: WidgetTypeKeysEnum,
): GetWidgetSettingsEnabledType => {
  const { package_features } = useAuthorization();
  const canCanUseExtendedSettings = computed(() =>
    useUserCan({
      permission: UserPermissionEnum.CanUseExtendedSettings,
      features: package_features.value,
    }),
  );
  return {
    hasSettings: widgetType && !witoutSettings.has(widgetType),
    hasEmbed: widgetType && embedWidgets.has(widgetType),
    hasShared: widgetType && sharedWidgets.has(widgetType),
    hasGpt: widgetType && gptWidgets.has(widgetType),
    hasCustomize:
      widgetType && !withoutCustomize.has(widgetType) && !!canCanUseExtendedSettings?.value,
    hasPrompt: widgetType && !withoutPrompt.has(widgetType),
    hasAnonymizer: widgetType && !withoutAnonymizer.has(widgetType),
    hasBehaviour: widgetType && !withoutBehaviour.has(widgetType),
    hasDialogs: widgetType && dialogWidgets.has(widgetType),
    hasMessengers: widgetType && hasMessengers.has(widgetType),
    hasIntegrations: widgetType && !withoutIntegrations.has(widgetType),
  };
};
