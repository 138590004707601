<template>
  <component :is="props.as!" :disabled="props.disabled" :class="buttonClass" :type="type">
    <svg
      v-if="props.loading"
      class="absolute h-5 w-5 animate-spin"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>

    <component :is="props.iconLeft!" :class="['mr-2 h-5 w-5', props.loading && 'invisible']" />

    <slot name="iconLeft" />

    <span class="flex items-center overflow-hidden" :class="[props.loading && 'invisible']">
      <slot />
    </span>

    <component
      :is="props.iconRight!"
      v-if="!props.loading"
      :class="['ml-2 h-5 w-5', props.loading && 'invisible']"
    />
    <slot name="iconRight" />
  </component>
</template>

<script setup lang="ts">
import { computed, type PropType } from 'vue';
import { cva } from 'class-variance-authority';

//Types
import type { ButtonType, ButtonFillType, ButtonSize } from '@/types/ButtonType';

const props = defineProps({
  iconLeft: {
    type: Function,
    default: null,
  },
  iconRight: {
    type: Function,
    default: null,
  },
  loading: Boolean,
  disabled: Boolean,
  as: {
    type: [String, Object],
    default: 'button',
  },
  intent: {
    type: String as PropType<ButtonType>,
    validator: (val: string) => ['primary', 'secondary', 'danger', 'light', 'text'].includes(val),
    default: 'secondary',
  },
  size: {
    type: String as PropType<ButtonSize>,
    default: 'medium',
  },
  fill: {
    type: String as PropType<ButtonFillType>,
    default: 'solid',
  },
  roundedClass: {
    type: String,
    default: 'rounded-[6px]',
  },
  textColor: {
    type: String,
    default: 'text-gray-700',
  },
  type: {
    type: String,
    default: 'button',
  },
  embed: {
    type: Boolean,
    default: false,
  },
});

const buttonClass = computed(() => {
  const fill = props.fill === 'solid' ? '' : `-${props.fill}`;
  const rounded = props.roundedClass;
  const textColor = props.textColor;
  const isEmbed = props.embed;

  return cva(
    `inline-flex items-center justify-center ${rounded} text-sm shadow-none whitespace-nowrap`,
    {
      variants: {
        intent: {
          primary: `btn btn${fill}-primary hover:opacity-80 hover:shadow-lg ${
            isEmbed ? '!border-[var(--color-secondary)] !bg-[var(--color-secondary)]' : ''
          }`,
          secondary: `btn ${props.fill === 'outline' ? 'btn-outline-secondary' : (props.fill === 'clear' ? 'btn-clear-secondary' : 'btn-secondary')}  hover:shadow-lg`,
          danger: `btn ${props.fill === 'outline' ? 'btn-outline-danger' : 'btn-danger'}`,
          warning: `btn btn${fill}-warning hover:opacity-80 hover:shadow-lg`,
          light: `btn ${
            props.fill === 'outline'
              ? 'border border-white text-white hover:text-dark hover:bg-white'
              : 'bg-white border-none text-dark hover:bg-gray-200'
          }`,
          text: `${textColor} hover:bg-black/5 `,
        },
        disabled: {
          true: 'disabled:!bg-gray-100 !text-gray-400 border-gray-300 dark:border-gray-300 shadow-gray-300 cursor-not-allowed',
        },
        size: {
          small: 'min-h-[36px] sm:px-[12px] px-0.5 py-[2px]',
          medium: 'min-h-[44px] px-[38px] py-[6px]',
          table: 'min-h-[44px] px-[26px] py-[6px]',
        },
      },
    },
  )({
    intent: props.intent,
    disabled: props.disabled,
    size: props.size,
  });
});
</script>

<style lang="scss">
.search-widget {
  .btn {
    &:disabled {
      --tw-shadow-color: transparent !important;
    }
  }
}
</style>
