{
  "googleSheetsAddOn": {
    "how_to_add_omnimind": "How to add Omnimind AI Google Sheets Add-On",
    "please_ensure_that_the_project": "Please ensure that the project is set to 'public.' To do this, click the 'Publish' button to make the project accessible to the public.",
    "copy_your_api_key": "Copy your API Key",
    "go_to_the_google_sheet_document": "Go to the Google Sheet document where you want to use the add on.",
    "press_the_extensions_menu_button": "Press the Extensions menu button and choose Add Ons and Get Add ons",
    "find_the_omnimind_add_on": "Find the OmniMind add on and install it",
    "after_installing_the_add_on": "After installing the Add On go to the Extensions menu, find the Omnimind add on menu item and press Launch",
    "you_will_be_prompted_to": "You will be prompted to Authorise yourself with Omnimind. Please authorise yourself, you will be redirected back to the Google document.",
    "launch_the_add_on_again": "Launch the Add on again. You will get a sidebar window where you can press Get Projects and specify which project data you want to use in the document.",
    "paste_your_api_key_in_the_provided_field": "Paste your API key in the provided field and click Get Projects.",
    "select_your_project": "Select Your Project",
    "a_list_of_your_projects_in_omnimind": "A list of your projects in Omnimind will appear.",
    "select_the_project_you_would_like_to": "Select the project you would like to use for data analysis.",
    "use_the_omni_ask_function": "Use the OMNI_ASK Function",
    "click_on_a_cell_where_you": "Click on a cell where you want the answer to appear.",
    "enter_the_function": "Enter the function",
    "your_question_here": "Your question here",
    "replace": "Replace",
    "with_the_actual_question_you": "with the actual question you want to ask.",
    "press_enter": "Press Enter",
    "getting_answers": "Getting Answers",
    "after_pressing_enter": "After pressing enter, the add-on will automatically search for the answer based on the resources uploaded in your selected project on Omnimind.",
    "the_answer_will_then_appear": "The answer will then appear in the cell where you entered the function.",
    "tips": "Tips",
    "make_sure_your_question": "Make sure your question is clear and concise to get the most accurate answers. You can ask multiple questions at once by using the OMNI_ASK function in different cells. You can update or change the project in the add-on settings if you wish to use different data for analysis.",
    "support": "Support",
    "if_you_encounter_any": "If you encounter any issues or have further questions, please visit our support page at"
  }
}