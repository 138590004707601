{
  "pagesProjectsIdWidgetViewContent": {
    "let_s_upload_the_content": "Vamos carregar o conteúdo",
    "with": "Com",
    "informers": "Informantes",
    "you_can_upload_information": "você pode carregar informações de várias fontes, como sites, documentos em PDF, até mesmo pastas do Google e muito mais. Lembre-se, você sempre pode voltar a esta tela para carregar conteúdo adicional para o seu projeto",
    "upload_information": "Carregar informações de várias fontes, como sites, documentos em PDF, até mesmo pastas do Google e muito mais. Lembre-se, você sempre pode voltar a esta tela para carregar conteúdo adicional para o seu projeto sempre que precisar.",
    "whenever_you_need": "sempre que precisar",
    "select_widget": "Selecionar widget",
    "back": "Voltar",
    "we_are_working_on_it": "Estamos trabalhando nisso.",
    "is_not_supported_for_project_with": "não é suportado para o projeto com dados carregados. Por favor, crie um novo projeto para este."
  },
  "pagesProjectsIdWidgetMyWidgets": {
    "delete_widget": "Excluir Widget",
    "confirm_widget_deletion": "Confirmar Exclusão do Widget",
    "are_you_sure_you_want_to_delete": "Tem certeza de que deseja excluir o widget {widgetName}? Essa ação não pode ser desfeita."
  }
}