{
  "knowledgeChunksModal": {
    "chunks": "Chunks",
    "save": "Speichern",
    "chunks_selected": "ausgewählte Chunks",
    "no_data_so_far": "Bisher keine Daten.",
    "title": "Titel",
    "page": "Seite",
    "size": "Größe",
    "last_modified": "Zuletzt geändert",
    "text": "Text",
    "actions": "Aktionen",
    "no_chunks_so_far": "Bisher keine Chunks",
    "all_done_your_items_were_removed_successfully": "Fertig! Ihre Elemente wurden erfolgreich entfernt."
  },
  "knowledgeChunksModalPagination": {
    "prev": "Zurück",
    "next": "Weiter"
  },
  "knowledgeChunksModalSearch": {
    "quick_search": "Schnellsuche",
    "search": "Suche"
  },
  "knowledgeFilterModal": {
    "filter": "Filter",
    "apply": "Anwenden",
    "reset": "Zurücksetzen",
    "by_name": "Nach Name",
    "by_title": "Nach Titel",
    "by_period": "Nach Zeitraum",
    "search_for_resource_name": "Nach Ressourcennamen suchen...",
    "search_for_resource_title": "Nach Ressourcentitel suchen...",
    "last_year": "Letztes Jahr",
    "quarter": "Quartal",
    "month": "Monat",
    "week": "Woche",
    "day": "Tag"
  }
}