{
  "projectSettingsTeam": {
    "user_access_rights_successfully_updated": "User access rights successfully updated!",
    "something_went_awry": "Something went awry!",
    "user_with_email": "User with email",
    "has_been_successfully_invited_to_you_project": "has been successfully invited to you project!",
    "already_exists": "already exists"
  },
  "teamInvite": {
    "enter_email_to_invite_people_to_this_project": "Enter email to invite people to this project",
    "email": "Email",
    "send_invite": "Send invite",
    "can_view": "Can View",
    "can_edit": "Can Edit"
  },
  "teamLink": {
    "project_link": "Project link",
    "copy_link": "Copy Link",
    "please_note_that_any_activity": "Please note that any activity of the users who you share this link with is bound to your account balance.",
    "url_link_copied_just_paste_the_link": "URL link copied! Just paste the link to anyone you want to share the project"
  },
  "teamsList": {
    "members": "Members"
  },
  "teamsListItem": {
    "can_view": "can view",
    "can_edit": "can edit",
    "remove": "remove",
    "you": "(you)",
    "owner": "owner",
    "pending": "(pending)"
  }
}