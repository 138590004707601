{
  "bulkCsvInformerInformers": {
    "select_csv_files_to_upload": "Select CSV files to upload",
    "bulk_upload_via_csv": "The Bulk Upload via CSV feature allows you to upload links listed in a CSV file.",
    "drag_drop_or": "Drag & Drop or",
    "select_a_csv_file": "Select a CSV file",
    "to_upload": "to upload",
    "download_example_csv": "Download Example CSV",
    "in_column": "In column",
    "we_found": "we found",
    "valid_links": "valid links",
    "there_are_no_valid_links_in_this_column": "There are no valid links in this column.",
    "error_uploading_file": "Error uploading file:"
  },
  "bulkCsvInformerMapperInformers": {
    "map_file_columns": "Map file columns",
    "specify_the_corresponding_column": "Specify the corresponding column in the CSV file that contains the URLs. This column should be selected for processing. If the URLs in the column do not include \"https://\", the system will automatically add it.",
    "link": "Link"
  },
  "csvInformerInformers": {
    "select_csv_file_to_upload": "Select CSV file to upload",
    "drag_drop_or": "Drag & Drop or",
    "select_a_csv_file": "Select a CSV file",
    "to_upload": "to upload",
    "download_example_csv": "Download Example CSV",
    "invalid_file_type": "Invalid file type. Please upload a CSV file.",
    "invalid_mime_type": "Invalid MIME type. The file does not seem to be a CSV.",
    "error_parsing_csv": "Error parsing CSV:",
    "error_uploading_file": "Error uploading file:",
    "something_went_awry": "Something went awry!"
  },
  "csvInformerAdvancedSettingsInformers": {
    "advanced_settings": "Advanced Settings",
    "hide_settings": "Hide Settings",
    "show_settings": "Show Settings",
    "select_title_columns": "Select Title Columns",
    "using_a_direct_custom_prompt": "Using a direct custom prompt is only necessary in special cases, and it will disable the main form.",
    "select_metadata_columns": "Select Metadata Columns"
  },
  "csvInformerMapperInformers": {
    "map_file_columns": "Map file columns",
    "content": "Content",
    "meta": "Meta"
  },
  "customiseColumnsListInformers": {
    "name": "Name",
    "label": "Label",
    "select_all": "Select All"
  },
  "previewCSVInformers": {
    "preview_csv_data": "Preview CSV Data",
    "more_data": "More data..."
  }
}