import { qAWidgetSettings } from '@/components/project/settings/defaults/qAWidgetSettings';
import { SettingsPropertiesEnum } from '@/types/SettingsPropertiesEnum';
import { anonymizerSettings } from '@components/project/settings/defaults/anonymizerSettings';

/* eslint-disable @typescript-eslint/no-unused-vars */
const {
  [SettingsPropertiesEnum.PLACEHOLDER]: _,
  [SettingsPropertiesEnum.BOT_NAME]: _ignore,
  ...restQASettings
} = qAWidgetSettings;
/* eslint-enable @typescript-eslint/no-unused-vars */

export const docSearchWidgetSettings = {
  ...restQASettings,
  ...anonymizerSettings,
  [SettingsPropertiesEnum.LOGO]: {
    id: '',
    src: '',
  },
  [SettingsPropertiesEnum.WELCOME_MESSAGE]: 'Hi there 👋, how can I help ?',
  [SettingsPropertiesEnum.WELCOME_MESSAGE_IS_ACTIVE]: true,
  [SettingsPropertiesEnum.IS_AUTO_LANGUAGE]: false,
  [SettingsPropertiesEnum.FORM_LANGUAGE]: 'English',
  [SettingsPropertiesEnum.RESOURCES]: true,
  [SettingsPropertiesEnum.MOST_LIKELY_ENABLED]: true,
};
