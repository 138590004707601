import { gql } from '@apollo/client/core';

export const GET_PACKAGES = gql`
  query GetPackages($type: package_type, $portal_id: uuid!) {
    packages(where: { type: { _eq: $type }, , solution_scope: { _eq: "omni" }, portal_id: { _eq: $portal_id } }) {
      features
      description
      units_amount
      has_trial
      id
      is_popular
      portal_id
      costs
      name
      params
      prices
      trial_params
      type
    }
  }
`;
