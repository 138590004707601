{
  "chatWidget": {
    "we_are_creating_suggestion": "Stiamo creando domande suggerite per te. Ma puoi usare la chat ora",
    "type_your_question": "Spiacenti, non siamo ancora riusciti a creare alcun {title}.",
    "processing_your_request": "Elaborazione della tua richiesta...",
    "ooops_we_have_t_found": "404 Ooops, non abbiamo trovato il progetto",
    "please_ask_the_owner_of": "Chiedi al proprietario del progetto di renderlo pubblico",
    "the_project_owner_needs_to": "Il proprietario del progetto deve aggiornare l'abbonamento in quanto quello attuale è scaduto.",
    "oops_it_seems_youve_reached": "Oops! Sembra che tu abbia raggiunto il limite di messaggi consentiti. Per continuare, regola gentilmente il tuo limite di velocità nelle impostazioni."
  }
}