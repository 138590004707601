{
  "settingsAppearance": {
    "appearance": "Aparência",
    "bot_name": "Nome do bot",
    "brand_name": "Nome da marca",
    "enter_custom_bot_name": "Digite o nome personalizado do bot",
    "enter_custom_brand_name": "Digite o nome personalizado da marca",
    "change_the_widget_color_style": "Alterar o estilo de cor do widget",
    "change_the_header_font_size_px": "Alterar o tamanho da fonte do cabeçalho (px)",
    "change_the_body_font_size_px": "Tamanho da fonte",
    "hide_powered_by_label": "Ocultar o rótulo 'Powered by'",
    "show_answer_sources_option": "Mostrar opção de fontes de resposta",
    "hide_the_full_site_overlay": "A sobreposição do site completo é um recurso que permite criar um ponto de foco em seu site, escurecendo o plano de fundo e exibindo o widget.",
    "hide_overlay": "Ocultar sobreposição",
    "change_placeholder_text_of_the_form_s_input": "Alterar o texto do espaço reservado da entrada do formulário",
    "enter_custom_placeholder_text": "Digite o texto personalizado do espaço reservado...",
    "submit_button_s_text": "Texto do botão de envio",
    "enter_text_for_submit_button": "Digite o texto para o botão de envio...",
    "failed_to_upload_branding_logo": "Falha ao fazer upload do logotipo da marca",
    "change_font_family": "Alterar a família da fonte",
    "show_on_mobile": "Mostrar no celular",
    "show_on_tablet": "Mostrar no tablet"
  }
}