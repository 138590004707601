{
  "pagesProjectsIdWidgetViewContent": {
    "let_s_upload_the_content": "Завантажте вміст",
    "with": "З",
    "informers": "Інформатори",
    "you_can_upload_information": "Ви можете завантажити інформацію з різних джерел, таких як веб-сайти, PDF-документи, навіть папки Google та інше. Пам'ятайте, що ви завжди можете повернутися до цього екрану, щоб завантажити додатковий вміст у свій проект",
    "upload_information": "Завантажте інформацію з різних джерел, таких як веб-сайти, PDF-документи, навіть папки Google та інше. Пам'ятайте, що ви завжди можете повернутися до цього екрану, щоб завантажити додатковий вміст у свій проект, коли вам зручно.",
    "whenever_you_need": "коли вам зручно",
    "select_widget": "Виберіть віджет",
    "back": "Назад",
    "we_are_working_on_it": "Ми працюємо над цим.",
    "is_not_supported_for_project_with": "не підтримується для проекту з завантаженими даними. Будь ласка, створіть новий проект для цього"
  },
  "pagesProjectsIdWidgetMyWidgets": {
    "delete_widget": "Видалити віджет",
    "confirm_widget_deletion": "Підтвердження видалення віджета",
    "are_you_sure_you_want_to_delete": "Ви впевнені, що хочете видалити віджет {widgetName}? Цю дію неможливо скасувати."
  }
}