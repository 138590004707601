{
  "package": {
    "popular": "popolare",
    "month": "mese",
    "chosen": "Scelto",
    "contact_us": "Contattaci",
    "package": "Pacchetto",
    "change": "Cambia",
    "subscribe": "Iscriviti",
    "projects": "Progetti",
    "characters": "Personaggi",
    "add_units": "Aggiungi unità",
    "units": "unità",
    "informers": "Informatori",
    "widgets": "Widget",
    "file_extensions": "Estensioni file",
    "alternative_ai": "IA alternativa",
    "updates": "Aggiornamenti",
    "extended_customization": "Personalizzazione estesa"
  },
  "packageEnterpriseModal": {
    "contact_us": "Contattaci",
    "submit": "Invia",
    "please_tell_us_more_about_your_use_case": "Per favore, raccontaci di più sul tuo caso d'uso",
    "share_your_expectations_from_the_plan": "Condividi le tue aspettative dal piano, come ad esempio: funzionalità richieste, numero di utenti, limiti dei personaggi, modelli di IA, ecc."
  },
  "packageFree": {
    "beginner_plan": "Piano per principianti",
    "for_beginners_and_micro_projects": "Per principianti e micro progetti",
    "free": "Gratuito",
    "project": "Progetto",
    "characters": "Personaggi",
    "overquote": "quota superata",
    "calls": "Chiamate",
    "balance": "Bilancio",
    "try_now": "Prova ora!"
  },
  "packages": {
    "beginner_plan": "Piano per principianti",
    "enterprise": "Enterprise",
    "custom_software_development": "Sviluppo di software personalizzato con hosting dedicato sulla piattaforma OmniMind",
    "integration_to_your_business": "Integrazione con la tua attività",
    "custom_dataflow": "Flusso di dati personalizzato",
    "dedicated_platform": "Piattaforma dedicata",
    "100_data_privacy": "Privacy dei dati al 100%",
    "invalid_number": "Numero non valido",
    "the_package_is_successfully_installed": "Il pacchetto è stato installato con successo!",
    "the_package_is_either_not_available": "Il pacchetto non è disponibile o è già stato installato."
  },
  "ResearcherDescription": "Per studi di livello base ed esplorazione accademica",
  "ProDescription": "Strumenti avanzati per professionisti e ricercatori esperti",
  "EinsteinDescription": "Supporto completo per scienziati e innovatori di spicco",
  "EnterpriseDescription": "Sviluppo di software personalizzato con hosting dedicato sulla piattaforma OmniMind"
}