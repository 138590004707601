{
  "chatWidget": {
    "we_are_creating_suggestion": "Wir erstellen Vorschlagsfragen für Sie. Aber Sie können den Chat jetzt nutzen",
    "type_your_question": "Entschuldigung, wir konnten noch keine {title} erstellen.",
    "processing_your_request": "Ihre Anfrage wird bearbeitet...",
    "ooops_we_have_t_found": "404 Ooops, wir haben das Projekt nicht gefunden",
    "please_ask_the_owner_of": "Bitte fragen Sie den Besitzer des Projekts, es öffentlich zu machen",
    "the_project_owner_needs_to": "Der Projektbesitzer muss das Abonnement aktualisieren, da das aktuelle abgelaufen ist.",
    "oops_it_seems_youve_reached": "Oops! Es scheint, dass Sie Ihr Nachrichtenlimit erreicht haben. Um fortzufahren, passen Sie bitte Ihr Limit in den Einstellungen an."
  }
}