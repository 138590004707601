{
  "apiKeys": {
    "api_keys": "Chaves de API",
    "please_generate_your_api_key": "Por favor, gere sua chave de API se você quiser usar nossa API de Backend",
    "api_keys_not_found": "Chaves de API não encontradas.",
    "let_s_generate_it": "Vamos gerá-la!",
    "generate_api_key": "Gerar Chave de API",
    "your_secret_api_keys_are_listed_below": "Suas chaves de API secretas estão listadas abaixo. Não compartilhe sua chave de API com outras pessoas ou a exponha no navegador ou em outro código do lado do cliente.",
    "name": "Nome",
    "key": "Chave",
    "created": "Criada",
    "expires": "Expira",
    "last_used": "Último Uso",
    "actions": "Ações",
    "name_is_not_provided": "Nome não fornecido",
    "api_key_was_update": "Chave de API foi atualizada!",
    "you_must_generate_a_new_api_key": "Você deve gerar uma nova chave de API para revogar sua única chave restante.",
    "api_key_was_revoked": "Chave de API foi revogada!"
  },
  "createApiKeyModal": {
    "create_new_secret_key": "Criar nova chave secreta",
    "name_optional": "Nome (opcional)",
    "my_test_key": "Minha Chave de Teste",
    "create_secret_key": "Criar chave secreta"
  },
  "createdNewKeyModal": {
    "created_new_secret_key": "Chave secreta criada",
    "please_save_this_secret_key": "Por favor, salve esta chave secreta em um local seguro e acessível. Por motivos de segurança, você não poderá visualizá-la novamente por meio de sua conta. Se você perder esta chave secreta, precisará gerar uma nova.",
    "api_key_copied": "Chave de API copiada!"
  },
  "presentApiKeyModal": {
    "your_secret_key": "Sua chave secreta",
    "secret_key": "Chave secreta",
    "api_key_copied": "Chave de API copiada!"
  },
  "renameApiKeyModal": {
    "edit_secret_key": "Editar chave secreta",
    "save": "Salvar",
    "edit_name": "Editar nome",
    "my_test_key": "Minha Chave de Teste"
  },
  "revokeKeyModal": {
    "revoke_secret_key": "Revogar chave secreta",
    "this_api_key_will_immediately_be_disabled": "Esta chave de API será imediatamente desativada. As solicitações de API feitas usando esta chave serão rejeitadas, o que pode fazer com que quaisquer sistemas que ainda dependam dela parem de funcionar. Depois de revogada, você não poderá mais visualizar ou modificar esta chave de API.",
    "revoke_key": "Revogar chave"
  }
}