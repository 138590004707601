{
  "caseDatabase": {
    "it_looks_like_you_ve_reached_your_maximum_number": "Parece que você atingiu o número máximo de projetos. Para iniciar um novo projeto, considere arquivar ou excluir os existentes que você não precisa mais, ou explore nossos planos de assinatura para limites mais altos."
  },
  "welcomeFlowCustomJourney": {
    "ready_for_the_next_step": "Pronto para o próximo passo em sua jornada personalizada?",
    "it_is_time_to_initiate_a_new_project": "É hora de iniciar um novo projeto. Mergulhe em uma experiência personalizada com nossa suíte de ferramentas, incluindo vários Widgets, todos projetados para organizar e analisar suas informações de forma perfeita.",
    "expand_your_projects_scope_by_integrating": "Expanda o escopo do seu projeto integrando uma variedade diversificada de conteúdo, desde sites e links individuais até documentos armazenados no Google Drive, Dropbox, One Drive, PDFs e muito mais.",
    "embark_on_this_unique_adventure": "Embarque nesta aventura única iniciando seu projeto personalizado hoje e desbloqueie todo o potencial de seus dados.",
    "create_project": "Criar Projeto",
    "or": "Ou",
    "advice": "conselho",
    "let_our_bot_provide_you_with": "deixe nosso bot fornecer a você",
    "on_how_to_make_this_process": "sobre como tornar esse processo mais fácil.",
    "project_name": "Nome do Projeto"
  },
  "wantToUpgradeCard": {
    "upgrade": "Atualizar",
    "we_understand_youre_interested_in_this": "Entendemos que você está interessado nesse recurso. Ele está disponível em nossos planos de nível superior. Considere fazer um upgrade para acessá-lo!"
  },
  "welcomeAnswerCard": {
    "you_can_select_a_tile_below": "Você pode selecionar um bloco abaixo",
    "it_looks_like_you_ve_reached_your_maximum_number": "Parece que você atingiu o número máximo de projetos. Para iniciar um novo projeto, considere arquivar ou excluir os existentes que você não precisa mais, ou explore nossos planos de assinatura para limites mais altos."
  },
  "welcomeScreen": {
    "default_goals": "Eu tenho uma série de arquivos em minha máquina local",
    "default_communicate_goals": "Eu quero que a comunidade tenha acesso 24/7 à base de conhecimento e possa conversar com ela como se fosse um humano",
    "not_sure_let_us_know": "Não tem certeza? Vamos descobrir algo em nosso",
    "what_want_to_do": "Digite o que você gostaria de fazer?",
    "what_i_want_to_do_question": "O que eu quero\n          fazer?",
    "how_communicate": "Como vou me comunicar com os dados?",
    "not_sure": "Não tem certeza?",
    "lets_find": "Vamos descobrir algo\n      em nosso",
    "what_types_of_files": "Que tipos de arquivos eu tenho e como quero enviá-los?",
    "cases_database": "Banco de casos",
    "or_start_your": "ou inicie o seu",
    "impress_me": "Impressionar-me",
    "regenerate": "Regenerar",
    "custom_journey": "Jornada personalizada",
    "project_name": "Nome do projeto",
    "enter_the_name_of_the_project": "Digite o nome do projeto",
    "enter_project_name": "Digite o nome do projeto",
    "what_i_want_to_do": "O que eu quero fazer: quero compartilhar meu conhecimento com meus alunos. Quero que eles tenham a capacidade de obter respostas 24/7.",
    "what_types_of_files_i_have_and_how_i_want": "Que tipos de arquivos eu tenho e como quero enviá-los: tenho uma série de arquivos PDF no OneDrive que quero enviar.",
    "how_i_am_going_to": "Como vou me comunicar com os dados: quero que os alunos conversem com o bot no WhatsApp.",
    "we_could_not_create_the": "Não foi possível criar o projeto corretamente.",
    "what_types_of_files_you_want_to_upload": "Que tipos de arquivos você deseja enviar?",
    "how_are_you_going_to_communicate_with_the_data": "Como você vai se comunicar com os dados?",
    "select_project_type": "Selecione o tipo de projeto",
    "agent": "Agente",
    "knowledge_legacy": "Conhecimento (legado)",
    "agent_description": "Os agentes podem conversar com o usuário com base em sua própria base de conhecimento e também realizar tarefas e ações com serviços externos através de uma variedade de Ferramentas e APIs de acordo com o cenário que você definir.",
    "knowledge_description": "Os projetos de conhecimento só podem falar com o usuário com base em seu próprio conhecimento personalizado."
  }
}
