{
  "retellingWidget": {
    "retelling": "Переказ",
    "generate_retelling": "Створити переказ",
    "context": "КОНТЕКСТ",
    "retell_in_language": "Переказати мовою {formLanguage} на {formSize} {formMethod} оригінального контексту",
    "keeping_the_tone": "зберігаючи {formTone} тон",
    "use_markdown_format": "Використовувати формат markdown",
    "add_the_primary_question": "Додати основне питання з відповіддю, яка відповідає контексту, використовуючи курсивний формат.",
    "add_main_emojis": "Додайте основні емодзі тут.",
    "add_bold_main_keywords": "Додайте жирні основні ключові слова тут.",
    "add_a_header": "Додайте заголовок як h2 markdown.",
    "add_1_sentence_quote": "Додайте 1 речення цитати найважливішої фрази контексту, використовуючи блокування markdown.",
    "if_you_have_what_to_add": "Якщо у вас є, що додати у вигляді маркерних пунктів, додайте це в кінці як список markdown.",
    "an_error_occurred_while_fetching": "Сталася помилка під час отримання переказу.",
    "something_didn_t_go_as_planned": "Щось пішло не за планом.",
    "error_occured_while_creating_pdf": "Під час створення PDF сталася помилка"
  },
  "retellingWidgetAnswer": {
    "retold": "Переказано",
    "from_original": "з оригінального ~{pages} сторінок тексту до",
    "download_pdf": "Завантажити PDF",
    "clear_retelling": "Очистити переказ",
    "retelling": "Переказ"
  },
  "retellingWidgetAnswerChunk": {
    "click_to_edit_this_block": "Клацніть, щоб редагувати цей блок",
    "click_to_adjust_this_block": "Клацніть, щоб налаштувати цей блок",
    "click_to_delete_this_block": "Клацніть, щоб видалити цей блок",
    "something_went_awry": "Щось пішло не так!",
    "you_ve_successfully_deleted": "Ви успішно видалили частину переказу!"
  },
  "retellingWidgetAnswerHeader": {
    "click_to_edit": "Клацніть, щоб редагувати заголовок переказу"
  },
  "retellingWidgetForm": {
    "you_ve_enabled_custom_prompt_in_settings": "Ви увімкнули власний запит у налаштуваннях!",
    "block_size": "Розмір блоку",
    "size_from_original": "Розмір з оригінальних ~{totalPages} сторінок тексту",
    "tone": "Тон",
    "speak_tone": "Говорити... тон",
    "language": "Мова",
    "options": "Опції",
    "add_subheaders": "додати підзаголовки",
    "add_important_quotes": "додати важливі цитати",
    "use_bullet_points": "використовувати формат маркерних пунктів",
    "add_main_question": "додати основне питання",
    "add_emojis": "додати емодзі",
    "bold_main_keywords": "жирні основні ключові слова",
    "write_it_for_a": "Напишіть це для дитини 9 років, як подорожню історію",
    "create_preview": "Створити попередній перегляд",
    "original": "оригінал"
  },
  "retellingWidgetModal": {
    "create_retelling_pdf": "Створити переказ у форматі PDF",
    "ok": "ОК",
    "wait_for_a_few_seconds_please": "Зачекайте кілька секунд, будь ласка..."
  },
  "retellingWidgetPreview": {
    "preview": "Попередній перегляд",
    "retelling_a_piece_of_content": "переказ частини вмісту",
    "refresh_preview": "Оновити перегляд"
  }
}