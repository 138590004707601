{
  "foundBug": {
    "found_a_bug": "Einen Fehler gefunden?"
  },
  "whatsNew": {
    "whats_new": "Was gibt es Neues?"
  },
  "userHeader": {
    "profile": "Profil",
    "billing": "Abrechnung"
  }
}