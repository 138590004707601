{
  "infoMessageOne": {
    "here_you_can_specify_the_depth_of_the_web_site_scraping": "Aqui você pode especificar a profundidade da raspagem do site",
    "not_deeper_than_the_specified_url": "não mais profundo do que a URL especificada",
    "the_scraper_goes_one_level_deeper": "o raspador vai um nível mais profundo, mas não segue os links no segundo nível",
    "the_scraper_goes_two_levels_deeper": "o raspador vai dois níveis mais profundos, mas não segue os links no terceiro nível",
    "the_scraper_goes_three_levels_deeper": "o raspador vai três níveis mais profundos, mas não segue os links no quarto nível"
  },
  "webInformerAdvancedSettingItems": {
    "depth": "Profundidade:",
    "page_limit": "Limite de páginas:",
    "enter_page_limit": "Digite o limite de páginas",
    "page_filter": "Filtro de página:",
    "glob_mask": "máscara glob",
    "define_the_update_period": "Defina o período de atualização",
    "if_you_are_not_sure_how_many_links": "Se você não tem certeza de quantos links existem no site, você pode definir o limite de páginas raspadas.",
    "here_you_can_specify_the_regularity": "Aqui você pode especificar a regularidade com que o link especificado será raspado novamente para atualizar as informações.",
    "you_can_lock_scraper_to_parse": "Você pode bloquear o raspador para analisar páginas com um determinado padrão de URL de acordo com",
    "hourly": "por hora",
    "daily": "diariamente",
    "weekly": "semanalmente",
    "monthly": "mensalmente"
  },
  "webInformerBtnAdvancedSetting": {
    "advanced_link_setting": "Configuração avançada de link"
  },
  "webList": {
    "preview": "Visualização"
  }
}