{
  "widgetsComponentDeleteWidgetModal": {
    "delete": "Видалити",
    "widget": "віджет",
    "do_you_really_want_to_remove": "Ви дійсно хочете видалити",
    "widget_from": "віджет з",
    "you_can_always_add_it_any_time_later": "Ви завжди можете додати його пізніше."
  },
  "widgetsComponentPasswordProjectModal": {
    "password_is_required": "Потрібен пароль",
    "enter_the_project_password": "Введіть пароль проекту",
    "submit": "Надіслати"
  }
}