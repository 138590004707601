{
  "pagesProjectsWidgetIdSettings": {
    "back": "Indietro",
    "your_settings_successfully_saved": "Le tue impostazioni sono state salvate con successo",
    "failed_to_save_settings": "Impossibile salvare le impostazioni",
    "failed_to_load_settings": "Impossibile caricare le impostazioni"
  },
  "pagesProjectsWidgetIdTeam": {
    "team": "Squadra",
    "back": "Indietro"
  },
  "pagesProjectsWidgetIdBasic": {
    "this_widget_view_is_not_ready_yet": "Questa vista del widget non è ancora pronta.",
    "add_a_new_view": "Aggiungi una nuova vista",
    "you_dont_have_any_resources": "Non hai risorse. Dovresti aggiungere un nuovo informatore.",
    "change_widget_view": "Cambia vista del widget"
  }
}