{
  "voiceMessageSpoiler": {
    "hide_transcript": "Hide transcript",
    "view_transcript": "View transcript"
  },
  "voiceStartMessage": {
    "listening_to_you": "Listening to you...",
    "welcome_to_voice_talk": "Welcome to Voice Talk",
    "widget": "Widget!",
    "lets_speak": "Let’s speak",
    "press_stop_button": "Press stop button below if you want to stop recording",
    "press_button_below_with_microphone": "Press button below with microphone to",
    "start_talking": "start talking"
  },
  "voiceWidgetAnswerMessage": {
    "copy_to_clipboard": "Copy to clipboard"
  },
  "voiceWidgetFooter": {
    "hold_and_ask_your_question": "Hold and ask your question",
    "press_to_stop_talking": "Press to stop talking"
  },
  "voiceWidgetMessageProcessing": {
    "processing_your_request": "Processing your request..."
  },
  "voiceWidgetQuestionMessage": {
    "you": "You"
  },
  "voiceWidgetQuestionSpoiler": {
    "you": "You"
  }
}