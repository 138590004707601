{
  "sidebar": {
    "it_looks_like_you_are": "Схоже, ви досягли максимальної кількості проектів. Щоб створити новий проект, розгляньте можливість архівування або видалення існуючих проектів, які вам більше не потрібні, або ознайомтесь з нашими підписними планами з більшими обмеженнями.",
    "new_project": "Новий проект",
    "select_widget": "Вибрати віджет"
  },
  "sidebarHeader": {
    "new_project": "Новий проект"
  },
  "sidebarLink": {
    "project_settings": "Налаштування проекту",
    "project_has_been_successfully": "Проект успішно {isFav} улюблений!",
    "we_encountered_an_issue": "Під час спроби оновлення улюблених виникла проблема. Будь ласка, спробуйте ще раз."
  },
  "sidebarNoProjects": {
    "there_are_no_projects": "Тут поки немає проектів"
  },
  "sidebarProjectsListFooter": {
    "create_project": "Створити проект",
    "create_a_project_using": "створити проект за допомогою",
    "bot_advice": "поради бота",
    "enter_redemption_code": "Код викупу",
    "applying": "Застосування...",
    "apply": "Застосувати",
    "oops_you_ve_reached_your_project_limit": "Ой! Ви досягли обмеження на кількість проектів для створення нових проектів. Щоб створити більше, розгляньте можливість оновлення вашого пакету!"
  },
  "sidebarProjectTree": {
    "knowledge": "Знання",
    "add_a_new_view": "Додати новий вид",
    "dialogs": "Діалоги",
    "conversations": "Розмови",
    "team": "Команда",
    "project": "Проект"
  },
  "sidebarPromocode": {
    "enter_redemption_code": "Код викупу",
    "exists_promo": "Цей промокод вже існує",
    "apply": "Застосувати"
  },
  "sidebarProjectTreeSettingsItem": {
    "settings": "Налаштування",
    "ai_model": "Модель ШІ",
    "behavior": "Поведінка",
    "appearance": "Зовнішній вигляд",
    "integrations": "Інтеграції",
    "database": "База даних"
  },
  "sidebarSharedProjectsListItem": {
    "something_went_awry": "Щось пішло не так!"
  },
  "sidebarEmailConfirmWarning": {
    "resend": "Відправити ще раз",
    "sending": "Надсилання",
    "please_check_your_inbox": "Будь ласка, перевірте свою поштову скриньку",
    "to_verify_your_email": "для підтвердження вашої електронної пошти.",
    "verification_email_is_sent": "Лист для підтвердження було відправлено.",
    "something_went_wrong": "Щось пішло не так"
  }
}