{
  "anonymizerSettings": {
    "anonymize_sensitive_data": "Anonimizar dados sensíveis",
    "language": "Idioma",
    "language_of_the_data": "Idioma dos dados",
    "replace": "Substituir",
    "replace_value": "Valor de substituição",
    "define_what_entities_must_be_anonymized": "Definir quais entidades devem ser anonimizadas",
    "anonymize_entities": "Anonimizar entidades"
  }
}