{
  "mindmapLoader": {
    "something_went_awry": "Something went awry!",
    "please_wait_while_we_generate_your": "Please wait while we generate your",
    "attempt": "Attempt"
  },
  "topicsModal": {
    "select_mind_map_topic": "Select mind map topic",
    "wait_while_we_generate_topics": "Wait, please while we generate topics based on your project context",
    "your_custom_topic": "Your custom topic"
  }
}
