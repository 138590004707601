{
  "tableDialogSQL": {
    "the_sql_query_for_your_prompt": "La consulta SQL para tu indicación"
  },
  "tableWidget": {
    "ask_your_questions_to_get_a_data": "Haz tus preguntas para obtener datos.",
    "show_sql": "Mostrar SQL",
    "export_to_csv": "Exportar a CSV",
    "failed_to_load_settings": "Error al cargar la configuración",
    "something_went_wrong": "Algo salió mal"
  },
  "tableWidgetTable": {
    "no_data": "No hay datos"
  }
}