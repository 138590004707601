import { nhost } from '@/lib/nhost';

export const getProjectResourcesChain = async (id: string) => {
  return await nhost.functions.call('chain/template/run', {
    chain: 'basic-get',
    payload: {
      indexName: id,
      limit: 1,
    },
  });
};
