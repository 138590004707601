{
  "userInformation": {
    "general_information": "Editar información",
    "full_name": "Nombre completo",
    "enter_full_name": "Ingrese el nombre completo",
    "email": "Correo electrónico",
    "enter_email": "Ingrese el correo electrónico",
    "company": "Empresa",
    "enter_company": "Ingrese la empresa",
    "phone": "Teléfono",
    "enter_phone_number": "Ingrese el número de teléfono",
    "interface_language": "Idioma de interfaz",
    "select_interface_language": "Seleccione el idioma de interfaz",
    "timezone": "Zona horaria",
    "select_your_timezone": "Seleccione su zona horaria",
    "cancel": "Cancelar",
    "avatar": "Avatar",
    "upload_photo": "Cargar foto",
    "save": "Guardar",
    "this_field_contains_special_characters": "Este campo contiene caracteres especiales",
    "your_personal_data_was_updated": "¡Se actualizaron tus datos personales!",
    "entered_phone_number_is_already_in_use": "El número de teléfono ingresado ya está en uso",
    "invalid_form": "Algunos campos faltan o son incorrectos. Por favor, revise los campos resaltados e intente nuevamente.",
    "confirmation_email_sent": "Se ha enviado una carta de confirmación para el cambio de correo electrónico a su nueva dirección de correo electrónico"
  }
}