{
  "tenantPortalPortalSection": {
    "completed": "Completado",
    "non_completed": "No completado",
    "completed_many": "Completados",
    "non_completed_many": "No completados"
  },
  "tenantPortalTabs": {
    "settings": "Configuración",
    "projects": "Proyectos",
    "packages": "Paquetes",
    "users": "Usuarios",
    "transactions": "Transacciones"
  }
}