{
  "anonymizerSettings": {
    "anonymize_sensitive_data": "Anonymize sensitive data",
    "language": "Language",
    "language_of_the_data": "Language of the data",
    "replace": "Replace",
    "replace_value": "Replace value",
    "define_what_entities_must_be_anonymized": "Define what entities must be anonymized",
    "anonymize_entities": "Anonymize entities"
  }
}