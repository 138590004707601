import gql from 'graphql-tag';

export const GET_PROJECT_SETTINGS = gql`
  query ($id: uuid!) {
    projects(where: { id: { _eq: $id } }) {
      id
      name
      description
      settings
    }
  }
`;
