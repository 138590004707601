{
  "knowledgeQa": {
    "no_data_so_far": "No data so far.",
    "question": "Question",
    "answer": "Answer",
    "last_modified": "Last modified",
    "actions": "Actions",
    "delete_these_items": "Delete these items?",
    "are_you_sure_you_want_to_delete": "Are you sure you want to delete selected items?",
    "this_action_cannot_be_undone": "This action cannot be undone.",
    "the_resources_were_removed": "The resources were removed.",
    "an_error_occurred": "An error occurred while deleting resources",
    "question_and_answer_were_saved": "Question and answer were saved successfully!",
    "an_error_occurred_while_saving": "An error occurred while saving question and answer!"
  },
  "knowledgeQaActions": {
    "edit": "Edit",
    "delete": "Delete"
  },
  "knowledgeQaEditModal": {
    "question": "Question",
    "cancel": "Cancel",
    "filter": "Filter",
    "apply": "Apply"
  },
  "knowledgeQaFilterModal": {
    "cancel": "Cancel",
    "filter": "Filter",
    "apply": "Apply",
    "by_content": "By content",
    "search_for_content": "Search for content..."
  },
  "knowledgeQaFilters": {
    "by_content": "By content"
  },
  "knowledgeQaToolbar": {
    "qa_selected": "QA selected:"
  }
}