{
  "pagesProjectsWidgetIdSettings": {
    "back": "Back",
    "your_settings_successfully_saved": "Your settings successfully saved",
    "failed_to_save_settings": "Failed to save settings",
    "failed_to_load_settings": "Failed to load settings"
  },
  "pagesProjectsWidgetIdTeam": {
    "team": "Team",
    "back": "Back"
  },
  "pagesProjectsWidgetIdBasic": {
    "this_widget_view_is_not_ready_yet": "This widget view is not ready yet.",
    "add_a_new_view": "Add a new view",
    "you_dont_have_any_resources": "You don't have any resources.You should add a new informer.",
    "change_widget_view": "Change widget view"
  }
}