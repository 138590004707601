<template>
  <svg aria-hidden="true" class="w-full h-full">
    <use :href="symbolId" fill="currentColor"/>
  </svg>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
  prefix: {
    type: String,
    default: 'icon',
  },
  name: {
    type: String,
    required: true,
  },
});
const symbolId = computed(() => `#${props.prefix}-${props.name}`);
</script>
