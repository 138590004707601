{
  "projectSettingsTeam": {
    "user_access_rights_successfully_updated": "Diritti di accesso dell'utente aggiornati con successo!",
    "something_went_awry": "Qualcosa è andato storto!",
    "user_with_email": "Utente con email",
    "has_been_successfully_invited_to_you_project": "è stato invitato con successo al tuo progetto!",
    "already_exists": "esiste già"
  },
  "teamInvite": {
    "enter_email_to_invite_people_to_this_project": "Inserisci l'email per invitare persone a questo progetto",
    "email": "Email",
    "send_invite": "Invia invito",
    "can_view": "Può visualizzare",
    "can_edit": "Può modificare"
  },
  "teamLink": {
    "project_link": "Link del progetto",
    "copy_link": "Copia link",
    "please_note_that_any_activity": "Si prega di notare che qualsiasi attività degli utenti con cui condividi questo link è legata al saldo del tuo account.",
    "url_link_copied_just_paste_the_link": "Link URL copiato! Incolla il link a chiunque tu voglia condividere il progetto"
  },
  "teamsList": {
    "members": "Membri"
  },
  "teamsListItem": {
    "can_view": "può visualizzare",
    "can_edit": "può modificare",
    "remove": "rimuovi",
    "you": "(tu)",
    "owner": "proprietario",
    "pending": "(in sospeso)"
  }
}