{
  "tenantPortalDeleteUserModal": {
    "delete_this_user": "Excluir este usuário?",
    "delete_user": "Excluir usuário",
    "user_will_be_deleted": "O usuário será excluído da lista."
  },
  "tenantPortalInviteModal": {
    "invite_user": "Convidar usuário",
    "apply": "Aplicar",
    "email": "Email",
    "promocode": "Código promocional",
    "enter_your_promo_code": "Digite seu código promocional",
    "message": "Mensagem",
    "enter_message_text": "Digite o texto da mensagem...",
    "the_invite_has_been_successfully_sent": "O convite foi enviado com sucesso!",
    "the_error_occurred_while_sending_an_email": "Ocorreu um erro ao enviar um email"
  },
  "tenantPortalPortalUsers": {
    "no_users_so_far": "Nenhum usuário até o momento",
    "invite": "Convidar",
    "email": "Email",
    "packages": "Pacotes",
    "status": "Status",
    "actions": "Ações"
  },
  "tenantPortalPortalUsersItem": {
    "user_was_deleted": "O usuário foi excluído"
  },
  "tenantPortalPortalUserModal": {
    "edit_user": "Editar usuário",
    "apply": "Aplicar",
    "email": "Email",
    "package": "Pacote",
    "status": "Status"
  }
}