{
  "billingOverview": {
    "billing_overview": "Abrechnungsübersicht",
    "expenses": "Ausgaben"
  },
  "billingOverviewItem": {
    "used": "Verwendet",
    "remaining": "verbleibend",
    "used_out_of": "Verwendet {formattedCurrent_value} von {formattedTotal_value}.",
    "money": "Geld"
  },
  "cardList": {
    "card_details": "Kartendetails",
    "expires_on": "Läuft ab am",
    "primary": "Primär"
  },
  "paymentHistory": {
    "payment_history": "Zahlungsverlauf",
    "view_invoice": "Rechnung anzeigen",
    "download_invoice": "Rechnung herunterladen",
    "march": "März",
    "february": "Februar",
    "january": "Januar",
    "pro_membership": "Pro-Mitgliedschaft"
  },
  "projectList": {
    "projects": "Projekte"
  },
  "proPlanInfo": {
    "pro_plan": "Pro-Plan",
    "renew_now": "Jetzt erneuern",
    "monthly_visitors": "{quantity} monatliche Besucher",
    "unlimited_reports": "Unbegrenzte Berichte",
    "years_data_storage": "{quantity} Jahre Datenspeicherung",
    "days_left": "Verbleibende Tage",
    "month": "Monat"
  },
  "userInfo": {
    "profile": "Profil",
    "no_company_name": "kein Firmenname",
    "no_phone_number": "keine Telefonnummer",
    "no_timezone": "keine Zeitzone",
    "remove_your_account": "Ihr Konto entfernen"
  }
}