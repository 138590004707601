{
  "knowledgeQa": {
    "no_data_so_far": "Bisher keine Daten.",
    "question": "Frage",
    "answer": "Antwort",
    "last_modified": "Zuletzt geändert",
    "actions": "Aktionen",
    "delete_these_items": "Diese Elemente löschen?",
    "are_you_sure_you_want_to_delete": "Sind Sie sicher, dass Sie die ausgewählten Elemente löschen möchten?",
    "this_action_cannot_be_undone": "Diese Aktion kann nicht rückgängig gemacht werden.",
    "the_resources_were_removed": "Die Ressourcen wurden entfernt.",
    "an_error_occurred": "Beim Löschen der Ressourcen ist ein Fehler aufgetreten.",
    "question_and_answer_were_saved": "Frage und Antwort wurden erfolgreich gespeichert!",
    "an_error_occurred_while_saving": "Beim Speichern von Frage und Antwort ist ein Fehler aufgetreten!"
  },
  "knowledgeQaActions": {
    "edit": "Bearbeiten",
    "delete": "Löschen"
  },
  "knowledgeQaEditModal": {
    "question": "Frage",
    "cancel": "Abbrechen",
    "filter": "Filtern",
    "apply": "Anwenden"
  },
  "knowledgeQaFilterModal": {
    "cancel": "Abbrechen",
    "filter": "Filtern",
    "apply": "Anwenden",
    "by_content": "Nach Inhalt",
    "search_for_content": "Nach Inhalt suchen..."
  },
  "knowledgeQaFilters": {
    "by_content": "Nach Inhalt"
  },
  "knowledgeQaToolbar": {
    "qa_selected": "Ausgewählte QA:"
  }
}