<template>
  <div :class="{ 'dark text-white-dark': store.semidark }">
    <nav
      class="sidebar fixed bottom-0 top-0 z-50 h-full min-h-screen w-[260px] border-r border-white-white100 shadow-lg transition-all duration-300 dark:border-dark-blue100 dark:shadow-dark-blue100"
    >
      <div
        class="grid h-full grid-rows-[min-content_1fr] bg-white-lightWhite dark:bg-dark-darkBlue"
      >
        <div
          class="flex items-center justify-between border-b border-white-white100 px-4 py-2.5 dark:border-dark-blue100"
        >
          <router-link to="/" class="main-logo flex shrink-0 items-center dark:text-white-light">
            <div class="ml-[5px] h-[38px] w-8 flex-none">
              <BaseWidgetSvgIcon name="logo-main" />
            </div>
            <span class="align-middle text-sm font-medium ltr:ml-3.5 rtl:mr-1.5 lg:inline"
              >OmniMind.AI</span
            >
          </router-link>
          <a
            href="javascript:;"
            class="collapse-icon flex h-8 w-8 items-center rounded-full transition duration-200 hover:bg-gray-500/10 hover:text-primary rtl:rotate-180 dark:text-white-light dark:hover:bg-dark-light/10"
            @click="store.toggleSidebar"
          >
            <svg
              class="m-auto h-5 w-5"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 19L7 12L13 5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.9998 19L10.9998 12L16.9998 5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </a>
        </div>
        <div class="relative grid grid-rows-[1fr_min-content_min-content] overflow-y-auto">
          <template v-if="Boolean(allProjects.length)">
            <perfect-scrollbar
              :options="{
                swipeEasing: true,
                wheelPropagation: Boolean(width < 450),
              }"
            >
              <SidebarSkeleton v-if="isProjectsLoading" />
              <template v-else>
                <SidebarHeader
                  :projects="allProjects"
                  :input-value="selectedProject"
                  class="mb-3 px-3"
                  @update:input-value="onSelectProject"
                />

                <div v-if="isProjectReady">
                  <SidebarProjectTree
                    v-if="widget && !isIntegration"
                    :project-id="selectedProject?.id"
                    :project-type="selectedProject?.type"
                    :widget="widget"
                    :integration="isIntegration"
                    :item-name="store.sidebarItem"
                    @selected-item="handleSelectItem"
                    @add-new-view="router.push(viewPath)"
                  />
                  <SidebarProjectIntegrationItem
                    v-if="storedIntegration"
                    :project-id="selectedProject.id"
                    :project="selectedProject"
                    :integration="storedIntegration"
                    :item-name="store.sidebarItem"
                    @selected-item="handleSelectItem"
                    @add-new-view="router.push(viewPath)"
                  />
                </div>
              </template>
            </perfect-scrollbar>
          </template>
          <SidebarNoProjects v-else />
          <SidebarEmailConfirmWarning
            v-if="!emailVerified && !closeEmailNotification"
            :loading="isLoading"
            :sent="emailSent"
            :error="emailError"
            @resend="onConfirmationEmailResend"
            @close="closeEmailNotification = true"
          />
          <BillingOverview />
          <SidebarPromocode />
        </div>
      </div>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref, watch, watchEffect } from 'vue';
import { useViewportDimensions } from '@/composables/useViewportDimensions';
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { useSubscription } from '@vue/apollo-composable';
import { useRouter, useRoute } from 'vue-router';
import { useAppStore } from '@/stores';
import { useResourcesStore } from '@/stores/resourcesStore';
import BaseWidgetSvgIcon from '@components/base/BaseWidgetSvgIcon.vue';
import SidebarNoProjects from '@components/layout/sidebar/SidebarNoProjects.vue';
import SidebarHeader from '@components/layout/sidebar/SidebarHeader.vue';
import SidebarPromocode from '@components/layout/sidebar/SidebarPromocode.vue';
import SidebarEmailConfirmWarning from '@components/layout/sidebar/SidebarEmailConfirmWarning.vue';
import SidebarProjectTree from '@components/layout/sidebar/SidebarProjectTree.vue';
import SidebarProjectIntegrationItem from '@components/layout/sidebar/SidebarProjectIntegrationItem.vue';
import BillingOverview from '@components/profile/info/BillingOverview.vue';
import SidebarSkeleton from '@components/base/skeleton/layout/SidebarSkeleton.vue';
import { useI18n } from 'vue-i18n';
import { UserPermissionEnum } from '@/interfaces/UserPermissionEnum';
import { useUserCan } from '@/composables/useUserCan';
import { useAuthorization } from '@/plugins/authorization';
import { GET_PROJECTS_LIST } from '@/api';
import type { Project } from '@/stores';
import { useSendVerificationEmail, type User } from '@nhost/vue';
import { ItemTypeKeysEnum } from '@/types/ItemTypeKeysEnum';

import { WidgetView } from '@components/basic-mode/interfaces/WidgetTypeInterface';
import { useCheckWeaviateResources } from '@/composables/useCheckWeaviateResources';
import { useMaximumNumberOfProjectsAlert } from '@/composables/useMaximumNumberOfProjectsAlert';
import { useWidgetsStore } from '@/stores/widgetsStore';
import { useIntegrations } from '@/composables/useIntegrations';
import { showAlert } from '@/composables/mixin-alert';

const props = defineProps<{
  user: User;
}>();

const { t } = useI18n();
const { showMaxProjectAlert } = useMaximumNumberOfProjectsAlert();
const userId = computed(() => props.user.id);

const NEW_PROJECT = { id: '1', name: t('sidebar.new_project') } as Project;

const selectedProject = ref<Project>(NEW_PROJECT);

const { width } = useViewportDimensions();

const store = useAppStore();
const resourcesStore = useResourcesStore();

const route = useRoute();
const router = useRouter();

const emailVerified = computed(() => props.user.emailVerified);

const widgetId = computed(() => route.params.widgetId);
const { widgetData } = useIntegrations();

const isIntegration = computed(() => {
  const parts = route.path.split('/');
  const last = parts[parts.length - 1];
  // @ts-ignore
  const integrations = widgetData.value.map(({ key }) => key);
  // @ts-ignore
  return integrations.includes(last) ? widgetData.value.find(({ key }) => key === last) : null;
});

const storedIntegration = ref(null);

const widget = computed(() => {
  if (storedIntegration.value) {
    return null;
  }
  const routeWidgetId = widgetId.value || route.query.wId;
  const storedWidget =
    localStorage.getItem(`project_${selectedProject.value?.id}_${userId.value}`)?.split('_')[1] ||
    null;

  if (storedWidget) {
    return (
      selectedProject.value.widgets.find((w: any) => w.id === storedWidget) ||
      selectedProject.value.widgets[0]
    );
  } else if (routeWidgetId) {
    return (
      selectedProject.value.widgets.find((w: any) => w.id === routeWidgetId) ||
      selectedProject.value.widgets[0]
    );
  } else {
    return selectedProject.value.widgets[0];
  }
});

const viewPath = computed(() => {
  return `/projects/${selectedProject.value.id}/widget/view/mywidgets?widgetType=${WidgetView.VISUAL}`;
});

const { onResult, loading: isProjectsLoading } = useSubscription(GET_PROJECTS_LIST, {
  id: userId.value,
});
const widgetStore = useWidgetsStore();

onResult(({ data }) => {
  const projectWithWidgetTypes = data.projects.map((x: any) => ({
    ...x,
    widgets: x.widgets.map((w: any) => ({
      ...w,
      widget_type: widgetStore.getWidgetTypeIdById(w.type_id),
    })),
  }));
  store.setProjects(projectWithWidgetTypes);
  store.setIsProjectsLoaded(isProjectsLoading.value);
});

const isProjectReady = computed(() => {
  const pr = allProjects.value.find((pr) => pr.id === selectedProject.value.id);

  return pr?.id !== '1' && Boolean(pr?.widgets.length);
});

// const { rights } = useAuthorization();
// const maxProjectsCount = computed(() => +rights.value.projects);

// const isProjects = computed(
//   () =>
//     Boolean(myProjects.value.length) ||
//     getInvited(sharedProjects.value).length !== sharedProjects.value.length,
// );

const sortProjects = (projects: Project[]) => {
  return projects.sort((a: Project, b: Project) => {
    const ownerA = a.user_to_projects.find((up) => up.user_id === props.user.id);
    const ownerB = b.user_to_projects.find((up) => up.user_id === props.user.id);

    if (ownerA?.favorite && !ownerB?.favorite) return -1;
    if (!ownerA?.favorite && ownerB?.favorite) return 1;

    return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
  });
};

const dividedProjects = computed(() => store.dividedProjects(userId.value));

const myProjects = computed(() => sortProjects(dividedProjects.value.myProjects));

const sharedProjects = computed(() => sortProjects(dividedProjects.value.sharedProjects));

const allProjects = computed(() => [...myProjects.value, ...sharedProjects.value]);

// const hasInvitations = computed(() => {
//   return sharedProjects.value.some((project) => {
//     return project.user_to_projects?.some((userProject) => {
//       return userProject.scopes !== TeamScopesEnum.OWNER && userProject.is_invited;
//     });
//   });
// });

// const isSharedProjectSelected = computed(() => {
//   return Boolean(
//     dividedProjects.value.sharedProjects.find((sharedProject) =>
//       route.path.includes(sharedProject.id),
//     ),
//   );
// });

const clearSavedIntegration = () => {
  localStorage.removeItem(`project_${selectedProject.value.id}_${userId.value}_integration`);
  store.setSavedIntegrationTrigger();
};

const setSelectedProjectValue = (id: string) => {
  const pr = myProjects.value.find((pr) => pr.id === id);

  if (pr) {
    store.setProject('id', pr.id);
    selectedProject.value = pr;
    storedIntegration.value = null;
  }
};

watchEffect(() => {
  if (typeof store.savedIntegrationTrigger !== 'boolean') {
    return;
  }

  const savedIntegration = localStorage.getItem(
    `project_${selectedProject.value.id}_${userId.value}_integration`,
  );
  storedIntegration.value = savedIntegration ? JSON.parse(JSON.stringify(savedIntegration)) : null;
});

watch(
  () => myProjects.value,
  (value) => {
    setSelectedProjectValue(selectedProject.value.id);
    if (!value.length) {
      store.setSidebarNoProjects(true);
    } else {
      store.setSidebarNoProjects(false);
    }
  },
  { immediate: true },
);

watch(
  () => route.params,
  ({ id }) => {
    if (id === selectedProject.value.id) return;
    setSelectedProjectValue(id as string);
  },
  { immediate: true, deep: true },
);

// watch(
//   () => isSharedProjectSelected.value,
//   (value) => {
//     if (value) {
//       selectedTab.value = 1;
//       return;
//     }
//     selectedTab.value = 0;
//   },
// );

const checkProjectWeaviateResources = async (projectId: string) => {
  const { hasWeaviateResources } = await useCheckWeaviateResources(projectId);
  if (hasWeaviateResources && projectId) {
    const currentProject = store.getProjectById(projectId);
    await router.push(`/projects/${projectId}/widget/${currentProject?.widgets[0]?.id}`);
  }
};

const { package_features } = useAuthorization();
const canCreateProjects = computed(() =>
  useUserCan({
    permission: UserPermissionEnum.CanCreateProjects,
    store,
    features: package_features.value,
  }),
);

clearSavedIntegration();

const onSelectProject = async (project: Project) => {
  clearSavedIntegration();

  if (project?.id === '') {
    return;
  }
  if (project?.id === '1') {
    if (!canCreateProjects.value) {
      await showMaxProjectAlert();
      return;
    }
    selectedProject.value = project;
    await router.push('/advise');
    return;
  }

  selectedProject.value = project;
  project.id && (await resourcesStore.fetchResources(project.id));

  const isNewCreatedProject = Boolean(store.project?.id === project.id);

  const isAdvice = Boolean(isNewCreatedProject && store.advice?.ask);
  const hasWeaviateResources = checkProjectWeaviateResources(project.id);
  const isResources =
    Boolean(resourcesStore.resourcesByProjectId(project?.id!)?.length) || hasWeaviateResources;

  const isEmpty = !Boolean(project.widgets?.length) && !isResources;

  const storedWidget =
    localStorage.getItem(`project_${selectedProject.value?.id}_${userId.value}`)?.split('_')[1] ||
    null;

  const hasStoredWidget = project?.widgets.some((w) => w.id === storedWidget);

  const defaultRoute = `/projects/${project.id}/widget/${
    !isResources
      ? `view?widgetType=${WidgetView.INFORMER}`
      : project.widgets?.[0]?.id || `view?widgetType=${WidgetView.VISUAL}`
  }`;

  const projectRoute = isAdvice
    ? `/advise?id=${project.id}`
    : storedWidget && hasStoredWidget
    ? `/projects/${project.id}/widget/${storedWidget}`
    : isEmpty
    ? `/projects/${project.id}/empty`
    : project.widgets?.length
    ? `/projects/${project.id}/widget/${project.widgets?.[0]?.id}`
    : defaultRoute;

  await router.push(projectRoute);
};

const handleSelectItem = (item: ItemTypeKeysEnum) => {
  // Knowledge
  if (item === ItemTypeKeysEnum.Knowledge) {
    const knowledgeRoute = `/projects/${selectedProject.value.id}/knowledge`;

    const resultKnowledgeRoute = isIntegration.value
      ? knowledgeRoute
      : `${knowledgeRoute}${widget.value?.id ? `?widget=${widget.value.id}` : ''}`;

    router.push(resultKnowledgeRoute);
    return;
  }

  if (store.isSettingsChanges) return;
  store.setSidebarItem(item);
};

onMounted(() => {
  store.setIsProjectsLoaded(true);
});

const { sendEmail, isLoading } = useSendVerificationEmail();
const emailSent = ref(false);
const emailError = ref(false);
const closeEmailNotification = ref(false);
const onConfirmationEmailResend = async () => {
  try {
    props.user.email && (await sendEmail(props.user.email));
    emailSent.value = true;
  } catch (e) {
    emailError.value = true;
    showAlert('error', 'Error heppened while sending verification email');
  }
};
</script>
