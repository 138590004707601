{
  "validations": {
    "required": "Questo campo è obbligatorio",
    "isOldPasswordCorrect": "Inserisci una vecchia password valida",
    "email": "Inserisci un indirizzo email valido",
    "url": "Inserisci un URL valido",
    "URL": "Inserisci un URL valido",
    "min": "Inserisci almeno {min} caratteri",
    "integer": "Inserisci un valore intero",
    "minValue": "Il valore deve essere almeno {min}",
    "maxValue": "Il valore deve essere al massimo {max}",
    "max": "Inserisci al massimo {max} caratteri",
    "minLength": "Inserisci almeno {min} caratteri",
    "maxLength": "Inserisci al massimo {max} caratteri",
    "requiredIf": "Questo campo è obbligatorio",
    "sameAs": "Il valore deve essere uguale all'altro valore"
  },
  "promocode": {
    "the_promocode_was_successfully_redeemed": "Il codice promozionale è stato riscattato con successo. Per ulteriori dettagli, controlla le tue statistiche di fatturazione",
    "unable_to_redeem_the_promo_code": "Impossibile riscattare il codice promozionale. Verifica il codice per eventuali errori di battitura e riprova, o contatta l'assistenza per assistenza."
  }
}