import type { WidgetTypeKeysEnum } from '@/types/WidgetTypeKeysEnum';

export enum WidgetView {
  ALL = 'ALL',
  VISUAL = 'VISUAL',
  INFORMER = 'INFORMER',
}

export interface WidgetTypeInterface {
  id: string;
  name: string;
  key: string;
  description?: string;
  icon?: string;
  is_active: boolean;
  view: WidgetView;
  type?: string;
  type_id: number;
  widget_type: {
    key: WidgetTypeKeysEnum;
  };
  settings?: any;
  categories?: string[];
  disabled?: boolean;
}

