{
  "quizWidget": {
    "delete_this_question": "Delete this question?",
    "are_you_sure_you_want_to_delete": "Are you sure you want to delete this question from the quiz? This action cannot be undone.",
    "delete_this_quiz": "Delete this quiz?",
    "are_you_sure_you_want_to_delete_the_quiz": "Are you sure you want to delete the whole quiz? This action cannot be undone.",
    "export_to_quizlet": "Export to Quizlet",
    "use_pattern": "Use pattern",
    "the_quiz_should_test_general_knowledge": "The quiz should test general knowledge, critical thinking, and understanding. Question must have a gap to fill.",
    "can_be_more_than_one_correct": "Can be more than one correct options",
    "each_question_should_focus": "4) Each question should focus on a specific theme or topic 5) Each question should have A MINIMUM OF 4 PAIRS OF ITEMS for matching 6) The structure of the JSON should be an array of objects, where each object represents a question with a 'question' field and a 'correct' field. The 'correct' field should be an object itself, containing key-value pairs that need to be matched. 7) The content should be educational and based on factual information or well-established concepts.8) Use the following JSON pattern for each question:",
    "the_quiz_should_cover": "The quiz should cover diverse topics or different aspects of the same theme.",
    "question_task_arrange_items": "Question task: arrange items or events in the correct order. Minimum 4 options placed in \"correct\" array in correct sequence",
    "given_the_context": "Given the { context }, please generate a quiz IN",
    "language_in_a_valid_json_format": "language in a VALID JSON FORMAT. The quiz should have the following characteristics: 1) Question Type:",
    "number_of_questions": "Number of Questions",
    "difficulty_level": "Difficulty Level",
    "this_time_only": "This time only {newQuestionsLength} questions. Maybe not enough content.",
    "something_didn_t_go_as_planned": "Something didn't go as planned.",
    "failed_to_get_the_pdf_file": "Failed to get the PDF file."
  },
  "quizWidgetAddCustomQuestionModal": {
    "create_custom_question": "Create custom question",
    "question_type": "Question type",
    "difficulty": "Difficulty"
  },
  "quizWidgetDeleteModal": {
    "delete_this_question": "Delete this question?",
    "are_you_sure_you_want_to_delete": "Are you sure you want to delete this question from the quiz? This action cannot be undone."
  },
  "quizWidgetExportModal": {
    "export_configuration": "Export configuration",
    "decide_what_should_be_included": "Decide what should be included into your quiz export",
    "show_numeration": "Show numeration",
    "show_answers": "Show answers",
    "show_explanation": "Show explanation",
    "show_instructions": "Show instructions",
    "show_difficulty": "Show difficulty",
    "show_images": "Show images"
  },
  "quizWidgetForm": {
    "question_type": "Question type",
    "language": "Language",
    "difficulty": "Difficulty",
    "max_questions": "Max Questions",
    "add_more": "Add more",
    "generate": "Generate",
    "original": "Original"
  },
  "quizWidgetListFooter": {
    "hide": "Hide",
    "show": "Show",
    "answers": "answers",
    "create_custom_question": "Create custom question",
    "clear_quiz": "Clear quiz",
    "export": "Export"
  },
  "quizWidgetListHeader": {
    "quiz": "Quiz",
    "questions": "questions"
  },
  "quizWidgetQuestionCardFooter": {
    "change_difficulty": "Click here to change difficulty"
  }
}