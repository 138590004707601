{
  "changePassword": {
    "you_will_be_redirected": "Ви будете перенаправлені на домашню сторінку через {value} секунд",
    "to_go_to_the_home_page": "Щоб перейти на домашню сторінку",
    "click_here": "натисніть тут",
    "enter_your_new_password": "Введіть новий пароль",
    "password_successfully_changed": "Пароль успішно змінено"
  },
  "signIn": {
    "sign_in": "Увійти",
    "enter_your_email_and_password": "Введіть свою електронну пошту та пароль для входу",
    "email": "Електронна пошта",
    "enter_email": "Введіть електронну пошту",
    "password": "Пароль",
    "enter_password": "Введіть пароль",
    "or": "АБО",
    "dont_have_an_account": "Не маєте облікового запису?",
    "sign_up_with_email": "Зареєструватися за допомогою електронної пошти",
    "dont_remember_the_password": "Не пам'ятаєте пароль?",
    "reset_password": "Скинути пароль",
    "link_expired": "Це посилання недійсне або вичерпано термін його дії.",
    "please_request_a_new_link": "Будь ласка, запросіть нове посилання.",
    "something_went_awry": "Щось пішло не так!",
    "your_email_is_not_yet_verified": "Ваша електронна пошта ще не підтверджена. Будь ласка, перевірте свою поштову скриньку та перейдіть за посиланням для завершення реєстрації."
  },
  "signUp": {
    "sign_up": "Зареєструватися"
  },
  "signUpForm": {
    "name": "Ім'я",
    "enter_first_name": "Введіть ім'я",
    "last_name": "Прізвище",
    "enter_last_name": "Введіть прізвище",
    "email": "Електронна пошта",
    "enter_email": "Введіть електронну пошту",
    "password": "Пароль",
    "enter_password": "Введіть пароль",
    "password_confirmation": "Підтвердження пароля",
    "i_agree": "Я погоджуюся з",
    "terms_and_conditions": "Умовами та положеннями",
    "and": "та",
    "privacy_policy": "Політикою конфіденційності",
    "by_checking_this": "Позначивши це, ви підтверджуєте та погоджуєтеся з передачею даних, які ви завантажуєте до цього проекту, стороннім службам, таким як OpenAI. Ця передача даних необхідна для належної роботи проекту та використання зовнішніх сервісів, які покращують його функціональність.",
    "third_party_services": "Згода на передачу даних стороннім службам",
    "sign_up": "ЗАРЕЄСТРУВАТИСЯ",
    "or_sign_up_with": "Або зареєструйтесь за допомогою",
    "already_have_an_account": "Вже маєте обліковий запис?",
    "sign_in": "Увійти",
    "please_check_your_mailbox_and": "Будь ласка, перевірте свою поштову скриньку та \n перейдіть за посиланням для завершення реєстрації. \n",
    "if_you_cant_find_it_in_your": "Якщо ви не можете знайти його у своїй папці «Вхідні», перевірте свою",
    "spam_folder": "папку «Спам»",
    "as_well": "також",
    "this_field_contains_special": "Це поле містить спеціальні символи",
    "please_ensure_youve_read": "Будь ласка, переконайтеся, що ви прочитали та погодилися з нашими Умовами та положеннями",
    "and_have_consented": "та дали згоду на передачу даних стороннім службам, позначивши відповідні прапорці",
    "before_signing_in": "перед входом",
    "by_signing_up_you_agree": "Зареєструвавшись, ви погоджуєтеся з нашими Умовами та положеннями та визнаєте, що ви дали згоду на передачу своїх даних стороннім службам.",
    "read_our": "Прочитайте наші"
  }
}