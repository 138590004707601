import gql from 'graphql-tag';

export const GET_AGENTS_BY_PROJECT_ID = gql`
  query projects_agents_by_project_id($id: uuid = "") {
    projects_agents(where: { project_id: { _eq: $id } }) {
      description
      engine
      id
      llm
      name
      project_id
      prompt
    }
  }
`;

export const GET_PROFILE_CREDENTIALS_BY_SLUG_AND_PROFILE_ID = gql`
  query GetProfileCredentialsBySlugAndProfileId($profile_id: uuid!) {
    profiles_credentials(where: { profile_id: { _eq: $profile_id } }) {
      profile_id
      name
      id
      slug
      config
    }
  }
`;

export const GET_AGENT_BY_ID = gql`
  query projects_agents_by_pk($id: uuid!) {
    projects_agents_by_pk(id: $id) {
      description
      engine
      id
      llm
      name
      prompt
      prompt_raw
    }
  }
`;

export const GET_TOOL_BY_ID = gql`
  query agents_tools_by_pk($id: uuid!) {
    agents_tools_by_pk(id: $id) {
      agent_id
      id
      description
      name
      slug
      config
      tools_credentials {
        credential_id
        tool_id
        profiles_credential {
          name
          profile_id
          slug
          config
          id
        }
      }
    }
  }
`;
