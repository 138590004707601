{
  "explainWidget": {
    "we_are_creating_suggestion_questions": "Estamos creando preguntas de sugerencia para ti. Pero puedes usar el chat ahora",
    "sorry_we_could_not_create_any_examples": "Lo sentimos, no pudimos crear ejemplos de preguntas sobre las que puedas preguntar al bot todavía.",
    "loading": "Cargando...",
    "explanation": "Explicación",
    "please_ask_the_owner_of_the": "Por favor, pregunta al propietario del proyecto para hacerlo público",
    "the_project_owner_needs_to": "El propietario del proyecto necesita actualizar la suscripción ya que la actual ha expirado.",
    "404_oops_we_havet_found": "404 Ooops, no hemos encontrado el proyecto",
    "oops_it_seems_youve_reached": "¡Ups! Parece que has alcanzado el límite de mensajes. Para continuar, ajusta amablemente tu límite de mensajes en la configuración."
  }
}