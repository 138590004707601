{
  "settingsBehaviour": {
    "behavior": "Comportamento",
    "choose_option": "Scegli l'opzione che desideri modificare",
    "label_for_examples_of_questions": "Etichetta per Esempi di domande che puoi fare al bot",
    "before_you_start_to_work_with_widget": "Prima di iniziare a lavorare con il widget, vedi gli esempi di domande che puoi fare al bot",
    "enter_label_for_examples_of_questions": "Inserisci l'etichetta per Esempi di domande che puoi fare al bot...",
    "split_your_questions_with_a_new_line": "Dividi le tue domande con una nuova riga.",
    "welcome_messages": "Messaggi di benvenuto",
    "enter_messages": "Inserisci i messaggi, separati da doppio Invio.",
    "start_each_message": "Inizia ogni messaggio su una nuova riga. Premi il tasto Invio due volte per separare i messaggi.",
    "type_messages": "Digita i messaggi, doppio Invio per separarli.",
    "add_a_custom_welcome_message_interval": "Aggiungi un intervallo di messaggio di benvenuto personalizzato (secondi)",
    "enter_welcome_message_interval": "Inserisci l'intervallo di messaggio di benvenuto...",
    "add_a_custom_welcome_message": "Cambia la frase \"Non conosco la risposta\"",
    "when_your_bot_isn_t_sure_about": "Quando il tuo bot non è sicuro della risposta, inizia la frase con \"Probabilmente.\" Puoi cambiare questa frase e apparirà al suo posto.",
    "most_likely": "Probabilmente...",
    "add_a_custom_message": "Aggiungi un messaggio personalizzato alla fine di ogni risposta di OmniMind",
    "enter_custom_message": "Inserisci un messaggio personalizzato...",
    "default_language": "Lingua predefinita",
    "custom_language": "Lingua personalizzata",
    "show_followups_questions": "Mostra domande di approfondimento",
    "live_connect": "Live Connect",
    "none": "Nessuno",
    "button_name_for_leaving_a_message": "Nome del pulsante per lasciare un messaggio",
    "leave_a_message": "Lascia un messaggio",
    "offline_message": "Messaggio offline",
    "button_name_for_offline_message": "Nome del pulsante per il messaggio offline",
    "dialogs": "Conversazioni",
    "anonymize_sensitive_data": "Anonimizza dati sensibili",
    "language_of_the_data": "Lingua dei dati",
    "language": "Lingua",
    "replace_value": "Sostituisci valore",
    "replace": "Sostituisci"
  },
  "behaviourDialogsSetting": {
    "history_mode": "Modalità cronologia",
    "collect_user_mode": "Modalità raccolta utente",
    "track_all_messages": "Tieni traccia di tutti i messaggi",
    "track_only_messages_with": "Tieni traccia solo dei feedback negativi e delle richieste di supporto",
    "dont_track": "Non tenere traccia",
    "collect_username_and_email_with_form": "Raccogli nome utente e email con il modulo",
    "provide_username_and_email_by_yourself": "Fornisci nome utente e email tu stesso",
    "do_not_collect_username_and_email": "Non raccogliere nome utente e email"
  },
  "behaviourLiveConnectAction": {
    "selected_option_will_be_applied": "L'opzione selezionata verrà applicata come azione per il pulsante",
    "live_connect_options": "Opzioni di Live Connect",
    "your_custom_js_code": "Il tuo codice JS personalizzato",
    "ensure": "Assicurati che",
    "is_correctly_set_up": "sia configurato correttamente nel tuo progetto. Hai bisogno di aiuto?",
    "check_here": "Controlla qui."
  },
  "behaviourOfflineMessageAction": {
    "selected_option_will_be_applied_as_action_for_button": "L'opzione selezionata verrà applicata come azione per il pulsante",
    "offline_message_options": "Opzioni di messaggio offline",
    "your_custom_js_code": "Il tuo codice JS personalizzato",
    "your_email": "La tua email"
  },
  "behaviourRateLimit": {
    "enable_rate_limit": "Abilita limite di velocità",
    "rate_limit": "Limite di velocità",
    "enable_rate_limit_message": "Limita il numero di messaggi inviati da questo widget per ora"
  }
}