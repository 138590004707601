{
  "confluenceInformerInformers": {
    "stay_organized_and_enhance_your_ai": "Manténgase organizado y mejore su proyecto de IA al traer fácilmente su contenido de Confluence.",
    "something_went_awry_check_provided_data": "¡Algo salió mal! ¡Verifique los datos proporcionados e intente nuevamente, por favor!",
    "something_went_awry": "¡Algo salió mal!"
  },
  "confluenceInformerFormInformers": {
    "email": "Correo electrónico",
    "your_confluence_email": "Su dirección de correo electrónico de Confluence utilizada para iniciar sesión.",
    "api_token": "Token de API",
    "a_secure_api_token": "Un token de API seguro generado desde su cuenta de Atlassian, asegurando una integración fluida y segura.",
    "your_specific_confluence_domain": "El prefijo de su dominio específico de Confluence",
    "e_g": "por ejemplo",
    "if_your_confluence_url_is": "si su URL de Confluence es",
    "fetch_resources": "Obtener recursos",
    "your": "Su",
    "subdomain": "Subdominio"
  },
  "confluenceInformerListPagesInformers": {
    "pages": "Páginas"
  },
  "confluenceInformerListPostsInformers": {
    "posts": "Publicaciones"
  }
}