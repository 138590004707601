{
  "cancelChain": {
    "cancel": "Cancelar",
    "yes": "Sim",
    "no": "Não",
    "stop_processing": "Parar processamento",
    "do_you_want_to_stop": "Deseja parar todas as cadeias de processamento?"
  },
  "progressIndex": {
    "no_resources_so_far_redirecting_to_informers": "Nenhum recurso até agora. Redirecionando para informantes...",
    "an_error_occured_while_deleting": "Ocorreu um erro ao excluir. Por favor, tente novamente"
  }
}