{
  "summaryWidgetWidgets": {
    "summary": "Resumen",
    "generate_summary": "Generar resumen",
    "context": "CONTEXTO",
    "make_summary_in": "Hacer resumen en",
    "keeping_the_tone": "manteniendo el tono {formTone}",
    "use_markdown_format": "Usar formato markdown",
    "add_the_primary_question": "Agregar la pregunta principal con una respuesta que responda al contexto usando formato cursiva.",
    "add_a_header_as_h2_markdown": "Agregar un encabezado como markdown h2.",
    "add_main_emojis": "Agregar emojis principales aquí.",
    "add_bold_main_keywords": "Agregar palabras clave principales en negrita aquí.",
    "add_a_header": "Agregar un encabezado como markdown h2.",
    "add_1_sentence_quote": "Agregar una cita de 1 oración de la frase más importante del contexto usando formato blockquote markdown.",
    "if_you_have_what_to_add": "Si tienes algo que agregar como puntos de viñeta, agrégalo al final como una lista markdown.",
    "an_error_occurred_while_fetching": "Ocurrió un error al obtener el resumen.",
    "something_didn_t_go_as_planned": "Algo no salió como estaba planeado.",
    "error_occured_while_creating_pdf": "Ocurrió un error al crear el PDF"
  },
  "summaryWidgetAnswer": {
    "summary": "Resumen",
    "from_original": "del original ~{pages} páginas de texto a",
    "download_pdf": "Descargar PDF",
    "clear_summary": "Borrar resumen"
  },
  "summaryWidgetAnswerChunk": {
    "click_to_edit_this_block": "Haz clic para editar este bloque",
    "click_to_adjust_this_block": "Haz clic para ajustar este bloque",
    "click_to_delete_this_block": "Haz clic para eliminar este bloque",
    "something_went_awry": "¡Algo salió mal!",
    "you_ve_successfully_deleted": "¡Has eliminado exitosamente parte del resumen!"
  },
  "summaryWidgetAnswerHeader": {
    "click_to_edit": "Haz clic para editar el encabezado del resumen"
  },
  "summaryWidgetForm": {
    "you_ve_enabled_custom_prompt_in_settings": "¡Has habilitado la solicitud personalizada en la configuración!",
    "block_size": "Tamaño del bloque",
    "size_from_original": "Tamaño del original ~{totalPages} páginas de texto",
    "tone": "Tono",
    "speak_tone": "Hablar... tono",
    "language": "Idioma",
    "options": "Opciones",
    "add_subheaders": "agregar subencabezados",
    "add_important_quotes": "agregar citas importantes",
    "use_bullet_points": "usar formato de viñetas",
    "add_main_question": "agregar pregunta principal",
    "add_emojis": "agregar emojis",
    "bold_main_keywords": "palabras clave principales en negrita",
    "write_it_for_a": "Escríbelo para un niño de 9 años como una historia de aventuras",
    "create_preview": "Crear vista previa",
    "original": "original"
  },
  "summaryWidgetModal": {
    "create_summary_pdf": "Crear resumen en PDF",
    "ok": "Aceptar",
    "wait_for_a_few_seconds_please": "Espere unos segundos por favor..."
  },
  "summaryWidgetPreview": {
    "preview": "Vista previa",
    "summary_a_piece_of_content": "resumen de una pieza de contenido",
    "refresh_preview": "Actualizar vista previa"
  },
  "SummaryMethodOptionsEnum": {
    "percent": "porcentaje",
    "pages": "páginas",
    "words": "palabras"
  }
}