{
  "chatWidget": {
    "we_are_creating_suggestion": "We are creating suggestion questions for you. But You can use the chat now",
    "type_your_question": "Sorry, we could not create any {title} yet.",
    "processing_your_request": "Processing your request...",
    "ooops_we_have_t_found": "404 Ooops, we have't found the project",
    "please_ask_the_owner_of": "Please ask the owner of the project to make it public",
    "the_project_owner_needs_to": "The project owner needs to upgrade subscription as the current one has expired.",
    "oops_it_seems_youve_reached": "Oops! It seems you've reached your message rate limit. To continue, kindly adjust your rate limit in the settings."
  }
}