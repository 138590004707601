{
  "whatsAppWidget": {
    "access_token": "Токен доступу",
    "phone_id": "Ідентифікатор телефону",
    "save": "Зберегти",
    "how_to_connect": "Як підключити Omnimind AI WhatsApp чат-бота",
    "make_sure_your_question": "Переконайтеся, що ваше запитання чітке і лаконічне, щоб отримати найточніші відповіді. Якщо ви хочете використовувати різні дані для аналізу, ви можете оновити або змінити проект у налаштуваннях дії.",
    "create_meta_business_account": "Створити Meta (Facebook) бізнес-акаунт",
    "create_new_meta_app": "Створити новий Meta (Facebook) додаток",
    "login_to_your_account": "Увійти в свій обліковий запис",
    "select_my_apps": "Виберіть \"Мої додатки\"",
    "select_create_app": "Виберіть \"Створити додаток\"",
    "select_options": "Виберіть опції",
    "use_case_other": "Використання: Інше",
    "app_type_business": "Тип додатку: Бізнес",
    "enter_app_details": "Введіть деталі додатку",
    "app_name": "Назва додатку",
    "app_contact_email": "Email для зв'язку з додатком",
    "business_account": "Бізнес-акаунт (необов'язково)",
    "add_whatsapp_to_your_meta_app": "Додайте WhatsApp до свого Meta додатку",
    "find_add_products_to_your_app": "Знайдіть \"Додати продукти до свого додатку\"",
    "select_whatsapp_set_up": "Виберіть 'WhatsApp' Налаштування",
    "quick_start": "Швидкий старт",
    "select_business_account": "Виберіть бізнес-акаунт",
    "purchase_new_business_number": "Придбати новий бізнес-номер для WhatsApp",
    "select_api_setup": "Виберіть 'API Setup' і знайдіть 'Крок 5'",
    "click_on_the_button": "Натисніть кнопку \"Додати номер телефону\"",
    "fill_in_all_fields": "Заповніть всі поля і підтвердьте свій номер телефону",
    "skip_if_already_have": "Пропустити, якщо у вас вже є бізнес-номер, який ви можете використовувати для свого WhatsApp бота",
    "configure_whatsapp_settings": "Налаштуйте налаштування WhatsApp",
    "select_configure_webhooks": "Виберіть 'Налаштувати вебхуки' на сторінці 'API Setup'",
    "edit_webhook": "Редагувати вебхук",
    "add_a_callback_url_and_verify_token": "Додайте URL зворотного виклику та перевірте токен.",
    "callback_url": "URL зворотного виклику",
    "verify_token": "Перевірити токен",
    "manage_webhook_fields": "Керуйте полями вебхука",
    "add_and_subscribe_only_to": "Додайте та підпишіться тільки на",
    "add_a_payment_method_to_your_whatsapp": "Додайте спосіб оплати до свого додатку WhatsApp",
    "payment_settings": "Налаштування оплати",
    "add_payment_method": "Додати спосіб оплати",
    "add_phone_number_and_business_details": "Додайте номер телефону (та бізнес-деталі)",
    "provide_necessary_info": "Надайте необхідну інформацію",
    "verify_number": "Перевірте номер",
    "send_a_test_message": "Надішліть тестове повідомлення",
    "select_api_setup_and_find_step_1": "Виберіть 'API Setup' і знайдіть 'Крок 1':",
    "select_the_from_number": "Виберіть номер 'Від'",
    "choose_the_option_to_add_and_verify_a_new_number": "Виберіть опцію для додавання та перевірки нового номера",
    "select_to_number": "Виберіть номер 'До' (додайте особистий номер для тестування)",
    "select_send_message": "Виберіть 'Надіслати повідомлення'",
    "update_business_details_for_your_meta": "Оновіть бізнес-деталі для вашого Meta додатку",
    "select_settings_basic": "У вашому Meta додатку виберіть 'Налаштування > Основне'",
    "fill_in_all_the_necessary_information": "Заповніть всю необхідну інформацію",
    "verify_business_account": "Перевірте бізнес-акаунт",
    "select_settings": "Виберіть 'Налаштування'",
    "select_business_account_info": "Виберіть 'Інформація про бізнес-акаунт'",
    "provide_all_the_necessary_information": "Надайте всю необхідну інформацію",
    "select_security_centre": "Виберіть 'Центр безпеки'",
    "select_start_verification": "Виберіть 'Почати перевірку'",
    "create_a_system_user": "Створіть системного користувача у вашому бізнес-акаунті",
    "settings_users_system_users": "Налаштування > Користувачі > Системні користувачі",
    "create_new_system_user": "Створити нового системного користувача",
    "name_anything": "Ім'я (будь-яке)",
    "role_admin": "Роль: Адміністратор",
    "select_add_assets": "Виберіть 'Додати активи'",
    "select_apps": "Виберіть 'Додатки'",
    "select_the_new_app_for_whatsapp": "Виберіть новий додаток (для WhatsApp)",
    "toggle_manage_app": "Переключіть 'Керувати додатком'",
    "generate_new_token_for_system_user": "Згенеруйте новий токен для системного користувача",
    "select_app_and_generate_new_token": "Виберіть додаток і 'Згенеруйте новий токен'",
    "select_never_expire": "Виберіть 'Ніколи' не закінчується",
    "select_only_permissions_whatsapp_business_messaging": "Виберіть тільки дозволи: whatsapp_business_messaging",
    "select_generate_token": "Виберіть 'Згенерувати токен'",
    "important_save_this_token": "Важливо: Збережіть цей токен десь, вам знадобиться",
    "add_token_and_phone_id": "Додайте токен та ідентифікатор телефону на початку цієї сторінки",
    "phone_id_this_is_from": "'Ідентифікатор телефону' - це з вашого Meta додатку (налаштування API, крок 5), переконайтеся, що ви вибрали правильний номер 'Від' і скопіюйте ідентифікатор телефону звідси",
    "token_this_is_from_step_8": "'Токен' - це з Кроку 8, від системного користувача в вашому бізнес-акаунті",
    "launch_your_whatsApp_integration": "Запустіть вашу інтеграцію WhatsApp",
    "set_your_meta_app_from_development": "Встановіть ваш Meta додаток з розробки на роботу (Відкрийте ваш Meta додаток з"
  }
}