{
  "ninjaIndicator": {
    "please_wait_while": "Bitte warten Sie, während wir Ihre Daten verarbeiten. Es kann etwa eine Minute dauern.",
    "redirecting_to_your_project": "Weiterleitung zu Ihrem Projekt...",
    "we_are_processing_your_information": "Wir verarbeiten Ihre Informationen..."
  },
  "progressCancelChain": {
    "cancel_all": "Alles abbrechen",
    "do_you_want_to_stop": "Möchten Sie alle Verarbeitungsketten stoppen?",
    "stop_processing": "Verarbeitung stoppen?",
    "yes": "Ja",
    "no": "Nein"
  },
  "progressNinjaLoader": {
    "please_wait_while_we_process": "Bitte warten Sie, während wir Ihre Daten verarbeiten. Es kann etwa eine Minute dauern.",
    "redirecting_to_your_project": "Weiterleitung zu Ihrem Projekt...",
    "we_are_processing_your_information": "Wir verarbeiten Ihre Daten jetzt, was einige Zeit dauern kann. Sie können das System sofort verwenden und der Prozess wird im Hintergrund fortgesetzt.",
    "process_in_background": "Im Hintergrund verarbeiten"
  },
  "progressResourceItem": {
    "there_were_some_errors_during_processing": "Es gab einige Fehler während der Verarbeitung."
  },
  "progressResourceList": {
    "jobs_in_progress": "Laufende Jobs"
  }
}