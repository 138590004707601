{
  "caseDatabase": {
    "it_looks_like_you_ve_reached_your_maximum_number": "Es sieht so aus, als hätten Sie die maximale Anzahl Ihrer Projekte erreicht. Um ein neues Projekt zu starten, sollten Sie vorhandene Projekte archivieren oder löschen, die Sie nicht mehr benötigen, oder erkunden Sie unsere Abonnementpläne für höhere Limits."
  },
  "welcomeFlowCustomJourney": {
    "ready_for_the_next_step": "Bereit für den nächsten Schritt in Ihrer personalisierten Reise?",
    "it_is_time_to_initiate_a_new_project": "Es ist Zeit, ein neues Projekt zu starten. Tauchen Sie ein in eine maßgeschneiderte Erfahrung mit unserer Suite von Tools, einschließlich verschiedener Widgets, die alle entwickelt wurden, um Ihre Informationen nahtlos zu organisieren und zu analysieren.",
    "expand_your_projects_scope_by_integrating": "Erweitern Sie den Umfang Ihres Projekts, indem Sie eine vielfältige Auswahl an Inhalten integrieren, von Websites und einzelnen Links bis hin zu Dokumenten, die in Google Drive, Dropbox, One Drive, PDFs und vielem mehr gespeichert sind.",
    "embark_on_this_unique_adventure": "Begib dich auf dieses einzigartige Abenteuer, indem du noch heute dein individuelles Projekt startest und das volle Potenzial deiner Daten freischaltest.",
    "create_project": "Projekt erstellen",
    "or": "Oder",
    "advice": "Ratschlag",
    "let_our_bot_provide_you_with": "lassen Sie unseren Bot Ihnen",
    "on_how_to_make_this_process": "dabei helfen, diesen Prozess zu erleichtern.",
    "project_name": "Projektname"
  },
  "wantToUpgradeCard": {
    "upgrade": "Upgrade",
    "we_understand_youre_interested_in_this": "Wir verstehen, dass Sie an diesem Feature interessiert sind. Es ist in unseren höheren Tarifplänen verfügbar. Erwägen Sie ein Upgrade, um darauf zugreifen zu können!"
  },
  "welcomeAnswerCard": {
    "you_can_select_a_tile_below": "Sie können unten eine Kachel auswählen",
    "it_looks_like_you_ve_reached_your_maximum_number": "Es sieht so aus, als hätten Sie die maximale Anzahl Ihrer Projekte erreicht. Um ein neues Projekt zu starten, sollten Sie vorhandene Projekte archivieren oder löschen, die Sie nicht mehr benötigen, oder erkunden Sie unsere Abonnementpläne für höhere Limits."
  },
  "welcomeScreen": {
    "default_goals": "Ich habe eine Reihe von Dateien auf meinem lokalen Gerät",
    "default_communicate_goals": "Ich möchte, dass die Community rund um die Uhr Zugriff auf die Wissensbasis hat und mit ihr wie mit einem Menschen chatten kann",
    "not_sure_let_us_know": "Nicht sicher? Lassen Sie uns in unserem",
    "what_want_to_do": "Geben Sie ein, was Sie tun möchten?",
    "what_i_want_to_do_question": "Was möchte ich\n          tun?",
    "how_communicate": "Wie werde ich mit den Daten kommunizieren?",
    "not_sure": "Nicht sicher?",
    "lets_find": "Lassen Sie uns in unserem",
    "what_types_of_files": "Welche Arten von Dateien habe ich und wie möchte ich sie hochladen?",
    "cases_database": "Fall-Datenbank",
    "or_start_your": "oder starten Sie Ihr",
    "impress_me": "Beeindrucken Sie mich",
    "regenerate": "Neu generieren",
    "custom_journey": "Individuelle Reise",
    "project_name": "Projektname",
    "enter_the_name_of_the_project": "Geben Sie den Namen des Projekts ein",
    "enter_project_name": "Projektnamen eingeben",
    "what_i_want_to_do": "Was ich tun möchte: Ich möchte mein Wissen mit meinen Schülern teilen. Ich möchte, dass sie rund um die Uhr Antworten erhalten.",
    "what_types_of_files_i_have_and_how_i_want": "Welche Arten von Dateien ich habe und wie ich sie hochladen möchte: Ich habe eine Reihe von PDF-Dateien auf OneDrive, die ich hochladen möchte.",
    "how_i_am_going_to": "Wie ich mit den Daten kommunizieren werde: Ich möchte, dass die Schüler mit dem Bot auf WhatsApp sprechen.",
    "we_could_not_create_the": "Wir konnten das Projekt nicht korrekt erstellen.",
    "what_types_of_files_you_want_to_upload": "Welche Arten von Dateien möchten Sie hochladen?",
    "how_are_you_going_to_communicate_with_the_data": "Wie werden Sie mit den Daten kommunizieren?",
    "select_project_type": "Wählen Sie den Projekttyp aus",
    "agent": "Agent",
    "knowledge_legacy": "Wissen (veraltet)",
    "agent_description": "Agenten können mit dem Benutzer auf Grundlage ihrer eigenen Wissensbasis kommunizieren und auch Aufgaben und Aktionen mit externen Diensten über eine Vielzahl von Tools und APIs entsprechend dem von Ihnen festgelegten Szenario ausführen.",
    "knowledge_description": "Wissensprojekte können nur auf Grundlage ihres eigenen maßgeschneiderten Wissens mit dem Benutzer kommunizieren."
  }
}
