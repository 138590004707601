{
  "marketplaceOption": {
    "purchase": "Purchase",
    "characters": "characters",
    "informers": "informers",
    "projects": "projects",
    "EssentialBoost": "Essential Boost",
    "PremiumExpansion": "Premium Expansion",
    "UltimateAdvantage": "Ultimate Advantage",
    "Projects10": "Projects 10",
    "EssentialBoostDescription": "Elevate your account with an additional $50, unlocking new possibilities and enhancing your experience.",
    "PremiumExpansionDescription": "Additional 1000 Question-Answer requests",
    "UltimateAdvantageDescription": "Transform your account with an additional $500, offering unparalleled access and supreme functionalities.",
    "Projects10Description": "Additional projects"
  }
}