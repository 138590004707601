export const correctDecimalValue = (event: InputEvent, inputType: string, model: any) => {
  if (inputType !== 'number') return;
  const inputElement = event.target as HTMLInputElement;

  if (event.inputType === 'insertText' && /[^0-9]/.test(event.data || '')) {
    event.preventDefault();
  }

  inputElement.value = inputElement.value.replace(/[^0-9]/g, '');

  model.value = parseInt(inputElement.value) || 0;
};
