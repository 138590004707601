{
  "whatsAppWidget": {
    "access_token": "Token di accesso",
    "phone_id": "ID telefono",
    "save": "Salva",
    "how_to_connect": "Come connettere il chatbot WhatsApp di Omnimind AI",
    "make_sure_your_question": "Assicurati che la tua domanda sia chiara e concisa per ottenere risposte più accurate. Puoi aggiornare o modificare il progetto nelle impostazioni dell'azione se desideri utilizzare dati diversi per l'analisi.",
    "create_meta_business_account": "Crea un account aziendale Meta (Facebook)",
    "create_new_meta_app": "Crea una nuova app Meta (Facebook)",
    "login_to_your_account": "Accedi al tuo account",
    "select_my_apps": "Seleziona \"Le mie app\"",
    "select_create_app": "Seleziona \"Crea app\"",
    "select_options": "Seleziona opzioni",
    "use_case_other": "Caso d'uso: Altro",
    "app_type_business": "Tipo di app: Aziendale",
    "enter_app_details": "Inserisci i dettagli dell'app",
    "app_name": "Nome dell'app",
    "app_contact_email": "Email di contatto dell'app",
    "business_account": "Account aziendale (opzionale)",
    "add_whatsapp_to_your_meta_app": "Aggiungi WhatsApp alla tua app Meta",
    "find_add_products_to_your_app": "Trova \"Aggiungi prodotti alla tua app\"",
    "select_whatsapp_set_up": "Seleziona 'Configura WhatsApp'",
    "quick_start": "Avvio rapido",
    "select_business_account": "Seleziona l'account aziendale",
    "purchase_new_business_number": "Acquista un nuovo numero aziendale per WhatsApp",
    "select_api_setup": "Seleziona 'Configurazione API' e trova 'Passaggio 5'",
    "click_on_the_button": "Fai clic sul pulsante \"Aggiungi numero di telefono\"",
    "fill_in_all_fields": "Compila tutti i campi e conferma il tuo numero di telefono",
    "skip_if_already_have": "Salta se hai già un numero aziendale che puoi utilizzare per il tuo bot WhatsApp",
    "configure_whatsapp_settings": "Configura le impostazioni di WhatsApp",
    "select_configure_webhooks": "Seleziona 'Configura webhook' nella pagina 'Configurazione API'",
    "edit_webhook": "Modifica webhook",
    "add_a_callback_url_and_verify_token": "Aggiungi un URL di callback e verifica il token.",
    "callback_url": "URL di callback",
    "verify_token": "Verifica token",
    "manage_webhook_fields": "Gestisci i campi del webhook",
    "add_and_subscribe_only_to": "Aggiungi e iscriviti solo a",
    "add_a_payment_method_to_your_whatsapp": "Aggiungi un metodo di pagamento alla tua app WhatsApp",
    "payment_settings": "Impostazioni di pagamento",
    "add_payment_method": "Aggiungi metodo di pagamento",
    "add_phone_number_and_business_details": "Aggiungi numero di telefono (e dettagli aziendali)",
    "provide_necessary_info": "Fornisci le informazioni necessarie",
    "verify_number": "Verifica numero",
    "send_a_test_message": "Invia un messaggio di prova",
    "select_api_setup_and_find_step_1": "Seleziona 'Configurazione API' e trova 'Passaggio 1':",
    "select_the_from_number": "Seleziona il numero 'Da'",
    "choose_the_option_to_add_and_verify_a_new_number": "Scegli l'opzione per aggiungere e verificare un nuovo numero",
    "select_to_number": "Seleziona il numero 'A' (aggiungi un numero personale per i test)",
    "select_send_message": "Seleziona 'Invia messaggio'",
    "update_business_details_for_your_meta": "Aggiorna i dettagli aziendali per la tua app Meta",
    "select_settings_basic": "All'interno della tua app Meta, seleziona 'Impostazioni > Base'",
    "fill_in_all_the_necessary_information": "Compila tutte le informazioni necessarie",
    "verify_business_account": "Verifica l'account aziendale",
    "select_settings": "Seleziona 'Impostazioni'",
    "select_business_account_info": "Seleziona 'Informazioni sull'account aziendale'",
    "provide_all_the_necessary_information": "Fornisci tutte le informazioni necessarie",
    "select_security_centre": "Seleziona 'Centro sicurezza'",
    "select_start_verification": "Seleziona 'Avvia verifica'",
    "create_a_system_user": "Crea un utente di sistema nel tuo account aziendale",
    "settings_users_system_users": "Impostazioni > Utenti > Utenti di sistema",
    "create_new_system_user": "Crea nuovo utente di sistema",
    "name_anything": "Nome (qualsiasi)",
    "role_admin": "Ruolo: Amministratore",
    "select_add_assets": "Seleziona 'Aggiungi risorse'",
    "select_apps": "Seleziona 'App'",
    "select_the_new_app_for_whatsapp": "Seleziona la nuova app (per WhatsApp)",
    "toggle_manage_app": "Attiva/Disattiva 'Gestisci app'",
    "generate_new_token_for_system_user": "Genera un nuovo token per l'utente di sistema",
    "select_app_and_generate_new_token": "Seleziona l'app e 'Genera nuovo token'",
    "select_never_expire": "Seleziona 'Mai' scadere",
    "select_only_permissions_whatsapp_business_messaging": "Seleziona solo le autorizzazioni: whatsapp_business_messaging",
    "select_generate_token": "Seleziona 'Genera token'",
    "important_save_this_token": "Importante: Salva questo token da qualche parte, ne avrai bisogno",
    "add_token_and_phone_id": "Aggiungi Token e ID telefono all'inizio di questa pagina",
    "phone_id_this_is_from": "'ID telefono' - questo proviene dalla tua app Meta (configurazione API, passaggio 5), assicurati di selezionare il numero 'Da' corretto e copia l'ID telefono da qui",
    "token_this_is_from_step_8": "'Token' - questo proviene dal passaggio 8, dall'utente di sistema nel tuo account aziendale",
    "launch_your_whatsApp_integration": "Lancia la tua integrazione WhatsApp",
    "set_your_meta_app_from_development": "Imposta la tua app Meta da sviluppo a live (Apri la tua app Meta da"
  }
}