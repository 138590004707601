{
  "package": {
    "popular": "popular",
    "month": "month",
    "chosen": "Chosen",
    "contact_us": "Contact Us",
    "package": "Package",
    "change": "Change",
    "subscribe": "Subscribe",
    "projects": "Projects",
    "characters": "Characters",
    "add_units": "Add units",
    "units": "units",
    "informers": "Informers",
    "widgets": "Widgets",
    "file_extensions": "File Extensions",
    "alternative_ai": "Alternative AI",
    "updates": "Updates",
    "extended_customization": "Extended Customization"
  },
  "packageEnterpriseModal": {
    "contact_us": "Contact Us",
    "submit": "Submit",
    "please_tell_us_more_about_your_use_case": "Please tell us more about your use case",
    "share_your_expectations_from_the_plan": "Share your expectations from the plan such as: required features, number of users, characters limits, AI models, etc."
  },
  "packageFree": {
    "beginner_plan": "Beginner plan",
    "for_beginners_and_micro_projects": "For beginners and micro projects",
    "free": "Free",
    "project": "Project",
    "characters": "Characters",
    "overquote": "overquote",
    "calls": "Calls",
    "balance": "Balance",
    "try_now": "Try now!"
  },
  "packages": {
    "beginner_plan": "Beginner Plan",
    "enterprise": "Enterprise",
    "custom_software_development": "Custom software development with dedicated OmniMind platform hosting",
    "integration_to_your_business": "Integration to your business",
    "custom_dataflow": "Custom dataflow",
    "dedicated_platform": "Dedicated platform",
    "100_data_privacy": "100% data privacy",
    "invalid_number": "Invalid number",
    "the_package_is_successfully_installed": "The package is successfully installed!",
    "the_package_is_either_not_available": "The package is either not available or has already been installed."
  },
  "ResearcherDescription": "For entry-level studies and academic exploration",
  "ProDescription": "Advanced tools for professionals and experienced researchers",
  "EinsteinDescription": "Comprehensive support for leading scientists and innovators",
  "EnterpriseDescription": "Custom software development with dedicated OmniMind platform hosting"
}
