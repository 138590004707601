<template>
  <PortalProvider v-if="isPortalRoute">
    <InitializationProvider>
      <div
        class="main-section relative font-nunito text-sm font-normal antialiased"
        :class="[store.sidebar ? 'toggle-sidebar' : '', store.menu, store.layout, store.rtlClass]"
      >
        <component :is="mainLayout"></component>
      </div>
    </InitializationProvider>
  </PortalProvider>
  <InitializationProvider v-else>
    <div
      class="main-section relative font-nunito text-sm font-normal antialiased"
      :class="[
        store.sidebar ? 'toggle-sidebar' : '',
        store.sidebarNoProjects ? 'sidebar-no-projects' : '',
        store.menu,
        store.layout,
        store.rtlClass,
      ]"
    >
      <component :is="mainLayout"></component>
    </div>

    <!-- <BaseModal
      :is-visible="isModalVisible"
      :title="$t('appTranslations.subscription_limit_reached')"
      :confirm-button-text="$t('appTranslations.upgrade_subscription')"
      hide-cancel-button
      modal-wight-class="max-w-[460px]"
      @confirm="upgradeSubscription"
      @cancel="upgradeSubscription"
    >
      <div class="py-5">
        <div class="text-base font-medium text-[#1f2937] dark:text-white-dark/70">
          <p>
            {{ $t('appTranslations.it_looks_like_you_ve_reached') }}
          </p>
        </div>
      </div>
    </BaseModal> -->

    <BaseModal
      :is-visible="isModalVisible"
      :title="$t('appTranslations.subscription_limit_reached')"
      :confirm-button-text="$t('appTranslations.ok')"
      hide-cancel-button
      modal-wight-class="max-w-[460px]"
      @confirm="redirectToPayment"
      @close="redirectToPayment"
    >
      <div class="py-5">
        <div class="text-base font-medium text-[#1f2937] dark:text-white-dark/70">
          <p>
            {{ $t('appTranslations.run_out_of_units') }}
          </p>
        </div>
      </div>
    </BaseModal>
  </InitializationProvider>
</template>

<script lang="ts" setup>
import { computed, provide, watch, defineAsyncComponent, nextTick } from 'vue';
import appLayout from '@/layouts/app-layout.vue';
import authLayout from '@/layouts/auth-layout.vue';
import { useI18n } from 'vue-i18n';
import { useAppStore } from '@/stores';
import { subscribeToIsPaidUser } from '@helpers/getIsPaidUserSubscription';
import { useBaseComponents } from '@/hooks/asyncComponents';
import { useModal } from '@/hooks/useModal';
import { useRoute, useRouter } from 'vue-router';
import InitializationProvider from '@/components/auth/InitializationProvider.vue';
import { getDomain } from '@helpers/getDomain';
import { useGetBillingPackages } from './hooks/profile';
import { showAlert } from '@/composables/mixin-alert';
import { getLocaleForUser } from '@/helpers/userPreferences';
import { redirectToPackagesSelectionPage } from '@/router/utils/redirectToPackagesSelection';

const PortalProvider = defineAsyncComponent(() => import('@components/auth/PortalProvider.vue'));
const { BaseModal } = useBaseComponents();
const { hideModal, showModal, isModalVisible } = useModal();

const { t } = useI18n();

const store = useAppStore();

const { isPaidUser, setNewUserId, stopSubscription } = subscribeToIsPaidUser(store.user?.id);
const router = useRouter();
const route = useRoute();

const defaultDomains = ['app.omnimind.ai', 'dev.omnimind.ai', 'demo.omnimind.ai'];

const isPortalRoute = computed(() => {
  const isLocalEnv = import.meta.env.MODE === 'development';

  const domain = getDomain();

  return (
    Boolean(import.meta.env.VITE_OMNI_PORTALS_ENABLED) &&
    (isLocalEnv ? route.fullPath.includes('omni-portal/portal') : !defaultDomains.includes(domain))
  );
});

provide('is-portal-route', isPortalRoute);

const updateUserPreferences = (id: string) => {
  localStorage.setItem('currentUserId', id);
  store.toggleLocale(getLocaleForUser());
};

const { loadBillingPackages } = useGetBillingPackages();

const redirectToPayment = () => {
  hideModal();
  if (!isPaidUser.value) {
    redirectToPackagesSelectionPage(router);
  }
};

const combinedDependencies = computed(() => ({
  user: store.user,
  isPaidUser: isPaidUser.value,
}));

watch(
  combinedDependencies,
  async (newVal) => {
    const { user, isPaidUser } = newVal;

    if (user?.id) {
      updateUserPreferences(user.id);
      setNewUserId(user.id);
    } else {
      stopSubscription();
    }

    if (isPaidUser === false && user) {
      showModal();
      loadBillingPackages();
    }
  },
  { immediate: true },
);

const mainLayout = computed(() => {
  return store.mainLayout === 'auth' ? authLayout : appLayout;
});

const showBillingMessage = (type: 'success' | 'failed') => {
  type === 'success' && showAlert('success', t('appTranslations.we_ve_successfully_renewed_your'));
  type === 'failed' && showAlert('error', t('appTranslations.we_encountered_an_error_while'));

  router.replace({
    query: { ...router.currentRoute.value.query, r: undefined },
  });
};

type RouteQuery = {
  r?: 'success' | 'failed';
};

watch(
  () => {
    const query = route.query as unknown as RouteQuery;
    return query;
  },
  (newQuery) => {
    if (newQuery.r) {
      const newQueryString = newQuery.r;

      router.replace({
        query: { ...newQuery, r: undefined },
      });

      nextTick(() => {
        showBillingMessage(newQueryString);
      });
    }
  },
  { immediate: true },
);

</script>
