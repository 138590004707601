{
  "flashcardsWidget": {
    "export_to_quizlet": "Export to Quizlet",
    "export_to_cram": "Export to Cram",
    "how_to_import_into_cram": "How to Import into Cram",
    "begin_by_visiting_the_cram": "Begin by visiting the Cram website and selecting the \"Create Flashcards\" option. Then, choose \"Import a flashcard set\". Copy your data, ensuring each term and its definition are separated by a",
    "and_different_cards_are": "and different cards are distinguished by a",
    "new_line": "New Line",
    "paste_this_into_the": "Paste this into the input field labeled \"COPY AND PASTE YOUR DATA:\", and click \"Import Data\". Enjoy your study session!",
    "oops_it_seems_youve_reached_your": "Oops! It seems you've reached your message rate limit. To continue, kindly adjust your rate limit in the settings.",
    "question": "Question",
    "tab": "Tab",
    "my_answer": "My Answer",
    "correct_answer": "Correct Answer",
    "evaluate_my_answer": "Evaluate my answer on a scale from 1 to 5 based on its accuracy and completeness in comparison to the correct answer, considering the context { context }. Use the following guidelines for rating: If the answer is completely incorrect or irrelevant to the question and it demonstrates a lack of understanding of the topic or question rate \"1\". If the answer addresses the question but has major inaccuracies or omissions but it shows some understanding but also significant misunderstandings or gaps in knowledge rate \"2\". If the answer is somewhat correct but is incomplete or has minor inaccuracies and answer shows a fair understanding of the topic but lacks detail or precision rate \"3\". If the answer is largely correct and covers most aspects of the question but minor details or nuances may be missing, but the overall understanding is good rate \"4\".If The answer is completely correct and provides a comprehensive response to the question rate \"5\". Use the format: \"number;explanation\" for your response.",
    "something_went_awry": "Something went awry!",
    "your_rate_limit_exceeded": "Generate in {formLanguage} language {formAmount} questions from { context } in a VALID JSON FORMAT. Use pattern",
    "answers_should_only_contain_the_essential": "Answers should only contain the essential key terms or phrases directly relevant to the question, without elaborating.",
    "we_ve_found_parsed_length_questions": "We've found {parsedLength} questions for now. Adding more content might help us find even more!"
  },
  "flashcardsWidgetActions": {
    "view_all_questions": "View all questions",
    "delete_card": "Delete card",
    "edit_card": "Edit card",
    "export_to_quizlet": "Export to Quizlet",
    "export_to_anki": "Export to Anki",
    "export_to_brainscape": "Export to Brainscape",
    "export_to_cram": "Export to Cram",
    "regenerate_cards": "Regenerate cards",
    "clear_all_cards": "Clear all cards"
  },
  "flashcardsWidgetFeedback": {
    "feedback": "Feedback"
  },
  "flashcardsWidgetForm": {
    "select_the_number_and_the_language": "Select the number and the language of flashcards",
    "cards_number": "Cards number",
    "enter_quantity": "Enter quantity",
    "language": "Language",
    "generate": "Generate",
    "original": "Original"
  },
  "flashcardsWidgetNavigation": {
    "back": "Back",
    "step_and_length_of_questions": "{currentStep} of {questionsLength} questions",
    "next": "Next",
    "skip": "Skip"
  },
  "flashcardsWidgetQuestion": {
    "question": "Question",
    "enter_your_answer_here": "Enter your answer here...",
    "answer": "Answer",
    "your_answer": "Your Answer",
    "correct_answer": "Correct Answer"
  },
  "flashcardsWidgetQuestionEditor": {
    "edit_card": "Edit Card",
    "save": "Save",
    "question": "Question",
    "answer": "Answer"
  },
  "flashcardsWidgetQuestionFooter": {
    "check_my_answer": "Check my answer",
    "hide_answer": "Hide answer",
    "i_want_to_answer_it_by_myself": "I want to answer it by myself",
    "show_answer": "Show answer"
  },
  "flashcardsWidgetQuestionsList": {
    "looks_like_it_s_pretty_empty_here": "Looks like it's pretty empty here!",
    "start_adding_some_cards_to_see_them_appear": "Start adding some cards to see them appear."
  }
}
