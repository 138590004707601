{
  "ninjaIndicator": {
    "please_wait_while": "Please wait while we process your data. It may take about a minute.",
    "redirecting_to_your_project": "Redirecting to your project...",
    "we_are_processing_your_information": "We are processing your information..."
  },
  "progressCancelChain": {
    "cancel_all": "Cancel All",
    "do_you_want_to_stop": "Do you want to stop all the processing chains?",
    "stop_processing": "Stop processing?",
    "yes": "Yes",
    "no": "No"
  },
  "progressNinjaLoader": {
    "please_wait_while_we_process": "Please wait while we process your data. It may take about a minute.",
    "redirecting_to_your_project": "Redirecting to your project... ",
    "we_are_processing_your_information": "We're processing your data now, which may take some time. You can start using the system immediately, and the process will continue in the background.",
    "process_in_background": "Process in background"
  },
  "progressResourceItem": {
    "there_were_some_errors_during_processing": "There were some errors during processing."
  },
  "progressResourceList": {
    "jobs_in_progress": "Jobs in progress"
  }
}
