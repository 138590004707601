{
  "formClearHistoryItem": {
    "clear_chat_history": "Cancella cronologia chat",
    "do_you_want_to_clear": "Vuoi cancellare la tua cronologia chat?",
    "clear_history": "Cancella cronologia",
    "all_your_history_will_be_deleted": "Tutta la tua cronologia chat sarà cancellata."
  },
  "formOptions": {
    "more_options": "Altre opzioni"
  },
  "formPdfGeneratingItem": {
    "export_chat_as_pdf": "Esporta chat come PDF",
    "create_chat_to_pdf": "Crea chat in PDF",
    "wait_for_a_few_seconds_please": "Attendere qualche secondo...",
    "something_happened": "Si è verificato un problema durante la creazione del PDF"
  }
}