{
  "searchWidget": {
    "search": "pesquisar",
    "we_are_creating_examples_of_questions": "Estamos criando exemplos de perguntas que você pode fazer ao bot, mas você pode usar o chat agora.",
    "sorry_we_could_not_create_any_examples": "Desculpe, não conseguimos criar nenhum exemplo de perguntas que você pode fazer ao bot ainda.",
    "please_ask_the_owner_of": "Por favor, peça ao proprietário do projeto para torná-lo público",
    "the_project_owner_needs_to": "O proprietário do projeto precisa atualizar a assinatura, pois a atual expirou.",
    "404_oops_we_havet_found": "404 Ooops, não encontramos o projeto",
    "loading": "Carregando...",
    "oops_it_seems_youve_reached": "Oops! Parece que você atingiu o limite de mensagens. Para continuar, ajuste seu limite de taxa nas configurações."
  }
}