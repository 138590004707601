{
  "package": {
    "popular": "popular",
    "month": "mes",
    "chosen": "Elegido",
    "contact_us": "Contáctenos",
    "package": "Paquete",
    "change": "Cambiar",
    "subscribe": "Suscribirse",
    "projects": "Proyectos",
    "characters": "Personajes",
    "add_units": "Agregar unidades",
    "units": "unidades",
    "informers": "Informantes",
    "widgets": "Widgets",
    "file_extensions": "Extensiones de archivo",
    "alternative_ai": "IA alternativa",
    "updates": "Actualizaciones",
    "extended_customization": "Personalización extendida"
  },
  "packageEnterpriseModal": {
    "contact_us": "Contáctenos",
    "submit": "Enviar",
    "please_tell_us_more_about_your_use_case": "Por favor, cuéntenos más sobre su caso de uso",
    "share_your_expectations_from_the_plan": "Comparta sus expectativas del plan, como: características requeridas, número de usuarios, límites de personajes, modelos de IA, etc."
  },
  "packageFree": {
    "beginner_plan": "Plan para principiantes",
    "for_beginners_and_micro_projects": "Para principiantes y micro proyectos",
    "free": "Gratis",
    "project": "Proyecto",
    "characters": "Personajes",
    "overquote": "exceso de cotización",
    "calls": "Llamadas",
    "balance": "Balance",
    "try_now": "¡Pruébalo ahora!"
  },
  "packages": {
    "beginner_plan": "Plan para principiantes",
    "enterprise": "Empresa",
    "custom_software_development": "Desarrollo de software personalizado con alojamiento dedicado en la plataforma OmniMind",
    "integration_to_your_business": "Integración en su negocio",
    "custom_dataflow": "Flujo de datos personalizado",
    "dedicated_platform": "Plataforma dedicada",
    "100_data_privacy": "100% privacidad de datos",
    "invalid_number": "Número inválido",
    "the_package_is_successfully_installed": "¡El paquete se ha instalado correctamente!",
    "the_package_is_either_not_available": "El paquete no está disponible o ya ha sido instalado."
  },
  "ResearcherDescription": "Para estudios de nivel de entrada y exploración académica",
  "ProDescription": "Herramientas avanzadas para profesionales e investigadores experimentados",
  "EinsteinDescription": "Soporte integral para científicos líderes e innovadores",
  "EnterpriseDescription": "Desarrollo de software personalizado con alojamiento dedicado en la plataforma OmniMind"
}