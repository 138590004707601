{
  "docSearchWidget": {
    "scanning_through_the_documentation": "Scanning through the documentation, please hold on for a moment!",
    "generate_three_suggested_questions": "Generate three suggested questions based on the provided resources. Each question should be separated by a line break and should consist of no more than five words. Please avoid numbering the questions.",
    "oops_it_seems_youve_reached_your": "Oops! It seems you've reached your message rate limit. To continue, kindly adjust your rate limit in the settings.",
    "we_encountered_an_issue_while": "We encountered an issue while attempting to generate response! Try again later!",
    "search": "Search"
  },
  "docSearchWidgetHeader": {
    "hi_how_can_i_help_you": "Hi! How can I help you?"
  },
  "docSearchWidgetInput": {
    "type_a_question_here": "Type a question here...",
    "ask": "Ask"
  },
  "docSearchWidgetReset": {
    "reset": "Reset"
  },
  "docSearchWidgetWrapper": {
    "click_here_to_see_embed_code": "Click here to see embed code"
  }
}