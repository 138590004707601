<template>
  <slot :key="rerenderKey" />
</template>

<script setup lang="ts">
import { AuthorizationSymbol, createAuthorization } from '@/plugins/authorization';
import { signupCheckPromocode } from '@/components/auth/utils/signupCheckPromocode';
import { GET_USER_BILLING_PACKAGES_BY_PORTAL_ID } from '@/api/portals/queries';
import { useAgentToolsStore } from "@/stores/agentToolsStore";
import { useLazyQuery, useSubscription } from '@vue/apollo-composable';
import { OMNIMIND_APP_PORTAL_ID } from '@/api/constants/portals';
import { usePortalsStore } from '@/stores/portalsStore';
import { useWidgetsStore } from '@/stores/widgetsStore';
import { provide, ref, watch } from 'vue';
import { useUserData } from '@nhost/vue';
import { useAppStore } from '@/stores';
import { GET_PROJECTS } from '@/api';

let user = useUserData();
const portalStore = usePortalsStore();
const rerenderKey = ref(999);

const { load: loadProjects, onResult: onGetProjects } = useLazyQuery(
  GET_PROJECTS,
  { id: user.value?.id },
  {
    errorPolicy: 'ignore',
    fetchPolicy: 'no-cache',
  },
);

portalStore.billingUserPackagesLoading = true;

const { onResult: onResultSub } = useSubscription(GET_USER_BILLING_PACKAGES_BY_PORTAL_ID, () => ({
  user_id: user.value?.id,
  portal_id: portalStore?.portal?.id ?? OMNIMIND_APP_PORTAL_ID,
}));

const authorization = createAuthorization();

const store = useAppStore();
const widgetStore = useWidgetsStore();
const toolStore = useAgentToolsStore();

onGetProjects(async ({ data }) => {
  if (!data) {
    return;
  }
  const projectWithWidgetTypes = data.projects.map((x: any) => ({
    ...x,
    widgets: x.widgets.map((w: any) => ({
      ...w,
      widget_type: widgetStore.getWidgetTypeIdById(w.type_id),
    })),
  }));
  store.setProjects(projectWithWidgetTypes);
});

provide(AuthorizationSymbol, authorization);

onResultSub(async ({ data }) => {
  portalStore.billingUserPackagesLoading = false;

  if (!data) {
    return;
  }

  if (portalStore.portal?.id !== OMNIMIND_APP_PORTAL_ID) {
    portalStore.setBillingUserPackages(data.billing_user_packages);
  }

  authorization.package_features.value = data?.billing_user_packages?.[0]?.package_features;
  authorization.package_price.value = data?.billing_user_packages?.[0]?.package_price;
  authorization.package_costs.value = data?.billing_user_packages?.[0]?.package_costs;
  authorization.units_amount.value = data?.billing_user_packages?.[0]?.units_amount;
  authorization.units_spent.value = data?.billing_user_packages?.[0]?.units_spent;
  authorization.cost.value = data?.billing_user_packages?.[0]?.cost;
});

watch(
  user,
  async (val) => {
    if (val) {
      await signupCheckPromocode();
    }
    if (val?.id && portalStore.portal?.id !== OMNIMIND_APP_PORTAL_ID) {
      await loadProjects(undefined, { id: val?.id });
      await widgetStore.fetchWidgetTypes();
      await toolStore.fetchAllToolTypes();
      rerenderKey.value += 1;
    }
  },
  {
    immediate: true,
    deep: true,
  },
);
</script>
