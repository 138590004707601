{
  "knowledgeQa": {
    "no_data_so_far": "Sin datos hasta ahora.",
    "question": "Pregunta",
    "answer": "Respuesta",
    "last_modified": "Última modificación",
    "actions": "Acciones",
    "delete_these_items": "¿Eliminar estos elementos?",
    "are_you_sure_you_want_to_delete": "¿Estás seguro de que deseas eliminar los elementos seleccionados?",
    "this_action_cannot_be_undone": "Esta acción no se puede deshacer.",
    "the_resources_were_removed": "Los recursos fueron eliminados.",
    "an_error_occurred": "Ocurrió un error al eliminar los recursos",
    "question_and_answer_were_saved": "¡La pregunta y la respuesta se guardaron correctamente!",
    "an_error_occurred_while_saving": "Ocurrió un error al guardar la pregunta y la respuesta."
  },
  "knowledgeQaActions": {
    "edit": "Editar",
    "delete": "Eliminar"
  },
  "knowledgeQaEditModal": {
    "question": "Pregunta",
    "cancel": "Cancelar",
    "filter": "Filtrar",
    "apply": "Aplicar"
  },
  "knowledgeQaFilterModal": {
    "cancel": "Cancelar",
    "filter": "Filtrar",
    "apply": "Aplicar",
    "by_content": "Por contenido",
    "search_for_content": "Buscar contenido..."
  },
  "knowledgeQaFilters": {
    "by_content": "Por contenido"
  },
  "knowledgeQaToolbar": {
    "qa_selected": "QA seleccionadas:"
  }
}