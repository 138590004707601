{
  "likeDislikeAnswer": {
    "please_provide_additional_feedback": "Por favor, proporciona comentarios adicionales",
    "enter_your_username": "Ingresa tu nombre",
    "enter_your_email": "Ingresa tu correo electrónico",
    "what_was_the_issue_with_the_response": "¿Cuál fue el problema con la respuesta?",
    "email_and_username_are_required": "Se requiere correo electrónico y nombre para enviar comentarios",
    "thank_you_sincerely_for_your_feedback": "Gracias sinceramente por tus comentarios. Son muy valorados.",
    "sorry_but_we_encountered": "Lo sentimos, pero encontramos un problema al intentar enviar tus comentarios. Por favor, inténtalo de nuevo.",
    "grateful_for_the_thumbs_up": "¡Agradecido por el pulgar hacia arriba!"
  },
  "likeDislikeAnswerModal": {
    "submit": "Enviar"
  },
  "likeDislikeAnswerModalHeader": {
    "didnt_like_the_response": "No me gustó la respuesta"
  }
}