import { SettingsPropertiesEnum } from '@/types/SettingsPropertiesEnum';
import { ChatGptModelNamesEnum, ProvidersEnum } from '@/types/ChatGptModelNamesEnum';

export const dataExtractorWidgetSettings = {
  [SettingsPropertiesEnum.PROVIDERNAME]: ProvidersEnum.OPENAI,
  [SettingsPropertiesEnum.MODELNAME]: ChatGptModelNamesEnum.GPT_4_TURBO_128K,
  [SettingsPropertiesEnum.TEMPERATURE]: 0.8,
  [SettingsPropertiesEnum.HYBRID]: 0.6,
  [SettingsPropertiesEnum.LIMIT]: 4,
  [SettingsPropertiesEnum.TOP_P]: 0.6,
};
