{
  "pagesProfileId": {
    "profile": "Perfil",
    "profile_settings": "Configurações do perfil",
    "settings": "Configurações",
    "home": "Início",
    "change_password": "Alterar senha"
  },
  "pagesProfileIndex": {
    "my_profile": "Meu perfil",
    "notifications": "Notificações",
    "profile_summary": "Resumo do perfil",
    "services_credentials": "Credenciais de serviços",
    "api_keys": "Chaves de API",
    "billings": "Faturamentos",
    "marketplace": "Loja",
    "packages": "Pacotes",
    "integrations": "Integrações",
    "payment_details": "Detalhes de pagamento"
  }
}