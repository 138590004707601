{
  "baseAiHelp": {
    "book_call": "Anruf buchen",
    "hide": "Ausblenden"
  },
  "baseModal": {
    "ok": "Ok",
    "cancel": "Abbrechen",
    "delete": "Löschen"
  },
  "baseModalFooter": {
    "cancel_text": "Abbrechen",
    "submit_text": "Speichern"
  },
  "baseDatePicker": {
    "to": "bis"
  },
  "basePagination": {
    "first": "Erste",
    "prev": "Vorherige",
    "next": "Nächste",
    "last": "Letzte"
  },
  "baseSelect": {
    "choose": "Auswählen...",
    "select_all": "Alle auswählen"
  },
  "baseSelectTable": {
    "select_all": "Alle auswählen",
    "wordpress_site_types": "WORDPRESS-SEITENTYPEN"
  },
  "baseSpeechToTextButton": {
    "hold_and_ask_your_question": "Halten Sie und stellen Sie Ihre Frage. Klicken Sie auf die Schaltfläche 'Weitere Optionen', um bei Bedarf eine Sprache anzugeben."
  },
  "baseStepper": {
    "previous": "Zurück",
    "next": "Weiter"
  },
  "baseTextToSpeechButton": {
    "text_to_voice": "Text zu Sprache",
    "text_to_speech_syntesis": "Die Sprachsynthese unterstützt die Sprache {languageValue} nicht"
  },
  "baseUnsavedModal": {
    "all_your_unsaved_changes": "Alle Ihre nicht gespeicherten Änderungen gehen verloren. Änderungen vor dem Verlassen der Seite speichern?",
    "save_changes": "Änderungen speichern",
    "you_have_unsaved_changes": "Sie haben nicht gespeicherte Änderungen"
  },
  "buttonInputViewCopyText": {
    "copied_to_clipboard": "In die Zwischenablage kopiert!"
  }
}