{
  "chatWidget": {
    "we_are_creating_suggestion": "Estamos criando perguntas de sugestão para você. Mas você pode usar o chat agora",
    "type_your_question": "Desculpe, ainda não conseguimos criar nenhum {title}.",
    "processing_your_request": "Processando sua solicitação...",
    "ooops_we_have_t_found": "404 Ooops, não encontramos o projeto",
    "please_ask_the_owner_of": "Por favor, peça ao proprietário do projeto para torná-lo público",
    "the_project_owner_needs_to": "O proprietário do projeto precisa atualizar a assinatura, pois a atual expirou.",
    "oops_it_seems_youve_reached": "Oops! Parece que você atingiu o limite de mensagens. Para continuar, ajuste seu limite de taxa nas configurações."
  }
}