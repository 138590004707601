{
  "projectSettingsTeam": {
    "user_access_rights_successfully_updated": "Benutzerzugriffsrechte erfolgreich aktualisiert!",
    "something_went_awry": "Etwas ist schief gelaufen!",
    "user_with_email": "Benutzer mit E-Mail",
    "has_been_successfully_invited_to_you_project": "wurde erfolgreich zu Ihrem Projekt eingeladen!",
    "already_exists": "existiert bereits"
  },
  "teamInvite": {
    "enter_email_to_invite_people_to_this_project": "Geben Sie die E-Mail-Adresse ein, um Personen zu diesem Projekt einzuladen",
    "email": "E-Mail",
    "send_invite": "Einladung senden",
    "can_view": "Kann anzeigen",
    "can_edit": "Kann bearbeiten"
  },
  "teamLink": {
    "project_link": "Projektlink",
    "copy_link": "Link kopieren",
    "please_note_that_any_activity": "Bitte beachten Sie, dass alle Aktivitäten der Benutzer, mit denen Sie diesen Link teilen, Ihrem Kontostand belastet werden.",
    "url_link_copied_just_paste_the_link": "URL-Link kopiert! Fügen Sie den Link einfach ein, um das Projekt mit anderen zu teilen"
  },
  "teamsList": {
    "members": "Mitglieder"
  },
  "teamsListItem": {
    "can_view": "kann anzeigen",
    "can_edit": "kann bearbeiten",
    "remove": "entfernen",
    "you": "(Sie)",
    "owner": "Besitzer",
    "pending": "(ausstehend)"
  }
}