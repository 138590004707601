{
  "CustomPromptOptionsEnumTranslations": {
    "adviser": "Consigliere IA",
    "sales_assistant": "Assistente alle vendite",
    "shakespeare": "William Shakespeare",
    "professor": "Professore",
    "formal": "formale",
    "informal": "informale",
    "persuasive": "persuasivo",
    "collaborative": "collaborativo",
    "creative": "creativo",
    "emojis": "emoji",
    "mark_keywords": "evidenzia le parole chiave principali",
    "subheaders": "sottotitoli",
    "billet_points_format": "formato elenco puntato"
  }
}