{
  "portalPreview": {
    "example": "Ejemplo",
    "suggestedQuestions": "Preguntas sugeridas",
    "who_s_on_duty_today": "¿Quién está de guardia hoy?",
    "send": "Enviar"
  },
  "tenantPortalPortalUi": {
    "portal_styles": "Estilos del portal",
    "background_color": "Color de fondo",
    "primary_color": "Color primario",
    "secondary_color": "Color secundario/contorno",
    "accent_color": "Color de acento",
    "text_color": "Color del texto",
    "portal_preview": "Vista previa del portal",
    "apply": "Aplicar",
    "field_is_not_a_valid_hex_color": "El campo no es un color hexadecimal válido",
    "failed_to_upload_branding_logo": "Error al cargar el logotipo de marca",
    "portal_ui_successfully_saved": "La interfaz de usuario del portal se guardó correctamente"
  }
}