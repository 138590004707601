{
  "voiceMessageSpoiler": {
    "hide_transcript": "Transkript ausblenden",
    "view_transcript": "Transkript anzeigen"
  },
  "voiceStartMessage": {
    "listening_to_you": "Ich höre zu...",
    "welcome_to_voice_talk": "Willkommen bei Voice Talk",
    "widget": "Widget!",
    "lets_speak": "Lass uns sprechen",
    "press_stop_button": "Drücke die Stopptaste unten, wenn du die Aufnahme beenden möchtest",
    "press_button_below_with_microphone": "Drücke die Taste unten mit dem Mikrofon, um",
    "start_talking": "anzufangen zu sprechen"
  },
  "voiceWidgetAnswerMessage": {
    "copy_to_clipboard": "In die Zwischenablage kopieren"
  },
  "voiceWidgetFooter": {
    "hold_and_ask_your_question": "Halte und stelle deine Frage",
    "press_to_stop_talking": "Drücke zum Beenden des Sprechens"
  },
  "voiceWidgetMessageProcessing": {
    "processing_your_request": "Deine Anfrage wird verarbeitet..."
  },
  "voiceWidgetQuestionMessage": {
    "you": "Du"
  },
  "voiceWidgetQuestionSpoiler": {
    "you": "Du"
  }
}