<template>
  <SidebarProjectTreeItem
    v-if="!canViewOnly"
    :title="$t('sidebarProjectTree.knowledge')"
    icon-name="books"
    :is-selected="itemName === ItemTypeKeysEnum.Knowledge"
    @click="onClickKnowledge"
  />
  <SidebarProjectTreeItem
    :title="translateName"
    :icon-name="parsedIntegration?.icon"
    :is-selected="itemName === ItemTypeKeysEnum.Integrations"
    @click="onClickIntegrations"
  >
    <BaseButton
      v-tippy="{
        content: $t('sidebarProjectTree.add_a_new_view'),
        placement: 'top',
        delay: 200,
        offset: [0, 10],
      }"
      class="dark:hover:bg-dark/60"
      rounded-class="rounded-full !p-1.5"
      size="small"
      intent="text"
      @click.prevent.stop="$emit('addNewView')"
    >
      <div class="h-6 w-6">
        <BaseSvgIcon name="draggable2" />
      </div>
    </BaseButton>
  </SidebarProjectTreeItem>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useBaseComponents } from '@/hooks/asyncComponents';
import SidebarProjectTreeItem from '@components/layout/sidebar/SidebarProjectTreeItem.vue';
import { useCurrentUserProjectScopes } from '@/composables/useCurrentUserProjectScopes';
//Types
import { ItemTypeKeysEnum } from '@/types/ItemTypeKeysEnum';
import { useTranslateWidgetContent } from '@/composables/useTranslateWidgetContent';
import { useRouter } from 'vue-router';

const props = defineProps<{
  projectId: string;
  itemName: ItemTypeKeysEnum;
  integration?: any;
}>();

const emits = defineEmits<{
  (e: 'selectedItem', value: ItemTypeKeysEnum): void;
  (e: 'addNewView'): void;
}>();

const { BaseButton, BaseSvgIcon } = useBaseComponents();
const { translateWidgetTitle } = useTranslateWidgetContent();
const router = useRouter();

const parsedIntegration = computed(() =>
  props.integration ? JSON.parse(props.integration) : null,
);

const basicItemRoute = computed(() => `/projects/${props.projectId}`);
const integrationRoute = computed(
  () => `${basicItemRoute.value}/widget/view/resources/${parsedIntegration.value?.key}`,
);

const translateName = computed(() =>
  props.integration ? translateWidgetTitle(parsedIntegration.value) : '',
);

const { canViewOnly } = useCurrentUserProjectScopes(props.projectId);

const onClickKnowledge = () => {
  router.push(basicItemRoute.value + '/knowledge');
  emits('selectedItem', ItemTypeKeysEnum.Knowledge);
};

const onClickIntegrations = () => {
  router.push(integrationRoute.value);
  emits('selectedItem', ItemTypeKeysEnum.Integrations);
};

emits('selectedItem', ItemTypeKeysEnum.Integrations);
</script>

<style scoped>
.highlighted-item {
  position: relative;
}

.highlighted-item::before {
  content: '';
  position: absolute;
  left: 12px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #c6cfdb;
}
</style>
