{
  "baseAiHelp": {
    "book_call": "Book a call",
    "hide": "Hide"
  },
  "baseModal": {
    "ok": "Ok",
    "cancel": "Cancel",
    "delete": "Delete"
  },
  "baseModalFooter": {
    "cancel_text": "Cancel",
    "submit_text": "Save"
  },
  "baseDatePicker": {
    "to": "to"
  },
  "basePagination": {
    "first": "First",
    "prev": "Prev",
    "next": "Next",
    "last": "Last"
  },
  "baseSelect": {
    "choose": "Choose...",
    "select_all": "Select all"
  },
  "baseSelectTable": {
    "select_all": "Select all",
    "wordpress_site_types": "WORDPRESS SITE TYPES"
  },
  "baseSpeechToTextButton": {
    "hold_and_ask_your_question": "Hold and ask your question. Specify a language if needed by clicking next ('More options') button"
  },
  "baseStepper": {
    "previous": "Previous",
    "next": "Next"
  },
  "baseTextToSpeechButton": {
    "text_to_voice": "Text to voice",
    "text_to_speech_syntesis": "Text to speech syntesis does not support {languageValue} language"
  },
  "baseUnsavedModal": {
    "all_your_unsaved_changes": "All your unsaved changes will be lost. Save changes before leaving the page?",
    "save_changes": "Save changes",
    "you_have_unsaved_changes": "You have unsaved changes"
  },
  "buttonInputViewCopyText": {
    "copied_to_clipboard": "Copied to clipboard!"
  }
}