{
  "interactiveMindmapWidgetActions": {
    "click_here_to_download": "Clicca qui per scaricare",
    "language": "Lingua",
    "create_map": "Crea mappa"
  },
  "interactiveMindmapWidgetMap": {
    "interactive_mind_map": "Mappa mentale interattiva",
    "an_interactive_mind_map_is_a_digital": "Una mappa mentale interattiva è uno strumento digitale progettato per facilitare l'organizzazione e la visualizzazione delle informazioni in una struttura gerarchica. Creata utilizzando tecnologie web dinamiche, queste mappe mentali offrono funzionalità interattive come il trascinamento, lo zoom e la modifica dei nodi. Sono particolarmente utili per le sessioni di brainstorming, la pianificazione dei progetti e la visualizzazione di idee o sistemi complessi. Utilizzare una mappa mentale interattiva consente di categorizzare e collegare facilmente diverse informazioni, rendendo più facile comprenderle, analizzarle e collaborare su di esse.",
    "generate_a_mind_map_in": "Genera una mappa mentale in",
    "something_went_awry": "Qualcosa è andato storto!",
    "we_are_unable_to_create_a_mindmap": "Non siamo in grado di creare una mappa mentale basata sul contenuto fornito. Puoi provare ad aprire le impostazioni del widget e modificarle per ottenere risultati migliori!",
    "translate_into": "Traduci in",
    "language_every_name_property_s_value_of_in": "lingua ogni valore della proprietà \"name\" in questo oggetto JSON stringificato",
    "don_t_change_other_values": "Non modificare altri valori. Assicurati di utilizzare solo virgolette doppie e inizia la risposta con",
    "give_me_a_valid_js_array_of_5_topics": "Dammi un array JS valido di 5 argomenti (1-3 parole ciascuno) per creare una mappa mentale basata su {context} nel formato \"[argomento 1, argomento 2, argomento 3, argomento 4, argomento 5]\" nella lingua {languageValue}",
    "please_generate_a_mind_map": "Genera una mappa mentale, per favore",
    "about": "riguardo",
    "with": "con",
    "as_a_core_node": "come nodo principale"
  }
}