{
  "retellingWidget": {
    "retelling": "Переказ",
    "generate_retelling": "Створити переказ",
    "retell_in_language": "Переказати мовою {formLanguage} на {formSize} {formMethod} оригінального контексту",
    "an_error_occurred_while_fetching": "Сталася помилка під час отримання переказу."
  },
  "retellingWidgetAnswer": {
    "retold": "Переказано",
    "clear_retelling": "Очистити переказ",
    "retelling": "Переказ"
  },
  "retellingWidgetAnswerChunk": {
    "you_ve_successfully_deleted": "Ви успішно видалили частину переказу!"
  },
  "retellingWidgetAnswerHeader": {
    "click_to_edit": "Клацніть, щоб редагувати заголовок переказу"
  },
  "retellingWidgetModal": {
    "create_retelling_pdf": "Створити переказ у форматі PDF"
  },
  "retellingWidgetPreview": {
    "retelling_a_piece_of_content": "переказ частини вмісту"
  }
}