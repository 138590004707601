import { SettingsPropertiesEnum } from '@/types/SettingsPropertiesEnum';
import { gptWidgetSettings } from '@/components/project/settings/defaults/gptWidgetSettings';

export const qAWidgetSettings = {
  ...gptWidgetSettings,
  [SettingsPropertiesEnum.ANSWER]: 'Most likely',
  [SettingsPropertiesEnum.MESSAGE]: '',
  [SettingsPropertiesEnum.MESSAGE_IS_ACTIVE]: false,
  [SettingsPropertiesEnum.PLACEHOLDER]: 'Type a question here...',
  [SettingsPropertiesEnum.COLORSCHEMES]: [],
  [SettingsPropertiesEnum.SELECTEDSCHEME]: 'default',
  [SettingsPropertiesEnum.FONTSIZE]: '14',
  [SettingsPropertiesEnum.FONT_FAMILY]: '',
  [SettingsPropertiesEnum.BORDERRADIUS]: '',
  [SettingsPropertiesEnum.INPUTHEIGHT]: '',
  [SettingsPropertiesEnum.BUTTONHEIGHT]: '',
  [SettingsPropertiesEnum.SUGGESTED_QUESTIONS]: '',
  [SettingsPropertiesEnum.SHOW_SUGGESTED_QUESTIONS]: true,
  [SettingsPropertiesEnum.ROLE]: '',
  [SettingsPropertiesEnum.ROLE_CUSTOM_OPTIONS]: [],
  [SettingsPropertiesEnum.BOT_NAME]: '',
  [SettingsPropertiesEnum.BOT_NAME_IS_ACTIVE]: true,
  [SettingsPropertiesEnum.TONE]: '',
  [SettingsPropertiesEnum.TONE_CUSTOM_OPTIONS]: [],
  [SettingsPropertiesEnum.FORMATTING]: '',
  [SettingsPropertiesEnum.CUSTOM]: `The following is a friendly conversation between a human and an AI. The AI is talkative is and provides lots of specific details from its context.\nIf the AI does not know the answer to a question, add\nMost likely' before answering.\nPlease take into attention the context: {context}\nAnswer the question: {question}`,
  [SettingsPropertiesEnum.ANSWERLENGTH]: 300,
  [SettingsPropertiesEnum.IS_ROLE_CHECK]: false,
  [SettingsPropertiesEnum.IS_TONE_CHECK]: false,
  [SettingsPropertiesEnum.IS_FORMAT_CHECK]: false,
  [SettingsPropertiesEnum.IS_CUSTOM_CHECK]: false,
  [SettingsPropertiesEnum.IS_ANSWER_LENGTH_CHECK]: true,
  [SettingsPropertiesEnum.HIDE_POWERED_BY]: false,
  [SettingsPropertiesEnum.LIVE_CONNECT_ENABLED]: false,
  [SettingsPropertiesEnum.LIVE_CONNECT]: 'Talk to a person',
  [SettingsPropertiesEnum.LIVE_CONNECT_ACTION]: {
    name: '',
    data: {
      link: '',
      code: '',
    },
  },
  [SettingsPropertiesEnum.OFFLINE_MESSAGE_ENABLED]: false,
  [SettingsPropertiesEnum.OFFLINE_MESSAGE]: 'Leave a message',
  [SettingsPropertiesEnum.OFFLINE_MESSAGE_ACTION]: {
    name: '',
    data: {
      form: '',
      link: '',
      code: '',
    },
  },
  [SettingsPropertiesEnum.USE_FOLLOWUPS]: true,
  [SettingsPropertiesEnum.KNOWLEDGE_DIVERSITY]: 3,
};
