import type { IAppEventProvider } from '@/analytics/IAppEventProvider';

const customerId = import.meta.env.VITE_REDITUS_CUSTOMER_ID;

const load = () => {
  (function (w, d, s, p, t) {
    w.gr =
      w.gr ||
      function (...args) {
        // @ts-ignore
        w.gr.ce = 60;
        w.gr.q = w.gr.q || [];
        w.gr.q.push(args);
      };
    // @ts-ignore
    p = d.getElementsByTagName(s)[0];
    // @ts-ignore
    t = d.createElement(s);
    // @ts-ignore
    t.async = true;
    // @ts-ignore
    t.src = 'https://script.getreditus.com/v2.js';
    // @ts-ignore
    p.parentNode.insertBefore(t, p);
  })(window, document, 'script');

  window.gr('initCustomer', customerId);
};

export class ReditusProvider implements IAppEventProvider {
  private isLoaded: boolean;

  constructor() {
    this.isLoaded = false;
  }

  async init(): Promise<void> {
    try {
      await load();
      this.isLoaded = true;
    } catch (error) {
      console.error('Failed to load Reditus script', error);
    }
  }

  private trackEvent(eventId: string, data: Record<string, any>): void {
    if (this.isLoaded) {
      window.gr('track', eventId, data);
    } else {
      console.error('Reditus script is not loaded');
    }
  }

  signup = (data: Record<string, any>): void => {
    const { email } = data;
    this.trackEvent('conversion', { email });
  };
}
