{
  "userInformation": {
    "general_information": "Edit Information",
    "full_name": "Full Name",
    "enter_full_name": "Enter full name",
    "email": "Email",
    "enter_email": "Enter email",
    "company": "Company",
    "enter_company": "Enter company",
    "phone": "Phone",
    "enter_phone_number": "Enter phone number",
    "interface_language": "Interface language",
    "select_interface_language": "Select interface language",
    "timezone": "Timezone",
    "select_your_timezone": "Select your timezone",
    "cancel": "Cancel",
    "avatar": "Avatar",
    "upload_photo": "Upload Photo",
    "save": "Save",
    "this_field_contains_special_characters": "This field contains special characters",
    "your_personal_data_was_updated": "Your personal data was updated!",
    "entered_phone_number_is_already_in_use": "Entered phone number is already in use",
    "invalid_form": "Some fields are missing or incorrect. Please review the highlighted fields and try again.",
    "confirmation_email_sent": "A confirmation letter for your email change has been sent to your new email address"
  }
}
