{
  "dataExtractorWidget": {
    "data_extractor_widget": "Estrattore di dati",
    "language": "Lingua",
    "add_prompt": "Aggiungi prompt",
    "reset_table": "Resetta tabella",
    "active": "Attivo",
    "resource": "Risorsa",
    "resource_already_exists": "La risorsa esiste già",
    "something_went_awry": "Qualcosa è andato storto",
    "loading": "Caricamento",
    "error": "Errore",
    "error_occured_while_creating_pdf": "Si è verificato un errore durante la creazione del PDF",
    "the_prompt_has_been_successfully_saved": "Il prompt è stato salvato con successo nel Catalogo.",
    "contact_owner_to_grant_permission": "Contatta il proprietario del progetto per ottenere il permesso di aggiungere risorse."
  },
  "dataExtractorWidgetActions": {
    "scan": "Scansiona",
    "export_pdf": "Esporta PDF",
    "export_csv": "Esporta CSV"
  },
  "dataExtractorWidgetAddPromptModal": {
    "add_edit_prompt": "Aggiungi/Modifica Prompt",
    "add_save": "Aggiungi/Salva",
    "add_prompt_to_catalog": "Aggiungi prompt al catalogo",
    "column": "Colonna",
    "locations": "Posizioni",
    "open_prompt_catalog": "Apri catalogo prompt",
    "prompt": "Prompt",
    "common_locations_of_clients": "Posizioni comuni dei clienti con cui fanno affari"
  },
  "dataExtractorWidgetAddPromptSupplement": {
    "these_prompts_will_be_applied": "Questi prompt verranno applicati a ogni risorsa",
    "by_bullet_points": "con punti elenco",
    "as_a_quote": "come una citazione",
    "in_a_few_words": "in poche parole"
  },
  "dataExtractorWidgetAddResourceModal": {
    "add_edit_resource": "Aggiungi/Modifica Risorsa",
    "submit": "Invia",
    "enter_your_resource": "Inserisci la tua risorsa",
    "this_resource_is_already_exist": "Questa risorsa esiste già!"
  },
  "dataExtractorWidgetDeleteResourceModal": {
    "delete_this_resource": "Eliminare questa risorsa?",
    "delete_resource": "Elimina risorsa",
    "resource_will_be_deleted": "La risorsa verrà eliminata dalla tabella.",
    "delete_resource_from_project": "Elimina risorsa dal progetto"
  },
  "dataExtractorWidgetPdfModal": {
    "create_pdf": "Crea PDF",
    "ok": "Ok",
    "wait_for_a_few_seconds_please": "Attendere qualche secondo..."
  },
  "dataExtractorWidgetPromptCatalog": {
    "prompt_catalog": "Catalogo Prompt",
    "delete_column": "Elimina colonna dal catalogo",
    "delete_prompt": "Elimina prompt dal catalogo"
  },
  "dataExtractorWidgetTable": {
    "no_resources_so_far": "Nessuna risorsa finora"
  },
  "dataExtractorWidgetTableHead": {
    "resource": "Risorsa",
    "refresh_values": "Aggiorna valori",
    "edit_prompt": "Modifica prompt",
    "delete_prompt": "Elimina prompt dalla tabella"
  }
}