{
  "portalPackageItem": {
    "name_is_not_provided": "Name wurde nicht angegeben",
    "click_to_edit_the_package": "Klicken Sie hier, um das Paket zu bearbeiten",
    "click_to_delete_the_package": "Klicken Sie hier, um das Paket zu löschen",
    "package_name": "Paketname",
    "your_package_name": "Ihr Paketname",
    "description": "Beschreibung",
    "your_package_description": "Ihre Paketbeschreibung",
    "model": "Modell",
    "package_price": "Paketpreis",
    "subscription_cost_for_the_package": "Abonnementkosten für das Paket",
    "cost_per_symbols": "Kosten pro {symbols_quantity} Symbole",
    "cost_for_characters": "Kosten für {characters_quantity} Zeichen bei Abfragen an OpenAI (entwickelt, um übermäßige Benutzerkosten zu vermeiden). Im Durchschnitt enthält eine Anfrage {average} Zeichen",
    "approx_number_of_requests": "ungefähre Anzahl von Anfragen",
    "trial_days": "Testtage",
    "links": "Links",
    "create_portal_link": "Portal-Link erstellen",
    "delete_this_project": "Dieses Projekt löschen?",
    "delete_project": "Projekt löschen",
    "package_will_be_deleted": "Das Paket wird gelöscht. Dies kann nicht rückgängig gemacht werden",
    "the_item_has_been_successfully_deleted": "Der Artikel wurde erfolgreich gelöscht."
  },
  "portalPackages": {
    "no_packages_so_far": "Bisher keine Pakete",
    "apply": "Anwenden",
    "stripe_account_is_not_configured": "Stripe-Konto ist nicht konfiguriert",
    "packages_successfully_saved": "Pakete erfolgreich gespeichert"
  }
}