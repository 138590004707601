{
  "projectSettingsTeam": {
    "user_access_rights_successfully_updated": "Права доступу користувача успішно оновлені!",
    "something_went_awry": "Щось пішло не так!",
    "user_with_email": "Користувач з електронною поштою",
    "has_been_successfully_invited_to_you_project": "був успішно запрошений до вашого проекту!",
    "already_exists": "вже існує"
  },
  "teamInvite": {
    "enter_email_to_invite_people_to_this_project": "Введіть електронну пошту, щоб запросити людей до цього проекту",
    "email": "Електронна пошта",
    "send_invite": "Надіслати запрошення",
    "can_view": "Може переглядати",
    "can_edit": "Може редагувати"
  },
  "teamLink": {
    "project_link": "Посилання на проект",
    "copy_link": "Скопіювати посилання",
    "please_note_that_any_activity": "Зверніть увагу, що будь-яка дія користувачів, з якими ви поділитеся цим посиланням, буде зарахована на ваш рахунок.",
    "url_link_copied_just_paste_the_link": "Посилання на URL скопійовано! Просто вставте посилання кому-небудь, з ким ви хочете поділитися проектом"
  },
  "teamsList": {
    "members": "Учасники"
  },
  "teamsListItem": {
    "can_view": "може переглядати",
    "can_edit": "може редагувати",
    "remove": "видалити",
    "you": "(ви)",
    "owner": "власник",
    "pending": "(очікує)"
  }
}