{
  "knowledgeChunksModal": {
    "chunks": "Fragmentos",
    "save": "Guardar",
    "chunks_selected": "fragmentos seleccionados",
    "no_data_so_far": "Hasta ahora no hay datos.",
    "title": "Título",
    "page": "Página",
    "size": "Tamaño",
    "last_modified": "Última modificación",
    "text": "Texto",
    "actions": "Acciones",
    "no_chunks_so_far": "Hasta ahora no hay fragmentos",
    "all_done_your_items_were_removed_successfully": "¡Listo! Tus elementos se eliminaron correctamente."
  },
  "knowledgeChunksModalPagination": {
    "prev": "Anterior",
    "next": "Siguiente"
  },
  "knowledgeChunksModalSearch": {
    "quick_search": "Búsqueda rápida",
    "search": "Buscar"
  },
  "knowledgeFilterModal": {
    "filter": "Filtrar",
    "apply": "Aplicar",
    "reset": "Restablecer",
    "by_name": "Por nombre",
    "by_title": "Por título",
    "by_period": "Por período",
    "search_for_resource_name": "Buscar nombre de recurso...",
    "search_for_resource_title": "Buscar título de recurso...",
    "last_year": "Último año",
    "quarter": "Trimestre",
    "month": "Mes",
    "week": "Semana",
    "day": "Día"
  }
}