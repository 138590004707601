{
  "googleSheetsAddOn": {
    "how_to_add_omnimind": "Come aggiungere l'estensione di Google Sheets di Omnimind AI",
    "please_ensure_that_the_project": "Assicurati che il progetto sia impostato su 'pubblico'. Per farlo, clicca sul pulsante 'Pubblica' per rendere il progetto accessibile al pubblico.",
    "copy_your_api_key": "Copia la tua chiave API",
    "go_to_the_google_sheet_document": "Vai al documento di Google Sheet in cui desideri utilizzare l'estensione.",
    "press_the_extensions_menu_button": "Premi il pulsante del menu delle estensioni e scegli Componenti aggiuntivi e Ottieni componenti aggiuntivi",
    "find_the_omnimind_add_on": "Trova l'estensione di OmniMind e installala",
    "after_installing_the_add_on": "Dopo aver installato l'estensione, vai al menu delle estensioni, trova la voce di menu di OmniMind e premi Avvia",
    "you_will_be_prompted_to": "Ti verrà chiesto di autorizzarti con OmniMind. Autorizzati, verrai reindirizzato al documento di Google.",
    "launch_the_add_on_again": "Avvia nuovamente l'estensione. Otterrai una finestra laterale in cui puoi premere Ottieni progetti e specificare quali dati del progetto desideri utilizzare nel documento.",
    "paste_your_api_key_in_the_provided_field": "Incolla la tua chiave API nel campo fornito e clicca su Ottieni progetti.",
    "select_your_project": "Seleziona il tuo progetto",
    "a_list_of_your_projects_in_omnimind": "Verrà visualizzata una lista dei tuoi progetti in OmniMind.",
    "select_the_project_you_would_like_to": "Seleziona il progetto che desideri utilizzare per l'analisi dei dati.",
    "use_the_omni_ask_function": "Utilizza la funzione OMNI_ASK",
    "click_on_a_cell_where_you": "Clicca su una cella in cui desideri che appaia la risposta.",
    "enter_the_function": "Inserisci la funzione",
    "your_question_here": "La tua domanda qui",
    "replace": "Sostituisci",
    "with_the_actual_question_you": "con la domanda effettiva che desideri fare.",
    "press_enter": "Premi Invio",
    "getting_answers": "Ottenere risposte",
    "after_pressing_enter": "Dopo aver premuto Invio, l'estensione cercherà automaticamente la risposta in base alle risorse caricate nel progetto selezionato su OmniMind.",
    "the_answer_will_then_appear": "La risposta apparirà quindi nella cella in cui hai inserito la funzione.",
    "tips": "Suggerimenti",
    "make_sure_your_question": "Assicurati che la tua domanda sia chiara e concisa per ottenere risposte più accurate. Puoi fare più domande contemporaneamente utilizzando la funzione OMNI_ASK in diverse celle. Puoi aggiornare o modificare il progetto nelle impostazioni dell'estensione se desideri utilizzare dati diversi per l'analisi.",
    "support": "Supporto",
    "if_you_encounter_any": "Se incontri problemi o hai ulteriori domande, visita la nostra pagina di supporto su"
  }
}