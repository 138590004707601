{
  "settingsBehaviour": {
    "behavior": "Comportamiento",
    "choose_option": "Elige la opción que deseas editar",
    "label_for_examples_of_questions": "Etiqueta para Ejemplos de preguntas que puedes hacer al bot",
    "before_you_start_to_work_with_widget": "Antes de comenzar a trabajar con el widget, verás ejemplos de preguntas que puedes hacer al bot",
    "enter_label_for_examples_of_questions": "Ingresa una etiqueta para Ejemplos de preguntas que puedes hacer al bot...",
    "split_your_questions_with_a_new_line": "Separa tus preguntas con una nueva línea.",
    "welcome_messages": "Mensajes de bienvenida",
    "enter_messages": "Ingresa mensajes, separados por doble Enter.",
    "start_each_message": "Inicia cada mensaje en una nueva línea. Presiona la tecla de retorno dos veces para separar los mensajes.",
    "type_messages": "Escribe mensajes, doble Enter para separar.",
    "add_a_custom_welcome_message_interval": "Agregar un intervalo de mensaje de bienvenida personalizado (segundos)",
    "enter_welcome_message_interval": "Ingresa el intervalo de mensaje de bienvenida...",
    "add_a_custom_welcome_message": "Cambiar el \"No sé la respuesta\"",
    "when_your_bot_isn_t_sure_about": "Cuando tu bot no está seguro acerca de la respuesta, comienza la oración con \"Probablemente.\" Puedes cambiar esta frase y aparecerá en su lugar.",
    "most_likely": "Probablemente...",
    "add_a_custom_message": "Agregar un mensaje personalizado al final de cada respuesta de OmniMind",
    "enter_custom_message": "Ingresa un mensaje personalizado...",
    "default_language": "Idioma predeterminado",
    "custom_language": "Idioma personalizado",
    "show_followups_questions": "Mostrar preguntas de seguimiento",
    "live_connect": "Conexión en vivo",
    "none": "Ninguno",
    "button_name_for_leaving_a_message": "Nombre del botón para dejar un mensaje",
    "leave_a_message": "Dejar un mensaje",
    "offline_message": "Mensaje sin conexión",
    "button_name_for_offline_message": "Nombre del botón para mensaje sin conexión",
    "dialogs": "Conversaciones",
    "anonymize_sensitive_data": "Anonimizar datos sensibles",
    "language_of_the_data": "Idioma de los datos",
    "language": "Idioma",
    "replace_value": "Reemplazar valor",
    "replace": "Reemplazar"
  },
  "behaviourDialogsSetting": {
    "history_mode": "Modo de historial",
    "collect_user_mode": "Modo de recopilación de usuario",
    "track_all_messages": "Registrar todos los mensajes",
    "track_only_messages_with": "Registrar solo comentarios negativos y consultas de soporte",
    "dont_track": "No registrar",
    "collect_username_and_email_with_form": "Recopilar nombre de usuario y correo electrónico con formulario",
    "provide_username_and_email_by_yourself": "Proporcionar nombre de usuario y correo electrónico por ti mismo",
    "do_not_collect_username_and_email": "No recopilar nombre de usuario y correo electrónico"
  },
  "behaviourLiveConnectAction": {
    "selected_option_will_be_applied": "La opción seleccionada se aplicará como acción para el botón",
    "live_connect_options": "Opciones de conexión en vivo",
    "your_custom_js_code": "Tu código JS personalizado",
    "ensure": "Asegúrate de que",
    "is_correctly_set_up": "esté configurado correctamente en tu proyecto. ¿Necesitas ayuda?",
    "check_here": "Verifica aquí."
  },
  "behaviourOfflineMessageAction": {
    "selected_option_will_be_applied_as_action_for_button": "La opción seleccionada se aplicará como acción para el botón",
    "offline_message_options": "Opciones de mensaje sin conexión",
    "your_custom_js_code": "Tu código JS personalizado",
    "your_email": "Tu correo electrónico"
  },
  "behaviourRateLimit": {
    "enable_rate_limit": "Habilitar límite de velocidad",
    "rate_limit": "Límite de velocidad",
    "enable_rate_limit_message": "Limita el número de mensajes enviados desde este widget por hora"
  }
}