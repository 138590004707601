{
  "agent_page": {
    "agent_settings": "Agent settings",
    "select_model": "Select Model",
    "prompt_editor": "Prompt Editor",
    "prompt_library": "Prompt Library"
  },
  "select_model": {
    "select_provider": "Select provider",
    "select_model": "Select model"
  },
  "prompt_editor": {
    "write_your_instruction": "Write your instruction",
    "variables": "Variables",
    "save_prompt": "Save Prompt",
    "prompt_has_been_updated": "Prompt has been updated!",
    "model_has_been_updated": "Model has been updated!",
    "prompt_view": "Prompt view",
    "edit_prompt": "Edit prompt",
    "cancel": "Cancel"
  },
  "prompt_library": {
    "no_save_prompts": "No saved prompts",
    "select_a_department_to_see_tasks": "Select a department to see tasks"
  }
}