{
  "copyProjectModal": {
    "clone_current_project": "Aktuelles Projekt klonen",
    "clone_project": "Projekt klonen",
    "copy_this_project": "Dieses Projekt kopieren?",
    "copy_project": "Projekt kopieren",
    "all_your_settings_and_resources_on_this": "Alle Ihre Einstellungen und Ressourcen in diesem Projekt werden kopiert.",
    "it_looks_like_you_ve_reached_your_maximum_number": "Es sieht so aus, als hätten Sie die maximale Anzahl an Projekten erreicht. Um ein neues Projekt zu starten, sollten Sie vorhandene Projekte archivieren oder löschen, die Sie nicht mehr benötigen, oder unsere Abonnementpläne für höhere Limits erkunden."
  },
  "deleteProjectModal": {
    "delete_current_project": "Aktuelles Projekt löschen",
    "delete_project": "Projekt löschen",
    "delete": "Löschen",
    "all_your_data_on": "Alle Ihre Daten auf",
    "will_be_deleted_immediately": "werden sofort gelöscht.",
    "the_project": "dem Projekt"
  },
  "deleteShopifyContent": {
    "seems_like_you_ve_created_a_project_using_our_shopify": "Es scheint, als hätten Sie ein Projekt mit unserer Shopify-App erstellt und überlegen nun, es über die Haupt-App zu löschen. Wir empfehlen, es über die Shopify-App zu löschen: Das Löschen eines Projekts über unsere Shopify-App ist der empfohlene und sicherste Weg, um einen reibungslosen und fehlerfreien Vorgang zu gewährleisten.",
    "yes_i_understand_it_but_want_to_delete_it_anyway": "Ja, ich verstehe es, möchte es aber trotzdem löschen."
  },
  "projectUploadingMessage": {
    "you_have_updated_knowledge_please_wait": "Sie haben das Wissen aktualisiert. Bitte warten Sie, während wir Ihr Wissen hochladen.",
    "knowledge_uploaded": "Wissen hochgeladen..."
  },
  "publishProject": {
    "unpublish": "Veröffentlichen aufheben",
    "publish": "Veröffentlichen",
    "unpublish_project": "Projektveröffentlichung aufheben",
    "all_users_who_had_access_to_the_widgets": "Alle Benutzer, die über den Link Zugriff auf die Widgets hatten, verlieren die Möglichkeit, sie zu verwenden.",
    "share_project": "Projekt teilen",
    "please_note_that_any_activity_of_the_users": "Bitte beachten Sie, dass alle Aktivitäten der Benutzer, mit denen Sie diesen Link teilen, an Ihr Kontoguthaben gebunden sind.",
    "publish_widget": "Widget veröffentlichen"
  },
  "renameProject": {
    "enter_the_short_name_of_your_project": "Geben Sie den Kurznamen Ihres Projekts ein",
    "my_project": "Mein Projekt",
    "description": "Beschreibung"
  },
  "shareProject": {
    "widget_link_copied_to_clipboard": "Widget-Link in die Zwischenablage kopiert",
    "share_project": "Projekt teilen",
    "please_note_that_any_activity_of_the_users": "Bitte beachten Sie, dass alle Aktivitäten der Benutzer, mit denen Sie diesen Link teilen, an Ihr Kontoguthaben gebunden sind.",
    "share": "Teilen",
    "publish_widget": "Widget veröffentlichen"
  },
  "shareProjectModal": {
    "share_widget": "Widget teilen",
    "please_note_that_any_activity_of_the_users": "Bitte beachten Sie, dass alle Aktivitäten der Benutzer, mit denen Sie diesen Link teilen, an Ihr Kontoguthaben gebunden sind.",
    "copy_link": "Link kopieren",
    "share_on": "Teilen auf"
  },
  "unpublishProject": {
    "unpublish_project": "Projektveröffentlichung aufheben",
    "all_users_who_had_access_to_the_widgets": "Alle Benutzer, die über den Link Zugriff auf die Widgets hatten, verlieren die Möglichkeit, sie zu verwenden."
  }
}