{
  "pagesProjectsIdWidgetViewContent": {
    "let_s_upload_the_content": "Carichiamo il contenuto",
    "with": "Con",
    "informers": "Informatori",
    "you_can_upload_information": "puoi caricare informazioni da una varietà di fonti, come siti web, documenti PDF, persino cartelle Google e altro ancora. Ricorda, puoi sempre tornare a questa schermata per caricare ulteriori contenuti nel tuo progetto",
    "upload_information": "Carica informazioni da una varietà di fonti, come siti web, documenti PDF, persino cartelle Google e altro ancora. Ricorda, puoi sempre tornare a questa schermata per caricare ulteriori contenuti nel tuo progetto quando ne hai bisogno.",
    "whenever_you_need": "quando ne hai bisogno",
    "select_widget": "Seleziona widget",
    "back": "Indietro",
    "we_are_working_on_it": "Stiamo lavorando su questo.",
    "is_not_supported_for_project_with": "non è supportato per il progetto con dati caricati. Crea un nuovo progetto per questo"
  },
  "pagesProjectsIdWidgetMyWidgets": {
    "delete_widget": "Elimina Widget",
    "confirm_widget_deletion": "Conferma eliminazione Widget",
    "are_you_sure_you_want_to_delete": "Sei sicuro di voler eliminare il widget {widgetName}? Questa azione non può essere annullata."
  }
}