import type { ApiResponse } from './ApiResponse';

export function convertResponseToApiResponse(response: Response, body?: unknown): ApiResponse {
  const headers = Array.from(response.headers.entries()).reduce<Record<string, string>>(
    (headersMap, [key, value]) => {
      headersMap[key] = value;
      return headersMap;
    },
    {},
  );

  return {
    body,
    headers,
    status: response.status,
    statusText: response.statusText,
    url: response.url,
  };
}
