{
  "auth": {
    "changePassword": {
      "change_password": "Cambia password",
      "enter_password": "Inserisci password",
      "new_password": "Nuova password",
      "password": "Password",
      "old_password": "Vecchia password",
      "cancel": "Annulla",
      "password_confirmation": "Conferma nuova password",
      "success": "Password cambiata con successo"
    },
    "changePasswordForm": {
      "new_password": "Nuova password",
      "enter_password": "Inserisci password",
      "password_confirmation": "Conferma password",
      "change_password": "Cambia password"
    },
    "portalProvider": {
      "portal": "Portale",
      "portal_not_found": "Portale non trovato",
      "is_not_currently_active": "non è attualmente attivo",
      "you_are_currently_blocked": "Sei attualmente bloccato"
    },
    "promocode": {
      "something_went_wrong": "Qualcosa è andato storto",
      "enjoy_benefits": "Goditi i vantaggi con il codice di riscatto!",
      "redemption_code": "Codice di riscatto",
      "enter_redemption_code": "Codice di riscatto",
      "verifying": "Verifica in corso...",
      "verify": "Verifica",
      "create_account_in_omnimind": "Crea un account in Omnimind AI per ottenere crediti acquistati",
      "this_promo_code_does_not_seem_to_exist": "Questo codice promozionale sembra non esistere. Controlla se ci sono errori di battitura e riprova.",
      "this_promo_code_has_expired": "Questo codice promozionale è scaduto. Prova un altro codice."
    },
    "resetPasswordForm": {
      "reset_password": "Reimposta password",
      "enter_your_email_for_resetting_password": "Inserisci la tua email per reimpostare la password",
      "enter_email": "Inserisci email",
      "please_check_your_mailbox": "Controlla la tua casella di posta elettronica e \n segui il link delle istruzioni per completare la modifica della password.",
      "if_you_can_not_find_it": "Se non lo trovi nella tua casella di posta, controlla la tua",
      "spam_folder": "cartella spam",
      "as_well": "anche."
    },
    "signOut": {
      "sign_out": "Esci"
    }
  }
}