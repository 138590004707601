{
  "voiceMessageSpoiler": {
    "hide_transcript": "Nascondi trascrizione",
    "view_transcript": "Visualizza trascrizione"
  },
  "voiceStartMessage": {
    "listening_to_you": "Ti sto ascoltando...",
    "welcome_to_voice_talk": "Benvenuto in Voice Talk",
    "widget": "Widget!",
    "lets_speak": "Parliamo",
    "press_stop_button": "Premi il pulsante di stop qui sotto se vuoi interrompere la registrazione",
    "press_button_below_with_microphone": "Premi il pulsante qui sotto con il microfono per",
    "start_talking": "iniziare a parlare"
  },
  "voiceWidgetAnswerMessage": {
    "copy_to_clipboard": "Copia negli appunti"
  },
  "voiceWidgetFooter": {
    "hold_and_ask_your_question": "Tieni premuto e fai la tua domanda",
    "press_to_stop_talking": "Premi per smettere di parlare"
  },
  "voiceWidgetMessageProcessing": {
    "processing_your_request": "Elaborazione della tua richiesta..."
  },
  "voiceWidgetQuestionMessage": {
    "you": "Tu"
  },
  "voiceWidgetQuestionSpoiler": {
    "you": "Tu"
  }
}