{
  "anonymizerSettings": {
    "anonymize_sensitive_data": "Anonimizza dati sensibili",
    "language": "Lingua",
    "language_of_the_data": "Lingua dei dati",
    "replace": "Sostituisci",
    "replace_value": "Sostituisci valore",
    "define_what_entities_must_be_anonymized": "Definisci quali entità devono essere anonimizzate",
    "anonymize_entities": "Anonimizza entità"
  }
}