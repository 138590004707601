export enum SettingsPropertiesEnum {
  ANSWER = 'answer',
  MOST_LIKELY_ENABLED = 'mostLikelyEnabled',
  MESSAGE = 'message',
  MESSAGE_IS_ACTIVE = 'messageIsActive',
  LOGO = 'logo',
  PLACEHOLDER = 'placeholder',
  COLORSCHEMES = 'colorSchemes',
  SELECTEDSCHEME = 'selectedScheme',
  HEADERFONTSIZE = 'headerFontSize',
  FONTSIZE = 'fontSize',
  FONT_FAMILY = 'fontFamily',
  BORDERRADIUS = 'borderRadius',
  INPUTHEIGHT = 'inputHeight',
  BUTTONHEIGHT = 'buttonHeight',
  MODELNAME = 'modelName',
  PROVIDERNAME = 'providerName',
  TEMPERATURE = 'temperature',
  TOP_P = 'top_p',
  HYBRID = 'hybrid',
  SUGGESTED_QUESTIONS = 'questionnaires',
  SHOW_SUGGESTED_QUESTIONS = 'showSuggestedQuestions',
  PASSWORD = 'password',
  DOMAINS = 'domains',
  FIRST_MESSAGE = 'firstMessage',
  WELCOME_MESSAGE = 'welcomeMessage',
  WELCOME_MESSAGE_IS_ACTIVE = 'welcomeMessageIsActive',
  WELCOME_MESSAGE_INTERVAL = 'welcomeMessageInterval',
  IS_AUTO_LANGUAGE = 'isAutoLanguage',
  QUESTIONS_TITLE = 'questionsTitle',
  QUESTIONS_TITLE_IS_ACTIVE = 'questionsTitleIsActive',
  SEARCH_PLACEHOLDER = 'searchPlaceholder',
  SEARCH_PLACEHOLDER_IS_ACTIVE = 'searchPlaceholderIsActive',
  SUBMIT_BUTTON_TEXT = 'submitButtonText',
  SUBMIT_BUTTON_TEXT_IS_ACTIVE = 'submitButtonTextIsActive',
  BOT_NAME = 'botName',
  BOT_NAME_IS_ACTIVE = 'botNameIsActive',
  FORM_LANGUAGE = 'formLanguage',
  LIMIT = 'limit',
  CUSTOMPROMPT = 'customPrompt',
  USECUSTOMPROMPT = 'useCustomPrompt',
  RETELLINGCONTEXT = 'retellingContext',
  SUMMARYCONTEXT = 'summaryContext',
  ROLE = 'role',
  ROLE_CUSTOM_OPTIONS = 'roleCustomOptions',
  TONE = 'tone',
  TONE_CUSTOM_OPTIONS = 'toneCustomOptions',
  FORMATTING = 'formatting',
  CUSTOM = 'custom',
  PRECONTEXT = 'precontext',
  ANSWERLENGTH = 'answerLength',
  IS_ROLE_CHECK = 'isRoleCheck',
  IS_TONE_CHECK = 'isToneCheck',
  IS_FORMAT_CHECK = 'isFormattingCheck',
  IS_CUSTOM_CHECK = 'isCustomCheck',
  IS_PRECONTEXT_CHECK = 'isPrecontextCheck',
  IS_ANSWER_LENGTH_CHECK = 'isAnswerLength',
  HIDE_POWERED_BY = 'hidePoweredBy',
  ANONYMIZER_ENABLED = 'anonymizerEnabled',
  ANONYMIZER_LANGUAGE = 'anonymizerLanguage',
  ANONYMIZER_REPLACE = 'anonymizerReplace',
  ANONYMIZER_ENTITIES = 'anonymizerEntities',
  RATE_LIMIT_ENABLED = 'rateLimitEnabled',
  RATE_LIMIT = 'rateLimit',
  LIVE_CONNECT_ENABLED = 'liveConnectEnabled',
  LIVE_CONNECT = 'liveConnect',
  LIVE_CONNECT_ACTION = 'liveConnectAction',
  OFFLINE_MESSAGE_ENABLED = 'offlineMessageEnabled',
  OFFLINE_MESSAGE = 'offlineMessage',
  OFFLINE_MESSAGE_ACTION = 'offlineMessageAction',
  USE_FOLLOWUPS = 'useFollowups',
  RESOURCES = 'resources',
  DIALOG_SAVING = 'dialogsSaving',
  DIALOG_USER_SAVING = 'dialogsUserSaving',
  HIDE_OVERLAY = 'hideOverlay',
  EXTERNAL_USERNAME_EXAMPLE = 'externalUsernameExample',
  EXTERNAL_EMAIL_EXAMPLE = 'externalEmailExample',
  SHOW_ON_MOBILE = 'showOnMobile',
  SHOW_ON_TABLET = 'showOnTablet',
  KNOWLEDGE_DIVERSITY = 'knowledgeDiversity',
  BRAND_NAME = 'brandName',
  BRAND_NAME_IS_ACTIVE = 'brandNameIsActive',
  BRAND_LOGO_IS_ACTIVE = 'brandLogoIsActive',
}
