<template>
  <div class="dropdown shrink-0">
    <Popper
      :placement="store.rtlClass === 'rtl' ? 'bottom-end' : 'bottom-start'"
      offset-distance="8"
      class="!block"
    >
      <button
        v-if="user"
        type="button"
        class="group relative flex items-center justify-center"
        :class="{ 'text-[var(--omni-color-text)]': isPortal }"
      >
        <div
          class="rounded-full border sm:mr-[12px]"
          :class="
            isPortal
              ? 'border-[var(--omni-color-secondary)]'
              : 'border-white-white100 dark:border-dark-blue100'
          "
        >
          <img
            v-fallback-image
            :src="user?.avatarUrl"
            class="h-[36px] w-[36px] rounded-full object-cover saturate-50 group-hover:saturate-100"
            alt="User avatar"
          />
        </div>
        <span class="hidden max-w-[150px] truncate text-base sm:block">
          {{ user?.displayName }}
        </span>
      </button>
      <div v-else class="h-[36px] w-[36px]">
        <loader></loader>
      </div>
      <template #content="{ close }">
        <ul
          class="w-[300px] !py-0 font-SF_Pro_Display_Bold"
          :class="
            isPortal
              ? '!border-[var(--omni-color-secondary)] !bg-[var(--omni-color-primary)] !text-[var(--omni-color-text)]'
              : 'text-dark dark:text-white-dark dark:text-white-light/90'
          "
        >
          <li :class="isPortal && '!border-[var(--omni-color-secondary)]'">
            <div class="flex items-center px-4 py-4">
              <div class="flex-none">
                <img
                  v-fallback-image
                  class="h-10 w-10 rounded-md border border-gray-300 object-cover"
                  :src="user?.avatarUrl"
                  :alt="user?.displayName"
                />
              </div>
              <div class="overflow-hidden ltr:pl-4 rtl:pr-4">
                <h4 class="truncate text-base">
                  {{ user?.displayName }}
                  <!--TODO uncomment when user will have status-->
                  <!-- <span
                    v-if="user?.status === 'Pro'"
                    class="rounded bg-success-light px-1 text-xs text-success ltr:ml-2 rtl:ml-2"
                    >Pro
                  </span> -->
                </h4>
                <a
                  class="block truncate text-black/60 hover:text-primary dark:text-dark-light/60 dark:hover:text-white"
                  :class="{
                    '!text-[var(--omni-color-text)] hover:!text-[var(--omni-color-accent)]':
                      isPortal,
                  }"
                  href="javascript:;"
                  >{{ user?.email }}</a
                >
              </div>
            </div>
          </li>
          <li
            v-for="item of commonListItems"
            :key="item.title"
            :class="isPortal && '!border-[var(--omni-color-secondary)]'"
          >
            <router-link :to="item.route" class="dark:hover:text-white" @click="close()">
              <BaseSvgIcon :name="item.icon" class="mr-2 !h-[18px] !w-[18px]" />
              {{ item.title }}
            </router-link>
          </li>
          <!-- <li></li> -->

          <li :class="isPortal && '!border-[var(--omni-color-secondary)]'">
            <SignOut></SignOut>
          </li>
        </ul>
      </template>
    </Popper>
  </div>
</template>

<script setup lang="ts">
import Loader from '@components/Loader.vue';
import BaseSvgIcon from '@components/base/BaseSvgIcon.vue';

import { computed, defineAsyncComponent } from 'vue';
import { useUserData } from '@nhost/vue';
import { useAppStore } from '@/stores';
import { useI18n } from 'vue-i18n';

const props = withDefaults(
  defineProps<{
    isPortal?: boolean;
  }>(),
  {
    isPortal: false,
  },
);
const SignOut = defineAsyncComponent(() => import('@components/auth/SignOut.vue'));

const store = useAppStore();
const user = useUserData();
const { t } = useI18n();

const commonListItems = computed(() => [
  ...(props.isPortal
    ? [
        {
          route: '/omni-portal/portal/profile',
          icon: 'profile',
          title: t('userHeader.profile'),
        },
      ]
    : [
        {
          route: '/profile',
          icon: 'profile',
          title: t('userHeader.profile'),
        },
        {
          route: {
            path: '/profile',
            query: { tab: 'billings' },
          },
          icon: 'dollar',
          title: t('userHeader.billing'),
        },
        {
          route: '/omni-portal',
          icon: 'portal',
          title: 'Omni Portal',
        },
      ]),
]);
</script>
