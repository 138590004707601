{
  "auth": {
    "changePassword": {
      "change_password": "Alterar senha",
      "enter_password": "Digite a senha",
      "new_password": "Nova senha",
      "password": "Senha",
      "old_password": "Senha antiga",
      "cancel": "Cancelar",
      "password_confirmation": "Confirmar nova senha",
      "success": "Senha alterada com sucesso"
    },
    "changePasswordForm": {
      "new_password": "Nova senha",
      "enter_password": "Digite a senha",
      "password_confirmation": "Confirmação de senha",
      "change_password": "Alterar senha"
    },
    "portalProvider": {
      "portal": "Portal",
      "portal_not_found": "Portal não encontrado",
      "is_not_currently_active": "não está atualmente ativo",
      "you_are_currently_blocked": "Você está atualmente bloqueado"
    },
    "promocode": {
      "something_went_wrong": "Algo deu errado",
      "enjoy_benefits": "Aproveite os benefícios pelo código de resgate!",
      "redemption_code": "Código de resgate",
      "enter_redemption_code": "Código de resgate",
      "verifying": "Verificando...",
      "verify": "Verificar",
      "create_account_in_omnimind": "Crie uma conta no Omnimind AI para obter créditos adquiridos",
      "this_promo_code_does_not_seem_to_exist": "Este código promocional parece não existir. Verifique se há erros de digitação e tente novamente.",
      "this_promo_code_has_expired": "Este código promocional expirou. Tente outro código."
    },
    "resetPasswordForm": {
      "reset_password": "Redefinir senha",
      "enter_your_email_for_resetting_password": "Digite seu e-mail para redefinir a senha",
      "enter_email": "Digite o e-mail",
      "please_check_your_mailbox": "Por favor, verifique sua caixa de entrada e \n siga o link de instruções para concluir a alteração da senha.",
      "if_you_can_not_find_it": "Se você não encontrar na sua caixa de entrada, verifique sua",
      "spam_folder": "pasta de spam",
      "as_well": "também."
    },
    "signOut": {
      "sign_out": "Sair"
    }
  }
}
