{
  "dataExtractorWidget": {
    "data_extractor_widget": "Extrator de Dados",
    "language": "Idioma",
    "add_prompt": "Adicionar prompt",
    "reset_table": "Redefinir tabela",
    "active": "Ativo",
    "resource": "Recurso",
    "resource_already_exists": "Recurso já existe",
    "something_went_awry": "Algo deu errado",
    "loading": "Carregando",
    "error": "Erro",
    "error_occured_while_creating_pdf": "Ocorreu um erro ao criar o PDF",
    "the_prompt_has_been_successfully_saved": "O prompt foi salvo com sucesso no Catálogo.",
    "contact_owner_to_grant_permission": "Por favor, entre em contato com o proprietário do projeto para conceder permissão para adicionar recursos."
  },
  "dataExtractorWidgetActions": {
    "scan": "Digitalizar",
    "export_pdf": "Exportar PDF",
    "export_csv": "Exportar CSV"
  },
  "dataExtractorWidgetAddPromptModal": {
    "add_edit_prompt": "Adicionar/Editar Prompt",
    "add_save": "Adicionar/Salvar",
    "add_prompt_to_catalog": "Adicionar prompt ao catálogo",
    "column": "Coluna",
    "locations": "Localizações",
    "open_prompt_catalog": "Abrir catálogo de prompts",
    "prompt": "Prompt",
    "common_locations_of_clients": "Localizações comuns dos clientes com quem fazem negócios"
  },
  "dataExtractorWidgetAddPromptSupplement": {
    "these_prompts_will_be_applied": "Esses prompts serão aplicados a cada recurso",
    "by_bullet_points": "por pontos",
    "as_a_quote": "como uma citação",
    "in_a_few_words": "em poucas palavras"
  },
  "dataExtractorWidgetAddResourceModal": {
    "add_edit_resource": "Adicionar/Editar Recurso",
    "submit": "Enviar",
    "enter_your_resource": "Digite seu recurso",
    "this_resource_is_already_exist": "Este recurso já existe!"
  },
  "dataExtractorWidgetDeleteResourceModal": {
    "delete_this_resource": "Excluir este recurso?",
    "delete_resource": "Excluir recurso",
    "resource_will_be_deleted": "O recurso será excluído da tabela.",
    "delete_resource_from_project": "Excluir recurso do projeto"
  },
  "dataExtractorWidgetPdfModal": {
    "create_pdf": "Criar PDF",
    "ok": "Ok",
    "wait_for_a_few_seconds_please": "Aguarde alguns segundos, por favor..."
  },
  "dataExtractorWidgetPromptCatalog": {
    "prompt_catalog": "Catálogo de Prompts",
    "delete_column": "Excluir coluna do catálogo",
    "delete_prompt": "Excluir prompt do catálogo"
  },
  "dataExtractorWidgetTable": {
    "no_resources_so_far": "Nenhum recurso até o momento"
  },
  "dataExtractorWidgetTableHead": {
    "resource": "Recurso",
    "refresh_values": "Atualizar valores",
    "edit_prompt": "Editar prompt",
    "delete_prompt": "Excluir prompt da tabela"
  }
}