{
  "interactiveMindmapWidgetActions": {
    "click_here_to_download": "Haz clic aquí para descargar",
    "language": "Idioma",
    "create_map": "Crear mapa"
  },
  "interactiveMindmapWidgetMap": {
    "interactive_mind_map": "Mapa Mental Interactivo",
    "an_interactive_mind_map_is_a_digital": "Un Mapa Mental Interactivo es una herramienta digital diseñada para facilitar la organización y visualización de información en una estructura jerárquica. Creados utilizando tecnologías web dinámicas, estos mapas mentales ofrecen características de interactividad como arrastrar, hacer zoom y editar nodos. Son especialmente útiles para sesiones de lluvia de ideas, planificación de proyectos y visualización de ideas o sistemas complejos. Utilizar un Mapa Mental Interactivo te permite categorizar y vincular fácilmente diferentes piezas de información, lo que facilita su comprensión, análisis y colaboración.",
    "generate_a_mind_map_in": "Generar un mapa mental en",
    "something_went_awry": "¡Algo salió mal!",
    "we_are_unable_to_create_a_mindmap": "No podemos crear un mapa mental basado en el contenido proporcionado. ¡Puedes intentar abrir la configuración del widget y cambiarla para obtener mejores resultados!",
    "translate_into": "Traducir al",
    "language_every_name_property_s_value_of_in": "idioma el valor de la propiedad \"name\" en este objeto JSON convertido a cadena",
    "don_t_change_other_values": "No cambies otros valores. Asegúrate de usar solo comillas dobles y comenzar la respuesta con",
    "give_me_a_valid_js_array_of_5_topics": "Dame una matriz JS válida de 5 temas (1-3 palabras cada uno) para crear un Mapa Mental basado en {context} en el formato \"[tema 1, tema 2, tema 3, tema 4, tema 5]\" en el idioma {languageValue}",
    "please_generate_a_mind_map": "Por favor, genera un mapa mental",
    "about": "acerca de",
    "with": "con",
    "as_a_core_node": "como un nodo principal"
  }
}