import gql from 'graphql-tag';

export const DELETE_WIDGET_BY_ID = gql`
  mutation deleteWidgetByIdMutation($id: uuid!) {
    delete_widgets_by_pk(id: $id) {
      id
      project_id
      project {
          widgets {
            id
            name
            type_id
            is_default
            settings
            project_id
          }
      }
    }
  }
`;
