import { type Ref, inject, provide, ref } from 'vue';
import type { PackageFeaturesInterface } from '@/interfaces/PackageFeaturesInterface';
import type { PackageCostsInterface } from '@/interfaces/PackageCostsInterface';

export interface AuthorizationInterface {
  package_features: Ref<PackageFeaturesInterface>;
  package_costs: Ref<PackageCostsInterface>;
  units_amount: Ref<number | null>;
  units_spent: Ref<number | null>;
  loading: Ref<boolean>;
  package_price: Ref<number | null>;
  cost: Ref<number | null>;
}

export const AuthorizationSymbol = Symbol('Authorization');

export function createAuthorization() {
  const package_features = ref(null);
  const package_costs = ref(null);
  const package_price = ref(null);
  const units_amount = ref(null);
  const units_spent = ref(null);
  const cost = ref(null);
  const loading = ref(true);

  return {
    package_features,
    package_price,
    package_costs,
    units_amount,
    units_spent,
    cost,
    loading,
  };
}

export function useAuthorization() {
  const authorization = inject<AuthorizationInterface>(AuthorizationSymbol);

  if (!authorization) {
    throw new Error(
      'No authorization provided. Ensure you have setup authorization via createAuthorization.',
    );
  }
  return authorization;
}

export function provideAuthorization(authorization: AuthorizationInterface) {
  provide(AuthorizationSymbol, authorization);
}
