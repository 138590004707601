{
  "appTranslations": {
    "subscription_limit_reached": "Límite de suscripción alcanzado",
    "upgrade_subscription": "Actualizar suscripción",
    "it_looks_like_you_ve_reached": "Parece que has alcanzado el límite de tu suscripción actual. Para seguir disfrutando de nuestros servicios, haz clic en el botón de abajo para actualizar tu suscripción.",
    "we_ve_successfully_renewed_your": "Hemos renovado tu suscripción correctamente.",
    "we_encountered_an_error_while": "Hemos encontrado un error al intentar renovar tu suscripción.",
    "run_out_of_units": "Te has quedado sin unidades disponibles. Por favor, repone tus unidades o actualiza el plan de facturación",
    "ok": "OK"
  }
}