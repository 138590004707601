{
  "pagesProjectsWidgetIdSettings": {
    "back": "Voltar",
    "your_settings_successfully_saved": "Suas configurações foram salvas com sucesso",
    "failed_to_save_settings": "Falha ao salvar as configurações",
    "failed_to_load_settings": "Falha ao carregar as configurações"
  },
  "pagesProjectsWidgetIdTeam": {
    "team": "Equipe",
    "back": "Voltar"
  },
  "pagesProjectsWidgetIdBasic": {
    "this_widget_view_is_not_ready_yet": "Esta visualização do widget ainda não está pronta.",
    "add_a_new_view": "Adicionar uma nova visualização",
    "you_dont_have_any_resources": "Você não possui nenhum recurso. Você deve adicionar um novo informante.",
    "change_widget_view": "Alterar visualização do widget"
  }
}