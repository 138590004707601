import { gql } from '@apollo/client/core';


export const GET_WIDGET_TYPES_BY_KEY = gql`
  query ($key: [String]) {
    widget_types(where: { key: { _in: $key } }) {
      name
      key
      is_active
      id
      description
      type
    }
  }
`;

