{
  "appTranslations": {
    "subscription_limit_reached": "Limite di abbonamento raggiunto",
    "upgrade_subscription": "Aggiorna abbonamento",
    "it_looks_like_you_ve_reached": "Sembra che tu abbia raggiunto il limite del tuo abbonamento attuale. Per continuare a usufruire dei nostri servizi, fai clic sul pulsante sottostante per aggiornare il tuo abbonamento.",
    "we_ve_successfully_renewed_your": "Abbiamo rinnovato con successo il tuo abbonamento.",
    "we_encountered_an_error_while": "Abbiamo riscontrato un errore durante il tentativo di rinnovo del tuo abbonamento.",
    "run_out_of_units": "Hai esaurito le unità disponibili. Per favore, ricarica le tue unità o aggiorna il piano di fatturazione",
    "ok": "OK"
  }
}