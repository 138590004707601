{
  "bulkCsvInformerInformers": {
    "select_csv_files_to_upload": "Wählen Sie CSV-Dateien zum Hochladen aus",
    "bulk_upload_via_csv": "Die Funktion zum Massenhochladen über CSV ermöglicht das Hochladen von Links, die in einer CSV-Datei aufgelistet sind.",
    "drag_drop_or": "Ziehen und Ablegen oder",
    "select_a_csv_file": "Wählen Sie eine CSV-Datei aus",
    "to_upload": "zum Hochladen",
    "download_example_csv": "Beispiel-CSV herunterladen",
    "in_column": "In Spalte",
    "we_found": "Wir haben",
    "valid_links": "gültige Links",
    "there_are_no_valid_links_in_this_column": "In dieser Spalte sind keine gültigen Links vorhanden.",
    "error_uploading_file": "Fehler beim Hochladen der Datei:"
  },
  "bulkCsvInformerMapperInformers": {
    "map_file_columns": "Spalten der Datei zuordnen",
    "specify_the_corresponding_column": "Geben Sie die entsprechende Spalte in der CSV-Datei an, die die URLs enthält. Diese Spalte sollte für die Verarbeitung ausgewählt werden. Wenn die URLs in der Spalte kein \"https://\" enthalten, wird es automatisch hinzugefügt.",
    "link": "Link"
  },
  "csvInformerInformers": {
    "select_csv_file_to_upload": "Wählen Sie eine CSV-Datei zum Hochladen aus",
    "drag_drop_or": "Ziehen und Ablegen oder",
    "select_a_csv_file": "Wählen Sie eine CSV-Datei aus",
    "to_upload": "zum Hochladen",
    "download_example_csv": "Beispiel-CSV herunterladen",
    "invalid_file_type": "Ungültiger Dateityp. Bitte laden Sie eine CSV-Datei hoch.",
    "invalid_mime_type": "Ungültiger MIME-Typ. Die Datei scheint keine CSV zu sein.",
    "error_parsing_csv": "Fehler beim Analysieren der CSV:",
    "error_uploading_file": "Fehler beim Hochladen der Datei:",
    "something_went_awry": "Etwas ist schiefgelaufen!"
  },
  "csvInformerAdvancedSettingsInformers": {
    "advanced_settings": "Erweiterte Einstellungen",
    "hide_settings": "Einstellungen ausblenden",
    "show_settings": "Einstellungen anzeigen",
    "select_title_columns": "Titelspalten auswählen",
    "using_a_direct_custom_prompt": "Die Verwendung einer direkten benutzerdefinierten Aufforderung ist nur in besonderen Fällen erforderlich und deaktiviert das Hauptformular.",
    "select_metadata_columns": "Metadatenspalten auswählen"
  },
  "csvInformerMapperInformers": {
    "map_file_columns": "Spalten der Datei zuordnen",
    "content": "Inhalt",
    "meta": "Meta"
  },
  "customiseColumnsListInformers": {
    "name": "Name",
    "label": "Beschriftung",
    "select_all": "Alle auswählen"
  },
  "previewCSVInformers": {
    "preview_csv_data": "Vorschau der CSV-Daten",
    "more_data": "Weitere Daten..."
  }
}