{
  "tenantPortalPortalSection": {
    "completed": "Completed",
    "non_completed": "Non-completed"
  },
  "tenantPortalTabs": {
    "settings": "Settings",
    "projects": "Projects",
    "packages": "Packages",
    "users": "Users",
    "transactions": "Transactions"
  }
}