{
  "billingsActivityAnalytics": {
    "title": "Análises"
  },
  "billingsActivityAnalyticsAverageChart": {
    "title": "Duração média das solicitações",
    "length": "Duração",
    "no_requests_yet": "Ainda não há solicitações"
  },
  "billingsActivityAnalyticsFilters": {
    "today": "hoje",
    "7_days": "7 dias",
    "month": "mês"
  },
  "billingsActivityAnalyticsRequestsChart": {
    "title": "Solicitações",
    "unique_visitors": "Visitantes únicos",
    "per_day": "por dia",
    "per_session": "por sessão"
  },
  "billingsActivityAnalyticsVisitors": {
    "title": "Visitantes únicos",
    "all_visitors": "Todos os visitantes",
    "no_visitors_yet": "Ainda não há visitantes"
  },
  "billingsActivityAnalyticsSuccessRate": {
    "title": "Taxa de sucesso",
    "subtitle": "Com que frequência a OmniMind pode responder perguntas"
  },
  "billingsActivityAnalyticsCosts": {
    "title": "Custos"
  }
}
