{
  "audioInformerInformers": {
    "language": "Lingua",
    "select_files_to_upload": "Seleziona i file da caricare",
    "for_advanced_users_only": "Solo per utenti avanzati!",
    "advanced_settings": "Impostazioni avanzate",
    "your_uploaded_files": "I tuoi file caricati",
    "file": "File",
    "was_added_previously": "è stato aggiunto in precedenza",
    "an_error_occurred_while": "Si è verificato un errore durante il caricamento di un file"
  },
  "baseInformerInformers": {
    "project_does_not_exist_in_data_base_with": "Il progetto non esiste nel database con"
  },
  "fileInformerInformers": {
    "select_files_to_upload": "Seleziona i file da caricare",
    "for_advanced_users_only": "Solo per utenti avanzati!",
    "advanced_settings": "Impostazioni avanzate",
    "your_uploaded_files": "I tuoi file caricati",
    "looks_like_your_file": "Sembra che il tuo file",
    "does_not_have_any_text": "non abbia alcun testo che possiamo utilizzare direttamente. Non preoccuparti! Puoi facilmente",
    "convert_it_into_a_text_filled_pdf": "convertirlo in un PDF con testo e caricarlo con PDF OCR Informer",
    "upgrade_your_plan_and": "aggiorna il tuo piano e convertilo in un PDF con testo e caricarlo con PDF OCR Informer",
    "file": "File",
    "was_added_previously": "è stato aggiunto in precedenza",
    "your": "Il tuo",
    "has_been_successfully_uploaded": "è stato caricato con successo",
    "an_error_occurred_while": "Si è verificato un errore durante il caricamento del file"
  },
  "notionInformerInformers": {
    "your": "Il tuo",
    "a_secure_integration_secret": "* Un segreto di integrazione sicuro generato dal tuo account Notion, garantendo un'integrazione senza problemi e sicura.",
    "integration_token": "Token di integrazione",
    "page_url": "URL della pagina",
    "page_should_be_shared": "* La tua pagina Notion dovrebbe essere condivisa con la tua integrazione.",
    "your_uploaded_links": "I tuoi link caricati:",
    "not_valid_url": "Il valore non è un indirizzo URL valido",
    "add": "Aggiungi"
  },
  "pdfOcrFileInformerInformers": {
    "select_files_to_upload": "Seleziona i file da caricare",
    "for_advanced_users_only": "Solo per utenti avanzati!",
    "advanced_settings": "Impostazioni avanzate",
    "your_uploaded_files": "I tuoi file caricati",
    "great_news_your_file": "Ottime notizie! Il tuo file",
    "is_a_straightforward_pdf": "è un PDF semplice e non richiede un'elaborazione OCR speciale. Procedi pure e",
    "upload_it_using_our": "caricalo usando il nostro File Informer",
    "for_a_smoother_experience": "per un'esperienza più fluida!",
    "file": "File",
    "was_added_previously": "è stato aggiunto in precedenza",
    "your": "Il tuo",
    "has_been_successfully_uploaded": "è stato caricato con successo",
    "an_error_occurred_while": "Si è verificato un errore durante il caricamento del file"
  },
  "singleWebInformerInformers": {
    "type_in_your_url_here": "Digita qui il tuo URL",
    "preview": "Anteprima",
    "page_content": "Contenuto della pagina",
    "preview_content": "Anteprima del contenuto",
    "project_does_not_exist_in_data_base_with": "Il progetto non esiste nel database con"
  },
  "uploadAdvancedSettingsInformers": {
    "embeddings_provider": "Provider di embedding",
    "you_can_specify_the": "Puoi specificare il provider di embedding che elaborerà le tue informazioni."
  },
  "webInformerInformers": {
    "paste_your_batch_of_links_here": "Incolla qui il tuo gruppo di link",
    "add_urls": "Aggiungi URL",
    "your_uploaded_links": "I tuoi link caricati:",
    "page_content": "Contenuto della pagina",
    "preview_content": "Anteprima del contenuto",
    "must_be_filled_with_a_value": "Deve essere compilato con un valore.",
    "invalid_urls": "URL non validi",
    "project_does_not_exist_in_data_base_with": "Il progetto non esiste nel database con"
  },
  "wordpressInformerInformers": {
    "import_from_url": "Importa da URL",
    "add": "Aggiungi",
    "remove": "Rimuovi",
    "couldn_t_get_retrieve_the_wordpress": "Impossibile recuperare il sito WordPress. Puoi provare a utilizzare il Single Site Informer o specificare un altro URL.",
    "go_to_website_informer": "Vai all'informazione sul sito web",
    "select_the_types_of_content": "Seleziona i tipi di contenuto che ti interessa ricevere dal sito web."
  }
}