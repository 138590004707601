{
  "mindmapLoader": {
    "something_went_awry": "Etwas ist schiefgelaufen!",
    "please_wait_while_we_generate_your": "Bitte warten Sie, während wir Ihre",
    "attempt": "Versuch"
  },
  "topicsModal": {
    "select_mind_map_topic": "Wählen Sie das Thema der Mind Map",
    "wait_while_we_generate_topics": "Bitte warten Sie, während wir Themen basierend auf Ihrem Projektkontext generieren",
    "your_custom_topic": "Ihr individuelles Thema"
  }
}