{
  "whatsAppWidget": {
    "access_token": "Zugriffstoken",
    "phone_id": "Telefon-ID",
    "save": "Speichern",
    "how_to_connect": "So verbinden Sie den Omnimind AI WhatsApp-Chatbot",
    "make_sure_your_question": "Stellen Sie sicher, dass Ihre Frage klar und prägnant ist, um die genauesten Antworten zu erhalten. Sie können das Projekt in den Aktionseinstellungen aktualisieren oder ändern, wenn Sie unterschiedliche Daten für die Analyse verwenden möchten.",
    "create_meta_business_account": "Erstellen Sie ein Meta (Facebook) Business-Konto",
    "create_new_meta_app": "Erstellen Sie eine neue Meta (Facebook) App",
    "login_to_your_account": "Melden Sie sich in Ihrem Konto an",
    "select_my_apps": "Wählen Sie \"Meine Apps\"",
    "select_create_app": "Wählen Sie \"App erstellen\"",
    "select_options": "Optionen auswählen",
    "use_case_other": "Anwendungsfall: Andere",
    "app_type_business": "App-Typ: Geschäftlich",
    "enter_app_details": "Geben Sie App-Details ein",
    "app_name": "App-Name",
    "app_contact_email": "App-Kontakt-E-Mail",
    "business_account": "Geschäftskonto (optional)",
    "add_whatsapp_to_your_meta_app": "Fügen Sie WhatsApp zu Ihrer Meta-App hinzu",
    "find_add_products_to_your_app": "Suchen Sie \"Produkte zu Ihrer App hinzufügen\"",
    "select_whatsapp_set_up": "Wählen Sie 'WhatsApp' Einrichtung",
    "quick_start": "Schnellstart",
    "select_business_account": "Wählen Sie Geschäftskonto",
    "purchase_new_business_number": "Kaufen Sie eine neue Geschäftsnummer für WhatsApp",
    "select_api_setup": "Wählen Sie 'API-Setup' und finden Sie 'Schritt 5'",
    "click_on_the_button": "Klicken Sie auf die Schaltfläche \"Telefonnummer hinzufügen\"",
    "fill_in_all_fields": "Füllen Sie alle Felder aus und bestätigen Sie Ihre Telefonnummer",
    "skip_if_already_have": "Überspringen Sie diesen Schritt, wenn Sie bereits eine Geschäftsnummer haben, die Sie für Ihren WhatsApp-Bot verwenden können",
    "configure_whatsapp_settings": "Konfigurieren Sie die WhatsApp-Einstellungen",
    "select_configure_webhooks": "Wählen Sie 'Webhooks konfigurieren' auf der 'API-Setup'-Seite",
    "edit_webhook": "Webhook bearbeiten",
    "add_a_callback_url_and_verify_token": "Fügen Sie eine Callback-URL hinzu und überprüfen Sie das Token.",
    "callback_url": "Callback-URL",
    "verify_token": "Token überprüfen",
    "manage_webhook_fields": "Webhook-Felder verwalten",
    "add_and_subscribe_only_to": "Hinzufügen und nur zu abonnieren",
    "add_a_payment_method_to_your_whatsapp": "Fügen Sie Ihrer WhatsApp-App eine Zahlungsmethode hinzu",
    "payment_settings": "Zahlungseinstellungen",
    "add_payment_method": "Zahlungsmethode hinzufügen",
    "add_phone_number_and_business_details": "Telefonnummer (und Geschäftsdaten) hinzufügen",
    "provide_necessary_info": "Geben Sie die erforderlichen Informationen ein",
    "verify_number": "Nummer überprüfen",
    "send_a_test_message": "Senden Sie eine Testnachricht",
    "select_api_setup_and_find_step_1": "Wählen Sie 'API-Setup' und finden Sie 'Schritt 1':",
    "select_the_from_number": "Wählen Sie die 'Von'-Nummer",
    "choose_the_option_to_add_and_verify_a_new_number": "Wählen Sie die Option zum Hinzufügen und Überprüfen einer neuen Nummer",
    "select_to_number": "Wählen Sie die 'An'-Nummer (fügen Sie eine persönliche Nummer für Tests hinzu)",
    "select_send_message": "Wählen Sie 'Nachricht senden'",
    "update_business_details_for_your_meta": "Aktualisieren Sie die Geschäftsdaten für Ihre Meta-App",
    "select_settings_basic": "Wählen Sie in Ihrer Meta-App 'Einstellungen > Grundlegend'",
    "fill_in_all_the_necessary_information": "Füllen Sie alle erforderlichen Informationen aus",
    "verify_business_account": "Geschäftskonto überprüfen",
    "select_settings": "Wählen Sie 'Einstellungen'",
    "select_business_account_info": "Wählen Sie 'Geschäftskonto-Info'",
    "provide_all_the_necessary_information": "Geben Sie alle erforderlichen Informationen ein",
    "select_security_centre": "Wählen Sie 'Sicherheitscenter'",
    "select_start_verification": "Wählen Sie 'Verifizierung starten'",
    "create_a_system_user": "Erstellen Sie einen Systembenutzer in Ihrem Geschäftskonto",
    "settings_users_system_users": "Einstellungen > Benutzer > Systembenutzer",
    "create_new_system_user": "Neuen Systembenutzer erstellen",
    "name_anything": "Name (beliebig)",
    "role_admin": "Rolle: Administrator",
    "select_add_assets": "Wählen Sie 'Assets hinzufügen'",
    "select_apps": "Wählen Sie 'Apps'",
    "select_the_new_app_for_whatsapp": "Wählen Sie die neue App (für WhatsApp)",
    "toggle_manage_app": "Schalten Sie 'App verwalten' ein",
    "generate_new_token_for_system_user": "Generieren Sie ein neues Token für den Systembenutzer",
    "select_app_and_generate_new_token": "Wählen Sie die App und 'Neues Token generieren'",
    "select_never_expire": "Wählen Sie 'Nie' ablaufen",
    "select_only_permissions_whatsapp_business_messaging": "Wählen Sie nur Berechtigungen: whatsapp_business_messaging",
    "select_generate_token": "Wählen Sie 'Token generieren'",
    "important_save_this_token": "Wichtig: Speichern Sie dieses Token an einem sicheren Ort, Sie werden es benötigen",
    "add_token_and_phone_id": "Fügen Sie Token und Telefon-ID am Anfang dieser Seite hinzu",
    "phone_id_this_is_from": "'Telefon-ID' - dies stammt aus Ihrer Meta-App (API-Setup, Schritt 5). Stellen Sie sicher, dass Sie die richtige 'Von'-Nummer auswählen und die Telefon-ID von hier kopieren",
    "token_this_is_from_step_8": "'Token' - dies stammt aus Schritt 8, vom Systembenutzer in Ihrem Geschäftskonto",
    "launch_your_whatsApp_integration": "Starten Sie Ihre WhatsApp-Integration",
    "set_your_meta_app_from_development": "Setzen Sie Ihre Meta-App von der Entwicklung auf Live (Öffnen Sie Ihre Meta-App von"
  }
}