{
  "interactiveMindmapWidgetActions": {
    "click_here_to_download": "Clique aqui para baixar",
    "language": "Idioma",
    "create_map": "Criar mapa"
  },
  "interactiveMindmapWidgetMap": {
    "interactive_mind_map": "Mapa Mental Interativo",
    "an_interactive_mind_map_is_a_digital": "Um Mapa Mental Interativo é uma ferramenta digital projetada para facilitar a organização e visualização de informações em uma estrutura hierárquica. Criados usando tecnologias web dinâmicas, esses mapas mentais oferecem recursos de interatividade, como arrastar, ampliar e editar nós. Eles são especialmente úteis para sessões de brainstorming, planejamento de projetos e visualização de ideias ou sistemas complexos. Usar um Mapa Mental Interativo permite categorizar e vincular facilmente várias informações, facilitando a compreensão, análise e colaboração.",
    "generate_a_mind_map_in": "Gerar um mapa mental em",
    "something_went_awry": "Algo deu errado!",
    "we_are_unable_to_create_a_mindmap": "Não é possível criar um mapa mental com base no conteúdo fornecido. Você pode tentar abrir as configurações do widget e alterá-las para obter melhores resultados!",
    "translate_into": "Traduzir para",
    "language_every_name_property_s_value_of_in": "idioma o valor da propriedade \"name\" em cada objeto JSON stringificado",
    "don_t_change_other_values": "Não altere outros valores. Certifique-se de usar apenas aspas duplas e comece a resposta com",
    "give_me_a_valid_js_array_of_5_topics": "Dê-me um array JS válido com 5 tópicos (1-3 palavras cada) para criar um Mapa Mental com base em {context} no formato \"[tópico 1, tópico 2, tópico 3, tópico 4, tópico 5]\" no idioma {languageValue}",
    "please_generate_a_mind_map": "Por favor, gere um mapa mental",
    "about": "sobre",
    "with": "com",
    "as_a_core_node": "como um nó central"
  }
}