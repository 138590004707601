{
  "cancelChain": {
    "cancel": "Скасувати",
    "yes": "Так",
    "no": "Ні",
    "stop_processing": "Зупинити обробку",
    "do_you_want_to_stop": "Ви хочете зупинити всі ланцюжки обробки?"
  },
  "progressIndex": {
    "no_resources_so_far_redirecting_to_informers": "До цього часу немає ресурсів. Перенаправлення на інформери...",
    "an_error_occured_while_deleting": "Під час видалення сталася помилка. Будь ласка, спробуйте ще раз"
  }
}
