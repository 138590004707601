import { gql } from '@apollo/client/core';

export const ProjectFields = gql`
  fragment ProjectFields on projects {
    id
    name
    type
    description
    created_at
    user_to_projects {
      id
      scopes
      user_id
      is_invited
      favorite
      favorite_time
      user {
        email
      }
    }
    widgets {
      id
      name
      type_id
      is_default
      settings
      project_id
    }
    projects_agents {
      id
      agents_tools {
        id
        name
        description
        config
        slug
      }
    }
    is_public
  }
`;
