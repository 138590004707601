{
  "apiKeys": {
    "api_keys": "Claves de API",
    "please_generate_your_api_key": "Por favor, genera tu clave de API si deseas utilizar nuestra API de Backend",
    "api_keys_not_found": "Claves de API no encontradas.",
    "let_s_generate_it": "¡Generémosla!",
    "generate_api_key": "Generar clave de API",
    "your_secret_api_keys_are_listed_below": "Tus claves de API secretas se enumeran a continuación. No compartas tu clave de API con otros ni la expongas en el navegador u otro código del lado del cliente.",
    "name": "Nombre",
    "key": "Clave",
    "created": "Creada",
    "expires": "Expira",
    "last_used": "Último uso",
    "actions": "Acciones",
    "name_is_not_provided": "No se proporciona el nombre",
    "api_key_was_update": "¡La clave de API se actualizó!",
    "you_must_generate_a_new_api_key": "Debes generar una nueva clave de API para revocar tu única clave restante.",
    "api_key_was_revoked": "¡La clave de API fue revocada!"
  },
  "createApiKeyModal": {
    "create_new_secret_key": "Crear nueva clave secreta",
    "name_optional": "Nombre (opcional)",
    "my_test_key": "Mi Clave de Prueba",
    "create_secret_key": "Crear clave secreta"
  },
  "createdNewKeyModal": {
    "created_new_secret_key": "Se creó una nueva clave secreta",
    "please_save_this_secret_key": "Por favor, guarda esta clave secreta en un lugar seguro y accesible. Por razones de seguridad, no podrás verla nuevamente a través de tu cuenta. Si pierdes esta clave secreta, deberás generar una nueva.",
    "api_key_copied": "¡Clave de API copiada!"
  },
  "presentApiKeyModal": {
    "your_secret_key": "Tu clave secreta",
    "secret_key": "Clave secreta",
    "api_key_copied": "¡Clave de API copiada!"
  },
  "renameApiKeyModal": {
    "edit_secret_key": "Editar clave secreta",
    "save": "Guardar",
    "edit_name": "Editar nombre",
    "my_test_key": "Mi Clave de Prueba"
  },
  "revokeKeyModal": {
    "revoke_secret_key": "Revocar clave secreta",
    "this_api_key_will_immediately_be_disabled": "Esta clave de API se desactivará de inmediato. Las solicitudes de API realizadas con esta clave serán rechazadas, lo que podría provocar que los sistemas que aún dependen de ella se rompan. Una vez revocada, ya no podrás ver ni modificar esta clave de API.",
    "revoke_key": "Revocar clave"
  }
}