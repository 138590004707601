{
  "vimeoInformerInformers": {
    "you_can_provide_your_own_vimeo_token": "Ви можете надати свій власний токен Vimeo у налаштуваннях облікових даних служби.",
    "click_here": "Натисніть тут!",
    "please_provide_a_valid_vimeo_video_url": "Будь ласка, надайте дійсну URL-адресу відео Vimeo",
    "this_video_has_no_text_tracks": "Це відео не має текстових доріжок!",
    "something_went_awry": "Щось пішло не так!"
  },
  "vimeoInformerFormInformers": {
    "insert_vimeo_video_url": "Вставте URL-адресу відео Vimeo",
    "fetch_subtitles": "Отримати субтитри"
  },
  "vimeoInformerListInformers": {
    "available_tracks": "Доступні доріжки"
  }
}