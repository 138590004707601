import { defineStore } from 'pinia';
import { StoreId } from '@/stores/StoreId';
import { type InformerTypeKeysEnum } from '@/types/InformerTypeKeysEnum';
import { getWidgetTypes } from '@/api/widgets/getWidgetTypes';
import { type WidgetTypeEntityInterface } from '@/types/WidgetTypesEntityInterfaces';
import { ref } from 'vue';

export const useWidgetsStore = defineStore(StoreId.Widgets, {
  state: (): {
    widgetTypesCollection: WidgetTypeEntityInterface[];
    widgetState: any;
  } => ({
    widgetTypesCollection: [],
    widgetState: {},
  }),

  getters: {
    widgetTypeById: (state) => (id: string) => state.widgetTypesCollection.find((x) => x.id === id),
    widgetTypes: (state) => () => state.widgetTypesCollection,
    getInformerTypeId:
      (state) =>
      (key: InformerTypeKeysEnum | '', type: string = '') => {
        const result = state.widgetTypesCollection.find(
          (x) => x.key === key && (type ? x.type === type : true),
        );
        return { informerId: ref(result?.id) };
      },
    getWidgetTypeIdByKey: (state) => (key: string) => {
      const result = state.widgetTypesCollection.find((x) => x.key === key);
      return result?.id;
    },
    getWidgetTypeByKeyAndType:
      (state) =>
      (key: string, type: string | null = null) => {
        return type
          ? state.widgetTypesCollection.find((x) => x.key === key && x.type === type)
          : state.widgetTypesCollection.find((x) => x.key === key);
      },
    getWidgetTypeIdById: (state) => (id: string) => {
      return state.widgetTypesCollection.find((x) => x.id === id);
    },
    getWidgetStatedById: (state) => (widgetId: string) => {
      return state.widgetState[widgetId];
    },
  },

  actions: {
    async fetchWidgetTypes(force = false) {
      if (this.widgetTypesCollection.length === 0 || force) {
        const data = await getWidgetTypes();
        if (data?.widget_types) {
          this.widgetTypesCollection = data.widget_types;
        }
      }
    },
    setWidgetState({ widgetId, state }: { widgetId: string; state: any }) {
      this.widgetState[widgetId] = state;
    },
  },
});
