{
  "knowledgeFilterSourcesModal": {
    "filter": "Filtro",
    "apply": "Applica",
    "cancel": "Annulla",
    "by_title": "Per titolo",
    "by_period": "Per periodo",
    "search_for_resource_title": "Cerca per titolo della risorsa..."
  },
  "knowledgeSources": {
    "no_data_so_far": "Nessun dato finora.",
    "type": "Tipo",
    "title": "Titolo",
    "date": "Data",
    "actions": "Azioni",
    "delete_these_items": "Eliminare questi elementi?",
    "are_you_sure_you_want_to_delete_selected_items": "Sei sicuro di voler eliminare gli elementi selezionati?",
    "reupload_these_items": "Ricaricare questi elementi?",
    "this_action_cannot_be_undone": "Questa azione non può essere annullata.",
    "are_you_sure_you_want_to_delete_and_reupload": "Sei sicuro di voler eliminare e ricaricare gli elementi selezionati?",
    "reupload": "Ricarica",
    "the_resources_were_removed": "Le risorse sono state rimosse.",
    "an_error_occurred_while_deleting_resources": "Si è verificato un errore durante l'eliminazione delle risorse",
    "sorry_we_re_unable_to_open_this_resource": "Spiacenti, non siamo in grado di aprire direttamente questa risorsa in quanto non è una risorsa di conoscenza testuale.",
    "show_progress": "Clicca qui per vedere il progresso"
  },
  "knowledgeSourcesActions": {
    "view": "Visualizza",
    "refresh_from_source_coming_soon": "Aggiorna da origine (Disponibile a breve!)",
    "delete": "Elimina"
  },
  "knowledgeSourcesToolbar": {
    "resources_selected": "Risorse selezionate:"
  },
  "knowledgeToolbarSourcesFilters": {
    "by_date": "Per data"
  },
  "knowledgeToolbarSourcesUpdater": {
    "refresh_from_source_available_soon": "Aggiorna da origine (Disponibile a breve!)"
  }
}