{
  "googleSheetsAddOn": {
    "how_to_add_omnimind": "Як додати додаток Omnimind AI для Google Sheets",
    "please_ensure_that_the_project": "Переконайтеся, що проект встановлено як «публічний». Для цього натисніть кнопку «Опублікувати», щоб зробити проект доступним для загального користування.",
    "copy_your_api_key": "Скопіюйте свій API-ключ",
    "go_to_the_google_sheet_document": "Перейдіть до документа Google Sheet, в якому ви хочете використовувати додаток.",
    "press_the_extensions_menu_button": "Натисніть кнопку меню Розширення та виберіть Додатки та Отримати додатки",
    "find_the_omnimind_add_on": "Знайдіть додаток OmniMind та встановіть його",
    "after_installing_the_add_on": "Після встановлення додатку перейдіть до меню Розширення, знайдіть пункт меню OmniMind та натисніть Запуск",
    "you_will_be_prompted_to": "Вам буде запропоновано авторизуватися в Omnimind. Будь ласка, авторизуйтеся, вас буде перенаправлено назад до документа Google.",
    "launch_the_add_on_again": "Запустіть додаток знову. Ви отримаєте бічне вікно, де ви зможете натиснути Отримати проекти та вказати, які дані проекту ви хочете використовувати в документі.",
    "paste_your_api_key_in_the_provided_field": "Вставте свій API-ключ у відповідне поле та натисніть Отримати проекти.",
    "select_your_project": "Виберіть свій проект",
    "a_list_of_your_projects_in_omnimind": "З'явиться список ваших проектів в Omnimind.",
    "select_the_project_you_would_like_to": "Виберіть проект, який ви хочете використовувати для аналізу даних.",
    "use_the_omni_ask_function": "Використовуйте функцію OMNI_ASK",
    "click_on_a_cell_where_you": "Клацніть на комірці, де ви хочете, щоб з'явилася відповідь.",
    "enter_the_function": "Введіть функцію",
    "your_question_here": "Ваше питання тут",
    "replace": "Замінити",
    "with_the_actual_question_you": "з фактичним питанням, яке ви хочете задати.",
    "press_enter": "Натисніть Enter",
    "getting_answers": "Отримання відповідей",
    "after_pressing_enter": "Після натискання клавіші Enter додаток автоматично шукатиме відповідь на основі ресурсів, завантажених у ваш обраний проект на Omnimind.",
    "the_answer_will_then_appear": "Відповідь з'явиться в комірці, де ви ввели функцію.",
    "tips": "Поради",
    "make_sure_your_question": "Переконайтеся, що ваше питання чітке і лаконічне, щоб отримати найточніші відповіді. Ви можете задавати кілька питань одночасно, використовуючи функцію OMNI_ASK у різних комірках. Якщо ви хочете використовувати різні дані для аналізу, ви можете оновити або змінити проект у налаштуваннях додатка.",
    "support": "Підтримка",
    "if_you_encounter_any": "Якщо у вас виникли проблеми або у вас є додаткові питання, відвідайте нашу сторінку підтримки за адресою"
  }
}