{
  "useWebPreview": {
    "do_you_see_relevant_content_from_url": "Você vê conteúdo relevante a partir do URL? Se não, tente uma forma alternativa de buscar.",
    "could_not_get_the_preview": "Não foi possível obter a pré-visualização. Por favor, tente outro método.",
    "alternative_fetch": "Busca alternativa",
    "we_tried_to_fetch_the_content_as_it_would_be": "Tentamos buscar o conteúdo como seria no navegador Chrome. Se ainda não estiver satisfeito, tente clicar em \"Texto alternativo\".",
    "alternative_text": "Texto alternativo",
    "we_have_demonstrated_all_the_ways": "Demonstramos todas as formas possíveis de buscar o conteúdo. Você deseja voltar ao método original e pré-visualizá-lo?",
    "back_to_origin": "Voltar à origem"
  },
  "useMaximumNumberOfProjectsAlert": {
    "limit_error": "Parece que você atingiu o número máximo de projetos. Para iniciar um novo projeto, considere arquivar ou excluir os existentes que você não precisa mais, ou explore nossos planos de assinatura para limites mais altos.",
    "upgrade": "Atualizar"
  },
  "Preview": "Pré-visualização"
}