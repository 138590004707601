{
  "portalPreview": {
    "example": "Приклад",
    "suggestedQuestions": "Рекомендовані питання",
    "who_s_on_duty_today": "Хто сьогодні на черзі?",
    "send": "Надіслати"
  },
  "tenantPortalPortalUi": {
    "portal_styles": "Стилі порталу",
    "background_color": "Колір фону",
    "primary_color": "Основний колір",
    "secondary_color": "Другорядний/обводка колір",
    "accent_color": "Акцентний колір",
    "text_color": "Колір тексту",
    "portal_preview": "Попередній перегляд порталу",
    "apply": "Застосувати",
    "field_is_not_a_valid_hex_color": "Поле не є дійсним шістнадцятковим кольором",
    "failed_to_upload_branding_logo": "Не вдалося завантажити логотип бренду",
    "portal_ui_successfully_saved": "Інтерфейс порталу успішно збережено"
  }
}