{
  "tenantPortalPortalSection": {
    "completed": "Completato",
    "non_completed": "Non completato"
  },
  "tenantPortalTabs": {
    "settings": "Impostazioni",
    "projects": "Progetti",
    "packages": "Pacchetti",
    "users": "Utenti",
    "transactions": "Transazioni"
  }
}