{
  "infoMessageOne": {
    "here_you_can_specify_the_depth_of_the_web_site_scraping": "Here you can specify the depth of the web site scraping",
    "not_deeper_than_the_specified_url": "not deeper than the specified URL",
    "the_scraper_goes_one_level_deeper": "the scraper goes one level deeper but doesn’t follow the links on the second level",
    "the_scraper_goes_two_levels_deeper": "the scraper goes two levels deeper but doesn’t follow the links on the third level",
    "the_scraper_goes_three_levels_deeper": "the scraper goes three levels deeper but doesn’t follow the links on the fourth level"
  },
  "webInformerAdvancedSettingItems": {
    "depth": "Depth:",
    "page_limit": "Page limit:",
    "enter_page_limit": "Enter page limit",
    "page_filter": "Page filter:",
    "glob_mask": "glob mask",
    "define_the_update_period": "Define the update period",
    "if_you_are_not_sure_how_many_links": "If you are not sure how many links are on the site you can define the limit of scraped pages.",
    "here_you_can_specify_the_regularity": "Here you can specify the regularity of how often the specified link will be scraped again to refresh the information.",
    "you_can_lock_scraper_to_parse": "You can lock scraper to parse pages with a certain URL pattern according to",
    "hourly": "hourly",
    "daily": "daily",
    "weekly": "weekly",
    "monthly": "monthly"
  },
  "webInformerBtnAdvancedSetting": {
    "advanced_link_setting": "Advanced link setting"
  },
  "webList": {
    "preview": "Preview"
  }
}