import { WidgetTypeKeysEnum } from '@/types/WidgetTypeKeysEnum';
import { searchWidgetSettings } from '@components/project/settings/defaults/searchWidgetSettings';
import { chatWidgetSettings } from '@components/project/settings/defaults/chatWidgetSettings';
import { voiceWidgetSettings } from '@components/project/settings/defaults/voiceWidgetSettings';
import { explainWidgetSettings } from '@components/project/settings/defaults/explainWidgetSettings';
import { docSearchWidgetSettings } from '@components/project/settings/defaults/docSearchWidgetSettings';
import { popupChatWidgetSettings } from '@components/project/settings/defaults/popupChatWidgetSettings';
import { retellingWidgetSettings } from '@components/project/settings/defaults/retellingWidgetSettings';
import { summaryWidgetSettings } from '@components/project/settings/defaults/summaryWidgetSettings';
import { mindmapWidgetSettings } from '@components/project/settings/defaults/mindmapWidgetSettings';
import { dataExtractorWidgetSettings } from '@/components/project/settings/defaults/dataExtractorWidgetSettings';
import { quizWidgetSettings } from '@/components/project/settings/defaults/quizWidgetSettings';
import { flashcardsWidgetSettings } from '@/components/project/settings/defaults/flashcardsWidgetSettings';

const settingsMap: Record<
  string,
  | typeof searchWidgetSettings
  | typeof explainWidgetSettings
  | typeof retellingWidgetSettings
  | typeof summaryWidgetSettings
  | typeof popupChatWidgetSettings
  | typeof mindmapWidgetSettings
  | typeof dataExtractorWidgetSettings
  | typeof quizWidgetSettings
> = {
  [WidgetTypeKeysEnum.SearchWidget]: searchWidgetSettings,
  [WidgetTypeKeysEnum.ExplainWidget]: explainWidgetSettings,
  [WidgetTypeKeysEnum.ChatWidget]: chatWidgetSettings,
  [WidgetTypeKeysEnum.VoiceWidget]: voiceWidgetSettings,
  [WidgetTypeKeysEnum.DocSearchWidget]: docSearchWidgetSettings,
  [WidgetTypeKeysEnum.PopupChatWidget]: popupChatWidgetSettings,
  [WidgetTypeKeysEnum.RetellingWidget]: retellingWidgetSettings,
  [WidgetTypeKeysEnum.SummaryWidget]: summaryWidgetSettings,
  [WidgetTypeKeysEnum.InteractiveMindmapWidget]: mindmapWidgetSettings,
  [WidgetTypeKeysEnum.MermaidMindmapWidget]: mindmapWidgetSettings,
  [WidgetTypeKeysEnum.DataExtractorWidget]: dataExtractorWidgetSettings,
  [WidgetTypeKeysEnum.QuizWidget]: quizWidgetSettings,
  [WidgetTypeKeysEnum.FlashcardsWidget]: flashcardsWidgetSettings,
};

export const getSettingsByWidgetTypeKey = (key: any) => {
  return settingsMap[key];
};
