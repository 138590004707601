{
  "knowledge": {
    "sources": "Quellen",
    "questions_and_answers": "Fragen und Antworten"
  },
  "knowledgeHeader": {
    "knowledge": "Wissen",
    "search_for_uploaded_knowledge": "Nach hochgeladenem Wissen suchen..."
  },
  "knowledgeTable": {
    "name": "Name",
    "title": "Titel",
    "size": "Größe",
    "date": "Datum",
    "actions": "Aktionen"
  }
}