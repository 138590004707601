{
  "addNewPortalModal": {
    "add_new_portal": "¿Agregar nuevo portal?",
    "add_portal": "Agregar Portal",
    "the_portal_was_successfully_created": "El portal se creó correctamente.",
    "oops_something_went_wrong": "¡Ups! Algo salió mal."
  },
  "deletePortalModal": {
    "delete_portal": "Eliminar portal",
    "confirm": "Confirmar",
    "you_are_about_to_delete_the_portal": "Estás a punto de eliminar el portal. Esto no se puede deshacer. ¿Estás seguro de que quieres continuar?"
  },
  "portalSettingsDomain": {
    "domain": "Dominio",
    "your_domain_copied_to_clipboard": "Tu dominio se copió al portapapeles",
    "example": "ejemplo",
    "subdomain": "Subdominio",
    "custom_domain": "Dominio personalizado",
    "this_domain_is_already_taken": "Este dominio ya está en uso"
  },
  "portalSettingsGeneral": {
    "general": "General"
  },
  "portalSettingsGeneralForm": {
    "name": "Nombre",
    "example_name": "Nombre de ejemplo",
    "brief_info": "Información breve",
    "example_brief_info": "Información breve de ejemplo"
  },
  "portalSettingsPayments": {
    "payments_processing": "Procesamiento de pagos",
    "we_use_stripe_payment_platform": "Utilizamos la plataforma de pago Stripe para procesar los pagos entre usted y sus clientes. Debe crear su perfil y conectar un método de pago",
    "connect": "Conectar",
    "configure": "Configurar",
    "account": "cuenta"
  },
  "portalSettings": {
    "manage": "Administrar",
    "suspend_portal": "Suspender portal",
    "reactivate_portal": "Reactivar portal",
    "delete_portal": "Eliminar portal",
    "apply": "Aplicar",
    "portal_settings_successfully_updated": "La configuración del portal se actualizó correctamente.",
    "oops_this_domain_is_already_taken": "¡Ups! Este dominio ya está en uso.",
    "please_fill_in_all_required_fields": "Por favor complete todos los campos requeridos."
  },
  "suspendPortalModal": {
    "suspend_portal": "Suspender portal",
    "confirm": "Confirmar",
    "you_are_about_to_suspend_the_portal": "Estás a punto de suspender el portal. El portal no estará disponible para tus usuarios hasta que lo actives nuevamente. ¿Estás seguro de que quieres continuar?"
  }
}