{
  "settingsCustomize": {
    "bot_name": "Ім'я бота",
    "enter_custom_bot_name": "Введіть власне ім'я бота",
    "welcome_messages": "Повідомлення привітання",
    "enter_messages_separated_by_double_enter": "Введіть повідомлення, розділені подвійним Enter.",
    "type_messages_double_enter_to_separate": "Введіть повідомлення, подвійний Enter для розділення.",
    "add_a_custom_welcome_message_interval_seconds": "Додайте власний інтервал повідомлення привітання (секунди)",
    "enter_welcome_message_interval": "Введіть інтервал повідомлення привітання...",
    "show_followups_questions": "Показати питання про продовження",
    "add_a_custom_message_ending_to_every_omni_mind_response": "Додайте власне закінчення повідомлення до кожної відповіді OmniMind",
    "enter_custom_message": "Введіть власне повідомлення...",
    "label_for_examples_of_questions": "Мітка для прикладів питань, на які ви можете запитати бота",
    "before_you_start_to_work_with_widget": "Перед тим як почати працювати з віджетом, ви побачите приклади питань, на які ви можете запитати бота",
    "enter_label_for_examples": "Введіть мітку для прикладів питань, на які ви можете запитати бота...",
    "change_placeholder_text_of_the_form_s_input": "Змінити текст заповнювача введення форми",
    "enter_custom_placeholder_text": "Введіть власний текст заповнювача...",
    "submit_button_s_text": "Текст кнопки відправки",
    "enter_text_for_submit_button": "Введіть текст для кнопки відправки...",
    "opening_message": "Початкове повідомлення",
    "enter_custom_opening_message": "Введіть власне початкове повідомлення",
    "change_the_widget_color_style": "Змінити стиль кольору віджета",
    "show_examples_of_questions_that_you_can_ask_the_bot_about": "Показати приклади питань, на які ви можете запитати бота",
    "default_language": "Мова за замовчуванням",
    "browser_language_auto_detect": "Автоматичне визначення мови браузера",
    "custom_prompt": "Власний підказка",
    "using_a_direct_custom_prompt_is_only_necessary": "Використання прямої власної підказки необхідне лише в особливих випадках, і це вимкне основну форму.",
    "change_the_header_font_size": "Змінити розмір шрифту заголовка",
    "change_the_body_font_size": "Змінити розмір шрифту тексту",
    "hide_powered_by_label": "Приховати напис 'Powered by'",
    "show_answer_sources_option": "Показати опцію джерел відповідей",
    "failed_to_upload_branding_logo": "Не вдалося завантажити логотип бренду"
  },
  "customizeColorInput": {
    "primary": "Основний",
    "background": "Фон",
    "secondary": "Другорядний",
    "stroke": "Контур",
    "accent": "Акцент",
    "text": "Текст",
    "body": "Тіло",
    "default_color_scheme": "Схема кольорів за замовчуванням"
  },
  "customizeColorModal": {
    "create_the_custom_widget_color_style": "Створити власний стиль кольору віджета",
    "save": "Зберегти",
    "the_custom_widget_color_style": "{value} власний стиль кольору віджета",
    "edit": "Редагувати",
    "create": "Створити",
    "primary": "Основний",
    "background_color": "Колір фону",
    "secondary": "Другорядний",
    "stroke_color": "Колір контуру",
    "text_color": "Колір тексту",
    "accent_color": "Колір акценту",
    "body_background": "Фон тіла",
    "leave_empty_for_transparent_body": "Залиште порожнім для прозорого тіла",
    "current_color_style_examples_click_to_clone": "Поточні приклади стилю кольору, клікніть, щоб клонувати",
    "field_is_not_a_valid_hex_color": "Поле не є дійсним шістнадцятковим кольором"
  },
  "customizeColorSchemes": {
    "changes_to_the_color_theme_of_your_widget": "Зміни кольорової теми вашого віджета будуть відображені в режимах \"Поділитися віджетом\" та \"Вставити код\".",
    "you_have_reached_the_maximum_allowed_limit": "Ви досягли максимально допустимого обмеження. Будь ласка, видаліть деякі схеми перед створенням нових.",
    "click_here_to_create_a_new_scheme": "Натисніть тут, щоб створити нову схему."
  },
  "customizeFileUploader": {
    "upload_custom_avatar_or_logo": "Завантажте логотип бренду",
    "drag_drop_or": "Перетягніть і відпустіть або",
    "select_files": "Виберіть файли",
    "to_upload": "для завантаження",
    "error_uploading_file": "Помилка завантаження файлу"
  },
  "customizeFileUploaderImage": {
    "no_logo": "Немає логотипу"
  },
  "customizeQuestionnaires": {
    "add_custom_questions": "Додати власні питання",
    "enter_custom_message": "Введіть власне повідомлення...",
    "regenerate": "Оновити питання"
  }
}