{
  "sidebar": {
    "it_looks_like_you_are": "Parece que você atingiu o número máximo de projetos. Para iniciar um novo projeto, considere arquivar ou excluir os existentes que você não precisa mais, ou explore nossos planos de assinatura para limites mais altos.",
    "new_project": "Novo projeto",
    "select_widget": "Selecionar widget"
  },
  "sidebarHeader": {
    "new_project": "Novo projeto"
  },
  "sidebarLink": {
    "project_settings": "Configurações do projeto",
    "project_has_been_successfully": "O projeto foi {isFav} favoritado com sucesso!",
    "we_encountered_an_issue": "Encontramos um problema ao tentar atualizar seus favoritos. Por favor, tente novamente."
  },
  "sidebarNoProjects": {
    "there_are_no_projects": "Não há projetos aqui ainda"
  },
  "sidebarProjectsListFooter": {
    "create_project": "Criar projeto",
    "create_a_project_using": "criar um projeto usando",
    "bot_advice": "conselho do bot",
    "enter_redemption_code": "Código de resgate",
    "applying": "Aplicando...",
    "apply": "Aplicar",
    "oops_you_ve_reached_your_project_limit": "Oops! Você atingiu o limite de projetos para criar novos projetos. Para criar mais, considere fazer upgrade do seu pacote!"
  },
  "sidebarProjectTree": {
    "knowledge": "Conhecimento",
    "add_a_new_view": "Adicionar uma nova visualização",
    "dialogs": "Diálogos",
    "conversations": "Conversas",
    "team": "Equipe",
    "project": "Projeto"
  },
  "sidebarPromocode": {
    "enter_redemption_code": "Código de resgate",
    "exists_promo": "Este código promocional já existe",
    "apply": "Aplicar"
  },
  "sidebarProjectTreeSettingsItem": {
    "settings": "Configurações",
    "ai_model": "Modelo de IA",
    "behavior": "Comportamento",
    "appearance": "Aparência",
    "integrations": "Integrações",
    "database": "Banco de dados"
  },
  "sidebarSharedProjectsListItem": {
    "something_went_awry": "Algo deu errado!"
  },
  "sidebarEmailConfirmWarning": {
    "resend": "Reenviar",
    "sending": "Enviando",
    "please_check_your_inbox": "Por favor, verifique sua caixa de entrada",
    "to_verify_your_email": "para verificar seu e-mail.",
    "verification_email_is_sent": "E-mail de verificação foi enviado.",
    "something_went_wrong": "Algo deu errado"
  }
}