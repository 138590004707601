{
  "cardEditorFooter": {
    "cancel": "Скасувати",
    "save": "Зберегти"
  },
  "cardFillBlank": {
    "correct_answer": "Правильна відповідь",
    "explanation": "Пояснення"
  },
  "cardFillBlankEditor": {
    "question": "Питання",
    "enter_question": "Введіть питання",
    "correct_answer": "Правильна відповідь",
    "enter_correct_answer": "Введіть правильну відповідь",
    "explanation_optional": "Пояснення (необов'язково)",
    "enter_explanation_for_the_correct_answer": "Введіть пояснення для правильної відповіді",
    "instructions_optional": "Інструкції (необов'язково)",
    "enter_instructions_for_answering_the_question": "Введіть інструкції для відповіді на питання"
  },
  "cardMatching": {
    "correct_answer": "Правильна відповідь",
    "explanation": "Пояснення"
  },
  "cardMatchingEditor": {
    "question": "Питання",
    "enter_question": "Введіть питання",
    "answers": "Відповіді",
    "list_the_items_in_the_correct_sequence": "Впорядкуйте елементи в правильній послідовності. Вони будуть випадковим чином перемішані для активності.",
    "enter_answer_option": "Введіть варіант відповіді",
    "add_more": "Додати ще",
    "explanation_optional": "Пояснення (необов'язково)",
    "enter_explanation_for_the_correct_answer": "Введіть пояснення для правильної відповіді",
    "instructions_optional": "Інструкції (необов'язково)",
    "enter_instructions_for_answering_the_question": "Введіть інструкції для відповіді на питання"
  },
  "cardMultipleAnswers": {
    "correct_answers": "Правильні відповіді",
    "explanation": "Пояснення"
  },
  "cardMultipleAnswersEditor": {
    "question": "Питання",
    "enter_question": "Введіть питання",
    "answer": "Відповідь",
    "enter_answer_option": "Введіть варіант відповіді",
    "add_more": "Додати ще",
    "correct_answers": "Правильні відповіді",
    "explanation_optional": "Пояснення (необов'язково)",
    "enter_explanation_for_the_correct_answer": "Введіть пояснення для правильної відповіді",
    "instructions_optional": "Інструкції (необов'язково)",
    "enter_instructions_for_answering_the_question": "Введіть інструкції для відповіді на питання",
    "only_unique_answer_values_allowed": "Дозволені лише унікальні значення відповідей!"
  },
  "cardMultipleChoice": {
    "correct_answer": "Правильна відповідь",
    "explanation": "Пояснення"
  },
  "cardMultipleChoiceEditor": {
    "question": "Питання",
    "enter_question": "Введіть питання",
    "answer": "Відповідь",
    "enter_answer_option": "Введіть варіант відповіді",
    "add_more": "Додати ще",
    "correct_answer": "Правильна відповідь",
    "enter_correct_answer": "Введіть правильну відповідь",
    "explanation_optional": "Пояснення (необов'язково)",
    "enter_explanation_for_the_correct_answer": "Введіть пояснення для правильної відповіді",
    "instructions_optional": "Інструкції (необов'язково)",
    "enter_instructions_for_answering_the_question": "Введіть інструкції для відповіді на питання",
    "only_unique_answer_values_allowed": "Дозволені лише унікальні значення відповідей!"
  },
  "cardSequence": {
    "correct_sequence": "Правильна послідовність",
    "explanation": "Пояснення"
  },
  "cardSequenceEditor": {
    "question": "Питання",
    "enter_question": "Введіть питання",
    "correct_answers": "Правильні відповіді",
    "list_the_items_in_the_correct_sequence": "Впорядкуйте елементи в правильній послідовності. Вони будуть випадковим чином перемішані для активності.",
    "enter_answer_option": "Введіть варіант відповіді",
    "add_more": "Додати ще",
    "explanation_optional": "Пояснення (необов'язково)",
    "enter_explanation_for_the_correct_answer": "Введіть пояснення для правильної відповіді",
    "instructions_optional": "Інструкції (необов'язково)",
    "enter_instructions_for_answering_the_question": "Введіть інструкції для відповіді на питання",
    "empty_values_are_not_allowed": "Порожні значення не дозволені!"
  },
  "cardShortAnswer": {
    "correct_answer": "Правильна відповідь",
    "explanation": "Пояснення"
  },
  "cardShortAnswerEditor": {
    "question": "Питання",
    "enter_question": "Введіть питання",
    "correct_answer": "Правильна відповідь",
    "enter_correct_answer": "Введіть правильну відповідь",
    "explanation_optional": "Пояснення (необов'язково)",
    "enter_explanation_for_the_correct_answer": "Введіть пояснення для правильної відповіді",
    "instructions_optional": "Інструкції (необов'язково)",
    "enter_instructions_for_answering_the_question": "Введіть інструкції для відповіді на питання"
  },
  "cardTrueFalse": {
    "true": "Правда",
    "false": "Неправда",
    "correct_answer": "Правильна відповідь",
    "explanation": "Пояснення"
  },
  "cardTrueFalseEditor": {
    "question": "Питання",
    "enter_question": "Введіть питання",
    "correct_answer": "Правильна відповідь",
    "explanation_optional": "Пояснення (необов'язково)",
    "enter_explanation_for_the_correct_answer": "Введіть пояснення для правильної відповіді",
    "instructions_optional": "Інструкції (необов'язково)",
    "enter_instructions_for_answering_the_question": "Введіть інструкції для відповіді на питання",
    "true": "Правда",
    "false": "Неправда"
  }
}