{
  "voiceMessageSpoiler": {
    "hide_transcript": "Ocultar transcripción",
    "view_transcript": "Ver transcripción"
  },
  "voiceStartMessage": {
    "listening_to_you": "Escuchándote...",
    "welcome_to_voice_talk": "Bienvenido a Voice Talk",
    "widget": "¡Widget!",
    "lets_speak": "Hablemos",
    "press_stop_button": "Presiona el botón de parar abajo si quieres detener la grabación",
    "press_button_below_with_microphone": "Presiona el botón de abajo con el micrófono para",
    "start_talking": "comenzar a hablar"
  },
  "voiceWidgetAnswerMessage": {
    "copy_to_clipboard": "Copiar al portapapeles"
  },
  "voiceWidgetFooter": {
    "hold_and_ask_your_question": "Sostén y haz tu pregunta",
    "press_to_stop_talking": "Presiona para dejar de hablar"
  },
  "voiceWidgetMessageProcessing": {
    "processing_your_request": "Procesando tu solicitud..."
  },
  "voiceWidgetQuestionMessage": {
    "you": "Tú"
  },
  "voiceWidgetQuestionSpoiler": {
    "you": "Tú"
  }
}