{
  "docSearchWidget": {
    "scanning_through_the_documentation": "Durchsuche die Dokumentation, bitte warten Sie einen Moment!",
    "generate_three_suggested_questions": "Generiere drei vorgeschlagene Fragen basierend auf den bereitgestellten Ressourcen. Jede Frage sollte durch einen Zeilenumbruch getrennt sein und aus höchstens fünf Wörtern bestehen. Bitte nummerieren Sie die Fragen nicht.",
    "oops_it_seems_youve_reached_your": "Hoppla! Es scheint, dass Sie Ihr Nachrichtenlimit erreicht haben. Um fortzufahren, passen Sie bitte Ihr Limit in den Einstellungen an.",
    "we_encountered_an_issue_while": "Beim Versuch, eine Antwort zu generieren, ist ein Problem aufgetreten! Versuchen Sie es später erneut!",
    "search": "Suche"
  },
  "docSearchWidgetHeader": {
    "hi_how_can_i_help_you": "Hallo! Wie kann ich Ihnen helfen?"
  },
  "docSearchWidgetInput": {
    "type_a_question_here": "Geben Sie hier eine Frage ein...",
    "ask": "Fragen"
  },
  "docSearchWidgetReset": {
    "reset": "Zurücksetzen"
  },
  "docSearchWidgetWrapper": {
    "click_here_to_see_embed_code": "Klicken Sie hier, um den Einbettungscode anzuzeigen"
  }
}