{
  "appTranslations": {
    "subscription_limit_reached": "Досягнуто обмеження підписки",
    "upgrade_subscription": "Оновити підписку",
    "it_looks_like_you_ve_reached": "Схоже, ви досягли обмеження вашої поточної підписки. Щоб продовжувати користуватися нашими послугами, натисніть кнопку нижче, щоб оновити підписку.",
    "we_ve_successfully_renewed_your": "Ми успішно оновили вашу підписку.",
    "we_encountered_an_error_while": "Під час спроби оновлення вашої підписки сталася помилка.",
    "run_out_of_units": "Ви вичерпали доступні одиниці. Будь ласка, поповніть свої одиниці або оновіть план оплати",
    "ok": "OK"
  }
}