import { showAlert } from '@/composables/mixin-alert';
import validationI18n from '@/helpers/validationI18n';

const { t } = validationI18n.global;

export const showPromocodeApplyMessage = (res: any) => {
  if (res?.status === 200) {
    showAlert('success', t('promocode.the_promocode_was_successfully_redeemed'));
  } else {
    showAlert('error', t('promocode.unable_to_redeem_the_promo_code'));
  }
};
