{
  "agent_page": {
    "agent_settings": "Configurações do agente",
    "select_model": "Selecionar modelo",
    "prompt_editor": "Editor de Prompt",
    "prompt_library": "Biblioteca de Prompt"
  },
  "select_model": {
    "select_provider": "Selecionar fornecedor",
    "select_model": "Selecionar modelo"
  },
  "prompt_editor": {
    "write_your_instruction": "Escreva sua instrução",
    "variables": "Variáveis",
    "save_prompt": "Salvar Prompt",
    "prompt_has_been_updated": "O prompt foi atualizado!",
    "model_has_been_updated": "O modelo foi atualizado!",
    "prompt_view": "Visualização do prompt",
    "edit_prompt": "Editar prompt",
    "cancel": "Cancelar"
  },
  "prompt_library": {
    "no_save_prompts": "Nenhum prompt salvo",
    "select_a_department_to_see_tasks": "Selecione um departamento para ver as tarefas"
  }
}