{
  "cardEditorFooter": {
    "cancel": "Cancelar",
    "save": "Guardar"
  },
  "cardFillBlank": {
    "correct_answer": "Respuesta correcta",
    "explanation": "Explicación"
  },
  "cardFillBlankEditor": {
    "question": "Pregunta",
    "enter_question": "Ingrese la pregunta",
    "correct_answer": "Respuesta correcta",
    "enter_correct_answer": "Ingrese la respuesta correcta",
    "explanation_optional": "Explicación (opcional)",
    "enter_explanation_for_the_correct_answer": "Ingrese la explicación para la respuesta correcta",
    "instructions_optional": "Instrucciones (opcional)",
    "enter_instructions_for_answering_the_question": "Ingrese las instrucciones para responder la pregunta"
  },
  "cardMatching": {
    "correct_answer": "Respuesta correcta",
    "explanation": "Explicación"
  },
  "cardMatchingEditor": {
    "question": "Pregunta",
    "enter_question": "Ingrese la pregunta",
    "answers": "Respuestas",
    "list_the_items_in_the_correct_sequence": "Enumere los elementos en la secuencia correcta. Se mezclarán para la actividad.",
    "enter_answer_option": "Ingrese la opción de respuesta",
    "add_more": "Agregar más",
    "explanation_optional": "Explicación (opcional)",
    "enter_explanation_for_the_correct_answer": "Ingrese la explicación para la respuesta correcta",
    "instructions_optional": "Instrucciones (opcional)",
    "enter_instructions_for_answering_the_question": "Ingrese las instrucciones para responder la pregunta"
  },
  "cardMultipleAnswers": {
    "correct_answers": "Respuestas correctas",
    "explanation": "Explicación"
  },
  "cardMultipleAnswersEditor": {
    "question": "Pregunta",
    "enter_question": "Ingrese la pregunta",
    "answer": "Respuesta",
    "enter_answer_option": "Ingrese la opción de respuesta",
    "add_more": "Agregar más",
    "correct_answers": "Respuestas correctas",
    "explanation_optional": "Explicación (opcional)",
    "enter_explanation_for_the_correct_answer": "Ingrese la explicación para la respuesta correcta",
    "instructions_optional": "Instrucciones (opcional)",
    "enter_instructions_for_answering_the_question": "Ingrese las instrucciones para responder la pregunta",
    "only_unique_answer_values_allowed": "¡Solo se permiten valores de respuesta únicos!"
  },
  "cardMultipleChoice": {
    "correct_answer": "Respuesta correcta",
    "explanation": "Explicación"
  },
  "cardMultipleChoiceEditor": {
    "question": "Pregunta",
    "enter_question": "Ingrese la pregunta",
    "answer": "Respuesta",
    "enter_answer_option": "Ingrese la opción de respuesta",
    "add_more": "Agregar más",
    "correct_answer": "Respuesta correcta",
    "enter_correct_answer": "Ingrese la respuesta correcta",
    "explanation_optional": "Explicación (opcional)",
    "enter_explanation_for_the_correct_answer": "Ingrese la explicación para la respuesta correcta",
    "instructions_optional": "Instrucciones (opcional)",
    "enter_instructions_for_answering_the_question": "Ingrese las instrucciones para responder la pregunta",
    "only_unique_answer_values_allowed": "¡Solo se permiten valores de respuesta únicos!"
  },
  "cardSequence": {
    "correct_sequence": "Secuencia correcta",
    "explanation": "Explicación"
  },
  "cardSequenceEditor": {
    "question": "Pregunta",
    "enter_question": "Ingrese la pregunta",
    "correct_answers": "Respuestas correctas",
    "list_the_items_in_the_correct_sequence": "Enumere los elementos en la secuencia correcta. Se mezclarán para la actividad.",
    "enter_answer_option": "Ingrese la opción de respuesta",
    "add_more": "Agregar más",
    "explanation_optional": "Explicación (opcional)",
    "enter_explanation_for_the_correct_answer": "Ingrese la explicación para la respuesta correcta",
    "instructions_optional": "Instrucciones (opcional)",
    "enter_instructions_for_answering_the_question": "Ingrese las instrucciones para responder la pregunta",
    "empty_values_are_not_allowed": "¡No se permiten valores vacíos!"
  },
  "cardShortAnswer": {
    "correct_answer": "Respuesta correcta",
    "explanation": "Explicación"
  },
  "cardShortAnswerEditor": {
    "question": "Pregunta",
    "enter_question": "Ingrese la pregunta",
    "correct_answer": "Respuesta correcta",
    "enter_correct_answer": "Ingrese la respuesta correcta",
    "explanation_optional": "Explicación (opcional)",
    "enter_explanation_for_the_correct_answer": "Ingrese la explicación para la respuesta correcta",
    "instructions_optional": "Instrucciones (opcional)",
    "enter_instructions_for_answering_the_question": "Ingrese las instrucciones para responder la pregunta"
  },
  "cardTrueFalse": {
    "true": "Verdadero",
    "false": "Falso",
    "correct_answer": "Respuesta correcta",
    "explanation": "Explicación"
  },
  "cardTrueFalseEditor": {
    "question": "Pregunta",
    "enter_question": "Ingrese la pregunta",
    "correct_answer": "Respuesta correcta",
    "explanation_optional": "Explicación (opcional)",
    "enter_explanation_for_the_correct_answer": "Ingrese la explicación para la respuesta correcta",
    "instructions_optional": "Instrucciones (opcional)",
    "enter_instructions_for_answering_the_question": "Ingrese las instrucciones para responder la pregunta",
    "true": "Verdadero",
    "false": "Falso"
  }
}