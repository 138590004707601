{
  "retellingWidget": {
    "retelling": "Racconto",
    "generate_retelling": "Genera racconto",
    "context": "CONTESTO",
    "retell_in_language": "Racconta in lingua {formLanguage} a {formSize} {formMethod} del contesto originale",
    "keeping_the_tone": "mantenendo il tono {formTone}",
    "use_markdown_format": "Usa il formato markdown",
    "add_the_primary_question": "Aggiungi la domanda principale con una risposta che risponda al contesto utilizzando il formato corsivo.",
    "add_main_emojis": "Aggiungi qui gli emoji principali.",
    "add_bold_main_keywords": "Aggiungi qui le parole chiave principali in grassetto.",
    "add_a_header": "Aggiungi un'intestazione come markdown h2.",
    "add_1_sentence_quote": "Aggiungi una citazione di 1 frase della frase più importante del contesto utilizzando il markdown del blocco quotato.",
    "if_you_have_what_to_add": "Se hai qualcosa da aggiungere come punti elenco, aggiungilo alla fine come elenco markdown.",
    "an_error_occurred_while_fetching": "Si è verificato un errore durante il recupero del racconto.",
    "something_didn_t_go_as_planned": "Qualcosa non è andato come previsto.",
    "error_occured_while_creating_pdf": "Si è verificato un errore durante la creazione del PDF"
  },
  "retellingWidgetAnswer": {
    "retold": "Raccontato",
    "from_original": "dalle originali ~{pages} pagine di testo a",
    "download_pdf": "Scarica PDF",
    "clear_retelling": "Cancella racconto",
    "retelling": "Racconto"
  },
  "retellingWidgetAnswerChunk": {
    "click_to_edit_this_block": "Fai clic per modificare questo blocco",
    "click_to_adjust_this_block": "Fai clic per regolare questo blocco",
    "click_to_delete_this_block": "Fai clic per eliminare questo blocco",
    "something_went_awry": "Qualcosa è andato storto!",
    "you_ve_successfully_deleted": "Hai eliminato con successo una parte del racconto!"
  },
  "retellingWidgetAnswerHeader": {
    "click_to_edit": "Fai clic per modificare l'intestazione del racconto"
  },
  "retellingWidgetForm": {
    "you_ve_enabled_custom_prompt_in_settings": "Hai abilitato il prompt personalizzato nelle impostazioni!",
    "block_size": "Dimensione blocco",
    "size_from_original": "Dimensione dalle originali ~{totalPages} pagine di testo",
    "tone": "Tono",
    "speak_tone": "Parla... tono",
    "language": "Lingua",
    "options": "Opzioni",
    "add_subheaders": "aggiungi sottointestazioni",
    "add_important_quotes": "aggiungi citazioni importanti",
    "use_bullet_points": "usa il formato elenco puntato",
    "add_main_question": "aggiungi domanda principale",
    "add_emojis": "aggiungi emoji",
    "bold_main_keywords": "parole chiave principali in grassetto",
    "write_it_for_a": "Scrivilo per un bambino di 9 anni come una storia di viaggio",
    "create_preview": "Crea anteprima",
    "original": "originale"
  },
  "retellingWidgetModal": {
    "create_retelling_pdf": "Crea PDF del racconto",
    "ok": "Ok",
    "wait_for_a_few_seconds_please": "Attendi qualche secondo per favore..."
  },
  "retellingWidgetPreview": {
    "preview": "Anteprima",
    "retelling_a_piece_of_content": "raccontando un pezzo di contenuto",
    "refresh_preview": "Aggiorna anteprima"
  }
}