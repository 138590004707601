import { SettingsPropertiesEnum } from '@/types/SettingsPropertiesEnum';
import { ChatGptModelNamesEnum, ProvidersEnum } from '@/types/ChatGptModelNamesEnum';

export const gptWidgetSettings = {
  [SettingsPropertiesEnum.MODELNAME]: ChatGptModelNamesEnum.GPT_4O_MINI,
  [SettingsPropertiesEnum.PROVIDERNAME]: ProvidersEnum.OPENAI,
  [SettingsPropertiesEnum.TEMPERATURE]: 0.6,
  [SettingsPropertiesEnum.TOP_P]: 0.6,
  [SettingsPropertiesEnum.HYBRID]: 0.6,
  [SettingsPropertiesEnum.LIMIT]: 4,
  [SettingsPropertiesEnum.RATE_LIMIT_ENABLED]: false,
  [SettingsPropertiesEnum.RATE_LIMIT]: 100,
};
