{
  "caseDatabase": {
    "it_looks_like_you_ve_reached_your_maximum_number": "Sembra che tu abbia raggiunto il numero massimo di progetti. Per avviare un nuovo progetto, considera di archiviare o eliminare quelli esistenti che non ti servono più, oppure esplora i nostri piani di abbonamento per limiti più elevati."
  },
  "welcomeFlowCustomJourney": {
    "ready_for_the_next_step": "Pronto per il prossimo passo nel tuo percorso personalizzato?",
    "it_is_time_to_initiate_a_new_project": "È il momento di avviare un nuovo progetto. Tuffati in un'esperienza su misura con la nostra suite di strumenti, inclusi vari widget, tutti progettati per organizzare e analizzare in modo fluido le tue informazioni.",
    "expand_your_projects_scope_by_integrating": "Espandi la portata del tuo progetto integrando una vasta gamma di contenuti, da siti web e link individuali a documenti archiviati in Google Drive, Dropbox, One Drive, PDF e molti altri.",
    "embark_on_this_unique_adventure": "Intraprendi questa avventura unica avviando oggi stesso il tuo progetto personalizzato e sblocca tutto il potenziale dei tuoi dati.",
    "create_project": "Crea Progetto",
    "or": "O",
    "advice": "consiglio",
    "let_our_bot_provide_you_with": "lascia che il nostro bot ti fornisca",
    "on_how_to_make_this_process": "su come rendere questo processo più facile.",
    "project_name": "Nome Progetto"
  },
  "wantToUpgradeCard": {
    "upgrade": "Aggiorna",
    "we_understand_youre_interested_in_this": "Siamo consapevoli del tuo interesse per questa funzionalità. È disponibile nei nostri piani di fascia superiore. Valuta l'aggiornamento per accedervi!"
  },
  "welcomeAnswerCard": {
    "you_can_select_a_tile_below": "Puoi selezionare una scheda qui sotto",
    "it_looks_like_you_ve_reached_your_maximum_number": "Sembra che tu abbia raggiunto il numero massimo di progetti. Per avviare un nuovo progetto, considera di archiviare o eliminare quelli esistenti che non ti servono più, oppure esplora i nostri piani di abbonamento per limiti più elevati."
  },
  "welcomeScreen": {
    "default_goals": "Ho una serie di file sul mio computer locale",
    "default_communicate_goals": "Voglio che la comunità abbia accesso 24/7 alla base di conoscenza e possa chattare con essa come con un essere umano",
    "not_sure_let_us_know": "Non sei sicuro? Scopri qualcosa nel nostro",
    "what_want_to_do": "Cosa vorresti fare?",
    "what_i_want_to_do_question": "Cosa voglio\n          fare?",
    "how_communicate": "Come comunicherò con i dati?",
    "not_sure": "Non sei sicuro?",
    "lets_find": "Scopriamo qualcosa\n      nel nostro",
    "what_types_of_files": "Quali tipi di file ho e come voglio caricarli?",
    "cases_database": "Database dei casi",
    "or_start_your": "o avvia il tuo",
    "impress_me": "Sorprendimi",
    "regenerate": "Rigenera",
    "custom_journey": "Percorso personalizzato",
    "project_name": "Nome progetto",
    "enter_the_name_of_the_project": "Inserisci il nome del progetto",
    "enter_project_name": "Inserisci il nome del progetto",
    "what_i_want_to_do": "Ciò che voglio fare: voglio condividere le mie conoscenze con i miei studenti. Voglio che abbiano la possibilità di ottenere risposte 24 ore su 24.",
    "what_types_of_files_i_have_and_how_i_want": "Quali tipi di file ho e come voglio caricarli: ho una serie di file PDF su OneDrive che voglio caricare.",
    "how_i_am_going_to": "Come comunicherò con i dati: voglio che gli studenti parlino con il bot su WhatsApp.",
    "we_could_not_create_the": "Non siamo riusciti a creare correttamente il progetto.",
    "what_types_of_files_you_want_to_upload": "Quali tipi di file vuoi caricare?",
    "how_are_you_going_to_communicate_with_the_data": "Come comunicherai con i dati?",
    "select_project_type": "Seleziona il tipo di progetto",
    "agent": "Agente",
    "knowledge_legacy": "Conoscenza (legacy)",
    "agent_description": "Gli agenti possono parlare con l’utente basandosi sulla propria base di conoscenza e possono anche eseguire compiti e azioni con servizi esterni attraverso una varietà di strumenti e API secondo lo scenario impostato.",
    "knowledge_description": "I progetti di conoscenza possono comunicare con l’utente solo basandosi sulla propria conoscenza personalizzata."
  }
}
