{
  "cardEditorFooter": {
    "cancel": "Annulla",
    "save": "Salva"
  },
  "cardFillBlank": {
    "correct_answer": "Risposta corretta",
    "explanation": "Spiegazione"
  },
  "cardFillBlankEditor": {
    "question": "Domanda",
    "enter_question": "Inserisci la domanda",
    "correct_answer": "Risposta corretta",
    "enter_correct_answer": "Inserisci la risposta corretta",
    "explanation_optional": "Spiegazione (opzionale)",
    "enter_explanation_for_the_correct_answer": "Inserisci la spiegazione per la risposta corretta",
    "instructions_optional": "Istruzioni (opzionale)",
    "enter_instructions_for_answering_the_question": "Inserisci le istruzioni per rispondere alla domanda"
  },
  "cardMatching": {
    "correct_answer": "Risposta corretta",
    "explanation": "Spiegazione"
  },
  "cardMatchingEditor": {
    "question": "Domanda",
    "enter_question": "Inserisci la domanda",
    "answers": "Risposte",
    "list_the_items_in_the_correct_sequence": "Elencare gli elementi nella sequenza corretta. Saranno mescolati per l'attività.",
    "enter_answer_option": "Inserisci l'opzione di risposta",
    "add_more": "Aggiungi altro",
    "explanation_optional": "Spiegazione (opzionale)",
    "enter_explanation_for_the_correct_answer": "Inserisci la spiegazione per la risposta corretta",
    "instructions_optional": "Istruzioni (opzionale)",
    "enter_instructions_for_answering_the_question": "Inserisci le istruzioni per rispondere alla domanda"
  },
  "cardMultipleAnswers": {
    "correct_answers": "Risposte corrette",
    "explanation": "Spiegazione"
  },
  "cardMultipleAnswersEditor": {
    "question": "Domanda",
    "enter_question": "Inserisci la domanda",
    "answer": "Risposta",
    "enter_answer_option": "Inserisci l'opzione di risposta",
    "add_more": "Aggiungi altro",
    "correct_answers": "Risposte corrette",
    "explanation_optional": "Spiegazione (opzionale)",
    "enter_explanation_for_the_correct_answer": "Inserisci la spiegazione per la risposta corretta",
    "instructions_optional": "Istruzioni (opzionale)",
    "enter_instructions_for_answering_the_question": "Inserisci le istruzioni per rispondere alla domanda",
    "only_unique_answer_values_allowed": "Sono consentiti solo valori di risposta unici!"
  },
  "cardMultipleChoice": {
    "correct_answer": "Risposta corretta",
    "explanation": "Spiegazione"
  },
  "cardMultipleChoiceEditor": {
    "question": "Domanda",
    "enter_question": "Inserisci la domanda",
    "answer": "Risposta",
    "enter_answer_option": "Inserisci l'opzione di risposta",
    "add_more": "Aggiungi altro",
    "correct_answer": "Risposta corretta",
    "enter_correct_answer": "Inserisci la risposta corretta",
    "explanation_optional": "Spiegazione (opzionale)",
    "enter_explanation_for_the_correct_answer": "Inserisci la spiegazione per la risposta corretta",
    "instructions_optional": "Istruzioni (opzionale)",
    "enter_instructions_for_answering_the_question": "Inserisci le istruzioni per rispondere alla domanda",
    "only_unique_answer_values_allowed": "Sono consentiti solo valori di risposta unici!"
  },
  "cardSequence": {
    "correct_sequence": "Sequenza corretta",
    "explanation": "Spiegazione"
  },
  "cardSequenceEditor": {
    "question": "Domanda",
    "enter_question": "Inserisci la domanda",
    "correct_answers": "Risposte corrette",
    "list_the_items_in_the_correct_sequence": "Elencare gli elementi nella sequenza corretta. Saranno mescolati per l'attività.",
    "enter_answer_option": "Inserisci l'opzione di risposta",
    "add_more": "Aggiungi altro",
    "explanation_optional": "Spiegazione (opzionale)",
    "enter_explanation_for_the_correct_answer": "Inserisci la spiegazione per la risposta corretta",
    "instructions_optional": "Istruzioni (opzionale)",
    "enter_instructions_for_answering_the_question": "Inserisci le istruzioni per rispondere alla domanda",
    "empty_values_are_not_allowed": "Non sono consentiti valori vuoti!"
  },
  "cardShortAnswer": {
    "correct_answer": "Risposta corretta",
    "explanation": "Spiegazione"
  },
  "cardShortAnswerEditor": {
    "question": "Domanda",
    "enter_question": "Inserisci la domanda",
    "correct_answer": "Risposta corretta",
    "enter_correct_answer": "Inserisci la risposta corretta",
    "explanation_optional": "Spiegazione (opzionale)",
    "enter_explanation_for_the_correct_answer": "Inserisci la spiegazione per la risposta corretta",
    "instructions_optional": "Istruzioni (opzionale)",
    "enter_instructions_for_answering_the_question": "Inserisci le istruzioni per rispondere alla domanda"
  },
  "cardTrueFalse": {
    "true": "Vero",
    "false": "Falso",
    "correct_answer": "Risposta corretta",
    "explanation": "Spiegazione"
  },
  "cardTrueFalseEditor": {
    "question": "Domanda",
    "enter_question": "Inserisci la domanda",
    "correct_answer": "Risposta corretta",
    "explanation_optional": "Spiegazione (opzionale)",
    "enter_explanation_for_the_correct_answer": "Inserisci la spiegazione per la risposta corretta",
    "instructions_optional": "Istruzioni (opzionale)",
    "enter_instructions_for_answering_the_question": "Inserisci le istruzioni per rispondere alla domanda",
    "true": "Vero",
    "false": "Falso"
  }
}