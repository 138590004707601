import { useWindowSize } from '@vueuse/core';
import { computed } from 'vue';

export const useViewportDimensions = () => {
  const { height, width } = useWindowSize();

  const vh = computed(() => `${height.value * 0.01}px`);

  return {
    vh,
    width,
    height,
  };
};
