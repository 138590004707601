{
  "CustomPromptOptionsEnumTranslations": {
    "adviser": "AI радник",
    "sales_assistant": "Продавець-консультант",
    "shakespeare": "Вільям Шекспір",
    "professor": "Професор",
    "formal": "офіційний",
    "informal": "неофіційний",
    "persuasive": "переконливий",
    "collaborative": "співпрацюючий",
    "creative": "творчий",
    "emojis": "емодзі",
    "mark_keywords": "виділити основні ключові слова",
    "subheaders": "підзаголовки",
    "billet_points_format": "формат маркованих пунктів"
  }
}