{
  "widgetTypeIndex": {
    "let_s_upload_the_content": "Carica il contenuto",
    "with": "Con",
    "informers": "Informer",
    "you_can_upload_information": "puoi caricare informazioni da una varietà di fonti, come siti web, documenti PDF, persino cartelle Google e altro ancora. Ricorda, puoi sempre tornare a questa schermata per caricare ulteriori contenuti nel tuo progetto",
    "upload_information": "Carica informazioni da una varietà di fonti, come siti web, documenti PDF, persino cartelle Google e altro ancora. Ricorda, puoi sempre tornare a questa schermata per caricare ulteriori contenuti nel tuo progetto quando ne hai bisogno",
    "whenever_you_need": "quando ne hai bisogno",
    "enter_the_name_of_the_project": "Inserisci il nome del progetto",
    "enter_project_name": "Inserisci il nome del progetto",
    "this_informer_is_not_available_yet": "Questo informer non è ancora disponibile.",
    "back": "Indietro"
  }
}