import { defineStore } from 'pinia';
import i18n from '@/i18n';
import appSetting from '@/app-setting';

//Types
import type { User } from '@nhost/nhost-js';
import type { WidgetTypeInterface } from '@components/basic-mode/interfaces/WidgetTypeInterface';
import type { BillingUserPackage } from '@/interfaces';
import type { AdviceValue, ImpressCard } from '@components/welcome-flow/WelcomeFlowTypes';
import { TeamScopesEnum } from '@components/project/settings/team/TeamScopesEnum';
import { ItemTypeKeysEnum } from '@/types/ItemTypeKeysEnum';
import { setLocaleForUser } from '@/helpers/userPreferences';

export interface UserToProjects {
  id: string;
  is_invited: Boolean;
  project: Project;
  project_id: string;
  scopes: TeamScopesEnum;
  user: User;
  user_id: string;
  favorite: boolean;
  favorite_time: Date;
}

export interface Project {
  id: string;
  name: string;
  description: string;
  created_at: string;
  widgetType: WidgetTypeInterface;
  projects_agents: any;
  user_to_projects: UserToProjects[];
  widgets: Widget[];
  chains: Array<{ id: string }>;
  is_public: boolean;
  type: string;
  tools: Tool[];
}

export interface ProjectType {
  value: string;
  label: string;
}

export interface Tool {
  id: number;
  name: string;
  key: string;
  icon?: string;
  type?: object;
  description?: string;
  slug: string;
}

export interface Widget {
  id: string;
  type_id: number;
  name: string;
  settings: any;
  is_default: boolean;
  project_id: string;
  styles: string;
  widget_type: WidgetTypeInterface;
  chains: Array<{ id: string }>;
}

export type RootState = {
  advice: AdviceValue;
  impressValue: ImpressCard | null;
  isDarkMode: boolean;
  isSettingsChanges: boolean;
  isLoadingQuestions: boolean;
  unsavedModalOpen: boolean;
  mainLayout: string;
  theme: string;
  menu: string;
  layout: string;
  rtlClass: string;
  animation: string;
  navbar: string;
  locale: string;
  sidebar: boolean;
  sidebarItem: ItemTypeKeysEnum;
  sidebarNoProjects: boolean;
  languageList: { code: string; name: string }[];
  isShowMainLoader: boolean;
  semidark: boolean;
  user: User | null;
  project: Project;
  projects: Project[] | [];
  projectTypes: ProjectType[];
  defaultProjectType: string;
  billingData: [];
  billingPackagesData: BillingUserPackage;
  billingPackagesHistory: any;
  widgets: Widget[] | [];
  isLoading: boolean;
  isProjectsLoaded: boolean;
  isPaidLoading: boolean;
  isPaid: boolean | null;
  isDeletedUser: boolean | null;
  isCancelledUser: boolean | null;
  savedIntegrationTrigger: boolean;
  unsavedSettingsCancelRedirectPath: string;
};

export const useAppStore = defineStore('app', {
  state: () =>
    ({
      advice: {},
      impressValue: null,
      isDarkMode: true,
      isSettingsChanges: false,
      unsavedModalOpen: false,
      mainLayout: 'app',
      theme: 'dark',
      menu: 'vertical',
      layout: 'full',
      rtlClass: 'ltr',
      animation: '',
      navbar: 'navbar-sticky',
      locale: 'en',
      sidebar: false,
      sidebarItem: ItemTypeKeysEnum.Widget,
      sidebarNoProjects: true,
      languageList: [{ code: 'en', name: 'English' }],
      isShowMainLoader: true,
      semidark: false,
      user: null,
      projects: [],
      project: {},
      projectTypes: [
        { value: 'agent/react', label: 'agent' },
        { value: 'legacy/knowledge', label: 'knowledge_legacy' },
      ],
      defaultProjectType: 'legacy/knowledge',
      billingData: [],
      billingPackagesData: {},
      billingPackagesHistory: [],
      widgets: [],
      isLoading: false,
      isProjectsLoaded: false,
      isPaidLoading: false,
      isPaid: null,
      isDeletedUser: null,
      isCancelledUser: null,
      isLoadingQuestions: false,
      savedIntegrationTrigger: false,
      unsavedSettingsCancelRedirectPath: '',
    } as RootState),

  actions: {
    setIsPaid(val: boolean | null) {
      this.isPaid = val;
    },
    setIsDeletedUser(val: boolean | null) {
      this.isDeletedUser = val;
    },
    setIsCancelledUser(val: boolean | null) {
      this.isCancelledUser = val;
    },
    setIsLoadingQuestions(val: boolean) {
      this.isLoadingQuestions = val;
    },
    setBillingData(data: any = null) {
      this.billingData = data;
    },
    setBillingPackagesData(data: any = null) {
      if (!data) return;
      this.billingPackagesData = { ...this.billingPackagesData, ...data };
    },
    setBillingPackagesHistory(data: any = null) {
      this.billingPackagesHistory = data;
    },
    setProject(key: 'name' | 'id' | 'type', value: string) {
      this.project[key] = value;
    },
    setByKeyAdviceValue<K extends keyof AdviceValue>(key: K, value: AdviceValue[K]) {
      this.advice[key] = value;
    },
    setImpressValue(data: ImpressCard | null = null) {
      if (data) {
        data.pairs = data.pairs.map((pair) => ({
          ...pair,
          widgets: pair.widgets ?? pair.visuals,
        }));
      }

      this.impressValue = data
        ? {
            ...data,
            pairs: data.pairs.filter(
              (pair) =>
                pair.informers.every((informer) => 'view' in informer) &&
                pair.widgets.every((widget) => 'view' in widget),
            ),
          }
        : data;
    },
    clearAdvice() {
      this.impressValue = null;
      this.advice = {} as any;
    },
    clearProject() {
      // @ts-ignore
      this.project = {};
    },
    setProjects(projects: any = null) {
      this.projects = projects;
      // @ts-ignore
      this.widgets = this.projects.reduce((acc: Widget[], project: Project) => {
        if (project.widgets) {
          acc = [...acc, ...project.widgets];
        }
        return acc;
      }, []);
    },
    setSavedIntegrationTrigger() {
      this.savedIntegrationTrigger = !this.savedIntegrationTrigger;
    },
    updateProjectWidgets(projectId: string, newWidgets: Widget[]) {
      const project = this.projects.find((p) => p.id === projectId);
      if (project) {
        project.widgets = newWidgets;
      }
    },
    deleteProjectById(id: string) {
      this.projects = this.projects.filter((pr) => pr.id !== id);
    },
    setUser(payload: User | null = null) {
      this.user = payload;
    },
    setMainLayout(payload: any = null) {
      this.mainLayout = payload; //app , auth
    },
    toggleTheme(payload: any = null) {
      payload = payload || this.theme; // light|dark|system
      localStorage.setItem('theme', payload);
      this.theme = payload;
      if (payload == 'light') {
        this.isDarkMode = false;
      } else if (payload == 'dark') {
        this.isDarkMode = true;
      } else {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
          this.isDarkMode = true;
        } else {
          this.isDarkMode = false;
        }
      }

      if (this.isDarkMode) {
        document.querySelector('body')?.classList.add('dark');
      } else {
        document.querySelector('body')?.classList.remove('dark');
      }
    },
    toggleMenu(payload: any = null) {
      payload = payload || this.menu; // vertical, collapsible-vertical, horizontal
      this.sidebar = false; // reset sidebar state
      localStorage.setItem('menu', payload);
      this.menu = payload;
    },
    toggleLayout(payload: any = null) {
      payload = payload || this.layout; // full, boxed-layout
      localStorage.setItem('layout', payload);
      this.layout = payload;
    },
    toggleRTL(payload: any = null) {
      payload = payload || this.rtlClass; // rtl, ltr
      localStorage.setItem('rtlClass', payload);
      this.rtlClass = payload;
      document.querySelector('html')?.setAttribute('dir', this.rtlClass || 'ltr');
    },
    toggleAnimation(payload: any = null) {
      payload = payload || this.animation; // animate__fadeIn, animate__fadeInDown, animate__fadeInUp, animate__fadeInLeft, animate__fadeInRight, animate__slideInDown, animate__slideInLeft, animate__slideInRight, animate__zoomIn
      payload = payload?.trim();
      localStorage.setItem('animation', payload);
      this.animation = payload;
      appSetting.changeAnimation();
    },
    toggleNavbar(payload: any = null) {
      payload = payload || this.navbar; // navbar-sticky, navbar-floating, navbar-static
      localStorage.setItem('navbar', payload);
      this.navbar = payload;
    },
    toggleSemidark(payload: any = null) {
      payload = payload || false;
      localStorage.setItem('semidark', payload);
      this.semidark = payload;
    },
    toggleLocale(payload: any = null) {
      payload = payload || this.locale;
      i18n.global.locale.value = payload;
      this.user && setLocaleForUser(payload);
      this.locale = payload;
    },
    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    setSettingsChanges(v: boolean) {
      this.isSettingsChanges = v;
    },
    setUnsavedModalOpen(v: boolean) {
      this.unsavedModalOpen = v;
    },
    setUnsavedSettingsCancelRedirect(path: string) {
      this.unsavedSettingsCancelRedirectPath = path;
    },
    setSidebarNoProjects(payload: boolean) {
      this.sidebarNoProjects = payload;
    },
    toggleMainLoader() {
      this.isShowMainLoader = true;
      setTimeout(() => {
        this.isShowMainLoader = false;
      }, 300);
    },
    setIsLoading(payload: any = null) {
      this.isLoading = payload;
    },
    setIsProjectsLoaded(payload: boolean) {
      this.isProjectsLoaded = payload;
    },
    setSidebarItem(payload: ItemTypeKeysEnum) {
      this.sidebarItem = payload;
    },
  },
  getters: {
    getProject(): Object {
      return this.project;
    },
    getProjects(): any[] {
      return this.projects;
    },
    getWidgetById: (state) => {
      return (id: string) =>
        state.widgets.find((widget: Widget) => {
          return String(widget.id) === id;
        });
    },
    dividedProjects: (state) => {
      return (myId: string) => {
        return (state.projects as []).reduce(
          (result: { myProjects: Project[]; sharedProjects: Project[] }, project: Project) => {
            const users: any = project.user_to_projects;
            if (
              users.findIndex(
                (user: UserToProjects) =>
                  user.user_id === myId && user.scopes === TeamScopesEnum.OWNER,
              ) > -1
            ) {
              result.myProjects.push(project);
            } else {
              result.sharedProjects.push(project);
            }
            return result;
          },
          { myProjects: [], sharedProjects: [] },
        );
      };
    },
    getProjectById() {
      return (id: string) => this.projects.find((pr) => pr.id === id);
    },
  },
});
