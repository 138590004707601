import { SettingsPropertiesEnum } from '@/types/SettingsPropertiesEnum';
import { anonymizerSettings } from '@components/project/settings/defaults/anonymizerSettings';
import { gptWidgetSettings } from '@components/project/settings/defaults/gptWidgetSettings';

export const retellingWidgetSettings = {
  ...gptWidgetSettings,
  [SettingsPropertiesEnum.CUSTOMPROMPT]:
    'Retell to 100 words of the original context keeping the persuasive tone. Write it for a 9 y.o. kid like a journey story. The whole answer must be only in English language. Use markdown format. Add the primary question with an answer that answers the context using italic formatting. Add a header as h2 markdown. Add 1 sentence quote of the most important phrase of context using blockqoute markdown. If you have what to add as bullet points than add it in the end as markdown list.',
  [SettingsPropertiesEnum.USECUSTOMPROMPT]: false,
  [SettingsPropertiesEnum.FORM_LANGUAGE]: 'English',
  [SettingsPropertiesEnum.RETELLINGCONTEXT]: '',
  [SettingsPropertiesEnum.LIMIT]: undefined,
  ...anonymizerSettings,
};
