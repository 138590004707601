{
  "caseDatabase": {
    "it_looks_like_you_ve_reached_your_maximum_number": "It looks like you've reached your maximum number of projects. To start a new project, consider archiving or deleting existing ones you no longer need, or explore our subscription plans for higher limits."
  },
  "welcomeFlowCustomJourney": {
    "ready_for_the_next_step": "Ready for the next step in your personalized journey?",
    "it_is_time_to_initiate_a_new_project": "It’s time to initiate a new project. Dive into a tailored experience with our suite of tools including and various Widgets, all designed to seamlessly organize and analyze your information.",
    "expand_your_projects_scope_by_integrating": "Expand your project’s scope by integrating a diverse array of content, from websites and individual links to documents housed in Google Drive, Dropbox, One Drive, PDFs. and many more.",
    "embark_on_this_unique_adventure": "Embark on this unique adventure by starting your custom project today, and unlock the full potential of your data.",
    "create_project": "Create Project",
    "or": "Or",
    "advice": "advice",
    "let_our_bot_provide_you_with": "let our bot provide you with",
    "on_how_to_make_this_process": "on how to make this process easier.",
    "project_name": "Project Name"
  },
  "wantToUpgradeCard": {
    "upgrade": "Upgrade",
    "we_understand_youre_interested_in_this": "We understand you're interested in this feature. It's available in our higher-tier plans. Consider upgrading to access it!"
  },
  "welcomeAnswerCard": {
    "you_can_select_a_tile_below": "You can select a tile below",
    "it_looks_like_you_ve_reached_your_maximum_number": "It looks like you've reached your maximum number of projects. To start a new project, consider archiving or deleting existing ones you no longer need, or explore our subscription plans for higher limits."
  },
  "welcomeScreen": {
    "default_goals": "I have a series of files on my local machine",
    "default_communicate_goals": "I want the community to have a 24/7 access to the knowledge base and chat with it like with a human",
    "not_sure_let_us_know": "Not sure? Let's find out something in our",
    "what_want_to_do": "Type what would you like to do?",
    "what_i_want_to_do_question": "What do I want\n          to do?",
    "how_communicate": "How am I going to communicate with the data?",
    "not_sure": "Not sure?",
    "lets_find": "Let's find out something\n      in our",
    "what_types_of_files": "What types of files do I have and how do I want to upload them?",
    "cases_database": "Cases database",
    "or_start_your": "or start your",
    "impress_me": "Impress me",
    "regenerate": "Regenerate",
    "custom_journey": "Custom journey",
    "project_name": "Project name",
    "enter_the_name_of_the_project": "Enter the name of the project",
    "enter_project_name": "Enter project name",
    "what_i_want_to_do": "What I want to do: I want to share my knowledge with my students. I want them to have the ability to get answers 24/7.",
    "what_types_of_files_i_have_and_how_i_want": "What types of files I have and how I want to upload them: I have a series of PDF files on OneDrive that I want to upload.",
    "how_i_am_going_to": "How I am going to communicate with the data: I want the students to talk to the bot on WhatsApp.",
    "we_could_not_create_the": "We could not create the project correctly.",
    "what_types_of_files_you_want_to_upload": "What types of files you want to upload?",
    "how_are_you_going_to_communicate_with_the_data": "How are you going to communicate with the data?",
    "select_project_type": "Select project type",
    "agent": "Agent",
    "knowledge_legacy": "Knowledge (legacy)",
    "agent_description": "Agents can talk to the user based on their own knowledge base and also perform tasks and actions with external services through a variety of Tools and APIs according to the scenario you set",
    "knowledge_description": "Knowledge projects can only talk to the user based on their own custom knowledge"
  }
}
