{
  "audioInformerInformers": {
    "language": "Language",
    "select_files_to_upload": "Select files to upload",
    "for_advanced_users_only": "For advanced users only!",
    "advanced_settings": "Advanced Settings",
    "your_uploaded_files": "Your uploaded files",
    "file": "File",
    "was_added_previously": "was added previously",
    "an_error_occurred_while": "An error occurred while uploading a file"
  },
  "baseInformerInformers": {
    "project_does_not_exist_in_data_base_with": "Project does not exist in data base with"
  },
  "fileInformerInformers": {
    "select_files_to_upload": "Select files to upload",
    "for_advanced_users_only": "For advanced users only!",
    "advanced_settings": "Advanced Settings",
    "your_uploaded_files": "Your uploaded files",
    "looks_like_your_file": "Looks like your file",
    "does_not_have_any_text": "doesn't have any text we can directly use. No worries! You can easily",
    "convert_it_into_a_text_filled_pdf": "convert it into a text-filled PDF and upload with PDF OCR Informer",
    "upgrade_your_plan_and": "upgrade your plan and convert it into a text-filled PDF and upload with PDF OCR Informer",
    "file": "File",
    "was_added_previously": "was added previously",
    "your": "Your",
    "has_been_successfully_uploaded": "has been successfully uploaded",
    "an_error_occurred_while": "An error occurred while uploading the file"
  },
  "notionInformerInformers": {
    "your": "Your",
    "a_secure_integration_secret": "* A secure integration secret generated from your Notion account, ensuring a seamless and safe integration.",
    "integration_token": "Integration token",
    "page_url": "Page url",
    "page_should_be_shared": "* Your Notion page should be shared with your integration.",
    "your_uploaded_links": "Your uploaded links:",
    "not_valid_url": "The value is not a valid URL address",
    "add": "Add"
  },
  "pdfOcrFileInformerInformers": {
    "select_files_to_upload": "Select files to upload",
    "for_advanced_users_only": "For advanced users only!",
    "advanced_settings": "Advanced Settings",
    "your_uploaded_files": "Your uploaded files",
    "great_news_your_file": "Great news! Your file",
    "is_a_straightforward_pdf": "is a straightforward PDF and doesn't require special OCR processing. Please go ahead and",
    "upload_it_using_our": "upload it using our File Informer",
    "for_a_smoother_experience": "for a smoother experience!",
    "file": "File",
    "was_added_previously": "was added previously",
    "your": "Your",
    "has_been_successfully_uploaded": "has been successfully uploaded",
    "an_error_occurred_while": "An error occurred while uploading the file"
  },
  "singleWebInformerInformers": {
    "type_in_your_url_here": "Type in your URL here",
    "preview": "Preview",
    "page_content": "Page content",
    "preview_content": "Preview content",
    "project_does_not_exist_in_data_base_with": "Project does not exist in data base with"
  },
  "uploadAdvancedSettingsInformers": {
    "embeddings_provider": "Embeddings Provider",
    "you_can_specify_the": "You can specify the embeddings provider that will process your information."
  },
  "webInformerInformers": {
    "paste_your_batch_of_links_here": "Paste your batch of links here",
    "add_urls": "Add urls",
    "your_uploaded_links": "Your uploaded links:",
    "page_content": "Page content",
    "preview_content": "Preview content",
    "must_be_filled_with_a_value": "Must be filled with a value.",
    "invalid_urls": "Invalid URLs",
    "project_does_not_exist_in_data_base_with": "Project does not exist in data base with"
  },
  "wordpressInformerInformers": {
    "import_from_url": "Import from URL",
    "add": "Add",
    "remove": "Remove",
    "couldn_t_get_retrieve_the_wordpress": "Couldn't get retrieve the WordPress site. You can try using the Single Site Informer or specify another url.",
    "go_to_website_informer": "Go to Website informer",
    "select_the_types_of_content": "Select the types of content you're interested in receiving from the website."
  }
}
