{
  "pagesProfileId": {
    "profile": "Профіль",
    "profile_settings": "Налаштування профілю",
    "settings": "Налаштування",
    "home": "Головна",
    "change_password": "Змінити пароль"
  },
  "pagesProfileIndex": {
    "my_profile": "Мій профіль",
    "notifications": "Сповіщення",
    "profile_summary": "Огляд профілю",
    "services_credentials": "Облікові дані сервісів",
    "api_keys": "Ключі API",
    "billings": "Рахунки",
    "marketplace": "Маркетплейс",
    "packages": "Пакети",
    "integrations": "Інтеграції",
    "payment_details": "Деталі платежу"
  }
}