import { defineAsyncComponent } from 'vue';

export function useBaseComponents() {
  const BaseButton = defineAsyncComponent(() => import('@components/base/BaseButton.vue'));
  const BaseAiHelp = defineAsyncComponent(() => import('@components/base/BaseAiHelp.vue'));
  const BaseErrorMessage = defineAsyncComponent(
    () => import('@components/base/BaseErrorMessage.vue'),
  );
  const BaseLabel = defineAsyncComponent(() => import('@components/base/BaseLabel.vue'));
  const BaseLabelPopup = defineAsyncComponent(() => import('@components/base/BaseLabelPopup.vue'));
  const BaseSliderRangeCentered = defineAsyncComponent(
    () => import('@components/base/BaseSliderRangeCentered.vue'),
  );
  const BaseField = defineAsyncComponent(() => import('@components/base/BaseField.vue'));
  const BaseSpinInput = defineAsyncComponent(() => import('@components/base/BaseSpinInput.vue'));
  const BaseInput = defineAsyncComponent(() => import('@components/base/BaseInput.vue'));
  const BaseModal = defineAsyncComponent(() => import('@components/base/BaseModal.vue'));
  const BaseRangepicker = defineAsyncComponent(
    () => import('@components/base/BaseRangepicker.vue'),
  );
  const BaseSlider = defineAsyncComponent(() => import('@components/base/BaseSlider.vue'));
  const BaseSelect = defineAsyncComponent(() => import('@components/base/BaseSelect.vue'));
  const BaseDatepicker = defineAsyncComponent(() => import('@components/base/BaseDatepicker.vue'));
  const BaseSelectInputGroup = defineAsyncComponent(
    () => import('@components/base/BaseSelectInputGroup.vue'),
  );
  const BaseSvgIcon = defineAsyncComponent(() => import('@components/base/BaseSvgIcon.vue'));
  const BaseTable = defineAsyncComponent(() => import('@components/base/BaseTable.vue'));
  const PopperMenu = defineAsyncComponent(() => import('@components/general/PopperMenu.vue'));
  const UnpublishProject = defineAsyncComponent(
    () => import('@components/project/UnpublishProject.vue'),
  );
  const SidebarLink = defineAsyncComponent(
    () => import('@components/layout/sidebar/SidebarLink.vue'),
  );
  const WidgetCard = defineAsyncComponent(() => import('@components/basic-mode/WidgetCard.vue'));
  const BaseCheckbox = defineAsyncComponent(() => import('@components/base/BaseCheckbox.vue'));
  const BaseSwitch = defineAsyncComponent(() => import('@components/base/BaseSwitch.vue'));
  const BaseResizeableTextarea = defineAsyncComponent(
    () => import('@components/base/BaseResizeableTextarea.vue'),
  );

  const BaseWidgetSvgIcon = defineAsyncComponent(
    () => import('@components/base/BaseWidgetSvgIcon.vue'),
  );

  const BaseInfoMessage = defineAsyncComponent(
    () => import('@components/base/BaseInfoMessage.vue'),
  );

  const BaseUnsavedModal = defineAsyncComponent(
    () => import('@components/base/BaseUnsavedModal.vue'),
  );

  const BaseRadioGroup = defineAsyncComponent(
    () => import('@components/base/BaseRadioGroup.vue'),
  );

  const BaseRadioButton = defineAsyncComponent(
    () => import('@components/base/BaseRadioButton.vue'),
  );

  const ScreenLoader = defineAsyncComponent(() => import('@components/base/ScreenLoader.vue'));
  const LoaderComponent = defineAsyncComponent(() => import('@components/Loader.vue'));
  return {
    BaseAiHelp,
    BaseButton,
    BaseCheckbox,
    BaseDatepicker,
    BaseErrorMessage,
    BaseField,
    BaseInfoMessage,
    BaseInput,
    BaseLabel,
    BaseLabelPopup,
    BaseModal,
    BaseRadioButton,
    BaseRadioGroup,
    BaseResizeableTextarea,
    BaseSelect,
    BaseSelectInputGroup,
    BaseSlider,
    BaseSliderRangeCentered,
    BaseSpinInput,
    BaseSvgIcon,
    BaseSwitch,
    BaseTable,
    BaseRangepicker,
    BaseUnsavedModal,
    BaseWidgetSvgIcon,
    LoaderComponent,
    PopperMenu,
    ScreenLoader,
    SidebarLink,
    UnpublishProject,
    WidgetCard,
  };
}
