import { nhost, nhostAuthProvider } from '@/lib/nhost';
import { GET_USER_IS_PAID_STATUS } from '@/api';
import { useAppStore } from '@/stores';
import { storeToRefs } from 'pinia';

const getIsPaidUser = async function (isPortalUser: boolean = false, portalDomain: string = '', force=false) {
  const store = useAppStore();
  const { isPaid } = storeToRefs(store);

  const user = await nhostAuthProvider.getUser();

  if (force || (isPaid.value === null && !isPortalUser)) {
    const result = await nhost.graphql.request(GET_USER_IS_PAID_STATUS, { id: `${user?.id}` });
    const res = result.data?.profiles_by_pk?.billing?.[0];
    store.setIsPaid((res?.units_amount - res?.units_spent) >= 0);
  }

  // if (isPaid.value === null && isPortalUser) {
  //   const result = await nhost.graphql.request(GET_PORTAL_USER_IS_PAID_STATUS, {
  //     domain: portalDomain,
  //     userId: `${user?.id}`,
  //   });
  //
  //   const paid = result.data.portals_profiles[0]?.is_paid ?? false;
  //   store.setIsPaid(paid);
  //
  //   return paid;
  // }
  return isPaid.value;
};

export { getIsPaidUser };
