{
  "portalProjectItem": {
    "your_link_name": "Your link name",
    "delete_this_project": "Delete this project?",
    "delete_project": "Delete project",
    "project_will_be_deleted": "Project will be deleted. This cannot be undone"
  },
  "portalProjects": {
    "no_projects_so_far": "No projects so far",
    "apply": "Apply",
    "link_name": "Link name",
    "project": "Project",
    "widget": "Widget",
    "actions": "Actions",
    "the_item_has_been_successfully_deleted": "The item has been successfully deleted.",
    "projects_successfully_updated": "Projects successfully updated."
  }
}