import { qAWidgetSettings } from '@/components/project/settings/defaults/qAWidgetSettings';
import { SettingsPropertiesEnum } from '@/types/SettingsPropertiesEnum';
import { anonymizerSettings } from '@components/project/settings/defaults/anonymizerSettings';

export const explainWidgetSettings = {
  ...qAWidgetSettings,
  ...anonymizerSettings,
  [SettingsPropertiesEnum.LOGO]: {
    id: '',
    src: '',
  },
  [SettingsPropertiesEnum.IS_AUTO_LANGUAGE]: false,
  [SettingsPropertiesEnum.FORM_LANGUAGE]: 'English',
  [SettingsPropertiesEnum.QUESTIONS_TITLE]: 'Examples of questions that you can ask the bot about',
  [SettingsPropertiesEnum.QUESTIONS_TITLE_IS_ACTIVE]: true,
  [SettingsPropertiesEnum.SEARCH_PLACEHOLDER]: 'Type a question here...',
  [SettingsPropertiesEnum.SEARCH_PLACEHOLDER_IS_ACTIVE]: true,
  [SettingsPropertiesEnum.SUBMIT_BUTTON_TEXT]: 'Ask',
  [SettingsPropertiesEnum.SUBMIT_BUTTON_TEXT_IS_ACTIVE]: true,
  [SettingsPropertiesEnum.RESOURCES]: false,
  [SettingsPropertiesEnum.MOST_LIKELY_ENABLED]: true,
  [SettingsPropertiesEnum.BRAND_NAME]: '',
  [SettingsPropertiesEnum.BRAND_NAME_IS_ACTIVE]: true,
  [SettingsPropertiesEnum.BRAND_LOGO_IS_ACTIVE]: true,
};
