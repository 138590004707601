import { NhostClient } from '@nhost/vue';

const nhostClient = new NhostClient({
  backendUrl: import.meta.env.VITE_NHOST_BACKEND_URL,
  subdomain: import.meta.env.VITE_NHOST_SUBDOMAIN,
  region: import.meta.env.VITE_NHOST_REGION,
})

export const nhost = nhostClient;
export const nhostAuthProvider = nhostClient.auth;
