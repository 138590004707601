{
  "validations": {
    "required": "Це поле обов'язкове",
    "isOldPasswordCorrect": "Будь ласка, введіть правильний старий пароль",
    "email": "Будь ласка, введіть правильну адресу електронної пошти",
    "url": "Будь ласка, введіть правильну URL-адресу",
    "URL": "Будь ласка, введіть правильну URL-адресу",
    "min": "Будь ласка, введіть щонайменше {min} символів",
    "integer": "Будь ласка, введіть ціле число",
    "minValue": "Значення повинно бути не менше {min}",
    "maxValue": "Значення повинно бути не більше {max}",
    "max": "Будь ласка, введіть не більше {max} символів",
    "minLength": "Будь ласка, введіть щонайменше {min} символів",
    "maxLength": "Будь ласка, введіть не більше {max} символів",
    "requiredIf": "Це поле обов'язкове",
    "sameAs": "Значення повинно бути рівним іншому значенню"
  },
  "promocode": {
    "the_promocode_was_successfully_redeemed": "Промокод успішно використано. Для отримання додаткової інформації перевірте ваші рахунки",
    "unable_to_redeem_the_promo_code": "Не вдалося використати промокод. Будь ласка, перевірте код на наявність помилок та спробуйте ще раз або зверніться до служби підтримки за допомогою."
  }
}