{
  "portalPreview": {
    "example": "Esempio",
    "suggestedQuestions": "Domande suggerite",
    "who_s_on_duty_today": "Chi è di turno oggi?",
    "send": "Invia"
  },
  "tenantPortalPortalUi": {
    "portal_styles": "Stili del portale",
    "background_color": "Colore di sfondo",
    "primary_color": "Colore primario",
    "secondary_color": "Colore secondario/Stroke",
    "accent_color": "Colore di accentuazione",
    "text_color": "Colore del testo",
    "portal_preview": "Anteprima del portale",
    "apply": "Applica",
    "field_is_not_a_valid_hex_color": "Il campo non è un colore esadecimale valido",
    "failed_to_upload_branding_logo": "Caricamento del logo del marchio non riuscito",
    "portal_ui_successfully_saved": "Interfaccia utente del portale salvata con successo"
  }
}