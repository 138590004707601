{
  "serviceItem": {
    "here_something_might_be_wrong": "Тут щось може бути не так! Виправте, будь ласка, і натисніть кнопку 'Перевірити валідність облікових даних' знову",
    "here_something_might_be_wrong_few": "Тут щось може бути не так! Виправте, будь ласка, і натисніть кнопку 'Перевірити валідність облікових даних' знову",
    "here_something_might_be_wrong_many": "Тут щось може бути не так! Виправте, будь ласка, і натисніть кнопку 'Перевірити валідність облікових даних' знову",
    "here_something_might_be_wrong_one": "Тут щось може бути не так! Виправте, будь ласка, і натисніть кнопку 'Перевірити валідність облікових даних' знову",
    "here_something_might_be_wrong_other": "Тут щось може бути не так! Виправте, будь ласка, і натисніть кнопку 'Перевірити валідність облікових даних' знову",
    "learn_more": "Дізнатися більше",
    "learn_more_few": "Дізнатися більше",
    "learn_more_many": "Дізнатися більше",
    "learn_more_one": "Дізнатися більше",
    "learn_more_other": "Дізнатися більше",
    "check_credentials_validity": "Перевірити валідність облікових даних",
    "check_credentials_validity_few": "Перевірити валідність облікових даних",
    "check_credentials_validity_many": "Перевірити валідність облікових даних",
    "check_credentials_validity_one": "Перевірити валідність облікових даних",
    "check_credentials_validity_other": "Перевірити валідність облікових даних"
  }
}