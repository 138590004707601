{
  "appTranslations": {
    "subscription_limit_reached": "Limite de Assinatura Atingido",
    "upgrade_subscription": "Atualizar Assinatura",
    "it_looks_like_you_ve_reached": "Parece que você atingiu o limite da sua assinatura atual. Para continuar desfrutando dos nossos serviços, clique no botão abaixo para atualizar sua assinatura.",
    "we_ve_successfully_renewed_your": "Renovamos sua assinatura com sucesso.",
    "we_encountered_an_error_while": "Encontramos um erro ao tentar renovar sua assinatura.",
    "run_out_of_units": "Você esgotou as unidades disponíveis. Por favor, reabasteça suas unidades ou atualize o plano de faturamento",
    "ok": "OK"
  }
}