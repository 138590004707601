{
  "dialogs": {
    "user_info": "Інформація про користувача",
    "select_all_dialogs": "Вибрати всі діалоги",
    "search": "Пошук...",
    "deletion_of_the_selected_dialogs": "Видалення вибраних діалогів",
    "there_are_no_dialogs_yet": "Поки що немає діалогів",
    "knowledge": "Знання",
    "delete": "Видалити",
    "something_went_wrong": "Під час видалення сталася помилка",
    "question_and_answer_were_saved_successfully": "Питання та відповідь успішно збережені!",
    "an_error_occurred_while_saving": "Під час збереження питання та відповіді сталася помилка!",
    "upgrade_plan": "Для використання цієї функціональності, оновіть свій план",
    "anonym": "Анонім",
    "no_tracking": "Відстеження повідомлень наразі вимкнено. Будь ласка, увімкніть його в налаштуваннях, щоб продовжити.",
    "not_defined": "Не визначено",
    "browser": "Браузер",
    "os": "ОС",
    "more": "більше",
    "less": "менше"
  },
  "dialogsFilters": {
    "filters": "Фільтри",
    "by_period": "За періодом",
    "by_negative": "Негативні відгуки",
    "by_calls": "Дзвінки оператора",
    "by_offline": "Офлайн повідомлення",
    "apply": "Застосувати",
    "cancel": "Скасувати"
  }
}