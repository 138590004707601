export enum ProvidersEnum {
  ANTHROPIC = 'anthropic',
  BEDROCK = 'bedrock',
  CLOUDFLARE = 'cloudflare',
  OPENAI = 'openai',
}

export enum ChatGptModelNamesEnum {
  GPT_4_TURBO_128K = 'gpt-4-1106-preview',
  GPT_4O = 'gpt-4o',
  GPT_4O_MINI = 'gpt-4o-mini',
}

export enum OpenAIEmbeddingsModelNamesEnum {
  TEXT_EMBEDDING_3_LARGE = 'text-embedding-3-large',
  TEXT_EMBEDDING_3_SMALL = 'text-embedding-3-small',
  TEXT_EMBEDDING_ADA_002 = 'text-embedding-ada-002',
}

export enum AnthropicModelNamesEnum {
  CLAUDE_3_HAIKU_20240307 = 'claude-3-haiku-20240307',
  CLAUDE_3_OPUS_20240229 = 'claude-3-opus-20240229',
  CLAUDE_3_SONNET_20240229 = 'claude-3-sonnet-20240229',
}

export enum BedrockModelNamesEnum {
  CLAUDE_3_HAIKU_20240307_V10 = 'claude-3-haiku-20240307-v1:0',
  CLAUDE_3_SONNET_20240229_V10 = 'claude-3-sonnet-20240229-v1:0',
  LLAMA3_70B_INSTRUCT_V10 = 'llama3-8b-instruct-v1:0',
  LLAMA3_8B_INSTRUCT_V10 = 'llama3-70b-instruct-v1:0',
  MISTRAL_7B_INSTRUCT_V02 = 'mistral-7b-instruct-v0:2',
  MIXTRAL_8X7B_INSTRUCT = 'mixtral-8x7b-instruct-v0:1',
  TITAN_TEXT_PREMIER_V10 = 'titan-text-premier-v1:0',
}

export enum CloudflareModelNamesEnum {
  LLAMA_2_7B_CHAT_FP16 = 'llama-2-7b-chat-fp16',
  LLAMA_2_7B_CHAT_INT18 = 'llama-2-7b-chat-int8',
  MISTRAL_7B_INSTRUCT_V01 = 'mistral-7b-instruct-v0.1',
}
