import type { ApiRequest, ApiResponse } from '@/api';

interface ApiErrorParams {
  message?: string;
  request: ApiRequest;
  response: ApiResponse;
}

export class ApiError extends Error {
  request: ApiRequest;
  response: ApiResponse;

  constructor({ message, request, response }: ApiErrorParams) {
    super(message);

    this.name = 'ApiError';
    this.request = request;
    this.response = response;
  }
}
