{
  "exportQuestionsCodeModal": {
    "how_to_import_into_quizlet": "Come importare in Quizlet",
    "start_by_heading_to": "Inizia dirigendoti su Quizlet e cliccando il pulsante + per creare un nuovo set di studio. Successivamente, trova e clicca il pulsante \"Importa\". Ora, semplicemente copia il contenuto fornito sopra e incollalo in Quizlet. Ricorda: usa una",
    "tab": "Tab",
    "between_each_term_and_its_definition_and_a": "tra ogni termine e la sua definizione, e una",
    "new_line": "Nuova riga",
    "to_separate_different_cards": "per separare le diverse schede. Buono studio!",
    "copy_content": "Copia contenuto",
    "copied_content": "Contenuto copiato",
    "content_was_copied": "Contenuto copiato!"
  },
  "filtersModal": {
    "filters": "Filtri",
    "search_for_resources": "Cerca risorse...",
    "search_by_selected_resources": "Cerca per risorse selezionate",
    "reset_all": "Resetta tutto",
    "check_all": "Seleziona tutto",
    "sorry_no_resources_found_for_your": "Spiacenti, nessuna risorsa trovata per la tua ricerca",
    "please_try_modifying_your": "Per favore prova a modificare i termini di ricerca o i filtri per espandere la tua ricerca.",
    "cancel": "Annulla",
    "apply": "Applica"
  },
  "liveConnect": {
    "error_executing_user_code": "Errore nell'esecuzione del codice utente",
    "something_went_wrong": "Qualcosa è andato storto! Per favore, assicurati di aver installato Intercom correttamente",
    "something_went_wrong_please_make_sure": "Qualcosa è andato storto! Per favore, assicurati di aver installato correttamente il widget Hubspot",
    "something_went_wrong_please_make_sure_dialogFlow": "Qualcosa è andato storto! Per favore, assicurati di aver installato correttamente DialogFlow"
  },
  "offlineMessage": {
    "name": "Nome",
    "email": "Email",
    "subject": "Oggetto",
    "message": "Messaggio",
    "submit": "Invia",
    "error_executing_user_code": "Errore nell'esecuzione del codice utente",
    "thank_you_for_your_response": "Grazie per la tua risposta!",
    "something_went_awry": "Qualcosa è andato storto!"
  },
  "poweredByMessage": {
    "powered_by": "Powered by"
  },
  "questionnaires": {
    "examples_of_questions_that_you_can_ask": "Esempi di domande che puoi fare al bot"
  },
  "widgetsFooter": {
    "back": "Indietro",
    "help": "Aiuto"
  },
  "widgetsModalInfo": {
    "it_staff_augmentation_in_portugal": "Potenziamento del personale IT in Portogallo",
    "upload_time": "Tempo di caricamento",
    "result_relevance": "Rilevanza dei risultati"
  },
  "widget_external_user_form": {
    "please_provide_information_to_chat_with_me": "Fornisci le informazioni per chattare con me",
    "your_email": "La tua email",
    "email": "Email",
    "your_name": "Il tuo nome",
    "name": "Nome",
    "continue": "Continua"
  }
}