{
  "knowledge_name": "Knowledge",
  "knowledge_description": "Answer questions using custom uploaded information",
  "google-calendar-book-meeting_name": "Google Calendar book meeting",
  "google-calendar-book-meeting_description": "Book appointment when user asks to schedule an appointment",
  "google-calendar-free-slots_name": "Google Calendar free slots",
  "google-calendar-free-slots_description": "Receives free time slots",
  "rest-api-call_name": "Rest API call",
  "rest-api-call_description": "Connect any custom REST API: GET, POST, PUT",
  "document-url_name": "Document URL",
  "document-url_description": "A tool to fetch documents like PDF or docx by URL and return it's content",
  "calculator_name": "Calculator",
  "calculator_description": "Getting the result of a math expression.",

  "pagesProjectsIdToolViewContent": {
    "let_s_upload_the_content": "Let's upload the content",
    "with": "With",
    "you_can_upload_information": "you can upload information from a variety of sources, such as websites, PDF documents, even Google folders, and more. Remember, you can always return to this screen to load additional content into your project",
    "upload_information": "Upload information from a variety of sources, such as websites, PDF documents, even Google folders, and more. Remember, you can always return to this screen to load additional content into your project whenever you need.",
    "whenever_you_need": "whenever you need",
    "select_tool": "Select tool",
    "back": "Back",
    "we_are_working_on_it": "We are working on it.",
    "is_not_supported_for_project_with": "is not supported for project with uploaded data. Please create a new project for this one"
  },
  "pagesProjectsIdToolMyTools": {
    "delete_tool": "Delete Tool",
    "confirm_tool_deletion": "Confirm Tool Deletion",
    "are_you_sure_you_want_to_delete": "Are you sure you want to delete {toolName} tool? This action cannot be undone."
  },
  "pagesProjectsIdToolSelectWidget": {
    "need_another": "Need another",
    "data_source": "data source",
    "view": "view",
    "have_a_specific": "Have a specific {feature} in mind that would enhance your experience? Request it as a feature, and we'll work on integrating it.",
    "sorry_we_could_not": "Sorry, we couldn't find any matching results. If you have a specific {feature} in mind, you can request it as a feature, and we'll work on integrating it for you.",
    "request_a_new": "Request a new {feature}"
  },
  "basicModeToolCard": {
    "coming_soon": "COMING SOON",
    "beta": "BETA",
    "popular": "POPULAR",
    "we_understand_you": "We understand you're interested in this feature. It's available in our higher-tier plans. Consider upgrading to access it!",
    "add_another_tool": "Add another tool",
    "expand_your_dashboard_by_adding_a_new_tool": "Expand your dashboard by adding a new tool."
  },

}
