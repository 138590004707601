{
  "likeDislikeAnswer": {
    "please_provide_additional_feedback": "Будь ласка, надайте додатковий відгук",
    "enter_your_username": "Введіть своє ім'я",
    "enter_your_email": "Введіть свою електронну пошту",
    "what_was_the_issue_with_the_response": "Яка була проблема з відповіддю?",
    "email_and_username_are_required": "Для надсилання відгуку потрібні електронна пошта та ім'я",
    "thank_you_sincerely_for_your_feedback": "Сердечно дякуємо за ваш відгук. Він дуже цінний.",
    "sorry_but_we_encountered": "Вибачте, але виникла проблема при спробі надіслати ваш відгук. Будь ласка, спробуйте ще раз.",
    "grateful_for_the_thumbs_up": "Дякуємо за позитивний відгук!"
  },
  "likeDislikeAnswerModal": {
    "submit": "Надіслати"
  },
  "likeDislikeAnswerModalHeader": {
    "didnt_like_the_response": "Не сподобалася відповідь"
  }
}