{
  "mindmapLoader": {
    "something_went_awry": "Qualcosa è andato storto!",
    "please_wait_while_we_generate_your": "Attendere mentre generiamo il tuo",
    "attempt": "Tentativo"
  },
  "topicsModal": {
    "select_mind_map_topic": "Seleziona argomento della mappa mentale",
    "wait_while_we_generate_topics": "Attendere mentre generiamo gli argomenti basati sul contesto del tuo progetto",
    "your_custom_topic": "Il tuo argomento personalizzato"
  }
}