{
  "billingInfo": {
    "select_project": "Seleziona progetto",
    "required": "Obbligatorio",
    "select_period": "Seleziona periodo",
    "your_current_package": "Il tuo pacchetto attuale",
    "cancelled_at": "cancellato il",
    "next_billing_date": "prossima data di fatturazione",
    "to_cancel_or_update_your_payment_plan": "Per annullare o aggiornare il tuo piano di pagamento, vai al Portale di pagamento del cliente.",
    "customer_payment_portal": "Portale di pagamento del cliente",
    "cancel_subscription": "Annulla abbonamento",
    "remove_your_account": "Rimuovi il tuo account",
    "remove_account": "Rimuovi account",
    "reactivate_account": "Riattiva account",
    "no_package_found": "Nessun pacchetto trovato.",
    "let_s_select_a_suitable_package": "Selezioniamo un pacchetto adatto.",
    "select_your_package": "Seleziona il tuo pacchetto",
    "the_account_will_deleted_at": "L'account verrà eliminato il",
    "cancel_subscription_and_remove_account": "Annulla abbonamento e rimuovi account",
    "the_subscription_will_cancelled_at": "L'abbonamento verrà annullato il",
    "cancel_current_subscription": "Annulla abbonamento corrente",
    "something_went_awry": "Qualcosa è andato storto"
  },
  "billings": {
    "billing_details": "Dettagli di fatturazione",
    "activity_summary": "Riepilogo attività"
  },
  "billingsActivity": {
    "purchase_history": "Cronologia acquisti",
    "request_history": "Cronologia richieste",
    "request_analytics": "Analisi"
  },
  "billingsActivityPurchase": {
    "no_data_so_far": "Nessun dato finora.",
    "download_invoice": "Scarica fattura",
    "date": "Data",
    "amount": "Importo",
    "package": "Pacchetto",
    "status": "Stato",
    "invoice": "Fattura"
  },
  "billingsActivityRequest": {
    "select_project": "Seleziona progetto",
    "required": "Obbligatorio",
    "select_period": "Seleziona periodo",
    "apply": "Applica",
    "no_data_so_far": "Nessun dato finora.",
    "created": "Creato",
    "entity": "Entità",
    "cost": "Costo"
  },
  "billingsCurrentPackage": {
    "current_package": "Pacchetto attuale",
    "click_here_to_see_package_info": "Clicca qui per vedere le informazioni sul pacchetto",
    "cancelled_at": "cancellato il",
    "deleted_at": "eliminato il",
    "trial_ends_at": "prova scade il",
    "next_billing_date": "prossima data di fatturazione",
    "change_package": "Cambia pacchetto",
    "add_package": "Aggiungi pacchetto",
    "cancel_subscription": "Annulla abbonamento",
    "the_subscription_will_cancelled_at": "L'abbonamento verrà annullato il",
    "cancel_current_subscription": "Annulla abbonamento corrente",
    "the_subscription_will_delete_at": "L'abbonamento verrà eliminato il",
    "something_went_wrong": "Qualcosa è andato storto!"
  },
  "billingsPackageInfo": {
    "package_information": "Informazioni sul pacchetto",
    "marketplace": "Mercato",
    "learning_data": "Dati di apprendimento",
    "requests": "Richieste",
    "on_average_one_request_contains": "In media una richiesta contiene",
    "characters": "caratteri",
    "no_data_so_far": "Nessun dato finora"
  },
  "billingsPackageInfoItem": {
    "upgrade_to_add_more": "Aggiorna per aggiungere altro"
  },
  "billingsUpgradePackageModal": {
    "expand_package": "Espandi pacchetto"
  },
  "chart": {
    "consumption_graph": "Grafico di consumo"
  },
  "claimOffer": {
    "fetching_a_discount_data": "Recupero dati sconto...",
    "claiming_the_offer": "Richiesta dell'offerta...",
    "are_you_considering_our": "Stai considerando il nostro",
    "discount_the": "sconto? Il",
    "month_plan_is_now_just": "piano mensile è ora solo",
    "month": "mese",
    "think_it_over": "Pensaci!"
  },
  "finalStep": {
    "your_subscription_has_been_successfully": "La tua sottoscrizione è stata annullata con successo. Speriamo di rivederti presto. Puoi comunque utilizzare il nostro servizio gratuitamente.",
    "the_account_will_be_active": "L'account sarà attivo fino alla fine del periodo di fatturazione.",
    "you_have_got_the_discount_now": "Hai ottenuto lo sconto! Siamo felici che tu sia rimasto con noi!"
  },
  "billingHelpers": {
    "request": "Richiesta",
    "requests": "Richieste",
    "learning_data_characters": "Dati di apprendimento, caratteri",
    "package_name": "Nome del pacchetto",
    "projects": "Progetti",
    "content_uploads": "Caricamenti di contenuti",
    "widgets": "Widget",
    "scheduled_content_updates": "Aggiornamenti programmati dei contenuti",
    "alternative_ai_models": "Modelli AI alternativi",
    "api_access": "Accesso API",
    "chrome_extension": "Estensione Chrome",
    "extended_customizations": "Personalizzazioni estese"
  },
  "keepAi": {
    "hold_on": "Aspetta!",
    "we_just_wanted_to_let_you_know": "Volevamo solo farti sapere che OmniMind.AI ti ha risparmiato",
    "hour_of_work_so_far": "ora di lavoro finora.",
    "our_support_team_can_help_you": "Il nostro team di supporto può aiutarti con qualsiasi problema tu stia affrontando.",
    "chat_with_live_support": "Chatta con il supporto live"
  },
  "keepPlan": {
    "keep_plan_we_are_sorry_to_see_you_go": "Mantieni il piano, ci dispiace vederti andare!",
    "cancel_your_subscription": "Sei sicuro di voler annullare la tua sottoscrizione?",
    "cancel_your_current_subscription": "Sei sicuro di voler annullare la tua sottoscrizione corrente e rimuovere il tuo account?",
    "please_let_us_know_cancel_your_subscription": "Per favore, facci sapere il motivo per cui vuoi annullare la tua sottoscrizione.",
    "reason_you_want_to_remove_the_account": "Per favore, facci sapere il motivo per cui vuoi rimuovere l'account. Dopo aver confermato l'azione, la sottoscrizione verrà annullata e l'account sarà attivo fino alla fine del periodo di fatturazione. Quando il periodo di fatturazione sarà terminato, rimuoveremo definitivamente tutti i dati del tuo progetto e il tuo account.",
    "select_a_reason": "Seleziona un motivo",
    "other_reason": "Altro motivo"
  },
  "keepPlanModal": {
    "MissingFeatures": "Funzionalità mancanti",
    "TechnicalIssues": "Problemi tecnici",
    "Havenovalue": "Non ha valore",
    "Tooexpensive": "Troppo costoso",
    "Otherreason": "Altro motivo"
  },
  "packageInfoModal": {
    "package_information": "Informazioni sul pacchetto"
  },
  "purchasedList": {
    "no_available_data_try_select_date": "Nessun dato disponibile, prova a selezionare una data...",
    "no_data_for_the_selected_period": "Nessun dato per il periodo selezionato",
    "created": "Creato",
    "entity": "Entità",
    "quantity": "Quantità"
  },
  "subscriptionModal": {
    "cancel": "Annulla",
    "remove": "Rimuovi",
    "removing": "Rimozione",
    "plan": "Piano",
    "account": "Account",
    "work_time_optimization": "Ottimizzazione del tempo di lavoro",
    "keep": "Mantieni",
    "discount_offer": "Offerta sconto",
    "claim_offer": "Richiedi offerta",
    "cancellation": "Annullamento",
    "confirmation": "Conferma",
    "keep_value": "Mantieni {value}",
    "final_step": "Passaggio finale",
    "confirm": "Conferma",
    "continue_to": "Continua a",
    "something_went_awry": "Qualcosa è andato storto!"
  }
}