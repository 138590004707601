{
  "foundBug": {
    "found_a_bug": "Encontrou um bug?",
    "found_a_bug_many": "Encontrou alguns bugs?",
    "found_a_bug_one": "Encontrou um bug?",
    "found_a_bug_other": "Encontrou {count} bugs?"
  },
  "whatsNew": {
    "whats_new": "O que há de novo?",
    "whats_new_many": "O que há de novo?",
    "whats_new_one": "O que há de novo?",
    "whats_new_other": "O que há de novo?"
  },
  "userHeader": {
    "profile": "Perfil",
    "billing": "Faturamento"
  }
}