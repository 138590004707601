{
  "useWebPreview": {
    "do_you_see_relevant_content_from_url": "Чи бачите ви відповідний вміст з URL? Якщо ні, спробуйте альтернативний спосіб отримання.",
    "could_not_get_the_preview": "Не вдалося отримати попередній перегляд. Спробуйте інший метод.",
    "alternative_fetch": "Альтернативне отримання",
    "we_tried_to_fetch_the_content_as_it_would_be": "Ми спробували отримати вміст так, як це робить браузер Chrome. Якщо ви все ще не задоволені, спробуйте натиснути \"Альтернативний текст\".",
    "alternative_text": "Альтернативний текст",
    "we_have_demonstrated_all_the_ways": "Ми продемонстрували всі способи отримання вмісту. Хочете повернутися до початкового методу й попереднього перегляду?",
    "back_to_origin": "Повернутися до початку"
  },
  "useMaximumNumberOfProjectsAlert": {
    "limit_error": "Схоже, ви досягли максимальної кількості проектів. Щоб створити новий проект, розгляньте можливість архівування або видалення існуючих проектів, які вам більше не потрібні, або ознайомтеся з нашими підписними планами для отримання більш високих обмежень.",
    "upgrade": "Оновити"
  },
  "Preview": "Попередній перегляд"
}