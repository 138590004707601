{
  "basicModeSelectWidget": {
    "need_another": "Hai bisogno di un altro",
    "data_source": "fonte dati",
    "view": "visualizza",
    "have_a_specific": "Hai in mente una specifica {feature} che potrebbe migliorare la tua esperienza? Richiedila come funzionalità e lavoreremo per integrarla.",
    "sorry_we_could_not": "Spiacenti, non siamo riusciti a trovare risultati corrispondenti. Se hai in mente una specifica {feature}, puoi richiederla come funzionalità e lavoreremo per integrarla per te.",
    "request_a_new": "Richiedi una nuova {feature}"
  },
  "basicModeWidgetCard": {
    "coming_soon": "PRESTO DISPONIBILE",
    "beta": "BETA",
    "popular": "POPOLARE",
    "we_understand_you": "Sappiamo che sei interessato a questa funzionalità. È disponibile nei nostri piani di fascia superiore. Valuta l'aggiornamento per accedervi!",
    "add_another_widget": "Aggiungi un altro widget",
    "expand_your_dashboard_by_adding_a_new_widget": "Espandi il tuo cruscotto aggiungendo un nuovo widget."
  },
  "basicModeWidgetFilters": {
    "informers": "Informatori",
    "all": "Tutti",
    "widgets": "Widget",
    "search": "Cerca...",
    "all_informers": "Tutti gli informatori",
    "popular": "Popolari",
    "beta": "Beta",
    "embed": "Incorpora",
    "integrations": "Integrazioni"
  }
}