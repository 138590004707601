{
  "foundBug": {
    "found_a_bug": "Found a bug?"
  },
  "whatsNew": {
    "whats_new": "What’s new?"
  },
  "userHeader": {
    "profile": "Profile",
    "billing" : "Billing"
  }
}
