{
  "airtableInformer": {
    "airtable_informer": "Airtable",
    "learn": "Aprender"
  },
  "audioInformer": {
    "audio_informer": "Áudio",
    "learn": "Aprender"
  },
  "baseInformer": {
    "learn": "Aprender"
  },
  "bulkcsvInformer": {
    "csv_links_informer": "Links CSV",
    "learn": "Aprender"
  },
  "chromeextensionInformer": {
    "chrome_extension_informer": "Extensão do Chrome",
    "select_project": "Selecionar projeto",
    "how_to_add_extension": "Como adicionar a Extensão do Chrome e começar a usá-la.",
    "visit_the_chrome_web_store": "Visite a Chrome Web Store e procure por",
    "extension": "Extensão",
    "click": "Clique",
    "add_to_chrome": "Adicionar ao Chrome",
    "authorize_yourself_in_the_extension": "Autorize-se na extensão, pressione Entrar",
    "if_you_dont_have_any_existing_projects": "Se você não tiver nenhum projeto existente, você pode criar um. Clique no link fornecido para OmniMind e crie seu projeto.",
    "to_add_the_entire_web_page": "Para adicionar a página da web inteira, selecione o projeto desejado e clique no botão \"Processar\" na extensão",
    "to_add_selected_text": "Para adicionar o texto selecionado, destaque o texto na página da web, clique com o botão direito e escolha \"Adicionar texto selecionado ao projeto OmniMind\" no menu de contexto.",
    "the_content_you_added_will_be_stored": "O conteúdo que você adicionou será armazenado no projeto escolhido. Agora você pode usar essas informações ao interagir com o modelo de IA."
  },
  "confluenceInformer": {
    "confluence_informer": "Confluence",
    "learn": "Aprender"
  },
  "csvInformer": {
    "csv_informer": "CSV",
    "learn": "Aprender"
  },
  "excelInformer": {
    "excel_informer": "Excel",
    "learn": "Aprender"
  },
  "fileInformer": {
    "file_informer": "Arquivo",
    "learn": "Aprender"
  },
  "googleSheetsInformer": {
    "google_sheets_informer": "Planilhas Google",
    "learn": "Aprender"
  },
  "notionInformer": {
    "notion_informer": "Notion",
    "learn": "Aprender"
  },
  "pdfOcrInformer": {
    "pdf_ocr_informer": "PDF",
    "learn": "Aprender"
  },
  "singleWebInformer": {
    "single_web_informer": "Web Única",
    "learn": "Aprender"
  },
  "slackIntegrationIndex": {
    "name": "nome",
    "select_project": "Selecionar projeto",
    "how_to_add_omnimind_app": "Como adicionar o aplicativo Omnimind ao Slack Workspace",
    "start_by_copying_your_omnimind_api_key": "Comece copiando sua chave de API Omnimind. Você precisará dela para conectar o Omnimind ao Slack.",
    "make_sure_your_question_is": "Certifique-se de que sua pergunta seja clara e concisa para obter as respostas mais precisas. Você pode atualizar ou alterar o projeto nas mensagens diretas do bot se desejar usar dados diferentes para análise.",
    "copy_your_project_id": "Copie seu ID de Projeto",
    "project_id_was_copied": "O ID do projeto foi copiado!",
    "add_omnimind_ai_to_your_slack_workspace": "Adicione a IA Omnimind ao seu Slack Workspace",
    "configure_your_omnimind_app": "Configure seu aplicativo Omnimind definindo as credenciais",
    "click_connect_to_omnimind_button": "Clique no botão \"Conectar ao Omnimind\"",
    "paste_your_api_key_and_project_id": "Cole sua chave de API e ID do Projeto",
    "start_asking_your_question": "Comece a fazer sua pergunta!"
  },
  "vimeoInformer": {
    "vimeo_informer": "Vimeo",
    "learn": "Aprender"
  },
  "webInformer": {
    "multiple_web_informer": "Web Múltipla",
    "learn": "Aprender"
  },
  "whatsappIntegration": {
    "name": "nome",
    "select_project": "Selecionar projeto"
  },
  "wordpressInformer": {
    "wordpress_informer": "Wordpress",
    "learn": "Aprender"
  },
  "youtubeInformer": {
    "youtube_informer": "Youtube",
    "learn": "Aprender"
  },
  "zapierWidget": {
    "name": "nome",
    "select_project": "Selecionar projeto",
    "how_to_add_omnimind_app": "Como adicionar o aplicativo Omnimind ao Zapier",
    "start_by_copying_your_omnimind_api_key": "Comece copiando sua chave de API Omnimind. Você precisará dela para conectar o Omnimind ao Zapier.",
    "make_sure_your_question_is_clear_and_concise": "Certifique-se de que sua pergunta seja clara e concisa para obter as respostas mais precisas. Você pode atualizar ou alterar o projeto nas configurações da ação se desejar usar dados diferentes para análise.",
    "go_to_the_zapier_application_where_you_d_like_to": "Vá para o aplicativo Zapier onde você gostaria de usar o Omnimind.",
    "create_a_new_action_step": "Crie uma nova etapa de ação e procure pela integração Omnimind.",
    "in_the_omnimind_app": "No aplicativo Omnimind, selecione a ação chamada \"Omni Ask\".",
    "log_into_your_omnimind_account": "Faça login na sua conta Omnimind usando a chave de API que você copiou anteriormente.",
    "in_the_action_tab": "Na guia Ação, forneça as informações necessárias.",
    "from_the_project_dropdown": "No menu suspenso Projeto, escolha o projeto que você deseja conectar.",
    "enter_the_question_you_d_like_to": "Digite a pergunta que você gostaria de enviar para o Omnimind.",
    "before_finalizing": "Antes de finalizar, teste sua ação para garantir que tudo funcione corretamente.",
    "in_zapier": "No Zapier, selecione o campo \"Resposta\" do aplicativo Omnimind. Você pode usar essa resposta em suas etapas subsequentes."
  }
}