{
  "quizWidget": {
    "delete_this_question": "Diese Frage löschen?",
    "are_you_sure_you_want_to_delete": "Sind Sie sicher, dass Sie diese Frage aus dem Quiz löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    "delete_this_quiz": "Dieses Quiz löschen?",
    "are_you_sure_you_want_to_delete_the_quiz": "Sind Sie sicher, dass Sie das gesamte Quiz löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden.",
    "export_to_quizlet": "Exportieren nach Quizlet",
    "use_pattern": "Muster verwenden",
    "the_quiz_should_test_general_knowledge": "Das Quiz sollte Allgemeinwissen, kritisches Denken und Verständnis testen. Die Frage muss eine Lücke zum Ausfüllen haben.",
    "can_be_more_than_one_correct": "Es können mehrere richtige Optionen sein",
    "each_question_should_focus": "4) Jede Frage sollte sich auf ein bestimmtes Thema oder einen bestimmten Bereich konzentrieren. 5) Jede Frage sollte MINDESTENS 4 PAARE VON ELEMENTEN für das Zuordnen haben. 6) Die Struktur des JSON sollte ein Array von Objekten sein, wobei jedes Objekt eine Frage mit einem 'question'-Feld und einem 'correct'-Feld darstellt. Das 'correct'-Feld sollte selbst ein Objekt sein, das Schlüssel-Wert-Paare enthält, die abgeglichen werden müssen. 7) Der Inhalt sollte lehrreich sein und auf sachlichen Informationen oder etablierten Konzepten basieren. 8) Verwenden Sie das folgende JSON-Muster für jede Frage:",
    "the_quiz_should_cover": "Das Quiz sollte verschiedene Themen oder verschiedene Aspekte desselben Themas abdecken.",
    "question_task_arrange_items": "Aufgabe der Frage: Elemente oder Ereignisse in der richtigen Reihenfolge anordnen. Mindestens 4 Optionen in der \"correct\"-Reihe in der richtigen Reihenfolge platzieren",
    "given_the_context": "Angesichts des { context } generieren Sie bitte ein Quiz IN",
    "language_in_a_valid_json_format": "Sprache in einem GÜLTIGEN JSON-FORMAT. Das Quiz sollte folgende Merkmale aufweisen: 1) Fragetyp:",
    "number_of_questions": "Anzahl der Fragen",
    "difficulty_level": "Schwierigkeitsgrad",
    "this_time_only": "Dieses Mal nur {newQuestionsLength} Fragen. Möglicherweise nicht genügend Inhalt.",
    "something_didn_t_go_as_planned": "Etwas ist schiefgelaufen.",
    "failed_to_get_the_pdf_file": "Fehler beim Abrufen der PDF-Datei."
  },
  "quizWidgetAddCustomQuestionModal": {
    "create_custom_question": "Benutzerdefinierte Frage erstellen",
    "question_type": "Fragetyp",
    "difficulty": "Schwierigkeitsgrad"
  },
  "quizWidgetDeleteModal": {
    "delete_this_question": "Diese Frage löschen?",
    "are_you_sure_you_want_to_delete": "Sind Sie sicher, dass Sie diese Frage aus dem Quiz löschen möchten? Diese Aktion kann nicht rückgängig gemacht werden."
  },
  "quizWidgetExportModal": {
    "export_configuration": "Exportkonfiguration",
    "decide_what_should_be_included": "Entscheiden Sie, was in Ihren Quizexport aufgenommen werden soll",
    "show_numeration": "Nummerierung anzeigen",
    "show_answers": "Antworten anzeigen",
    "show_explanation": "Erklärung anzeigen",
    "show_instructions": "Anweisungen anzeigen",
    "show_difficulty": "Schwierigkeitsgrad anzeigen",
    "show_images": "Bilder anzeigen"
  },
  "quizWidgetForm": {
    "question_type": "Fragetyp",
    "language": "Sprache",
    "difficulty": "Schwierigkeitsgrad",
    "max_questions": "Maximale Anzahl von Fragen",
    "add_more": "Weitere hinzufügen",
    "generate": "Generieren",
    "original": "Original"
  },
  "quizWidgetListFooter": {
    "hide": "Ausblenden",
    "show": "Anzeigen",
    "answers": "Antworten",
    "create_custom_question": "Benutzerdefinierte Frage erstellen",
    "clear_quiz": "Quiz löschen",
    "export": "Exportieren"
  },
  "quizWidgetListHeader": {
    "quiz": "Quiz",
    "questions": "Fragen"
  },
  "quizWidgetQuestionCardFooter": {
    "change_difficulty": "Hier klicken, um den Schwierigkeitsgrad zu ändern"
  }
}