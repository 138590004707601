import gql from 'graphql-tag';

export const ADD_KNOWLEDGE = gql`
  mutation addKnowledge(
    $project_id: uuid!
    $url: String
    $settings: jsonb
    $title: String
    $widget_type_id: Int
  ) {
    insert_knowledge_one(
      object: {
        project_id: $project_id
        url: $url
        settings: $settings
        title: $title
        widget_type_id: $widget_type_id
      }
    ) {
      id
    }
  }
`;
