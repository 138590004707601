{
  "dialogs": {
    "user_info": "Benutzerinformationen",
    "select_all_dialogs": "Alle Dialoge auswählen",
    "search": "Suchen...",
    "deletion_of_the_selected_dialogs": "Löschen der ausgewählten Dialoge",
    "there_are_no_dialogs_yet": "Es gibt noch keine Dialoge",
    "knowledge": "Wissen",
    "delete": "Löschen",
    "something_went_wrong": "Beim Löschen ist ein Fehler aufgetreten",
    "question_and_answer_were_saved_successfully": "Frage und Antwort wurden erfolgreich gespeichert!",
    "an_error_occurred_while_saving": "Beim Speichern der Frage und Antwort ist ein Fehler aufgetreten!",
    "upgrade_plan": "Um diese Funktion nutzen zu können, bitte aktualisieren Sie Ihren Plan",
    "anonym": "Anonym",
    "no_tracking": "Die Nachrichtenverfolgung ist derzeit deaktiviert. Bitte aktivieren Sie sie in den Einstellungen, um fortzufahren.",
    "not_defined": "Nicht definiert",
    "browser": "Browser",
    "os": "Betriebssystem",
    "more": "mehr",
    "less": "weniger"
  },
  "dialogsFilters": {
    "filters": "Filter",
    "by_period": "Nach Zeitraum",
    "by_negative": "Negative Rückmeldungen",
    "by_calls": "Operatoranrufe",
    "by_offline": "Offline-Nachrichten",
    "apply": "Anwenden",
    "cancel": "Abbrechen"
  }
}