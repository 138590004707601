{
  "projectSettingsTeam": {
    "user_access_rights_successfully_updated": "Direitos de acesso do usuário atualizados com sucesso!",
    "something_went_awry": "Algo deu errado!",
    "user_with_email": "Usuário com o email",
    "has_been_successfully_invited_to_you_project": "foi convidado com sucesso para o seu projeto!",
    "already_exists": "já existe"
  },
  "teamInvite": {
    "enter_email_to_invite_people_to_this_project": "Digite o email para convidar pessoas para este projeto",
    "email": "Email",
    "send_invite": "Enviar convite",
    "can_view": "Pode visualizar",
    "can_edit": "Pode editar"
  },
  "teamLink": {
    "project_link": "Link do projeto",
    "copy_link": "Copiar link",
    "please_note_that_any_activity": "Por favor, observe que qualquer atividade dos usuários com quem você compartilhar este link será vinculada ao saldo da sua conta.",
    "url_link_copied_just_paste_the_link": "Link da URL copiado! Basta colar o link para qualquer pessoa com quem você deseja compartilhar o projeto"
  },
  "teamsList": {
    "members": "Membros"
  },
  "teamsListItem": {
    "can_view": "pode visualizar",
    "can_edit": "pode editar",
    "remove": "remover",
    "you": "(você)",
    "owner": "proprietário",
    "pending": "(pendente)"
  }
}