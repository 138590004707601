{
  "pricingItem": {
    "month": "місяць",
    "up_to_todo_calculate_pricing_info": "До 'TODO: Розрахувати інформацію про ціни'",
    "trial_days": "днів пробного періоду",
    "this_includes:": "Це включає",
    "subscribe:": "Підписатися"
  },
  "pricingTable": {
    "subscriptions": "Підписки",
    "to_access_the_widget_you_need": "Для доступу до віджета вам потрібно вибрати один з запропонованих планів підписки",
    "oops": "Ой!",
    "there_no_available_packages": "Для цього посилання немає доступних пакетів"
  }
}