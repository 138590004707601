{
  "validations": {
    "required": "This field is required",
    "isOldPasswordCorrect": "Please enter a valid old password",
    "email": "Please enter a valid email address",
    "url": "Please enter a valid URL",
    "URL": "Please enter a valid URL",
    "min": "Please enter at least {min} characters",
    "integer": "Please enter an integer value",
    "minValue": "The value must be at least {min}",
    "maxValue": "The value must be at least {max}",
    "max": "Please enter no more than {max} characters",
    "minLength": "Please enter at least {min} characters",
    "maxLength": "Please enter no more than {max} characters",
    "requiredIf": "This field is required",
    "sameAs": "The value must be equal to the other value"
  },
  "promocode": {
    "the_promocode_was_successfully_redeemed": "The promocode was successfully redeemed. For more details, please check your billing stats",
    "unable_to_redeem_the_promo_code": "Unable to redeem the promo code. Please verify the code for any typos and try again, or contact support for assistance.",
    "request": "Request",
    "requests": "Requests",
    "learning_data_characters": "Learning data, characters",
    "package_name": "Package name",
    "projects": "Projects",
    "content_uploads": "Content Uploads",
    "widgets": "Widgets",
    "dialogs": "Dialogs",
    "informers": "Informers",
    "scheduled_content_updates": "Scheduled content updates",
    "alternative_ai_models": "Alternative AI models",
    "api_access": "API access",
    "chrome_extension": "Chrome extension",
    "extended_customizations": "Extended customizations",
    "file_extensions": "File extensions"
  }
}
