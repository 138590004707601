{
  "voiceWidget": {
    "ooops_we_have_t_found": "404 Ooops, wir haben das Projekt nicht gefunden",
    "ooops_we_have_t_found_one": "404 Ooops, wir haben das Projekt nicht gefunden",
    "ooops_we_have_t_found_other": "404 Ooops, wir haben das Projekt nicht gefunden",
    "oops_it_seems_youve_reached": "Oops! Es scheint, dass Sie Ihr Nachrichtenlimit erreicht haben. Um fortzufahren, passen Sie bitte Ihr Limit in den Einstellungen an.",
    "oops_it_seems_youve_reached_one": "Oops! Es scheint, dass Sie Ihr Nachrichtenlimit erreicht haben. Um fortzufahren, passen Sie bitte Ihr Limit in den Einstellungen an.",
    "oops_it_seems_youve_reached_other": "Oops! Es scheint, dass Sie Ihr Nachrichtenlimit erreicht haben. Um fortzufahren, passen Sie bitte Ihr Limit in den Einstellungen an.",
    "please_ask_the_owner_of": "401 Bitte fragen Sie den Besitzer des Projekts, es öffentlich zu machen",
    "please_ask_the_owner_of_one": "401 Bitte fragen Sie den Besitzer des Projekts, es öffentlich zu machen",
    "please_ask_the_owner_of_other": "401 Bitte fragen Sie den Besitzer des Projekts, es öffentlich zu machen"
  }
}