import type { IAppEventProvider } from '@/analytics/IAppEventProvider';

export class AppEventProcessor {
  private providers: IAppEventProvider[];

  constructor(providers: IAppEventProvider[]) {
    this.providers = providers;
  }

  async init(): Promise<void> {
    await Promise.all(this.providers.map((provider) => provider.init()));
  }

  event(eventName: string, data: Record<string, any>): void {
    this.providers.forEach((provider) => {
      const eventHandler = provider[eventName as keyof IAppEventProvider] as unknown as (
        data: Record<string, any>,
      ) => void;
      if (typeof eventHandler === 'function') {
        eventHandler(data);
      }
    });
  }
}
