{
  "vimeoInformerInformers": {
    "you_can_provide_your_own_vimeo_token": "Puoi fornire il tuo token Vimeo nelle impostazioni delle credenziali dei servizi.",
    "click_here": "Clicca qui!",
    "please_provide_a_valid_vimeo_video_url": "Per favore, fornisci un URL video Vimeo valido",
    "this_video_has_no_text_tracks": "Questo video non ha tracce di testo!",
    "something_went_awry": "Qualcosa è andato storto!"
  },
  "vimeoInformerFormInformers": {
    "insert_vimeo_video_url": "Inserisci l'URL del video Vimeo",
    "fetch_subtitles": "Recupera sottotitoli"
  },
  "vimeoInformerListInformers": {
    "available_tracks": "Tracce disponibili"
  }
}