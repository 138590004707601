{
  "tableDialogSQL": {
    "the_sql_query_for_your_prompt": "The SQL query for your prompt"
  },
  "tableWidget": {
    "ask_your_questions_to_get_a_data": "Ask a question",
    "show_sql": "Show SQL",
    "export_to_csv": "Export to CSV",
    "failed_to_load_settings": "Failed to load settings",
    "something_went_wrong": "Something went wrong"
  },
  "tableWidgetTable": {
    "no_data": "No data"
  }
}