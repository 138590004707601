{
  "billingOverview": {
    "billing_overview": "Panoramica della fatturazione",
    "expenses": "Spese"
  },
  "billingOverviewItem": {
    "used": "Utilizzato",
    "remaining": "rimanente",
    "used_out_of": "Utilizzato {formattedCurrent_value} su {formattedTotal_value}.",
    "money": "Denaro"
  },
  "cardList": {
    "card_details": "Dettagli carta",
    "expires_on": "Scade il",
    "primary": "Primaria"
  },
  "paymentHistory": {
    "payment_history": "Storico pagamenti",
    "view_invoice": "Visualizza fattura",
    "download_invoice": "Scarica fattura",
    "march": "Marzo",
    "february": "Febbraio",
    "january": "Gennaio",
    "pro_membership": "Abbonamento Pro"
  },
  "projectList": {
    "projects": "Progetti"
  },
  "proPlanInfo": {
    "pro_plan": "Piano Pro",
    "renew_now": "Rinnova ora",
    "monthly_visitors": "{quantity} Visitatori mensili",
    "unlimited_reports": "Report illimitati",
    "years_data_storage": "{quantity} Anni di archiviazione dati",
    "days_left": "Giorni rimanenti",
    "month": "mese"
  },
  "userInfo": {
    "profile": "Profilo",
    "no_company_name": "nessun nome azienda",
    "no_phone_number": "nessun numero di telefono",
    "no_timezone": "nessuna zona oraria",
    "remove_your_account": "Rimuovi il tuo account"
  }
}