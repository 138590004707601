{
  "SearchWidget_name": "Pesquisar",
  "SearchWidget_description": "Comunicação como na barra de pesquisa do navegador. Faça perguntas e veja o histórico de respostas.",
  "ExplainWidget_name": "Explicar",
  "ExplainWidget_description": "Comunicação para respostas mais detalhadas. Pergunta - Resposta.",
  "ChatWidget_name": "Chat",
  "ChatWidget_description": "Comunicação no formato usual como em um mensageiro. Curto e claro.",
  "BaseInformer_xml-sitemap_name": "Mapa do site XML",
  "BaseInformer_xml-sitemap_description": "Você pode adicionar um mapa do site XML para análise do site",
  "PopupChatWidget_name": "Chat Pop-up",
  "PopupChatWidget_description": "Widget interativo para respostas instantâneas. Faça perguntas, obtenha respostas.",
  "CSVInformer_name": "Texto CSV",
  "CSVInformer_description": "Você pode fazer upload do seu arquivo .csv com mapeamento personalizado de colunas",
  "WebInformer_name": "Vários Websites",
  "WebInformer_description": "Você pode definir um lote de URLs de sites para processamento",
  "FileInformer_name": "Upload de Arquivo",
  "FileInformer_description": "Você pode selecionar seu arquivo local para processamento",
  "BaseInformer_url_name": "Arquivo de URL",
  "BaseInformer_url_description": "Você pode definir o caminho do URL para fazer upload do arquivo da internet",
  "YoutubeInformer_name": "Youtube",
  "YoutubeInformer_description": "Você pode adicionar legendas de vídeo do Youtube",
  "BaseInformer_text_name": "Texto",
  "BaseInformer_text_description": "Simplesmente adicione texto",
  "BulkCSVInformer_name": "Links CSV",
  "BulkCSVInformer_description": "Upload em massa via CSV com URLs",
  "BaseInformer_dropbox-file_name": "Arquivo do Dropbox",
  "BaseInformer_dropbox-file_description": "Adicione o link para o arquivo do Dropbox",
  "BaseInformer_dropbox-folder_name": "Pasta do Dropbox",
  "BaseInformer_dropbox-folder_description": "Adicione o link para a pasta do Dropbox",
  "BaseInformer_gdrive-file_name": "Arquivo do GDrive",
  "BaseInformer_gdrive-file_description": "Adicione o link para o arquivo do GDrive",
  "BaseInformer_gdrive-folder_name": "Pasta do GDrive",
  "BaseInformer_gdrive-folder_description": "Adicione o link para a pasta do GDrive",
  "DocSearchWidget_name": "Pesquisa de Documentos",
  "DocSearchWidget_description": "Permite instalar um widget pop-up para pesquisar a documentação",
  "RetellingWidget_name": "Recontagem",
  "RetellingWidget_description": "Ferramenta interativa de recontagem para insights da sua base de conhecimento.",
  "VimeoInformer_name": "Vimeo",
  "VimeoInformer_description": "Você pode adicionar legendas de vídeo do Vimeo",
  "ConfluenceInformer_name": "Confluence",
  "ConfluenceInformer_description": "Você pode adicionar sua base de conhecimento do Confluence",
  "BaseInformer_zendesk_name": "Zendesk",
  "BaseInformer_zendesk_description": "Você pode definir a URL do centro de ajuda do Zendesk para processamento.",
  "BaseInformer_youtube-channel_name": "Canal do Youtube",
  "BaseInformer_youtube-channel_description": "Você pode definir a URL do canal do Youtube para processamento.",
  "BaseInformer_youtube-playlist_name": "Playlist do Youtube",
  "BaseInformer_youtube-playlist_description": "Você pode definir a URL da playlist do Youtube para processamento.",
  "NotionInformer_name": "Notion",
  "NotionInformer_description": "Você pode adicionar páginas ou bancos de dados do Notion",
  "InteractiveMindmapWidget_name": "Mapa Mental Interativo",
  "InteractiveMindmapWidget_description": "Crie e personalize mapas mentais hierárquicos.",
  "MermaidMindmapWidget_name": "Mapa Mental Mermaid",
  "MermaidMindmapWidget_description": "Crie e personalize mapas mentais hierárquicos.",
  "WordpressInformer_name": "Wordpress",
  "WordpressInformer_description": "Você pode adicionar um site do Wordpress",
  "GoogleSheetsAddOn_name": "Complemento do Google Sheets",
  "GoogleSheetsAddOn_description": "Adicionando o complemento do Google Sheets da Omnimind AI às suas planilhas do Google",
  "ZapierWidget_name": "Integração Zapier",
  "ZapierWidget_description": "Adicionando a Omnimind AI à sua aplicação Zapier",
  "ChromeExtensionInformer_name": "Extensão do Chrome",
  "ChromeExtensionInformer_description": "Faça upload das informações dos seus recursos usando nossa Extensão do Chrome.",
  "VoiceWidget_name": "Voz",
  "VoiceWidget_description": "Converse com seu bot para obter respostas como se estivesse conversando com um humano",
  "SingleWebInformer_name": "Website",
  "SingleWebInformer_description": "Você pode definir uma única URL do site para processamento",
  "DataExtractorWidget_name": "Extrator de Dados",
  "DataExtractorWidget_description": "Extrai informações de sites para coletar informações",
  "SlackIntegration_name": "Integração Slack",
  "SlackIntegration_description": "Adicionando a Omnimind AI ao seu espaço de trabalho do Slack",
  "WhatsAppIntegration_name": "Integração WhatsApp",
  "WhatsAppIntegration_description": "Comunicação no formato usual no WhatsApp.",
  "BaseInformer_onedrive-file_name": "Arquivo do OneDrive",
  "BaseInformer_onedrive-file_description": "Adicione o link para o arquivo do OneDrive",
  "BaseInformer_onedrive-folder_name": "Pasta do OneDrive",
  "BaseInformer_onedrive-folder_description": "Adicione o link para a pasta do OneDrive",
  "SummaryWidget_name": "Resumo",
  "SummaryWidget_description": "Transforme seus dados em resumos claros e concisos.",
  "QuizWidget_name": "Quiz",
  "QuizWidget_description": "Criação e exportação abrangente de quizzes",
  "TableWidget_name": "Tabela",
  "TableWidget_description": "Widget comum para exibir dados em uma tabela. Este widget está oculto na lista de widgets.",
  "AudioInformer_name": "Arquivo de Áudio",
  "AudioInformer_description": "Você pode selecionar seu arquivo de áudio local para processamento",
  "AirtableInformer_name": "Airtable",
  "AirtableInformer_description": "Você pode adicionar sua base de conhecimento do Airtable",
  "FlashcardsWidget_name": "Flashcards",
  "FlashcardsWidget_description": "Crie e use flashcards para aprendizado eficaz e testes de conhecimento",
  "ExcelInformer_name": "Excel",
  "ExcelInformer_description": "Você pode fazer upload do seu arquivo .xls ou .xlsx com mapeamento personalizado de colunas",
  "GoogleSheetsInformer_name": "Planilhas do Google",
  "GoogleSheetsInformer_description": "Você pode fazer upload das suas Planilhas do Google com mapeamento personalizado de colunas",
  "DatabaseInformer_name": "Insights de Banco de Dados",
  "DatabaseInformer_description": "Você pode fazer solicitações aos seus próprios bancos de dados por meio de linguagem legível por humanos.",
  "PdfOcrInformer_name": "PDF",
  "PdfOcrInformer_description": "Extrai todos os dados de PDFs e os adiciona aos seus recursos.",
  "BubbleIntegration_name": "Plugin Bubble.io",
  "BubbleIntegration_description": "Integre a OmniMind em suas soluções Bubble.",
  "NpmIntegration_name": "Pacote NPM",
  "NpmIntegration_description": "Adicionando o pacote Npm da Omnimind AI aos seus projetos",
  "MicrosoftTeamsIntegration_name": "Integração Microsoft Teams",
  "MicrosoftTeamsIntegration_description": "Integre sua base de conhecimento à comunicação corporativa com o Microsoft Teams e um de nossos chatbots e acelere seus processos operacionais."
}