import type { WidgetTypeInterface } from '@/components/basic-mode/interfaces/WidgetTypeInterface';
import type { PackageFeaturesInterface } from '@/interfaces/PackageFeaturesInterface';
import { UserPermissionEnum } from '@/interfaces/UserPermissionEnum';
import { storeToRefs } from 'pinia';

const userCanCreateProject = ({
  store,
  features,
}: {
  store: any;
  features: PackageFeaturesInterface;
}) => {
  const { projects } = storeToRefs(store);
  if (!projects.value?.length) return true;
  return projects.value.length < (features?.projects ? +features.projects : 0);
};

const userCanUseGpt4 = (features: PackageFeaturesInterface) => {
  return features?.gpt4;
};

const userCanUseInformers = ({ features }: { features: PackageFeaturesInterface }) => {
  return features?.informers?.split(',');
};

const userCanUseWidgets = (features: PackageFeaturesInterface) => {
  return features?.widgets?.split(',');
};

const userCanUseExtendedSettings = (features: PackageFeaturesInterface) => {
  return features?.ext_customise === true || String(features?.ext_customise) === 'true';
};

const userCanUseDialogs = (features: PackageFeaturesInterface) => {
  return features?.dialogs && features?.dialogs !== 'none';
};

const userCanSaveAllDialogs = (features: PackageFeaturesInterface) => {
  return features?.dialogs && features?.dialogs === 'all';
};
const userCanUseExtensions = (features: PackageFeaturesInterface) => {
  return features?.extensions?.split(',').map((extension) => `.${extension}`);
};

export const useUserCan = ({
  permission,
  features,
  store,
}: {
  permission: UserPermissionEnum;
  features: PackageFeaturesInterface;
  store?: unknown;
  informers?: WidgetTypeInterface[];
}): any => {
  if (permission === UserPermissionEnum.CanCreateProjects) {
    return userCanCreateProject({ store, features });
  }
  if (permission === UserPermissionEnum.CanUseGpt4) {
    return userCanUseGpt4(features);
  }
  if (permission === UserPermissionEnum.CanUseInformers) {
    return userCanUseInformers({ features });
  }
  if (permission === UserPermissionEnum.CanUseWidgets) {
    return userCanUseWidgets(features);
  }
  if (permission === UserPermissionEnum.CanUseExtendedSettings) {
    return userCanUseExtendedSettings(features);
  }
  if (permission === UserPermissionEnum.CanUseExtensions) {
    return userCanUseExtensions(features);
  }
  if (permission === UserPermissionEnum.CanUseDialogs) {
    return userCanUseDialogs(features);
  }
  if (permission === UserPermissionEnum.CanSaveAllDialogs) {
    return userCanSaveAllDialogs(features);
  }
};
