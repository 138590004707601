{
  "useWebPreview": {
    "do_you_see_relevant_content_from_url": "Do you see relevant content from URL? If not, try an alternative way to fetch.",
    "could_not_get_the_preview": "Could not get the preview. Please try another method.",
    "alternative_fetch": "Alternative fetch",
    "we_tried_to_fetch_the_content_as_it_would_be": "We tried to fetch the content as it would be Chrome browser. If you are still not satisfied, try to click \"Alternative text\".",
    "alternative_text": "Alternative text",
    "we_have_demonstrated_all_the_ways": "We have demonstrated all the ways we could fetch the content. Do you want to get back to the original method and preview it?",
    "back_to_origin": "Back to origin"
  },
  "useMaximumNumberOfProjectsAlert": {
    "limit_error": "It looks like you've reached your maximum number of projects. To start a new project, consider archiving or deleting existing ones you no longer need, or explore our subscription plans for higher limits.",
    "upgrade": "Upgrade"
  },
  "Preview": "Preview"
}