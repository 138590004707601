{
  "settingsIntegrations": {
    "integrations": "Integrationen",
    "please_note_that_any_activity_of_the_users": "Bitte beachten Sie, dass alle Aktivitäten der Benutzer, mit denen Sie diesen Link teilen, an Ihr Kontoguthaben gebunden sind.",
    "embed": "Einbetten",
    "messengers": "Messenger",
    "integration_confirmation": "Bestätigung der Integration",
    "please_note_that_any_activity": "Bitte beachten Sie, dass alle Aktivitäten der Benutzer, mit denen Sie diesen Link teilen, an Ihr Kontoguthaben gebunden sind.",
    "copy_link": "Link kopieren",
    "share_on": "Teilen auf",
    "widget_link_copied_to_clipboard": "Widget-Link in die Zwischenablage kopiert",
    "personal_shared": "persönlich / geteilt",
    "share": "Teilen",
    "on": "Aktivieren",
    "off": "Deaktivieren"
  }
}