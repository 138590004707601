{
  "widgetTypeIndex": {
    "let_s_upload_the_content": "Let's upload the content",
    "with": "With",
    "informers": "Informers",
    "you_can_upload_information": "you can upload information from a variety of sources, such as websites, PDF documents, even Google folders, and more. Remember, you can always return to this screen to load additional content into your project",
    "upload_information": "Upload information from a variety of sources, such as websites, PDF documents, even Google folders, and more. Remember, you can always return to this screen to load additional content into your project whenever you need",
    "whenever_you_need": "whenever you need",
    "enter_the_name_of_the_project": "Enter the name of the project",
    "enter_project_name": "Enter project name",
    "this_informer_is_not_available_yet": "This informer is not available yet.",
    "back": "Back"
  }
}
