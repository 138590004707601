import gql from 'graphql-tag';

export const UPDATE_PROJECT_SETTINGS = gql`
  mutation UpdateProjectSettings($id: uuid, $settings: json = "") {
    update_projects(where: { id: { _eq: $id } }, _set: { settings: $settings }) {
      returning {
        id
      }
    }
  }
`;
