import { useStorage } from '@vueuse/core';
import { nhost } from '@/lib/nhost';
import { generateApiKey, generateSignToken, GET_API_KEY, getApiKeys } from '@/api';
import getQueryParamFromEncodedUrl from '@helpers/getQueryParamFromEncodedUrl';
import { getIsPaidUser } from '@/helpers';
import { createApolloClient } from '@nhost/apollo';

const RESOURCES = Object.freeze({ SHOPIFY: 'shopify', GOOGLE_SHEETS: 'google-sheets' });

export const notifyExtension = async (path: string, next: Function) => {
  await nhost.auth.refreshSession();
  setTimeout(async () => {
    const refreshToken = await useStorage('nhostRefreshToken', '');
    const user = await nhost.auth.getUser();
    const resource = getQueryParamFromEncodedUrl(path, 'resource');
    const dataToSend = {
      refreshToken: refreshToken.value,
      user: user?.id,
    };

    const isPaid = await getIsPaidUser();

    if (!isPaid) {
      next('/profile?tab=packages');
      return;
    }

    if (resource === RESOURCES.SHOPIFY) {
      const tempToken = await generateSignToken();

      window.location.href = decodeURIComponent(path + `&token=${tempToken.res.data.refreshToken}`);
      localStorage.removeItem('externalRedirect');
      return true;
    }

    if (resource === RESOURCES.GOOGLE_SHEETS) {
      const apiKeys = await getApiKeys();
      let apiKey = '';

      const googleSheetApiKeyPresent = apiKeys?.res?.data?.find(
        (key: any) => key.name === 'google-sheets',
      );

      if (googleSheetApiKeyPresent) {
        const apolloClient = createApolloClient({
          nhost,
          headers: { Authorization: `Bearer ${nhost.auth.getAccessToken()}` },
        });
        const response = await apolloClient.query({
          query: GET_API_KEY,
          variables: { id: googleSheetApiKeyPresent.id },
        });

        apiKey = response.data?.user_api_keys?.[0]?.token;
      } else {
        const response: any = await generateApiKey('google-sheets');

        apiKey = response.res.data.apiToken;
      }

      window.opener.postMessage({ details: { apiKey } }, '*');
      localStorage.removeItem('externalRedirect');

      window.close();

      return true;
    }

    const event = new CustomEvent('dataEvent', { detail: dataToSend });
    await window.dispatchEvent(event);
    localStorage.removeItem('externalRedirect');
    window.location.href = decodeURIComponent(path);
    return true;
  }, 1500);
};
