{
  "ninjaIndicator": {
    "please_wait_while": "Будь ласка, зачекайте, поки ми обробляємо ваші дані. Це може зайняти близько хвилини.",
    "redirecting_to_your_project": "Перенаправлення на ваш проект...",
    "we_are_processing_your_information": "Ми обробляємо вашу інформацію..."
  },
  "progressCancelChain": {
    "cancel_all": "Скасувати все",
    "do_you_want_to_stop": "Ви хочете зупинити всі ланцюжки обробки?",
    "stop_processing": "Зупинити обробку?",
    "yes": "Так",
    "no": "Ні"
  },
  "progressNinjaLoader": {
    "please_wait_while_we_process": "Будь ласка, зачекайте, поки ми обробляємо ваші дані. Це може зайняти близько хвилини.",
    "redirecting_to_your_project": "Перенаправлення на ваш проект...",
    "we_are_processing_your_information": "Ми обробляємо ваші дані зараз, це може зайняти деякий час. Ви можете почати використовувати систему негайно, а процес буде продовжуватися в фоновому режимі.",
    "process_in_background": "Обробка в фоновому режимі"
  },
  "progressResourceItem": {
    "there_were_some_errors_during_processing": "Під час обробки сталося декілька помилок."
  },
  "progressResourceList": {
    "jobs_in_progress": "Завдання в процесі"
  }
}