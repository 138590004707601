{
  "widgetTypeIndex": {
    "let_s_upload_the_content": "Завантажте вміст",
    "with": "З",
    "informers": "Інформери",
    "you_can_upload_information": "Ви можете завантажити інформацію з різних джерел, таких як веб-сайти, PDF-документи, навіть папки Google та інше. Пам'ятайте, що ви завжди можете повернутися до цього екрану, щоб завантажити додатковий вміст у свій проект",
    "upload_information": "Завантажте інформацію з різних джерел, таких як веб-сайти, PDF-документи, навіть папки Google та інше. Пам'ятайте, що ви завжди можете повернутися до цього екрану, щоб завантажити додатковий вміст у свій проект, коли вам зручно",
    "whenever_you_need": "коли вам зручно",
    "enter_the_name_of_the_project": "Введіть назву проекту",
    "enter_project_name": "Введіть назву проекту",
    "this_informer_is_not_available_yet": "Цей інформатор ще не доступний.",
    "back": "Назад"
  }
}
