{
  "foundBug": {
    "found_a_bug": "¿Encontraste un error?",
    "found_a_bug_many": "¿Encontraste errores?",
    "found_a_bug_one": "¿Encontraste un error?"
  },
  "whatsNew": {
    "whats_new": "¿Qué hay de nuevo?",
    "whats_new_many": "¿Qué hay de nuevo?",
    "whats_new_one": "¿Qué hay de nuevo?"
  },
  "userHeader": {
    "profile": "Perfil",
    "billing": "Facturación"
  }
}