{
  "agent_page": {
    "agent_settings": "Impostazioni dell'agente",
    "select_model": "Seleziona modello",
    "prompt_editor": "Editor di Prompt",
    "prompt_library": "Biblioteca di Prompt"
  },
  "select_model": {
    "select_provider": "Seleziona fornitore",
    "select_model": "Seleziona modello"
  },
  "prompt_editor": {
    "write_your_instruction": "Scrivi la tua istruzione",
    "variables": "Variabili",
    "save_prompt": "Salva Prompt",
    "prompt_has_been_updated": "Il prompt è stato aggiornato!",
    "model_has_been_updated": "Il modello è stato aggiornato!",
    "prompt_view": "Visualizzazione prompt",
    "edit_prompt": "Modifica prompt",
    "cancel": "Annulla"
  },
  "prompt_library": {
    "no_save_prompts": "Nessun prompt salvato",
    "select_a_department_to_see_tasks": "Seleziona un dipartimento per vedere le attività"
  }
}