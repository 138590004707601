{
  "serviceCredentials": {
    "you_can_define_your_credentials": "Sie können Ihre Anmeldeinformationen definieren und Ihre eigenen Dienste verwenden, um Informationen zu speichern und zu verarbeiten.",
    "save_credentials": "Anmeldeinformationen speichern",
    "keys": "Schlüssel",
    "key": "Schlüssel",
    "service_credentials_were_updated": "Dienstanmeldeinformationen wurden aktualisiert!",
    "credentials_are_valid": "Anmeldeinformationen sind gültig!",
    "credentials_are_invalid": "Anmeldeinformationen sind ungültig!",
    "something_went_wrong": "Etwas ist schief gelaufen!",
    "cancel": "Abbrechen"
  }
}