{
  "projectSettingsTeam": {
    "user_access_rights_successfully_updated": "¡Los derechos de acceso del usuario se actualizaron correctamente!",
    "something_went_awry": "¡Algo salió mal!",
    "user_with_email": "Usuario con correo electrónico",
    "has_been_successfully_invited_to_you_project": "ha sido invitado/a con éxito a tu proyecto!",
    "already_exists": "ya existe"
  },
  "teamInvite": {
    "enter_email_to_invite_people_to_this_project": "Ingresa el correo electrónico para invitar a personas a este proyecto",
    "email": "Correo electrónico",
    "send_invite": "Enviar invitación",
    "can_view": "Puede ver",
    "can_edit": "Puede editar"
  },
  "teamLink": {
    "project_link": "Enlace del proyecto",
    "copy_link": "Copiar enlace",
    "please_note_that_any_activity": "Ten en cuenta que cualquier actividad de los usuarios con quienes compartas este enlace está vinculada a tu saldo de cuenta.",
    "url_link_copied_just_paste_the_link": "¡Enlace URL copiado! Solo pega el enlace a cualquier persona con la que quieras compartir el proyecto"
  },
  "teamsList": {
    "members": "Miembros"
  },
  "teamsListItem": {
    "can_view": "puede ver",
    "can_edit": "puede editar",
    "remove": "eliminar",
    "you": "(tú)",
    "owner": "propietario",
    "pending": "(pendiente)"
  }
}