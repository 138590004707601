{
  "pagesProfileId": {
    "profile": "Profilo",
    "profile_settings": "Impostazioni del profilo",
    "settings": "Impostazioni",
    "home": "Home",
    "change_password": "Cambia password"
  },
  "pagesProfileIndex": {
    "my_profile": "Il mio profilo",
    "notifications": "Notifiche",
    "profile_summary": "Riepilogo del profilo",
    "services_credentials": "Credenziali dei servizi",
    "api_keys": "Chiavi API",
    "billings": "Fatturazioni",
    "marketplace": "Mercato",
    "packages": "Pacchetti",
    "integrations": "Integrazioni",
    "payment_details": "Dettagli di pagamento"
  }
}