import { gql } from '@apollo/client/core';

export const KNOWLEDGE_QUICK_SEARCH = gql`
  query KnowledgeQuickSearchQuery(
    $project_id: uuid
    $title: String
    $url: String
    $startDate: timestamptz
    $endDate: timestamptz
  ) {
    knowledge(
      where: {
        _and: { project_id: { _eq: $project_id } }
        title: { _ilike: $title }
        url: { _ilike: $url }
        created_at: { _gte: $startDate, _lte: $endDate }
      }
    ) {
      id
      title
      url
      created_at
      settings
      cron_time
      widget_type_id
    }
  }
`;
