import { gql } from '@apollo/client/core';

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: uuid!, $name: String = "") {
    update_projects(where: { id: { _eq: $id } }, _set: { name: $name }) {
      returning {
        name
        id
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation ($user_id: uuid!, $name: String!) {
    insert_projects_one(
      object: { name: $name, user_to_projects: { data: { user_id: $user_id, scopes: OWNER } } }
    ) {
      id
    }
  }
`;

export const SET_PROJECT_WIDGET = gql`
  mutation ($project_id: uuid, $type_id: Int, $widgetName: String) {
    insert_widgets(objects: { project_id: $project_id, type_id: $type_id, name: $widgetName }) {
      affected_rows
      returning {
        id
        type_id
        name
        settings
        is_default
        project_id
      }
    }
  }
`;

export const UPDATE_USER_TO_PROJECT = gql`
  mutation ($id: uuid, $is_invited: Boolean) {
    update_user_to_projects(_set: { is_invited: $is_invited }, where: { id: { _eq: $id } }) {
      returning {
        id
        user_id
      }
    }
  }
`;

export const DELETE_USER_TO_PROJECT = gql`
  mutation ($id: uuid!) {
    delete_user_to_projects(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

export const CANCEL_CHAIN = gql`
  mutation ($canceled_at: timestamptz, $chain_id: uuid) {
    update_chains(where: { id: { _eq: $chain_id } }, _set: { canceled_at: $canceled_at }) {
      returning {
        id
        canceled_at
      }
    }
  }
`;

export const UPDATE_USER_METADATA_ZONE = gql`
  mutation ($id: uuid!, $metadata: jsonb) {
    updateUser(_set: { metadata: $metadata }, pk_columns: { id: $id }) {
      id
    }
  }
`;
