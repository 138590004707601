{
  "basicModeSelectWidget": {
    "need_another": "Потрібен ще один",
    "data_source": "джерело даних",
    "view": "перегляд",
    "have_a_specific": "У вас є конкретна {feature}, яка покращить ваш досвід? Запитайте її як функцію, і ми працюватимемо над її інтеграцією.",
    "sorry_we_could_not": "На жаль, ми не змогли знайти жодних відповідних результатів. Якщо у вас є конкретна {feature}, ви можете запитати її як функцію, і ми працюватимемо над її інтеграцією для вас.",
    "request_a_new": "Запитати нову {feature}"
  },
  "basicModeWidgetCard": {
    "coming_soon": "СКОРО",
    "beta": "БЕТА",
    "popular": "ПОПУЛЯРНІ",
    "we_understand_you": "Ми розуміємо, що вам цікава ця функція. Вона доступна в наших платних планах вищого рівня. Розгляньте можливість оновлення, щоб отримати до неї доступ!",
    "add_another_widget": "Додати ще один віджет",
    "expand_your_dashboard_by_adding_a_new_widget": "Розширте свою інформаційну панель, додавши новий віджет."
  },
  "basicModeWidgetFilters": {
    "informers": "Інформери",
    "all": "Всі",
    "widgets": "Віджети",
    "search": "Пошук...",
    "all_informers": "Всі інформери",
    "popular": "Популярні",
    "beta": "Бета",
    "embed": "Вбудувати",
    "integrations": "Інтеграції"
  }
}
