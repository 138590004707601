{
  "addToFavorites": {
    "remove_from_favorites": "Eliminar de favoritos",
    "remove_from_favorites_many": "Eliminar de favoritos",
    "remove_from_favorites_one": "Eliminar de favoritos",
    "remove_from_favorites_other": "Eliminar de favoritos",
    "add_to_favorites": "Agregar a favoritos",
    "add_to_favorites_many": "Agregar a favoritos",
    "add_to_favorites_one": "Agregar a favoritos",
    "add_to_favorites_other": "Agregar a favoritos"
  },
  "confirmationModal": {
    "ok": "Aceptar",
    "ok_many": "Aceptar",
    "ok_one": "Aceptar",
    "ok_other": "Aceptar",
    "cancel": "Cancelar",
    "cancel_many": "Cancelar",
    "cancel_one": "Cancelar",
    "cancel_other": "Cancelar"
  }
}