{
  "settingsBehaviour": {
    "behavior": "Behavior",
    "choose_option": "Choose option you want to edit",
    "label_for_examples_of_questions": "Label for Examples of questions that you can ask the bot about",
    "before_you_start_to_work_with_widget": "Before you start to work with widget, you see examples of questions that you can ask the bot about",
    "enter_label_for_examples_of_questions": "Enter label for Examples of questions that you can ask the bot about...",
    "split_your_questions_with_a_new_line": "Split your questions with a new line.",
    "welcome_messages": "Welcome Messages",
    "enter_messages": "Enter messages, separated by double Enter.",
    "start_each_message": "Start each message on a new line. Press the return key twice to split the messages.",
    "type_messages": "Type messages, double Enter to separate.",
    "add_a_custom_welcome_message_interval": "Add a custom welcome message interval (seconds)",
    "enter_welcome_message_interval": "Enter welcome message interval...",
    "add_a_custom_welcome_message": "Change the \"I don’t know the answer\"",
    "when_your_bot_isn_t_sure_about": "When your bot isn’t sure about the answer, it starts the sentence with \"Most likely.\" You can change this phrase, and it will appear instead of it.",
    "most_likely": "Most likely...",
    "add_a_custom_message": "Add a custom message ending to every OmniMind response",
    "enter_custom_message": "Enter custom message...",
    "default_language": "Default language",
    "custom_language": "Custom language",
    "show_followups_questions": "Show followups questions",
    "live_connect": "Live Connect",
    "none": "None",
    "button_name_for_leaving_a_message": "Button name for leaving a message",
    "leave_a_message": "Leave a message",
    "offline_message": "Offline Message",
    "button_name_for_offline_message": "Button name for offline message",
    "dialogs": "Conversations",
    "anonymize_sensitive_data": "Anonymize sensitive data",
    "language_of_the_data": "Language of the data",
    "language": "Language",
    "replace_value": "Replace value",
    "replace": "Replace"
  },
  "behaviourDialogsSetting": {
    "history_mode": "History mode",
    "collect_user_mode": "Collect user mode",
    "track_all_messages": "Track all messages",
    "track_only_messages_with": "Track negative feedback and support inquiries only",
    "dont_track": "Do not track",
    "collect_username_and_email_with_form": "Collect user name and email with form",
    "provide_username_and_email_by_yourself": "Provide user name and email by yourself",
    "do_not_collect_username_and_email": "Do not collect user name and email"
  },
  "behaviourLiveConnectAction": {
    "selected_option_will_be_applied": "Selected option will be applied as action for button",
    "live_connect_options": "Live connect options",
    "your_custom_js_code": "Your custom JS code",
    "ensure": "Ensure",
    "is_correctly_set_up": "is correctly set up in your project. Need help?",
    "check_here": "Check here."
  },
  "behaviourOfflineMessageAction": {
    "selected_option_will_be_applied_as_action_for_button": "Selected option will be applied as action for button",
    "offline_message_options": "Offline message options",
    "your_custom_js_code": "Your custom JS code",
    "your_email": "Your email"
  },
  "behaviourRateLimit": {
    "enable_rate_limit": "Enable Rate Limit",
    "rate_limit": "Rate Limit",
    "enable_rate_limit_message": "Limit the number of messages sent from this widget per hour"
  }
}
