{
  "knowledgeQa": {
    "no_data_so_far": "Nessun dato finora.",
    "question": "Domanda",
    "answer": "Risposta",
    "last_modified": "Ultima modifica",
    "actions": "Azioni",
    "delete_these_items": "Eliminare questi elementi?",
    "are_you_sure_you_want_to_delete": "Sei sicuro di voler eliminare gli elementi selezionati?",
    "this_action_cannot_be_undone": "Questa azione non può essere annullata.",
    "the_resources_were_removed": "Le risorse sono state rimosse.",
    "an_error_occurred": "Si è verificato un errore durante l'eliminazione delle risorse",
    "question_and_answer_were_saved": "Domanda e risposta sono state salvate con successo!",
    "an_error_occurred_while_saving": "Si è verificato un errore durante il salvataggio della domanda e risposta!"
  },
  "knowledgeQaActions": {
    "edit": "Modifica",
    "delete": "Elimina"
  },
  "knowledgeQaEditModal": {
    "question": "Domanda",
    "cancel": "Annulla",
    "filter": "Filtra",
    "apply": "Applica"
  },
  "knowledgeQaFilterModal": {
    "cancel": "Annulla",
    "filter": "Filtra",
    "apply": "Applica",
    "by_content": "Per contenuto",
    "search_for_content": "Cerca contenuto..."
  },
  "knowledgeQaFilters": {
    "by_content": "Per contenuto"
  },
  "knowledgeQaToolbar": {
    "qa_selected": "QA selezionati:"
  }
}