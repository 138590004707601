<template>
  <header :class="{ dark: store.semidark && store.menu === 'horizontal' }">
    <div
      class="border-b"
      :class="
        isPortal
          ? 'border-[var(--omni-color-secondary)]'
          : 'border-white-white100 dark:border-dark-blue100'
      "
    >
      <div
        class="relative flex w-full items-center px-5 py-2.5"
        :class="
          isPortal
            ? 'bg-[var(--omni-color-primary)] text-[var(--omni-color-text)]'
            : 'bg-white dark:bg-dark-darkBlue'
        "
      >
        <div class="horizontal-logo flex items-center gap-2 lg:hidden">
          <router-link
            :to="logoRoute"
            class="main-logo hidden shrink-0 items-center gap-3 dark:text-white-light sm:flex"
            :class="{ '!text-[var(--omni-color-text)]': isPortal }"
          >
            <div class="h-[38px] w-8" :class="{ '!h-8': logo }">
              <BaseWidgetSvgIcon v-if="!logo" name="logo-main" />
              <img
                v-else
                :src="logo"
                alt="portal-logo"
                class="my-auto h-8 w-8 rounded-full object-cover"
              />
            </div>
            <span class="align-middle text-sm font-medium">
              {{ title ?? 'OmniMind.AI' }}
            </span>
          </router-link>
          <a
            href="javascript:;"
            class="collapse-icon flex flex-none rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:text-[#d0d2d6] dark:hover:bg-dark/60 dark:hover:text-primary"
            :class="
              isPortal &&
              '!bg-[var(--omni-color-secondary)] !text-[var(--omni-color-text)] hover:!text-[var(--omni-color-accent)]'
            "
            @click="store.toggleSidebar()"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20 7L4 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" />
              <path
                opacity="0.5"
                d="M20 12L4 12"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M20 17L4 17"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
              />
            </svg>
          </a>
        </div>
        <div
          class="flex items-center justify-end space-x-1.5 ltr:ml-auto rtl:mr-auto rtl:space-x-reverse dark:text-[#d0d2d6] sm:flex-1 ltr:sm:ml-0 sm:rtl:mr-0 lg:space-x-2"
        >
          <template v-if="!isPortal">
            <div
              v-tippy="{
                content: $t('header.switch_colour_theme'),
                placement: 'bottom',
                touch: false,
              }"
              class="rounded-full border border-white-white100 dark:border-dark-blue100"
            >
              <a
                href="javascript:;"
                class="flex h-9 w-9 items-center rounded-full bg-white-light/40 p-2 hover:bg-white-light/90 hover:text-primary dark:bg-dark/40 dark:hover:bg-dark/60"
                @click="toggleTheme"
              >
                <BaseSvgIcon :name="store.theme === 'light' ? 'moon' : 'sun'" />
              </a>
            </div>
          </template>
          <UserHeader :is-portal="isPortal"></UserHeader>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts" setup>
import { useAppStore } from '@/stores';
import BaseWidgetSvgIcon from '@components/base/BaseWidgetSvgIcon.vue';
import BaseSvgIcon from '@components/base/BaseSvgIcon.vue';
import UserHeader from '@components/UserHeader.vue';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    title?: string;
    logo?: string;
    isPortal?: boolean;
  }>(),
  {
    title: undefined,
    logo: '',
    isPortal: false,
  },
);
const store = useAppStore();

const toggleTheme = () => {
  const newTheme = store.theme === 'light' ? 'dark' : 'light';
  store.toggleTheme(newTheme);
};

const logoRoute = computed(() => {
  if (!store.isPaid && !props.isPortal) {
    return '/profile?tab=packages';
  } else if (!store.isPaid && props.isPortal) {
    return '/omni-portal/portal/profile?tab=packages';
  } else if (props.isPortal) {
    return '/omni-portal/portal/';
  } else {
    return '/';
  }
});
</script>
