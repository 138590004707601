{
  "knowledge": {
    "sources": "Fonti",
    "questions_and_answers": "Domande e risposte"
  },
  "knowledgeHeader": {
    "knowledge": "Conoscenza",
    "search_for_uploaded_knowledge": "Cerca nella conoscenza caricata..."
  },
  "knowledgeTable": {
    "name": "Nome",
    "title": "Titolo",
    "size": "Dimensione",
    "date": "Data",
    "actions": "Azioni"
  }
}