{
  "portalProjectItem": {
    "your_link_name": "Ihr Link-Name",
    "delete_this_project": "Dieses Projekt löschen?",
    "delete_project": "Projekt löschen",
    "project_will_be_deleted": "Projekt wird gelöscht. Dies kann nicht rückgängig gemacht werden"
  },
  "portalProjects": {
    "no_projects_so_far": "Bisher keine Projekte",
    "apply": "Anwenden",
    "link_name": "Link-Name",
    "project": "Projekt",
    "widget": "Widget",
    "actions": "Aktionen",
    "the_item_has_been_successfully_deleted": "Der Eintrag wurde erfolgreich gelöscht.",
    "projects_successfully_updated": "Projekte erfolgreich aktualisiert."
  }
}