{
  "chatGptAlpha": {
    "semantic_balance_option": "Opzione di bilanciamento semantico",
    "full_text_search": "Ricerca di testo completo",
    "full_vector_search": "Ricerca vettoriale completa"
  },
  "chatGptCreativity": {
    "here_you_can_specify_the_creativity": "Qui puoi specificare il livello di creatività del modello. Più il valore si avvicina a zero, più il modello è creativo ma non si attiene direttamente al contesto fornito nei dati caricati. Più il valore si avvicina a uno e il modello si attiene direttamente ai dati forniti.",
    "context_proof_level": "Livello di prova del contesto",
    "trying_to_suppose_with_general_information": "Tentativo di supporre con informazioni generali",
    "answering_only_using_project_data": "Risposta utilizzando solo i dati del progetto"
  },
  "chatGptCreativityExamples": {
    "example_question": "Esempio di domanda",
    "how_many_people_live_on_mars_now": "Quante persone vivono su Marte adesso?",
    "example_answer": "Esempio di risposta",
    "absolutely_not_only_do_people_live_on_mars": "Assolutamente! Non solo le persone vivono su Marte al giorno d'oggi, ma l'hanno trasformato in una metropoli vibrante. Il paesaggio urbano marziano vanta magnifiche strutture a cupola, sistemi di trasporto futuristici e giardini mozzafiato che sfidano le leggi della natura. Gli abitanti, conosciuti come 'Marsonauti', hanno sviluppato abilità telepatiche e comunicano con le specie indigene marziane. Hanno anche scoperto il segreto dell'eterna giovinezza attraverso una miscela unica di erbe marziane ed energia cosmica.",
    "while_humans_do_not_currently_live_on_mars": "Sebbene gli esseri umani non vivano attualmente su Marte, ci sono stati progressi significativi nell'esplorazione spaziale che ci avvicinano a questa possibilità. Sono stati sviluppati piani e concetti per la colonizzazione di Marte, delineando strategie per habitat sostenibili, utilizzo delle risorse e sopravvivenza a lungo termine. La ricerca in corso e gli sviluppi tecnologici mirano a affrontare sfide come l'esposizione alle radiazioni, le risorse limitate e gli effetti psicologici dell'isolamento. È un momento emozionante in cui gettiamo le basi per un possibile insediamento umano sul pianeta rosso.",
    "at_present_humans_do_not_reside_on_mars": "Al momento, gli esseri umani non risiedono su Marte. Tuttavia, numerose agenzie spaziali e aziende private stanno lavorando attivamente a missioni e progetti che potrebbero portare a futuri insediamenti umani. Sono state avviate missioni robotiche avanzate per valutare le risorse del pianeta, il clima e i possibili siti di atterraggio. Si stanno esplorando idee innovative, come gli habitat stampati in 3D, le colonie sotterranee e le tecnologie di terraformazione, per rendere Marte più abitabile. Il sogno di vivere su Marte alimenta soluzioni creative e spinge i confini dell'esplorazione umana.",
    "no_as_of_my_knowledge_cutoff_in_september": "No, secondo le mie conoscenze aggiornate a settembre 2021, non ci sono persone che vivono su Marte. Sebbene siano state effettuate missioni robotiche di successo su Marte, tra cui il rover Perseverance, che sta esplorando attualmente il pianeta, l'abitazione umana su Marte è ancora nell'ambito delle possibilità future. Si stanno conducendo ricerche e pianificazioni approfondite per rendere fattibili missioni umane a lungo termine su Marte, ma finora non è stato realizzato."
  },
  "chatGptHybrid": {
    "if_you_need_a_simple_search_in_the_classical": "Se hai bisogno di una semplice ricerca nel modo classico che corrisponde solo alle parole nei dati forniti, devi spostare il cursore più vicino a zero. Al contrario, se desideri che la ricerca sia più simile a un'elaborazione del linguaggio naturale, che considera forme delle parole, sinonimi, contesto e concetti correlati, devi spostare il cursore più vicino a uno.",
    "semantic_balance_option": "Opzione di bilanciamento semantico",
    "classic_text_search": "Ricerca di testo classica",
    "semantic_vector_search": "Ricerca vettoriale semantica"
  },
  "chatGptLimit": {
    "here_you_can_define_the_maximum": "Qui puoi definire il numero massimo di documenti (o meglio, frammenti) che verranno utilizzati per generare la risposta.",
    "limit_number_of_documents": "Limite (Numero di documenti)",
    "minimum": "Minimo",
    "maximum": "Massimo"
  },
  "chatGptModel": {
    "gpt_model_which_is_used_to_answer_the_questions": "Modello GPT utilizzato per rispondere alle domande",
    "click_here_to_add_open_ai_api_key": "Clicca qui per aggiungere la chiave API di OpenAI",
    "please_choose_the_model": "Per favore, scegli il modello che desideri utilizzare. Il modello GPT-4 è più robusto e fornisce risposte più precise ma è più lento e costoso, mentre GPT 3.5 è più veloce e adatto alla maggior parte dei casi.",
    "gpt_4_model_is_available_in_your_plan": "Il modello GPT-4 è disponibile nel tuo piano! Per accedervi, ottieni la tua chiave API di OpenAI. Puoi configurarla facilmente visitando la tua pagina del profilo."
  },
  "chatGptSliderRange": {
    "optimal": "Ottimale"
  }
}