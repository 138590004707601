export enum UserPermissionEnum {
  CanCreateProjects = 'can-create-projects',
  CanUseGpt4 = 'can-use-gpt-4',
  CanUseInformers = 'can-use-informers',
  CanUseWidgets = 'can-use-widgets',
  CanUseTools = 'can-use-tools',
  CanUseExtendedSettings = 'can-use-extended-settings',
  CanUseExtensions = 'can-use-extensions',
  CanUseDialogs = 'can-use-dialogs',
  CanSaveAllDialogs = 'can-save-all-dialogs',
}
