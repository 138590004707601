{
  "copyProjectModal": {
    "clone_current_project": "Clonar proyecto actual",
    "clone_project": "Clonar proyecto",
    "copy_this_project": "¿Copiar este proyecto?",
    "copy_project": "Copiar proyecto",
    "all_your_settings_and_resources_on_this": "Se copiarán todas tus configuraciones y recursos en este proyecto.",
    "it_looks_like_you_ve_reached_your_maximum_number": "Parece que has alcanzado tu número máximo de proyectos. Para comenzar un nuevo proyecto, considera archivar o eliminar los existentes que ya no necesitas, o explora nuestros planes de suscripción para límites más altos."
  },
  "deleteProjectModal": {
    "delete_current_project": "Eliminar proyecto actual",
    "delete_project": "Eliminar proyecto",
    "delete": "Eliminar",
    "all_your_data_on": "Todos tus datos en",
    "will_be_deleted_immediately": "serán eliminados inmediatamente.",
    "the_project": "el proyecto"
  },
  "deleteShopifyContent": {
    "seems_like_you_ve_created_a_project_using_our_shopify": "Parece que has creado un proyecto utilizando nuestra aplicación de Shopify y estás considerando eliminarlo a través de la aplicación principal. Recomendamos eliminarlo a través de la aplicación de Shopify: Eliminar un proyecto a través de nuestra aplicación de Shopify es la forma recomendada y más segura de garantizar un proceso fluido y sin errores.",
    "yes_i_understand_it_but_want_to_delete_it_anyway": "Sí, lo entiendo, pero quiero eliminarlo de todos modos."
  },
  "projectUploadingMessage": {
    "you_have_updated_knowledge_please_wait": "Has actualizado el conocimiento. Por favor espera mientras cargamos tu conocimiento.",
    "knowledge_uploaded": "Conocimiento cargado..."
  },
  "publishProject": {
    "unpublish": "Despublicar",
    "publish": "Publicar",
    "unpublish_project": "Despublicar proyecto",
    "all_users_who_had_access_to_the_widgets": "Todos los usuarios que tenían acceso a los widgets a través del enlace perderán la capacidad de usarlos.",
    "share_project": "Compartir proyecto",
    "please_note_that_any_activity_of_the_users": "Ten en cuenta que cualquier actividad de los usuarios con los que compartas este enlace se cargará en el saldo de tu cuenta.",
    "publish_widget": "Publicar widget"
  },
  "renameProject": {
    "enter_the_short_name_of_your_project": "Ingresa el nombre corto de tu proyecto",
    "my_project": "Mi Proyecto",
    "description": "Descripción"
  },
  "shareProject": {
    "widget_link_copied_to_clipboard": "Enlace del widget copiado al portapapeles",
    "share_project": "Compartir proyecto",
    "please_note_that_any_activity_of_the_users": "Ten en cuenta que cualquier actividad de los usuarios con los que compartas este enlace se cargará en el saldo de tu cuenta.",
    "share": "Compartir",
    "publish_widget": "Publicar widget"
  },
  "shareProjectModal": {
    "share_widget": "Compartir widget",
    "please_note_that_any_activity_of_the_users": "Ten en cuenta que cualquier actividad de los usuarios con los que compartas este enlace se cargará en el saldo de tu cuenta.",
    "copy_link": "Copiar enlace",
    "share_on": "Compartir en"
  },
  "unpublishProject": {
    "unpublish_project": "Despublicar proyecto",
    "all_users_who_had_access_to_the_widgets": "Todos los usuarios que tenían acceso a los widgets a través del enlace perderán la capacidad de usarlos."
  }
}