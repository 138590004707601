import { SettingsPropertiesEnum } from '@/types/SettingsPropertiesEnum';
import { qAWidgetSettings } from '@components/project/settings/defaults/qAWidgetSettings';
import {
  DialogExternalUserOptionsEnum,
  DialogSavingOptionsEnum,
} from '@components/settings/types/behavior';
import { anonymizerSettings } from '@components/project/settings/defaults/anonymizerSettings';

export const popupChatWidgetSettings = {
  ...qAWidgetSettings,
  ...anonymizerSettings,
  [SettingsPropertiesEnum.LOGO]: {
    id: '',
    src: '',
  },
  [SettingsPropertiesEnum.BOT_NAME]: 'OmniMind AI',
  [SettingsPropertiesEnum.IS_PRECONTEXT_CHECK]: true,
  [SettingsPropertiesEnum.FORM_LANGUAGE]: 'English',
  [SettingsPropertiesEnum.PRECONTEXT]: 7,
  [SettingsPropertiesEnum.WELCOME_MESSAGE]: 'Hi there 👋, how can I help ?',
  [SettingsPropertiesEnum.WELCOME_MESSAGE_IS_ACTIVE]: true,
  [SettingsPropertiesEnum.WELCOME_MESSAGE_INTERVAL]: 5,
  [SettingsPropertiesEnum.IS_AUTO_LANGUAGE]: false,
  // [SettingsPropertiesEnum.QUESTIONS_TITLE]: 'Examples of questions that you can ask the bot about',
  [SettingsPropertiesEnum.SEARCH_PLACEHOLDER]: 'Type a question here...',
  [SettingsPropertiesEnum.SEARCH_PLACEHOLDER_IS_ACTIVE]: true,
  [SettingsPropertiesEnum.SUBMIT_BUTTON_TEXT]: 'Ask',
  [SettingsPropertiesEnum.SUBMIT_BUTTON_TEXT_IS_ACTIVE]: true,
  [SettingsPropertiesEnum.RESOURCES]: false,
  [SettingsPropertiesEnum.HIDE_OVERLAY]: false,
  [SettingsPropertiesEnum.DIALOG_SAVING]: DialogSavingOptionsEnum.NEGATIVE,
  [SettingsPropertiesEnum.DIALOG_USER_SAVING]: DialogExternalUserOptionsEnum.NONE,
  [SettingsPropertiesEnum.EXTERNAL_EMAIL_EXAMPLE]: '',
  [SettingsPropertiesEnum.EXTERNAL_USERNAME_EXAMPLE]: '',
  [SettingsPropertiesEnum.SHOW_ON_MOBILE]: true,
  [SettingsPropertiesEnum.SHOW_ON_TABLET]: true,
  [SettingsPropertiesEnum.MOST_LIKELY_ENABLED]: true,
};
