{
  "serviceCredentials": {
    "you_can_define_your_credentials": "Puedes definir tus credenciales y usar tus propios servicios para almacenar y procesar información.",
    "save_credentials": "Guardar credenciales",
    "keys": "Claves",
    "key": "Clave",
    "service_credentials_were_updated": "¡Las credenciales del servicio se actualizaron!",
    "credentials_are_valid": "¡Las credenciales son válidas!",
    "credentials_are_invalid": "¡Las credenciales no son válidas!",
    "something_went_wrong": "¡Algo salió mal!",
    "cancel": "Cancelar"
  }
}