{
  "settingsCustomize": {
    "bot_name": "Bot-Name",
    "enter_custom_bot_name": "Geben Sie einen benutzerdefinierten Bot-Namen ein",
    "welcome_messages": "Willkommensnachrichten",
    "enter_messages_separated_by_double_enter": "Geben Sie Nachrichten ein, die durch doppeltes Enter getrennt sind.",
    "type_messages_double_enter_to_separate": "Geben Sie Nachrichten ein, doppeltes Enter zum Trennen.",
    "add_a_custom_welcome_message_interval_seconds": "Fügen Sie ein benutzerdefiniertes Willkommensnachrichten-Intervall hinzu (Sekunden)",
    "enter_welcome_message_interval": "Geben Sie das Willkommensnachrichten-Intervall ein...",
    "show_followups_questions": "Folgefragen anzeigen",
    "add_a_custom_message_ending_to_every_omni_mind_response": "Fügen Sie jedem OmniMind-Antwort eine benutzerdefinierte Nachrichtenendung hinzu",
    "enter_custom_message": "Geben Sie eine benutzerdefinierte Nachricht ein...",
    "label_for_examples_of_questions": "Bezeichnung für Beispiele von Fragen, die Sie den Bot stellen können",
    "before_you_start_to_work_with_widget": "Bevor Sie mit dem Widget arbeiten, sehen Sie Beispiele von Fragen, die Sie den Bot stellen können",
    "enter_label_for_examples": "Geben Sie eine Bezeichnung für Beispiele von Fragen ein, die Sie den Bot stellen können...",
    "change_placeholder_text_of_the_form_s_input": "Ändern Sie den Platzhaltertext der Eingabe des Formulars",
    "enter_custom_placeholder_text": "Geben Sie einen benutzerdefinierten Platzhaltertext ein...",
    "submit_button_s_text": "Text der Schaltfläche 'Senden'",
    "enter_text_for_submit_button": "Geben Sie den Text für die Schaltfläche 'Senden' ein...",
    "opening_message": "Eröffnungsnachricht",
    "enter_custom_opening_message": "Geben Sie eine benutzerdefinierte Eröffnungsnachricht ein",
    "change_the_widget_color_style": "Ändern Sie den Farbstil des Widgets",
    "show_examples_of_questions_that_you_can_ask_the_bot_about": "Zeigen Sie Beispiele von Fragen an, die Sie den Bot stellen können",
    "default_language": "Standardsprache",
    "browser_language_auto_detect": "Automatische Erkennung der Browsersprache",
    "custom_prompt": "Benutzerdefinierter Hinweis",
    "using_a_direct_custom_prompt_is_only_necessary": "Die Verwendung eines direkten benutzerdefinierten Hinweises ist nur in besonderen Fällen erforderlich und deaktiviert das Hauptformular.",
    "change_the_header_font_size": "Ändern Sie die Schriftgröße des Headers",
    "change_the_body_font_size": "Ändern Sie die Schriftgröße des Textkörpers",
    "hide_powered_by_label": "Verbergen Sie das Label 'Powered by'",
    "show_answer_sources_option": "Option 'Antwortquellen anzeigen'",
    "failed_to_upload_branding_logo": "Fehler beim Hochladen des Branding-Logos"
  },
  "customizeColorInput": {
    "primary": "Primär",
    "background": "Hintergrund",
    "secondary": "Sekundär",
    "stroke": "Strich",
    "accent": "Akzent",
    "text": "Text",
    "body": "Textkörper",
    "default_color_scheme": "Standard-Farbschema"
  },
  "customizeColorModal": {
    "create_the_custom_widget_color_style": "Erstellen Sie den benutzerdefinierten Farbstil des Widgets",
    "save": "Speichern",
    "the_custom_widget_color_style": "{value} den benutzerdefinierten Farbstil des Widgets",
    "edit": "Bearbeiten",
    "create": "Erstellen",
    "primary": "Primär",
    "background_color": "Hintergrundfarbe",
    "secondary": "Sekundär",
    "stroke_color": "Strichfarbe",
    "text_color": "Textfarbe",
    "accent_color": "Akzentfarbe",
    "body_background": "Hintergrund des Textkörpers",
    "leave_empty_for_transparent_body": "Leer lassen für transparenten Textkörper",
    "current_color_style_examples_click_to_clone": "Aktuelle Farbstilbeispiele, zum Klonen klicken",
    "field_is_not_a_valid_hex_color": "Feld ist keine gültige Hex-Farbe"
  },
  "customizeColorSchemes": {
    "changes_to_the_color_theme_of_your_widget": "Änderungen am Farbthema Ihres Widgets werden im Modus \"Widget teilen\" und \"Einbettungscode\" angezeigt.",
    "you_have_reached_the_maximum_allowed_limit": "Sie haben das zulässige Maximum erreicht. Bitte entfernen Sie einige Schemata, bevor Sie neue erstellen.",
    "click_here_to_create_a_new_scheme": "Klicken Sie hier, um ein neues Schema zu erstellen."
  },
  "customizeFileUploader": {
    "upload_custom_avatar_or_logo": "Branding-Logo hochladen",
    "drag_drop_or": "Drag & Drop oder",
    "select_files": "Dateien auswählen",
    "to_upload": "zum Hochladen",
    "error_uploading_file": "Fehler beim Hochladen der Datei"
  },
  "customizeFileUploaderImage": {
    "no_logo": "Kein Logo"
  },
  "customizeQuestionnaires": {
    "add_custom_questions": "Benutzerdefinierte Fragen hinzufügen",
    "enter_custom_message": "Geben Sie eine benutzerdefinierte Nachricht ein...",
    "regenerate": "Fragen aktualisieren"
  }
}