{
  "whatsAppWidget": {
    "access_token": "Token de acceso",
    "phone_id": "ID de teléfono",
    "save": "Guardar",
    "how_to_connect": "Cómo conectar el chatbot de WhatsApp de Omnimind AI",
    "make_sure_your_question": "Asegúrate de que tu pregunta sea clara y concisa para obtener las respuestas más precisas. Puedes actualizar o cambiar el proyecto en la configuración de la acción si deseas utilizar diferentes datos para el análisis.",
    "create_meta_business_account": "Crear una cuenta de negocio Meta (Facebook)",
    "create_new_meta_app": "Crear una nueva aplicación Meta (Facebook)",
    "login_to_your_account": "Inicia sesión en tu cuenta",
    "select_my_apps": "Selecciona \"Mis aplicaciones\"",
    "select_create_app": "Selecciona \"Crear aplicación\"",
    "select_options": "Selecciona opciones",
    "use_case_other": "Caso de uso: Otro",
    "app_type_business": "Tipo de aplicación: Negocio",
    "enter_app_details": "Ingresa los detalles de la aplicación",
    "app_name": "Nombre de la aplicación",
    "app_contact_email": "Correo electrónico de contacto de la aplicación",
    "business_account": "Cuenta de negocio (opcional)",
    "add_whatsapp_to_your_meta_app": "Agrega WhatsApp a tu aplicación Meta",
    "find_add_products_to_your_app": "Encuentra \"Agregar productos a tu aplicación\"",
    "select_whatsapp_set_up": "Selecciona 'Configuración de WhatsApp'",
    "quick_start": "Inicio rápido",
    "select_business_account": "Selecciona la cuenta de negocio",
    "purchase_new_business_number": "Compra un nuevo número de negocio para WhatsApp",
    "select_api_setup": "Selecciona 'Configuración de API' y encuentra 'Paso 5'",
    "click_on_the_button": "Haz clic en el botón \"Agregar número de teléfono\"",
    "fill_in_all_fields": "Completa todos los campos y confirma tu número de teléfono",
    "skip_if_already_have": "Omite este paso si ya tienes un número de negocio que puedes usar para tu bot de WhatsApp",
    "configure_whatsapp_settings": "Configura la configuración de WhatsApp",
    "select_configure_webhooks": "Selecciona 'Configurar webhooks' en la página 'Configuración de API'",
    "edit_webhook": "Edita el webhook",
    "add_a_callback_url_and_verify_token": "Agrega una URL de devolución de llamada y un token de verificación.",
    "callback_url": "URL de devolución de llamada",
    "verify_token": "Token de verificación",
    "manage_webhook_fields": "Administra los campos del webhook",
    "add_and_subscribe_only_to": "Agrega y suscríbete solo a",
    "add_a_payment_method_to_your_whatsapp": "Agrega un método de pago a tu aplicación de WhatsApp",
    "payment_settings": "Configuración de pago",
    "add_payment_method": "Agregar método de pago",
    "add_phone_number_and_business_details": "Agrega el número de teléfono (y los detalles del negocio)",
    "provide_necessary_info": "Proporciona la información necesaria",
    "verify_number": "Verificar número",
    "send_a_test_message": "Envía un mensaje de prueba",
    "select_api_setup_and_find_step_1": "Selecciona 'Configuración de API' y encuentra 'Paso 1':",
    "select_the_from_number": "Selecciona el número 'De'",
    "choose_the_option_to_add_and_verify_a_new_number": "Elige la opción para agregar y verificar un nuevo número",
    "select_to_number": "Selecciona el número 'Para' (agrega un número personal para realizar pruebas)",
    "select_send_message": "Selecciona 'Enviar mensaje'",
    "update_business_details_for_your_meta": "Actualiza los detalles del negocio para tu aplicación Meta",
    "select_settings_basic": "Dentro de tu aplicación Meta, selecciona 'Configuración > Básico'",
    "fill_in_all_the_necessary_information": "Completa toda la información necesaria",
    "verify_business_account": "Verificar cuenta de negocio",
    "select_settings": "Selecciona 'Configuración'",
    "select_business_account_info": "Selecciona 'Información de la cuenta de negocio'",
    "provide_all_the_necessary_information": "Proporciona toda la información necesaria",
    "select_security_centre": "Selecciona 'Centro de seguridad'",
    "select_start_verification": "Selecciona 'Iniciar verificación'",
    "create_a_system_user": "Crea un usuario del sistema en tu cuenta de negocio",
    "settings_users_system_users": "Configuración > Usuarios > Usuarios del sistema",
    "create_new_system_user": "Crear nuevo usuario del sistema",
    "name_anything": "Nombre (cualquier nombre)",
    "role_admin": "Rol: Administrador",
    "select_add_assets": "Selecciona 'Agregar activos'",
    "select_apps": "Selecciona 'Aplicaciones'",
    "select_the_new_app_for_whatsapp": "Selecciona la nueva aplicación (para WhatsApp)",
    "toggle_manage_app": "Activa 'Administrar aplicación'",
    "generate_new_token_for_system_user": "Genera un nuevo token para el usuario del sistema",
    "select_app_and_generate_new_token": "Selecciona la aplicación y 'Generar nuevo token'",
    "select_never_expire": "Selecciona 'Nunca' expirar",
    "select_only_permissions_whatsapp_business_messaging": "Selecciona solo los permisos: whatsapp_business_messaging",
    "select_generate_token": "Selecciona 'Generar token'",
    "important_save_this_token": "Importante: Guarda este token en algún lugar, lo necesitarás",
    "add_token_and_phone_id": "Agrega el Token y el ID de teléfono al principio de esta página",
    "phone_id_this_is_from": "'ID de teléfono' - esto es de tu aplicación Meta (configuración de API, paso 5), asegúrate de seleccionar el número 'De' correcto y copia el ID de teléfono desde aquí",
    "token_this_is_from_step_8": "'Token' - esto es del paso 8, del usuario del sistema en tu cuenta de negocio",
    "launch_your_whatsApp_integration": "Lanza tu integración de WhatsApp",
    "set_your_meta_app_from_development": "Configura tu aplicación Meta de desarrollo a producción (Abre tu aplicación Meta desde"
  }
}