{
  "pagesProjectsEmpty": {
    "empty_project": "Empty project",
    "is_not_complete_yet": "is not complete yet because it doesn't have knowledge. You can either upload knowledge or simply delete the project.",
    "add_knowledge": "Add knowledge",
    "this_project": "This project"
  },
  "pagesProjectsIndex": {
    "redirecting": "Redirecting..."
  }
}