{
  "knowledgeChunksModal": {
    "chunks": "Blocchi",
    "save": "Salva",
    "chunks_selected": "blocchi selezionati",
    "no_data_so_far": "Nessun dato finora.",
    "title": "Titolo",
    "page": "Pagina",
    "size": "Dimensione",
    "last_modified": "Ultima modifica",
    "text": "Testo",
    "actions": "Azioni",
    "no_chunks_so_far": "Nessun blocco finora",
    "all_done_your_items_were_removed_successfully": "Fatto! I tuoi elementi sono stati rimossi con successo."
  },
  "knowledgeChunksModalPagination": {
    "prev": "Precedente",
    "next": "Successivo"
  },
  "knowledgeChunksModalSearch": {
    "quick_search": "Ricerca rapida",
    "search": "Cerca"
  },
  "knowledgeFilterModal": {
    "filter": "Filtro",
    "apply": "Applica",
    "reset": "Reset",
    "by_name": "Per nome",
    "by_title": "Per titolo",
    "by_period": "Per periodo",
    "search_for_resource_name": "Cerca per nome risorsa...",
    "search_for_resource_title": "Cerca per titolo risorsa...",
    "last_year": "Ultimo anno",
    "quarter": "Trimestre",
    "month": "Mese",
    "week": "Settimana",
    "day": "Giorno"
  }
}