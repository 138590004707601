{
  "knowledgeQa": {
    "no_data_so_far": "Sem dados até agora.",
    "question": "Pergunta",
    "answer": "Resposta",
    "last_modified": "Última modificação",
    "actions": "Ações",
    "delete_these_items": "Excluir estes itens?",
    "are_you_sure_you_want_to_delete": "Tem certeza de que deseja excluir os itens selecionados?",
    "this_action_cannot_be_undone": "Esta ação não pode ser desfeita.",
    "the_resources_were_removed": "Os recursos foram removidos.",
    "an_error_occurred": "Ocorreu um erro ao excluir os recursos",
    "question_and_answer_were_saved": "Pergunta e resposta foram salvos com sucesso!",
    "an_error_occurred_while_saving": "Ocorreu um erro ao salvar a pergunta e resposta!"
  },
  "knowledgeQaActions": {
    "edit": "Editar",
    "delete": "Excluir"
  },
  "knowledgeQaEditModal": {
    "question": "Pergunta",
    "cancel": "Cancelar",
    "filter": "Filtrar",
    "apply": "Aplicar"
  },
  "knowledgeQaFilterModal": {
    "cancel": "Cancelar",
    "filter": "Filtrar",
    "apply": "Aplicar",
    "by_content": "Por conteúdo",
    "search_for_content": "Pesquisar por conteúdo..."
  },
  "knowledgeQaFilters": {
    "by_content": "Por conteúdo"
  },
  "knowledgeQaToolbar": {
    "qa_selected": "QA selecionado:"
  }
}