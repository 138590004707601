{
  "projectSettingsSecurity": {
    "add": "Додати",
    "add_new_domain": "Додати новий домен",
    "allowed_domains": "Дозволені домени",
    "the_value_is_not_a_valid_domain": "Значення не є дійсним доменом",
    "password_is_required": "Пароль обов'язковий",
    "password_must_be_at_least": "Пароль повинен містити принаймні 8 символів",
    "password_should_be_alphanumeric": "Пароль повинен містити лише буквено-цифрові символи",
    "this_domain_was_added_previously": "Цей домен був доданий раніше"
  },
  "projectSettingsSecurityPassword": {
    "password": "Пароль",
    "delete_project_password": "Видалити пароль проекту",
    "delete_the_project_password": "Видалити пароль проекту",
    "set_up_password_for_the_project": "Встановити пароль для проекту",
    "anyone_will_be_able_to_use": "Будь-хто зможе використовувати проект без пароля",
    "anyone_who_has_access_to_this_project": "Будь-хто, хто має доступ до цього проекту, повинен ввести пароль"
  }
}