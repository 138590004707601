{
  "knowledge": {
    "sources": "Fuentes",
    "questions_and_answers": "Preguntas y respuestas"
  },
  "knowledgeHeader": {
    "knowledge": "Conocimiento",
    "search_for_uploaded_knowledge": "Buscar conocimiento subido..."
  },
  "knowledgeTable": {
    "name": "Nombre",
    "title": "Título",
    "size": "Tamaño",
    "date": "Fecha",
    "actions": "Acciones"
  }
}