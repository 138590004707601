{
  "explainWidget": {
    "we_are_creating_suggestion_questions": "Ми створюємо запитання-пропозиції для вас. Але ви можете використовувати чат зараз",
    "sorry_we_could_not_create_any_examples": "Вибачте, ми не змогли створити жодних прикладів запитань, на які ви можете запитати бота.",
    "loading": "Завантаження...",
    "explanation": "Пояснення",
    "please_ask_the_owner_of_the": "Будь ласка, запитайте власника проекту, щоб зробити його публічним",
    "the_project_owner_needs_to": "Власник проекту повинен оновити підписку, оскільки поточна закінчилася.",
    "404_oops_we_havet_found": "404 Ой, ми не знайшли проект",
    "oops_it_seems_youve_reached": "Ой! Здається, ви досягли обмеження на кількість повідомлень. Щоб продовжити, будь ласка, налаштуйте обмеження на кількість повідомлень у налаштуваннях."
  }
}