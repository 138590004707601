import { getEventProcessor } from '@/analytics/eventProcessor';
import { useAppStore } from '@/stores';
export const signupAnalyticsInform = (event: string) => {
  const eventProcessor = getEventProcessor();
  const store = useAppStore();

  const displayName = store.user?.displayName || '';
  const [firstName = '', lastName = ''] =
    displayName.split(' ').length > 1 ? displayName.split(' ') : [displayName, ''];

  eventProcessor &&
    eventProcessor.event(event, {
      id: store.user?.id, // Gleap
      email: store.user?.email, // Gleap
      name: displayName, // Gleap
      firstName, // Segment-Hubspot
      lastName, // Segment-Hubspot
      phone: store.user?.phoneNumber || '', // Gleap
      customData: {
        createdAt: store.user?.createdAt,
      }, // Gleap
    });
};
