{
  "anonymizerSettings": {
    "anonymize_sensitive_data": "Анонімізувати чутливі дані",
    "language": "Мова",
    "language_of_the_data": "Мова даних",
    "replace": "Замінити",
    "replace_value": "Замінити значення",
    "define_what_entities_must_be_anonymized": "Визначте, які сутності потрібно анонімізувати",
    "anonymize_entities": "Анонімізувати сутності",
    "anonymize_sensitive_data_few": "Анонімізувати кілька чутливих даних",
    "anonymize_sensitive_data_many": "Анонімізувати багато чутливих даних",
    "anonymize_sensitive_data_one": "Анонімізувати один чутливий даних",
    "anonymize_sensitive_data_other": "Анонімізувати інші чутливі дані",
    "language_few": "Мова (кілька)",
    "language_many": "Мова (багато)",
    "language_one": "Мова (одна)",
    "language_other": "Мова (інша)",
    "language_of_the_data_few": "Мова даних (кілька)",
    "language_of_the_data_many": "Мова даних (багато)",
    "language_of_the_data_one": "Мова даних (одна)",
    "language_of_the_data_other": "Мова даних (інша)",
    "replace_few": "Замінити (кілька)",
    "replace_many": "Замінити (багато)",
    "replace_one": "Замінити (один)",
    "replace_other": "Замінити (інший)",
    "replace_value_few": "Замінити значення (кілька)",
    "replace_value_many": "Замінити значення (багато)",
    "replace_value_one": "Замінити значення (один)",
    "replace_value_other": "Замінити значення (інший)",
    "define_what_entities_must_be_anonymized_few": "Визначте, які сутності потрібно анонімізувати (кілька)",
    "define_what_entities_must_be_anonymized_many": "Визначте, які сутності потрібно анонімізувати (багато)",
    "define_what_entities_must_be_anonymized_one": "Визначте, які сутності потрібно анонімізувати (одна)",
    "define_what_entities_must_be_anonymized_other": "Визначте, які сутності потрібно анонімізувати (інша)",
    "anonymize_entities_few": "Анонімізувати сутності (кілька)",
    "anonymize_entities_many": "Анонімізувати сутності (багато)",
    "anonymize_entities_one": "Анонімізувати сутності (одна)",
    "anonymize_entities_other": "Анонімізувати сутності (інша)"
  }
}