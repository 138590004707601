import type { App } from 'vue';
import { AppEventProcessor } from '@/analytics/AppEventProcessor';
import { SentryProvider } from '@/analytics/eventproviders/SentryProvider';
import { SegmentProvider } from '@/analytics/eventproviders/SegmentProvider';
import { ReditusProvider } from '@/analytics/eventproviders/ReditusProvider';

// import { HubspotProvider } from '@/analytics/eventproviders/HubspotProvider';

let eventProcessor: AppEventProcessor | null = null;

// TODO: Add remove redundant environments
const ALLOWED_ENVIRONMENTS = ['main', 'demo', 'development'];

function shouldInitializeProviders(): boolean {
  const env = import.meta.env.VITE_APP_ENV;
  return ALLOWED_ENVIRONMENTS.includes(env);
}

export function initializeEventProcessor(app: App): AppEventProcessor | null {
  if (!eventProcessor && shouldInitializeProviders()) {
    const segmentProvider = new SegmentProvider();
    const sentryProvider = new SentryProvider(app);
    const reditusProvider = new ReditusProvider();
    // const hubspotProvider = new HubspotProvider();
    const providers = [sentryProvider, segmentProvider, reditusProvider];
    eventProcessor = new AppEventProcessor(providers);
    eventProcessor.init();
  }

  return eventProcessor;
}

export function getEventProcessor(): AppEventProcessor | null {
  if (!eventProcessor) {
    shouldInitializeProviders() && console.log('Event processor is not initialized');
    return null;
  }
  return eventProcessor;
}
