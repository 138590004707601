export const setUserData = (data: Record<string, any>) => {
  const userId = localStorage.getItem('currentUserId');

  if (userId) {
    const storedData = localStorage.getItem(userId);
    const userData = storedData ? JSON.parse(storedData) : {};
    const updatedUserData = { ...userData, ...data };
    localStorage.setItem(userId, JSON.stringify(updatedUserData));
  }
};

export const getUserData = (): Record<string, any> | null => {
  const userId = localStorage.getItem('currentUserId');

  if (userId) {
    const storedData = localStorage.getItem(userId);
    return storedData ? JSON.parse(storedData) : null;
  }
  return null;
};

export const setLocaleForUser = (locale: string) => {
  setUserData({ i18n_locale: locale });
};

export const getLocaleForUser = (): string => {
  const userData = getUserData();
  return userData?.i18n_locale || 'en';
};
