{
  "addFileDrop": {
    "drag_drop_or": "Перетягніть та відпустіть або",
    "select_files": "Виберіть файли",
    "to_upload": "для завантаження",
    "error_uploading_file": "Помилка завантаження файлу",
    "error_uploading_files": "Помилка завантаження файлів"
  },
  "resourcesProject": {
    "show_uploaded_resources": "Показати завантажені ресурси",
    "knowledge": "Знання"
  },
  "resourcesProjectListItem": {
    "click_here_to_show_hide_explore_the_pages": "Натисніть тут, щоб {value} сторінки!",
    "delete": "Видалити",
    "chunks": "частини",
    "cancel": "Скасувати",
    "resource": "Ресурс",
    "size": "Розмір",
    "updated": "Оновлено",
    "page_content": "Зміст сторінки"
  },
  "resourcesProjectModal": {
    "knowledge": "Знання",
    "save": "Зберегти",
    "search_for_uploaded_knowledge": "Пошук завантажених знань...",
    "add_more": "Додати ще"
  }
}