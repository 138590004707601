<template>
  <Header
    :title="portalStore.portal?.name"
    :logo="portalStore?.portal?.settings?.logo?.src"
    is-portal
    class="text-[var(--omni-color-text)]"
  />
</template>

<script lang="ts" setup>
import { usePortalsStore } from '@/stores/portalsStore';
import Header from '@components/layout/Header.vue';

const portalStore = usePortalsStore();
</script>
