{
  "widgetsComponentDeleteWidgetModal": {
    "delete": "Delete",
    "widget": "widget",
    "do_you_really_want_to_remove": "Do you really want to remove",
    "widget_from": "widget from",
    "you_can_always_add_it_any_time_later": "You can always add it any time later."
  },
  "widgetsComponentPasswordProjectModal": {
    "password_is_required": "Password is required",
    "enter_the_project_password": "Enter the project password",
    "submit": "Submit"
  }
}