{
  "interactiveMindmapWidgetActions": {
    "click_here_to_download": "Click here to download",
    "language": "Language",
    "create_map": "Create map"
  },
  "interactiveMindmapWidgetMap": {
    "interactive_mind_map": "Interactive Mind Map",
    "an_interactive_mind_map_is_a_digital": "An Interactive Mind Map is a digital tool designed to facilitate the organization and visualization of information in a hierarchical structure. Created using dynamic web technologies, these mind maps offer interactivity features such as dragging, zooming, and node editing. They are especially useful for brainstorming sessions, project planning, and visualizing complex ideas or systems. Using an Interactive Mind Map allows you to easily categorize and link various pieces of information, making it easier to comprehend, analyze, and collaborate on.",
    "generate_a_mind_map_in": "Generate a mind map in",
    "something_went_awry": "Something went awry!",
    "we_are_unable_to_create_a_mindmap": "We are unable to create a mindmap based on the provided content. You can try to open widget settings and change them to get better results!",
    "translate_into": "Translate into",
    "language_every_name_property_s_value_of_in": "language every \"name\" property's value of in this JSON stringified object",
    "don_t_change_other_values": "Don't change other values. Ensure to use only double quotes, and start the response with",
    "give_me_a_valid_js_array_of_5_topics": "Give me a valid JS array of 5 topics (1-3 words each) to create Mind Map based on { context } in format \"[topic 1, topic 2, topic 3, topic 4, topic 5]\" in {languageValue} language",
    "please_generate_a_mind_map": "Please generate a mind map",
    "about": "about",
    "with": "with",
    "as_a_core_node": "as a core node"
  }
}