{
  "addNewPortalModal": {
    "add_new_portal": "Adicionar novo portal?",
    "add_portal": "Adicionar Portal",
    "the_portal_was_successfully_created": "O portal foi criado com sucesso.",
    "oops_something_went_wrong": "Oops! Algo deu errado."
  },
  "deletePortalModal": {
    "delete_portal": "Excluir portal",
    "confirm": "Confirmar",
    "you_are_about_to_delete_the_portal": "Você está prestes a excluir o portal. Isso não pode ser desfeito. Tem certeza de que deseja continuar?"
  },
  "portalSettingsDomain": {
    "domain": "Domínio",
    "your_domain_copied_to_clipboard": "Seu domínio foi copiado para a área de transferência",
    "example": "exemplo",
    "subdomain": "Subdomínio",
    "custom_domain": "Domínio personalizado",
    "this_domain_is_already_taken": "Este domínio já está sendo usado"
  },
  "portalSettingsGeneral": {
    "general": "Geral"
  },
  "portalSettingsGeneralForm": {
    "name": "Nome",
    "example_name": "Nome de exemplo",
    "brief_info": "Informações breves",
    "example_brief_info": "Informações breves de exemplo"
  },
  "portalSettingsPayments": {
    "payments_processing": "Processamento de pagamentos",
    "we_use_stripe_payment_platform": "Usamos a plataforma de pagamento Stripe para processar pagamentos entre você e seus clientes. Você precisa criar seu perfil e conectar um método de pagamento",
    "connect": "Conectar",
    "configure": "Configurar",
    "account": "conta"
  },
  "portalSettings": {
    "manage": "Gerenciar",
    "suspend_portal": "Suspender portal",
    "reactivate_portal": "Reativar portal",
    "delete_portal": "Excluir portal",
    "apply": "Aplicar",
    "portal_settings_successfully_updated": "Configurações do portal atualizadas com sucesso.",
    "oops_this_domain_is_already_taken": "Oops! Este domínio já está sendo usado.",
    "please_fill_in_all_required_fields": "Por favor, preencha todos os campos obrigatórios."
  },
  "suspendPortalModal": {
    "suspend_portal": "Suspender portal",
    "confirm": "Confirmar",
    "you_are_about_to_suspend_the_portal": "Você está prestes a suspender o portal. O portal ficará indisponível para seus usuários até que você o reative. Tem certeza de que deseja continuar?"
  }
}