{
  "chatWidget": {
    "we_are_creating_suggestion": "Estamos creando preguntas de sugerencia para ti. Pero puedes usar el chat ahora",
    "type_your_question": "Lo siento, aún no hemos creado ningún {title}.",
    "processing_your_request": "Procesando tu solicitud...",
    "ooops_we_have_t_found": "404 Ooops, no hemos encontrado el proyecto",
    "please_ask_the_owner_of": "Por favor, pregunta al propietario del proyecto para hacerlo público",
    "the_project_owner_needs_to": "El propietario del proyecto necesita actualizar la suscripción ya que la actual ha expirado.",
    "oops_it_seems_youve_reached": "¡Oops! Parece que has alcanzado el límite de mensajes. Para continuar, ajusta tu límite de mensajes en la configuración."
  }
}