{
  "CustomPromptOptionsEnumTranslations": {
    "adviser": "Consultor de IA",
    "sales_assistant": "Assistente de vendas",
    "shakespeare": "William Shakespeare",
    "professor": "Professor",
    "formal": "formal",
    "informal": "informal",
    "persuasive": "persuasivo",
    "collaborative": "colaborativo",
    "creative": "criativo",
    "emojis": "emojis",
    "mark_keywords": "destacar palavras-chave principais",
    "subheaders": "subtítulos",
    "billet_points_format": "formato de tópicos"
  }
}