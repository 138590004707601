{
  "serviceCredentials": {
    "you_can_define_your_credentials": "Você pode definir suas credenciais e usar seus próprios serviços para armazenar e processar informações.",
    "save_credentials": "Salvar credenciais",
    "keys": "Chaves",
    "key": "Chave",
    "service_credentials_were_updated": "As credenciais do serviço foram atualizadas!",
    "credentials_are_valid": "As credenciais são válidas!",
    "credentials_are_invalid": "As credenciais são inválidas!",
    "something_went_wrong": "Algo deu errado!",
    "cancel": "Cancelar"
  }
}