{
  "tableDialogSQL": {
    "the_sql_query_for_your_prompt": "La query SQL per il tuo prompt"
  },
  "tableWidget": {
    "ask_your_questions_to_get_a_data": "Fai le tue domande per ottenere dei dati.",
    "show_sql": "Mostra SQL",
    "export_to_csv": "Esporta in CSV",
    "failed_to_load_settings": "Impossibile caricare le impostazioni",
    "something_went_wrong": "Qualcosa è andato storto"
  },
  "tableWidgetTable": {
    "no_data": "Nessun dato"
  }
}