{
  "package": {
    "popular": "популярний",
    "month": "місяць",
    "chosen": "Обраний",
    "contact_us": "Зв'яжіться з нами",
    "package": "Пакет",
    "change": "Змінити",
    "subscribe": "Підписатися",
    "projects": "Проекти",
    "characters": "Персонажі",
    "add_units": "Додати одиниці",
    "units": "одиниці",
    "informers": "Інформатори",
    "widgets": "Віджети",
    "file_extensions": "Розширення файлів",
    "alternative_ai": "Альтернативний ШІ",
    "updates": "Оновлення",
    "extended_customization": "Розширена настройка"
  },
  "packageEnterpriseModal": {
    "contact_us": "Зв'яжіться з нами",
    "submit": "Надіслати",
    "please_tell_us_more_about_your_use_case": "Будь ласка, розкажіть нам більше про ваш випадок використання",
    "share_your_expectations_from_the_plan": "Поділіться своїми очікуваннями від плану, такими як: необхідні функції, кількість користувачів, обмеження на персонажів, моделі ШІ тощо."
  },
  "packageFree": {
    "beginner_plan": "Початковий план",
    "for_beginners_and_micro_projects": "Для початківців та мікропроектів",
    "free": "Безкоштовно",
    "project": "Проект",
    "characters": "Персонажі",
    "overquote": "перевищення квоти",
    "calls": "Дзвінки",
    "balance": "Баланс",
    "try_now": "Спробуйте зараз!"
  },
  "packages": {
    "beginner_plan": "Початковий план",
    "enterprise": "Enterprise",
    "custom_software_development": "Розробка власного програмного забезпечення з присвяченою платформою OmniMind",
    "integration_to_your_business": "Інтеграція в ваш бізнес",
    "custom_dataflow": "Власний потік даних",
    "dedicated_platform": "Присвячена платформа",
    "100_data_privacy": "100% конфіденційність даних",
    "invalid_number": "Недійсний номер",
    "the_package_is_successfully_installed": "Пакет успішно встановлено!",
    "the_package_is_either_not_available": "Пакет недоступний або вже встановлено."
  },
  "ResearcherDescription": "Для початкових досліджень та академічного дослідження",
  "ProDescription": "Професійні інструменти для фахівців та досвідчених дослідників",
  "EinsteinDescription": "Комплексна підтримка провідних вчених та інноваторів",
  "EnterpriseDescription": "Розробка власного програмного забезпечення з присвяченою платформою OmniMind"
}