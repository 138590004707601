{
  "voiceWidget": {
    "ooops_we_have_t_found": "404 Oops, non abbiamo trovato il progetto",
    "ooops_we_have_t_found_many": "404 Oops, non abbiamo trovato i progetti",
    "ooops_we_have_t_found_one": "404 Oops, non abbiamo trovato il progetto",
    "oops_it_seems_youve_reached": "Oops! Sembra che tu abbia raggiunto il limite di messaggi consentiti. Per continuare, ti preghiamo di regolare il limite di messaggi nelle impostazioni.",
    "please_ask_the_owner_of": "401 Si prega di chiedere al proprietario del progetto di renderlo pubblico",
    "please_ask_the_owner_of_many": "401 Si prega di chiedere ai proprietari dei progetti di renderli pubblici",
    "please_ask_the_owner_of_one": "401 Si prega di chiedere al proprietario del progetto di renderlo pubblico"
  }
}