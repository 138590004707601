import { gql } from '@apollo/client/core';
import { ProjectFields } from '../fragments';

export const GET_PROJECTS = gql`
  ${ProjectFields}
  query GetProjects($id: uuid!) {
    projects(
      where: { user_to_projects: { user_id: { _eq: $id } } }
      order_by: { created_at: asc }
    ) {
      ...ProjectFields
    }
  }
`;
