{
  "airtableInformerInformers": {
    "you_can_provide_your_own_airtable": "Puedes proporcionar tu propio Airtable",
    "personal_access_token": "Token de Acceso Personal",
    "in_your_services_credentials_settings": "en la configuración de credenciales de tus servicios.",
    "click_here": "¡Haz clic aquí!",
    "if_you_have_not_generated_yet_a_token": "Si aún no has generado un token, dirígete a tu",
    "airtable_platform": "plataforma de Airtable",
    "and_generate_one": "y genera uno",
    "please_make_sure_to_select": "Asegúrate de seleccionar",
    "and": "y",
    "scopes_and_related_bases": "alcances y bases relacionadas",
    "your_stored_integration_token": "Tu Token de Integración almacenado",
    "is_the_token_incorrect": "¿El token es incorrecto? Puedes cambiarlo en la configuración de credenciales de servicios.",
    "services_credentials_settings": "configuración de credenciales de servicios",
    "fetch_airtable_bases": "Obtener bases de Airtable",
    "select_base": "Seleccionar base",
    "select_table": "Seleccionar tabla"
  }
}