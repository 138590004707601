{
  "flashcardsWidget": {
    "export_to_quizlet": "Експортувати до Quizlet",
    "export_to_cram": "Експортувати до Cram",
    "how_to_import_into_cram": "Як імпортувати в Cram",
    "begin_by_visiting_the_cram": "Почніть з відвідування веб-сайту Cram та виберіть опцію \"Створити навчальні картки\". Потім виберіть \"Імпортувати набір навчальних карток\". Скопіюйте свої дані, переконавшись, що кожен термін та його визначення розділені",
    "and_different_cards_are": "і різні картки відрізняються",
    "new_line": "Новий рядок",
    "paste_this_into_the": "Вставте це у поле введення з підписом \"КОПІЮВАТИ І ВСТАВИТИ СВОЇ ДАНІ:\", і натисніть \"Імпортувати дані\". Насолоджуйтесь сеансом навчання!",
    "oops_it_seems_youve_reached_your": "Ой! Здається, ви досягли обмеження на кількість повідомлень. Щоб продовжити, будь ласка, змініть обмеження на кількість повідомлень у налаштуваннях.",
    "question": "Питання",
    "tab": "Вкладка",
    "my_answer": "Моя відповідь",
    "correct_answer": "Правильна відповідь",
    "evaluate_my_answer": "Оцініть мою відповідь за шкалою від 1 до 5 з урахуванням її точності та повноти порівняно з правильною відповіддю, враховуючи контекст { context }. Використовуйте наступні критерії для оцінки: Якщо відповідь повністю неправильна або нестосовна до питання і вона свідчить про незрозуміння теми або питання, оцініть \"1\". Якщо відповідь відповідає питанню, але має серйозні неточності або пропуски, але вона виявляє деяке розуміння, але також значні неправильні трактування або прогалини в знаннях, оцініть \"2\". Якщо відповідь дещо правильна, але неповна або має незначні неточності, а відповідь демонструє справедливе розуміння теми, але не має деталей або точності, оцініть \"3\". Якщо відповідь в основному правильна і охоплює більшість аспектів питання, але можуть бути пропущені незначні деталі або нюанси, але загальне розуміння добре, оцініть \"4\". Якщо відповідь повністю правильна і надає повну відповідь на питання, оцініть \"5\". Використовуйте формат: \"число; пояснення\" для вашої відповіді.",
    "something_went_awry": "Щось пішло не так!",
    "your_rate_limit_exceeded": "Згенеруйте {formAmount} питань мовою {formLanguage} з { context } у ВАЛІДНОМУ JSON ФОРМАТІ. Використовуйте шаблон",
    "answers_should_only_contain_the_essential": "Відповіді повинні містити лише основні ключові терміни або фрази, без розширення.",
    "we_ve_found_parsed_length_questions": "Ми знайшли {parsedLength} питань наразі. Додавання більшого контенту може допомогти знайти ще більше!"
  },
  "flashcardsWidgetActions": {
    "view_all_questions": "Переглянути всі питання",
    "delete_card": "Видалити картку",
    "edit_card": "Редагувати картку",
    "export_to_quizlet": "Експортувати до Quizlet",
    "export_to_anki": "Експортувати до Anki",
    "export_to_brainscape": "Експортувати до Brainscape",
    "export_to_cram": "Експортувати до Cram",
    "regenerate_cards": "Перегенерувати картки",
    "clear_all_cards": "Очистити всі картки"
  },
  "flashcardsWidgetFeedback": {
    "feedback": "Зворотній зв'язок"
  },
  "flashcardsWidgetForm": {
    "select_the_number_and_the_language": "Виберіть кількість та мову навчальних карток",
    "cards_number": "Кількість карток",
    "enter_quantity": "Введіть кількість",
    "language": "Мова",
    "generate": "Генерувати",
    "original": "Оригінал"
  },
  "flashcardsWidgetNavigation": {
    "back": "Назад",
    "step_and_length_of_questions": "{currentStep} з {questionsLength} питань",
    "next": "Далі",
    "skip": "Пропустити"
  },
  "flashcardsWidgetQuestion": {
    "question": "Питання",
    "enter_your_answer_here": "Введіть свою відповідь тут...",
    "answer": "Відповідь",
    "your_answer": "Ваша відповідь",
    "correct_answer": "Правильна відповідь"
  },
  "flashcardsWidgetQuestionEditor": {
    "edit_card": "Редагувати картку",
    "save": "Зберегти",
    "question": "Питання",
    "answer": "Відповідь"
  },
  "flashcardsWidgetQuestionFooter": {
    "check_my_answer": "Перевірити мою відповідь",
    "hide_answer": "Приховати відповідь",
    "i_want_to_answer_it_by_myself": "Я хочу відповісти самостійно",
    "show_answer": "Показати відповідь"
  },
  "flashcardsWidgetQuestionsList": {
    "looks_like_it_s_pretty_empty_here": "Схоже, тут досить порожньо!",
    "start_adding_some_cards_to_see_them_appear": "Почніть додавати деякі картки, щоб побачити їх."
  }
}
