{
  "baseDropboxFile": {
    "schema": {
      "folder_name": "Nome cartella Dropbox",
      "folder_description": "Nome cartella corrente",
      "url_title": "URL file Dropbox",
      "url_description": "L'URL al file Dropbox"
    },
    "schemaUi": {
      "label": "File Dropbox",
      "description": "Qui puoi incollare l'URL del tuo file su Dropbox. Assicurati che il file sia pubblicamente accessibile, altrimenti otterrai un errore nella fase di elaborazione",
      "placeholder": "Inserisci l'URL"
    }
  },
  "baseDropboxFolder": {
    "schema": {
      "url_title": "URL cartella Dropbox",
      "url_description": "L'URL alla cartella Dropbox"
    },
    "schemaUi": {
      "label": "Cartella Dropbox",
      "description": "Qui puoi incollare il link alla tua cartella Dropbox. Assicurati che la cartella e tutti i file al suo interno siano pubblicamente accessibili, altrimenti otterrai un errore nella fase di elaborazione",
      "placeholder": "Inserisci l'URL"
    }
  },
  "baseGoogleDriveFile": {
    "schema": {
      "folder_name": "Nome cartella Gdrive",
      "folder_description": "Il nome per la cartella Gdrive",
      "url_title": "URL file Gdrive",
      "url_description": "L'URL al file Gdrive"
    },
    "schemaUi": {
      "label": "File Gdrive",
      "description": "Qui puoi incollare il link al tuo file su Google Drive. Assicurati che il file sia pubblicamente accessibile, altrimenti otterrai un errore nella fase di elaborazione",
      "placeholder": "Inserisci l'URL"
    }
  },
  "baseGoogleDriveFolder": {
    "schema": {
      "url_title": "URL cartella Gdrive",
      "url_description": "L'URL alla cartella Gdrive"
    },
    "schemaUi": {
      "label": "Cartella Gdrive",
      "description": "Qui puoi aggiungere il link alla tua cartella Google Drive. Assicurati che la cartella e tutti i file al suo interno siano pubblicamente accessibili, altrimenti otterrai un errore nella fase di elaborazione",
      "placeholder": "Inserisci l'URL"
    }
  },
  "baseOneDriveFile": {
    "schema": {
      "parent_title": "Nome cartella Gdrive",
      "url_title": "URL file OneDrive",
      "url_description": "L'URL al file OneDrive"
    },
    "schemaUi": {
      "label": "File OneDrive",
      "description": "Qui puoi incollare il link al tuo file di OneDrive. Assicurati che il file sia pubblicamente accessibile, altrimenti otterrai un errore nella fase di elaborazione",
      "placeholder": "Inserisci l'URL"
    }
  },
  "baseOneDriveFolder": {
    "schema": {
      "url_title": "URL cartella OneDrive",
      "url_description": "L'URL alla cartella OneDrive"
    },
    "schemaUi": {
      "label": "Cartella OneDrive",
      "description": "Qui puoi aggiungere il link alla tua cartella OneDrive. Assicurati che la cartella e tutti i file al suo interno siano pubblicamente accessibili, altrimenti otterrai un errore nella fase di elaborazione",
      "placeholder": "Inserisci l'URL"
    }
  },
  "baseXmlSitemap": {
    "schema": {
      "url_title": "URL XML",
      "url_description": "Digita l'URL del tuo sitemap"
    },
    "schemaUi": {
      "label": "Sitemap XML",
      "description": "Se hai il file sitemap con tutti i link che desideri indicizzare, puoi definire liberamente l'URL del file sitemap",
      "placeholder": "https://esempio.com/sitemap.xml"
    }
  },
  "baseYoutubeChannel": {
    "schema": {
      "url_title": "URL canale",
      "url_description": "Premi su Condividi sul canale YouTube e copia il link nella casella di input sopra",
      "language_title": "La lingua preferita"
    },
    "schemaUi": {
      "label": "Canale YouTube",
      "description": "Incolla il link al canale YouTube che ha i sottotitoli nella lingua specificata di seguito. Nota: l'informer elabora solo video con sottotitoli, altrimenti otterrai un errore.",
      "placeholder": "Inserisci l'URL",
      "placeholder_2": "it"
    }
  },
  "baseYoutubePlaylist": {
    "schema": {
      "url_title": "URL playlist",
      "url_description": "Premi su Condividi sulla playlist YouTube e copia il link nella casella di input sopra",
      "language_title": "La lingua preferita"
    },
    "schemaUi": {
      "label": "Playlist YouTube",
      "description": "Incolla il link alla playlist YouTube che ha i sottotitoli nella lingua specificata di seguito. Nota: l'informer elabora solo video con sottotitoli, altrimenti otterrai un errore.",
      "placeholder": "Inserisci l'URL",
      "placeholder_2": "it"
    }
  },
  "baseZendesk": {
    "schema": {
      "url_title": "URL Zendesk",
      "url_description": "Inserisci il link al centro assistenza Zendesk"
    },
    "schemaUi": {
      "label": "Zendesk",
      "description": "Incolla il link al centro assistenza Zendesk.",
      "placeholder": "Inserisci l'URL"
    }
  },
  "baseText": {
    "schema": {
      "content_title": "Testo",
      "content_description": "Aggiungi il testo che desideri aggiungere al tuo progetto",
      "metadata_title": "Metadati aggiuntivi"
    },
    "schemaUi": {
      "label": "Testo",
      "description": "Aggiungi il testo al tuo progetto",
      "placeholder": "Inserisci il testo"
    }
  },
  "baseUrl": {
    "schema": {
      "url_title": "URL file",
      "url_description": "L'URL del file che verrà aggiunto al progetto"
    },
    "schemaUi": {
      "label": "File URL",
      "description": "Assicurati che il file sia pubblico su Internet, altrimenti otterrai un errore di autorizzazione",
      "placeholder": "esempio.com/file.pdf"
    }
  }
}