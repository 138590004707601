<template>
  <div class="flex flex-col">
    <BaseLabelPopup v-if="label && tooltip" :info-text="tooltip">
      {{ label }}
    </BaseLabelPopup>
    <BaseLabel v-else-if="label" :for="id" :required="required">
      {{ label }}
      <slot name="end" />
    </BaseLabel>

    <slot v-bind="props" />

    <BaseErrorMessage v-if="error">
      {{ error }}
    </BaseErrorMessage>

    <BaseHelperMessage
      v-if="help"
      :id="ariaDescribedBy"
      class="mt-1 text-sm text-gray-500 dark:text-gray-400"
    >
      {{ help }}
    </BaseHelperMessage>
  </div>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent, provide } from 'vue';
import BaseLabelPopup from '@components/base/BaseLabelPopup.vue';

const BaseLabel = defineAsyncComponent(() => import('../../components/base/BaseLabel.vue'));
const BaseHelperMessage = defineAsyncComponent(
  () => import('../../components/base/BaseHelperMessage.vue'),
);
const BaseErrorMessage = defineAsyncComponent(
  () => import('../../components/base/BaseErrorMessage.vue'),
);

const props = defineProps({
  id: {
    type: String,
    default: () => `field-${Math.random() * 1000}`,
  },
  label: {
    type: String,
    default: '',
  },
  tooltip: {
    type: String,
    default: '',
  },
  required: Boolean,
  error: {
    type: String,
    default: '',
  },
  help: {
    type: String,
    default: '',
  },
});

const ariaDescribedBy = computed(() => {
  return !!props.help ? `help-${Math.random() * 1000}` : null;
});

provide(
  'field',
  computed(() => {
    return {
      ...props,
      invalid: !!props.error,
      ariaDescribedBy: ariaDescribedBy.value,
    };
  }),
);
</script>

<style scoped></style>
