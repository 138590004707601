{
  "bulkCsvInformerInformers": {
    "select_csv_files_to_upload": "Виберіть CSV-файли для завантаження",
    "bulk_upload_via_csv": "Функція масового завантаження через CSV дозволяє вам завантажувати посилання, перераховані в файлі CSV.",
    "drag_drop_or": "Перетягніть і відпустіть або",
    "select_a_csv_file": "Виберіть файл CSV",
    "to_upload": "для завантаження",
    "download_example_csv": "Завантажити приклад CSV",
    "in_column": "У стовпці",
    "we_found": "ми знайшли",
    "valid_links": "дійсних посилань",
    "there_are_no_valid_links_in_this_column": "У цьому стовпці немає дійсних посилань.",
    "error_uploading_file": "Помилка завантаження файлу:"
  },
  "bulkCsvInformerMapperInformers": {
    "map_file_columns": "Відображення стовпців файлу",
    "specify_the_corresponding_column": "Вкажіть відповідний стовпець у файлі CSV, який містить URL-адреси. Цей стовпець повинен бути вибраний для обробки. Якщо URL-адреси в стовпці не містять \"https://\", система автоматично додасть його.",
    "link": "Посилання"
  },
  "csvInformerInformers": {
    "select_csv_file_to_upload": "Виберіть файл CSV для завантаження",
    "drag_drop_or": "Перетягніть і відпустіть або",
    "select_a_csv_file": "Виберіть файл CSV",
    "to_upload": "для завантаження",
    "download_example_csv": "Завантажити приклад CSV",
    "invalid_file_type": "Недійсний тип файлу. Будь ласка, завантажте файл CSV.",
    "invalid_mime_type": "Недійсний тип MIME. Файл, схоже, не є CSV.",
    "error_parsing_csv": "Помилка розбору CSV:",
    "error_uploading_file": "Помилка завантаження файлу:",
    "something_went_awry": "Щось пішло не так!"
  },
  "csvInformerAdvancedSettingsInformers": {
    "advanced_settings": "Розширені налаштування",
    "hide_settings": "Приховати налаштування",
    "show_settings": "Показати налаштування",
    "select_title_columns": "Виберіть стовпці заголовків",
    "using_a_direct_custom_prompt": "Використання прямого користувацького підказки необхідно лише у спеціальних випадках, і це вимкне основну форму.",
    "select_metadata_columns": "Виберіть стовпці метаданих"
  },
  "csvInformerMapperInformers": {
    "map_file_columns": "Відображення стовпців файлу",
    "content": "Вміст",
    "meta": "Мета"
  },
  "customiseColumnsListInformers": {
    "name": "Назва",
    "label": "Мітка",
    "select_all": "Вибрати все"
  },
  "previewCSVInformers": {
    "preview_csv_data": "Попередній перегляд даних CSV",
    "more_data": "Більше даних..."
  }
}