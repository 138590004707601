{
  "explainWidget": {
    "we_are_creating_suggestion_questions": "Stiamo creando domande suggerite per te. Ma puoi usare la chat ora",
    "sorry_we_could_not_create_any_examples": "Spiacenti, non siamo riusciti a creare esempi di domande che puoi fare al bot.",
    "loading": "Caricamento...",
    "explanation": "Spiegazione",
    "please_ask_the_owner_of_the": "Chiedi gentilmente al proprietario del progetto di renderlo pubblico",
    "the_project_owner_needs_to": "Il proprietario del progetto deve aggiornare l'abbonamento in quanto quello attuale è scaduto.",
    "404_oops_we_havet_found": "404 Oops, non abbiamo trovato il progetto",
    "oops_it_seems_youve_reached": "Oops! Sembra che tu abbia raggiunto il limite di messaggi consentiti. Per continuare, regola gentilmente il tuo limite di messaggi nelle impostazioni."
  }
}