import { showAlert } from '@/composables/mixin-alert';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
const UPGRADE_PLAN_PATH = '/profile?tab=packages';

export const useMaximumNumberOfProjectsAlert = () => {
  const { t } = useI18n();
  const router = useRouter();

  const showMaxProjectAlert = async () => {
    try {
      await showAlert(
        'error',
        t('useMaximumNumberOfProjectsAlert.limit_error'),
        '50%',
        5000,
        true,
        t('useMaximumNumberOfProjectsAlert.upgrade'),
      ).then((result) => {
        if (result.isConfirmed) {
          router.push(UPGRADE_PLAN_PATH);
        }
      });
    } catch (error) {
      console.error('Error showing alert:', error);
    }
  };

  return {
    showMaxProjectAlert,
  };
};
