{
  "airtableInformerInformers": {
    "you_can_provide_your_own_airtable": "Puoi fornire il tuo Airtable",
    "personal_access_token": "Token di accesso personale",
    "in_your_services_credentials_settings": "nelle impostazioni delle credenziali dei servizi.",
    "click_here": "Clicca qui!",
    "if_you_have_not_generated_yet_a_token": "Se non hai ancora generato un token, vai alla tua",
    "airtable_platform": "piattaforma Airtable",
    "and_generate_one": "e generane uno",
    "please_make_sure_to_select": "Assicurati di selezionare",
    "and": "e",
    "scopes_and_related_bases": "ambiti e basi correlate",
    "your_stored_integration_token": "Il tuo token di integrazione memorizzato",
    "is_the_token_incorrect": "Il token è errato? Puoi cambiarlo nelle impostazioni delle credenziali dei servizi.",
    "services_credentials_settings": "impostazioni delle credenziali dei servizi",
    "fetch_airtable_bases": "Recupera le basi di Airtable",
    "select_base": "Seleziona la base",
    "select_table": "Seleziona la tabella"
  }
}