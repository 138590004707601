{
  "addFileDrop": {
    "drag_drop_or": "Arrastrar y soltar o",
    "select_files": "Seleccionar archivos",
    "to_upload": "para subir",
    "error_uploading_file": "Error al subir el archivo",
    "error_uploading_files": "Error al subir los archivos"
  },
  "resourcesProject": {
    "show_uploaded_resources": "Mostrar recursos subidos",
    "knowledge": "Conocimiento"
  },
  "resourcesProjectListItem": {
    "click_here_to_show_hide_explore_the_pages": "Haz clic aquí para {value} las páginas!",
    "delete": "Eliminar",
    "chunks": "fragmentos",
    "cancel": "Cancelar",
    "resource": "Recurso",
    "size": "Tamaño",
    "updated": "Actualizado",
    "page_content": "Contenido de la página"
  },
  "resourcesProjectModal": {
    "knowledge": "Conocimiento",
    "save": "Guardar",
    "search_for_uploaded_knowledge": "Buscar conocimiento subido...",
    "add_more": "Agregar más"
  }
}