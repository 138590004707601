import type { ResourceItemInterface } from '@/components/project/resources/interfaces/ResourceItemInterface';
import { StoreId } from '@/stores/StoreId';
import { KNOWLEDGE_QUICK_SEARCH } from '@/api';
import { defineStore } from 'pinia';
import { createApolloClient } from '@nhost/apollo';
import { nhost } from '@/lib/nhost';
import { getQa, type ApiContext } from '@/api';
import { useWidgetsStore } from '@/stores/widgetsStore';

const apolloClient = createApolloClient({ nhost });

export const useResourcesStore = defineStore(StoreId.Resources, {
  state: (): {
    resourcesMap: Record<string, ResourceItemInterface[]>;
    qaMap: Record<string, ResourceItemInterface[]>;
  } => ({
    resourcesMap: {},
    qaMap: {},
  }),
  getters: {
    resourcesByProjectId: (state) => (id: string) => state.resourcesMap[id],
    qaByProjectId: (state) => (id: string) => state.qaMap[id],
  },
  actions: {
    async fetchResources(
      project_id: string,
      title = '%',
      url = '%',
      startDate = '2024-01-01T00:00:00Z',
      endDate = new Date().toISOString(),
    ) {
      if (!project_id) {
        return;
      }
      const variables = {
        project_id,
        title,
        url,
        startDate,
        endDate,
      };

      const { data } = await apolloClient.query({
        query: KNOWLEDGE_QUICK_SEARCH,
        variables,
      });
      if (data.knowledge) {
        const widgetStore = useWidgetsStore();
        this.resourcesMap[project_id] = data.knowledge.map((x: any) => ({
          ...x,
          widget_type: widgetStore.getWidgetTypeIdById(x?.widget_type_id),
        }));
      }
    },
    cleanData(projectId: string) {
      if (this.resourcesMap[projectId]?.length) {
        delete this.resourcesMap[projectId];
      }
    },
    async fetchQa(
      params: { limit?: number; offset?: number; query?: string },
      context: ApiContext,
    ) {
      const qas = await getQa(params, context);
      this.qaMap[context.projectId as string] = qas ?? [];
    },
  },
});
