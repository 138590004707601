{
  "baseAiHelp": {
    "book_call": "Prenota una chiamata",
    "hide": "Nascondi"
  },
  "baseModal": {
    "ok": "Ok",
    "cancel": "Annulla",
    "delete": "Elimina"
  },
  "baseModalFooter": {
    "cancel_text": "Annulla",
    "submit_text": "Salva"
  },
  "baseDatePicker": {
    "to": "a"
  },
  "basePagination": {
    "first": "Primo",
    "prev": "Precedente",
    "next": "Successivo",
    "last": "Ultimo"
  },
  "baseSelect": {
    "choose": "Scegli...",
    "select_all": "Seleziona tutto"
  },
  "baseSelectTable": {
    "select_all": "Seleziona tutto",
    "wordpress_site_types": "TIPI DI SITO WORDPRESS"
  },
  "baseSpeechToTextButton": {
    "hold_and_ask_your_question": "Tieni premuto e fai la tua domanda. Specifica una lingua se necessario cliccando sul pulsante successivo ('Altre opzioni')"
  },
  "baseStepper": {
    "previous": "Precedente",
    "next": "Successivo"
  },
  "baseTextToSpeechButton": {
    "text_to_voice": "Testo in voce",
    "text_to_speech_syntesis": "La sintesi del testo in voce non supporta la lingua {languageValue}"
  },
  "baseUnsavedModal": {
    "all_your_unsaved_changes": "Tutte le modifiche non salvate andranno perse. Salvare le modifiche prima di lasciare la pagina?",
    "save_changes": "Salva modifiche",
    "you_have_unsaved_changes": "Hai delle modifiche non salvate"
  },
  "buttonInputViewCopyText": {
    "copied_to_clipboard": "Copiato negli appunti!"
  }
}