{
  "portalPackageItem": {
    "name_is_not_provided": "Nome não fornecido",
    "click_to_edit_the_package": "Clique para editar o pacote",
    "click_to_delete_the_package": "Clique para excluir o pacote",
    "package_name": "Nome do pacote",
    "your_package_name": "Nome do seu pacote",
    "description": "Descrição",
    "your_package_description": "Descrição do seu pacote",
    "model": "Modelo",
    "package_price": "Preço do pacote",
    "subscription_cost_for_the_package": "Custo da assinatura para o pacote",
    "cost_per_symbols": "Custo por {symbols_quantity} símbolos",
    "cost_for_characters": "Custo para {characters_quantity} caracteres ao consultar a OpenAI (projetado para ajudar a evitar gastos excessivos do usuário). Em média, uma solicitação tem {average} caracteres",
    "approx_number_of_requests": "número aproximado de solicitações",
    "trial_days": "Dias de teste",
    "links": "Links",
    "create_portal_link": "Criar link do portal",
    "delete_this_project": "Excluir este projeto?",
    "delete_project": "Excluir projeto",
    "package_will_be_deleted": "O pacote será excluído. Isso não pode ser desfeito",
    "the_item_has_been_successfully_deleted": "O item foi excluído com sucesso."
  },
  "portalPackages": {
    "no_packages_so_far": "Nenhum pacote até o momento",
    "apply": "Aplicar",
    "stripe_account_is_not_configured": "Conta Stripe não está configurada",
    "packages_successfully_saved": "Pacotes salvos com sucesso"
  }
}