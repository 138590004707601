{
  "agent_page": {
    "agent_settings": "Configuración del agente",
    "select_model": "Seleccionar modelo",
    "prompt_editor": "Editor de Prompts",
    "prompt_library": "Biblioteca de Prompts"
  },
  "select_model": {
    "select_provider": "Seleccionar proveedor",
    "select_model": "Seleccionar modelo"
  },
  "prompt_editor": {
    "write_your_instruction": "Escribe tu instrucción",
    "variables": "Variables",
    "save_prompt": "Guardar Prompt",
    "prompt_has_been_updated": "¡El prompt ha sido actualizado!",
    "model_has_been_updated": "¡El modelo ha sido actualizado!",
    "prompt_view": "Vista del prompt",
    "edit_prompt": "Editar prompt",
    "cancel": "Cancelar"
  },
  "prompt_library": {
    "no_save_prompts": "No hay prompts guardados",
    "select_a_department_to_see_tasks": "Selecciona un departamento para ver las tareas"
  }
}