{
  "infoMessageOne": {
    "here_you_can_specify_the_depth_of_the_web_site_scraping": "Aquí puedes especificar la profundidad del rastreo del sitio web",
    "not_deeper_than_the_specified_url": "no más profundo que la URL especificada",
    "the_scraper_goes_one_level_deeper": "el rastreador va un nivel más profundo pero no sigue los enlaces en el segundo nivel",
    "the_scraper_goes_two_levels_deeper": "el rastreador va dos niveles más profundo pero no sigue los enlaces en el tercer nivel",
    "the_scraper_goes_three_levels_deeper": "el rastreador va tres niveles más profundo pero no sigue los enlaces en el cuarto nivel"
  },
  "webInformerAdvancedSettingItems": {
    "depth": "Profundidad:",
    "page_limit": "Límite de páginas:",
    "enter_page_limit": "Ingrese el límite de páginas",
    "page_filter": "Filtro de páginas:",
    "glob_mask": "máscara glob",
    "define_the_update_period": "Definir el período de actualización",
    "if_you_are_not_sure_how_many_links": "Si no estás seguro de cuántos enlaces hay en el sitio, puedes definir el límite de páginas rastreadas.",
    "here_you_can_specify_the_regularity": "Aquí puedes especificar la regularidad con la que se volverá a rastrear el enlace especificado para actualizar la información.",
    "you_can_lock_scraper_to_parse": "Puedes bloquear el rastreador para analizar páginas con un patrón de URL específico según",
    "hourly": "cada hora",
    "daily": "diariamente",
    "weekly": "semanalmente",
    "monthly": "mensualmente"
  },
  "webInformerBtnAdvancedSetting": {
    "advanced_link_setting": "Configuración avanzada de enlaces"
  },
  "webList": {
    "preview": "Vista previa"
  }
}