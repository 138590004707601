{
  "databaseSettings": {
    "databaseSettings": "Impostazioni del database",
    "configure_the_connection_to_your_database": "Configura la connessione al tuo database e seleziona le tabelle che desideri utilizzare nelle tue query.",
    "cancel": "Annulla",
    "back": "Indietro",
    "save": "Salva",
    "fetch_resources": "Recupera risorse",
    "resources_fetched_successfully": "Risorse recuperate con successo",
    "something_went_wrong": "Qualcosa è andato storto",
    "failed_to_load_settings": "Impossibile caricare le impostazioni"
  },
  "databaseSettingsConnectInformation": {
    "connect_information": "Informazioni di connessione",
    "type_of_database": "Tipo di database",
    "host": "Host",
    "port": "Porta",
    "database": "Database",
    "username": "Nome utente",
    "password": "Password"
  },
  "databaseSettingsForm": {
    "host": "Host"
  },
  "databaseSettingsHost": {
    "type_of_database": "Tipo di database",
    "select_type_of_connection": "Seleziona il tipo di connessione",
    "host": "Host",
    "address_to_your_database": "indirizzo del tuo database",
    "address_of_your_db": "Indirizzo del tuo DB",
    "port_to_your_database": "porta del tuo database",
    "port_of_your_db": "Porta del tuo DB",
    "port": "Porta",
    "username": "Nome utente",
    "username_to_your_database": "nome utente del tuo database",
    "username_of_your_db": "Nome utente del tuo DB",
    "password": "Password",
    "password_to_your_database": "password del tuo database",
    "password_of_your_db": "Password del tuo DB",
    "database": "Database",
    "your_database_name": "nome del tuo database",
    "database_name": "Nome del tuo database nel DB"
  },
  "databaseSettingsTables": {
    "general_instructions": "Istruzioni generali",
    "there_goes_general_description_about": "Qui viene inserita una descrizione generale sul database, sulla sua logica o su alcuni dettagli specifici del progetto...",
    "select_all": "Seleziona tutto",
    "clean_all_selected": "Pulisci tutto selezionato",
    "search": "Cerca...",
    "active": "Attivo",
    "table": "Tabella",
    "comment": "Commento",
    "description": "Descrizione"
  },
  "databaseSettingsURL": {
    "host": "Host",
    "address_to_your_database": "indirizzo del tuo database",
    "address_of_your_db": "Indirizzo del tuo DB"
  }
}