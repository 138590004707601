{
  "knowledgeFilterSourcesModal": {
    "filter": "Filter",
    "apply": "Anwenden",
    "cancel": "Abbrechen",
    "by_title": "Nach Titel",
    "by_period": "Nach Zeitraum",
    "search_for_resource_title": "Nach Ressourcentitel suchen..."
  },
  "knowledgeSources": {
    "no_data_so_far": "Bisher keine Daten.",
    "type": "Typ",
    "title": "Titel",
    "date": "Datum",
    "actions": "Aktionen",
    "delete_these_items": "Diese Elemente löschen?",
    "are_you_sure_you_want_to_delete_selected_items": "Sind Sie sicher, dass Sie die ausgewählten Elemente löschen möchten?",
    "reupload_these_items": "Diese Elemente erneut hochladen?",
    "this_action_cannot_be_undone": "Diese Aktion kann nicht rückgängig gemacht werden.",
    "are_you_sure_you_want_to_delete_and_reupload": "Sind Sie sicher, dass Sie die ausgewählten Elemente löschen und erneut hochladen möchten?",
    "reupload": "Erneut hochladen",
    "the_resources_were_removed": "Die Ressourcen wurden entfernt.",
    "an_error_occurred_while_deleting_resources": "Beim Löschen der Ressourcen ist ein Fehler aufgetreten",
    "sorry_we_re_unable_to_open_this_resource": "Entschuldigung, wir können diese Ressource nicht direkt öffnen, da sie keine Textwissensressource ist.",
    "show_progress": "Hier klicken, um den Fortschritt anzuzeigen"
  },
  "knowledgeSourcesActions": {
    "view": "Anzeigen",
    "refresh_from_source_coming_soon": "Aktualisieren aus Quelle (Demnächst verfügbar!)",
    "delete": "Löschen"
  },
  "knowledgeSourcesToolbar": {
    "resources_selected": "Ausgewählte Ressourcen:"
  },
  "knowledgeToolbarSourcesFilters": {
    "by_date": "Nach Datum"
  },
  "knowledgeToolbarSourcesUpdater": {
    "refresh_from_source_available_soon": "Aktualisieren aus Quelle (Demnächst verfügbar!)"
  }
}