import { ref } from "vue";

export const useModal = () => {
  const isModalVisible = ref<boolean>(false);

  const hideModal = () => {
    isModalVisible.value = false;
  };

  const showModal = () => {
    isModalVisible.value = true;
  };

  return {
    isModalVisible,
    hideModal,
    showModal,
  };
};
