{
  "summaryWidgetWidgets": {
    "summary": "Resumo",
    "generate_summary": "Gerar resumo",
    "context": "CONTEXTO",
    "make_summary_in": "Fazer resumo em",
    "keeping_the_tone": "mantendo o tom {formTone}",
    "use_markdown_format": "Usar formato markdown",
    "add_the_primary_question": "Adicionar a pergunta principal com uma resposta que responda ao contexto usando formatação em itálico.",
    "add_a_header_as_h2_markdown": "Adicionar um cabeçalho como markdown h2.",
    "add_main_emojis": "Adicionar emojis principais aqui.",
    "add_bold_main_keywords": "Adicionar palavras-chave principais em negrito aqui.",
    "add_a_header": "Adicionar um cabeçalho como markdown h2.",
    "add_1_sentence_quote": "Adicionar uma citação de 1 frase da frase mais importante do contexto usando markdown de bloco de citação.",
    "if_you_have_what_to_add": "Se você tiver algo para adicionar como marcadores, adicione-o no final como uma lista markdown.",
    "an_error_occurred_while_fetching": "Ocorreu um erro ao buscar o resumo.",
    "something_didn_t_go_as_planned": "Algo não saiu como planejado.",
    "error_occured_while_creating_pdf": "Ocorreu um erro ao criar o PDF"
  },
  "summaryWidgetAnswer": {
    "summary": "Resumo",
    "from_original": "do original ~{pages} páginas de texto para",
    "download_pdf": "Baixar PDF",
    "clear_summary": "Limpar resumo"
  },
  "summaryWidgetAnswerChunk": {
    "click_to_edit_this_block": "Clique para editar este bloco",
    "click_to_adjust_this_block": "Clique para ajustar este bloco",
    "click_to_delete_this_block": "Clique para excluir este bloco",
    "something_went_awry": "Algo deu errado!",
    "you_ve_successfully_deleted": "Você excluiu com sucesso parte do resumo!"
  },
  "summaryWidgetAnswerHeader": {
    "click_to_edit": "Clique para editar o cabeçalho do resumo"
  },
  "summaryWidgetForm": {
    "you_ve_enabled_custom_prompt_in_settings": "Você habilitou o prompt personalizado nas configurações!",
    "block_size": "Tamanho do bloco",
    "size_from_original": "Tamanho do original ~{totalPages} páginas de texto",
    "tone": "Tom",
    "speak_tone": "Falar... tom",
    "language": "Idioma",
    "options": "Opções",
    "add_subheaders": "adicionar subcabeçalhos",
    "add_important_quotes": "adicionar citações importantes",
    "use_bullet_points": "usar formato de marcadores",
    "add_main_question": "adicionar pergunta principal",
    "add_emojis": "adicionar emojis",
    "bold_main_keywords": "palavras-chave principais em negrito",
    "write_it_for_a": "Escreva para uma criança de 9 anos como uma história de aventura",
    "create_preview": "Criar visualização",
    "original": "original"
  },
  "summaryWidgetModal": {
    "create_summary_pdf": "Criar resumo em PDF",
    "ok": "Ok",
    "wait_for_a_few_seconds_please": "Aguarde alguns segundos, por favor..."
  },
  "summaryWidgetPreview": {
    "preview": "Visualização",
    "summary_a_piece_of_content": "resumir um pedaço de conteúdo",
    "refresh_preview": "Atualizar visualização"
  },
  "SummaryMethodOptionsEnum": {
    "percent": "porcentagem",
    "pages": "páginas",
    "words": "palavras"
  }
}