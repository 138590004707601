{
  "projectSettingsSecurity": {
    "add": "Adicionar",
    "add_new_domain": "Adicionar novo domínio",
    "allowed_domains": "Domínios permitidos",
    "the_value_is_not_a_valid_domain": "O valor não é um domínio válido",
    "password_is_required": "Senha é obrigatória",
    "password_must_be_at_least": "A senha deve ter pelo menos 8 caracteres",
    "password_should_be_alphanumeric": "A senha deve ser alfanumérica",
    "this_domain_was_added_previously": "Este domínio já foi adicionado anteriormente"
  },
  "projectSettingsSecurityPassword": {
    "password": "Senha",
    "delete_project_password": "Excluir senha do projeto",
    "delete_the_project_password": "Excluir a senha do projeto",
    "set_up_password_for_the_project": "Configurar senha para o projeto",
    "anyone_will_be_able_to_use": "Qualquer pessoa poderá usar o projeto sem senha",
    "anyone_who_has_access_to_this_project": "Qualquer pessoa que tenha acesso a este projeto precisará inserir uma senha"
  }
}