{
  "ninjaIndicator": {
    "please_wait_while": "Attendere mentre elaboriamo i tuoi dati. Potrebbe richiedere circa un minuto.",
    "redirecting_to_your_project": "Reindirizzamento al tuo progetto...",
    "we_are_processing_your_information": "Stiamo elaborando le tue informazioni..."
  },
  "progressCancelChain": {
    "cancel_all": "Annulla tutto",
    "do_you_want_to_stop": "Vuoi interrompere tutte le catene di elaborazione?",
    "stop_processing": "Interrompere l'elaborazione?",
    "yes": "Sì",
    "no": "No"
  },
  "progressNinjaLoader": {
    "please_wait_while_we_process": "Attendere mentre elaboriamo i tuoi dati. Potrebbe richiedere circa un minuto.",
    "redirecting_to_your_project": "Reindirizzamento al tuo progetto...",
    "we_are_processing_your_information": "Stiamo elaborando i tuoi dati ora, il che potrebbe richiedere del tempo. Puoi iniziare a utilizzare il sistema immediatamente e il processo continuerà in background.",
    "process_in_background": "Elabora in background"
  },
  "progressResourceItem": {
    "there_were_some_errors_during_processing": "Si sono verificati alcuni errori durante l'elaborazione."
  },
  "progressResourceList": {
    "jobs_in_progress": "Lavori in corso"
  }
}