{
  "pagesOmniPortalId": {
    "name": "ім'я",
    "add_new": "Додати нове",
    "name_not_provided": "Ім'я не надано"
  },
  "pagesOmniPortalIndex": {
    "portal": "Портал",
    "welcome_to_our_turnkey_portal": "Ласкаво просимо до нашої функції готового порталу, де ви можете налаштовувати, переозначати та надавати передові рішення штучного інтелекту без необхідності розробляти з нуля. Наша платформа пропонує всеосяжний фреймворк, який дозволяє точно налаштовувати плани та рівні передплати відповідно до вашої та потреб ваших користувачів. Завдяки всього кількох клацанням ви можете вибрати та призначити різні віджети та інформери, забезпечуючи кожному з ваших користувачів персоналізований досвід під вашим брендом.",
    "the_seamless_integration": "Ця безшовна інтеграція означає, що ви можете пропонувати складні послуги, зберігаючи повний контроль над інтерфейсом користувача та функціональністю. Все це стосується покращення вашої пропозиції з мінімальними зусиллями та максимальною ефективністю. Ви надаєте візію, наша платформа надає інструменти для її втілення. Почніть переозначати свою цифрову службу сьогодні і спостерігайте, як ваш бізнес зростає на основі наших рішень зі штучним інтелектом.",
    "create_my_first_portal": "Створити мій перший портал"
  },
  "pagesOmniPortalChangePassword": {
    "you_will_be_redirected_to_the_home": "Ви будете перенаправлені на головну сторінку через {value} секунд",
    "to_go_to_the_home_page": "Щоб перейти на головну сторінку",
    "click_here": "натисніть тут",
    "enter_your_new_password": "Введіть ваш новий пароль",
    "password_successfully_changed": "Пароль успішно змінено",
    "invalid_token": "Недійсний токен"
  },
  "pagesOmniPortalLink": {
    "looks_like_you_ve_got_no_access": "Схоже, у вас немає доступу до цього віджета"
  },
  "pagesOmniPortalResetPassword": {
    "user_with_such_email_not_found": "Користувача з такою електронною поштою не знайдено"
  },
  "pagesOmniPortal": {
    "this_portal_does_not_contain_any_projects": "Цей портал не містить жодних проектів"
  }
}
