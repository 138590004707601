{
  "changePassword": {
    "you_will_be_redirected": "Sie werden in {value} Sekunden zur Startseite weitergeleitet",
    "to_go_to_the_home_page": "Um zur Startseite zu gelangen",
    "click_here": "klicken Sie hier",
    "enter_your_new_password": "Geben Sie Ihr neues Passwort ein",
    "password_successfully_changed": "Passwort erfolgreich geändert"
  },
  "signIn": {
    "sign_in": "Anmelden",
    "enter_your_email_and_password": "Geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein, um sich anzumelden",
    "email": "E-Mail",
    "enter_email": "E-Mail eingeben",
    "password": "Passwort",
    "enter_password": "Passwort eingeben",
    "or": "ODER",
    "dont_have_an_account": "Sie haben noch keinen Account?",
    "sign_up_with_email": "Mit E-Mail registrieren",
    "dont_remember_the_password": "Passwort vergessen?",
    "reset_password": "Passwort zurücksetzen",
    "link_expired": "Dieser Link ist ungültig oder abgelaufen.",
    "please_request_a_new_link": "Bitte fordern Sie einen neuen Link an.",
    "something_went_awry": "Etwas ist schiefgelaufen!",
    "your_email_is_not_yet_verified": "Ihre E-Mail-Adresse wurde noch nicht verifiziert. Bitte überprüfen Sie Ihren Posteingang und folgen Sie dem Verifizierungslink, um die Registrierung abzuschließen."
  },
  "signUp": {
    "sign_up": "Registrieren"
  },
  "signUpForm": {
    "name": "Name",
    "enter_first_name": "Vorname eingeben",
    "last_name": "Nachname",
    "enter_last_name": "Nachname eingeben",
    "email": "E-Mail",
    "enter_email": "E-Mail eingeben",
    "password": "Passwort",
    "enter_password": "Passwort eingeben",
    "password_confirmation": "Passwort bestätigen",
    "i_agree": "Ich stimme den",
    "terms_and_conditions": "Allgemeinen Geschäftsbedingungen",
    "and": "und",
    "privacy_policy": "Datenschutzrichtlinie",
    "by_checking_this": "Durch Anklicken bestätigen Sie, dass Sie dem Transfer der von Ihnen hochgeladenen Daten an Drittanbieter wie OpenAI zustimmen. Dieser Datenübertragung ist für die ordnungsgemäße Funktion des Projekts und zur Nutzung externer Dienste erforderlich, die seine Funktionen verbessern.",
    "third_party_services": "Zustimmung zur Datenübertragung an Drittanbieter",
    "sign_up": "REGISTRIEREN",
    "or_sign_up_with": "Oder mit",
    "already_have_an_account": "Sie haben bereits einen Account?",
    "sign_in": "Anmelden",
    "please_check_your_mailbox_and": "Bitte überprüfen Sie Ihren Posteingang und folgen Sie dem Verifizierungslink, um die Registrierung abzuschließen.",
    "if_you_cant_find_it_in_your": "Wenn Sie ihn nicht in Ihrem Posteingang finden können, überprüfen Sie bitte Ihren",
    "spam_folder": "Spam-Ordner",
    "as_well": "auch",
    "this_field_contains_special": "Dieses Feld enthält Sonderzeichen",
    "please_ensure_youve_read": "Bitte stellen Sie sicher, dass Sie unsere Allgemeinen Geschäftsbedingungen gelesen und akzeptiert haben",
    "and_have_consented": "und dass Sie durch Anklicken der entsprechenden Kästchen der Datenübertragung an Drittanbieter zugestimmt haben",
    "before_signing_in": "bevor Sie sich anmelden",
    "by_signing_up_you_agree": "Durch die Registrierung stimmen Sie unseren Allgemeinen Geschäftsbedingungen zu und bestätigen, dass Sie dem Transfer Ihrer Daten an Drittanbieter zugestimmt haben.",
    "read_our": "Lesen Sie unsere"
  }
}