{
  "SearchWidget_name": "Ricerca",
  "SearchWidget_description": "Comunicazione come nella barra di ricerca del browser. Fai domande e visualizza la cronologia delle risposte.",
  "ExplainWidget_name": "Spiega",
  "ExplainWidget_description": "Comunicazione per risposte più dettagliate. Domanda - Risposta.",
  "ChatWidget_name": "Chat",
  "ChatWidget_description": "Comunicazione nella forma usuale come in una chat. Breve e chiaro.",
  "BaseInformer_xml-sitemap_name": "Mappa del sito XML",
  "BaseInformer_xml-sitemap_description": "Puoi aggiungere una mappa del sito XML per l'analisi del sito web",
  "PopupChatWidget_name": "Chat Popup",
  "PopupChatWidget_description": "Widget interattivo per risposte immediate. Fai domande, ottieni risposte.",
  "CSVInformer_name": "Testo CSV",
  "CSVInformer_description": "Puoi caricare il tuo file .csv con mappatura personalizzata delle colonne",
  "WebInformer_name": "Siti Web multipli",
  "WebInformer_description": "Puoi definire un gruppo di URL del sito web per l'elaborazione",
  "FileInformer_name": "Carica file",
  "FileInformer_description": "Puoi selezionare il tuo file locale per l'elaborazione",
  "BaseInformer_url_name": "File URL",
  "BaseInformer_url_description": "Puoi definire il percorso URL per caricare il file da Internet",
  "YoutubeInformer_name": "Youtube",
  "YoutubeInformer_description": "Puoi aggiungere i sottotitoli dei video di Youtube",
  "BaseInformer_text_name": "Testo",
  "BaseInformer_text_description": "Aggiungi semplicemente del testo",
  "BulkCSVInformer_name": "Collegamenti CSV",
  "BulkCSVInformer_description": "Caricamento in blocco tramite CSV con URL",
  "BaseInformer_dropbox-file_name": "File Dropbox",
  "BaseInformer_dropbox-file_description": "Aggiungi il link al file Dropbox",
  "BaseInformer_dropbox-folder_name": "Cartella Dropbox",
  "BaseInformer_dropbox-folder_description": "Aggiungi il link alla cartella Dropbox",
  "BaseInformer_gdrive-file_name": "File GDrive",
  "BaseInformer_gdrive-file_description": "Aggiungi il link al file GDrive",
  "BaseInformer_gdrive-folder_name": "Cartella GDrive",
  "BaseInformer_gdrive-folder_description": "Aggiungi il link alla cartella GDrive",
  "DocSearchWidget_name": "Ricerca documenti",
  "DocSearchWidget_description": "Consente di installare un widget popup per cercare nella documentazione",
  "RetellingWidget_name": "Racconto",
  "RetellingWidget_description": "Strumento interattivo di racconto per le tue intuizioni sulla base di conoscenza.",
  "VimeoInformer_name": "Vimeo",
  "VimeoInformer_description": "Puoi aggiungere i sottotitoli dei video di Vimeo",
  "ConfluenceInformer_name": "Confluence",
  "ConfluenceInformer_description": "Puoi aggiungere la tua base di conoscenza di Confluence",
  "BaseInformer_zendesk_name": "Zendesk",
  "BaseInformer_zendesk_description": "Puoi definire l'URL del centro assistenza Zendesk per l'elaborazione.",
  "BaseInformer_youtube-channel_name": "Canale Youtube",
  "BaseInformer_youtube-channel_description": "Puoi definire l'URL del canale Youtube per l'elaborazione.",
  "BaseInformer_youtube-playlist_name": "Playlist Youtube",
  "BaseInformer_youtube-playlist_description": "Puoi definire l'URL della playlist Youtube per l'elaborazione.",
  "NotionInformer_name": "Notion",
  "NotionInformer_description": "Puoi aggiungere pagine o database di Notion",
  "InteractiveMindmapWidget_name": "Mappa mentale interattiva",
  "InteractiveMindmapWidget_description": "Crea e personalizza mappe mentali gerarchiche.",
  "MermaidMindmapWidget_name": "Mappa mentale Mermaid",
  "MermaidMindmapWidget_description": "Crea e personalizza mappe mentali gerarchiche.",
  "WordpressInformer_name": "Wordpress",
  "WordpressInformer_description": "Puoi aggiungere un sito Wordpress",
  "GoogleSheetsAddOn_name": "Add-On Google Sheets",
  "GoogleSheetsAddOn_description": "Aggiungi l'Add-On di Omnimind AI a Google Sheets",
  "ZapierWidget_name": "Integrazione Zapier",
  "ZapierWidget_description": "Aggiungi Omnimind AI alla tua applicazione Zapier",
  "ChromeExtensionInformer_name": "Estensione Chrome",
  "ChromeExtensionInformer_description": "Carica le informazioni dalle tue risorse utilizzando la nostra estensione Chrome.",
  "VoiceWidget_name": "Voce",
  "VoiceWidget_description": "Parla con il tuo bot per ottenere risposte come se parlassi con un essere umano",
  "SingleWebInformer_name": "Sito web",
  "SingleWebInformer_description": "Puoi definire un singolo URL del sito web per l'elaborazione",
  "DataExtractorWidget_name": "Estrattore di dati",
  "DataExtractorWidget_description": "Estrae informazioni dai siti web per raccogliere informazioni",
  "SlackIntegration_name": "Integrazione Slack",
  "SlackIntegration_description": "Aggiungi Omnimind AI al tuo spazio di lavoro Slack",
  "WhatsAppIntegration_name": "Integrazione WhatsApp",
  "WhatsAppIntegration_description": "Comunicazione nella forma usuale in WhatsApp.",
  "BaseInformer_onedrive-file_name": "File OneDrive",
  "BaseInformer_onedrive-file_description": "Aggiungi il link al file OneDrive",
  "BaseInformer_onedrive-folder_name": "Cartella OneDrive",
  "BaseInformer_onedrive-folder_description": "Aggiungi il link alla cartella OneDrive",
  "SummaryWidget_name": "Sommario",
  "SummaryWidget_description": "Trasforma i tuoi dati in riassunti chiari e concisi.",
  "QuizWidget_name": "Quiz",
  "QuizWidget_description": "Creazione e esportazione di quiz completi",
  "TableWidget_name": "Tabella",
  "TableWidget_description": "Widget comune per visualizzare dati in una tabella. Questo widget è nascosto dalla lista dei widget.",
  "AudioInformer_name": "File audio",
  "AudioInformer_description": "Puoi selezionare il tuo file audio locale per l'elaborazione",
  "AirtableInformer_name": "Airtable",
  "AirtableInformer_description": "Puoi aggiungere la tua base di conoscenza di Airtable",
  "FlashcardsWidget_name": "Flashcards",
  "FlashcardsWidget_description": "Crea e utilizza flashcard per un apprendimento efficace e test di conoscenza",
  "ExcelInformer_name": "Excel",
  "ExcelInformer_description": "Puoi caricare il tuo file .xls o .xlsx con mappatura personalizzata delle colonne",
  "GoogleSheetsInformer_name": "Google Sheets",
  "GoogleSheetsInformer_description": "Puoi caricare le tue Google Sheets con mappatura personalizzata delle colonne",
  "DatabaseInformer_name": "Insight sul database",
  "DatabaseInformer_description": "Puoi fare richieste ai tuoi database utilizzando un linguaggio comprensibile dall'essere umano.",
  "PdfOcrInformer_name": "PDF",
  "PdfOcrInformer_description": "Estrae tutti i dati dai PDF e li aggiunge alle tue risorse.",
  "BubbleIntegration_name": "Plugin Bubble.io",
  "BubbleIntegration_description": "Integra OmniMind nelle tue soluzioni Bubble.",
  "NpmIntegration_name": "Pacchetto NPM",
  "NpmIntegration_description": "Aggiungi il pacchetto Npm di Omnimind AI ai tuoi progetti",
  "MicrosoftTeamsIntegration_name": "Integrazione Microsoft Teams",
  "MicrosoftTeamsIntegration_description": "Integra la tua base di conoscenza nella comunicazione aziendale con Microsoft Teams e uno dei nostri chatbot e velocizza i tuoi processi operativi."
}