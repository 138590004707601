{
  "aiModel": {
    "please_choose_the_model_that_you_want_to_use": "Por favor, elige el modelo que deseas utilizar. El modelo GPT-4 es más robusto y proporciona respuestas más precisas pero es más lento y más caro, mientras que GPT 3.5 es más rápido y se adapta a la mayoría de los casos.",
    "gpt_model_which_is_used_to_answer_the_questions": "Modelo GPT que se utiliza para responder las preguntas",
    "personality": "Personalidad",
    "role": "Rol",
    "act_as": "Actuar como...",
    "tone": "Tono",
    "custom_prompt_is_deactivated": "La indicación personalizada está desactivada",
    "personality_settings_are_deactivated": "La configuración de personalidad está desactivada",
    "speak_tone": "Hablar con tono...",
    "formatting": "Formato",
    "use": "Usar...",
    "formatting_works_better_with_the_chat_gpt_4_model": "El formato funciona mejor con el modelo de chat GPT 4.",
    "the_answer_length_field_should_not_be_less_than": "El campo de longitud de respuesta no debe ser menor a 50 ni mayor a 2500",
    "using_variables_question_and_context_are_mandatory": "¡El uso de las variables {question} y {context} es obligatorio!"
  },
  "aiModelAdvanceSettings": {
    "advance_settings": "Configuración avanzada",
    "enter_a_number_between": "Ingresa un número entre {value_1} y {value_2}",
    "custom_prompt": "Indicación personalizada",
    "custom_prompt_template": "Plantilla de indicación personalizada",
    "amount_of_the_last_messages_to_be_saved_in_context": "Cantidad de los últimos mensajes a guardar en el contexto",
    "precontext": "Precontexto",
    "more_about_custom_prompt": "Más sobre la indicación personalizada",
    "save_last_messages_to_context": "Guardar los últimos mensajes en el contexto: [ingresa un número]",
    "precontext_property_controls": "El precontexto controla la cantidad de respuestas y preguntas en el historial de conversación con la IA que se utilizarán en las nuevas respuestas de la IA"
  },
  "aiModelHeader": {
    "ai_model": "Modelo de IA",
    "advance_settings": "Configuración avanzada"
  },
  "aiModelHelpData": {
    "struggling_with_tuning_ai_model": "¿Tienes dificultades para ajustar el modelo de IA?",
    "don_t_hesitate_to_book_a_15_minutes_free_workshop": "No dudes en reservar un taller gratuito de 15 minutos con nuestro ingeniero de IA. Él te ayudará a ajustar tu verdadero bot de IA.",
    "book_a_call": "Reservar una llamada",
    "struggling_with_custom_settings": "¿Tienes dificultades con la configuración personalizada?",
    "don_t_hesitate_to_chat_with_our_ai_engineer": "No dudes en chatear con nuestro ingeniero de IA. Él te ayudará a ajustar tu verdadero bot de IA.",
    "open_chat": "Abrir chat"
  },
  "aiModelSettingsData": {
    "degree_of_originality": "Grado de originalidad",
    "here_you_can_specify_the_creativity_level": "Aquí puedes especificar el nivel de creatividad para el modelo. Cuanto más cercano sea el valor a cero, más creativo será el modelo, pero puede que no se adhiera directamente al contexto proporcionado en los datos cargados. Cuanto más cercano sea el valor a uno, más se adhiere el modelo directamente a los datos proporcionados.",
    "topic": "Tema",
    "creative": "Creativo",
    "diversity_range": "Rango de diversidad",
    "adjust_this_setting_to_control": "Ajusta esta configuración para controlar qué tan diverso será el texto del modelo. Valores más bajos proporcionan respuestas más predecibles, mientras que valores más altos ofrecen más variedad pero pueden ser menos consistentes.",
    "minimum_textual_diversity": "Diversidad textual mínima",
    "maximum_textual_diversity": "Diversidad textual máxima",
    "balance": "Equilibrio",
    "semantic_balance_option": "Opción de equilibrio semántico",
    "if_you_need_a_simple_search_in": "Si necesitas una búsqueda simple de la manera clásica que solo coincida con las palabras en los datos proporcionados, debes mover el control deslizante más cerca de cero. Por el contrario, si deseas que la búsqueda sea más parecida al procesamiento del lenguaje natural, teniendo en cuenta sinónimos, contexto y conceptos relacionados, debes mover el control deslizante más cerca de uno.",
    "if_you_want_a_simple_text_search": "Si deseas una búsqueda de texto simple, mueve el control deslizante a \"Búsqueda de texto clásica\". Para una búsqueda más compleja con significados y formas de palabras, elige \"Búsqueda por contexto\". Si no estás seguro, establece el control deslizante en \"Óptimo\".",
    "classic_text_search": "Búsqueda de texto clásica",
    "text_search": "Búsqueda de texto",
    "semantic_vector_search": "Búsqueda semántica vectorial",
    "search_by_context": "Búsqueda por contexto",
    "knowledge_sources": "Fuentes de conocimiento",
    "here_you_can_define_the_maximum": "Aquí puedes definir el número máximo de documentos (o fragmentos) que se utilizarán para generar la respuesta.",
    "knowledge_diversity": "Diversidad de conocimiento",
    "knowledge_diversity_settings_helps": "La configuración de Diversidad de conocimiento te ayuda a controlar la variedad de información que obtienes en los resultados de búsqueda. <br/><br/><strong>Así es cómo funciona:</strong><br/><br/><strong>Valor recomendado: 3:</strong><br/> <i>Propósito:</i> Proporciona una buena mezcla de información diferente evitando contenido repetitivo.<br/> <i>Caso de uso:</i> Ideal para obtener puntos de vista diversos y una amplia gama de conocimiento sobre un tema.<br/><br/><strong>Valor: 0:</strong><br/> <i>Propósito:</i> Incluye todo el contenido similar, incluso si repite información.<br/><i>Caso de uso:</i> Útil cuando deseas recopilar todos los datos posibles, como en un proyecto de investigación detallado o un estudio exhaustivo.<br/><br/>Al establecer el nivel de Diversidad de conocimiento, puedes asegurarte de que los resultados de búsqueda se adapten a tus necesidades, ya sea información variada o todos los detalles posibles sobre un tema."
  }
}