{
  "projectSettingsSecurity": {
    "add": "Add",
    "add_new_domain": "Add new domain",
    "allowed_domains": "Allowed domains",
    "the_value_is_not_a_valid_domain": "The value is not a valid domain",
    "password_is_required": "Password is required",
    "password_must_be_at_least": "Password must be at least 8 characters long",
    "password_should_be_alphanumeric": "Password should be alphanumeric",
    "this_domain_was_added_previously": "This domain was added previously"
  },
  "projectSettingsSecurityPassword": {
    "password": "Password",
    "delete_project_password": "Delete project password",
    "delete_the_project_password": "Delete the project password",
    "set_up_password_for_the_project": "Set up password for the project",
    "anyone_will_be_able_to_use": "Anyone will be able to use the project without a password",
    "anyone_who_has_access_to_this_project": "Anyone who has access to this project will be required to enter a password"
  }
}