{
  "mermaidMindmapWidgetActions": {
    "export": "Експорт",
    "click_here": "Натисніть тут, щоб відкрити редактори ментальних карт",
    "theme": "Тема",
    "select_timezone": "Виберіть свою часову зону",
    "language": "Мова",
    "create_map": "Створити карту",
    "regenerate_tooltip": "Натисніть тут, щоб перегенерувати всю ментальну карту. Зверніть увагу, що це повністю замінить поточну карту. Використовуйте цей варіант лише у випадку, якщо ви не задоволені існуючою картою. Якщо вам потрібно зробити незначні корективи, просто натисніть кнопку «Редагувати».",
    "export_svg": "Експорт у форматі SVG",
    "export_png": "Експорт у форматі PNG"
  },
  "mermaidMindmapWidgetMap": {
    "mermaid_mind_map": "Ментальна карта Mermaid",
    "what_is_mind_map": "Що таке ментальна карта",
    "a_mermaid_mind_map_is_a_visualization_tool": "Ментальна карта Mermaid - це інструмент візуалізації, створений за допомогою бібліотеки Mermaid.js, який дозволяє організовувати та представляти інформацію у ієрархічному форматі. З використанням простого синтаксису, схожого на markdown, ви можете створювати ментальні карти, які надають чітку візуальну структуру складним ідеям або проектам. Цей метод значно полегшує процеси мозкового штурму та планування, допомагаючи чітко визначити та уточнити складні дані або концепції ефективно. Крім того, вхідний текст у форматі простого тексту легко редагується, що сприяє швидким оновленням та адаптаціям. Його можна легко інтегрувати в різні платформи, що спрощує процес обміну та вбудовування цих ментальних карт.",
    "steps_to_improve_your_mind_map": "Кроки для покращення вашої ментальної карти",
    "clarify_your_intent": "Уточніть свої наміри",
    "use_the_custom_prompt_to": "Використовуйте спеціальний запит, щоб вказати свої вимоги при створенні ментальної карти.",
    "declutter_knowledge": "Очистити знання",
    "navigate_to_the_knowledge_section": "Перейдіть до розділу «Знання» (поруч з налаштуваннями). Видаліть зайві фрагменти та непотрібну інформацію, щоб зберегти фокус та ясність.",
    "adjust_with_editors": "Налаштувати за допомогою редакторів",
    "employ_the_map_editor_to_correct": "Використовуйте редактор карт, щоб виправити та налаштувати синтаксис Mermaid. Використовуйте редактор вузлів, щоб додавати, видаляти або заміняти вузли в ментальній карті для кращого вирівнювання.",
    "by_following_these_steps": "Дотримуючись цих кроків, ваша ментальна карта буде більш зручною, зосередженою та відповідатиме вашим потребам.",
    "map_should_highlight_the_key_aspects_and": "Карта повинна виділяти ключові аспекти та ієрархічні зв'язки { context }. Почніть карту з одного кореневого вузла, який лаконічно представляє основну концепцію або тему. Розробляйте гілки для дослідження підтем або пов'язаних концепцій, надаючи чіткі мітки для кожного вузла. Зберігайте чіткий та логічний потік між з'єднаними вузлами та підвузлами, використовуючи належне відступлення для позначення ієрархії. Зберігайте вузли короткими та конкретними, переконуючись, що вони безпосередньо пов'язані з сусідніми вузлами. У відповіді дотримуйтесь шаблону",
    "with_adequate_spacing_to_demonstrate": "з належним відступом для демонстрації ієрархічних зв'язків. Ваш результат повинен бути текстовим, відформатованим таким чином, щоб він точно відображався з Mermaid, і не містити великих літер або спеціальних символів, крім тих, що необхідні для синтаксису Mermaid.",
    "we_are_unable_to_create_a_mindmap": "Ми не можемо створити ментальну карту на основі наданого вмісту. Ви можете спробувати відкрити налаштування віджета та змінити їх, щоб отримати кращі результати!",
    "something_went_awry": "Щось пішло не так! Але ми спробуємо знову згенерувати вашу карту!",
    "please_generate_a_mermaid_mind": "Будь ласка, згенеруйте ментальну карту Mermaid на основі { context } у",
    "language": "мові"
  },
  "mermaidMindmapWidgetMapEditor": {
    "map_editor": "Редактор карт",
    "manipulate_the_structure_and": "Легко маніпулюйте структурою та потоком вашої карти, налаштовуючи відступи та додаванням нових рядків для створення вузлів, що сприяють налаштованій та пізнавальній візуалізації ваших ідей. Готуйтеся до вдосконалення та переосмислення свого досвіду створення ментальних карт, де кожна зміна наближає вас до досконалості."
  },
  "mermaidMindmapWidgetNodesEditor": {
    "nodes_editor": "Редактор вузлів",
    "dont_settle_for_nodes_that_dont_resonate": "Не задовольняйтеся вузлами, які не відповідають вашим потребам; за допомогою редактора вузлів ви можете легко перегенерувати будь-який елемент, щоб він краще відповідав вашому баченню, або додати зовсім нові вузли, щоб розширити ваші ідеї.",
    "click_here_to_re_generate_this_node": "Натисніть тут, щоб перегенерувати цей вузол. Переконайтеся, що надали чіткі інструкції.",
    "click_here_to_add_a_brand_new_node": "Натисніть тут, щоб додати зовсім новий вузол. Всі інші вузли залишаться без змін. Переконайтеся, що надали чіткі інструкції.",
    "add_node": "Додати вузол",
    "add_instructions": "Додати інструкції",
    "enter_your_hint_here": "Введіть вашу підказку тут",
    "this_node": "Цей вузол",
    "and_all_it_s_children_will": "і всі його дочірні вузли будуть перегенеровані.",
    "you_have_the_option_to": "У вас є можливість покращити вашу ментальну карту, додавши новий вузол.",
    "feel_free_to_include_any_specific": "Не соромтеся включати будь-які конкретні деталі або вимоги як підказку, якщо це необхідно."
  }
}