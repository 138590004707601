{
  "portalPreview": {
    "example": "Beispiel",
    "suggestedQuestions": "Vorgeschlagene Fragen",
    "who_s_on_duty_today": "Wer ist heute im Dienst?",
    "send": "Senden"
  },
  "tenantPortalPortalUi": {
    "portal_styles": "Portal-Stile",
    "background_color": "Hintergrundfarbe",
    "primary_color": "Primärfarbe",
    "secondary_color": "Sekundäre/Umrandungsfarbe",
    "accent_color": "Akzentfarbe",
    "text_color": "Textfarbe",
    "portal_preview": "Portal-Vorschau",
    "apply": "Anwenden",
    "field_is_not_a_valid_hex_color": "Feld ist keine gültige Hex-Farbe",
    "failed_to_upload_branding_logo": "Fehler beim Hochladen des Branding-Logos",
    "portal_ui_successfully_saved": "Portal UI erfolgreich gespeichert"
  }
}