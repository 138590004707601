{
  "infoMessageOne": {
    "here_you_can_specify_the_depth_of_the_web_site_scraping": "Тут ви можете вказати глибину скрапінгу веб-сайту",
    "not_deeper_than_the_specified_url": "не глибше, ніж вказаний URL",
    "the_scraper_goes_one_level_deeper": "скрапер переходить на один рівень глибше, але не переходить за посиланнями на другому рівні",
    "the_scraper_goes_two_levels_deeper": "скрапер переходить на два рівні глибше, але не переходить за посиланнями на третьому рівні",
    "the_scraper_goes_three_levels_deeper": "скрапер переходить на три рівні глибше, але не переходить за посиланнями на четвертому рівні"
  },
  "webInformerAdvancedSettingItems": {
    "depth": "Глибина:",
    "page_limit": "Ліміт сторінок:",
    "enter_page_limit": "Введіть ліміт сторінок",
    "page_filter": "Фільтр сторінок:",
    "glob_mask": "глобальна маска",
    "define_the_update_period": "Визначте період оновлення",
    "if_you_are_not_sure_how_many_links": "Якщо ви не впевнені, скільки посилань є на сайті, ви можете визначити ліміт скраплених сторінок.",
    "here_you_can_specify_the_regularity": "Тут ви можете вказати регулярність того, як часто буде скраплено вказане посилання для оновлення інформації.",
    "you_can_lock_scraper_to_parse": "Ви можете заблокувати скрапер для аналізу сторінок з певним шаблоном URL-адреси згідно з",
    "hourly": "щогодини",
    "daily": "щоденно",
    "weekly": "щотижня",
    "monthly": "щомісячно"
  },
  "webInformerBtnAdvancedSetting": {
    "advanced_link_setting": "Розширена настройка посилань"
  },
  "webList": {
    "preview": "Попередній перегляд"
  }
}