{
  "embedCode": {
    "you_can_copy_paste_this_snippet_into_your": "You can copy paste this snippet into your code and use the widget on your side or you can use our",
    "package": "package",
    "and_natively_integrate_the_widget_into_your_react_app": "and natively integrate the widget into your React app.",
    "incorporate_this_html_code_into_your_document": "Incorporate this HTML code into your document",
    "subsequently_append_the_following_java_script": "Subsequently, append the following JavaScript code after your scripts",
    "send_the_code_and_integration_instructions_to_the_developer": "Send the code and integration instructions to the developer",
    "email": "Email",
    "send": "Send",
    "copy_code": "Copy code",
    "code_was_copied": "Code was copied!",
    "copied_code": "Copied code",
    "something_went_awry": "Something went awry",
    "your_code_successfully_sent": "Your code successfully sent",
    "external_user_comment": "Enter to see an example code how you can identify the user",
    "email_example": "Email example",
    "email_example_placeholder": "Enter email example",
    "username_example": "Username example",
    "username_example_placeholder": "Enter username example"
  },
  "settingsInformerModal": {
    "informer_settings": "Informer settings",
    "anonymizer": "Anonymizer",
    "splitter": "Embeddings",
    "advanced": "Advanced",
    "apply": "Apply",
    "apply_for_all": "Apply for all",
    "your_settings_were_successfully_saved": "Your settings were successfully saved"
  },
  "settingsProject": {
    "rename_the_project": "Rename the project",
    "security": "Security",
    "operations": "Operations",
    "back": "Back",
    "failed_to_load_settings": "Failed to load settings",
    "your_settings_successfully_saved": "Your settings successfully saved",
    "failed_to_save_settings": "Failed to save settings",
    "failed_to_delete_password": "Failed to delete password"
  }
}