{
  "formClearHistoryItem": {
    "clear_chat_history": "Clear chat history",
    "do_you_want_to_clear": "Do you want to clear your chat history?",
    "clear_history": "Clear history",
    "all_your_history_will_be_deleted": "All your history chat will be deleted."
  },
  "formOptions": {
    "more_options": "More options"
  },
  "formPdfGeneratingItem": {
    "export_chat_as_pdf": "Export chat as PDF",
    "create_chat_to_pdf": "Create chat to PDF",
    "wait_for_a_few_seconds_please": "Wait for a few seconds please...",
    "something_happened": "Something happened with creating to PDF"
  }
}