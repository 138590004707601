{
  "widgetsComponentDeleteWidgetModal": {
    "delete": "Eliminar",
    "widget": "widget",
    "do_you_really_want_to_remove": "¿Realmente quieres eliminar",
    "widget_from": "widget de",
    "you_can_always_add_it_any_time_later": "Siempre puedes agregarlo más tarde."
  },
  "widgetsComponentPasswordProjectModal": {
    "password_is_required": "Se requiere contraseña",
    "enter_the_project_password": "Ingresa la contraseña del proyecto",
    "submit": "Enviar"
  }
}