{
  "auth": {
    "changePassword": {
      "change_password": "Cambiar contraseña",
      "enter_password": "Ingresar contraseña",
      "new_password": "Nueva contraseña",
      "password": "Contraseña",
      "old_password": "Contraseña anterior",
      "cancel": "Cancelar",
      "password_confirmation": "Confirmar nueva contraseña",
      "success": "Contraseña cambiada exitosamente"
    },
    "changePasswordForm": {
      "new_password": "Nueva contraseña",
      "enter_password": "Ingresar contraseña",
      "password_confirmation": "Confirmación de contraseña",
      "change_password": "Cambiar contraseña"
    },
    "portalProvider": {
      "portal": "Portal",
      "portal_not_found": "Portal no encontrado",
      "is_not_currently_active": "no está actualmente activo",
      "you_are_currently_blocked": "Actualmente estás bloqueado"
    },
    "promocode": {
      "something_went_wrong": "Algo salió mal",
      "enjoy_benefits": "¡Disfruta de los beneficios con el código de canje!",
      "redemption_code": "Código de canje",
      "enter_redemption_code": "Código de canje",
      "verifying": "Verificando...",
      "verify": "Verificar",
      "create_account_in_omnimind": "Crea una cuenta en Omnimind AI para obtener créditos comprados",
      "this_promo_code_does_not_seem_to_exist": "Este código promocional no parece existir. Por favor, verifica si hay errores tipográficos e intenta nuevamente.",
      "this_promo_code_has_expired": "Este código promocional ha expirado. Intenta con otro código."
    },
    "resetPasswordForm": {
      "reset_password": "Restablecer contraseña",
      "enter_your_email_for_resetting_password": "Ingresa tu correo electrónico para restablecer la contraseña",
      "enter_email": "Ingresar correo electrónico",
      "please_check_your_mailbox": "Por favor, revisa tu bandeja de entrada y \n sigue el enlace de instrucciones para completar el cambio de contraseña.",
      "if_you_can_not_find_it": "Si no lo encuentras en tu bandeja de entrada, por favor revisa tu",
      "spam_folder": "carpeta de spam",
      "as_well": "también."
    },
    "signOut": {
      "sign_out": "Cerrar sesión"
    }
  }
}