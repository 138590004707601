{
  "airtableInformer": {
    "airtable_informer": "Airtable",
    "learn": "Learn"
  },
  "audioInformer": {
    "audio_informer": "Audio",
    "learn": "Learn"
  },
  "baseInformer": {
    "learn": "Learn"
  },
  "bulkcsvInformer": {
    "csv_links_informer": "CSV links",
    "learn": "Learn"
  },
  "chromeextensionInformer": {
    "chrome_extension_informer": "Chrome Extension",
    "select_project": "Select project",
    "how_to_add_extension": "How to add Chrome Extension and start using it.",
    "visit_the_chrome_web_store": "Visit the Chrome Web Store and search for the",
    "extension": "Extension",
    "click": "Click",
    "add_to_chrome": "Add to Chrome",
    "authorize_yourself_in_the_extension": "Authorize yourself in the extension, press Sign In",
    "if_you_dont_have_any_existing_projects": "If you don't have any existing projects, you can create one. Click on the provided link to OmniMind and create your project.",
    "to_add_the_entire_web_page": "To add the entire web page, select your desired project and click the \"Process\" button in the extension",
    "to_add_selected_text": "To add selected text, highlight the text on the web page right-click and choose \"Add selected text to OmniMind project\" from the context menu.",
    "the_content_you_added_will_be_stored": "The content you added will be stored in your chosen project. You can now use this information when interacting with the AI model."
  },
  "confluenceInformer": {
    "confluence_informer": "Confluence",
    "learn": "Learn"
  },
  "csvInformer": {
    "csv_informer": "CSV",
    "learn": "Learn"
  },
  "excelInformer": {
    "excel_informer": "Excel",
    "learn": "Learn"
  },
  "fileInformer": {
    "file_informer": "File",
    "learn": "Learn"
  },
  "googleSheetsInformer": {
    "google_sheets_informer": "Google Sheets",
    "learn": "Learn"
  },
  "notionInformer": {
    "notion_informer": "Notion",
    "learn": "Learn"
  },
  "pdfOcrInformer": {
    "pdf_ocr_informer": "PDF",
    "learn": "Learn"
  },
  "singleWebInformer": {
    "single_web_informer": "Single Web",
    "learn": "Learn"
  },
  "slackIntegrationIndex": {
    "name": "name",
    "select_project": "Select project",
    "how_to_add_omnimind_app": "How to add Omnimind app to Slack Workspace",
    "start_by_copying_your_omnimind_api_key": "Start by copying your Omnimind API key. You'll need this to connect Omnimind with Slack.",
    "make_sure_your_question_is": "Make sure your question is clear and concise to get the most accurate answers. You can update or change the project in the bot direct messages if you wish to use different data for analysis.",
    "copy_your_project_id": "Copy your Project ID",
    "project_id_was_copied": "Project id was copied!",
    "add_omnimind_ai_to_your_slack_workspace": "Add The Omnimind AI to your Slack Workspace",
    "configure_your_omnimind_app": "Configure your Omnimind App by setting credentials",
    "click_connect_to_omnimind_button": "Click \"Connect to Omnimind\" button",
    "paste_your_api_key_and_project_id": "Paste your API key and Project ID",
    "start_asking_your_question": "Start asking your question!"
  },
  "vimeoInformer": {
    "vimeo_informer": "Vimeo",
    "learn": "Learn"
  },
  "webInformer": {
    "multiple_web_informer": "Multiple Web",
    "learn": "Learn"
  },
  "whatsappIntegration": {
    "name": "name",
    "select_project": "Select project"
  },
  "wordpressInformer": {
    "wordpress_informer": "Wordpress",
    "learn": "Learn"
  },
  "youtubeInformer": {
    "youtube_informer": "Youtube",
    "learn": "Learn"
  },
  "zapierWidget": {
    "name": "name",
    "select_project": "Select project",
    "how_to_add_omnimind_app": "How to add Omnimind app to Zapier",
    "start_by_copying_your_omnimind_api_key": "Start by copying your Omnimind API key. You'll need this to connect Omnimind with Zapier.",
    "make_sure_your_question_is_clear_and_concise": "Make sure your question is clear and concise to get the most accurate answers. You can update or change the project in the action settings if you wish to use different data for analysis.",
    "go_to_the_zapier_application_where_you_d_like_to": "Go to the Zapier application where you'd like to use Omnimind.",
    "create_a_new_action_step": "Create a new action step and look for the Omnimind integration.",
    "in_the_omnimind_app": "In the Omnimind App, select the action named \"Omni Ask.\"",
    "log_into_your_omnimind_account": "Log into your Omnimind account using the API key you copied earlier.",
    "in_the_action_tab": "In the Action tab, provide the necessary information.",
    "from_the_project_dropdown": "From the Project dropdown, choose the project you want to connect with.",
    "enter_the_question_you_d_like_to": "Enter the question you'd like to send to Omnimind.",
    "before_finalizing": "Before finalizing, test your action to ensure everything works smoothly.",
    "in_zapier": "In Zapier, select the \"Answer\" field from the Omnimind app. You can use this answer in your subsequent steps."
  }
}
