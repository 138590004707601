{
  "billingsActivityAnalytics": {
    "title": "Analytics"
  },
  "billingsActivityAnalyticsAverageChart": {
    "title": "Average length of requests",
    "length": "Length",
    "no_requests_yet": "There are no requests yet"
  },
  "billingsActivityAnalyticsFilters": {
    "today": "today",
    "7_days": "7 days",
    "month": "month"
  },
  "billingsActivityAnalyticsRequestsChart": {
    "title": "Requests",
    "unique_visitors": "Unique visitors",
    "per_day": "per day",
    "per_session": "per session"
  },
  "billingsActivityAnalyticsVisitors": {
    "title": "Unique visitors",
    "all_visitors": "All visitors",
    "no_visitors_yet": "No visitors yet"
  },
  "billingsActivityAnalyticsSuccessRate": {
    "title": "Success rate",
    "subtitle": "How often OmniMind can answer questions"
  },
  "billingsActivityAnalyticsCosts": {
    "title": "Costs"
  }
}
