{
  "tenantPortalDeleteUserModal": {
    "delete_this_user": "Видалити цього користувача?",
    "delete_user": "Видалити користувача",
    "user_will_be_deleted": "Користувач буде видалений зі списку."
  },
  "tenantPortalInviteModal": {
    "invite_user": "Запросити користувача",
    "apply": "Застосувати",
    "email": "Електронна пошта",
    "promocode": "Промокод",
    "enter_your_promo_code": "Введіть свій промокод",
    "message": "Повідомлення",
    "enter_message_text": "Введіть текст повідомлення...",
    "the_invite_has_been_successfully_sent": "Запрошення було успішно відправлено!",
    "the_error_occurred_while_sending_an_email": "Виникла помилка під час відправлення електронної пошти"
  },
  "tenantPortalPortalUsers": {
    "no_users_so_far": "Поки що немає користувачів",
    "invite": "Запросити",
    "email": "Електронна пошта",
    "packages": "Пакети",
    "status": "Статус",
    "actions": "Дії"
  },
  "tenantPortalPortalUsersItem": {
    "user_was_deleted": "Користувача було видалено"
  },
  "tenantPortalPortalUserModal": {
    "edit_user": "Редагувати користувача",
    "apply": "Застосувати",
    "email": "Електронна пошта",
    "package": "Пакет",
    "status": "Статус"
  }
}