{
  "confluenceInformerInformers": {
    "stay_organized_and_enhance_your_ai": "Залишайтеся організованими та покращуйте свій проект штучного інтелекту, легко імпортуючи ваш вміст з Confluence.",
    "something_went_awry_check_provided_data": "Щось пішло не так! Перевірте надані дані та спробуйте ще раз, будь ласка!",
    "something_went_awry": "Щось пішло не так!"
  },
  "confluenceInformerFormInformers": {
    "email": "Електронна пошта",
    "your_confluence_email": "Ваша електронна пошта Confluence, яку використовуєте для входу.",
    "api_token": "API-токен",
    "a_secure_api_token": "Безпечний API-токен, згенерований з вашого облікового запису Atlassian, забезпечує безпроблемну та безпечну інтеграцію.",
    "your_specific_confluence_domain": "Ваш конкретний префікс домену Confluence",
    "e_g": "наприклад",
    "if_your_confluence_url_is": "якщо ваш URL Confluence -",
    "fetch_resources": "Отримати ресурси",
    "your": "Ваш",
    "subdomain": "Піддомен"
  },
  "confluenceInformerListPagesInformers": {
    "pages": "Сторінки"
  },
  "confluenceInformerListPostsInformers": {
    "posts": "Пости"
  }
}