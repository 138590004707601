{
  "billingOverview": {
    "billing_overview": "Visão geral da faturação",
    "expenses": "Despesas"
  },
  "billingOverviewItem": {
    "used": "Utilizado",
    "remaining": "restante",
    "used_out_of": "Utilizado {formattedCurrent_value} de {formattedTotal_value}.",
    "money": "Dinheiro"
  },
  "cardList": {
    "card_details": "Detalhes do cartão",
    "expires_on": "Expira em",
    "primary": "Principal"
  },
  "paymentHistory": {
    "payment_history": "Histórico de pagamentos",
    "view_invoice": "Ver fatura",
    "download_invoice": "Baixar fatura",
    "march": "Março",
    "february": "Fevereiro",
    "january": "Janeiro",
    "pro_membership": "Associação Pro"
  },
  "projectList": {
    "projects": "Projetos"
  },
  "proPlanInfo": {
    "pro_plan": "Plano Pro",
    "renew_now": "Renovar agora",
    "monthly_visitors": "{quantity} Visitantes mensais",
    "unlimited_reports": "Relatórios ilimitados",
    "years_data_storage": "{quantity} Anos de armazenamento de dados",
    "days_left": "Dias restantes",
    "month": "mês"
  },
  "userInfo": {
    "profile": "Perfil",
    "no_company_name": "sem nome da empresa",
    "no_phone_number": "sem número de telefone",
    "no_timezone": "sem fuso horário",
    "remove_your_account": "Remover sua conta"
  }
}