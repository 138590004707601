{
  "tenantPortalDeleteUserModal": {
    "delete_this_user": "Diesen Benutzer löschen?",
    "delete_user": "Benutzer löschen",
    "user_will_be_deleted": "Der Benutzer wird aus der Liste gelöscht."
  },
  "tenantPortalInviteModal": {
    "invite_user": "Benutzer einladen",
    "apply": "Anwenden",
    "email": "E-Mail",
    "promocode": "Promocode",
    "enter_your_promo_code": "Geben Sie Ihren Promocode ein",
    "message": "Nachricht",
    "enter_message_text": "Geben Sie den Nachrichtentext ein...",
    "the_invite_has_been_successfully_sent": "Die Einladung wurde erfolgreich gesendet!",
    "the_error_occurred_while_sending_an_email": "Beim Senden einer E-Mail ist ein Fehler aufgetreten"
  },
  "tenantPortalPortalUsers": {
    "no_users_so_far": "Bisher keine Benutzer",
    "invite": "Einladen",
    "email": "E-Mail",
    "packages": "Pakete",
    "status": "Status",
    "actions": "Aktionen"
  },
  "tenantPortalPortalUsersItem": {
    "user_was_deleted": "Benutzer wurde gelöscht"
  },
  "tenantPortalPortalUserModal": {
    "edit_user": "Benutzer bearbeiten",
    "apply": "Anwenden",
    "email": "E-Mail",
    "package": "Paket",
    "status": "Status"
  }
}