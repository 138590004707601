{
  "aiModel": {
    "please_choose_the_model_that_you_want_to_use": "Por favor, escolha o modelo que você deseja usar. O modelo GPT-4 é mais robusto e fornece respostas mais precisas, porém é mais lento e mais caro, enquanto o GPT 3.5 é mais rápido e adequado para a maioria dos casos.",
    "gpt_model_which_is_used_to_answer_the_questions": "Modelo GPT que é usado para responder às perguntas",
    "personality": "Personalidade",
    "role": "Papel",
    "act_as": "Atuar como...",
    "tone": "Tom",
    "custom_prompt_is_deactivated": "O prompt personalizado está desativado",
    "personality_settings_are_deactivated": "As configurações de personalidade estão desativadas",
    "speak_tone": "Falar... tom",
    "formatting": "Formatação",
    "use": "Usar...",
    "formatting_works_better_with_the_chat_gpt_4_model": "A formatação funciona melhor com o modelo de chat GPT 4.",
    "the_answer_length_field_should_not_be_less_than": "O campo de comprimento da resposta não deve ser menor que 50 e não maior que 2500",
    "using_variables_question_and_context_are_mandatory": "O uso das variáveis {question} e {context} é obrigatório!"
  },
  "aiModelAdvanceSettings": {
    "advance_settings": "Configurações avançadas",
    "enter_a_number_between": "Digite um número entre {value_1} e {value_2}",
    "custom_prompt": "Prompt personalizado",
    "custom_prompt_template": "Modelo de prompt personalizado",
    "amount_of_the_last_messages_to_be_saved_in_context": "Quantidade das últimas mensagens a serem salvas no contexto",
    "precontext": "Pré-contexto",
    "more_about_custom_prompt": "Mais sobre o prompt personalizado",
    "save_last_messages_to_context": "Salvar últimas mensagens no contexto: [digite um número]",
    "precontext_property_controls": "O pré-contexto controla o número de respostas e perguntas em seu histórico de conversas com a IA que serão usadas nas novas respostas da IA"
  },
  "aiModelHeader": {
    "ai_model": "Modelo de IA",
    "advance_settings": "Configurações avançadas"
  },
  "aiModelHelpData": {
    "struggling_with_tuning_ai_model": "Com dificuldades para ajustar o modelo de IA?",
    "don_t_hesitate_to_book_a_15_minutes_free_workshop": "Não hesite em agendar uma oficina gratuita de 15 minutos com nosso engenheiro de IA. Ele ajuda a ajustar seu verdadeiro bot de IA.",
    "book_a_call": "Agendar uma ligação",
    "struggling_with_custom_settings": "Com dificuldades nas configurações personalizadas?",
    "don_t_hesitate_to_chat_with_our_ai_engineer": "Não hesite em conversar com nosso engenheiro de IA. Ele ajuda a ajustar seu verdadeiro bot de IA.",
    "open_chat": "Abrir chat"
  },
  "aiModelSettingsData": {
    "degree_of_originality": "Grau de Originalidade",
    "here_you_can_specify_the_creativity_level": "Aqui você pode especificar o nível de criatividade para o modelo. Quanto mais próximo o valor de zero, mais criativo o modelo, mas ele pode não aderir diretamente ao contexto fornecido nos dados enviados. Quanto mais próximo o valor de um, mais o modelo adere diretamente aos dados fornecidos.",
    "topic": "Tópico",
    "creative": "Criativo",
    "diversity_range": "Intervalo de Diversidade",
    "adjust_this_setting_to_control": "Ajuste essa configuração para controlar o quão diverso o texto do modelo será. Valores mais baixos fornecem respostas mais previsíveis, enquanto valores mais altos oferecem mais variedade, mas podem ser menos consistentes.",
    "minimum_textual_diversity": "Diversidade textual mínima",
    "maximum_textual_diversity": "Diversidade textual máxima",
    "balance": "Equilíbrio",
    "semantic_balance_option": "Opção de equilíbrio semântico",
    "if_you_need_a_simple_search_in": "Se você precisa de uma pesquisa simples da maneira clássica, correspondendo apenas às palavras nos dados fornecidos, então você deve mover o controle deslizante mais próximo de zero. Por outro lado, se você deseja que a pesquisa seja mais parecida com o processamento de linguagem natural, considerando sinônimos, contexto e conceitos relacionados, então você deve mover o controle deslizante mais próximo de um.",
    "if_you_want_a_simple_text_search": "Se você deseja uma pesquisa de texto simples, mova o controle deslizante para \"Pesquisa de texto clássica\". Para uma pesquisa mais complexa com significados e formas de palavras, escolha \"Pesquisar por contexto\". Se você não tem certeza, defina o controle deslizante como \"Ótimo\".",
    "classic_text_search": "Pesquisa de texto clássica",
    "text_search": "Pesquisa de texto",
    "semantic_vector_search": "Pesquisa semântica por vetor",
    "search_by_context": "Pesquisar por contexto",
    "knowledge_sources": "Fontes de Conhecimento",
    "here_you_can_define_the_maximum": "Aqui você pode definir o número máximo de documentos (ou partes) que serão usados para gerar a resposta.",
    "knowledge_diversity": "Diversidade de Conhecimento",
    "knowledge_diversity_settings_helps": "A configuração de Diversidade de Conhecimento ajuda a controlar a variedade de informações que você obtém nos resultados da pesquisa. <br/><br/><strong>Aqui está como funciona:</strong><br/><br/><strong>Valor recomendado: 3:</strong><br/> <i>Propósito:</i> Fornece uma boa mistura de informações diferentes, evitando conteúdo repetitivo.<br/> <i>Caso de uso:</i> Ótimo para obter pontos de vista diversos e uma ampla gama de conhecimento sobre um tópico.<br/><br/><strong>Valor: 0:</strong><br/> <i>Propósito:</i> Inclui todo o conteúdo semelhante, mesmo que repita informações.<br/><i>Caso de uso:</i> Útil quando você deseja coletar todos os dados possíveis, como em um projeto de pesquisa detalhado ou estudo abrangente.<br/><br/>Ao definir o nível de Diversidade de Conhecimento, você pode garantir que os resultados da pesquisa sejam adaptados ao que você precisa, seja informações variadas ou todos os detalhes possíveis sobre um tópico."
  }
}