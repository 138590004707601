{
  "billingInfo": {
    "select_project": "Select project",
    "required": "Required",
    "select_period": "Select period",
    "your_current_package": "Your current package",
    "cancelled_at": "cancelled at",
    "next_billing_date": "next billing date",
    "to_cancel_or_update_your_payment_plan": "To cancel or update your payment plan please go to the Customer Payment Portal.",
    "customer_payment_portal": "Customer payment portal",
    "cancel_subscription": "Cancel Subscription",
    "remove_your_account": "Remove your account",
    "remove_account": "Remove account",
    "reactivate_account": "Reactivate account",
    "no_package_found": "No package found.",
    "let_s_select_a_suitable_package": "Let's select a suitable package.",
    "select_your_package": "Select your package",
    "the_account_will_deleted_at": "The account will deleted at",
    "cancel_subscription_and_remove_account": "Cancel subscription and remove account",
    "the_subscription_will_cancelled_at": "The subscription will cancelled at",
    "cancel_current_subscription": "Cancel current subscription",
    "something_went_awry": "Something went awry"
  },
  "billings": {
    "billing_details": "Billing details",
    "activity_summary": "Activity Summary"
  },
  "billingsActivity": {
    "purchase_history": "Purchase History",
    "request_history": "Request History",
    "request_analytics": "Analytics"
  },
  "billingsActivityPurchase": {
    "no_data_so_far": "No data so far.",
    "download_invoice": "Download invoice",
    "date": "Date",
    "amount": "Amount",
    "package": "Package",
    "status": "Status",
    "invoice": "Invoice"
  },
  "billingsActivityRequest": {
    "select_project": "Select project",
    "required": "Required",
    "select_period": "Select period",
    "apply": "Apply",
    "no_data_so_far": "No data so far.",
    "created": "Created",
    "entity": "Entity",
    "cost": "Cost"
  },
  "billingsCurrentPackage": {
    "current_package": "Current package",
    "click_here_to_see_package_info": "Click here to see package info",
    "cancelled_at": "cancelled at",
    "deleted_at": "deleted at",
    "trial_ends_at": "trial expires on",
    "next_billing_date": "next billing date",
    "change_package": "Change Package",
    "add_package": "Add Package",
    "cancel_subscription": "Cancel Subscription",
    "the_subscription_will_cancelled_at": "The subscription will cancelled at",
    "cancel_current_subscription": "Cancel current subscription",
    "the_subscription_will_delete_at": "The subscription will delete at",
    "something_went_wrong": "Something went wrong!"
  },
  "billingsPackageInfo": {
    "package_information": "Package Information",
    "marketplace": "Marketplace",
    "learning_data": "Learning Data",
    "requests": "Requests",
    "on_average_one_request_contains": "On average one request contains",
    "characters": "characters",
    "no_data_so_far": "No data so far"
  },
  "billingsPackageInfoItem": {
    "upgrade_to_add_more": "Upgrade to add more"
  },
  "billingsUpgradePackageModal": {
    "expand_package": "Expand Package"
  },
  "chart": {
    "consumption_graph": "Consumption Graph"
  },
  "claimOffer": {
    "fetching_a_discount_data": "Fetching a discount data...",
    "claiming_the_offer": "Claiming the offer...",
    "are_you_considering_our": "Are you considering our",
    "discount_the": "discount? The",
    "month_plan_is_now_just": "month plan is now just",
    "month": "month",
    "think_it_over": "Think it over!"
  },
  "finalStep": {
    "your_subscription_has_been_successfully": "Your subscription has been successfully canceled. I hope we will see you soon. You still can use our service for free.",
    "the_account_will_be_active": "The account will be active up till the end of the billing period.",
    "you_have_got_the_discount_now": "You have got the discount now! We are happy that you have stayed with us!"
  },
  "billingHelpers": {
    "request": "Request",
    "requests": "Requests",
    "learning_data_characters": "Learning data, characters",
    "package_name": "Package name",
    "projects": "Projects",
    "content_uploads": "Content Uploads",
    "widgets": "Widgets",
    "scheduled_content_updates": "Scheduled content updates",
    "alternative_ai_models": "Alternative AI models",
    "api_access": "API access",
    "chrome_extension": "Chrome extension",
    "extended_customizations": "Extended customizations"
  },
  "keepAi": {
    "hold_on": "Hold on!",
    "we_just_wanted_to_let_you_know": "We just wanted to let you know that OmniMind.AI saved you",
    "hour_of_work_so_far": "hour of work so far.",
    "our_support_team_can_help_you": "Our support team can help you with any problems you might be facing.",
    "chat_with_live_support": "Chat with Live Support"
  },
  "keepPlan": {
    "keep_plan_we_are_sorry_to_see_you_go": "Keep plan, we are sorry to see you go!",
    "cancel_your_subscription": "Are You sure that you want to cancel your subscription?",
    "cancel_your_current_subscription": "Are You sure that you want to cancel your current subscription and remove your account?",
    "please_let_us_know_cancel_your_subscription": "Please let us know the reason you want to cancel your subscription.",
    "reason_you_want_to_remove_the_account": "Please let us know the reason you want to remove the account. After confirming the action the subscription will be cancelled and the account will be active up till the end of the billing period. When the billing period is over we will permanently remove all your project data and your account.",
    "select_a_reason": "Select a reason",
    "other_reason": "Other reason"
  },
  "keepPlanModal": {
    "MissingFeatures": "Missing Features",
    "TechnicalIssues": "Technical Issues",
    "Havenovalue": "Have no value",
    "Tooexpensive": "Too expensive",
    "Otherreason": "Other reason"
  },
  "packageInfoModal": {
    "package_information": "Package information"
  },
  "purchasedList": {
    "no_available_data_try_select_date": "No available data, try select date...",
    "no_data_for_the_selected_period": "No data for the selected period",
    "created": "Created",
    "entity": "Entity",
    "quantity": "Quantity"
  },
  "subscriptionModal": {
    "cancel": "Cancel",
    "remove": "Remove",
    "removing": "Removing",
    "plan": "Plan",
    "account": "Account",
    "work_time_optimization": "Work Time Optimization",
    "keep": "Keep",
    "discount_offer": "Discount Offer",
    "claim_offer": "Claim Offer",
    "cancellation": "Cancellation",
    "confirmation": "Confirmation",
    "keep_value": "Keep {value}",
    "final_step": "Final Step",
    "confirm": "Confirm",
    "continue_to": "Continue to",
    "something_went_awry": "Something went awry!"
  }
}
