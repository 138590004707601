{
  "youtubeInformer": {
    "please_provide_a_valid_vimeo_video_url": "Bitte geben Sie eine gültige Vimeo-Video-URL an",
    "tracks_are_unavailable_due_to_restrictions": "Die Spuren dieses Videos sind aufgrund von Beschränkungen nicht verfügbar",
    "something_went_awry": "Etwas ist schief gelaufen",
    "no_text_tracks": "Dieses Video hat keine Textspuren!",
    "start_time_cant_be_greater": "Die Startzeit darf nicht größer oder gleich der Endzeit sein"
  },
  "youtubeInformerForm": {
    "insert_youtube_video_url": "Youtube Video-URL einfügen",
    "fetch_subtitles": "Untertitel abrufen"
  },
  "youtubeInformerList": {
    "available_tracks": "Verfügbare Spuren"
  },
  "youtubeInformerPreview": {
    "please_adjust_the_sliders_below": "Bitte passen Sie die Schieberegler unten an, um einen Bereich zum Schneiden von Untertiteln aus Ihrem Video auszuwählen."
  }
}