{
  "caseDatabase": {
    "it_looks_like_you_ve_reached_your_maximum_number": "Parece que has alcanzado el número máximo de proyectos. Para iniciar un nuevo proyecto, considera archivar o eliminar los existentes que ya no necesitas, o explora nuestros planes de suscripción para obtener límites más altos."
  },
  "welcomeFlowCustomJourney": {
    "ready_for_the_next_step": "¿Listo para el siguiente paso en tu viaje personalizado?",
    "it_is_time_to_initiate_a_new_project": "Es hora de iniciar un nuevo proyecto. Sumérgete en una experiencia personalizada con nuestra suite de herramientas, incluyendo varios widgets, todos diseñados para organizar y analizar tu información de manera fluida.",
    "expand_your_projects_scope_by_integrating": "Amplía el alcance de tu proyecto integrando una amplia variedad de contenido, desde sitios web y enlaces individuales hasta documentos alojados en Google Drive, Dropbox, One Drive, PDF y muchos más.",
    "embark_on_this_unique_adventure": "Embárcate en esta aventura única iniciando tu proyecto personalizado hoy mismo y desbloquea todo el potencial de tus datos.",
    "create_project": "Crear Proyecto",
    "or": "O",
    "advice": "consejo",
    "let_our_bot_provide_you_with": "permite que nuestro bot te proporcione",
    "on_how_to_make_this_process": "sobre cómo hacer que este proceso sea más fácil.",
    "project_name": "Nombre del Proyecto"
  },
  "wantToUpgradeCard": {
    "upgrade": "Actualizar",
    "we_understand_youre_interested_in_this": "Entendemos que estás interesado en esta función. Está disponible en nuestros planes de nivel superior. ¡Considera actualizar para acceder a ella!"
  },
  "welcomeAnswerCard": {
    "you_can_select_a_tile_below": "Puedes seleccionar una ficha a continuación",
    "it_looks_like_you_ve_reached_your_maximum_number": "Parece que has alcanzado el número máximo de proyectos. Para iniciar un nuevo proyecto, considera archivar o eliminar los existentes que ya no necesitas, o explora nuestros planes de suscripción para obtener límites más altos."
  },
  "welcomeScreen": {
    "default_goals": "Tengo una serie de archivos en mi máquina local",
    "default_communicate_goals": "Quiero que la comunidad tenga acceso las 24 horas del día, los 7 días de la semana a la base de conocimientos y pueda chatear con ella como si fuera una persona",
    "not_sure_let_us_know": "¿No estás seguro? Descubramos algo en nuestro",
    "what_want_to_do": "Escribe lo que te gustaría hacer",
    "what_i_want_to_do_question": "¿Qué quiero\n          hacer?",
    "how_communicate": "¿Cómo voy a comunicarme con los datos?",
    "not_sure": "¿No estás seguro?",
    "lets_find": "Descubramos algo\n      en nuestro",
    "what_types_of_files": "¿Qué tipos de archivos tengo y cómo quiero subirlos?",
    "cases_database": "Base de datos de casos",
    "or_start_your": "o inicia tu",
    "impress_me": "Impresióname",
    "regenerate": "Regenerar",
    "custom_journey": "Viaje personalizado",
    "project_name": "Nombre del proyecto",
    "enter_the_name_of_the_project": "Ingresa el nombre del proyecto",
    "enter_project_name": "Ingresa el nombre del proyecto",
    "what_i_want_to_do": "Lo que quiero hacer: quiero compartir mi conocimiento con mis estudiantes. Quiero que tengan la capacidad de obtener respuestas las 24 horas del día, los 7 días de la semana.",
    "what_types_of_files_i_have_and_how_i_want": "Qué tipos de archivos tengo y cómo quiero subirlos: tengo una serie de archivos PDF en OneDrive que quiero subir.",
    "how_i_am_going_to": "Cómo voy a comunicarme con los datos: quiero que los estudiantes hablen con el bot en WhatsApp.",
    "we_could_not_create_the": "No pudimos crear el proyecto correctamente.",
    "what_types_of_files_you_want_to_upload": "¿Qué tipos de archivos quieres subir?",
    "how_are_you_going_to_communicate_with_the_data": "¿Cómo vas a comunicarte con los datos?",
    "select_project_type": "Seleccione el tipo de proyecto",
    "agent": "Agente",
    "knowledge_legacy": "Conocimiento (legado)",
    "agent_description": "Los agentes pueden hablar con el usuario basándose en su propia base de conocimientos y también realizar tareas y acciones con servicios externos a través de una variedad de herramientas y API según el escenario que establezca.",
    "knowledge_description": "Los proyectos de conocimiento solo pueden hablar con el usuario basándose en su propio conocimiento personalizado."
  }
}
