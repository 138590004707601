{
  "casesDatabase": {
    "popular": "Popular",
    "HR": "RH",
    "support": "Suporte",
    "eCommerce": "Comércio eletrônico",
    "business": "Negócios",
    "community": "Comunidade",
    "developers": "Desenvolvedores",
    "professionals": "Profissionais",
    "teachers": "Professores",
    "sales_and_marketing": "Vendas e Marketing",
    "website_widget_title": "Website e Chatbot",
    "website_widget_description": "Ferramenta de chat para dados do site.",
    "knowledge_bot_title": "Bot de conhecimento",
    "knowledge_bot_description": "Encontre informações em arquivos enviados rapidamente.",
    "slack_HR_support_bot_title": "Bot de suporte de RH no Slack",
    "slack_HR_support_bot_description": "Bot de RH no Slack da equipe",
    "GDrive_file_HR_chatbot_for_Slack_title": "Chatbot de RH com arquivo do Google Drive para Slack",
    "GDrive_file_HR_chatbot_for_Slack_description": "Envie arquivos do Google Drive para o Slack.",
    "GDrive_folder_HR_chatbot_for_slack_title": "Chatbot de RH com pasta do Google Drive para Slack",
    "GDrive_folder_HR_chatbot_for_slack_description": "Envie pastas do Google Drive para o Slack.",
    "OneDrive_file_HR_chatbot_for_Slack_title": "Chatbot de RH com arquivo do OneDrive para Slack",
    "OneDrive_file_HR_chatbot_for_Slack_description": "Envie arquivos do OneDrive para o Slack.",
    "OneDrive_folder_HR_chatbot_for_slack_title": "Chatbot de RH com pasta do OneDrive para Slack",
    "OneDrive_folder_HR_chatbot_for_slack_description": "Envie pastas do OneDrive para o Slack.",
    "knowledge_base_chat_popup_for_websites_title": "Pop-up de chat da base de conhecimento para sites",
    "knowledge_base_chat_popup_for_websites_description": "Dados do site como chat de ajuda.",
    "interactive_website_chat_for_customer_support_title": "Chat interativo do site para suporte ao cliente",
    "interactive_website_chat_for_customer_support_description": "Chat de ajuda a partir de dados do site.",
    "slack_integration_for_internal_knowledge_management_title": "Integração do Slack para gerenciamento interno de conhecimento",
    "slack_integration_for_internal_knowledge_management_description": "Bot do Slack para conhecimento de arquivos.",
    "AI_product_search_advisor_with_CSV_sync_title": "Consultor de pesquisa de produtos com sincronização de CSV",
    "AI_product_search_advisor_with_CSV_sync_description": "Sincronize listas de produtos com CSV.",
    "whatsApp_AI_travel_advisor_for_villas_title": "Consultor de viagens do WhatsApp com IA para vilas",
    "whatsApp_AI_travel_advisor_for_villas_description": "Encontre e reserve vilas via WhatsApp.",
    "zendesk_inquiry_slackbot_title": "Slackbot de consulta do Zendesk",
    "zendesk_inquiry_slackbot_description": "Veja os tickets do Zendesk no Slack.",
    "google_sheets_query_slackbot_title": "Slackbot de consulta do Google Sheets",
    "google_sheets_query_slackbot_description": "Pergunte sobre dados do Google Sheets no Slack.",
    "community_content_explorer_widget_title": "Explorador de conteúdo da comunidade",
    "community_content_explorer_widget_description": "Pesquise por conteúdo da comunidade.",
    "vimeo_content_explorer_for_communities_title": "Explorador de conteúdo do Vimeo para comunidades",
    "vimeo_content_explorer_for_communities_description": "Explore vídeos do Vimeo facilmente.",
    "web_content_explorer_widget_title": "Explorador de conteúdo da web",
    "web_content_explorer_widget_description": "Encontre conteúdo do site facilmente.",
    "PDF_content_discovery_widget_title": "Descoberta de conteúdo em PDF",
    "PDF_content_discovery_widget_description": "Encontre documentos em PDF facilmente.",
    "celebrity_chef_AI_chatbot_title": "Chatbot de IA de chef celebridade",
    "celebrity_chef_AI_chatbot_description": "Obtenha receitas de um bot de chef.",
    "project_artifact_summarizer_title": "Resumidor de artefatos de projeto",
    "project_artifact_summarizer_description": "Resuma arquivos de projeto rapidamente.",
    "AI_powered_data_extractor_for_bubble_apps_title": "Extrator de dados com IA para aplicativos Bubble",
    "AI_powered_data_extractor_for_bubble_apps_description": "Use IA para coletar dados.",
    "complex_document_simplifier_title": "Simplificador de documentos complexos",
    "complex_document_simplifier_description": "Torne os documentos fáceis de ler.",
    "professional_summary_creator_title": "Criador de resumo profissional",
    "professional_summary_creator_description": "Obtenha resumos simples de arquivos.",
    "lesson_plan_quiz_generator_title": "Gerador de questionário de plano de aula",
    "lesson_plan_quiz_generator_description": "Crie questionários a partir de planos de aula.",
    "notion_flashcard_creator_title": "Criador de flashcards do Notion",
    "notion_flashcard_creator_description": "Transforme páginas do Notion em flashcards.",
    "client_engagement_chat_assistant_title": "Assistente de chat de engajamento do cliente",
    "client_engagement_chat_assistant_description": "Crie planos de acompanhamento para clientes.",
    "lead_data_extraction_tool_title": "Ferramenta de extração de dados de leads",
    "lead_data_extraction_tool_description": "Colete dados de sites de leads.",
    "personalized_follow_up_generator_title": "Gerador de acompanhamento personalizado",
    "personalized_follow_up_generator_description": "Crie planos de acompanhamento a partir de dados.",
    "competitive_knowledge_mindmap_tool_title": "Ferramenta de mapa mental de conhecimento competitivo",
    "competitive_knowledge_mindmap_tool_description": "Transforme sites concorrentes em um mapa mental."
  },
  "informerHelpContent": {
    "PdfOcrInformer": "Faça upload de um arquivo Pdf do seu computador.",
    "FileInformer": "Faça upload de um arquivo do seu computador.",
    "WebInformer": "Liste links de sites, um por linha.",
    "SingleWebInformer": "Forneça um link de site para extrair informações.",
    "AirtableInformer": "Escolha as tabelas a serem incluídas do Airtable.",
    "AudioInformer": "Faça upload de um arquivo de áudio e escolha o idioma.",
    "ChromeExtensionInformer": "Conecte-se à nossa extensão do Chrome.",
    "ConfluenceInformer": "Acesse artigos do Confluence com credenciais.",
    "DatabaseInformer": "Comunique diretamente com o banco de dados.",
    "SlackIntegration": "Integre o Omnimind no canal do Slack.",
    "CSVInformer": "Faça upload de um arquivo CSV com dados.",
    "dropbox-file": "Cole o link para o arquivo do Dropbox.",
    "dropbox-folder": "Cole o link para a pasta do Dropbox.",
    "ExcelInformer": "Faça upload do seu arquivo do Excel com dados.",
    "gdrive-file": "Cole o link para o arquivo do Google Drive.",
    "gdrive-folder": "Cole o link para a pasta do Google Drive.",
    "GoogleSheetsInformer": "Digite o link para o arquivo do Google Sheets.",
    "onedrive-file": "Cole o link para o arquivo do OneDrive.",
    "onedrive-folder": "Cole o link para a pasta do OneDrive.",
    "text": "Digite ou cole o texto para adicionar.",
    "url": "Cole o link para um arquivo da internet.",
    "VimeoInformer": "Cole o link do vídeo do Vimeo e salve o token.",
    "xml-sitemap": "Cole o link para o arquivo de sitemap XML.",
    "youtube-channel": "Cole o link do canal do YouTube e escolha o idioma.",
    "YoutubeInformer": "Cole o link do vídeo do YouTube e escolha o idioma.",
    "youtube-playlist": "Cole o link da playlist do YouTube e escolha o idioma.",
    "BulkCSVInformer": "Faça upload de um arquivo CSV com links.",
    "NotionInformer": "Forneça o token do Notion e o link da página.",
    "WordpressInformer": "Cole o link do Wordpress e escolha as categorias.",
    "zendesk": "Cole o link do centro de ajuda do Zendesk."
  }
}