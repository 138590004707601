{
  "serviceCredentials": {
    "you_can_define_your_credentials": "You can define your credentials and use your own services to store and process information.",
    "save_credentials": "Save credentials",
    "keys": "Keys",
    "key": "Key",
    "service_credentials_were_updated": "Service credentials were updated!",
    "credentials_are_valid": "Credentials are valid!",
    "credentials_are_invalid": "Credentials are invalid!",
    "something_went_wrong": "Something went wrong!",
    "cancel": "Cancel"
  }
}