{
  "dataExtractorWidget": {
    "data_extractor_widget": "Extractor de datos",
    "language": "Idioma",
    "add_prompt": "Agregar indicación",
    "reset_table": "Restablecer tabla",
    "active": "Activo",
    "resource": "Recurso",
    "resource_already_exists": "El recurso ya existe",
    "something_went_awry": "Algo salió mal",
    "loading": "Cargando",
    "error": "Error",
    "error_occured_while_creating_pdf": "Ocurrió un error al crear el PDF",
    "the_prompt_has_been_successfully_saved": "La indicación se ha guardado correctamente en el catálogo.",
    "contact_owner_to_grant_permission": "Por favor, contacta al propietario del proyecto para que te otorgue permiso para agregar recursos."
  },
  "dataExtractorWidgetActions": {
    "scan": "Escanear",
    "export_pdf": "Exportar PDF",
    "export_csv": "Exportar CSV"
  },
  "dataExtractorWidgetAddPromptModal": {
    "add_edit_prompt": "Agregar/Editar Indicación",
    "add_save": "Agregar/Guardar",
    "add_prompt_to_catalog": "Agregar indicación al catálogo",
    "column": "Columna",
    "locations": "Ubicaciones",
    "open_prompt_catalog": "Abrir catálogo de indicaciones",
    "prompt": "Indicación",
    "common_locations_of_clients": "Ubicaciones comunes de los clientes con los que hacen negocios"
  },
  "dataExtractorWidgetAddPromptSupplement": {
    "these_prompts_will_be_applied": "Estas indicaciones se aplicarán a cada recurso",
    "by_bullet_points": "por puntos",
    "as_a_quote": "como una cita",
    "in_a_few_words": "en pocas palabras"
  },
  "dataExtractorWidgetAddResourceModal": {
    "add_edit_resource": "Agregar/Editar Recurso",
    "submit": "Enviar",
    "enter_your_resource": "Ingresa tu recurso",
    "this_resource_is_already_exist": "¡Este recurso ya existe!"
  },
  "dataExtractorWidgetDeleteResourceModal": {
    "delete_this_resource": "¿Eliminar este recurso?",
    "delete_resource": "Eliminar recurso",
    "resource_will_be_deleted": "El recurso se eliminará de la tabla.",
    "delete_resource_from_project": "Eliminar recurso del proyecto"
  },
  "dataExtractorWidgetPdfModal": {
    "create_pdf": "Crear PDF",
    "ok": "Aceptar",
    "wait_for_a_few_seconds_please": "Espera unos segundos por favor..."
  },
  "dataExtractorWidgetPromptCatalog": {
    "prompt_catalog": "Catálogo de Indicaciones",
    "delete_column": "Eliminar columna del catálogo",
    "delete_prompt": "Eliminar indicación del catálogo"
  },
  "dataExtractorWidgetTable": {
    "no_resources_so_far": "No hay recursos hasta ahora"
  },
  "dataExtractorWidgetTableHead": {
    "resource": "Recurso",
    "refresh_values": "Actualizar valores",
    "edit_prompt": "Editar indicación",
    "delete_prompt": "Eliminar indicación de la tabla"
  }
}