import { AnalyticsBrowser } from '@segment/analytics-next';
import type { IAppEventProvider } from '@/analytics/IAppEventProvider';

export class SegmentProvider implements IAppEventProvider {
  private analytics: AnalyticsBrowser;

  constructor() {
    this.analytics = new AnalyticsBrowser();
  }

  async init(): Promise<void> {
    this.analytics.load({
      writeKey: import.meta.env.VITE_SEGMENT_API_KEY,
    });
  }

  signin = (data: Record<string, any>): void => {
    this.analytics.track('User Signed In', data);
  };

  signup = (data: Record<string, any>): void => {
    this.analytics.track('User Signed Up', data);
  };
}
