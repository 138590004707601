{
  "formClearHistoryItem": {
    "clear_chat_history": "Очистити історію чату",
    "do_you_want_to_clear": "Ви хочете очистити історію чату?",
    "clear_history": "Очистити історію",
    "all_your_history_will_be_deleted": "Вся історія чату буде видалена."
  },
  "formOptions": {
    "more_options": "Більше опцій"
  },
  "formPdfGeneratingItem": {
    "export_chat_as_pdf": "Експортувати чат у форматі PDF",
    "create_chat_to_pdf": "Створити чат у форматі PDF",
    "wait_for_a_few_seconds_please": "Зачекайте кілька секунд, будь ласка...",
    "something_happened": "Щось сталося зі створенням у форматі PDF"
  }
}