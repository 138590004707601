{
  "changePassword": {
    "you_will_be_redirected": "You will be redirected to the home page in {value} seconds",
    "to_go_to_the_home_page": "To go to the home page",
    "click_here": "click here",
    "enter_your_new_password": "Enter your new password",
    "password_successfully_changed": "Password successfully changed"
  },
  "signIn": {
    "sign_in": "Sign In",
    "enter_your_email_and_password": "Enter your email and password to login",
    "email": "Email",
    "enter_email": "Enter email",
    "password": "Password",
    "enter_password": "Enter password",
    "or": "OR",
    "dont_have_an_account": "Don't have an account?",
    "sign_up_with_email": "Sign Up with Email",
    "dont_remember_the_password": "Don't remember the password?",
    "reset_password": "Reset password",
    "link_expired": "This link is invalid or has expired.",
    "please_request_a_new_link": "Please request a new link.",
    "something_went_awry": "Something went awry!",
    "your_email_is_not_yet_verified": "Your email is not yet verified. Please check your mailbox and follow the verification link finish registration."
  },
  "signUp": {
    "sign_up": "Sign Up"
  },
  "signUpForm": {
    "name": "Name",
    "enter_first_name": "Enter first name",
    "last_name": "Last name",
    "enter_last_name": "Enter last name",
    "email": "Email",
    "enter_email": "Enter email",
    "password": "Password",
    "enter_password": "Enter password",
    "password_confirmation": "Password confirmation",
    "i_agree": "I agree to the",
    "terms_and_conditions": "Terms and Conditions",
    "and": "and",
    "privacy_policy": "Privacy Policy",
    "by_checking_this": "By checking this, you acknowledge and agree to the transfer of the data you upload to this project to third-party services, such as OpenAI. This data transfer is necessary for the proper functioning of the project and to leverage external services that enhance its features.",
    "third_party_services": "Third party services data transfer consent",
    "sign_up": "SIGN UP",
    "or_sign_up_with": "Or Sign Up with",
    "already_have_an_account": "Already have an account?",
    "sign_in": "Sign In",
    "please_check_your_mailbox_and": "Please check your mailbox and \n follow the verification link to finish registration. \n",
    "if_you_cant_find_it_in_your": "If you can't find it in your inbox, please check your",
    "spam_folder": "spam folder",
    "as_well": "as well",
    "this_field_contains_special": "This field contains special characters",
    "please_ensure_youve_read": "Please ensure you've read and agreed to our Terms and Conditions",
    "and_have_consented": "and have consented to the transfer of data to third-party services by checking the respective boxes",
    "before_signing_in": "before signing in",
    "by_signing_up_you_agree": "By signing up, you agree to our Terms and Conditions and acknowledge that you have consented to the transfer of your data to third-party services.",
    "read_our": "Read our"
  }
}
