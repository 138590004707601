{
  "interactiveMindmapWidgetActions": {
    "click_here_to_download": "Натисніть тут, щоб завантажити",
    "language": "Мова",
    "create_map": "Створити мапу"
  },
  "interactiveMindmapWidgetMap": {
    "interactive_mind_map": "Інтерактивна ментальна карта",
    "an_interactive_mind_map_is_a_digital": "Інтерактивна ментальна карта - це цифровий інструмент, призначений для полегшення організації та візуалізації інформації в ієрархічній структурі. Створені за допомогою динамічних веб-технологій, ці ментальні карти пропонують функції взаємодії, такі як перетягування, масштабування та редагування вузлів. Вони особливо корисні для сесій мозкового штурму, планування проектів та візуалізації складних ідей або систем. Використання інтерактивної ментальної карти дозволяє легко категоризувати та посилатися на різні частини інформації, що полегшує їх сприйняття, аналіз та співпрацю.",
    "generate_a_mind_map_in": "Створити ментальну карту в",
    "something_went_awry": "Щось пішло не так!",
    "we_are_unable_to_create_a_mindmap": "Ми не можемо створити ментальну карту на основі наданого вмісту. Ви можете спробувати відкрити налаштування віджета та змінити їх, щоб отримати кращі результати!",
    "translate_into": "Перекласти на",
    "language_every_name_property_s_value_of_in": "мову кожного значення властивості \"name\" в цьому рядку JSON-об'єкта",
    "don_t_change_other_values": "Не змінюйте інші значення. Переконайтеся, що використовуєте лише подвійні лапки та почніть відповідь з",
    "give_me_a_valid_js_array_of_5_topics": "Дайте мені дійсний масив JS з 5 темами (1-3 слова кожна), щоб створити ментальну карту на основі {context} у форматі \"[тема 1, тема 2, тема 3, тема 4, тема 5]\" у мові {languageValue}",
    "please_generate_a_mind_map": "Будь ласка, створіть ментальну карту",
    "about": "про",
    "with": "з",
    "as_a_core_node": "як основний вузол"
  }
}