{
  "airtableInformerInformers": {
    "you_can_provide_your_own_airtable": "Você pode fornecer seu próprio Airtable",
    "personal_access_token": "Token de Acesso Pessoal",
    "in_your_services_credentials_settings": "nas configurações de credenciais de serviços.",
    "click_here": "Clique aqui!",
    "if_you_have_not_generated_yet_a_token": "Se você ainda não gerou um token, por favor vá para a",
    "airtable_platform": "plataforma Airtable",
    "and_generate_one": "e gere um",
    "please_make_sure_to_select": "Por favor, certifique-se de selecionar",
    "and": "e",
    "scopes_and_related_bases": "escopos e bases relacionadas",
    "your_stored_integration_token": "Seu Token de Integração armazenado",
    "is_the_token_incorrect": "O token está incorreto? Você pode alterá-lo nas configurações de credenciais de serviços.",
    "services_credentials_settings": "configurações de credenciais de serviços",
    "fetch_airtable_bases": "Buscar bases do Airtable",
    "select_base": "Selecionar base",
    "select_table": "Selecionar tabela"
  }
}