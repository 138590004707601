{
  "embedCode": {
    "you_can_copy_paste_this_snippet_into_your": "Você pode copiar e colar este trecho em seu código e usar o widget em seu site ou pode usar nosso",
    "package": "pacote",
    "and_natively_integrate_the_widget_into_your_react_app": "e integrar nativamente o widget em seu aplicativo React.",
    "incorporate_this_html_code_into_your_document": "Incorpore este código HTML em seu documento",
    "subsequently_append_the_following_java_script": "Em seguida, adicione o seguinte código JavaScript após seus scripts",
    "send_the_code_and_integration_instructions_to_the_developer": "Envie o código e as instruções de integração para o desenvolvedor",
    "email": "Email",
    "send": "Enviar",
    "copy_code": "Copiar código",
    "code_was_copied": "Código foi copiado!",
    "copied_code": "Código copiado",
    "something_went_awry": "Algo deu errado",
    "your_code_successfully_sent": "Seu código foi enviado com sucesso"
  },
  "settingsInformerModal": {
    "informer_settings": "Configurações do informante",
    "anonymizer": "Anonimizador",
    "splitter": "Divisor",
    "advanced": "Avançado",
    "apply": "Aplicar",
    "apply_for_all": "Aplicar para todos",
    "your_settings_were_successfully_saved": "Suas configurações foram salvas com sucesso"
  },
  "settingsProject": {
    "rename_the_project": "Renomear o projeto",
    "security": "Segurança",
    "operations": "Operações",
    "back": "Voltar",
    "failed_to_load_settings": "Falha ao carregar as configurações",
    "your_settings_successfully_saved": "Suas configurações foram salvas com sucesso",
    "failed_to_save_settings": "Falha ao salvar as configurações",
    "failed_to_delete_password": "Falha ao excluir a senha"
  }
}