{
  "tenantPortalPortalSection": {
    "completed": "Завершено",
    "non_completed": "Не завершено"
  },
  "tenantPortalTabs": {
    "settings": "Налаштування",
    "projects": "Проекти",
    "packages": "Пакети",
    "users": "Користувачі",
    "transactions": "Транзакції"
  }
}