{
  "exportQuestionsCodeModal": {
    "how_to_import_into_quizlet": "Wie man in Quizlet importiert",
    "start_by_heading_to": "Beginnen Sie, indem Sie zu Quizlet gehen und auf die Schaltfläche + klicken, um einen neuen Lernset zu erstellen. Suchen und klicken Sie dann auf die Schaltfläche \"Importieren\". Kopieren Sie nun einfach den oben bereitgestellten Inhalt und fügen Sie ihn in Quizlet ein. Denken Sie daran: Verwenden Sie einen",
    "tab": "Tabulator",
    "between_each_term_and_its_definition_and_a": "zwischen jedem Begriff und seiner Definition und einen",
    "new_line": "Neue Zeile",
    "to_separate_different_cards": "um verschiedene Karten zu trennen. Viel Spaß beim Lernen!",
    "copy_content": "Inhalt kopieren",
    "copied_content": "Inhalt kopiert",
    "content_was_copied": "Inhalt wurde kopiert!"
  },
  "filtersModal": {
    "filters": "Filter",
    "search_for_resources": "Nach Ressourcen suchen...",
    "search_by_selected_resources": "Nach ausgewählten Ressourcen suchen",
    "reset_all": "Alles zurücksetzen",
    "check_all": "Alle auswählen",
    "sorry_no_resources_found_for_your": "Es tut uns leid, es wurden keine Ressourcen für Ihre Suchanfrage gefunden",
    "please_try_modifying_your": "Bitte versuchen Sie, Ihre Suchbegriffe oder Filter zu ändern, um Ihre Suche zu erweitern.",
    "cancel": "Abbrechen",
    "apply": "Anwenden"
  },
  "liveConnect": {
    "error_executing_user_code": "Fehler beim Ausführen des Benutzercodes",
    "something_went_wrong": "Etwas ist schief gelaufen! Bitte stellen Sie sicher, dass Sie Intercom korrekt installiert haben",
    "something_went_wrong_please_make_sure": "Etwas ist schief gelaufen! Bitte stellen Sie sicher, dass Sie das Hubspot-Widget korrekt installiert haben",
    "something_went_wrong_please_make_sure_dialogFlow": "Etwas ist schief gelaufen! Bitte stellen Sie sicher, dass Sie DialogFlow korrekt installiert haben"
  },
  "offlineMessage": {
    "name": "Name",
    "email": "E-Mail",
    "subject": "Betreff",
    "message": "Nachricht",
    "submit": "Senden",
    "error_executing_user_code": "Fehler beim Ausführen des Benutzercodes",
    "thank_you_for_your_response": "Vielen Dank für Ihre Antwort!",
    "something_went_awry": "Etwas ist schief gelaufen!"
  },
  "poweredByMessage": {
    "powered_by": "Powered by"
  },
  "questionnaires": {
    "examples_of_questions_that_you_can_ask": "Beispiele für Fragen, die Sie den Bot stellen können"
  },
  "widgetsFooter": {
    "back": "Zurück",
    "help": "Hilfe"
  },
  "widgetsModalInfo": {
    "it_staff_augmentation_in_portugal": "IT-Personalverstärkung in Portugal",
    "upload_time": "Upload-Zeit",
    "result_relevance": "Ergebnisrelevanz"
  },
  "widget_external_user_form": {
    "please_provide_information_to_chat_with_me": "Bitte geben Sie Informationen an, um mit mir zu chatten",
    "your_email": "Ihre E-Mail",
    "email": "E-Mail",
    "your_name": "Ihr Name",
    "name": "Name",
    "continue": "Weiter"
  }
}