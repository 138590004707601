{
  "pagesProjectsIdWidgetViewContent": {
    "let_s_upload_the_content": "Let's upload the content",
    "with": "With",
    "informers": "Informers",
    "you_can_upload_information": "you can upload information from a variety of sources, such as websites, PDF documents, even Google folders, and more. Remember, you can always return to this screen to load additional content into your project",
    "upload_information": "Upload information from a variety of sources, such as websites, PDF documents, even Google folders, and more. Remember, you can always return to this screen to load additional content into your project whenever you need.",
    "whenever_you_need": "whenever you need",
    "select_widget": "Select widget",
    "back": "Back",
    "we_are_working_on_it": "We are working on it.",
    "is_not_supported_for_project_with": "is not supported for project with uploaded data. Please create a new project for this one"
  },
  "pagesProjectsIdWidgetMyWidgets": {
    "delete_widget": "Delete Widget",
    "confirm_widget_deletion": "Confirm Widget Deletion",
    "are_you_sure_you_want_to_delete": "Are you sure you want to delete {widgetName} widget? This action cannot be undone."
  }
}
