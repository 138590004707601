{
  "summaryWidgetWidgets": {
    "summary": "Зведення",
    "generate_summary": "Створити зведення",
    "context": "КОНТЕКСТ",
    "make_summary_in": "Створити зведення в",
    "keeping_the_tone": "зберігаючи {formTone} тон",
    "use_markdown_format": "Використовуйте формат markdown",
    "add_the_primary_question": "Додайте основне питання з відповіддю, яка відповідає контексту, використовуючи курсивний формат.",
    "add_a_header_as_h2_markdown": "Додайте заголовок як h2 markdown.",
    "add_main_emojis": "Додайте основні емодзі тут.",
    "add_bold_main_keywords": "Додайте жирні основні ключові слова тут.",
    "add_a_header": "Додайте заголовок як h2 markdown.",
    "add_1_sentence_quote": "Додайте 1 речення цитати найважливішої фрази контексту, використовуючи блокування markdown.",
    "if_you_have_what_to_add": "Якщо у вас є, що додати у вигляді маркерних пунктів, додайте його в кінці як список markdown.",
    "an_error_occurred_while_fetching": "Під час отримання зведення сталася помилка.",
    "something_didn_t_go_as_planned": "Щось пішло не за планом.",
    "error_occured_while_creating_pdf": "Під час створення PDF сталася помилка"
  },
  "summaryWidgetAnswer": {
    "summary": "Зведення",
    "from_original": "з оригінальних ~{pages} сторінок тексту",
    "download_pdf": "Завантажити PDF",
    "clear_summary": "Очистити зведення"
  },
  "summaryWidgetAnswerChunk": {
    "click_to_edit_this_block": "Клацніть, щоб редагувати цей блок",
    "click_to_adjust_this_block": "Клацніть, щоб налаштувати цей блок",
    "click_to_delete_this_block": "Клацніть, щоб видалити цей блок",
    "something_went_awry": "Щось пішло не так!",
    "you_ve_successfully_deleted": "Ви успішно видалили частину зведення!"
  },
  "summaryWidgetAnswerHeader": {
    "click_to_edit": "Клацніть, щоб редагувати заголовок зведення"
  },
  "summaryWidgetForm": {
    "you_ve_enabled_custom_prompt_in_settings": "Ви увімкнули власний запит у налаштуваннях!",
    "block_size": "Розмір блоку",
    "size_from_original": "Розмір з оригінальних ~{totalPages} сторінок тексту",
    "tone": "Тон",
    "speak_tone": "Говорити... тон",
    "language": "Мова",
    "options": "Опції",
    "add_subheaders": "додати підзаголовки",
    "add_important_quotes": "додати важливі цитати",
    "use_bullet_points": "використовуйте формат маркерних пунктів",
    "add_main_question": "додати основне питання",
    "add_emojis": "додати емодзі",
    "bold_main_keywords": "жирні основні ключові слова",
    "write_it_for_a": "Напишіть це для дитини 9 років, як подорож-історію",
    "create_preview": "Створити попередній перегляд",
    "original": "оригінал"
  },
  "summaryWidgetModal": {
    "create_summary_pdf": "Створити зведення PDF",
    "ok": "ОК",
    "wait_for_a_few_seconds_please": "Зачекайте кілька секунд, будь ласка..."
  },
  "summaryWidgetPreview": {
    "preview": "Попередній перегляд",
    "summary_a_piece_of_content": "зведення частини вмісту",
    "refresh_preview": "Оновити перегляд"
  },
  "SummaryMethodOptionsEnum": {
    "percent": "відсотків",
    "pages": "сторінок",
    "words": "слів"
  }
}