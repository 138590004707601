{
  "knowledgeFilterSourcesModal": {
    "filter": "Filtrar",
    "apply": "Aplicar",
    "cancel": "Cancelar",
    "by_title": "Por título",
    "by_period": "Por período",
    "search_for_resource_title": "Pesquisar por título do recurso..."
  },
  "knowledgeSources": {
    "no_data_so_far": "Sem dados até agora.",
    "type": "Tipo",
    "title": "Título",
    "date": "Data",
    "actions": "Ações",
    "delete_these_items": "Excluir estes itens?",
    "are_you_sure_you_want_to_delete_selected_items": "Tem certeza de que deseja excluir os itens selecionados?",
    "reupload_these_items": "Reenviar estes itens?",
    "this_action_cannot_be_undone": "Esta ação não pode ser desfeita.",
    "are_you_sure_you_want_to_delete_and_reupload": "Tem certeza de que deseja excluir e reenviar os itens selecionados?",
    "reupload": "Reenviar",
    "the_resources_were_removed": "Os recursos foram removidos.",
    "an_error_occurred_while_deleting_resources": "Ocorreu um erro ao excluir os recursos",
    "sorry_we_re_unable_to_open_this_resource": "Desculpe, não é possível abrir este recurso diretamente, pois não é um recurso de conhecimento em texto.",
    "show_progress": "Clique aqui para ver o progresso"
  },
  "knowledgeSourcesActions": {
    "view": "Visualizar",
    "refresh_from_source_coming_soon": "Atualizar a partir da fonte (Em breve!)",
    "delete": "Excluir"
  },
  "knowledgeSourcesToolbar": {
    "resources_selected": "Recursos selecionados:"
  },
  "knowledgeToolbarSourcesFilters": {
    "by_date": "Por data"
  },
  "knowledgeToolbarSourcesUpdater": {
    "refresh_from_source_available_soon": "Atualizar a partir da fonte (Disponível em breve!)"
  }
}