{
  "copyProjectModal": {
    "clone_current_project": "Clone current project",
    "clone_project": "Clone project",
    "copy_this_project": "Copy this project?",
    "copy_project": "Copy project",
    "all_your_settings_and_resources_on_this": "All your settings and resources on this project will be copy.",
    "it_looks_like_you_ve_reached_your_maximum_number": "It looks like you've reached your maximum number of projects. To start a new project, consider archiving or deleting existing ones you no longer need, or explore our subscription plans for higher limits."
  },
  "deleteProjectModal": {
    "delete_current_project": "Delete current project",
    "delete_project": "Delete project",
    "delete": "Delete",
    "all_your_data_on": "All your data on",
    "will_be_deleted_immediately": "will be deleted immediately.",
    "the_project": "the project"
  },
  "deleteShopifyContent": {
    "seems_like_you_ve_created_a_project_using_our_shopify": "Seems like you've created a project using our Shopify app, and you're considering to delete it through the main app. We Recommend Deleting it via Shopify App: Deleting a project through our Shopify app is the recommended and safest way to ensure a smooth and error-free process.",
    "yes_i_understand_it_but_want_to_delete_it_anyway": "Yes, I understand it, but want to delete it anyway."
  },
  "projectUploadingMessage": {
    "you_have_updated_knowledge_please_wait": "You have updated Knowledge. Please wait while we uploading your Knowledge.",
    "knowledge_uploaded": "Knowledge uploaded..."
  },
  "publishProject": {
    "unpublish": "Unpublish",
    "publish": "Publish",
    "unpublish_project": "Unpublish project",
    "all_users_who_had_access_to_the_widgets": "All users who had access to the widgets through the link will lose the ability to use them.",
    "share_project": "Share project",
    "please_note_that_any_activity_of_the_users": "Please note that any activity of the users who you share this link with is bound to your account balance.",
    "publish_widget": "Publish widget"
  },
  "renameProject": {
    "enter_the_short_name_of_your_project": "Enter the short name of your project",
    "my_project": "My Project",
    "description": "Description"
  },
  "shareProject": {
    "widget_link_copied_to_clipboard": "Widget link copied to clipboard",
    "share_project": "Share project",
    "please_note_that_any_activity_of_the_users": "Please note that any activity of the users who you share this link with is bound to your account balance.",
    "share": "Share",
    "publish_widget": "Publish widget"
  },
  "shareProjectModal": {
    "share_widget": "Share widget",
    "please_note_that_any_activity_of_the_users": "Please note that any activity of the users who you share this link with is bound to your account balance.",
    "copy_link": "Copy link",
    "share_on": "Share on"
  },
  "unpublishProject": {
    "unpublish_project": "Unpublish project",
    "all_users_who_had_access_to_the_widgets": "All users who had access to the widgets through the link will lose the ability to use them."
  }
}