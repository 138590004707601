{
  "caseDatabase": {
    "it_looks_like_you_ve_reached_your_maximum_number": "Схоже, ви досягли максимальної кількості проектів. Щоб створити новий проект, розгляньте можливість архівування або видалення існуючих, які вам більше не потрібні, або ознайомтесь з нашими підписними планами для отримання більш високих обмежень."
  },
  "welcomeFlowCustomJourney": {
    "ready_for_the_next_step": "Готові до наступного кроку у вашому персоналізованому шляху?",
    "it_is_time_to_initiate_a_new_project": "Час розпочати новий проект. Погрузьтесь у налаштований досвід з нашим набором інструментів, включаючи різноманітні віджети, призначені для організації та аналізу вашої інформації.",
    "expand_your_projects_scope_by_integrating": "Розширте обсяг вашого проекту, інтегруючи різноманітний контент, від веб-сайтів та окремих посилань до документів, розміщених у Google Drive, Dropbox, One Drive, PDF-файлів та багатьох інших.",
    "embark_on_this_unique_adventure": "Вирушайте у цю унікальну пригоду, розпочавши свій власний проект сьогодні та розкрийте повний потенціал своїх даних.",
    "create_project": "Створити проект",
    "or": "Або",
    "advice": "порада",
    "let_our_bot_provide_you_with": "дозвольте нашому боту надати вам",
    "on_how_to_make_this_process": "щодо того, як зробити цей процес легшим.",
    "project_name": "Назва проекту"
  },
  "wantToUpgradeCard": {
    "upgrade": "Оновити",
    "we_understand_youre_interested_in_this": "Ми розуміємо, що вам цікава ця функція. Вона доступна у наших планах вищого рівня. Розгляньте можливість оновлення, щоб отримати до неї доступ!"
  },
  "welcomeAnswerCard": {
    "you_can_select_a_tile_below": "Ви можете вибрати плитку нижче",
    "it_looks_like_you_ve_reached_your_maximum_number": "Схоже, ви досягли максимальної кількості проектів. Щоб створити новий проект, розгляньте можливість архівування або видалення існуючих, які вам більше не потрібні, або ознайомтесь з нашими підписними планами для отримання більш високих обмежень."
  },
  "welcomeScreen": {
    "default_goals": "У мене є серія файлів на моєму комп'ютері",
    "default_communicate_goals": "Я хочу, щоб спільнота мала цілодобовий доступ до бази знань та могла спілкуватися з нею, як з людиною",
    "not_sure_let_us_know": "Не впевнені? Давайте дізнаємося щось у нашому",
    "what_want_to_do": "Введіть, що ви хочете зробити?",
    "what_i_want_to_do_question": "Що я хочу\n          зробити?",
    "how_communicate": "Як я буду спілкуватися з даними?",
    "not_sure": "Не впевнені?",
    "lets_find": "Давайте дізнаємося щось\n      у нашому",
    "what_types_of_files": "Які типи файлів у мене є і як я хочу їх завантажити?",
    "cases_database": "База даних справ",
    "or_start_your": "або розпочніть свій",
    "impress_me": "Врази мене",
    "regenerate": "Відновити",
    "custom_journey": "Індивідуальний шлях",
    "project_name": "Назва проекту",
    "enter_the_name_of_the_project": "Введіть назву проекту",
    "enter_project_name": "Введіть назву проекту",
    "what_i_want_to_do": "Те, що я хочу зробити: я хочу поділитися своїми знаннями зі своїми студентами. Я хочу, щоб вони мали можливість отримувати відповіді цілодобово.",
    "what_types_of_files_i_have_and_how_i_want": "Які типи файлів у мене є і як я хочу їх завантажити: у мене є серія PDF-файлів на OneDrive, які я хочу завантажити.",
    "how_i_am_going_to": "Як я буду спілкуватися з даними: я хочу, щоб студенти спілкувалися з ботом у WhatsApp.",
    "we_could_not_create_the": "Ми не змогли створити проект правильно.",
    "what_types_of_files_you_want_to_upload": "Які типи файлів ви хочете завантажити?",
    "how_are_you_going_to_communicate_with_the_data": "Як ви будете спілкуватися з даними?",
    "select_project_type": "Виберіть тип проекту",
    "agent": "Агент",
    "knowledge_legacy": "Знання (спадщина)",
    "agent_description": "Агенти можуть спілкуватися з користувачем на основі власної бази знань, а також виконувати завдання та дії із зовнішніми сервісами через різноманітні інструменти та API відповідно до встановленого вами сценарію.",
    "knowledge_description": "Проєкти знань можуть спілкуватися з користувачем лише на основі власних спеціальних знань."
  }
}
