{
  "docSearchWidget": {
    "scanning_through_the_documentation": "Сканування документації, зачекайте, будь ласка!",
    "generate_three_suggested_questions": "Згенеруйте три рекомендовані питання на основі наданих ресурсів. Кожне питання повинно бути розділене переносом рядка і складатися не більше, ніж з п'яти слів. Будь ласка, уникайте нумерації питань.",
    "oops_it_seems_youve_reached_your": "Ой! Здається, ви досягли обмеження на кількість повідомлень. Щоб продовжити, будь ласка, змініть обмеження в налаштуваннях.",
    "we_encountered_an_issue_while": "Під час спроби згенерувати відповідь виникла проблема! Спробуйте ще раз пізніше!",
    "search": "Пошук"
  },
  "docSearchWidgetHeader": {
    "hi_how_can_i_help_you": "Привіт! Як я можу вам допомогти?"
  },
  "docSearchWidgetInput": {
    "type_a_question_here": "Введіть питання тут...",
    "ask": "Запитати"
  },
  "docSearchWidgetReset": {
    "reset": "Скинути"
  },
  "docSearchWidgetWrapper": {
    "click_here_to_see_embed_code": "Натисніть тут, щоб побачити вбудований код"
  }
}