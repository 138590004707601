{
  "portalPackageItem": {
    "name_is_not_provided": "Ім'я не вказано",
    "click_to_edit_the_package": "Натисніть, щоб редагувати пакет",
    "click_to_delete_the_package": "Натисніть, щоб видалити пакет",
    "package_name": "Назва пакету",
    "your_package_name": "Назва вашого пакету",
    "description": "Опис",
    "your_package_description": "Опис вашого пакету",
    "model": "Модель",
    "package_price": "Ціна пакету",
    "subscription_cost_for_the_package": "Вартість підписки на пакет",
    "cost_per_symbols": "Вартість за {symbols_quantity} символів",
    "cost_for_characters": "Вартість за {characters_quantity} символів при запиті до OpenAI (призначено для уникнення зайвих витрат користувача). У середньому, один запит містить {average} символів",
    "approx_number_of_requests": "приблизна кількість запитів",
    "trial_days": "Дні пробного періоду",
    "links": "Посилання",
    "create_portal_link": "Створити посилання на портал",
    "delete_this_project": "Видалити цей проект?",
    "delete_project": "Видалити проект",
    "package_will_be_deleted": "Пакет буде видалено. Цю дію неможливо скасувати",
    "the_item_has_been_successfully_deleted": "Елемент успішно видалено."
  },
  "portalPackages": {
    "no_packages_so_far": "Поки що немає пакетів",
    "apply": "Застосувати",
    "stripe_account_is_not_configured": "Акаунт Stripe не налаштовано",
    "packages_successfully_saved": "Пакети успішно збережено"
  }
}