{
  "formClearHistoryItem": {
    "clear_chat_history": "Limpar histórico do chat",
    "do_you_want_to_clear": "Deseja limpar o histórico do chat?",
    "clear_history": "Limpar histórico",
    "all_your_history_will_be_deleted": "Todo o seu histórico do chat será excluído."
  },
  "formOptions": {
    "more_options": "Mais opções"
  },
  "formPdfGeneratingItem": {
    "export_chat_as_pdf": "Exportar chat como PDF",
    "create_chat_to_pdf": "Criar chat para PDF",
    "wait_for_a_few_seconds_please": "Aguarde alguns segundos, por favor...",
    "something_happened": "Ocorreu algo ao criar o PDF"
  }
}