{
  "pagesOmniPortalId": {
    "name": "nombre",
    "add_new": "Agregar nuevo",
    "name_not_provided": "No se proporcionó el nombre"
  },
  "pagesOmniPortalIndex": {
    "portal": "Portal",
    "welcome_to_our_turnkey_portal": "Bienvenido a nuestra función de portal llave en mano, donde tienes el poder de personalizar, rebrandear y ofrecer soluciones de IA de vanguardia sin necesidad de desarrollar desde cero. Nuestra plataforma ofrece un marco integral que te permite adaptar planes de suscripción y niveles exactamente a tus necesidades y las de tus usuarios. Con solo unos clics, puedes seleccionar y asignar varios widgets e informadores, asegurando que cada uno de tus usuarios reciba una experiencia personalizada bajo tu marca.",
    "the_seamless_integration": "Esta integración perfecta significa que puedes ofrecer servicios sofisticados manteniendo un control completo sobre la interfaz de usuario y la funcionalidad. Se trata de mejorar tu oferta con un esfuerzo mínimo y una eficiencia máxima. Tú proporcionas la visión; nuestra plataforma proporciona las herramientas para darle vida. Comienza a redefinir tu servicio digital hoy mismo y observa cómo tu negocio crece gracias a nuestras soluciones de IA.",
    "create_my_first_portal": "Crear mi primer portal"
  },
  "pagesOmniPortalChangePassword": {
    "you_will_be_redirected_to_the_home": "Serás redirigido a la página de inicio en {value} segundos",
    "to_go_to_the_home_page": "Para ir a la página de inicio",
    "click_here": "haz clic aquí",
    "enter_your_new_password": "Ingresa tu nueva contraseña",
    "password_successfully_changed": "Contraseña cambiada exitosamente",
    "invalid_token": "Token inválido"
  },
  "pagesOmniPortalLink": {
    "looks_like_you_ve_got_no_access": "Parece que no tienes acceso a este widget"
  },
  "pagesOmniPortalResetPassword": {
    "user_with_such_email_not_found": "No se encontró ningún usuario con ese correo electrónico"
  },
  "pagesOmniPortal": {
    "this_portal_does_not_contain_any_projects": "Este portal no contiene ningún proyecto"
  }
}