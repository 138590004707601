{
  "settingsCustomize": {
    "bot_name": "Bot name",
    "enter_custom_bot_name": "Enter custom bot name",
    "welcome_messages": "Welcome Messages",
    "enter_messages_separated_by_double_enter": "Enter messages, separated by double Enter.",
    "type_messages_double_enter_to_separate": "Type messages, double Enter to separate.",
    "add_a_custom_welcome_message_interval_seconds": "Add a custom welcome message interval (seconds)",
    "enter_welcome_message_interval": "Enter welcome message interval...",
    "show_followups_questions": "Show followups questions",
    "add_a_custom_message_ending_to_every_omni_mind_response": "Add a custom message ending to every OmniMind response",
    "enter_custom_message": "Enter custom message...",
    "label_for_examples_of_questions": "Label for Examples of questions that you can ask the bot about",
    "before_you_start_to_work_with_widget": "Before you start to work with widget, you see examples of questions that you can ask the bot about",
    "enter_label_for_examples": "Enter label for Examples of questions that you can ask the bot about...",
    "change_placeholder_text_of_the_form_s_input": "Change placeholder text of the form's input",
    "enter_custom_placeholder_text": "Enter custom placeholder text...",
    "submit_button_s_text": "Submit button's text",
    "enter_text_for_submit_button": "Enter text for submit button...",
    "opening_message": "Opening message",
    "enter_custom_opening_message": "Enter custom opening message",
    "change_the_widget_color_style": "Change the widget color style",
    "show_examples_of_questions_that_you_can_ask_the_bot_about": "Show examples of questions that you can ask the bot about",
    "default_language": "Default language",
    "browser_language_auto_detect": "Browser language auto-detect",
    "custom_prompt": "Custom prompt",
    "using_a_direct_custom_prompt_is_only_necessary": "Using a direct custom prompt is only necessary in special cases, and it will disable the main form.",
    "change_the_header_font_size": "Change the header font size",
    "change_the_body_font_size": "Change the body font size",
    "hide_powered_by_label": "Hide 'Powered by' label",
    "show_answer_sources_option": "Show answer sources option",
    "failed_to_upload_branding_logo": "Failed to upload branding logo"
  },
  "customizeColorInput": {
    "primary": "Primary",
    "background": "Background",
    "secondary": "Secondary",
    "stroke": "Stroke",
    "accent": "Accent",
    "text": "Text",
    "body": "Body",
    "default_color_scheme": "Default color scheme"
  },
  "customizeColorModal": {
    "create_the_custom_widget_color_style": "Create the custom widget color style",
    "save": "Save",
    "the_custom_widget_color_style": "{value} the custom widget color style",
    "edit": "Edit",
    "create": "Create",
    "primary": "Primary",
    "background_color": "Background color",
    "secondary": "Secondary",
    "stroke_color": "Stroke color",
    "text_color": "Text color",
    "accent_color": "Accent color",
    "body_background": "Body background",
    "leave_empty_for_transparent_body": "Leave empty for transparent body",
    "current_color_style_examples_click_to_clone": "Current color style examples, click to clone",
    "field_is_not_a_valid_hex_color": "Field is not a valid hex color"
  },
  "customizeColorSchemes": {
    "changes_to_the_color_theme_of_your_widget": "Changes to the color theme of your widget will be reflected in the \"Share Widget\" and \"Embed Code\" modes.",
    "you_have_reached_the_maximum_allowed_limit": "You have reached the maximum allowed limit. Please remove some schemes before creating new ones.",
    "click_here_to_create_a_new_scheme": "Click here to create a new scheme."
  },
  "customizeFileUploader": {
    "upload_custom_avatar_or_logo": "Upload branding logo",
    "drag_drop_or": "Drag & Drop or",
    "select_files": "Select files",
    "to_upload": "to upload",
    "error_uploading_file": "Error uploading file"
  },
  "customizeFileUploaderImage": {
    "no_logo": "No logo"
  },
  "customizeQuestionnaires": {
    "add_custom_questions": "Add custom questions",
    "enter_custom_message": "Enter custom message...",
    "regenerate": "Refresh  questions"
  }
}
