{
  "addNewPortalModal": {
    "add_new_portal": "Add new portal?",
    "add_portal": "Add Portal",
    "the_portal_was_successfully_created": "The Portal was successfully created.",
    "oops_something_went_wrong": "Oops! Something went wrong."
  },
  "deletePortalModal": {
    "delete_portal": "Delete portal",
    "confirm": "Confirm",
    "you_are_about_to_delete_the_portal": "You are about to delete the portal. This cannot be undone. Are you sure you want to continue?"
  },
  "portalSettingsDomain": {
    "domain": "Domain",
    "your_domain_copied_to_clipboard": "Your domain copied to clipboard",
    "example": "example",
    "subdomain": "Subdomain",
    "custom_domain": "Custom domain",
    "this_domain_is_already_taken": "This domain is already taken"
  },
  "portalSettingsGeneral": {
    "general": "General"
  },
  "portalSettingsGeneralForm": {
    "name": "Name",
    "example_name": "Example name",
    "brief_info": "Brief info",
    "example_brief_info": "Example brief info"
  },
  "portalSettingsPayments": {
    "payments_processing": "Payments processing",
    "we_use_stripe_payment_platform": "We use Stripe payment platform to process payments between you and your clients. You have to create your profile and connect payment method",
    "connect": "Connect",
    "configure": "Configure",
    "account": "account"
  },
  "portalSettings": {
    "manage": "Manage",
    "suspend_portal": "Suspend portal",
    "reactivate_portal": "Reactivate portal",
    "delete_portal": "Delete portal",
    "apply": "Apply",
    "portal_settings_successfully_updated": "Portal settings successfully updated.",
    "oops_this_domain_is_already_taken": "Oops! This domain is already taken.",
    "please_fill_in_all_required_fields": "Please fill in all required fields."
  },
  "suspendPortalModal": {
    "suspend_portal": "Suspend portal",
    "confirm": "Confirm",
    "you_are_about_to_suspend_the_portal": "You are about to suspend the portal. The portal will be unavailable to your users until you activate this one. Are you sure you want to continue?"
  }
}