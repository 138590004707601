{
  "pagesOmniPortalId": {
    "name": "nome",
    "add_new": "Adicionar novo",
    "name_not_provided": "Nome não fornecido"
  },
  "pagesOmniPortalIndex": {
    "portal": "Portal",
    "welcome_to_our_turnkey_portal": "Bem-vindo ao nosso recurso de portal completo, onde você tem o poder de personalizar, rebrand e fornecer soluções de IA de ponta sem a necessidade de desenvolver do zero. Nossa plataforma oferece uma estrutura abrangente que permite adaptar planos de assinatura e níveis precisamente às suas necessidades e às de seus usuários. Com apenas alguns cliques, você pode selecionar e atribuir vários widgets e informantes, garantindo que cada um de seus usuários receba uma experiência personalizada sob sua marca.",
    "the_seamless_integration": "Essa integração perfeita significa que você pode oferecer serviços sofisticados mantendo completo controle sobre a interface do usuário e a funcionalidade. Tudo se resume a aprimorar sua oferta com o mínimo de esforço e máxima eficiência. Você fornece a visão; nossa plataforma fornece as ferramentas para dar vida a ela. Comece a redefinir seu serviço digital hoje mesmo e veja seu negócio crescer com a força de nossas soluções de IA.",
    "create_my_first_portal": "Criar meu primeiro portal"
  },
  "pagesOmniPortalChangePassword": {
    "you_will_be_redirected_to_the_home": "Você será redirecionado para a página inicial em {value} segundos",
    "to_go_to_the_home_page": "Para ir para a página inicial",
    "click_here": "clique aqui",
    "enter_your_new_password": "Digite sua nova senha",
    "password_successfully_changed": "Senha alterada com sucesso",
    "invalid_token": "Token inválido"
  },
  "pagesOmniPortalLink": {
    "looks_like_you_ve_got_no_access": "Parece que você não tem acesso a este widget"
  },
  "pagesOmniPortalResetPassword": {
    "user_with_such_email_not_found": "Usuário com esse e-mail não encontrado"
  },
  "pagesOmniPortal": {
    "this_portal_does_not_contain_any_projects": "Este portal não contém nenhum projeto"
  }
}