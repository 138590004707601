{
  "dialogs": {
    "user_info": "Informações do usuário",
    "select_all_dialogs": "Selecionar todos os diálogos",
    "search": "Pesquisar...",
    "deletion_of_the_selected_dialogs": "Exclusão dos diálogos selecionados",
    "there_are_no_dialogs_yet": "Ainda não há diálogos",
    "knowledge": "Conhecimento",
    "delete": "Excluir",
    "something_went_wrong": "Algo deu errado durante a exclusão",
    "question_and_answer_were_saved_successfully": "Pergunta e resposta foram salvos com sucesso!",
    "an_error_occurred_while_saving": "Ocorreu um erro ao salvar a pergunta e resposta!",
    "upgrade_plan": "Para usar essa funcionalidade, por favor, atualize seu plano",
    "anonym": "Anônimo",
    "no_tracking": "O rastreamento de mensagens está desativado no momento. Por favor, habilite-o nas configurações para continuar.",
    "not_defined": "Não definido",
    "browser": "Navegador",
    "os": "SO",
    "more": "mais",
    "less": "menos"
  },
  "dialogsFilters": {
    "filters": "Filtros",
    "by_period": "Por período",
    "by_negative": "Feedbacks negativos",
    "by_calls": "Chamadas de operador",
    "by_offline": "Mensagens offline",
    "apply": "Aplicar",
    "cancel": "Cancelar"
  }
}