import { gql } from '@apollo/client/core';

export const GET_USER_PORTALS = gql`
  query GetUserPortals($id: uuid!) {
    portals(where: { user_id: { _eq: $id } }) {
      brief
      domain
      id
      name
      settings
      stripe_id
      user_id
      is_active
      portals_profiles {
        user_id
        portal_id
        user {
          id
          email
        }
        status
      }
      billing_user_packages {
        id
        user_id
        package_name
        product_id
      }
      portals_links(order_by: { order: asc }) {
        id
        order
        portal_id
        project_id
        widget_id
        link_name
      }
    }
  }
`;

export const GET_PORTAL_PROJECTS_BY_DOMAIN = gql`
  subscription GetPortalProjectsByDomain($domain: String!) {
    portals(where: { domain: { _eq: $domain } }) {
      id
      name
      domain
      settings
      stripe_id
      user_id
      is_active
      portals_links(order_by: { order: asc }) {
        id
        order
        portal_id
        project_id
        widget_id
        link_name
      }
      portals_profiles {
        user_id
        portal_id
        status
      }
    }
  }
`;

export const GET_PORTAL_STYLES_BY_DOMAIN = gql`
  query GetPortalProjectsByDomain($domain: String!) {
    portals(where: { domain: { _eq: $domain } }) {
      id
      settings
    }
  }
`;

export const GET_USER_BILLING_PACKAGES_BY_PORTAL_ID = gql`
  subscription GetUserBillingPackagesByPortalId($user_id: uuid!, $portal_id: uuid!) {
    billing_user_packages(
     where: { status: { _eq: "active"}, portal_id: { _eq: $portal_id }, user_id: { _eq: $user_id } },
     order_by: { updated_at: desc }, 
     limit: 1
    ) {
      package_name
      package_features
      status
      portal_id
      user_id
      product_id
      package_price
      package_costs
      units_amount
      units_spent
      cost
    }
  }
`;

export const GET_WIDGET_BY_ID = gql`
  query getWidgetById($id: uuid!) {
    widgets(where: { id: { _eq: $id } }) {
      id
      project_id
      name
      type_id
      is_default
      settings
    }
  }
`;

export const GET_PORTALS_DOMAINS = gql`
  query getPortalsDomains {
    portals {
      id
      domain
    }
  }
`;

export const GET_PORTAL_USER_IS_PAID_STATUS = gql`
  query getPortalUserIsPaidStatus($domain: String!, $userId: uuid!) {
    portals_profiles(where: { portal: { domain: { _eq: $domain } }, user_id: { _eq: $userId } }) {
      user_id
      portal_id
    }
  }
`;
