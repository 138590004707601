{
  "foundBug": {
    "found_a_bug": "Hai trovato un bug?"
  },
  "whatsNew": {
    "whats_new": "Novità"
  },
  "userHeader": {
    "profile": "Profilo",
    "billing": "Fatturazione"
  }
}