import type { ObjectDirective } from 'vue';

interface HTMLImageElementWithOnError extends HTMLImageElement {
  onerror: () => void;
}

const setImage = (el: HTMLImageElement, fallback: string) => {
  const img = new Image();

  img.onerror = () => {
    if (el.src !== fallback) {
      el.src = fallback;
    }
  };

  img.onload = () => {
    if (el.src !== img.src) {
      el.src = img.src;
    }
  };

  img.src = el.src;

  if (!img.complete && img.src !== fallback) {
    el.src = fallback;
  }
}

const fallbackImage: ObjectDirective<HTMLImageElementWithOnError> = {
  beforeMount(el, binding) {
    const fallback = binding.value || '/assets/images/user_avatar_icon.svg';
    setImage(el, fallback)
  },
  updated(el, binding, vnode, oldVnode) {
    const oldSrc = oldVnode.props && oldVnode.props.src;
    const newSrc = vnode.props && vnode.props.src;
    if (newSrc !== oldSrc) {
      const fallback = binding.value || '/assets/images/user_avatar_icon.svg';
      setImage(el, fallback)
    }
  }
};

export default fallbackImage;
