{
  "addNewPortalModal": {
    "add_new_portal": "Додати новий портал?",
    "add_portal": "Додати портал",
    "the_portal_was_successfully_created": "Портал успішно створено.",
    "oops_something_went_wrong": "Ой! Виникла помилка."
  },
  "deletePortalModal": {
    "delete_portal": "Видалити портал",
    "confirm": "Підтвердити",
    "you_are_about_to_delete_the_portal": "Ви збираєтесь видалити портал. Цю дію неможливо скасувати. Ви впевнені, що хочете продовжити?"
  },
  "portalSettingsDomain": {
    "domain": "Домен",
    "your_domain_copied_to_clipboard": "Ваш домен скопійовано в буфер обміну",
    "example": "приклад",
    "subdomain": "Піддомен",
    "custom_domain": "Спеціальний домен",
    "this_domain_is_already_taken": "Цей домен вже зайнятий"
  },
  "portalSettingsGeneral": {
    "general": "Загальне"
  },
  "portalSettingsGeneralForm": {
    "name": "Назва",
    "example_name": "Приклад назви",
    "brief_info": "Коротка інформація",
    "example_brief_info": "Приклад короткої інформації"
  },
  "portalSettingsPayments": {
    "payments_processing": "Обробка платежів",
    "we_use_stripe_payment_platform": "Ми використовуємо платіжну платформу Stripe для обробки платежів між вами та вашими клієнтами. Вам потрібно створити свій профіль та підключити платіжний метод",
    "connect": "Підключити",
    "configure": "Налаштувати",
    "account": "акаунт"
  },
  "portalSettings": {
    "manage": "Керувати",
    "suspend_portal": "Призупинити портал",
    "reactivate_portal": "Активувати портал",
    "delete_portal": "Видалити портал",
    "apply": "Застосувати",
    "portal_settings_successfully_updated": "Налаштування порталу успішно оновлено.",
    "oops_this_domain_is_already_taken": "Ой! Цей домен вже зайнятий.",
    "please_fill_in_all_required_fields": "Будь ласка, заповніть всі обов'язкові поля."
  },
  "suspendPortalModal": {
    "suspend_portal": "Призупинити портал",
    "confirm": "Підтвердити",
    "you_are_about_to_suspend_the_portal": "Ви збираєтесь призупинити портал. Портал буде недоступний для ваших користувачів, поки ви його не активуєте. Ви впевнені, що хочете продовжити?"
  }
}