import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import type { PortalColorsInterface } from '@components/project/settings/SettingsColorsInterface';
import { useUserData } from '@nhost/vue';

export const usePortalsStore = defineStore('portals', () => {
  const user = useUserData();

  const portal = ref<any | null>(null);
  const portalLoading = ref(false);
  const portalError = ref('');
  const portalStyles = ref<PortalColorsInterface | null>(null);
  const currentLink = ref<any | null>(null);
  const billingUserPackages = ref<any[]>([]);
  const billingUserPackagesLoading = ref<boolean>(false);

  const currentPortalId = computed(() => portal.value?.id);

  const portalLinks = computed(() => portal.value?.portals_links);

  const isLockedCurrentLink = computed(() => {
    return isLockedLink(currentLink.value.id);
  });

  const isLockedLink = (linkId: string) => {
    return (
      portal.value?.user_id !== user.value?.id &&
      !billingUserPackages.value?.some((packageItem: any) => {
        return packageItem.package_features?.portal_links?.includes(linkId);
      })
    );
  };

  const setCurrentPortalLinkById = (linkId: string) => {
    const portalLink = getLinkById(linkId);

    if (portalLink) {
      currentLink.value = portalLink;
    }
  };

  const setBillingUserPackages = (payload: any) => {
    billingUserPackages.value = payload;
  };

  const getLinkById = (linkId: string) => {
    return portalLinks.value.find((link: any) => link.id === linkId);
  };

  const setPortal = (payload: any) => {
    portal.value = payload;
  };

  return {
    portal,
    setPortal,
    portalLinks,
    currentLink,
    billingUserPackages,
    currentPortalId,
    isLockedLink,
    portalStyles,
    setCurrentPortalLinkById,
    setBillingUserPackages,
    getLinkById,
    portalLoading,
    portalError,
    isLockedCurrentLink,
    billingUserPackagesLoading,
  };
});
