{
  "retellingWidget": {
    "retelling": "Recreación",
    "generate_retelling": "Generar recreación",
    "context": "CONTEXTO",
    "retell_in_language": "Recrear en el idioma {formLanguage} a {formSize} {formMethod} del contexto original",
    "keeping_the_tone": "manteniendo el tono {formTone}",
    "use_markdown_format": "Usar formato markdown",
    "add_the_primary_question": "Agregar la pregunta principal con una respuesta que responda al contexto utilizando formato en cursiva.",
    "add_main_emojis": "Agregar aquí los emojis principales.",
    "add_bold_main_keywords": "Agregar aquí las palabras clave principales en negrita.",
    "add_a_header": "Agregar un encabezado como h2 en markdown.",
    "add_1_sentence_quote": "Agregar una cita de 1 oración de la frase más importante del contexto utilizando formato de bloque de cita en markdown.",
    "if_you_have_what_to_add": "Si tienes algo que agregar como puntos de viñeta, agrégalo al final como una lista en markdown.",
    "an_error_occurred_while_fetching": "Ocurrió un error al obtener la recreación.",
    "something_didn_t_go_as_planned": "Algo no salió como estaba planeado.",
    "error_occured_while_creating_pdf": "Ocurrió un error al crear el PDF"
  },
  "retellingWidgetAnswer": {
    "retold": "Recreado",
    "from_original": "del original ~{pages} páginas de texto a",
    "download_pdf": "Descargar PDF",
    "clear_retelling": "Borrar recreación",
    "retelling": "Recreación"
  },
  "retellingWidgetAnswerChunk": {
    "click_to_edit_this_block": "Haz clic para editar este bloque",
    "click_to_adjust_this_block": "Haz clic para ajustar este bloque",
    "click_to_delete_this_block": "Haz clic para eliminar este bloque",
    "something_went_awry": "¡Algo salió mal!",
    "you_ve_successfully_deleted": "¡Has eliminado correctamente una parte de la recreación!"
  },
  "retellingWidgetAnswerHeader": {
    "click_to_edit": "Haz clic para editar el encabezado recreado"
  },
  "retellingWidgetForm": {
    "you_ve_enabled_custom_prompt_in_settings": "¡Has habilitado la indicación personalizada en la configuración!",
    "block_size": "Tamaño del bloque",
    "size_from_original": "Tamaño del original ~{totalPages} páginas de texto",
    "tone": "Tono",
    "speak_tone": "Hablar... tono",
    "language": "Idioma",
    "options": "Opciones",
    "add_subheaders": "agregar subencabezados",
    "add_important_quotes": "agregar citas importantes",
    "use_bullet_points": "usar formato de viñetas",
    "add_main_question": "agregar pregunta principal",
    "add_emojis": "agregar emojis",
    "bold_main_keywords": "palabras clave principales en negrita",
    "write_it_for_a": "Escríbelo para un niño de 9 años como una historia de aventuras",
    "create_preview": "Crear vista previa",
    "original": "original"
  },
  "retellingWidgetModal": {
    "create_retelling_pdf": "Crear PDF de recreación",
    "ok": "Aceptar",
    "wait_for_a_few_seconds_please": "Espera unos segundos por favor..."
  },
  "retellingWidgetPreview": {
    "preview": "Vista previa",
    "retelling_a_piece_of_content": "recreando una pieza de contenido",
    "refresh_preview": "Actualizar vista previa"
  }
}