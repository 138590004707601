const getQueryParamFromEncodedUrl = (encodedUrl: string, param: string): string | null => {
  try {
    const decodedUrl = decodeURIComponent(encodedUrl);

    const urlObject = new URL(decodedUrl);

    const params = new URLSearchParams(urlObject.search);

    return params.get(param);
  } catch (error) {
    console.error('Error parsing URL:', error);
    return null;
  }
};

export default getQueryParamFromEncodedUrl;
