{
  "baseAiHelp": {
    "book_call": "Reservar una llamada",
    "hide": "Ocultar"
  },
  "baseModal": {
    "ok": "Aceptar",
    "cancel": "Cancelar",
    "delete": "Eliminar"
  },
  "baseModalFooter": {
    "cancel_text": "Cancelar",
    "submit_text": "Guardar"
  },
  "baseDatePicker": {
    "to": "hasta"
  },
  "basePagination": {
    "first": "Primero",
    "prev": "Anterior",
    "next": "Siguiente",
    "last": "Último"
  },
  "baseSelect": {
    "choose": "Seleccionar...",
    "select_all": "Seleccionar todo"
  },
  "baseSelectTable": {
    "select_all": "Seleccionar todo",
    "wordpress_site_types": "TIPOS DE SITIOS WORDPRESS"
  },
  "baseSpeechToTextButton": {
    "hold_and_ask_your_question": "Mantén presionado y haz tu pregunta. Especifica un idioma si es necesario haciendo clic en el botón siguiente ('Más opciones')"
  },
  "baseStepper": {
    "previous": "Anterior",
    "next": "Siguiente"
  },
  "baseTextToSpeechButton": {
    "text_to_voice": "Texto a voz",
    "text_to_speech_syntesis": "La síntesis de texto a voz no admite el idioma {languageValue}"
  },
  "baseUnsavedModal": {
    "all_your_unsaved_changes": "Se perderán todos los cambios no guardados. ¿Guardar los cambios antes de abandonar la página?",
    "save_changes": "Guardar cambios",
    "you_have_unsaved_changes": "Tienes cambios no guardados"
  },
  "buttonInputViewCopyText": {
    "copied_to_clipboard": "¡Copiado al portapapeles!"
  }
}