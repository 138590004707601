{
  "casesDatabase": {
    "popular": "Popolare",
    "HR": "HR",
    "support": "Supporto",
    "eCommerce": "eCommerce",
    "business": "Business",
    "community": "Comunità",
    "developers": "Sviluppatori",
    "professionals": "Professionisti",
    "teachers": "Insegnanti",
    "sales_and_marketing": "Vendite e Marketing",
    "website_widget_title": "Widget Sito Web e Chatbot",
    "website_widget_description": "Strumento di chat per i dati del sito web.",
    "knowledge_bot_title": "Bot di Conoscenza",
    "knowledge_bot_description": "Trova informazioni nei file caricati rapidamente.",
    "slack_HR_support_bot_title": "Bot di supporto HR per Slack",
    "slack_HR_support_bot_description": "Bot HR nel team di Slack",
    "GDrive_file_HR_chatbot_for_Slack_title": "GDrive File HR Chatbot per Slack",
    "GDrive_file_HR_chatbot_for_Slack_description": "Carica file di Google Drive su Slack.",
    "GDrive_folder_HR_chatbot_for_slack_title": "GDrive Cartella HR Chatbot per Slack",
    "GDrive_folder_HR_chatbot_for_slack_description": "Carica cartelle di Google Drive su Slack.",
    "OneDrive_file_HR_chatbot_for_Slack_title": "OneDrive File HR Chatbot per Slack",
    "OneDrive_file_HR_chatbot_for_Slack_description": "Carica file di OneDrive su Slack.",
    "OneDrive_folder_HR_chatbot_for_slack_title": "OneDrive Cartella HR Chatbot per Slack",
    "OneDrive_folder_HR_chatbot_for_slack_description": "Carica cartelle di OneDrive su Slack.",
    "knowledge_base_chat_popup_for_websites_title": "Popup Chat Base di Conoscenza per Siti Web",
    "knowledge_base_chat_popup_for_websites_description": "Dati del sito web come chat di aiuto.",
    "interactive_website_chat_for_customer_support_title": "Chat Interattiva per il Supporto Clienti sul Sito Web",
    "interactive_website_chat_for_customer_support_description": "Chat di aiuto dai dati del sito web.",
    "slack_integration_for_internal_knowledge_management_title": "Integrazione Slack per la Gestione Interna della Conoscenza",
    "slack_integration_for_internal_knowledge_management_description": "Bot Slack per la conoscenza dei file.",
    "AI_product_search_advisor_with_CSV_sync_title": "Consulente di Ricerca di Prodotti AI con Sincronizzazione CSV",
    "AI_product_search_advisor_with_CSV_sync_description": "Sincronizza elenchi di prodotti con CSV.",
    "whatsApp_AI_travel_advisor_for_villas_title": "Consulente di Viaggio AI su WhatsApp per Ville",
    "whatsApp_AI_travel_advisor_for_villas_description": "Trova e prenota ville tramite WhatsApp.",
    "zendesk_inquiry_slackbot_title": "Zendesk Inquiry Slackbot",
    "zendesk_inquiry_slackbot_description": "Visualizza i ticket di Zendesk su Slack.",
    "google_sheets_query_slackbot_title": "Google Sheets Query Slackbot",
    "google_sheets_query_slackbot_description": "Chiedi informazioni sui dati di Google Sheets su Slack.",
    "community_content_explorer_widget_title": "Esploratore di Contenuti della Comunità",
    "community_content_explorer_widget_description": "Cerca contenuti della comunità.",
    "vimeo_content_explorer_for_communities_title": "Esploratore di Contenuti Vimeo per le Comunità",
    "vimeo_content_explorer_for_communities_description": "Esplora facilmente i video di Vimeo.",
    "web_content_explorer_widget_title": "Esploratore di Contenuti Web",
    "web_content_explorer_widget_description": "Trova facilmente contenuti del sito web.",
    "PDF_content_discovery_widget_title": "Ricerca Contenuti PDF",
    "PDF_content_discovery_widget_description": "Trova facilmente documenti PDF.",
    "celebrity_chef_AI_chatbot_title": "Chef Celebrità AI Chatbot",
    "celebrity_chef_AI_chatbot_description": "Ottieni ricette da un bot chef.",
    "project_artifact_summarizer_title": "Sintetizzatore di Artefatti di Progetto",
    "project_artifact_summarizer_description": "Sintetizza rapidamente i file del progetto.",
    "AI_powered_data_extractor_for_bubble_apps_title": "Estrattore di Dati Alimentato da AI per App Bubble",
    "AI_powered_data_extractor_for_bubble_apps_description": "Usa l'IA per raccogliere dati.",
    "complex_document_simplifier_title": "Semplificatore di Documenti Complessi",
    "complex_document_simplifier_description": "Rendi i documenti facili da leggere.",
    "professional_summary_creator_title": "Creatore di Sintesi Professionali",
    "professional_summary_creator_description": "Ottieni sintesi semplici dai file.",
    "lesson_plan_quiz_generator_title": "Generatore di Quiz per Piani di Lezione",
    "lesson_plan_quiz_generator_description": "Crea quiz dai piani di lezione.",
    "notion_flashcard_creator_title": "Creatore di Flashcard per Notion",
    "notion_flashcard_creator_description": "Trasforma le pagine di Notion in flashcard.",
    "client_engagement_chat_assistant_title": "Assistente di Chat per l'Engagement dei Clienti",
    "client_engagement_chat_assistant_description": "Crea piani di follow-up per i clienti.",
    "lead_data_extraction_tool_title": "Strumento di Estrazione Dati Lead",
    "lead_data_extraction_tool_description": "Raccogli dati dai siti web dei lead.",
    "personalized_follow_up_generator_title": "Generatore di Follow-Up Personalizzati",
    "personalized_follow_up_generator_description": "Crea piani di follow-up dai dati.",
    "competitive_knowledge_mindmap_tool_title": "Strumento di Mappa Mentale per la Conoscenza Competitiva",
    "competitive_knowledge_mindmap_tool_description": "Trasforma i siti dei concorrenti in una mappa mentale."
  },
  "informerHelpContent": {
    "PdfOcrInformer": "Carica un file Pdf dal tuo computer.",
    "FileInformer": "Carica un file dal tuo computer.",
    "WebInformer": "Elenco di link del sito web, uno per riga.",
    "SingleWebInformer": "Fornisci un link del sito web da analizzare.",
    "AirtableInformer": "Scegli le tabelle da includere da Airtable.",
    "AudioInformer": "Carica un file audio e scegli la lingua.",
    "ChromeExtensionInformer": "Collegati alla nostra estensione Chrome.",
    "ConfluenceInformer": "Accedi agli articoli di Confluence con le credenziali.",
    "DatabaseInformer": "Comunica direttamente con il database.",
    "SlackIntegration": "Integra Omnimind nel canale Slack.",
    "CSVInformer": "Carica un file CSV con i dati.",
    "dropbox-file": "Incolla il link al file Dropbox.",
    "dropbox-folder": "Incolla il link alla cartella Dropbox.",
    "ExcelInformer": "Carica il tuo file Excel con i dati.",
    "gdrive-file": "Incolla il link al file di Google Drive.",
    "gdrive-folder": "Incolla il link alla cartella di Google Drive.",
    "GoogleSheetsInformer": "Inserisci il link al file di Google Sheets.",
    "onedrive-file": "Incolla il link al file di OneDrive.",
    "onedrive-folder": "Incolla il link alla cartella di OneDrive.",
    "text": "Digita o incolla il testo da aggiungere.",
    "url": "Incolla il link a un file su Internet.",
    "VimeoInformer": "Incolla il link al video di Vimeo e salva il token.",
    "xml-sitemap": "Incolla il link al file sitemap XML.",
    "youtube-channel": "Incolla il link al canale YouTube e scegli la lingua.",
    "YoutubeInformer": "Incolla il link al video di YouTube e scegli la lingua.",
    "youtube-playlist": "Incolla il link alla playlist di YouTube e scegli la lingua.",
    "BulkCSVInformer": "Carica un file CSV con i link.",
    "NotionInformer": "Fornisci il token di Notion e il link alla pagina.",
    "WordpressInformer": "Incolla il link di Wordpress e scegli le categorie.",
    "zendesk": "Incolla il link al centro assistenza di Zendesk."
  }
}