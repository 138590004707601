{
  "addFileDrop": {
    "drag_drop_or": "Trascina e rilascia o",
    "select_files": "Seleziona file",
    "to_upload": "per caricare",
    "error_uploading_file": "Errore durante il caricamento del file",
    "error_uploading_files": "Errore durante il caricamento dei file"
  },
  "resourcesProject": {
    "show_uploaded_resources": "Mostra risorse caricate",
    "knowledge": "Conoscenza"
  },
  "resourcesProjectListItem": {
    "click_here_to_show_hide_explore_the_pages": "Clicca qui per {value} le pagine!",
    "delete": "Elimina",
    "chunks": "pezzi",
    "cancel": "Annulla",
    "resource": "Risorsa",
    "size": "Dimensione",
    "updated": "Aggiornato",
    "page_content": "Contenuto della pagina"
  },
  "resourcesProjectModal": {
    "knowledge": "Conoscenza",
    "save": "Salva",
    "search_for_uploaded_knowledge": "Cerca conoscenza caricata...",
    "add_more": "Aggiungi altro"
  }
}