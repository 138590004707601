{
  "pagesOmniPortalId": {
    "name": "name",
    "add_new": "Add new",
    "name_not_provided": "Name is not provided"
  },
  "pagesOmniPortalIndex": {
    "portal": "Portal",
    "welcome_to_our_turnkey_portal": "Welcome to our turnkey portal feature, where you’re empowered to customize, rebrand, and deliver cutting-edge AI solutions without the need to develop from scratch. Our platform offers a comprehensive framework that allows you to tailor subscription plans and tiers precisely to your and your users’ needs. With just a few clicks, you can select and assign various widgets and informers, ensuring each of your users receives a personalized experience under your brand.",
    "the_seamless_integration": "This seamless integration means you can offer sophisticated services while maintaining complete control over the user interface and functionality. It’s all about enhancing your offering with minimal effort and maximum efficiency. You provide the vision; our platform provides the tools to bring it to life. Start redefining your digital service today, and watch your business grow on the strength of our AI solutions.",
    "create_my_first_portal": "Create my first portal"
  },
  "pagesOmniPortalChangePassword": {
    "you_will_be_redirected_to_the_home": "You will be redirected to the home page in {value} seconds",
    "to_go_to_the_home_page": "To go to the home page",
    "click_here": "click here",
    "enter_your_new_password": "Enter your new password",
    "password_successfully_changed": "Password successfully changed",
    "invalid_token": "Invalid token"
  },
  "pagesOmniPortalLink": {
    "looks_like_you_ve_got_no_access": "Looks like you've got no access to this widget"
  },
  "pagesOmniPortalResetPassword": {
    "user_with_such_email_not_found": "User with such email not found"
  },
  "pagesOmniPortal": {
    "this_portal_does_not_contain_any_projects": "This portal does not contain any projects"
  }
}