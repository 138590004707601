{
  "tenantPortalDeleteUserModal": {
    "delete_this_user": "Delete this user?",
    "delete_user": "Delete user",
    "user_will_be_deleted": "User will be deleted from the list."
  },
  "tenantPortalInviteModal": {
    "invite_user": "Invite user",
    "apply": "Apply",
    "email": "Email",
    "promocode": "Promocode",
    "enter_your_promo_code": "Enter your promo code",
    "message": "Message",
    "enter_message_text": "Enter message text...",
    "the_invite_has_been_successfully_sent": "The invite has been successfully sent!",
    "the_error_occurred_while_sending_an_email": "The error occurred while sending an email"
  },
  "tenantPortalPortalUsers": {
    "no_users_so_far": "No users so far",
    "invite": "Invite",
    "email": "Email",
    "packages": "Packages",
    "status": "Status",
    "actions": "Actions"
  },
  "tenantPortalPortalUsersItem": {
    "user_was_deleted": "User was deleted"
  },
  "tenantPortalPortalUserModal": {
    "edit_user": "Edit user",
    "apply": "Apply",
    "email": "Email",
    "package": "Package",
    "status": "Status"
  }
}