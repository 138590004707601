{
  "basicModeSelectWidget": {
    "need_another": "Precisa de outro",
    "data_source": "fonte de dados",
    "view": "visualização",
    "have_a_specific": "Tem algum {feature} específico em mente que melhoraria sua experiência? Solicite-o como uma funcionalidade e trabalharemos na integração.",
    "sorry_we_could_not": "Desculpe, não encontramos nenhum resultado correspondente. Se você tiver algum {feature} específico em mente, pode solicitar como uma funcionalidade e trabalharemos na integração para você.",
    "request_a_new": "Solicitar uma nova {feature}"
  },
  "basicModeWidgetCard": {
    "coming_soon": "EM BREVE",
    "beta": "BETA",
    "popular": "POPULAR",
    "we_understand_you": "Entendemos que você está interessado nessa funcionalidade. Ela está disponível em nossos planos de nível superior. Considere fazer um upgrade para acessá-la!",
    "add_another_widget": "Adicionar outro widget",
    "expand_your_dashboard_by_adding_a_new_widget": "Expanda seu painel adicionando um novo widget."
  },
  "basicModeWidgetFilters": {
    "informers": "Informadores",
    "all": "Todos",
    "widgets": "Widgets",
    "search": "Pesquisar...",
    "all_informers": "Todos os Informadores",
    "popular": "Popular",
    "beta": "Beta",
    "embed": "Incorporar",
    "integrations": "Integrações"
  }
}