{
  "vimeoInformerInformers": {
    "you_can_provide_your_own_vimeo_token": "Você pode fornecer seu próprio Token do Vimeo nas configurações de credenciais de seus serviços.",
    "click_here": "Clique aqui!",
    "please_provide_a_valid_vimeo_video_url": "Por favor, forneça uma URL válida de vídeo do Vimeo",
    "this_video_has_no_text_tracks": "Este vídeo não possui faixas de texto!",
    "something_went_awry": "Algo deu errado!"
  },
  "vimeoInformerFormInformers": {
    "insert_vimeo_video_url": "Insira a URL do vídeo do Vimeo",
    "fetch_subtitles": "Buscar legendas"
  },
  "vimeoInformerListInformers": {
    "available_tracks": "Faixas disponíveis"
  }
}