{
  "googleSheetsAddOn": {
    "how_to_add_omnimind": "Cómo agregar el complemento de Google Sheets de Omnimind AI",
    "please_ensure_that_the_project": "Asegúrese de que el proyecto esté configurado como 'público'. Para hacer esto, haga clic en el botón 'Publicar' para que el proyecto sea accesible al público.",
    "copy_your_api_key": "Copia tu clave de API",
    "go_to_the_google_sheet_document": "Ve al documento de Google Sheet donde deseas usar el complemento.",
    "press_the_extensions_menu_button": "Presiona el botón del menú de extensiones y elige Complementos y Obtener complementos",
    "find_the_omnimind_add_on": "Encuentra el complemento de OmniMind e instálalo",
    "after_installing_the_add_on": "Después de instalar el complemento, ve al menú de extensiones, encuentra el elemento de menú del complemento de Omnimind y presiona Lanzar",
    "you_will_be_prompted_to": "Se te pedirá que te autentiques con Omnimind. Por favor, autentícate, serás redirigido de vuelta al documento de Google.",
    "launch_the_add_on_again": "Lanza el complemento nuevamente. Obtendrás una ventana de barra lateral donde puedes presionar Obtener proyectos y especificar qué datos del proyecto deseas usar en el documento.",
    "paste_your_api_key_in_the_provided_field": "Pega tu clave de API en el campo proporcionado y haz clic en Obtener proyectos.",
    "select_your_project": "Selecciona tu proyecto",
    "a_list_of_your_projects_in_omnimind": "Aparecerá una lista de tus proyectos en Omnimind.",
    "select_the_project_you_would_like_to": "Selecciona el proyecto que te gustaría usar para el análisis de datos.",
    "use_the_omni_ask_function": "Usa la función OMNI_ASK",
    "click_on_a_cell_where_you": "Haz clic en una celda donde quieras que aparezca la respuesta.",
    "enter_the_function": "Ingresa la función",
    "your_question_here": "Tu pregunta aquí",
    "replace": "Reemplaza",
    "with_the_actual_question_you": "con la pregunta real que deseas hacer.",
    "press_enter": "Presiona Enter",
    "getting_answers": "Obteniendo respuestas",
    "after_pressing_enter": "Después de presionar Enter, el complemento buscará automáticamente la respuesta en función de los recursos cargados en tu proyecto seleccionado en Omnimind.",
    "the_answer_will_then_appear": "La respuesta aparecerá en la celda donde ingresaste la función.",
    "tips": "Consejos",
    "make_sure_your_question": "Asegúrate de que tu pregunta sea clara y concisa para obtener las respuestas más precisas. Puedes hacer varias preguntas a la vez utilizando la función OMNI_ASK en diferentes celdas. Puedes actualizar o cambiar el proyecto en la configuración del complemento si deseas utilizar diferentes datos para el análisis.",
    "support": "Soporte",
    "if_you_encounter_any": "Si encuentras algún problema o tienes más preguntas, visita nuestra página de soporte en"
  }
}