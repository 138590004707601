{
  "pricingItem": {
    "month": "mês",
    "up_to_todo_calculate_pricing_info": "Até 'TODO: Calcular informações de preços'",
    "trial_days": "dias de teste",
    "this_includes:": "Isso inclui",
    "subscribe:": "Inscrever-se"
  },
  "pricingTable": {
    "subscriptions": "Assinaturas",
    "to_access_the_widget_you_need": "Para acessar o widget, você precisa escolher um dos planos de assinatura oferecidos",
    "oops": "Ops!",
    "there_no_available_packages": "Não há pacotes disponíveis para este link"
  }
}