{
  "foundBug": {
    "found_a_bug": "Знайшли помилку?"
  },
  "whatsNew": {
    "whats_new": "Що нового?"
  },
  "userHeader": {
    "profile": "Профіль",
    "billing": "Розрахунки"
  }
}