{
  "pagesProjectsIdWidgetViewContent": {
    "let_s_upload_the_content": "Carguemos el contenido",
    "with": "Con",
    "informers": "Informantes",
    "you_can_upload_information": "puedes cargar información de una variedad de fuentes, como sitios web, documentos PDF, incluso carpetas de Google, y más. Recuerda, siempre puedes volver a esta pantalla para cargar contenido adicional en tu proyecto",
    "upload_information": "Carga información de una variedad de fuentes, como sitios web, documentos PDF, incluso carpetas de Google, y más. Recuerda, siempre puedes volver a esta pantalla para cargar contenido adicional en tu proyecto cuando lo necesites.",
    "whenever_you_need": "cuando lo necesites",
    "select_widget": "Seleccionar widget",
    "back": "Atrás",
    "we_are_working_on_it": "Estamos trabajando en ello.",
    "is_not_supported_for_project_with": "no es compatible con el proyecto con datos cargados. Por favor, crea un nuevo proyecto para este."
  },
  "pagesProjectsIdWidgetMyWidgets": {
    "delete_widget": "Eliminar Widget",
    "confirm_widget_deletion": "Confirmar Eliminación de Widget",
    "are_you_sure_you_want_to_delete": "¿Estás seguro de que quieres eliminar el widget {widgetName}? Esta acción no se puede deshacer."
  }
}