{
  "docSearchWidget": {
    "scanning_through_the_documentation": "Sto esaminando la documentazione, per favore attendi un momento!",
    "generate_three_suggested_questions": "Genera tre domande suggerite basate sulle risorse fornite. Ogni domanda deve essere separata da un'interruzione di linea e non deve superare le cinque parole. Evita di numerare le domande.",
    "oops_it_seems_youve_reached_your": "Ops! Sembra che tu abbia raggiunto il limite di messaggi consentiti. Per continuare, modifica gentilmente il tuo limite di messaggi nelle impostazioni.",
    "we_encountered_an_issue_while": "Abbiamo riscontrato un problema durante il tentativo di generare una risposta! Riprova più tardi!",
    "search": "Cerca"
  },
  "docSearchWidgetHeader": {
    "hi_how_can_i_help_you": "Ciao! Come posso aiutarti?"
  },
  "docSearchWidgetInput": {
    "type_a_question_here": "Digita una domanda qui...",
    "ask": "Chiedi"
  },
  "docSearchWidgetReset": {
    "reset": "Reset"
  },
  "docSearchWidgetWrapper": {
    "click_here_to_see_embed_code": "Clicca qui per vedere il codice di incorporamento"
  }
}