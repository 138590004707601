{
  "voiceMessageSpoiler": {
    "hide_transcript": "Ocultar transcrição",
    "view_transcript": "Ver transcrição"
  },
  "voiceStartMessage": {
    "listening_to_you": "Ouvindo você...",
    "welcome_to_voice_talk": "Bem-vindo ao Voice Talk",
    "widget": "Widget!",
    "lets_speak": "Vamos falar",
    "press_stop_button": "Pressione o botão de parar abaixo se você quiser parar a gravação",
    "press_button_below_with_microphone": "Pressione o botão abaixo com o microfone para",
    "start_talking": "começar a falar"
  },
  "voiceWidgetAnswerMessage": {
    "copy_to_clipboard": "Copiar para a área de transferência"
  },
  "voiceWidgetFooter": {
    "hold_and_ask_your_question": "Segure e faça sua pergunta",
    "press_to_stop_talking": "Pressione para parar de falar"
  },
  "voiceWidgetMessageProcessing": {
    "processing_your_request": "Processando sua solicitação..."
  },
  "voiceWidgetQuestionMessage": {
    "you": "Você"
  },
  "voiceWidgetQuestionSpoiler": {
    "you": "Você"
  }
}