{
  "serviceItem": {
    "here_something_might_be_wrong": "¡Aquí algo podría estar mal! Corríjalo, por favor, y presione el botón 'Verificar validez de las credenciales' nuevamente",
    "here_something_might_be_wrong_many": "¡Aquí algo podría estar mal! Corríjalo, por favor, y presione el botón 'Verificar validez de las credenciales' nuevamente",
    "here_something_might_be_wrong_one": "¡Aquí algo podría estar mal! Corríjalo, por favor, y presione el botón 'Verificar validez de las credenciales' nuevamente",
    "here_something_might_be_wrong_other": "¡Aquí algo podría estar mal! Corríjalo, por favor, y presione el botón 'Verificar validez de las credenciales' nuevamente",
    "learn_more": "Aprender más",
    "learn_more_many": "Aprender más",
    "learn_more_one": "Aprender más",
    "learn_more_other": "Aprender más",
    "check_credentials_validity": "Verificar validez de las credenciales",
    "check_credentials_validity_many": "Verificar validez de las credenciales",
    "check_credentials_validity_one": "Verificar validez de las credenciales",
    "check_credentials_validity_other": "Verificar validez de las credenciales"
  }
}