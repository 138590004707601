{
  "basicModeSelectWidget": {
    "need_another": "Necesito otro",
    "data_source": "fuente de datos",
    "view": "vista",
    "have_a_specific": "¿Tienes en mente alguna {feature} específica que mejorarían tu experiencia? Solicítala como una característica y trabajaremos en integrarla.",
    "sorry_we_could_not": "Lo sentimos, no pudimos encontrar ningún resultado coincidente. Si tienes en mente alguna {feature} específica, puedes solicitarla como una característica y trabajaremos en integrarla para ti.",
    "request_a_new": "Solicitar una nueva {feature}"
  },
  "basicModeWidgetCard": {
    "coming_soon": "PRÓXIMAMENTE",
    "beta": "BETA",
    "popular": "POPULAR",
    "we_understand_you": "Entendemos que estás interesado en esta característica. Está disponible en nuestros planes de nivel superior. ¡Considera actualizar para acceder a ella!",
    "add_another_widget": "Agregar otro widget",
    "expand_your_dashboard_by_adding_a_new_widget": "Expande tu panel agregando un nuevo widget."
  },
  "basicModeWidgetFilters": {
    "informers": "Informadores",
    "all": "Todos",
    "widgets": "Widgets",
    "search": "Buscar...",
    "all_informers": "Todos los informadores",
    "popular": "Popular",
    "beta": "Beta",
    "embed": "Insertar",
    "integrations": "Integraciones"
  }
}