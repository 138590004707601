{
  "exportQuestionsCodeModal": {
    "how_to_import_into_quizlet": "Як імпортувати в Quizlet",
    "start_by_heading_to": "Почніть з переходу до Quizlet та натисніть кнопку +, щоб створити новий набір для вивчення. Далі знайдіть та натисніть кнопку \"Імпортувати\". Тепер просто скопіюйте вміст, наведений вище, і вставте його в Quizlet. Пам'ятайте: використовуйте",
    "tab": "вкладку",
    "between_each_term_and_its_definition_and_a": "між кожним терміном та його визначенням, а також",
    "new_line": "новий рядок",
    "to_separate_different_cards": "для розділення різних карток. Приємного вивчення!",
    "copy_content": "Скопіювати вміст",
    "copied_content": "Скопійований вміст",
    "content_was_copied": "Вміст був скопійований!"
  },
  "filtersModal": {
    "filters": "Фільтри",
    "search_for_resources": "Пошук ресурсів...",
    "search_by_selected_resources": "Пошук за обраними ресурсами",
    "reset_all": "Скинути все",
    "check_all": "Позначити все",
    "sorry_no_resources_found_for_your": "На жаль, не знайдено ресурсів за вашим запитом",
    "please_try_modifying_your": "Спробуйте змінити свої пошукові терміни або фільтри, щоб розширити пошук.",
    "cancel": "Скасувати",
    "apply": "Застосувати"
  },
  "liveConnect": {
    "error_executing_user_code": "Помилка виконання користувацького коду",
    "something_went_wrong": "Щось пішло не так! Будь ласка, переконайтеся, що ви правильно встановили Intercom",
    "something_went_wrong_please_make_sure": "Щось пішло не так! Будь ласка, переконайтеся, що ви правильно встановили віджет Hubspot",
    "something_went_wrong_please_make_sure_dialogFlow": "Щось пішло не так! Будь ласка, переконайтеся, що ви правильно встановили DialogFlow"
  },
  "offlineMessage": {
    "name": "Ім'я",
    "email": "Електронна пошта",
    "subject": "Тема",
    "message": "Повідомлення",
    "submit": "Надіслати",
    "error_executing_user_code": "Помилка виконання користувацького коду",
    "thank_you_for_your_response": "Дякуємо за вашу відповідь!",
    "something_went_awry": "Щось пішло не так!"
  },
  "poweredByMessage": {
    "powered_by": "Працює на"
  },
  "questionnaires": {
    "examples_of_questions_that_you_can_ask": "Приклади питань, на які ви можете запитати бота"
  },
  "widgetsFooter": {
    "back": "Назад",
    "help": "Довідка"
  },
  "widgetsModalInfo": {
    "it_staff_augmentation_in_portugal": "Підвищення кваліфікації ІТ-персоналу в Португалії",
    "upload_time": "Час завантаження",
    "result_relevance": "Релевантність результату"
  },
  "widget_external_user_form": {
    "please_provide_information_to_chat_with_me": "Будь ласка, надайте інформацію для спілкування зі мною",
    "your_email": "Ваша електронна пошта",
    "email": "Електронна пошта",
    "your_name": "Ваше ім'я",
    "name": "Ім'я",
    "continue": "Продовжити"
  }
}