{
  "baseAiHelp": {
    "book_call": "Agendar uma chamada",
    "hide": "Esconder"
  },
  "baseModal": {
    "ok": "Ok",
    "cancel": "Cancelar",
    "delete": "Excluir"
  },
  "baseModalFooter": {
    "cancel_text": "Cancelar",
    "submit_text": "Salvar"
  },
  "baseDatePicker": {
    "to": "até"
  },
  "basePagination": {
    "first": "Primeiro",
    "prev": "Anterior",
    "next": "Próximo",
    "last": "Último"
  },
  "baseSelect": {
    "choose": "Escolher...",
    "select_all": "Selecionar tudo"
  },
  "baseSelectTable": {
    "select_all": "Selecionar tudo",
    "wordpress_site_types": "TIPOS DE SITE WORDPRESS"
  },
  "baseSpeechToTextButton": {
    "hold_and_ask_your_question": "Segure e faça sua pergunta. Especifique um idioma, se necessário, clicando no botão 'Mais opções'"
  },
  "baseStepper": {
    "previous": "Anterior",
    "next": "Próximo"
  },
  "baseTextToSpeechButton": {
    "text_to_voice": "Texto para voz",
    "text_to_speech_syntesis": "A síntese de texto para fala não suporta o idioma {languageValue}"
  },
  "baseUnsavedModal": {
    "all_your_unsaved_changes": "Todas as alterações não salvas serão perdidas. Salvar alterações antes de sair da página?",
    "save_changes": "Salvar alterações",
    "you_have_unsaved_changes": "Você tem alterações não salvas"
  },
  "buttonInputViewCopyText": {
    "copied_to_clipboard": "Copiado para a área de transferência!"
  }
}