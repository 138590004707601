{
  "serviceCredentials": {
    "you_can_define_your_credentials": "Ви можете визначити свої облікові дані та використовувати власні сервіси для зберігання та обробки інформації.",
    "save_credentials": "Зберегти облікові дані",
    "keys": "Ключі",
    "key": "Ключ",
    "service_credentials_were_updated": "Облікові дані сервісу були оновлені!",
    "credentials_are_valid": "Облікові дані дійсні!",
    "credentials_are_invalid": "Облікові дані недійсні!",
    "something_went_wrong": "Щось пішло не так!",
    "cancel": "Скасувати"
  }
}