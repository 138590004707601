<template>
  <SidebarProjectTreeItem
    v-if="!canViewOnly"
    :title="$t('sidebarProjectTree.knowledge')"
    icon-name="books"
    :route="basicItemRoute + '/knowledge'"
    :is-selected="itemName === ItemTypeKeysEnum.Knowledge"
    @click="$emit('selectedItem', ItemTypeKeysEnum.Knowledge)"
  />
  <div v-if="isAgentProjectType">
    <SidebarAgentTools :project-id="projectId" :item-name="itemName" :basic-item-route="basicItemRoute"/>
    <SidebarAgent  :project-id="projectId" :item-name="itemName" :basic-item-route="basicItemRoute"/>
  </div>

  <SidebarProjectTreeItem
    :title="translateName"
    :icon-name="integration?.icon || widget?.widget_type?.icon"
    :route="basicItemRoute"
    :is-selected="
      itemName === ItemTypeKeysEnum.Widget || itemName === ItemTypeKeysEnum.Integrations
    "
    @click="
      $emit(
        'selectedItem',
        integration?.icon ? ItemTypeKeysEnum.Integrations : ItemTypeKeysEnum.Widget,
      )
    "
  >
    <BaseButton
      v-tippy="{
        content: $t('sidebarProjectTree.add_a_new_view'),
        placement: 'top',
        delay: 200,
        offset: [0, 10],
      }"
      class="dark:hover:bg-dark/60"
      rounded-class="rounded-full !p-1.5"
      size="small"
      intent="text"
      @click.prevent.stop="$emit('addNewView')"
    >
      <div class="h-6 w-6">
        <BaseSvgIcon name="draggable2" />
      </div>
    </BaseButton>
  </SidebarProjectTreeItem>
  <template v-if="!hasIntegration && !canViewOnly">
    <SidebarProjectTreeItem
      v-if="settingsEnabled.hasDialogs && !canViewOnly"
      :title="$t('sidebarProjectTree.conversations')"
      icon-name="dialogs"
      :route="basicItemRoute + '/dialogs'"
      :is-selected="itemName === ItemTypeKeysEnum.Dialogs"
      @click="$emit('selectedItem', ItemTypeKeysEnum.Dialogs)"
    />
    <div class="highlighted-item">
      <SidebarProjectTreeSettingsItem
        v-if="settingsEnabled.hasSettings"
        :basic-item-route="basicItemRoute"
        :item-name="itemName"
        :w-key="widgetKey"
        @selected-setting-item="$emit('selectedItem', $event)"
      />
    </div>
    <SidebarProjectTreeItem
      v-if="!canViewOnly"
      :title="$t('sidebarProjectTree.team')"
      icon-name="team"
      :route="basicItemRoute + '/team'"
      :is-selected="itemName === ItemTypeKeysEnum.Team"
      @click="$emit('selectedItem', ItemTypeKeysEnum.Team)"
    />
    <SidebarProjectTreeItem
      v-if="!canViewOnly"
      :title="$t('sidebarProjectTree.project')"
      icon-name="settings"
      :route="basicItemRoute + '/project'"
      :is-selected="itemName === ItemTypeKeysEnum.Project"
      @click="$emit('selectedItem', ItemTypeKeysEnum.Project)"
    />
  </template>
</template>

<script setup lang="ts">
import SidebarAgent from "@components/layout/sidebar/SidebarAgent.vue";
import SidebarAgentTools from "@components/layout/sidebar/SidebarAgentTools.vue";
import { computed } from 'vue';
import { useBaseComponents } from '@/hooks/asyncComponents';
import { getWidgetSettingsEnabled } from '@components/project/settings/helpers';
import SidebarProjectTreeItem from '@components/layout/sidebar/SidebarProjectTreeItem.vue';
import SidebarProjectTreeSettingsItem from '@components/layout/sidebar/SidebarProjectTreeSettingsItem.vue';
import { useCurrentUserProjectScopes } from '@/composables/useCurrentUserProjectScopes';
//Types
import { ItemTypeKeysEnum } from '@/types/ItemTypeKeysEnum';
import type { WidgetTypeKeysEnum } from '@/types/WidgetTypeKeysEnum';
import { type Widget } from '@/stores';
import { useTranslateWidgetContent } from '@/composables/useTranslateWidgetContent';

const props = defineProps<{
  projectId: string;
  projectType: string;
  widget: Widget;
  itemName: ItemTypeKeysEnum;
  integration?: any;
}>();

defineEmits<{
  (e: 'selectedItem', value: ItemTypeKeysEnum): void;
  (e: 'addNewView'): void;
}>();

const { BaseButton, BaseSvgIcon } = useBaseComponents();
const { translateWidgetTitle } = useTranslateWidgetContent();

const isAgentProjectType = computed(() => {
  return props.projectType?.includes('agent');
});

const basicItemRoute = computed(() => {
  if (props.widget && props.widget.id) {
    return `/projects/${props.projectId}/widget/${props.widget.id}`;
  }
  return `/projects/${props.projectId}`;
});

const widgetKey = computed(() => {
  const key = props.widget?.widget_type?.key || 'SearchWidget';
  return key as WidgetTypeKeysEnum;
});

const settingsEnabled = computed(() => getWidgetSettingsEnabled(widgetKey.value));

const hasIntegration = computed(() => {
  return (
    props.integration ||
    //@ts-ignore
    props.widget?.widget_type?.categories.some((c) => c.name.includes('Integrations'))
  );
});

const translateName = computed(() => {
  const { integration, widget } = props;

  switch (true) {
    case !!integration:
      return translateWidgetTitle(integration);
    case !!widget:
      return translateWidgetTitle(widget.widget_type);
    default:
      return '';
  }
});

const { canViewOnly } = useCurrentUserProjectScopes();
</script>

<style scoped>
.highlighted-item {
  position: relative;
}

.highlighted-item::before {
  content: '';
  position: absolute;
  left: 12px;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #c6cfdb;
}
</style>
