{
  "dialogs": {
    "user_info": "Información del usuario",
    "select_all_dialogs": "Seleccionar todos los diálogos",
    "search": "Buscar...",
    "deletion_of_the_selected_dialogs": "Eliminación de los diálogos seleccionados",
    "there_are_no_dialogs_yet": "Todavía no hay diálogos",
    "knowledge": "Conocimiento",
    "delete": "Eliminar",
    "something_went_wrong": "Algo salió mal durante la eliminación",
    "question_and_answer_were_saved_successfully": "¡La pregunta y respuesta se guardaron correctamente!",
    "an_error_occurred_while_saving": "¡Ocurrió un error al guardar la pregunta y respuesta!",
    "upgrade_plan": "Para utilizar esta funcionalidad, por favor, actualice su plan",
    "anonym": "Anónimo",
    "no_tracking": "El seguimiento de mensajes está actualmente desactivado. Por favor, habilítelo en la configuración para continuar.",
    "not_defined": "No definido",
    "browser": "Navegador",
    "os": "Sistema operativo",
    "more": "más",
    "less": "menos"
  },
  "dialogsFilters": {
    "filters": "Filtros",
    "by_period": "Por período",
    "by_negative": "Comentarios negativos",
    "by_calls": "Llamadas de operador",
    "by_offline": "Mensajes sin conexión",
    "apply": "Aplicar",
    "cancel": "Cancelar"
  }
}