{
  "copyProjectModal": {
    "clone_current_project": "Clonar projeto atual",
    "clone_project": "Clonar projeto",
    "copy_this_project": "Copiar este projeto?",
    "copy_project": "Copiar projeto",
    "all_your_settings_and_resources_on_this": "Todas as suas configurações e recursos deste projeto serão copiados.",
    "it_looks_like_you_ve_reached_your_maximum_number": "Parece que você atingiu o número máximo de projetos. Para iniciar um novo projeto, considere arquivar ou excluir os existentes que você não precisa mais, ou explore nossos planos de assinatura para limites mais altos."
  },
  "deleteProjectModal": {
    "delete_current_project": "Excluir projeto atual",
    "delete_project": "Excluir projeto",
    "delete": "Excluir",
    "all_your_data_on": "Todos os seus dados em",
    "will_be_deleted_immediately": "serão excluídos imediatamente.",
    "the_project": "o projeto"
  },
  "deleteShopifyContent": {
    "seems_like_you_ve_created_a_project_using_our_shopify": "Parece que você criou um projeto usando nosso aplicativo Shopify e está considerando excluí-lo através do aplicativo principal. Recomendamos excluí-lo via aplicativo Shopify: excluir um projeto através do nosso aplicativo Shopify é a maneira recomendada e mais segura de garantir um processo tranquilo e sem erros.",
    "yes_i_understand_it_but_want_to_delete_it_anyway": "Sim, eu entendo, mas quero excluí-lo mesmo assim."
  },
  "projectUploadingMessage": {
    "you_have_updated_knowledge_please_wait": "Você atualizou o conhecimento. Aguarde enquanto carregamos seu conhecimento.",
    "knowledge_uploaded": "Conhecimento carregado..."
  },
  "publishProject": {
    "unpublish": "Despublicar",
    "publish": "Publicar",
    "unpublish_project": "Despublicar projeto",
    "all_users_who_had_access_to_the_widgets": "Todos os usuários que tinham acesso aos widgets através do link perderão a capacidade de usá-los.",
    "share_project": "Compartilhar projeto",
    "please_note_that_any_activity_of_the_users": "Observe que qualquer atividade dos usuários com quem você compartilha este link está vinculada ao saldo da sua conta.",
    "publish_widget": "Publicar widget"
  },
  "renameProject": {
    "enter_the_short_name_of_your_project": "Digite o nome curto do seu projeto",
    "my_project": "Meu Projeto",
    "description": "Descrição"
  },
  "shareProject": {
    "widget_link_copied_to_clipboard": "Link do widget copiado para a área de transferência",
    "share_project": "Compartilhar projeto",
    "please_note_that_any_activity_of_the_users": "Observe que qualquer atividade dos usuários com quem você compartilha este link está vinculada ao saldo da sua conta.",
    "share": "Compartilhar",
    "publish_widget": "Publicar widget"
  },
  "shareProjectModal": {
    "share_widget": "Compartilhar widget",
    "please_note_that_any_activity_of_the_users": "Observe que qualquer atividade dos usuários com quem você compartilha este link está vinculada ao saldo da sua conta.",
    "copy_link": "Copiar link",
    "share_on": "Compartilhar em"
  },
  "unpublishProject": {
    "unpublish_project": "Despublicar projeto",
    "all_users_who_had_access_to_the_widgets": "Todos os usuários que tinham acesso aos widgets através do link perderão a capacidade de usá-los."
  }
}