{
  "airtableInformer": {
    "airtable_informer": "Airtable",
    "learn": "Aprender"
  },
  "audioInformer": {
    "audio_informer": "Audio",
    "learn": "Aprender"
  },
  "baseInformer": {
    "learn": "Aprender"
  },
  "bulkcsvInformer": {
    "csv_links_informer": "Enlaces CSV",
    "learn": "Aprender"
  },
  "chromeextensionInformer": {
    "chrome_extension_informer": "Extensión de Chrome",
    "select_project": "Seleccionar proyecto",
    "how_to_add_extension": "Cómo agregar la extensión de Chrome y comenzar a usarla.",
    "visit_the_chrome_web_store": "Visita la Chrome Web Store y busca la",
    "extension": "extensión",
    "click": "Haz clic",
    "add_to_chrome": "Añadir a Chrome",
    "authorize_yourself_in_the_extension": "Autorízate en la extensión, presiona Iniciar sesión",
    "if_you_dont_have_any_existing_projects": "Si no tienes proyectos existentes, puedes crear uno. Haz clic en el enlace proporcionado a OmniMind y crea tu proyecto.",
    "to_add_the_entire_web_page": "Para agregar toda la página web, selecciona tu proyecto deseado y haz clic en el botón \"Procesar\" en la extensión",
    "to_add_selected_text": "Para agregar texto seleccionado, resalta el texto en la página web, haz clic derecho y elige \"Agregar texto seleccionado al proyecto OmniMind\" en el menú contextual.",
    "the_content_you_added_will_be_stored": "El contenido que agregaste se almacenará en tu proyecto elegido. Ahora puedes usar esta información al interactuar con el modelo de IA."
  },
  "confluenceInformer": {
    "confluence_informer": "Confluence",
    "learn": "Aprender"
  },
  "csvInformer": {
    "csv_informer": "CSV",
    "learn": "Aprender"
  },
  "excelInformer": {
    "excel_informer": "Excel",
    "learn": "Aprender"
  },
  "fileInformer": {
    "file_informer": "Archivo",
    "learn": "Aprender"
  },
  "googleSheetsInformer": {
    "google_sheets_informer": "Google Sheets",
    "learn": "Aprender"
  },
  "notionInformer": {
    "notion_informer": "Notion",
    "learn": "Aprender"
  },
  "pdfOcrInformer": {
    "pdf_ocr_informer": "PDF",
    "learn": "Aprender"
  },
  "singleWebInformer": {
    "single_web_informer": "Web Individual",
    "learn": "Aprender"
  },
  "slackIntegrationIndex": {
    "name": "nombre",
    "select_project": "Seleccionar proyecto",
    "how_to_add_omnimind_app": "Cómo agregar la aplicación Omnimind a Slack Workspace",
    "start_by_copying_your_omnimind_api_key": "Comienza copiando tu clave de API de Omnimind. Necesitarás esto para conectar Omnimind con Slack.",
    "make_sure_your_question_is": "Asegúrate de que tu pregunta sea clara y concisa para obtener respuestas más precisas. Puedes actualizar o cambiar el proyecto en los mensajes directos del bot si deseas utilizar datos diferentes para el análisis.",
    "copy_your_project_id": "Copia tu ID de proyecto",
    "project_id_was_copied": "¡Se copió el ID del proyecto!",
    "add_omnimind_ai_to_your_slack_workspace": "Agrega la IA de Omnimind a tu espacio de trabajo de Slack",
    "configure_your_omnimind_app": "Configura tu aplicación Omnimind estableciendo las credenciales",
    "click_connect_to_omnimind_button": "Haz clic en el botón \"Conectar a Omnimind\"",
    "paste_your_api_key_and_project_id": "Pega tu clave de API y el ID de proyecto",
    "start_asking_your_question": "¡Comienza a hacer tu pregunta!"
  },
  "vimeoInformer": {
    "vimeo_informer": "Vimeo",
    "learn": "Aprender"
  },
  "webInformer": {
    "multiple_web_informer": "Web Múltiple",
    "learn": "Aprender"
  },
  "whatsappIntegration": {
    "name": "nombre",
    "select_project": "Seleccionar proyecto"
  },
  "wordpressInformer": {
    "wordpress_informer": "Wordpress",
    "learn": "Aprender"
  },
  "youtubeInformer": {
    "youtube_informer": "Youtube",
    "learn": "Aprender"
  },
  "zapierWidget": {
    "name": "nombre",
    "select_project": "Seleccionar proyecto",
    "how_to_add_omnimind_app": "Cómo agregar la aplicación Omnimind a Zapier",
    "start_by_copying_your_omnimind_api_key": "Comienza copiando tu clave de API de Omnimind. Necesitarás esto para conectar Omnimind con Zapier.",
    "make_sure_your_question_is_clear_and_concise": "Asegúrate de que tu pregunta sea clara y concisa para obtener respuestas más precisas. Puedes actualizar o cambiar el proyecto en la configuración de la acción si deseas utilizar datos diferentes para el análisis.",
    "go_to_the_zapier_application_where_you_d_like_to": "Ve a la aplicación de Zapier donde te gustaría usar Omnimind.",
    "create_a_new_action_step": "Crea un nuevo paso de acción y busca la integración de Omnimind.",
    "in_the_omnimind_app": "En la aplicación de Omnimind, selecciona la acción llamada \"Omni Ask\".",
    "log_into_your_omnimind_account": "Inicia sesión en tu cuenta de Omnimind usando la clave de API que copiaste anteriormente.",
    "in_the_action_tab": "En la pestaña de Acción, proporciona la información necesaria.",
    "from_the_project_dropdown": "Desde el menú desplegable de Proyecto, elige el proyecto con el que deseas conectarte.",
    "enter_the_question_you_d_like_to": "Ingresa la pregunta que te gustaría enviar a Omnimind.",
    "before_finalizing": "Antes de finalizar, prueba tu acción para asegurarte de que todo funcione correctamente.",
    "in_zapier": "En Zapier, selecciona el campo \"Respuesta\" de la aplicación de Omnimind. Puedes usar esta respuesta en tus pasos posteriores."
  }
}