{
  "agent_page": {
    "agent_settings": "Agenteneinstellungen",
    "select_model": "Modell auswählen",
    "prompt_editor": "Prompt Editor",
    "prompt_library": "Prompt Bibliothek"
  },
  "select_model": {
    "select_provider": "Anbieter auswählen",
    "select_model": "Modell auswählen"
  },
  "prompt_editor": {
    "write_your_instruction": "Schreiben Sie Ihre Anweisung",
    "variables": "Variablen",
    "save_prompt": "Prompt speichern",
    "prompt_has_been_updated": "Prompt wurde aktualisiert!",
    "model_has_been_updated": "Modell wurde aktualisiert!",
    "prompt_view": "Prompt-Ansicht",
    "edit_prompt": "Prompt bearbeiten",
    "cancel": "Abbrechen"
  },
  "prompt_library": {
    "no_save_prompts": "Keine gespeicherten Prompts",
    "select_a_department_to_see_tasks": "Wählen Sie eine Abteilung aus, um Aufgaben zu sehen"
  }
}