{
  "mindmapLoader": {
    "something_went_awry": "Щось пішло не так!",
    "please_wait_while_we_generate_your": "Зачекайте, будь ласка, поки ми генеруємо ваш",
    "attempt": "Спроба"
  },
  "topicsModal": {
    "select_mind_map_topic": "Виберіть тему ментальної карти",
    "wait_while_we_generate_topics": "Зачекайте, будь ласка, поки ми генеруємо теми на основі контексту вашого проекту",
    "your_custom_topic": "Ваша власна тема"
  }
}