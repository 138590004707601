{
  "databaseSettings": {
    "databaseSettings": "Налаштування бази даних",
    "configure_the_connection_to_your_database": "Налаштуйте з'єднання з вашою базою даних та виберіть таблиці, які ви хочете використовувати у своїх запитах.",
    "cancel": "Скасувати",
    "back": "Назад",
    "save": "Зберегти",
    "fetch_resources": "Отримати ресурси",
    "resources_fetched_successfully": "Ресурси успішно отримані",
    "something_went_wrong": "Щось пішло не так",
    "failed_to_load_settings": "Не вдалося завантажити налаштування"
  },
  "databaseSettingsConnectInformation": {
    "connect_information": "Інформація про підключення",
    "type_of_database": "Тип бази даних",
    "host": "Хост",
    "port": "Порт",
    "database": "База даних",
    "username": "Ім'я користувача",
    "password": "Пароль"
  },
  "databaseSettingsForm": {
    "host": "Хост"
  },
  "databaseSettingsHost": {
    "type_of_database": "Тип бази даних",
    "select_type_of_connection": "Виберіть тип підключення",
    "host": "Хост",
    "address_to_your_database": "адреса вашої бази даних",
    "address_of_your_db": "Адреса вашої БД",
    "port_to_your_database": "порт вашої бази даних",
    "port_of_your_db": "Порт вашої БД",
    "port": "Порт",
    "username": "Ім'я користувача",
    "username_to_your_database": "ім'я користувача вашої бази даних",
    "username_of_your_db": "Ім'я користувача вашої БД",
    "password": "Пароль",
    "password_to_your_database": "пароль вашої бази даних",
    "password_of_your_db": "Пароль вашої БД",
    "database": "База даних",
    "your_database_name": "назва вашої бази даних",
    "database_name": "Назва вашої бази даних у БД"
  },
  "databaseSettingsTables": {
    "general_instructions": "Загальні інструкції",
    "there_goes_general_description_about": "Тут йде загальний опис про базу даних, її логіку або деякі проект-специфічні деталі...",
    "select_all": "Вибрати все",
    "clean_all_selected": "Очистити всі вибрані",
    "search": "Пошук...",
    "active": "Активний",
    "table": "Таблиця",
    "comment": "Коментар",
    "description": "Опис"
  },
  "databaseSettingsURL": {
    "host": "Хост",
    "address_to_your_database": "адреса вашої бази даних",
    "address_of_your_db": "Адреса вашої БД"
  }
}