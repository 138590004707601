import { createI18n } from 'vue-i18n';
import { languages } from '@components/utils/languages';
import { getLocaleForUser } from '@/helpers/userPreferences';
import { computed } from 'vue';

const locales: string[] = languages.map((language) => language.code);

const currentLocale = computed(() => getLocaleForUser());

// This type of import does not work with loops, the construct
// { eager: true, import: "default" } is also required in every import, does not work with variable
const imports: any = {
  en: import.meta.glob(`./**/locales/en/**/*.json`, { eager: true, import: 'default' }),
  es: import.meta.glob(`./**/locales/es/**/*.json`, { eager: true, import: 'default' }),
  pt: import.meta.glob(`./**/locales/pt/**/*.json`, { eager: true, import: 'default' }),
  de: import.meta.glob(`./**/locales/de/**/*.json`, { eager: true, import: 'default' }),
  uk: import.meta.glob(`./**/locales/uk/**/*.json`, { eager: true, import: 'default' }),
  it: import.meta.glob(`./**/locales/it/**/*.json`, { eager: true, import: 'default' }),
};

const getLocaleMessages = () =>
  locales.reduce(
    (messages: any, locale: string) => ({
      ...messages,
      [locale]: Object.values(imports[locale]).reduce(
        (message: any, current: any) => ({ ...message, ...current }),
        {},
      ),
    }),
    {},
  );

export default createI18n({
  legacy: false,
  strictMessage: false,
  allowComposition: true,
  locale: currentLocale.value,
  globalInjection: true,
  fallbackLocale: 'en',
  messages: getLocaleMessages() || [],
});
