{
  "airtableInformer": {
    "airtable_informer": "Airtable",
    "learn": "Вивчити"
  },
  "audioInformer": {
    "audio_informer": "Аудіо",
    "learn": "Вивчити"
  },
  "baseInformer": {
    "learn": "Вивчити"
  },
  "bulkcsvInformer": {
    "csv_links_informer": "Посилання на CSV",
    "learn": "Вивчити"
  },
  "chromeextensionInformer": {
    "chrome_extension_informer": "Розширення Chrome",
    "select_project": "Вибрати проект",
    "how_to_add_extension": "Як додати розширення Chrome та почати його використовувати.",
    "visit_the_chrome_web_store": "Відвідайте Chrome Web Store та знайдіть",
    "extension": "розширення",
    "click": "Клацніть",
    "add_to_chrome": "Додати до Chrome",
    "authorize_yourself_in_the_extension": "Авторизуйтеся в розширенні, натиснувши «Увійти»",
    "if_you_dont_have_any_existing_projects": "Якщо у вас немає жодних існуючих проектів, ви можете створити один. Клацніть по наданому посиланню на OmniMind та створіть свій проект.",
    "to_add_the_entire_web_page": "Щоб додати весь веб-сайт, виберіть потрібний проект та клацніть кнопку «Обробити» в розширенні",
    "to_add_selected_text": "Щоб додати вибраний текст, виділіть текст на веб-сторінці, клацніть правою кнопкою миші та виберіть «Додати вибраний текст до проекту OmniMind» з контекстного меню.",
    "the_content_you_added_will_be_stored": "Доданий вміст буде збережено у вибраному вами проекті. Тепер ви можете використовувати цю інформацію при взаємодії з моделлю штучного інтелекту."
  },
  "confluenceInformer": {
    "confluence_informer": "Confluence",
    "learn": "Вивчити"
  },
  "csvInformer": {
    "csv_informer": "CSV",
    "learn": "Вивчити"
  },
  "excelInformer": {
    "excel_informer": "Excel",
    "learn": "Вивчити"
  },
  "fileInformer": {
    "file_informer": "Файл",
    "learn": "Вивчити"
  },
  "googleSheetsInformer": {
    "google_sheets_informer": "Google Sheets",
    "learn": "Вивчити"
  },
  "notionInformer": {
    "notion_informer": "Notion",
    "learn": "Вивчити"
  },
  "pdfOcrInformer": {
    "pdf_ocr_informer": "PDF",
    "learn": "Вивчити"
  },
  "singleWebInformer": {
    "single_web_informer": "Одна веб-сторінка",
    "learn": "Вивчити"
  },
  "slackIntegrationIndex": {
    "name": "ім'я",
    "select_project": "Вибрати проект",
    "how_to_add_omnimind_app": "Як додати додаток Omnimind до робочого простору Slack",
    "start_by_copying_your_omnimind_api_key": "Почніть з копіювання вашого API-ключа Omnimind. Вам знадобиться це для підключення Omnimind до Slack.",
    "make_sure_your_question_is": "Переконайтеся, що ваше запитання чітке та конкретне, щоб отримати найточніші відповіді. Якщо ви хочете використовувати різні дані для аналізу, ви можете оновити або змінити проект у приватних повідомленнях з ботом.",
    "copy_your_project_id": "Скопіюйте свій ідентифікатор проекту",
    "project_id_was_copied": "Ідентифікатор проекту був скопійований!",
    "add_omnimind_ai_to_your_slack_workspace": "Додайте Omnimind AI до свого робочого простору Slack",
    "configure_your_omnimind_app": "Налаштуйте свій додаток Omnimind, встановивши облікові дані",
    "click_connect_to_omnimind_button": "Клацніть кнопку «Підключитися до Omnimind»",
    "paste_your_api_key_and_project_id": "Вставте свій API-ключ та ідентифікатор проекту",
    "start_asking_your_question": "Почніть задавати своє запитання!"
  },
  "vimeoInformer": {
    "vimeo_informer": "Vimeo",
    "learn": "Вивчити"
  },
  "webInformer": {
    "multiple_web_informer": "Декілька веб-сторінок",
    "learn": "Вивчити"
  },
  "whatsappIntegration": {
    "name": "ім'я",
    "select_project": "Вибрати проект"
  },
  "wordpressInformer": {
    "wordpress_informer": "Wordpress",
    "learn": "Вивчити"
  },
  "youtubeInformer": {
    "youtube_informer": "Youtube",
    "learn": "Вивчити"
  },
  "zapierWidget": {
    "name": "ім'я",
    "select_project": "Вибрати проект",
    "how_to_add_omnimind_app": "Як додати додаток Omnimind до Zapier",
    "start_by_copying_your_omnimind_api_key": "Почніть з копіювання вашого API-ключа Omnimind. Вам знадобиться це для підключення Omnimind до Zapier.",
    "make_sure_your_question_is_clear_and_concise": "Переконайтеся, що ваше запитання чітке та конкретне, щоб отримати найточніші відповіді. Якщо ви хочете використовувати різні дані для аналізу, ви можете оновити або змінити проект у налаштуваннях дії.",
    "go_to_the_zapier_application_where_you_d_like_to": "Перейдіть до застосунку Zapier, в якому ви хочете використовувати Omnimind.",
    "create_a_new_action_step": "Створіть новий крок дії та знайдіть інтеграцію Omnimind.",
    "in_the_omnimind_app": "У додатку Omnimind виберіть дію з назвою «Omni Ask».",
    "log_into_your_omnimind_account": "Увійдіть до свого облікового запису Omnimind, використовуючи раніше скопійований API-ключ.",
    "in_the_action_tab": "У вкладці «Дія» надайте необхідну інформацію.",
    "from_the_project_dropdown": "У розкривному списку проектів виберіть проект, з яким ви хочете підключитися.",
    "enter_the_question_you_d_like_to": "Введіть запитання, яке ви хочете надіслати в Omnimind.",
    "before_finalizing": "Перед завершенням протестуйте свою дію, щоб переконатися, що все працює належним чином.",
    "in_zapier": "У Zapier виберіть поле «Відповідь» з додатку Omnimind. Ви можете використовувати цю відповідь у наступних кроках."
  }
}