import { gql } from '@apollo/client/core';

export const UPDATE_USER_MUTATION = gql`
  mutation (
    $id: uuid!
    $displayName: String!
    $email: citext
    $phoneNumber: String
    $metadata: jsonb
    $avatarUrl: String
  ) {
    updateUser(
      pk_columns: { id: $id }
      _set: {
        displayName: $displayName
        email: $email
        phoneNumber: $phoneNumber
        metadata: $metadata
        avatarUrl: $avatarUrl
      }
    ) {
      id
    }
  }
`;

export const INSERT_PROFILE = gql`
  mutation ($user_id: uuid!) {
    insert_profiles(objects: { id: $user_id }) {
      returning {
        id
      }
    }
  }
`;

export const UPDATE_SERVICE_CREDENTIAL = gql`
  mutation ($id: uuid!, $credentials: json!) {
    update_profiles(where: { id: { _eq: $id } }, _set: { credentials: $credentials }) {
      returning {
        id
        credentials
      }
    }
  }
`;

export const UPDATE_API_KEY_NAME = gql`
  mutation ($id: uuid!, $name: String!) {
    update_user_api_keys(where: { id: { _eq: $id } }, _set: { name: $name }) {
      returning {
        id
        name
      }
    }
  }
`;

export const DELETE_API_KEY_BY_ID = gql`
  mutation ($id: uuid!) {
    delete_user_api_keys(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
