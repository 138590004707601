{
  "retellingWidget": {
    "retelling": "Nacherzählung",
    "generate_retelling": "Nacherzählung generieren",
    "context": "KONTEXT",
    "retell_in_language": "Nacherzählen in {formLanguage}-Sprache für {formSize} {formMethod} des ursprünglichen Kontexts",
    "keeping_the_tone": "den {formTone} Ton beibehalten",
    "use_markdown_format": "Markdown-Format verwenden",
    "add_the_primary_question": "Fügen Sie die Hauptfrage mit einer Antwort hinzu, die den Kontext mit kursiver Formatierung beantwortet.",
    "add_main_emojis": "Fügen Sie hier Haupt-Emojis hinzu.",
    "add_bold_main_keywords": "Fügen Sie hier fettgedruckte Haupt-Schlüsselwörter hinzu.",
    "add_a_header": "Fügen Sie einen Header als h2-Markdown hinzu.",
    "add_1_sentence_quote": "Fügen Sie ein Zitat mit einem Satz der wichtigsten Phrase des Kontexts hinzu, indem Sie Blockzitat-Markdown verwenden.",
    "if_you_have_what_to_add": "Wenn Sie etwas hinzufügen möchten, fügen Sie es am Ende als Markdown-Liste hinzu.",
    "an_error_occurred_while_fetching": "Beim Abrufen der Nacherzählung ist ein Fehler aufgetreten.",
    "something_didn_t_go_as_planned": "Etwas ist nicht wie geplant gelaufen.",
    "error_occured_while_creating_pdf": "Beim Erstellen des PDF ist ein Fehler aufgetreten."
  },
  "retellingWidgetAnswer": {
    "retold": "Nacherzählt",
    "from_original": "von Original ~{pages} Seiten Text zu",
    "download_pdf": "PDF herunterladen",
    "clear_retelling": "Nacherzählung löschen",
    "retelling": "Nacherzählung"
  },
  "retellingWidgetAnswerChunk": {
    "click_to_edit_this_block": "Klicken Sie hier, um diesen Block zu bearbeiten",
    "click_to_adjust_this_block": "Klicken Sie hier, um diesen Block anzupassen",
    "click_to_delete_this_block": "Klicken Sie hier, um diesen Block zu löschen",
    "something_went_awry": "Etwas ist schief gelaufen!",
    "you_ve_successfully_deleted": "Sie haben erfolgreich einen Teil der Nacherzählung gelöscht!"
  },
  "retellingWidgetAnswerHeader": {
    "click_to_edit": "Klicken Sie hier, um den Nacherzählungsheader zu bearbeiten"
  },
  "retellingWidgetForm": {
    "you_ve_enabled_custom_prompt_in_settings": "Sie haben die benutzerdefinierte Aufforderung in den Einstellungen aktiviert!",
    "block_size": "Blockgröße",
    "size_from_original": "Größe von Original ~{totalPages} Seiten Text",
    "tone": "Ton",
    "speak_tone": "Sprechen Sie... Ton",
    "language": "Sprache",
    "options": "Optionen",
    "add_subheaders": "Unterüberschriften hinzufügen",
    "add_important_quotes": "Wichtige Zitate hinzufügen",
    "use_bullet_points": "Aufzählungspunkte verwenden",
    "add_main_question": "Hauptfrage hinzufügen",
    "add_emojis": "Emojis hinzufügen",
    "bold_main_keywords": "Fettgedruckte Haupt-Schlüsselwörter",
    "write_it_for_a": "Schreiben Sie es für ein 9-jähriges Kind wie eine Abenteuergeschichte",
    "create_preview": "Vorschau erstellen",
    "original": "Original"
  },
  "retellingWidgetModal": {
    "create_retelling_pdf": "Nacherzählungs-PDF erstellen",
    "ok": "OK",
    "wait_for_a_few_seconds_please": "Bitte warten Sie ein paar Sekunden..."
  },
  "retellingWidgetPreview": {
    "preview": "Vorschau",
    "retelling_a_piece_of_content": "Nacherzählung eines Inhaltsstücks",
    "refresh_preview": "Vorschau aktualisieren"
  }
}