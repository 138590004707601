import type { WidgetTypeInterface } from '@components/basic-mode/interfaces/WidgetTypeInterface';
import { useI18n } from 'vue-i18n';

export const useTranslateWidgetContent = () => {
  const { t } = useI18n();
  const BASE_INFORMER = 'BaseInformer';
  type widgetType = { type: string; key: string } | WidgetTypeInterface;
  const translateKey = (view: widgetType, property: string) => {
    const keyPrefix = view?.key === BASE_INFORMER ? `${view.key}_${view.type}` : view?.key;
    return t(`${keyPrefix}_${property}`);
  };

  const translateWidgetDescription = (view: widgetType) => translateKey(view, 'description');
  const translateWidgetTitle = (view: widgetType) => translateKey(view, 'name');

  return {
    translateWidgetDescription,
    translateWidgetTitle,
  };
};
