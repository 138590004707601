{
  "apiKeys": {
    "api_keys": "API-Schlüssel",
    "please_generate_your_api_key": "Generieren Sie bitte Ihren API-Schlüssel, wenn Sie unsere Backend-API verwenden möchten",
    "api_keys_not_found": "API-Schlüssel nicht gefunden.",
    "let_s_generate_it": "Lassen Sie uns ihn generieren!",
    "generate_api_key": "API-Schlüssel generieren",
    "your_secret_api_keys_are_listed_below": "Ihre geheimen API-Schlüssel sind unten aufgeführt. Teilen Sie Ihren API-Schlüssel nicht mit anderen Personen oder geben Sie ihn im Browser oder in anderem clientseitigem Code preis.",
    "name": "Name",
    "key": "Schlüssel",
    "created": "Erstellt",
    "expires": "Läuft ab",
    "last_used": "Zuletzt verwendet",
    "actions": "Aktionen",
    "name_is_not_provided": "Name wurde nicht angegeben",
    "api_key_was_update": "API-Schlüssel wurde aktualisiert!",
    "you_must_generate_a_new_api_key": "Sie müssen einen neuen API-Schlüssel generieren, um Ihren einzigen verbleibenden Schlüssel zu widerrufen.",
    "api_key_was_revoked": "API-Schlüssel wurde widerrufen!"
  },
  "createApiKeyModal": {
    "create_new_secret_key": "Neuen geheimen Schlüssel erstellen",
    "name_optional": "Name (optional)",
    "my_test_key": "Mein Test-Schlüssel",
    "create_secret_key": "Geheimen Schlüssel erstellen"
  },
  "createdNewKeyModal": {
    "created_new_secret_key": "Neuer geheimer Schlüssel erstellt",
    "please_save_this_secret_key": "Bitte speichern Sie diesen geheimen Schlüssel an einem sicheren und zugänglichen Ort. Aus Sicherheitsgründen können Sie ihn nicht erneut über Ihr Konto anzeigen. Wenn Sie diesen geheimen Schlüssel verlieren, müssen Sie einen neuen generieren.",
    "api_key_copied": "API-Schlüssel kopiert!"
  },
  "presentApiKeyModal": {
    "your_secret_key": "Ihr geheimer Schlüssel",
    "secret_key": "Geheimer Schlüssel",
    "api_key_copied": "API-Schlüssel kopiert!"
  },
  "renameApiKeyModal": {
    "edit_secret_key": "Geheimen Schlüssel bearbeiten",
    "save": "Speichern",
    "edit_name": "Name bearbeiten",
    "my_test_key": "Mein Test-Schlüssel"
  },
  "revokeKeyModal": {
    "revoke_secret_key": "Geheimen Schlüssel widerrufen",
    "this_api_key_will_immediately_be_disabled": "Dieser API-Schlüssel wird sofort deaktiviert. API-Anfragen, die mit diesem Schlüssel gemacht werden, werden abgelehnt, was dazu führen kann, dass Systeme, die noch davon abhängen, nicht mehr funktionieren. Sobald der Schlüssel widerrufen wurde, können Sie ihn nicht mehr anzeigen oder ändern.",
    "revoke_key": "Schlüssel widerrufen"
  }
}