{
  "pagesOmniPortalId": {
    "name": "Name",
    "add_new": "Neu hinzufügen",
    "name_not_provided": "Name wurde nicht angegeben"
  },
  "pagesOmniPortalIndex": {
    "portal": "Portal",
    "welcome_to_our_turnkey_portal": "Willkommen bei unserer schlüsselfertigen Portal-Funktion, mit der Sie maßgeschneiderte, umbrandete und hochmoderne KI-Lösungen bereitstellen können, ohne von Grund auf entwickeln zu müssen. Unsere Plattform bietet einen umfassenden Rahmen, mit dem Sie Abonnementpläne und -stufen genau an Ihre und die Bedürfnisse Ihrer Benutzer anpassen können. Mit nur wenigen Klicks können Sie verschiedene Widgets und Informationsgeber auswählen und zuweisen, um sicherzustellen, dass jeder Ihrer Benutzer eine personalisierte Erfahrung unter Ihrer Marke erhält.",
    "the_seamless_integration": "Diese nahtlose Integration ermöglicht es Ihnen, anspruchsvolle Dienstleistungen anzubieten, während Sie die vollständige Kontrolle über die Benutzeroberfläche und Funktionalität behalten. Es geht darum, Ihr Angebot mit minimalem Aufwand und maximaler Effizienz zu verbessern. Sie liefern die Vision; unsere Plattform bietet die Werkzeuge, um sie zum Leben zu erwecken. Beginnen Sie noch heute damit, Ihren digitalen Service neu zu definieren, und beobachten Sie, wie Ihr Unternehmen aufgrund unserer KI-Lösungen wächst.",
    "create_my_first_portal": "Mein erstes Portal erstellen"
  },
  "pagesOmniPortalChangePassword": {
    "you_will_be_redirected_to_the_home": "Sie werden in {value} Sekunden zur Startseite weitergeleitet",
    "to_go_to_the_home_page": "Um zur Startseite zu gelangen",
    "click_here": "hier klicken",
    "enter_your_new_password": "Geben Sie Ihr neues Passwort ein",
    "password_successfully_changed": "Passwort erfolgreich geändert",
    "invalid_token": "Ungültiges Token"
  },
  "pagesOmniPortalLink": {
    "looks_like_you_ve_got_no_access": "Sie haben anscheinend keinen Zugriff auf dieses Widget"
  },
  "pagesOmniPortalResetPassword": {
    "user_with_such_email_not_found": "Benutzer mit dieser E-Mail-Adresse nicht gefunden"
  },
  "pagesOmniPortal": {
    "this_portal_does_not_contain_any_projects": "Dieses Portal enthält keine Projekte"
  }
}