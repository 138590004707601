{
  "voiceMessageSpoiler": {
    "hide_transcript": "Приховати транскрипцію",
    "view_transcript": "Переглянути транскрипцію"
  },
  "voiceStartMessage": {
    "listening_to_you": "Слухаю вас...",
    "welcome_to_voice_talk": "Ласкаво просимо до голосового розмови",
    "widget": "Віджет!",
    "lets_speak": "Поговоримо",
    "press_stop_button": "Натисніть кнопку зупинки нижче, якщо хочете зупинити запис",
    "press_button_below_with_microphone": "Натисніть кнопку знизу з мікрофоном, щоб",
    "start_talking": "почати говорити"
  },
  "voiceWidgetAnswerMessage": {
    "copy_to_clipboard": "Скопіювати в буфер обміну"
  },
  "voiceWidgetFooter": {
    "hold_and_ask_your_question": "Утримуйте і задайте своє питання",
    "press_to_stop_talking": "Натисніть, щоб зупинити говорити"
  },
  "voiceWidgetMessageProcessing": {
    "processing_your_request": "Обробка вашого запиту..."
  },
  "voiceWidgetQuestionMessage": {
    "you": "Ви"
  },
  "voiceWidgetQuestionSpoiler": {
    "you": "Ви"
  }
}