{
  "billingInfo": {
    "select_project": "Виберіть проект",
    "required": "Обов'язково",
    "select_period": "Виберіть період",
    "your_current_package": "Ваш поточний пакет",
    "cancelled_at": "скасовано",
    "next_billing_date": "наступна дата оплати",
    "to_cancel_or_update_your_payment_plan": "Щоб скасувати або оновити платіжний план, перейдіть до порталу оплати клієнта.",
    "customer_payment_portal": "Портал оплати клієнта",
    "cancel_subscription": "Скасувати підписку",
    "remove_your_account": "Видалити ваш обліковий запис",
    "remove_account": "Видалити обліковий запис",
    "reactivate_account": "Активувати обліковий запис",
    "no_package_found": "Пакет не знайдено.",
    "let_s_select_a_suitable_package": "Давайте виберемо підходящий пакет.",
    "select_your_package": "Виберіть свій пакет",
    "the_account_will_deleted_at": "Обліковий запис буде видалено",
    "cancel_subscription_and_remove_account": "Скасувати підписку та видалити обліковий запис",
    "the_subscription_will_cancelled_at": "Підписка буде скасована",
    "cancel_current_subscription": "Скасувати поточну підписку",
    "something_went_awry": "Щось пішло не так"
  },
  "billings": {
    "billing_details": "Деталі оплати",
    "activity_summary": "Зведення активності"
  },
  "billingsActivity": {
    "purchase_history": "Історія покупок",
    "request_history": "Історія запитів",
    "request_analytics": "Аналітика"
  },
  "billingsActivityPurchase": {
    "no_data_so_far": "Поки немає даних.",
    "download_invoice": "Завантажити рахунок",
    "date": "Дата",
    "amount": "Сума",
    "package": "Пакет",
    "status": "Статус",
    "invoice": "Рахунок"
  },
  "billingsActivityRequest": {
    "select_project": "Виберіть проект",
    "required": "Обов'язково",
    "select_period": "Виберіть період",
    "apply": "Застосувати",
    "no_data_so_far": "Поки немає даних.",
    "created": "Створено",
    "entity": "Сутність",
    "cost": "Вартість"
  },
  "billingsCurrentPackage": {
    "current_package": "Поточний пакет",
    "click_here_to_see_package_info": "Натисніть тут, щоб переглянути інформацію про пакет",
    "cancelled_at": "скасовано",
    "deleted_at": "видалено",
    "trial_ends_at": "пробний період закінчується",
    "next_billing_date": "наступна дата оплати",
    "change_package": "Змінити пакет",
    "add_package": "Додати пакет",
    "cancel_subscription": "Скасувати підписку",
    "the_subscription_will_cancelled_at": "Підписка буде скасована",
    "cancel_current_subscription": "Скасувати поточну підписку",
    "the_subscription_will_delete_at": "Підписка буде видалена",
    "something_went_wrong": "Щось пішло не так!"
  },
  "billingsPackageInfo": {
    "package_information": "Інформація про пакет",
    "marketplace": "Маркетплейс",
    "learning_data": "Дані навчання",
    "requests": "Запити",
    "on_average_one_request_contains": "У середньому один запит містить",
    "characters": "символів",
    "no_data_so_far": "Поки немає даних"
  },
  "billingsPackageInfoItem": {
    "upgrade_to_add_more": "Оновіть, щоб додати більше"
  },
  "billingsUpgradePackageModal": {
    "expand_package": "Розширити пакет"
  },
  "chart": {
    "consumption_graph": "Графік споживання"
  },
  "claimOffer": {
    "fetching_a_discount_data": "Отримання даних про знижку...",
    "claiming_the_offer": "Отримання пропозиції...",
    "are_you_considering_our": "Ви розглядаєте нашу",
    "discount_the": "знижку? Ціна",
    "month_plan_is_now_just": "місячного плану тепер всього",
    "month": "місяць",
    "think_it_over": "Подумайте про це!"
  },
  "finalStep": {
    "your_subscription_has_been_successfully": "Ваша підписка успішно скасована. Сподіваємося, що ми знову побачимо вас. Ви все ще можете користуватися нашим сервісом безкоштовно.",
    "the_account_will_be_active": "Обліковий запис буде активним до кінця періоду оплати.",
    "you_have_got_the_discount_now": "Ви отримали знижку! Ми раді, що ви залишилися з нами!"
  },
  "billingHelpers": {
    "request": "Запит",
    "requests": "Запити",
    "learning_data_characters": "Дані навчання, символи",
    "package_name": "Назва пакета",
    "projects": "Проекти",
    "content_uploads": "Завантаження контенту",
    "widgets": "Віджети",
    "scheduled_content_updates": "Заплановані оновлення контенту",
    "alternative_ai_models": "Альтернативні моделі штучного інтелекту",
    "api_access": "Доступ до API",
    "chrome_extension": "Розширення Chrome",
    "extended_customizations": "Розширені налаштування"
  },
  "keepAi": {
    "hold_on": "Зачекайте!",
    "we_just_wanted_to_let_you_know": "Ми просто хотіли повідомити вам, що OmniMind.AI заощадив вам",
    "hour_of_work_so_far": "годин роботи до цього часу.",
    "our_support_team_can_help_you": "Наша служба підтримки може допомогти вам з будь-якими проблемами, з якими ви можете зіткнутися.",
    "chat_with_live_support": "Чат з живою підтримкою"
  },
  "keepPlan": {
    "keep_plan_we_are_sorry_to_see_you_go": "Збережіть план, нам шкода, що ви йдете!",
    "cancel_your_subscription": "Ви впевнені, що хочете скасувати підписку?",
    "cancel_your_current_subscription": "Ви впевнені, що хочете скасувати поточну підписку та видалити обліковий запис?",
    "please_let_us_know_cancel_your_subscription": "Будь ласка, дайте нам знати причину, з якої ви хочете скасувати підписку.",
    "reason_you_want_to_remove_the_account": "Будь ласка, дайте нам знати причину, з якої ви хочете видалити обліковий запис. Після підтвердження дії підписка буде скасована, а обліковий запис буде активним до кінця періоду оплати. Після закінчення періоду оплати ми назавжди видалимо всі дані вашого проекту та ваш обліковий запис.",
    "select_a_reason": "Виберіть причину",
    "other_reason": "Інша причина"
  },
  "keepPlanModal": {
    "MissingFeatures": "Відсутні функції",
    "TechnicalIssues": "Технічні проблеми",
    "Havenovalue": "Не має цінності",
    "Tooexpensive": "Занадто дорого",
    "Otherreason": "Інша причина"
  },
  "packageInfoModal": {
    "package_information": "Інформація про пакет"
  },
  "purchasedList": {
    "no_available_data_try_select_date": "Немає доступних даних, спробуйте вибрати дату...",
    "no_data_for_the_selected_period": "Немає даних за вибраний період",
    "created": "Створено",
    "entity": "Сутність",
    "quantity": "Кількість"
  },
  "subscriptionModal": {
    "cancel": "Скасувати",
    "remove": "Видалити",
    "removing": "Видалення",
    "plan": "План",
    "account": "Обліковий запис",
    "work_time_optimization": "Оптимізація робочого часу",
    "keep": "Зберегти",
    "discount_offer": "Пропозиція зі знижкою",
    "claim_offer": "Отримати пропозицію",
    "cancellation": "Скасування",
    "confirmation": "Підтвердження",
    "keep_value": "Зберегти {value}",
    "final_step": "Останній крок",
    "confirm": "Підтвердити",
    "continue_to": "Продовжити до",
    "something_went_awry": "Щось пішло не так!"
  }
}