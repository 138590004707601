import * as Sentry from '@sentry/vue';
import type { IAppEventProvider } from '@/analytics/IAppEventProvider';
import type { App } from 'vue';

export class SentryProvider implements IAppEventProvider {
  private app: App;

  constructor(app: App) {
    this.app = app;
  }
  init(): void {
    Sentry.init({
      app: this.app,
      dsn: import.meta.env.VITE_SENTRY_DSN,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,

      integrations: [
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
    });
  }

  private setUser = (data: Record<string, any>): void => {
    Sentry.setUser({
      email: data.email,
      username: `${data.firstName} ${data.lastName}`,
    });
  };

  signin = (data: Record<string, any>): void => {
    // Sentry.captureMessage(`User signed in: ${JSON.stringify(data)}`);
    this.setUser(data);
  };

  signup = (data: Record<string, any>): void => {
    this.setUser(data);
  };

  signout = (): void => {
    Sentry.setUser(null);
  };
}
