{
  "airtableInformerInformers": {
    "you_can_provide_your_own_airtable": "You can, provide your own Airtable",
    "personal_access_token": "Personal Access Token",
    "in_your_services_credentials_settings": "in your services credentials settings.",
    "click_here": "Click here!",
    "if_you_have_not_generated_yet_a_token": "If you have not generated yet a token, please head to your",
    "airtable_platform": "Airtable platform",
    "and_generate_one": "and generate one",
    "please_make_sure_to_select": "Please make sure to select",
    "and": "and",
    "scopes_and_related_bases": "scopes and related bases",
    "your_stored_integration_token": "Your stored Integration Token",
    "is_the_token_incorrect": "Is the token incorrect? You can change it in the service credentials settings.",
    "services_credentials_settings": "services credentials settings",
    "fetch_airtable_bases": "Fetch airtable bases",
    "select_base": "Select base",
    "select_table": "Select table"
  }
}