{
  "aiModel": {
    "please_choose_the_model_that_you_want_to_use": "Bitte wählen Sie das Modell aus, das Sie verwenden möchten. Das GPT-4-Modell ist robuster und liefert präzisere Antworten, ist jedoch langsamer und teurer, während GPT 3.5 schneller ist und für die meisten Fälle geeignet ist.",
    "gpt_model_which_is_used_to_answer_the_questions": "GPT-Modell, das verwendet wird, um die Fragen zu beantworten",
    "personality": "Persönlichkeit",
    "role": "Rolle",
    "act_as": "Handeln als...",
    "tone": "Ton",
    "custom_prompt_is_deactivated": "Benutzerdefinierte Eingabeaufforderung ist deaktiviert",
    "personality_settings_are_deactivated": "Persönlichkeitseinstellungen sind deaktiviert",
    "speak_tone": "Sprechen Sie... Ton",
    "formatting": "Formatierung",
    "use": "Verwenden Sie...",
    "formatting_works_better_with_the_chat_gpt_4_model": "Die Formatierung funktioniert besser mit dem Chat-GPT-4-Modell.",
    "the_answer_length_field_should_not_be_less_than": "Das Feld für die Antwortlänge sollte nicht kleiner als 50 und nicht größer als 2500 sein",
    "using_variables_question_and_context_are_mandatory": "Die Verwendung von Variablen {question} und {context} ist obligatorisch!"
  },
  "aiModelAdvanceSettings": {
    "advance_settings": "Erweiterte Einstellungen",
    "enter_a_number_between": "Geben Sie eine Zahl zwischen {value_1} und {value_2} ein",
    "custom_prompt": "Benutzerdefinierte Eingabeaufforderung",
    "custom_prompt_template": "Vorlage für benutzerdefinierte Eingabeaufforderung",
    "amount_of_the_last_messages_to_be_saved_in_context": "Anzahl der zuletzt gespeicherten Nachrichten im Kontext",
    "precontext": "Vorkontext",
    "more_about_custom_prompt": "Mehr über benutzerdefinierte Eingabeaufforderung",
    "save_last_messages_to_context": "Letzte Nachrichten im Kontext speichern: [Geben Sie eine Zahl ein]",
    "precontext_property_controls": "Der Vorkontext steuert die Anzahl der Antworten und Fragen in Ihrer Konversationshistorie mit KI, die in den neuen Antworten der KI verwendet werden."
  },
  "aiModelHeader": {
    "ai_model": "KI-Modell",
    "advance_settings": "Erweiterte Einstellungen"
  },
  "aiModelHelpData": {
    "struggling_with_tuning_ai_model": "Schwierigkeiten bei der Abstimmung des KI-Modells?",
    "don_t_hesitate_to_book_a_15_minutes_free_workshop": "Zögern Sie nicht, einen 15-minütigen kostenlosen Workshop mit unserem KI-Ingenieur zu buchen. Er hilft Ihnen bei der Abstimmung Ihres echten KI-Bots.",
    "book_a_call": "Termin buchen",
    "struggling_with_custom_settings": "Schwierigkeiten mit benutzerdefinierten Einstellungen?",
    "don_t_hesitate_to_chat_with_our_ai_engineer": "Zögern Sie nicht, mit unserem KI-Ingenieur zu chatten. Er hilft Ihnen bei der Abstimmung Ihres echten KI-Bots.",
    "open_chat": "Chat öffnen"
  },
  "aiModelSettingsData": {
    "degree_of_originality": "Grad der Originalität",
    "here_you_can_specify_the_creativity_level": "Hier können Sie den Kreativitätsgrad für das Modell angeben. Je näher der Wert null ist, desto kreativer ist das Modell, aber es hält sich möglicherweise nicht direkt an den bereitgestellten Kontext. Je näher der Wert eins ist, desto mehr hält sich das Modell direkt an die bereitgestellten Daten.",
    "topic": "Thema",
    "creative": "Kreativ",
    "diversity_range": "Vielfaltsspanne",
    "adjust_this_setting_to_control": "Passen Sie diese Einstellung an, um zu steuern, wie vielfältig der Text des Modells sein wird. Niedrigere Werte liefern vorhersehbarere Antworten, während höhere Werte mehr Abwechslung bieten, aber möglicherweise weniger konsistent sind.",
    "minimum_textual_diversity": "Minimale textuelle Vielfalt",
    "maximum_textual_diversity": "Maximale textuelle Vielfalt",
    "balance": "Ausgewogenheit",
    "semantic_balance_option": "Option für semantische Ausgewogenheit",
    "if_you_need_a_simple_search_in": "Wenn Sie eine einfache Suche auf klassische Weise benötigen, bei der nur die Wörter in den bereitgestellten Daten übereinstimmen, müssen Sie den Schieberegler näher an null bewegen. Wenn Sie hingegen möchten, dass die Suche eher wie natürliche Sprachverarbeitung ist und Wortformen unter Berücksichtigung von Synonymen, Kontext und verwandten Konzepten berücksichtigt, müssen Sie den Schieberegler näher an eins bewegen.",
    "if_you_want_a_simple_text_search": "Wenn Sie eine einfache Textsuche möchten, bewegen Sie den Schieberegler auf \"Klassische Textsuche\". Für eine komplexere Suche mit Sprachbedeutungen und Wortformen wählen Sie \"Suche nach Kontext\". Wenn Sie unsicher sind, stellen Sie den Schieberegler auf \"Optimal\" ein.",
    "classic_text_search": "Klassische Textsuche",
    "text_search": "Textsuche",
    "semantic_vector_search": "Semantische Vektorsuche",
    "search_by_context": "Suche nach Kontext",
    "knowledge_sources": "Wissensquellen",
    "here_you_can_define_the_maximum": "Hier können Sie die maximale Anzahl von Dokumenten (bzw. Chunks) definieren, die zur Generierung der Antwort verwendet werden.",
    "knowledge_diversity": "Wissensvielfalt",
    "knowledge_diversity_settings_helps": "Die Einstellung zur Wissensvielfalt hilft Ihnen, die Vielfalt der Informationen zu steuern, die Sie in Ihren Suchergebnissen erhalten. <br/><br/><strong>So funktioniert es:</strong><br/><br/><strong>Empfohlener Wert: 3:</strong><br/> <i>Zweck:</i> Bietet eine gute Mischung aus verschiedenen Informationen und vermeidet wiederholte Inhalte.<br/> <i>Anwendungsfall:</i> Ideal, um verschiedene Standpunkte und ein breites Wissensspektrum zu einem Thema zu erhalten.<br/><br/><strong>Wert: 0:</strong><br/> <i>Zweck:</i> Enthält alle ähnlichen Inhalte, auch wenn Informationen wiederholt werden.<br/><i>Anwendungsfall:</i> Nützlich, wenn Sie alle möglichen Daten sammeln möchten, z.B. für ein detailliertes Forschungsprojekt oder eine umfassende Studie.<br/><br/>Durch Festlegen des Wissensvielfalt-Levels können Sie sicherstellen, dass Ihre Suchergebnisse auf Ihre Bedürfnisse zugeschnitten sind, egal ob es sich um vielfältige Informationen oder um alle möglichen Details zu einem Thema handelt."
  }
}