{
  "confluenceInformerInformers": {
    "stay_organized_and_enhance_your_ai": "Rimani organizzato e migliora il tuo progetto di intelligenza artificiale importando facilmente i tuoi contenuti di Confluence.",
    "something_went_awry_check_provided_data": "Qualcosa è andato storto! Controlla i dati forniti e riprova, per favore!",
    "something_went_awry": "Qualcosa è andato storto!"
  },
  "confluenceInformerFormInformers": {
    "email": "Email",
    "your_confluence_email": "Il tuo indirizzo email di Confluence utilizzato per l'accesso.",
    "api_token": "Token API",
    "a_secure_api_token": "Un token API sicuro generato dal tuo account Atlassian, garantendo un'integrazione senza problemi e sicura.",
    "your_specific_confluence_domain": "Il tuo dominio specifico di Confluence",
    "e_g": "ad esempio",
    "if_your_confluence_url_is": "se il tuo URL di Confluence è",
    "fetch_resources": "Recupera risorse",
    "your": "Il tuo",
    "subdomain": "Sottodominio"
  },
  "confluenceInformerListPagesInformers": {
    "pages": "Pagine"
  },
  "confluenceInformerListPostsInformers": {
    "posts": "Post"
  }
}